import axios from "axios";
import axiosUrl from "./axios.service";
const API_URL = axiosUrl.getAxiosUrl();

class AuthService {
  login(user) {
    return axios
      .post(API_URL + "users/authenticate", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    sessionStorage.removeItem("searchText");
    sessionStorage.removeItem("durationPhrase");
    sessionStorage.removeItem("searchOnlyName");
    sessionStorage.removeItem("filterByICTUFlag");
    sessionStorage.removeItem("statusesString");
    sessionStorage.removeItem("startDate");
    sessionStorage.removeItem("endDate");
    sessionStorage.removeItem("updatedDateGreaterThan");
    sessionStorage.removeItem("updatedDateLessThan");
    sessionStorage.removeItem("schemeStartGreaterThan");
    sessionStorage.removeItem("schemeStartLessThan");
    sessionStorage.removeItem("schemeEndGreaterThan");
    sessionStorage.removeItem("schemeEndLessThan");
    sessionStorage.removeItem("publicationStatusesGroup");
    sessionStorage.removeItem("schemeStatusGroup");
    sessionStorage.removeItem("minBudget");
    sessionStorage.removeItem("maxBudget");
    sessionStorage.removeItem("organisationsInclude");
    sessionStorage.removeItem("organisationsExclude");
    sessionStorage.removeItem("searchOnAdministratingOrgs");
    sessionStorage.removeItem("searchOnAdvisingOrgs");
    sessionStorage.removeItem("searchOnFundingOrgs");
    sessionStorage.removeItem("searchOnRecipientOrgs");
    sessionStorage.removeItem("selectedLocations");
    sessionStorage.removeItem("newsSelectedLocations");
    sessionStorage.removeItem("selectedAudiences");
    sessionStorage.removeItem("selectedPurposes");
    sessionStorage.removeItem("selectedSchemeTypes");
    sessionStorage.removeItem("selectedProjectTypes");
    sessionStorage.removeItem("schemeUpdatesToAdd");
    sessionStorage.removeItem("showAllLanguageVersions");
  }
}

export default new AuthService();
