<template>
  <q-table
    v-if="currentAction === 'Editing'"
    :rows="schemeUpdatesState"
    :columns="schemeUpdatesColumns"
    row-key="id"
    :filter="filter"
    :loading="loading"
    :dense="$q.screen.lt.md"
    v-model:pagination="pagination"
    @request="onRequest"
    binary-state-sort
    title="Significant updates"
    class="updates-table"
  >
    <template v-slot:loading>
      <q-inner-loading showing color="primary"></q-inner-loading>
    </template>
    <template v-slot:top-right>
      <q-input
        borderless
        dense
        debounce="300"
        color="primary"
        placeholder="Search"
        v-model="filter"
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <q-separator vertical item class="q-mr-md q-ml-md" />
      <q-btn
        :disable="loading"
        @click="showAddSchemeUpdate = true"
        dense
        no-caps
        label="New significant update"
        class="orange-btn bold-font"
      >
        <q-tooltip>
          Add new scheme update
        </q-tooltip>
      </q-btn>
    </template>

    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="updateReason" :props="props">
          <span v-html="props.row.updateReason"></span>
          <q-popup-edit
            buttons
            v-model="props.row.updateReason"
            v-slot="scope"
            @save="updateEditingState(props.row)"
          >
            <q-input
              type="textarea"
              v-model="scope.value"
              dense
              autofocus
              counter
              @keyup.enter.stop
            />
          </q-popup-edit>
        </q-td>
        <q-td key="updateDateTime" :props="props">
          {{ props.row.updateDateTime }}
        </q-td>
        <q-td key="updateCreatedBy" :props="props">
          {{ props.row.updateCreatedBy }}
        </q-td>
        <q-td key="updateUpdatedBy" :props="props">
          {{ props.row.updateUpdatedBy }}
        </q-td>
        <q-td key="actions" :props="props" class="q-gutter-xs">
          <q-btn
            color="indigo-10"
            @click="
              (confirmRemoveSignificantUpdate = true),
                (significantUpdate = props.row)
            "
            size="sm"
            no-caps
          >
            <i class="las la-2x la-trash la-fw"></i>
            <q-tooltip>
              Remove
            </q-tooltip>
          </q-btn>
        </q-td>
      </q-tr>
    </template>
  </q-table>

  <q-table
    v-if="currentAction === 'Adding'"
    :rows="schemeUpdatesState"
    :columns="schemeUpdatesColumns"
    row-key="id"
    :filter="filter"
    :dense="$q.screen.lt.md"
    title="Significant updates"
  >
    <template v-slot:top-right>
      <q-input
        borderless
        dense
        debounce="300"
        v-model="filter"
        placeholder="Search"
        color="primary"
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <q-separator vertical item class="q-mr-md q-ml-md" />
      <q-btn
        class="orange-btn bold-font"
        @click="showAddSchemeUpdate = true"
        dense
        no-caps
        label="New significant update"
      >
        <q-tooltip>
          Add new scheme update
        </q-tooltip>
      </q-btn>
    </template>

    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="updateReason" :props="props">
          <span v-html="props.row.updateReason"></span>
          <q-popup-edit buttons v-model="props.row.updateReason" v-slot="scope">
            <q-input
              type="textarea"
              v-model="scope.value"
              dense
              autofocus
              counter
              @keyup.enter.stop
            />
          </q-popup-edit>
        </q-td>
        <q-td key="updateDateTime" :props="props">
          {{ props.row.updateDateTime }}
        </q-td>
        <q-td key="updateCreatedBy" :props="props">
          {{ props.row.updateCreatedBy }}
        </q-td>
        <q-td key="updateUpdatedBy" :props="props">
          {{ props.row.updateUpdatedBy }}
        </q-td>
      </q-tr>
    </template>
  </q-table>
  <q-dialog v-model="showAddSchemeUpdate" persistent class="modal-general">
    <q-card style="min-width: 350px">
      <q-card-section>
        <div>New significant update</div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-md">
        <q-input
          borderless
          bg-color="white"
          v-model="newSignificantUpdate.updateReason"
          label="Significant update"
          :rules="requiredFieldRule"
          lazy-rules
          type="textarea"
          stack-label
        />

        <q-input
          class="q-mt-md"
          borderless
          bg-color="white"
          v-model="newSignificantUpdate.updateDateTime"
          label="Date of significant update"
          stack-label
          :rules="requiredFieldRule"
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer q-pr-sm">
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  v-model="newSignificantUpdate.updateDateTime"
                  mask="DD-MM-YYYY"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          class="orange-btn-filled bold-font"
          no-caps
          label="Save"
          @click="addSchemeUpdate"
          :disable="
            newSignificantUpdate.updateReason != '' &&
            newSignificantUpdate.updateDateTime != null &&
            newSignificantUpdate.updateDateTime != ''
              ? null
              : true
          "
        />
        <q-btn
          class="orange-btn bold-font"
          no-caps
          label="Cancel"
          @click="disableAddSchemeUpdate"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="confirmRemoveSignificantUpdate" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-trash la-fw"></i>
        <span class="q-ml-sm"
          >Do you want to remove this significant update?</span
        >
      </q-card-section>

      <q-card-actions align="right">
        <q-btn outline label="Cancel" color="primary" v-close-popup />
        <q-btn
          label="Remove"
          color="primary"
          v-close-popup
          @click="removeSignificantUpdate(significantUpdate)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted, computed, reactive } from "vue";
import store from "../../store/index.js";
import SchemesService from "../../services/schemes.services.js";
import { useRoute } from "vue-router";
import moment from "moment";
/* import { useVModel } from "../../composables/useVModel.js";
 */
const schemeUpdatesColumns = [
  {
    name: "updateReason",
    required: true,
    label: "Significant update",
    align: "left",
    sortable: true,
    field: (row) => row.updateReason,
    format: (val) => `${val}`,
  },
  {
    name: "updateDateTime",
    align: "left",
    sortable: true,
    label: "Date of significant update",
    field: (row) => row.updateDateTime,
  },
  {
    name: "updateCreatedBy",
    align: "center",
    sortable: true,
    label: "Created by",
    field: (row) => row.updateCreatedBy,
  },
  {
    name: "updateUpdatedBy",
    align: "center",
    sortable: true,
    label: "Updated by",
    field: (row) => row.updateUpdatedBy,
  },
  { name: "actions", align: "center", field: "actions" },
];

export default {
  name: "SchemeUpdates",
  SchemeUpdates: "SchemeUpdates",
  emits: ["update:SchemeUpdates"],
  props: {
    schemeUpdates: Array,
    action: String,
  },

  setup(props) {
    const route = useRoute();
    const loading = ref(false);
    const filter = ref("");
    const schemeId = route.params.id;
    const showAddSchemeUpdate = ref(false);
    const currentAction = ref(props.action);
    const confirmRemoveSignificantUpdate = ref(false);
    const significantUpdate = ref(null);
    const schemeUpdatesState = ref([]);

    onMounted(async () => {
      if (currentAction.value === "Editing") {
        (loading.value = true), await SchemesService.getSchemeUpdates(schemeId);
        loading.value = false;
      }

      schemeUpdatesState.value = props.schemeUpdates;

      var cs = JSON.parse(sessionStorage.getItem("schemeUpdatesToAdd"));

      if (cs && cs.length > 0)
        for (let i = 0; i < cs.length; i++) {
          schemeUpdatesState.value.push(cs[i]);
        }
    });

    const newSignificantUpdate = reactive({
      updateReason: "",
      updateDateTime: moment().format("DD-MM-YYYY"),
    });

    const pagination = ref(
      computed({
        get: () => store.state.scheme.schemeUpdatesPagination,
        set: (paginationVar) =>
          store.commit("scheme/updateSchemeUpdatesPagination", paginationVar),
      })
    );

    const onRequest = async (props) => {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const fetchCount =
        rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage;
      const filterSearchTerm = props.filter;
      loading.value = true;

      await SchemesService.getSchemeUpdates(
        schemeId,
        filterSearchTerm,
        sortBy,
        descending,
        page - 1,
        fetchCount
      );
      store.commit(
        "scheme/updateSchemeUpdatesPaginationPage",
        props.pagination
      );
      loading.value = false;
    };

    // const schemeUpdatesState = ref(useVModel(props, 'schemeUpdates'));

    const addSchemeUpdate = async () => {
      var updateToAdd = {
        updateReason: newSignificantUpdate.updateReason,
        updateDateTime: newSignificantUpdate.updateDateTime,
        adding: true,
        editing: false,
      };

      if (
        sessionStorage.getItem("schemeUpdatesToAdd") &&
        sessionStorage.getItem("schemeUpdatesToAdd").length > 0
      ) {
        var currentStorage = JSON.parse(
          sessionStorage.getItem("schemeUpdatesToAdd")
        );
        currentStorage.push(updateToAdd);
        sessionStorage.setItem(
          "schemeUpdatesToAdd",
          JSON.stringify(currentStorage)
        );
      } else {
        sessionStorage.setItem(
          "schemeUpdatesToAdd",
          JSON.stringify([updateToAdd])
        );
      }

      schemeUpdatesState.value.push(updateToAdd);

      newSignificantUpdate.updateReason = "";
      newSignificantUpdate.updateDateTime = moment().format("DD-MM-YYYY");
      showAddSchemeUpdate.value = false;
    };

    const disableAddSchemeUpdate = () => {
      (newSignificantUpdate.updateReason = ""),
        (newSignificantUpdate.updateDateTime = moment().format("DD-MM-YYYY"));
      showAddSchemeUpdate.value = false;
    };

    const removeSignificantUpdate = (significantUpdate) => {
      const index = schemeUpdatesState.value.indexOf(significantUpdate);

      if (index > -1) schemeUpdatesState.value.splice(index, 1);
    };

    return {
      schemeUpdatesColumns,
      loading,
      filter,
      onRequest,
      pagination,
      route,
      schemeId,
      showAddSchemeUpdate,
      newSignificantUpdate,
      addSchemeUpdate,
      disableAddSchemeUpdate,
      schemeUpdatesState,
      currentAction,
      confirmRemoveSignificantUpdate,
      significantUpdate,
      removeSignificantUpdate,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || "Field is required!",
      ],
      updateEditingState(row) {
        if (row.id != 0) {
          let obj = schemeUpdatesState.value.find((el) => el.id === row.id);
          if (obj) {
            obj.editing = true;
          }
        }
      },
    };
  },
};
</script>
