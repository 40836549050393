<template>
  <q-dialog class="users-list-modal" v-model="show" persistent fit>
    <q-card class="modal-card">
      <div class="row justify-end close-btn-area">
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
          @click="disableModal()"
          class="row justify-end close-btn"
        />
      </div>

      <q-table
        class="my-sticky-header-table"
        row-key="name"
        flat
        bordered
        :separator="'cell'"
        :pagination="pagination"
        :rows="users"
        :columns="columns"
        v-if="modalDataType == 'activeDemo'"
        :rows-per-page-options="[]"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="customer" :props="props">
              {{ props.row.person.customer }}
            </q-td> 
            <q-td key="endDate" :props="props">
              <p  v-if="
                props.row.customerEndDate == null ||
                  formatDate(props.row.customerEndDate) == 'Invalid date'
              ">Not specified</p>
              <p v-else> {{ formatDate(props.row.customerEndDate) }}</p>
            
            </q-td>
            <q-td key="username" :props="props">
              {{ props.row.username }}
            </q-td>
            <q-td key="endDate" :props="props">
                <p v-if="  props.row.person.endDate == null ||
                  props.row.person.endDate == ''">Not specified</p>  
                <p v-else >
                {{ formatDate(props.row.person.endDate) }}</p>
                             
            </q-td>
          </q-tr>
        </template>
      </q-table>

      <q-table
        class="my-sticky-header-table"
        row-key="name"
        flat
        bordered
        :separator="'cell'"
        :pagination="pagination"
        :rows-per-page-options="[]"
        :rows="users"
        :columns="columnsNoNewsletterOrProfile"
        v-else-if="
          modalDataType == 'noNewsletter' || modalDataType == 'noProfile'
        "
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="customer" :props="props">
              {{ props.row.person.customer }}
            </q-td>
            <q-td key="username" :props="props">
              {{ props.row.username }}
            </q-td>
          </q-tr>
        </template>
      </q-table>

      <q-table
        class="my-sticky-header-table"
        row-key="name"
        flat
        bordered
        :separator="'cell'"
        :pagination="pagination"
        :rows="users"
        :columns="columnsNoVisit"
        v-else-if="modalDataType == 'noVisit'"
        :rows-per-page-options="[]"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="customer" :props="props">
              {{ props.row.person.customer }}
            </q-td>
            <q-td key="username" :props="props">
              {{ props.row.username }}
            </q-td>
            <q-td key="lastVisit" :props="props">
              <span v-if="formatDate(props.row.lastVisit) != '01-01-0001'">
                {{ formatDate(props.row.lastVisit) }}
              </span>
              <span v-else>No visit</span>
            </q-td>
          </q-tr>
        </template>
      </q-table>

      <q-table
        class="my-sticky-header-table"
        row-key="name"
        flat
        bordered
        :separator="'cell'"
        :rows="users"
        :columns="customersInDangerColumns"
        v-else-if="modalDataType == 'customersInDanger'"
        :rows-per-page-options="[]"
        :pagination="pagination"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="name" :props="props" class="name">
              {{ props.row.name }}
            </q-td>
            <q-td key="dangerReason" :props="props" class="reason">
              {{ props.row.dangerReason }}
            </q-td>
          </q-tr>
        </template>
      </q-table>

      <q-table
        class="my-sticky-header-table"
        row-key="name"
        flat
        bordered
        :separator="'cell'"
        :rows="users"
        :columns="columnsNewPortalUsers"
        :rows-per-page-options="[]"
        :pagination="pagination"
        v-else
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="name" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="portalName" :props="props">
              {{ props.row.portalName }}
            </q-td>
            <q-td key="createdDate" :props="props">
              <span v-if="formatDate(props.row.createdDate) != '01-01-0001'">
                {{ formatDate(props.row.createdDate) }}
              </span>
            </q-td>
            <q-td key="lastVisit" :props="props">
              <span v-if="formatDate(props.row.lastVisit) != '01-01-0001'">
                {{ formatDate(props.row.lastVisit) }}
              </span>
              <span v-else>No visit</span>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed } from 'vue';
import store from '../../../store/index';
import moment from 'moment';

export default {
  props: {
    typeOfData: String,
  },

  setup() {
    const disableModal = () => {
      store.commit('generalStats/updateShowModal', false);
    };

    const show = computed({
      get: () => store.state.generalStats.showModal,
    });

    const users = computed({
      get: () => store.state.generalStats.modalData,
    });

    const isCustomerModalData = computed({
      get: () => store.state.generalStats.isCustomerModalData,
    });
    const modalDataType = computed({
      get: () => store.state.generalStats.modalDataType,
    });
    const columns = [
      {
        name: 'customer',
        field: 'customer',
        label: 'Customer',
        align: 'left',
        sortable: true,
      },
      {
        name: 'customerEndDate',
        field: 'customerEndDate',
        label: 'Customer end date',
        align: 'left',
        sortable: true,
        format: (val) => formatDate(val),
      },
      {
        name: 'username',
        field: 'username',
        label: 'Username',
        align: 'left',
      },

      {
        name: 'endDate',
        field: 'endDate',
        label: 'User end date',
        align: 'left',
        format: (val) => formatDate(val),
      },
    ];
    const columnsNoVisit = [
      {
        name: 'customer',
        field: 'customer',
        label: 'Customer',
        align: 'left',
        sortable: true,
      },
      {
        name: 'username',
        field: 'username',
        label: 'Username',
        align: 'left',
      },
      {
        name: 'lastVisit',
        field: 'lastVisit',
        label: 'Last Visit',
        align: 'left',
        format: (val) => formatDate(val),
      },
    ];

    const columnsNoNewsletterOrProfile = [
      {
        name: 'customer',
        field: 'customer',
        label: 'Customer',
        align: 'left',
        sortable: true,
      },
      {
        name: 'username',
        field: 'username',
        label: 'Username',
        align: 'left',
      },
    ];

    const customersInDangerColumns = [
      {
        name: 'name',
        field: 'name',
        label: 'Name',
        align: 'left',
        sortable: true,
      },
      {
        name: 'dangerReason',
        field: 'dangerReason',
        label: 'Reason why in danger',
        align: 'left',
      },
    ];
    const formatDate = (stringDate) => {
      const date = new Date(stringDate);
      return moment(date).format('DD-MM-YYYY');
    };

    const columnsNewPortalUsers = [
      {
        name: 'name',
        field: 'name',
        label: 'Name',
        align: 'left',
        sortable: true,
      },
      {
        name: 'portalName',
        field: 'portalName',
        label: 'Portal',
        align: 'left',
      },
      {
        name: 'createdDate',
        field: 'createdDate',
        label: 'Registration date',
        align: 'left',
        format: (val) => formatDate(val),
      },
      {
        name: 'lastVisit',
        field: 'lastVisit',
        label: 'Last visit in 30 days',
        align: 'left',
        format: (val) => formatDate(val),
      },
    ];
    return {
      columnsNewPortalUsers,
      columnsNoVisit,
      customersInDangerColumns,
      modalDataType,
      columnsNoNewsletterOrProfile,
      formatDate,
      isCustomerModalData,
      users,
      show,
      disableModal,
      columns,
      pagination: {
        rowsPerPage: 500,
        page: 1,
      },
    };
  },
};
</script>

<style lang="sass">
.my-sticky-header-table
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    background-color: #25408f

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  &.q-table--loading thead tr:last-child th
    top: 48px
</style>
