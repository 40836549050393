<template>
  <q-spinner v-if="areSchemeChangesLoading" color="primary" size="4em" />
  <div v-else class="create-scheme-page">
    <ModalError />
    <div class="row heading-scheme">
      <div class="q-pl-md q-pr-md">
        <i
          class="las la-arrow-circle-left la-3x cursor-pointer"
          @click="router.go(-1)"
        >
          <q-tooltip>
            Go to previous page
          </q-tooltip>
        </i>
      </div>
      <div class="col-md-5">
        <q-input
          borderless
          v-model="scheme.newName"
          placeholder="Enter name"
          bg-color="white"
          stack-label
          :rules="requiredFieldRule"
          label="Name"
        />
      </div>
      <div class="col-md-5">
        <div class="row items-center justify-around">
          <div class="col-md-4 ratings q-gutter-x-lg q-pr-md">
            <div>
              <span>Complexity</span>
            </div>
            <q-rating
              v-model="scheme.complexityRating"
              size="sm"
              :max="5"
              color="primary"
              icon="star_border"
              icon-selected="star"
            ></q-rating>
          </div>
          <div class="col-md-4 ratings q-gutter-x-lg q-pr-md">
            <div>
              <span>Success rate</span>
            </div>
            <q-rating
              v-model="scheme.chanceOfSuccessRating"
              size="sm"
              :max="5"
              color="primary"
              icon="star_border"
              icon-selected="star"
            ></q-rating>
          </div>
          <div class="col-md-4 ratings q-gutter-x-lg q-pr-md">
            <div>
              <span>Application duration</span>
            </div>
            <q-rating
              v-model="scheme.timeForApplicationRating"
              size="sm"
              :max="5"
              color="primary"
              icon="star_border"
              icon-selected="star"
            ></q-rating>
          </div>
        </div>
      </div>
    </div>

    <div class="row main-page">
      <div class="q-pa-md">
        <div class="row justify-between scheme-top-inputs">
          <div class="scheme-inputs">
            <q-input
              borderless
              v-model="scheme.identifier"
              bg-color="white"
              label="Identifier"
              stack-label
            />

            <q-input
              class="q-mt-md createcheme-input"
              borderless
              v-model="scheme.newAccronym"
              stack-label
              bg-color="white"
              :rules="maxLengthRule"
              label="Accronym"
              lazy-rules
            />

            <q-input
              class="q-mt-md createcheme-input"
              borderless
              bg-color="white"
              v-model="scheme.newArchiveCode"
              stack-label
              :rules="maxLengthRule"
              label="Archive code"
            />

            <q-input
              v-model.number="scheme.minAmount"
              type="number"
              min="0"
              label="Minimum amount"
              stack-label
              bg-color="white"
              borderless
            />
            <q-input
              class="q-mt-md"
              v-model.number="scheme.maxAmount"
              type="number"
              min="0"
              label="Maximum amount"
              bg-color="white"
              stack-label
              borderless
            />

            <q-input
              class="q-mt-md createcheme-input"
              borderless
              v-model="scheme.newDuration"
              stack-label
              bg-color="white"
              :rules="maxLengthRule"
              label="Duration"
            />

            <q-separator class="q-mb-md" />

            <div class="date-inputs q-gutter-y-md q-mb-md q-mt-md">
              <div class="date-input">
                <q-input
                  borderless
                  bg-color="white"
                  v-model="scheme.startDate"
                  label="Start date"
                  stack-label
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="scheme.startDate"
                          :options="startDateOptionsFn"
                          mask="DD-MM-YYYY"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>

              <div class="date-input end-date">
                <q-input
                  borderless
                  bg-color="white"
                  v-model="scheme.endDate"
                  label="End date"
                  stack-label
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="scheme.endDate"
                          :options="optionsFn"
                          mask="DD-MM-YYYY"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </div>
          </div>
          <div class="dropdown-selectors q-gutter-y-md scheme-inputs">
            <div class="organisations-select">
              <q-select
                borderless
                bg-color="white"
                v-model="scheme.selectedFundingOrgs"
                multiple
                :options="filterOrganisationsFundingOptions"
                use-chips
                use-input
                option-value="name"
                option-label="name"
                stack-label
                label="Select funding organisation(s)"
                @filter="filterFundingOrganisationsFn"
                :rules="requiredFieldRule"
                @update:model-value="setInput()"
                ref="fundingOrgSelect"
              />
            </div>
            <div class="organisations-select">
              <q-select
                borderless
                bg-color="white"
                v-model="scheme.selectedAdministratingOrgs"
                multiple
                :options="filterOrganisationsAdministratingOptions"
                use-chips
                use-input
                option-value="name"
                option-label="name"
                stack-label
                label="Select administrating organisation(s)"
                @filter="filterAdministratingOrganisationsFn"
                @update:model-value="setInput()"
                ref="adminOrgsSelect"
              />
            </div>
            <div class="organisations-select">
              <q-select
                borderless
                bg-color="white"
                v-model="scheme.selectedAdvisingOrgs"
                multiple
                :options="filterOrganisationsAdvisingOptions"
                use-chips
                use-input
                option-value="name"
                option-label="name"
                stack-label
                label="Select advising organisation(s)"
                @filter="filterAdvisingOrganisationsFn"
                @update:model-value="setInput()"
                ref="advisingOrgsSelect"
              />
            </div>
            <div class="organisations-select">
              <q-select
                borderless
                bg-color="white"
                v-model="scheme.selectedRecipientOrgs"
                multiple
                :options="filterOrganisationsRecipientOptions"
                use-chips
                use-input
                option-value="name"
                option-label="name"
                stack-label
                label="Select recipient organisation(s)"
                @filter="filterRecipientOrganisationsFn"
                @update:model-value="setInput()"
                ref="recipientOrgsSelect"
              />
            </div>

            <div class="other-select">
              <q-select
                borderless
                bg-color="white"
                v-model="scheme.selectedScope"
                :options="scopes"
                option-value="name"
                option-label="name"
                stack-label
                label="Scope"
              />
            </div>

            <div class="other-select">
              <q-select
                borderless
                bg-color="white"
                v-model="scheme.selectedApplicationTerm"
                :options="applicationTerms"
                option-value="name"
                option-label="name"
                stack-label
                label="Application term"
              />
            </div>

            <div class="other-select">
              <q-select
                borderless
                bg-color="white"
                v-model="scheme.publicationStatus"
                :options="publicationStatusOptions"
                option-value="name"
                option-label="name"
                stack-label
                label="Publication status"
              />
            </div>
            <div class="other-select">
              <q-select
                borderless
                bg-color="white"
                v-model="scheme.status"
                :options="schemeStatusOptions"
                option-value="value"
                option-label="label"
                stack-label
                label="Status"
                emit-value
                map-options
              />
            </div>
          </div>

          <div class="scheme-checkboxes column" style="min-width: 240px">
            <q-checkbox
              v-model="scheme.isPortalScheme"
              label="Is portal scheme"
            />
            <q-checkbox
              v-model="scheme.isScienceScheme"
              label="Is science scheme"
            />
            <q-checkbox
              v-model="scheme.isEnglishScheme"
              label="Is scheme english"
            />
            <q-checkbox
              v-model="scheme.hasEnglishCalls"
              label="Has english calls"
            />
            <q-checkbox
              v-model="scheme.isAlwaysOpen"
              label="Is scheme always open"
            />

            <q-checkbox
              v-model="scheme.schemeIsOBScheme"
              label="Is OB scheme"
            />

            <q-input
              v-if="scheme.schemeIsOBScheme"
              borderless
              v-model="scheme.schemeOBTitle"
              bg-color="white"
              label="Scheme OB title"
              stack-label
              :rules="maxLengthRule"
              lazy-rules
              style="min-width: 240px"
            />
            <q-input
              v-if="scheme.schemeIsOBScheme"
              borderless
              v-model="scheme.schemeOBDescription"
              bg-color="white"
              label="Scheme OB description"
              stack-label
              lazy-rules
              style="min-width: 240px"
              type="textarea"
            />
          </div>
        </div>

        <q-separator class="q-mt-md q-mb-md" />
        <span class="input-title">
          <p class="section-lables q-mt-lg">
            Exclusions:
          </p>
        </span>
        <ckeditor
          :editor="editorRef.help.editor"
          v-model="scheme.exclusion"
          :config="editorRef.help.editorConfig"
        ></ckeditor>
        <q-separator class="q-mt-md q-mb-md" />

        <div class="links-list">
          <q-list bordered>
            <q-item-label class="tags-heading" header>
              Important links:
              <q-btn
                color="primary"
                :disable="loading"
                @click="
                  showAddLink = true;
                  showCategories = false;
                "
                dense
                no-caps
              >
                <i class="las la-1x la-plus-circle la-fw"></i>
                <q-tooltip>
                  Add new link
                </q-tooltip>
              </q-btn>
            </q-item-label>
            <q-item v-for="link in schemeImportantLinks" :key="link">
              <q-item-section avatar>
                <q-icon name="link" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="q-mt-xs link-text">
                  <span class="cursor-pointer">
                    <a :href="link.url" target="_blank">
                      {{ link.text + '   (' + link.url + ')' }}
                    </a>
                  </span>
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn
                  color="red"
                  size="12px"
                  flat
                  dense
                  round
                  icon="delete"
                  @click="removeLink(link)"
                >
                  <q-tooltip>
                    Remove link
                  </q-tooltip>
                </q-btn>
                <q-btn
                  @click="
                    showEditLink = true;
                    selectedLink = link;
                    showCategories = false;
                  "
                  dense
                  size="12px"
                  round
                  flat
                  color="indigo-10"
                  icon="las la-pencil-alt"
                >
                  <q-tooltip>
                    Edit link
                  </q-tooltip>
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>

      <div class="large-text q-pa-md">
        <div class="ictu-section q-pb-md">
          <span class="input-title">
            <p class="section-labels q-mt-lg">
              ICTU:
            </p>
          </span>

          <ICTUSection
            v-model:ICTUscheme="scheme.ICTUscheme"
            v-model:schemeMinimalAge="scheme.minimalAge"
            v-model:schemeMaximalAge="scheme.maximalAge"
            :educationLevelPairs="
              scheme.selectedTags.selectedSchemeEducationLevels
            "
            :userStatusPairs="scheme.selectedTags.selectedSchemeUserStatuses"
          />
        </div>

        <span class="input-title">
          <p class="section-labels q-mt-lg">
            Introduction:
          </p>
        </span>
        <q-input
          :rules="requiredFieldRule"
          class="textarea-input"
          v-model="scheme.newIntroduction"
          borderless
          bg-color="white"
          type="textarea"
        />

        <span class="input-title">
          <p class="section-labels q-mt-lg">
            Body:
          </p>
        </span>
        <ckeditor
          :editor="editorRef.help.editor"
          v-model="scheme.newBody"
          :config="editorRef.help.editorConfig"
        ></ckeditor>

        <span class="input-title">
          <p class="section-labels q-mt-lg">
            Application information:
          </p>
        </span>
        <ckeditor
          :editor="editorRef.help.editor"
          v-model="scheme.applicationInformation"
          :config="editorRef.help.editorConfig"
        ></ckeditor>

        <span class="input-title">
          <p class="section-labels q-mt-lg">
            Application procedure:
          </p>
        </span>
        <div class="introduction-input">
          <q-input
            class="textarea-input applicationprocedure-textarea"
            v-model="scheme.applicationProcedure"
            borderless
            bg-color="white"
            type="textarea"
          />
        </div>

        <span class="input-title">
          <p class="section-labels q-mt-lg">
            Criteria:
          </p>
        </span>
        <ckeditor
          :editor="editorRef.help.editor"
          v-model="scheme.newCriteria"
          :config="editorRef.help.editorConfig"
        ></ckeditor>

        <span class="input-title">
          <p class="section-labels q-mt-lg">
            Budget:
          </p>
        </span>
        <q-input
          class="textarea-input budget-textarea"
          v-model="scheme.newBudget"
          borderless
          bg-color="white"
          type="textarea"
        />

        <span class="input-title">
          <p class="section-labels" style="margin-top: 92px">
            Financial information:
          </p>
        </span>
        <div class="introduction-input">
          <ckeditor
            :editor="editorRef.help.editor"
            v-model="scheme.financialInformation"
            :config="editorRef.help.editorConfig"
          ></ckeditor>
        </div>

        <span class="input-title">
          <p class="section-labels q-mt-lg">
            Portal body:
          </p>
        </span>
        <div class="introduction-input">
          <ckeditor
            :editor="editorRef.help.editor"
            v-model="scheme.portalBody"
            :config="editorRef.help.editorConfig"
          ></ckeditor>
        </div>

        <div class="introduction-input q-mt-md" style="background:white">
          <q-list bordered separator class="textarea-input">
            <q-item-label class="tags-heading" header>
              <div class="q-gutter-xs">
                Documents and other links:
                <q-btn
                  color="primary"
                  :disable="loading"
                  @click="
                    showAddLink = true;
                    showCategories = true;
                  "
                  dense
                  no-caps
                >
                  <i class="las la-1x la-link la-fw"></i>
                  <q-tooltip>
                    Add new link
                  </q-tooltip>
                </q-btn>
                <q-btn
                  color="primary"
                  :disable="loading"
                  @click="showAddDocument = true"
                  dense
                  no-caps
                >
                  <i class="las la-1x la-paperclip la-fw"></i>
                  <q-tooltip>
                    Add new document
                  </q-tooltip>
                </q-btn>
              </div>
            </q-item-label>
            <q-item
              v-ripple
              v-for="(doc, index) in selectedFiles"
              :key="doc"
              class="textarea-input"
            >
              <q-item-section side style="width:150px;">
                {{ getDocTypeDescription(doc.documentType) }}
              </q-item-section>
              <q-item-section avatar>
                <i
                  :class="iconBasedOnExtension(getExtension(doc.file.name))"
                  style="color: blue;"
                ></i>
              </q-item-section>
              <q-item-section v-if="doc.name !== ''">{{
                nameSubstring(doc.name)
              }}</q-item-section>
              <q-item-section v-else>{{
                nameSubstring(doc.file.name)
              }}</q-item-section>
              <q-item-section top side>
                <q-btn
                  class="gt-xs"
                  size="12px"
                  flat
                  dense
                  round
                  icon="delete"
                  color="red"
                  @click="selectedFiles.splice(index, 1)"
                >
                  <q-tooltip>Remove file</q-tooltip>
                </q-btn>
              </q-item-section>
              <q-item-section top side>
                <q-btn
                  class="gt-xs"
                  size="12px"
                  flat
                  dense
                  round
                  icon="las la-pencil-alt"
                  color="indigo-10"
                  @click="
                    showEditDocument = true;
                    selectedDocument = doc;
                  "
                >
                  <q-tooltip>Edit document</q-tooltip>
                </q-btn>
              </q-item-section>
              <q-tooltip v-if="doc.name !== ''">{{ doc.name }}</q-tooltip>
              <q-tooltip v-else>{{ doc.file.name }}</q-tooltip>
            </q-item>
            <q-item
              v-ripple
              v-for="link in schemeOtherLinks"
              :key="link"
              class="textarea-input"
            >
              <q-item-section side style="width:150px;">
                {{ getDocTypeDescription(link.documentType) }}
              </q-item-section>
              <q-item-section avatar>
                <i class="las la-2x la-link" style="color: blue;"></i>
              </q-item-section>
              <q-item-section>
                <a :href="link.url" target="_blank">
                  {{ link.text + '   (' + link.url + ')' }}
                </a>
              </q-item-section>
              <q-item-section top side>
                <q-btn
                  class="gt-xs"
                  size="12px"
                  flat
                  dense
                  round
                  icon="delete"
                  color="red"
                  @click="removeLink(link)"
                >
                  <q-tooltip>Remove link</q-tooltip>
                </q-btn>
              </q-item-section>
              <q-item-section top side>
                <q-btn
                  class="gt-xs"
                  size="12px"
                  flat
                  dense
                  round
                  icon="las la-pencil-alt"
                  color="indigo-10"
                  @click="
                    showEditLink = true;
                    selectedLink = link;
                    showCategories = true;
                  "
                >
                  <q-tooltip>Edit link</q-tooltip>
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
        <SelectedTagsCreateScheme
          :selectedAudience="scheme.selectedTags.selectedAudiences"
          :selectedPurposes="scheme.selectedTags.selectedPurposes"
          :selectedLocations="scheme.selectedTags.selectedLocations"
          :selectedSchemeTypes="scheme.selectedTags.selectedSchemeTypes"
          :selectedIndustriesNew="scheme.selectedTags.selectedIndustriesNew"
          :selectedProjectTypes="scheme.selectedTags.selectedProjectTypes"
          :selectedKeywords="scheme.selectedTags.selectedSchemeKeywords"
          :selectedGroups="scheme.selectedGroups"
        />
        <div class="q-pt-md">
          <SchemeUpdates
            :schemeUpdates="scheme.schemeUpdates"
            action="Adding"
          />
        </div>
      </div>
      <div class="row justify-end q-mr-md" style="width:100%">
        <div class="column">
          <q-btn
            class="orange-btn-filled q-mb-lg q-mt-lg bold-font"
            label="Submit"
            @click="createNewScheme()"
            no-caps
          />
        </div>
      </div>
    </div>
  </div>
  <AddLink
    :selectedSchemeLinks="scheme.schemeLinks"
    :showCategories="showCategories"
  />
  <EditLink v-if="selectedLink" :showCategories="showCategories" />
  <EditDocument v-if="selectedDocument" />
  <AddFile :selectedSchemeDocuments="selectedFiles" />
</template>

<script>
import { ref, reactive, onMounted, watch, computed } from 'vue';
import router from '../router/index';
import ModalError from '../components/schemes-components/SchemeModalError.vue';
import SchemeServices from '../services/schemes.services';
import OrganisationsService from '../services/organisations.service';
import store from '../store/index';
import TagsService from '../services/tags.service';
import schemesServices from '../services/schemes.services';
import moment from 'moment';
import ICTUSection from '../components/schemes-components/ICTUSection.vue';
import SchemeUpdates from '../components/schemes-components/SchemeUpdates.vue';
import SelectedTagsCreateScheme from '../components/schemes-components/SelectedTagsCreateScheme.vue';
import AddLink from '../components/schemes-components/AddLink.vue';
import EditLink from '../components/schemes-components/EditLink.vue';
import AddFile from '../components/schemes-components/AddFile.vue';
import EditDocument from '../components/schemes-components/EditDocument.vue';
import help from '../helpers/editor';
import ETagType from '../enums/ETagType';
import EDocumentType from '../enums/EDocumentType';

export default {
  name: 'CreateScheme',
  components: {
    ModalError,
    ICTUSection,
    SchemeUpdates,
    SelectedTagsCreateScheme,
    AddLink,
    EditLink,
    EditDocument,
    AddFile,
  },
  setup() {
    const showCategories = ref(false);

    const showAddLink = computed({
      get: () => store.state.scheme.showAddLink,
      set: (value) => store.commit('scheme/updateShowAddLink', value),
    });

    const showEditLink = computed({
      get: () => store.state.scheme.showEditLink,
      set: (value) => store.commit('scheme/updateShowEditLink', value),
    });

    const locationGroups = computed({
      get: () => store.state.tree.locationGroupsSelected,
    });
    const showAddDocument = computed({
      get: () => store.state.scheme.showAddDocument,
      set: (value) => store.commit('scheme/updateShowAddDocument', value),
    });

    const showEditDocument = computed({
      get: () => store.state.scheme.showEditDocument,
      set: (value) => store.commit('scheme/updateShowEditDocument', value),
    });
    const locationTagsSelectedIds = computed(
      () => store.state.scheme.locationTagsSelectedIds
    );
    let selectedFiles = ref([]);

    const selectedLink = computed({
      get: () => store.state.scheme.selectedLink,
      set: (value) => store.commit('scheme/setSelectedLink', value),
    });

    const selectedDocument = computed({
      get: () => store.state.scheme.selectedDocument,
      set: (value) => store.commit('scheme/setSelectedDocument', value),
    });
    const editorRef = help;
    const scheme = reactive({
      newName: '',
      identifier: '',
      newBody: '',
      newCriteria: '',
      newBudget: '',
      startDate: ref(moment().format('DD-MM-YYYY')),
      endDate: ref(moment().format('DD-MM-YYYY')),
      selectedFundingOrgs: null,
      selectedAdministratingOrgs: null,
      selectedAdvisingOrgs: null,
      selectedRecipientOrgs: null,
      selectedSME: '',
      selectedScope: '',
      selectedApplicationTerm: '',
      status: 5,
      publicationStatus: '',
      newIntroduction: '',
      newAccronym: '',
      newArchiveCode: '',
      comlexityRating: 0,
      chanceOfSuccessRating: 0,
      timeForApplicationRating: 0,
      minAmount: 0,
      maxAmount: 0,
      exclusion: '',
      financialInformation: '',
      applicationProcedure: '',
      applicationInformation: '',
      schemeIsOBScheme: false,
      schemeOBTitle: '',
      schemeOBDescription: '',
      portalBody: '',
      schemeLinks: [],
      schemeUpdates: [],
      selectedTags: {
        selectedAudiences: [],
        selectedPurposes: [],
        selectedLocations: [],
        //This is just hidden for now, don't remove it
        //selectedIndustries: [],
        selectedSchemeTypes: [],
        selectedProjectTypes: [],
        selectedSchemeKeywords: [],
        selectedSchemeEducationLevels: [],
        selectedSchemeUserStatuses: [],
        selectedIndustriesNew: [],
      },
      selectedGroups: locationGroups.value,
      locationTags: computed(() => locationTagsSelectedIds.value),
      newDuration: '',
      ICTUscheme: false,
      minmimalAge: null,
      maximalAge: null,
      isPortalScheme: false,
      isScienceScheme: false,
      isEnglishScheme: false,
      hasEnglishCalls: false,
      isAlwaysOpen: false,
    });

    watch(locationGroups, (newValue) => {
      scheme.selectedGroups = newValue;
    });

    const allOrganisations = computed({
      get: () => store.state.organisation.organisationsList,
    });

    const filterOrganisationsFundingOptions = ref(allOrganisations.value);
    const filterOrganisationsAdministratingOptions = ref(
      allOrganisations.value
    );
    const filterOrganisationsAdvisingOptions = ref(allOrganisations.value);
    const filterOrganisationsRecipientOptions = ref(allOrganisations.value);

    const smeTypes = computed({
      get: () => store.state.scheme.smeTypes,
    });

    const scopes = computed({
      get: () => store.state.scheme.scopes,
    });

    const applicationTerms = computed({
      get: () => store.state.scheme.applicationTerms,
    });

    const publicationStatusOptions = computed({
      get: () => store.state.scheme.publicationStatuses,
    });

    onMounted(async () => {
      await OrganisationsService.getAllOrganisationsList();
      await TagsService.getTagTypeTree('User Status', ETagType.UserStatus);
      await TagsService.getTagTypeTree(
        'Education Level',
        ETagType.EducationLevel
      );
      schemesServices.getSMETypes();
      schemesServices.getScopes();
      schemesServices.getTerms();
      schemesServices.getDocumentTypes();
      await schemesServices.getPublicationStatuses();
      store.commit('scheme/setEducationLevelTagsIds', []);
      store.commit('scheme/setUserStatusTagsIds', []);
      store.commit('tree/setLocationGroupsSelected', []);
      store.commit("scheme/updateLocationTagsSelectedBulk", []);
      schemesServices.getSchemeStatuses();

    });
    const schemeStatusOptions = computed({
      get: () => store.state.scheme.schemeStatuses
    });
    const documentTypes = computed({
      get: () => store.state.scheme.documentTypes
    });
    const getDocTypeDescription = (type) => {
      return documentTypes.value.find(({ id }) => id === type).description;
    };

    const createNewScheme = async () => {
      if (!scheme.selectedTags.selectedLocations.length > 0) {
        store.commit('scheme/enableModalError');
        store.commit(
          'scheme/updateModalError',
          'Please select at least one location tag.'
        );
      } else if (selectedFiles.value.length > 0) {
        let fd = new FormData();

        //Even though the selectedFiles variable contains all the selected files, it is not an array. So, in order to use the map function, we are using the
        //Array.from()syntax, which will convert the array-like object into the new array copy.

        Array.from(selectedFiles.value).map(
          ({ file, name, documentType }, index) => {
            fd.append('file' + index, file);
            fd.append('newnamefile' + index, name);
            fd.append('documenttypefile' + index, documentType);
            return fd;
          }
        );
        await SchemeServices.createNewScheme(scheme, fd);
      } else await SchemeServices.createNewScheme(scheme, null);   
    };

    const nameSubstring = (name) => {
      return String(name).slice(0, 150);
    };

    const getExtension = (fileName) => {
      return fileName.split('.').pop();
    };

    const schemeImportantLinks = computed(() => {
      return scheme.schemeLinks.filter(
        (el) => el.documentType === EDocumentType.UsefulLinks || el.documentType === null
      );
    });

    const schemeOtherLinks = computed(() => {
      return scheme.schemeLinks.filter(
        (el) =>
          el.documentType !== EDocumentType.UsefulLinks &&
          el.documentType !== null &&
          el.documentType !== EDocumentType.VisualPingLink
      );
    });

    const removeLink = (link) => {
      const index = scheme.schemeLinks.indexOf(link);

      if (index > -1) scheme.schemeLinks.splice(index, 1);
    };

    const areSchemeChangesLoading = computed({
      get: () => store.state.scheme.areSchemeChangesLoading,
    });

    const fundingOrgSelect = ref(null);
    const adminOrgsSelect = ref(null);
    const advisingOrgsSelect = ref(null);
    const recipientOrgsSelect = ref(null);

    const setInput = () => {
      fundingOrgSelect.value.updateInputValue('');
      adminOrgsSelect.value.updateInputValue('');
      advisingOrgsSelect.value.updateInputValue('');
      recipientOrgsSelect.value.updateInputValue('');
    };

    return {
      locationTagsSelectedIds,
      adminOrgsSelect,
      advisingOrgsSelect,
      recipientOrgsSelect,
      setInput,
      fundingOrgSelect,
      areSchemeChangesLoading,
      locationGroups,
      selectedFiles,
      selectedDocument,
      showAddLink,
      publicationStatusOptions,
      schemeStatusOptions,
      createNewScheme,
      scheme,
      router,
      smeTypes,
      allOrganisations,
      filterOrganisationsFundingOptions,
      filterOrganisationsAdministratingOptions,
      filterOrganisationsAdvisingOptions,
      filterOrganisationsRecipientOptions,
      showEditLink,
      selectedLink,
      nameSubstring,
      getExtension,
      schemeImportantLinks,
      schemeOtherLinks,
      editorRef,
      removeLink,
      showAddDocument,
      showEditDocument,
      documentTypes,
      getDocTypeDescription,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || 'Field is required!',
      ],
      scopes,
      applicationTerms,
      showCategories,
      maxLengthRule: [
        (val) => val.length < 100 || 'Max length is 100 characters!',
      ],

      startDateOptionsFn(date) {
        if (
          scheme.endDate != null &&
          scheme.endDate != '' &&
          scheme.endDate.length > 0
        ) {
          return (
            date <= moment(scheme.endDate, 'DD-MM-YYYY').format('YYYY/MM/DD')
          );
        }

        return true;
      },
      optionsFn(date) {
        if (
          scheme.startDate != null &&
          scheme.startDate != '' &&
          scheme.startDate.length > 0
        ) {
          return (
            date >= moment(scheme.startDate, 'DD-MM-YYYY').format('YYYY/MM/DD')
          );
        }

        return true;
      },
      filterFundingOrganisationsFn(val, update) {
        update(() => {
          if (val === '') {
            filterOrganisationsFundingOptions.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();
            filterOrganisationsFundingOptions.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterAdministratingOrganisationsFn(val, update) {
        update(() => {
          if (val === '') {
            filterOrganisationsAdministratingOptions.value =
              allOrganisations.value;
          } else {
            const needle = val.toLowerCase();
            filterOrganisationsAdministratingOptions.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterAdvisingOrganisationsFn(val, update) {
        update(() => {
          if (val === '') {
            filterOrganisationsAdvisingOptions.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();
            filterOrganisationsAdvisingOptions.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterRecipientOrganisationsFn(val, update) {
        update(() => {
          if (val === '') {
            filterOrganisationsRecipientOptions.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();
            filterOrganisationsRecipientOptions.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      iconBasedOnExtension(extension) {
        switch (extension) {
          case 'pdf':
            return 'las la-2x la-file-pdf';
          case 'doc':
            return 'las la-2x la-file-word';
          case 'docx':
            return 'las la-2x la-file-word';
          case 'xls':
            return 'las la-2x la-file-excel';
          case 'xlsx':
            return 'las la-2x la-file-excel';
          case 'csv':
            return 'las la-2x la-file-csv';
          case 'txt':
            return 'las la-2x la-file';
          default:
            return 'las la-2x la-file';
        }
      },
    };
  },
};
</script>
