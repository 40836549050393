<template>
  <div class="portal-stats-page">
    <div class="row page-heading">
      <h2 class="title">Portal stats</h2>
      <div class="row">
        <router-link :to="{ name: 'GeneralStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('general')">
            General stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'UserStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('user')">
            User stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'PortalStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('portal')">
            Portal stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'ConsumptionStats' }" class="page-link">
          <q-btn
            flat
            class="btn-navigation"
            :class="isUnderlined('consumption')"
          >
            Consumption stats
          </q-btn>
        </router-link>
        <!--   <router-link :to="{ name: 'OnzeStats' }" class="page-link">
          <q-btn flat class="btn-navigation" :class="isUnderlined('onze')">
            ONZE stats
          </q-btn>
        </router-link> -->
      </div>
    </div>

    <div class="row">
      <div class="column statfilters-area">
        <div class="stats-filters column">
          <div class="search-button">
            <p class="warning">
              Select filter(s) to show data
            </p>
            <q-btn
              flat
              no-caps
              class="orange-btn bold-font"
              label="Search"
              @click="fetchData()"
            />
          </div>
          <div class="stats-filters">
            <div class="filter-box">
              <q-select
                class="filter-select"
                borderless
                bg-color="white"
                v-model="modelYearFrom"
                :options="optionsYearsFrom"
                label="Year from"
              />
            </div>
            <div class="filter-box">
              <q-select
                class="filter-select"
                borderless
                bg-color="white"
                v-model="modelMonthFrom"
                :options="optionsMonths"
                label="Month from"
                option-value="id"
                option-label="name"
              >
              </q-select>
            </div>
          </div>
          <div class="stats-filters">
            <div class="filter-box">
              <q-select
                class="filter-select"
                borderless
                bg-color="white"
                v-model="modelYearTo"
                :options="optionsYearsTo"
                label="Year to"
              />
            </div>
            <div class="filter-box">
              <q-select
                class="filter-select"
                borderless
                bg-color="white"
                v-model="modelMonthTo"
                :options="optionsMonths"
                label="Month to"
                option-value="id"
                option-label="name"
              >
              </q-select>
            </div>
          </div>
          <div class="filter-box  q-mt-md">
            <q-select
              class="filter-select"
              multiple
              borderless
              bg-color="white"
              v-model="selectedCustomers"
              :options="filteredCustomersList"
              label="Customer(s)"
              option-value="id"
              option-label="name"
              @filter="filterCustomersFn"
              use-input
              input-debounce="0"
              behavior="menu"
              ref="customersMultiselect"
              @update:model-value="setInput()"
              clearable
              use-chips
              @popup-show="width = 200"
              :popup-content-style="`width: 200px; word-break: break-all;`"
              style="word-break: break-all;"
              emit-value
              map-options
            />
          </div>

          <div class="filter-box q-mt-sm">
            <q-select
              class="filter-select"
              multiple
              borderless
              bg-color="white"
              v-model="selectedPortalUsers"
              :options="filteredPortalUsersList"
              label="User(s)"
              option-value="id"
              option-label="username"
              @filter="filterPortalUsersFn"
              use-input
              input-debounce="0"
              behavior="menu"
              ref="portalUsersMultiSelect"
              @update:model-value="setInputPortalUsers()"
              clearable
              use-chips
              @popup-show="width = 200"
              :popup-content-style="`width: 200px; word-break: break-all;`"
              style="word-break: break-all;"
              emit-value
              map-options
            />
          </div>
        </div>
      </div>

      <div v-if="isLoading" style="text-align: center; ">
        <span style="display: inline-block">
          <q-spinner-bars color="primary" size="2em" />
        </span>
      </div>
      <div class="allData" style="width:30%; margin-left: 2vw" v-else>
        <div class="data-container column" v-if="isShowing">
          <div class="row stats-boxes" style="width: max-content">
            <div class="stat-section">
              <span class="stat-section-data">
                {{ registeredUsers ? registeredUsers : 0 }}
              </span>
              <span class="stat-section-text">registered users</span>
            </div>
            <div class="stat-section">
              <span class="stat-section-data">
                {{
                  registeredUsersInTimeFrame ? registeredUsersInTimeFrame : 0
                }}
              </span>
              <span class="stat-section-text">new registered users</span>
            </div>
            <div class="stat-section">
              <span class="stat-section-data">
                {{
                  usersWithLoginInLast60Days ? usersWithLoginInLast60Days : 0
                }}
              </span>
              <span class="stat-section-text"
                >user with login in last 60 days</span
              >
            </div>
            <div class="stat-section">
              <span class="stat-section-data">
                {{ usersThatHaveRevisited ? usersThatHaveRevisited : 0 }}
              </span>
              <span class="stat-section-text">revisits</span>
            </div>
          </div>
          <div class="row graphs-container q-mt-md" v-if="isShowing">
            <PortalUsersGraph class="graph" />
            <PortalVisitsGraph class="graph-visits" />
          </div>
        </div>
        <div class="column tables q-mb-lg q-mt-md" v-if="isShowing">
          <PortalStatsTableExportable style="width: 70vw" />
          <div class="bar-graph-wrapper">
            <PortalBarGraphMain class="scheme-visits-bar" style="width: 70vw" />
          </div>
          <div class="bar-graph-wrapper">
            <SchemeVisitsBar class="scheme-visits-bar" style="width: 70vw" />
          </div>
          <div class="bar-graph-wrapper q-mt-lg">
            <SchemePurposesBar class="scheme-visits-bar" style="width: 70vw" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import StatsService from "../../services/stats.service";
import CustomersService from "../../services/customers.service";
import store from "../../store/index";
import PortalUsersGraph from "./Portal/PortalUsersGraph.vue";
import PortalVisitsGraph from "./Portal/PortalVisitsGraph.vue";
import SchemeVisitsBar from "./Portal/SchemeVisitsBar.vue";
import SchemePurposesBar from "./Portal/SchemePurposesBar.vue";
import PortalBarGraphMain from "./Portal/PortalBarGraphMain.vue";
import PortalStatsTableExportable from "./Portal/PortalStatsTableExportable.vue";
import { useRoute } from "vue-router";
import moment from "moment";
export default {
  name: "PortalStats",
  components: {
    PortalUsersGraph,
    PortalVisitsGraph,
    SchemeVisitsBar,
    SchemePurposesBar,
    PortalBarGraphMain,
    PortalStatsTableExportable,
  },
  setup() {
    const isLoading = ref(false);
    const isShowing = ref(false);
    const route = useRoute();
    onMounted(async () => {
      store.commit("portalStats/updateYearsOptions");
      let thisMonth = new Date().getMonth();
      let date1 = new Date();
      let date2 = new Date();
      let date3 = new Date();
      thisMonth;
      date1.setMonth(thisMonth - 1, 0);
      date2.setMonth(thisMonth, 0);
      date3.setMonth(thisMonth + 1, 0);

      if (date3.getMonth() == 0 || date3.getMonth() == 1) {
        store.commit("portalStats/updateYearFrom", moment().year() - 1);
      }

      store.commit("portalStats/updateYearsToOptions");

      store.commit("portalStats/updateMonthTo", {
        id: date3.getMonth(),
        name: date3.toLocaleString("nl-NL", { month: "long" }),
      });

      store.commit("portalStats/updateMonthFrom", {
        id: date1.getMonth(),
        name: date1.toLocaleString("nl-NL", { month: "long" }),
      });

      await CustomersService.getPortalCustomers();
      await StatsService.getPortalUsers();
      await StatsService.getPortalBarGraphMain();
      store.commit("portalStats/resetFilters");
    });

    const setInput = () => {
      customersMultiselect.value.updateInputValue("");
    };

    const portalUsersMultiSelect = ref(null);

    const setInputPortalUsers = () => {
      portalUsersMultiSelect.value.updateInputValue("");
    };

    const customersList = computed({
      get: () => store.state.customers.portalCustomersList,
    });
    const filteredCustomersList = ref(customersList.value);

    const optionsYears = [2023, 2022, 2021, 2020, 2019, 2018, 2017];

    const selectedMonthsIds = computed({
      get: () => store.state.portalStats.selectedMonthsIds,
    });

    const selectedCustomers = computed({
      get: () => store.state.portalStats.selectedCustomers,
      set: async (val) => {
        store.commit("portalStats/updateSelectedCustomers", val);
      },
    });

    const customersMultiselect = ref(null);

    const registeredUsers = computed({
      get: () => store.state.portalStats.registeredUsers,
    });

    const registeredUsersInTimeFrame = computed({
      get: () => store.state.portalStats.registeredUsersInTimeFrame,
    });
    const usersThatHaveRevisited = computed({
      get: () => store.state.portalStats.usersThatHaveRevisited,
    });
    const usersWithLoginInLast60Days = computed({
      get: () => store.state.portalStats.usersWithLoginInLast60Days,
    });

    const selectedMonthsIdsServer = computed({
      get: () => store.state.portalStats.selectedMonthsIdsServer,
    });

    const modelMonthIdFrom = computed({
      get: () => store.state.portalStats.monthFromId,
    });

    const modelMonthIdTo = computed({
      get: () => store.state.portalStats.monthToId,
    });

    const optionsYearsFrom = computed({
      get: () => store.state.portalStats.optionsYearsFrom,
    });

    const optionsYearsTo = computed({
      get: () => store.state.portalStats.optionsYearsTo,
    });

    const modelYearFrom = computed({
      get: () => store.state.portalStats.yearFrom,
      set: async (val) => {
        store.commit("portalStats/updateYearFrom", val);
        store.commit("portalStats/updateYearsToOptions");
      },
    });

    const modelYearTo = computed({
      get: () => store.state.portalStats.yearTo,
      set: async (val) => {
        store.commit("portalStats/updateYearTo", val);
      },
    });

    const modelMonthFrom = computed({
      get: () => store.state.portalStats.monthFrom,
      set: async (val) => {
        store.commit("portalStats/updateMonthFrom", val);
      },
    });

    const modelMonthTo = computed({
      get: () => store.state.portalStats.monthTo,
      set: async (val) => {
        store.commit("portalStats/updateMonthTo", val);
      },
    });

    const portalUsersList = computed({
      get: () => store.state.portalStats.portalUsers,
    });

    const filteredPortalUsersList = ref(portalUsersList.value);

    const selectedPortalUsers = computed({
      get: () => store.state.portalStats.selectedPortalUsers,
      set: async (val) => {
        store.commit("portalStats/updateSelectedPortalUsers", val);
      },
    });

    const fetchData = async () => {
      isShowing.value = true;
      isLoading.value = true;
      await StatsService.getPortalUsersGraph(
        modelYearFrom,
        modelYearTo,
        modelMonthIdFrom,
        modelMonthIdTo,
        selectedCustomers,
        selectedPortalUsers
      );
      await StatsService.getPortalVisitsGraph(
        modelYearFrom,
        modelYearTo,
        modelMonthIdFrom,
        modelMonthIdTo,
        selectedCustomers,
        selectedPortalUsers
      );
      await StatsService.getPortalBoxesData(
        modelYearFrom,
        modelYearTo,
        modelMonthIdFrom,
        modelMonthIdTo,
        selectedCustomers,
        selectedPortalUsers
      );
      await StatsService.getPortalSchemeVisitsTable(
        modelYearFrom,
        modelYearTo,
        modelMonthIdFrom,
        modelMonthIdTo,
        selectedCustomers,
        selectedPortalUsers
      );
      await StatsService.getPortalSchemePurposesCounts(
        modelYearFrom,
        modelYearTo,
        modelMonthIdFrom,
        modelMonthIdTo,
        selectedCustomers,
        selectedPortalUsers
      );

      await StatsService.getPortalUsersTableExportable(selectedCustomers);
      isLoading.value = false;
    };

    return {
      setInputPortalUsers,
      portalUsersMultiSelect,
      selectedPortalUsers,
      filteredPortalUsersList,
      portalUsersList,
      modelYearFrom,
      route,
      modelYearTo,
      modelMonthIdFrom,
      modelMonthIdTo,
      isLoading,
      isShowing,
      fetchData,
      modelMonthTo,
      modelMonthFrom,
      optionsYearsFrom,
      optionsYearsTo,
      selectedMonthsIdsServer,
      registeredUsers,
      registeredUsersInTimeFrame,
      usersThatHaveRevisited,
      usersWithLoginInLast60Days,
      selectedMonthsIds,
      customersList,
      optionsYears,
      filteredCustomersList,
      selectedCustomers,
      setInput,
      customersMultiselect,
      filterCustomersFn(val, update) {
        update(() => {
          if (val === "") {
            filteredCustomersList.value = customersList.value;
          } else {
            const needle = val.toLowerCase();

            filteredCustomersList.value = customersList.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterPortalUsersFn(val, update) {
        update(() => {
          if (val === "") {
            filteredPortalUsersList.value = portalUsersList.value;
          } else {
            const needle = val.toLowerCase();

            filteredPortalUsersList.value = portalUsersList.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      optionsMonths: [
        { id: 0, name: "January" },
        { id: 1, name: "February" },
        { id: 2, name: "March" },
        { id: 3, name: "April" },
        { id: 4, name: "May" },
        { id: 5, name: "June" },
        { id: 6, name: "July" },
        { id: 7, name: "August" },
        { id: 8, name: "September" },
        { id: 9, name: "October" },
        { id: 10, name: "November" },
        { id: 11, name: "December" },
      ],
    };
  },
  methods: {
    isUnderlined(buttonRoute) {
      if (this.route.fullPath.includes(buttonRoute))
        return "btn-nav-underlined";
      return "";
    },
  },
};
</script>
