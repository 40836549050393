<template>
  <Pie />
</template>

<script>
import { h, computed } from "vue";
import store from "../../../store/index";
import { Pie } from "vue-chartjs";
import CustomersService from "../../../services/customers.service";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "PieChart",
  components: {
    Pie,
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
  },
  setup(props) {
    const colors = [
      "#003f5c",
      "#2f4b7c",
      "#4c699c",
      "#665191",
      "#a05195",
      "#d45087",
      "#f95d6a",
      "#fcc360",
      "#ff7c43",
      "#ffa600",
    ];

    async function handleLegendClick(clickedLabel) {
      if (clickedLabel == "Not filled") {
        clickedLabel = "Undefined";
      }
      await CustomersService.getCustomersByType(clickedLabel);
    }

    const chartDataStore = computed(() => {
      let data = store.state.generalStats.pieChartData;
      data.labels = data.labels.map((label, i) => ({
        label,
        value: data.datasets[0].data[i],
      }));
      data.labels.sort((a, b) => b.value - a.value);
      data.datasets[0].data = data.labels.map((item) => item.value);
      data.labels = data.labels.map((item) => item.label);
      data.datasets[0].backgroundColor = data.datasets[0].data.map(
        (_, i) => colors[i % colors.length]
      );
      return data;
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          fullSize: false,
          maxWidth: 340,
        },
      },
      onClick: (evt, el, chart) => {
        let point = chart.getElementsAtEventForMode(
          evt,
          "point",
          {
            intersect: true,
          },
          true
        );
        var clickeditemLabel = point[0].index;
        handleLegendClick(chartDataStore.value.labels[clickeditemLabel]);
      },
    };

    return () =>
      h(Pie, {
        chartData: chartDataStore.value,
        chartOptions,
        chartId: props.chartId,
        width: props.width,
        height: props.height,
        cssClasses: props.cssClasses,
        styles: props.styles,
        plugins: props.plugins,
      });
  },
};
</script>
