
const state = () => ({
environmentTypes : []
});

const getters = {};

const actions = {};

const mutations = {
  updateEnvironmentTypesList(state, list){
    state.environmentTypes = list;
 },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
