import './styles/quasar.scss'
import "quasar/dist/quasar.sass"
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/line-awesome/line-awesome.css';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    extras: [
      'line-awesome',
    ]
  }
}