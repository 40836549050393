<template>
  <NavMenu />
  <router-view :key="$route.path" />
</template>

<style lang="scss" src="./content/sass/site.scss"></style>

<script>
import { ref } from 'vue';
import NavMenu from './components/NavMenu.vue';

export default {
  name: 'LayoutDefault',

  components: {
    NavMenu,
  },

  setup() {
    return {
      leftDrawerOpen: ref(false),
    };
  },
};
</script>
