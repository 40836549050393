import axios from 'axios';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
import axiosUrl from './axios.service';
import router from '../router/index';
import moment from 'moment';
import AuthService from './auth.service';

const API_URL = axiosUrl.getAxiosUrl();

class SchemesServices {
  async getSearchResults(
    searchText,
    // statusBoxes,
    audiences,
    locations,
    germanLocationsPaths,
    //This is just hidden for now, don't remove it
    //industries,
    purposes,
    schemeTypes,
    projectTypes,
    smeString,
    applicationTermsString,
    minBudget,
    maxBudget,
    scopesString,
    orgsInclude,
    orgsExclude,
    startDate,
    endDate,
    page = 0,
    pageSize = 5000,
    schemeStatusGroup
  ) {
    return await axios
      .get(API_URL + 'search/searchschemes', {
        headers: authHeader(),
        params: {
          Term: searchText.value,
          PageSize: pageSize,
          Page: page,
          Sort: 'Relevantie',
          // Statusses: statusBoxes.value,
          Statusses: schemeStatusGroup,
          AudienceTags: audiences.value,
          //This is just hidden for now, don't remove it
          //IndustriesTags: industries.value,
          PurposesTags: purposes.value,
          SchemeTypesTags: schemeTypes.value,
          ProjectTypesTags: projectTypes.value,
          LocationsTags:
            locations.value != null && locations.value.length > 0
              ? locations.value.join()
              : null,
          GermanLocationsTags:
            germanLocationsPaths.value != null &&
            germanLocationsPaths.value.length > 0
              ? germanLocationsPaths.value.join()
              : null,
          AppliesToSME: smeString.value,
          ApplicationTerms: applicationTermsString.value,
          MinAmount: minBudget.value != 0 ? minBudget.value : null,
          MaxAmount: maxBudget.value != 0 ? maxBudget.value : null,
          Scopes: scopesString.value,
          NotOrganisations:
            orgsExclude.value.length > 0 ? orgsExclude.value.join() : null,
          Organisations:
            orgsInclude.value.length > 0 ? orgsInclude.value.join() : null,
          CallStartDate:
            startDate.value != null &&
            startDate.value != '' &&
            startDate.value != '""'
              ? moment(startDate.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          CallEndDate:
            endDate.value != null &&
            endDate.value != '' &&
            endDate.value != '""'
              ? moment(endDate.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
        },
      })
      .then((response) => {
        store.commit('advancedSearch/updateCurrentPage', 1);
        store.commit('scheme/updateAllSchemes', response.data);
        store.commit('schemeItems/updateGrants', response.data);
        store.commit('advancedSearch/updatePageCount', response.data.pageCount);
        store.commit(
          'advancedSearch/updateResultsCount',
          response.data.resultCount
        );
        store.commit('scheme/updatePagination', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }
  async getAllSchemes(page, dateFrom, dateTo) {
    return await axios
      .get(API_URL + 'search/searchschemes', {
        params: {
          pageSize: 7,
          page: page - 1,
          DateFrom: moment(dateFrom).format('YYYY-MM-DD'),
          DateTo: moment(dateTo).format('YYYY-MM-DD'),
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/updateAllSchemes', response.data);
        store.commit('scheme/updatePagination', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }
  async getResulstsByPage(
    current,
    searchText,
    //  statusBoxes,
    audiences,
    locations,
    germanLocationsPaths,
    //This is just hidden for now, don't remove it
    //industries,
    purposes,
    schemeTypes,
    projectTypes,
    smeString,
    applicationTermsString,
    minBudget,
    maxBudget,
    scopesString,
    orgsInclude,
    orgsExclude,
    startDate,
    endDate,
    page = 0,
    pageSize = 7,
    schemeStatusGroup
  ) {
    return await axios
      .get(API_URL + 'search/searchschemes', {
        headers: authHeader(),
        params: {
          Term: searchText.value,
          //Statusses: statusBoxes.value,
          Statusses: schemeStatusGroup,
          AudienceTags: audiences.value,
          //This is just hidden for now, don't remove it
          //IndustriesTags: industries.value,
          PurposesTags: purposes.value,
          SchemeTypesTags: schemeTypes.value,
          ProjectTypesTags: projectTypes.value,
          LocationsTags:
            locations.value != null && locations.value.length > 0
              ? locations.value.join()
              : null,
          GermanLocationsTags:
            germanLocationsPaths.value != null &&
            germanLocationsPaths.value.length > 0
              ? germanLocationsPaths.value.join()
              : null,
          AppliesToSME: smeString.value,
          ApplicationTerms: applicationTermsString.value,
          MinAmount: minBudget.value != 0 ? minBudget.value : null,
          MaxAmount: maxBudget.value != 0 ? maxBudget.value : null,
          Scopes: scopesString.value,
          Organisations: orgsInclude.value.join(),
          NotOrganisations: orgsExclude.value.join(),
          CallStartDate:
            startDate.value != null &&
            startDate.value != '' &&
            startDate.value != '""'
              ? moment(startDate.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          CallEndDate:
            endDate.value != null &&
            endDate.value != '' &&
            endDate.value != '""'
              ? moment(endDate.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          PageSize: pageSize,
          Page: page,
        },
      })
      .then((response) => {
        store.commit('schemeItems/updateGrants', response.data);
        store.commit(
          'advancedSearch/updatePageCount',
          response.data.pageCount + 1
        );
        store.commit(
          'advancedSearch/updateResultsCount',
          response.data.resultCount
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }
  getSMETypes() {
    return axios
      .get(API_URL + `schemes/getAllSMETypes`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/updateSME', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  getSchemeSDGThemeTypes() {
    return axios
      .get(API_URL + `schemes/getSchemeSDGThemeTypes`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/updateSDGThemeTypes', response.data);
      })
      .catch((error) => {
        store.commit('scheme/updateModalError', error),
          store.commit('scheme/enableModalError');
      });
  }
  getDocumentTypes() {
    return axios
      .get(API_URL + `schemes/getDocumentTypes`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/updateDocumentTypes', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async getSchemeCallsAndApplicationPeriods(
    schemeId,
    filterSearchTerm,
    sortBy,
    descending,
    page,
    pageSize
  ) {
    return await axios
      .get(API_URL + 'schemes/getSchemeCallsAndApplicationPeriods', {
        params: {
          schemeId: schemeId,
          filterSearchTerm: filterSearchTerm,
          sortBy: sortBy,
          descending: descending,
          index: page ?? 0,
          size: pageSize ?? 20,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit(
          'scheme/updateSchemeCallsAndApplicationPeriodsList',
          response.data.items
        );
        store.commit(
          'scheme/updateSchemeCallsAndApplicationPeriodsPagination',
          response.data
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          store.commit('scheme/updateModalError', error),
            store.commit('scheme/enableModalError');
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  getScopes() {
    return axios
      .get(API_URL + `schemes/getAllScopes`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/updateScopes', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  getTerms() {
    return axios
      .get(API_URL + `schemes/getAllTerms`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/updateApplicationTerms', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async getSchemesTree() {
    return await axios
      .get(API_URL + `schemes/getSchemeTree`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/updateSchemes', response.data);
        store.commit('scheme/disableSpinner');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async getSchemeById(id, includeChildren) {
    return await axios
      .get(API_URL + 'schemes/getScheme', {
        params: {
          schemeId: id,
          includeChildren: includeChildren,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/setSelectedScheme', response.data);
        if (response.data.groupTags != null) {
          const groupNames = response.data.groupTags.map(
            (group) => group.groupName
          );
          store.commit('tree/setLocationGroupsSelected', groupNames);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async getSchemeSelectedTags(schemeId, tagType, tagTypeValue) {
    return await axios
      .get(
        API_URL +
          `schemes/getSchemeSelectedTags?schemeId=${schemeId}&tagType=${tagTypeValue}`,
        {
          params: {
            culture: 'nl',
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var type = tagType.split(' ').join('');
          store.commit(
            'scheme/setSelectedScheme' + `${type}` + 'Tags',
            response.data
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }
  async createNewScheme(scheme, filesFormData) {
    const schemeUpdates = scheme.schemeUpdates.map((obj) => {
      return {
        ...obj,
        updateDateTime: moment(obj.updateDateTime, 'DD-MM-YYYY').format(
          'YYYY-MM-DD'
        ),
      };
    });

    const schemeSME = store.state.scheme.createSchemeSmeString;
    return await axios({
      method: 'post',
      url: API_URL + 'schemes/createScheme',
      headers: authHeader(),
      data: {
        Name: scheme.newName,
        Identifier: scheme.identifier,
        Introduction: scheme.newIntroduction,
        Body: scheme.newBody,
        Criteria: scheme.newCriteria,
        Budget: scheme.newBudget,
        SchemeStart:
          scheme.startDate != null && scheme.startDate != ''
            ? moment(scheme.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null,
        SchemeEnd:
          scheme.endDate != null && scheme.endDate != ''
            ? moment(scheme.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null,
        FundingOrganisations: scheme.selectedFundingOrgs,
        AdministratingOrganisations: scheme.selectedAdministratingOrgs,
        AdvisingOrganisations: scheme.selectedAdvisingOrgs,
        RecipientOrganisations: scheme.selectedRecipientOrgs,
        //   SMEType: scheme.selectedSME,
        SMEType: schemeSME,
        Scope: scheme.selectedScope,
        Status: scheme.status,
        PublicationStatus: scheme.publicationStatus,
        Accronym: scheme.newAccronym,
        Archivecode: scheme.newArchiveCode,
        ApplicationTerm: scheme.selectedApplicationTerm,
        Duration: scheme.newDuration,
        MinAmount: scheme.minAmount,
        MaxAmount: scheme.maxAmount,
        Exclusion: scheme.exclusion,
        FinancialInformation: scheme.financialInformation,
        ApplicationProcedure: scheme.applicationProcedure,
        PortalBody: scheme.portalBody,
        ComplexityRating: scheme.complexityRating,
        ChanceOfSuccessRating: scheme.chanceOfSuccessRating,
        TimeForApplicationRating: scheme.timeForApplicationRating,
        Links: scheme.schemeLinks,
        IsICTUScheme: scheme.ICTUscheme,
        MinimalAge: scheme.minimalAge,
        MaximalAge: scheme.maximalAge,
        SchemeUpdates: schemeUpdates,
        IsPortalScheme: scheme.isPortalScheme,
        IsAlwaysOpen: scheme.isAlwaysOpen,
        isScienceScheme: scheme.isScienceScheme,
        IsEnglishScheme: scheme.isEnglishScheme,
        HasEnglishCalls: scheme.hasEnglishCalls,
        IsOBScheme: scheme.schemeIsOBScheme,
        OBDescription: scheme.schemeOBDescription,
        OBTitle: scheme.schemeOBTitle,
        ApplicationInformation: scheme.applicationInformation,
        Tags: scheme.selectedTags.selectedAudiences
          .concat(scheme.selectedTags.selectedPurposes)
          .concat(scheme.selectedTags.selectedLocations)
          .concat(scheme.selectedTags.selectedSchemeTypes)
          .concat(scheme.selectedTags.selectedSchemeUserStatuses)
          .concat(scheme.selectedTags.selectedSchemeEducationLevels)
          .concat(scheme.selectedTags.selectedProjectTypes)
          .concat(scheme.selectedTags.selectedIndustriesNew),
        //This is just hidden for now, don't remove it
        //.concat(scheme.selectedTags.selectedIndustries)
        KeywordsTags: scheme.selectedTags.selectedSchemeKeywords,
        GroupsList: scheme.selectedGroups,
        LocationTags: scheme.locationTags,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          var newSchemeId = response.data.id;
          if (filesFormData != null) {
            filesFormData.append('schemeId', newSchemeId);
            await axios
              .post(API_URL + `schemes/uploadFile`, filesFormData, {
                headers: authHeader(),
              })
              .then((response) => {
                if (response.status == 200) {
                  router.push({
                    name: 'EditScheme',
                    params: { id: newSchemeId },
                  });
                }
              })
              .catch((error) => {
                if (error.response.status === 401) {
                  AuthService.logout();
                  router.go();
                }
                if (error) {
                  if (error.response.status === 403) {
                    store.commit(
                      'scheme/updateModalError',
                      "You don't have permission to perform this action!"
                    ),
                      store.commit('scheme/enableModalError');
                  } else {
                    store.commit(
                      'scheme/updateModalError',
                      error.response.data
                    ),
                      store.commit('scheme/enableModalError');
                  }
                }
              });

            store.commit('scheme/updateAreSchemeChangesLoading', false);
          } else {
            router.push({ name: 'EditScheme', params: { id: newSchemeId } });
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            router.go(-1);
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async editScheme(scheme) {
    store.commit('scheme/updateAreSchemeChangesLoading', true);

    const schemeUpdates = scheme.schemeUpdates.value.map((obj) => {
      return {
        ...obj,
        updateDateTime: moment(obj.updateDateTime, 'DD-MM-YYYY').format(
          'YYYY-MM-DD'
        ),
      };
    });
    return await axios({
      method: 'post',
      url: API_URL + `schemes/editScheme`,
      headers: authHeader(),
      data: {
        Id: scheme.id,
        Name: scheme.schemeName.value,
        Introduction: scheme.schemeIntroduction.value,
        SchemeStart:
          scheme.schemeStart.value == null || scheme.schemeStart.value == ''
            ? null
            : moment(scheme.schemeStart.value, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              ),
        SchemeEnd:
          scheme.schemeEnd.value == null || scheme.schemeEnd.value == ''
            ? null
            : moment(scheme.schemeEnd.value, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        CallStart:
          scheme.callStart.value == null || scheme.callStart.value == ''
            ? null
            : moment(scheme.callStart.value, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        CallEnd:
          scheme.callEnd.value == null || scheme.callEnd.value == ''
            ? null
            : moment(scheme.callEnd.value, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        Body: scheme.schemeBody.value,
        Criteria: scheme.schemeCriteria.value,
        Budget: scheme.schemeBudget.value,
        FundingOrganisations: scheme.schemeFundingOrganisations.value,
        AdministratingOrganisations:
          scheme.schemeAdministratingOrganisations.value,
        AdvisingOrganisations: scheme.schemeAdvisingOrganisations.value,
        RecipientOrganisations: scheme.schemeRecipientOrganisations.value,
        SchemeSDGThemes: scheme.schemeSDGThemes.value,
        GroupsList: scheme.groupsList,
        SMEType: scheme.schemeSME.value,
        ApplicationTerm: scheme.schemeTerm.value,
        Scope: scheme.schemeScope.value,
        Archivecode: scheme.schemeArchiveCode.value,
        Accronym: scheme.schemeAccronym.value,
        PublicationStatus: scheme.schemePublicationStatus.value,
        Status: scheme.schemeStatus.value,
        MinimalAge: scheme.schemeMinimalAge.value,
        MaximalAge: scheme.schemeMaximalAge.value,
        MinAmount: scheme.schemeMinAmount.value,
        MaxAmount: scheme.schemeMaxAmount.value,
        IsICTUScheme: scheme.ICTUscheme.value,
        Exclusion: scheme.schemeExclusion.value,
        FinancialInformation: scheme.schemeFinancialInformation.value,
        ApplicationProcedure: scheme.schemeApplicationProcedure.value,
        PortalBody: scheme.schemePortalBody.value,
        ComplexityRating: scheme.schemeComplexityRating.value,
        ChanceOfSuccessRating: scheme.schemeChanceOfSuccessRating.value,
        TimeForApplicationRating: scheme.schemeTimeForApplicationRating.value,
        KeywordsTags: scheme.schemeKeywordsTags.value,
        IsCall: scheme.isCall.value,
        IsApplicationPeriod: scheme.isApplicationPeriod.value,
        IsPortalScheme: scheme.isPortalScheme.value,
        IsAlwaysOpen: scheme.isAlwaysOpen.value,
        IsScienceScheme: scheme.isScienceScheme.value,
        IsEnglishScheme: scheme.isEnglishScheme.value,
        HasEnglishCalls: scheme.hasEnglishCalls.value,
        Duration: scheme.schemeDuration.value,
        Identifier: scheme.schemeIdentifier.value,
        Links: scheme.schemeLinks.value,
        IsOBScheme: scheme.isOBScheme.value,
        OBDescription: scheme.oBDescription.value,
        OBTitle: scheme.oBTitle.value,
        SchemeUpdates: schemeUpdates,
        ApplicationInformation: scheme.schemeApplicationInformation.value,
        RecurringStartingYear: scheme.selectedSchemeRecurringStartingYear.value,
        RecurringYearsDuration:
          scheme.selectedSchemeRecurringYearsDuration.value,
        RecurringStartDate: scheme.selectedSchemeRecurringStartDate.value
          ? moment(
              scheme.selectedSchemeRecurringStartDate.value,
              'DD-MM-YYYY'
            ).format('YYYY-MM-DD')
          : null,
        RecurringEndDate: scheme.selectedSchemeRecurringEndDate.value
          ? moment(
              scheme.selectedSchemeRecurringEndDate.value,
              'DD-MM-YYYY'
            ).format('YYYY-MM-DD')
          : null,
        HasRecurringDeadline: scheme.hasRecurringDeadline.value,
        LocationTags: scheme.locationTags.value,
        Tags: scheme.schemeAudienceTags.value

          .concat(scheme.schemePurposesTags.value)

          //This is just hidden for now, don't remove it
          //.concat(scheme.schemeIndustriesTags.value)

          .concat(scheme.schemeLocationsTags.value)

          .concat(scheme.schemeSchemeTypesTags.value)

          .concat(scheme.schemeProjectTypesTags.value)

          .concat(scheme.schemeIndustriesNewTags.value)

          .concat(scheme.schemeUserStatusTags.value)

          .concat(scheme.schemeEducationLevelTags.value),
      },
    })
      .then((response) => {
        if (response.status == 200) {
          router.push({ name: 'SchemesSearchPage' });
        }
        store.commit('scheme/updateAreSchemeChangesLoading', false);
        sessionStorage.removeItem('schemeUpdatesToAdd');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            router.go(-1);
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  // used for the old q-uploader (when there was a need to upload multiple files at once)
  async uploadFile(formData, id) {
    return await axios
      .post(API_URL + `schemes/uploadFile`, formData, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          this.getSchemeDocuments(id);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async uploadSingleFile(formData, id) {
    return await axios
      .post(API_URL + `schemes/uploadSingleFile`, formData, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          this.getSchemeDocuments(id);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async removeDocument(id, documentId) {
    return await axios
      .post(
        API_URL + `schemes/removeDocument`,
        {
          id: id,
          document: {
            id: documentId,
          },
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          this.getSchemeDocuments(id);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async updateDocument(schemeId, documentId, name, documentType) {
    return await axios
      .post(
        API_URL + `schemes/updateDocument`,
        {
          id: documentId,
          schemeId: schemeId,
          name: name,
          documentType: documentType,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          store.commit('scheme/refreshSchemeDocuments', response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async downloadDocument(documentId) {
    return await axios
      .get(API_URL + `schemes/download?documentId=${documentId}`, {
        headers: authHeader(),
        responseType: 'blob',
        reportProgress: true,
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async getPublicationStatuses() {
    return await axios
      .get(API_URL + 'news/getPublicationStatuses', { headers: authHeader() })
      .then((response) => {
        store.commit('scheme/updatePublicationStatuses', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async createCall(call, filesFormData) {
    store.commit('scheme/updateAreSchemeChangesLoading', true);

    return await axios({
      method: 'post',
      url: API_URL + 'schemes/createCall',
      headers: authHeader(),
      data: {
        Name: call.name,
        Identifier: call.identifier,
        Body: call.body,
        PublicationStatus: call.publicationStatus,
        Budget: call.budget,
        ParentSchemeId: call.parentId,
        IsCall: call.isCall,
        IsAlwaysOpen: call.isAlwaysOpen,
        CallStart:
          call.callStartDate != null && call.callStartDate != ''
            ? moment(call.callStartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null,
        CallEnd:
          call.callEndDate != null && call.CallEndDate != ''
            ? moment(call.callEndDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null,
        Links: call.callLinks,
        Tags: call.selectedTags.selectedAudiences
          .concat(call.selectedTags.selectedPurposes)
          .concat(call.selectedTags.selectedLocations)
          .concat(call.selectedTags.selectedSchemeTypes)
          .concat(call.selectedTags.selectedIndustriesNew),
        KeywordsTags: call.selectedTags.selectedKeywords,
        InheritTags: call.inheritTags,
        RecurringStartingYear: call.recurringStartingYear,
        RecurringYearsDuration: call.recurringYearsDuration,
        RecurringStartDate: call.recurringStartDate
          ? moment(call.recurringStartDate, 'DD-MM').format('YYYY-MM-DD')
          : null,
        RecurringEndDate: call.recurringEndDate
          ? moment(call.recurringEndDate, 'DD-MM').format('YYYY-MM-DD')
          : null,
        HasRecurringDeadline: call.isRecurringDeadline,
        IsEnglishScheme: call.isEnglishScheme,
        LocationTags: call.locationTags,
      },
    })
      .then(async (response) => {
        if (response.status === 200) {
          if (filesFormData != null) {
            filesFormData.append('schemeId', response.data.id);
            await axios
              .post(API_URL + `schemes/uploadFile`, filesFormData, {
                headers: authHeader(),
              })
              .then((response) => {
                if (response.status == 200) {
                  store.commit('scheme/enableSuccessfullyCreatedCallDialog');
                  this.getSchemeCallsAndApplicationPeriods(call.parentId);
                }
              })
              .catch((error) => {
                if (error.response.status === 401) {
                  AuthService.logout();
                  router.go();
                }
                if (error) {
                  if (error.response.status === 403) {
                    store.commit(
                      'scheme/updateModalError',
                      "You don't have permission to perform this action!"
                    ),
                      store.commit('scheme/enableModalError');
                  } else {
                    store.commit(
                      'scheme/updateModalError',
                      error.response.data
                    ),
                      store.commit('scheme/enableModalError');
                  }
                }
              });
            store.commit('scheme/updateAreSchemeChangesLoading', false);
          } else {
            store.commit('scheme/updateAreSchemeChangesLoading', false);
            store.commit('scheme/enableSuccessfullyCreatedCallDialog');
            this.getSchemeCallsAndApplicationPeriods(call.parentId);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit('scheme/updateAreSchemeChangesLoading', false);
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateAreSchemeChangesLoading', false);
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async getSchemeDocuments(schemeId) {
    return await axios
      .get(API_URL + 'schemes/getSchemeDocuments', {
        params: {
          schemeId: schemeId,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/refreshSchemeDocuments', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async getSchemeUpdates(
    schemeId,
    filterSearchTerm,
    sortBy,
    descending,
    page,
    pageSize
  ) {
    return await axios
      .get(API_URL + 'schemes/getSchemeUpdates', {
        params: {
          schemeId: schemeId,
          filterSearchTerm: filterSearchTerm,
          sortBy: sortBy,
          descending: descending,
          index: page ?? 0,
          size: pageSize ?? 20,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/updateSchemeUpdatesList', response.data.items);
        store.commit('scheme/updateSchemeUpdatesPagination', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async deleteCallOrApplicationPeriod(itemId) {
    return await axios
      .post(
        API_URL + 'schemes/deleteCallOrApplicationPeriod',
        {
          id: itemId,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async getSearchResultsSorted(
    searchText,
    audiences,
    locations,
    germanLocationsPaths,
    //This is just hidden for now, don't remove it
    //industries,
    purposes,
    schemeTypes,
    projectTypes,
    smeString,
    applicationTermsString,
    minBudget,
    maxBudget,
    scopesString,
    orgsInclude,
    orgsExclude,
    startDate,
    endDate,
    page = 0,
    pageSize = 5000,
    sortBy,
    descending,
    searchOnlyName,
    publicationStatusesString,
    filterByICTUFlag,
    durationPhrase,
    updatedDateGreaterThan,
    updatedDateLessThan,
    schemeStartGreaterThan,
    schemeStartLessThan,
    schemeEndGreaterThan,
    schemeEndLessThan,
    searchOnFundingOrgs,
    searchOnAdministratingOrgs,
    searchOnAdvisingOrgs,
    searchOnRecipientOrgs,
    isScienceScheme,
    isPortalScheme,
    isEnglishScheme,
    isObScheme,
    showAllLanguageVersions,
    schemeStatusesNew
  ) {
    return await axios
      .get(API_URL + 'search/searchschemes', {
        headers: authHeader(),
        params: {
          Term: searchText.value,
          DurationPhrase: durationPhrase.value,
          PageSize: pageSize,
          Page: page ? page - 1 : 0,
          Sort: sortBy != '' && sortBy != null ? sortBy : 'Relevantie',
          SortAscending: !descending,
          // Sort: "Title",
          //   Statusses: statusBoxes.value, --remove com
          Statusses: schemeStatusesNew,
          AudienceTags: audiences.value,
          //This is just hidden for now, don't remove it
          //IndustriesTags: industries.value,
          PurposesTags: purposes.value,
          SchemeTypesTags: schemeTypes.value,
          ProjectTypesTags: projectTypes.value,
          LocationsTags:
            locations.value != null && locations.value.length > 0
              ? locations.value.join()
              : null,
          GermanLocationsTags:
            germanLocationsPaths.value != null &&
            germanLocationsPaths.value.length > 0
              ? germanLocationsPaths.value.join()
              : null,
          AppliesToSME: smeString.value,
          IsScienceScheme: isScienceScheme.value,
          IsPortalScheme: isPortalScheme.value,
          IsEnglishScheme: isEnglishScheme.value,
          IsObScheme: isObScheme.value,
          ApplicationTerms: applicationTermsString.value,
          PublicationStatus: publicationStatusesString,
          MinAmount: minBudget.value != 0 ? minBudget.value : null,
          MaxAmount: maxBudget.value != 0 ? maxBudget.value : null,
          Scopes: scopesString.value,
          NotOrganisations:
            orgsExclude.value && orgsExclude.value.length > 0
              ? orgsExclude.value.join()
              : null,
          Organisations:
            orgsInclude.value && orgsInclude.value.length > 0
              ? orgsInclude.value.join()
              : null,
          CallStartDate:
            startDate.value != null &&
            startDate.value != '' &&
            startDate.value != '""'
              ? moment(startDate.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          CallEndDate:
            endDate.value != null &&
            endDate.value != '' &&
            endDate.value != '""'
              ? moment(endDate.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          UpdatedDateLessThan:
            updatedDateLessThan.value != null && updatedDateLessThan.value != ''
              ? moment(updatedDateLessThan.value, 'DD-MM-YYYY')
                  .endOf('day')
                  .format('YYYY-MM-DD HH:mm:ss')
              : null,
          UpdatedDateGreaterThan:
            updatedDateGreaterThan.value != null &&
            updatedDateGreaterThan.value != ''
              ? moment(updatedDateGreaterThan.value, 'DD-MM-YYYY')
                  .startOf('day')
                  .format('YYYY-MM-DD HH:mm:ss')
              : null,
          SchemeStartGreaterThan:
            schemeStartGreaterThan.value != null &&
            schemeStartGreaterThan.value != ''
              ? moment(schemeStartGreaterThan.value, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                )
              : null,
          SchemeStartLessThan:
            schemeStartLessThan.value != null && schemeStartLessThan.value != ''
              ? moment(schemeStartLessThan.value, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                )
              : null,
          SchemeEndGreaterThan:
            schemeEndGreaterThan.value != null &&
            schemeEndGreaterThan.value != ''
              ? moment(schemeEndGreaterThan.value, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                )
              : null,
          SchemeEndLessThan:
            schemeEndLessThan.value != null && schemeEndLessThan.value != ''
              ? moment(schemeEndLessThan.value, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                )
              : null,
          SearchOnlySchemeName: searchOnlyName.value,
          ShowIctuSchemes: filterByICTUFlag.value,
          SearchOnFundingOrganisations: searchOnFundingOrgs.value,
          SearchOnAdministratingOrganisations: searchOnAdministratingOrgs.value,
          SearchOnAdvisingOrganisations: searchOnAdvisingOrgs.value,
          SearchOnRecipientOrganisations: searchOnRecipientOrgs.value,
          ShowAllLanguageVersions: showAllLanguageVersions.value,
        },
      })
      .then((response) => {
        store.commit('advancedSearch/updateCurrentPage', 1);
        store.commit('scheme/updateAllSchemes', response.data);
        store.commit('schemeItems/updateGrants', response.data);
        store.commit('advancedSearch/updatePageCount', response.data.pageCount);
        store.commit(
          'advancedSearch/updateResultsCount',
          response.data.resultCount
        );
        store.commit('scheme/updatePagination', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async copyScheme(schemeId, copyChildren) {
    return await axios
      .post(
        API_URL + 'schemes/copyScheme',
        {
          id: schemeId,
          copyChildren: copyChildren,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        router.push({ name: 'EditScheme', params: { id: response.data.id } });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async copyCall(callId) {
    return await axios
      .post(
        API_URL + 'schemes/copyCall',
        {
          id: callId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        router.push({ name: 'EditScheme', params: { id: response.data.id } });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async getColorBasedOnVisualpingLinksStatuses(schemeId) {
    return await axios
      .get(API_URL + 'schemes/getColorBasedOnVisualpingLinksStatuses', {
        params: {
          schemeId: schemeId,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/updateVisualpingColorForSelectedScheme', {
          schemeId: schemeId,
          color: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async getSchemeVisualPingLinks(schemeId) {
    return await axios
      .get(API_URL + 'schemes/getSchemeVisualPingLinks', {
        params: {
          schemeId: schemeId,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/updateSchemeVisualPingLinks', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async getVisualPingLinkWithChanges(linkId) {
    return await axios
      .get(API_URL + 'linkChecker/getvisualpinglinkwithchanges', {
        params: {
          linkid: linkId,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/updateVisualPingLinkWithChanges', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async getFilteredSchemesForSelect(filter) {
    return await axios
      .get(API_URL + 'schemes/getFilteredSchemesForSelect', {
        params: {
          filter: filter,
        },
        headers: authHeader(),
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async exportSchemes(
    searchText,
    audiences,
    locations,
    germanLocationsPaths,
    purposes,
    schemeTypes,
    projectTypes,
    smeString,
    applicationTermsString,
    minBudget,
    maxBudget,
    scopesString,
    orgsInclude,
    orgsExclude,
    startDate,
    endDate,
    page = 0,
    pageSize = 5000,
    sortBy,
    descending,
    searchOnlyName,
    publicationStatusesString,
    filterByICTUFlag,
    durationPhrase,
    updatedDateGreaterThan,
    updatedDateLessThan,
    schemeStartGreaterThan,
    schemeStartLessThan,
    schemeEndGreaterThan,
    schemeEndLessThan,
    searchOnFundingOrgs,
    searchOnAdministratingOrgs,
    searchOnAdvisingOrgs,
    searchOnRecipientOrgs,
    isScienceScheme,
    isPortalScheme,
    isEnglishScheme,
    isObScheme,
    showAllLanguageVersions,
    schemeStatusGroup
  ) {
    return await axios
      .get(API_URL + `schemes/exportSchemes`, {
        params: {
          Term: searchText,
          DurationPhrase: durationPhrase,
          PageSize: pageSize,
          Page: page ? page - 1 : 0,
          Sort: sortBy != '' && sortBy != null ? sortBy : 'Relevantie',
          SortAscending: !descending,
          Statusses: schemeStatusGroup,
          AudienceTags: audiences.value,
          PurposesTags: purposes.value,
          SchemeTypesTags: schemeTypes.value,
          ProjectTypesTags: projectTypes.value,
          LocationsTags: locations.value != null && locations.value.length > 0 ? locations.value.join() : null,
          GermanLocationsTags: germanLocationsPaths.value != null &&  germanLocationsPaths.value.length > 0 ? germanLocationsPaths.value.join() : null,
          AppliesToSME: smeString.value,
          ApplicationTerms: applicationTermsString.value,
          PublicationStatus: publicationStatusesString,
          MinAmount: minBudget.value,
          MaxAmount: maxBudget.value,
          Scopes: scopesString.value,
          NotOrganisations:
            orgsExclude.value.length > 0 ? orgsExclude.value.join() : null,
          Organisations:
            orgsInclude.value.length > 0 ? orgsInclude.value.join() : null,
          CallStartDate:
            startDate.value != null && startDate.value != ''
              ? moment(startDate.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          CallEndDate:
            endDate.value != null && endDate.value != ''
              ? moment(endDate.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          UpdatedDateLessThan:
            updatedDateLessThan.value != null && updatedDateLessThan.value != ''
              ? moment(updatedDateLessThan.value, 'DD-MM-YYYY')
                  .endOf('day')
                  .format('YYYY-MM-DD HH:mm:ss')
              : null,
          UpdatedDateGreaterThan:
            updatedDateGreaterThan.value != null &&
            updatedDateGreaterThan.value != ''
              ? moment(updatedDateGreaterThan.value, 'DD-MM-YYYY')
                  .startOf('day')
                  .format('YYYY-MM-DD HH:mm:ss')
              : null,
          SchemeStartGreaterThan:
            schemeStartGreaterThan.value != null &&
            schemeStartGreaterThan.value != ''
              ? moment(schemeStartGreaterThan.value, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                )
              : null,
          SchemeStartLessThan:
            schemeStartLessThan.value != null && schemeStartLessThan.value != ''
              ? moment(schemeStartLessThan.value, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                )
              : null,
          SchemeEndGreaterThan:
            schemeEndGreaterThan.value != null &&
            schemeEndGreaterThan.value != ''
              ? moment(schemeEndGreaterThan.value, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                )
              : null,
          SchemeEndLessThan:
            schemeEndLessThan.value != null && schemeEndLessThan.value != ''
              ? moment(schemeEndLessThan.value, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                )
              : null,
          SearchOnlySchemeName: searchOnlyName.value,
          ShowIctuSchemes: filterByICTUFlag.value,
          SearchOnFundingOrganisations: searchOnFundingOrgs.value,
          SearchOnAdministratingOrganisations: searchOnAdministratingOrgs.value,
          SearchOnAdvisingOrganisations: searchOnAdvisingOrgs.value,
          SearchOnRecipientOrganisations: searchOnRecipientOrgs.value,
          IsScienceScheme: isScienceScheme.value,
          IsPortalScheme: isPortalScheme.value,
          IsEnglishScheme: isEnglishScheme.value,
          IsObScheme: isObScheme.value,
          ShowAllLanguageVersions: showAllLanguageVersions.value,
        },
        headers: authHeader(),
        responseType: 'blob',
        reportProgress: true,
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error.response.data),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async searchLocationCodesBulk(query) {
    return await axios
      .get(API_URL + `locatioTag/searchLocationTagsBulk`, {
        params: {
          query: query,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/updateLocationTagsBulkList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
      });

    /*   async getLanguageCodes() {
    return await axios
      .get(API_URL + `users/getAllLanguageCodes`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit("scheme/updateLanguageCodes", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              "news/updateModalError",
              "You don't have permission to perform this action!"
            ),
              store.commit("news/enableModalError");
          } else {
            store.commit("news/updateModalError", error.response.data),
              store.commit("news/enableModalError");
          }
        }
      });
  } */
  }

  getSchemeStatuses() {
    return axios
      .get(API_URL + `schemes/getSchemeStatuses`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('scheme/fillSchemeStatusOptions', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }
}

export default new SchemesServices();
