import moment from "moment";
import { ref } from "vue";
import store from "../index";

const state = () => ({
  showListCalls: false,
  schemesIndex: [{}],
  selectedNodeAndParent: {},
  schemeNodes: [],
  schemeTreeLabel: "",
  totalNodes: 0,
  modalErrorMessage: "",
  hasModalError: false,
  selectedNode: 0,
  schemesList: [],
  spinner: false,
  selectedScheme: {},
  counter: 0,
  shortIntroduction: "",
  shortBody: "",
  createdSchemeId: 0,
  selectedSchemeName: "",
  selectedSchemeIsOBScheme: null,
  selectedSchemeOBTitle: "",
  selectedSchemeOBDescription: "",
  selectedSchemeStatus: 0,
  selectedSchemeIdentifier: "",
  selectedSchemeIntroduction: "",
  selectedSchemeStartDate: "",
  selectedSchemeEndDate: "",
  selectedSchemeCallStartDate: "",
  selectedSchemeCallEndDate: "",
  selectedSchemeBody: "",
  selectedTransBody: "",
  selectedTransCriteria: "",
  selectedTransApplicationProcedure: "",
  selectedTransApplicationInformation: "",
  selectedTransIntroduction: "",
  selectedTransFinancialinformation: "",
  selectedTransName: "",
  selectedSchemeCriteria: "",
  selectedSchemeFundingOrgs: [{}],
  selectedSchemeAdministratingOrgs: [{}],
  selectedSchemeAdvisingOrgs: [{}],
  selectedSchemeRecipientOrgs: [{}],
  selectedSchemeScope: "",
  selectedSchemeSME: "",
  createSchemeSmeString: "",
  selectedSchemeTerm: "",
  selectedSchemeAccronym: "",
  selectedSchemeArchivecode: "",
  selectedSchemePublicationStatus: "",
  selectedSchemeBudget: "",
  selectedSchemeMinimalAge: 0,
  selectedSchemeMaximalAge: 0,
  selectedSchemeMinAmount: 0,
  selectedSchemeMaxAmount: 0,
  selectedSchemeICTU: false,
  selectedSchemeExclusion: "",
  selectedSchemeApplicationProcedure: "",
  selectedSchemeApplicationInformation: "",
  selectedSchemeFinancialInformation: "",
  selectedSchemePortalBody: "",
  selectedSchemeComplexityRating: 0,
  selectedSchemeChanceOfSuccessRating: 0,
  selectedSchemeTimeForApplicationRating: 0,
  selectedSchemeTags: [{}],
  selectedSchemeIsPortal: false,
  selectedSchemeIsScience: false,
  selectedSchemeIsEnglish: false,
  selectedSchemeHasEnglishCalls: false,
  selectedSchemeIsAlwaysOpen: false,
  showAddRelationBox: false,
  smeTypes: [],
  scopes: [],
  applicationTerms: [],
  documentTypes: [],
  selectedSchemeAudienceTags: [],
  selectedSchemePurposesTags: [],
  //This is just hidden for now, don't remove it
  //selectedSchemeIndustriesTags: [],
  //selectedSchemeIndustriesTagsIds: [],
  //schemeIndustriesTagsCount: 0,
  //industriesTags: { options: [], label: '' },
  selectedSchemeLocationsTags: [],
  selectedSchemeSchemeTypesTags: [],
  selectedSchemeProjectTypesTags: [],
  selectedSchemeIndustriesNewTags: [],
  selectedSchemeUserStatusTags: [],
  selectedSchemeEducationLevelTags: [],
  selectedSchemeKeywordsTags: [],
  selectedSchemeAudienceTagsIds: [],
  selectedSchemePurposesTagsIds: [],
  selectedSchemeLocationsTagsIds: [],
  selectedSchemeSchemeTypesTagsIds: [],
  selectedSchemeProjectTypesTagsIds: [],
  selectedSchemeIndustriesNewTagsIds: [],
  selectedSchemeUserStatusTagsIds: [],
  selectedSchemeEducationLevelTagsIds: [],
  // German locations
  locationTagsSelectBulk: [],
  // German locations
  locationTagsSelectedIds: [],
  selectedSchemeHasRecurringDeadline: false,
  selectedSchemeRecurringStartingYear: 0,
  selectedSchemeRecurringYearsDuration: 0,
  selectedSchemeRecurringStartDate: "",
  selectedSchemeRecurringEndDate: "",
  schemeAudienceTagsCount: 0,
  schemePurposesTagsCount: 0,
  schemeLocationsTagsCount: 0,
  schemeSchemeTypesTagsCount: 0,
  schemeProjectTypesTagsCount: 0,
  schemeIndustriesNewTagsCount: 0,
  schemeUserStatusTagsCount: 0,
  schemeEducationLevelTagsCount: 0,
  selectedSchemeLinks: [{}],
  selectedSchemeDocuments: [{}],
  selectedSchemeCallsAndApplicationPeriods: [{}],
  selectedSchemeUpdates: [{}],
  selectedSchemeIsCall: null,
  selectedSchemeIsApplicationPeriod: null,
  publicationStatuses: [],
  schemeStatuses: [],
  pagination: {
    sortBy: "asc",
    descending: false,
    page: 1,
    rowsPerPage: 5000,
    rowsNumber: null,
  },
  schemes: [{}],
  maxPages: 0,
  totalResults: 10,
  pageSize: 0,
  audienceTags: { options: [], label: "" },
  purposesTags: { options: [], label: "" },
  locationsTags: { options: [], label: "" },
  schemeTypesTags: { options: [], label: "" },
  projectTypesTags: { options: [], label: "" },
  industriesNewTags: { options: [], label: "" },
  educationLevelTags: { options: [], label: "" },
  userStatusTags: { options: [], label: "" },
  locationTagsBulkList: [],
  selectedSchemeIntroductionPlain: "",
  selectedSchemeBodyPlain: "",
  callsAndApplicationPeriodsPagination: {
    sortBy: "desc",
    descending: false,
    page: 1,
    rowsPerPage: 20,
    rowsNumber: null,
  },
  schemeUpdatesPagination: {
    sortBy: "",
    descending: false,
    page: 1,
    rowsPerPage: 20,
    rowsNumber: null,
  },
  // New additions here (from word document)
  selectedSchemeVS: false,
  selectedSchemeId: 0,
  selectedSchemeDuration: "",
  showSuccessfullyCreatedCallDialog: false,
  showSelectTags: false,
  showAddLink: false,
  showEditLink: false,
  showAddDocument: false,
  showEditDocument: false,
  selectedLink: null,
  selectedLinkUrl: "",
  selectedLinkText: "",
  selectedLinkDocumentType: null,
  selectedDocument: null,
  selectedDocumentName: "",
  selectedDocumentDocumentType: null,
  showVisualPingDialog: false,
  selectedSchemeVisualPingLinks: [],
  visualPingLinkWithChanges: {},
  showAddVisualPingLink: false,
  showEditVisualPingLink: false,
  selectedVpLinkToEdit: null,
  selectedVpLinkToEditUrl: "",
  selectedVpLinkToEditText: "",
  isOneOffDeadline: true,
  isRecurringDeadline: false,
  isAlwaysOpenCall: false,
  schemeSDGThemeTypes: [],
  selectedSchemeSDGThemes: [],
  selectedSchemeIsOneOffDeadline: false,
  areTagsLoading: false,
  areSchemeChangesLoading: false,
  showDiscontinuedModal: false,
  originalSchemeStatus: {},
});

const getters = {};

const actions = {};

const mutations = {
  resetSchemeStatus(state) {
    state.selectedSchemeStatus = state.originalSchemeStatus;
  },
  updatedShowDiscontinuedModal(state, val) {
    state.showDiscontinuedModal = val;
  },
  // update location tags bulk list
  updateLocationTagsBulkList(state, val) {
    state.locationTagsBulkList = val;
  },
  updateAreSchemeChangesLoading(state, val) {
    state.areSchemeChangesLoading = val;
  },
  updateAreTagsLoading(state, val) {
    state.areTagsLoading = val;
  },
  /* OLD
   updateDisabledNodesinTree(state,locationGroupsSelected){
       const uniqueFilteredListIds = ref([]);
         if (Array.isArray(locationGroupsSelected) && locationGroupsSelected.length > 0) {

        const groups = computed({
          get: () => store.state.tree.groupObjectData,
        });

        //filtering retreived groups to match selected ones and getting tagIds that belong to these selected groups
        const filteredGroups = groups.value.filter((group) =>
          locationGroupsSelected.includes(group.groupName)
        );
        const filteredListIds = filteredGroups.flatMap(
          (group) => group.selectedTagsIds
        );

        //removing duplicates since its posible to have one location tag in multiple groups
        uniqueFilteredListIds.value = [...new Set(filteredListIds)];
        
        //disabling nodes if they belong to location group
        state.locationsTags.options.forEach((option) => {
          const isInFilteredList = uniqueFilteredListIds.value.includes(option.id);
          if(option.children.length > 0){
            option.children.forEach((child) => {
              if(child.children){
                child.children.forEach((grandchild) =>  {
                  if(uniqueFilteredListIds.value.includes(grandchild.id)){
                    grandchild.disabled = true;
                  } else grandchild.disabled = false;
                })
              } else child.disabled = true;
            })
          } else
            option.disabled = isInFilteredList;
          
        })
      }
  }*/
  updateDisabledNodesinTree(state, locationGroupsSelected) {
    const uniqueFilteredListIds = new Set();

    // Recursively disable nodes
    function disableNodes(nodes) {
      nodes.forEach((node) => {
        if (node.children && node.children.length > 0) {
          disableNodes(node.children);
        } else {
          node.disabled = uniqueFilteredListIds.has(node.id);
        }
      });
    }

    if (
      Array.isArray(locationGroupsSelected) &&
      locationGroupsSelected.length > 0
    ) {
      const groups = store.state.tree.groupObjectData;

      const filteredGroups = groups.filter((group) =>
        locationGroupsSelected.includes(group.groupName)
      );

      const filteredListIds = filteredGroups.flatMap(
        (group) => group.selectedTagsIds
      );

      uniqueFilteredListIds.clear();
      filteredListIds.forEach((id) => uniqueFilteredListIds.add(id));

      disableNodes(state.locationsTags.options);
    }
  },
  updateSelectedSchemeHasRecurringDeadline(state, val) {
    state.selectedSchemeHasRecurringDeadline = val;
  },
  updateSelectedSchemeIsOneOffDeadline(state, val) {
    state.selectedSchemeIsOneOffDeadline = val;
  },

  updateSelectedSchemeDeadlineType(state, val) {
    if (val.type == "oneOff") {
      state.selectedSchemeIsOneOffDeadline = val.value;
      if (val.value == true) {
        state.selectedSchemeHasRecurringDeadline = false;
        state.selectedSchemeIsAlwaysOpen = false;
      }
    } else if (val.type == "recurring") {
      state.selectedSchemeHasRecurringDeadline = val.value;
      if (val.value == true) {
        state.selectedSchemeIsOneOffDeadline = false;
        state.selectedSchemeIsAlwaysOpen = false;
      }
    } else if (val.type == "alwaysopen") {
      state.selectedSchemeIsAlwaysOpen = val.value;
      if (val.value == true) {
        state.selectedSchemeIsOneOffDeadline = false;
        state.selectedSchemeHasRecurringDeadline = false;
      }
    }
  },

  updateSelectedSchemeRecurringStartingYear(state, val) {
    state.selectedSchemeRecurringStartingYear = val;
  },
  updateSelectedSchemeRecurringYearsDuration(state, val) {
    state.selectedSchemeRecurringYearsDuration = val;
  },
  updateSelectedSchemeRecurringStartDate(state, val) {
    state.selectedSchemeRecurringStartDate = moment(val, "DD-MM").format(
      "DD-MM-YYYY"
    );
  },
  updateSelectedSchemeRecurringEndDate(state, val) {
    state.selectedSchemeRecurringEndDate = moment(val, "DD-MM").format(
      "DD-MM-YYYY"
    );
  },

  updateDeadlineType(state, val) {
    if (val.type == "oneOff") {
      state.isOneOffDeadline = val.value;
      if (val.value == true) {
        state.isRecurringDeadline = false;
        state.isAlwaysOpenCall = false;
      }
    } else if (val.type == "recurring") {
      state.isRecurringDeadline = val.value;
      if (val.value == true) {
        state.isOneOffDeadline = false;
        state.isAlwaysOpenCall = false;
      }
    } else {
      state.isAlwaysOpenCall = val.value;
      if (val.value == true) {
        state.isOneOffDeadline = false;
        state.isRecurringDeadline = false;
      }
    }
  },
  enableSuccessfullyCreatedCallDialog(state) {
    state.showSuccessfullyCreatedCallDialog = true;
  },
  disableSuccessfullyCreatedCallDialog(state) {
    state.showSuccessfullyCreatedCallDialog = false;
  },
  updateSelectedSchemeVS(state, value) {
    state.selectedSchemeVS = value;
  },
  updateSchemeDuration(state, value) {
    state.selectedSchemeDuration = value;
  },
  updateSchemeIdentifier(state, value) {
    state.selectedSchemeIdentifier = value;
  },
  //quasar toggle for dropdown
  showListCalls(state) {
    state.showListCalls = true;
  },
  //qausar table pagination
  setDefaultPagination(state) {
    state.pagination = ref({
      page: 1,
      rowsPerPage: 5000,
      rowsNumber: 10,
    });
  },
  updateAllSchemes(state, val) {
    state.schemes = val.results;
    state.maxPages = val.pageCount;
    state.totalResults = val.resultCount;
    state.pageSize = val.pageSize;
  },
  updatePagination(state, val) {
    state.pagination = {
      rowsPerPage: val.pageSize,
      rowsNumber: val.resultCount,
    };
  },
  updatePaginationPage(state, val) {
    state.pagination = {
      page: val.page,
      rowsPerPage: val.rowsPerPage,
      rowsNumber: val.rowsNumber,
      sortBy: val.sortBy,
      descending: val.descending,
    };
  },
  updateSME(state, val) {
    state.smeTypes = val;
  },
  updateScopes(state, val) {
    state.scopes = val;
  },
  updateApplicationTerms(state, val) {
    state.applicationTerms = val;
  },
  updateDocumentTypes(state, val) {
    state.documentTypes = val;
  },
  updateScope(state, val) {
    state.selectedSchemeScope = val;
  },
  updateTerm(state, val) {
    state.selectedSchemeTerm = val;
  },
  updateCreateSMEString(state, val) {
    state.createSchemeSmeString = val;
  },
  updateSelectedSME(state, val) {
    state.selectedSchemeSME = val;
  },
  updateSelectedAccronym(state, val) {
    state.selectedSchemeAccronym = val;
  },
  updateSelectedArchivecode(state, val) {
    state.selectedSchemeArchivecode = val;
  },
  updatePublicationStatus(state, val) {
    state.selectedSchemePublicationStatus = val;
  },
  updatePublicationStatuses(state, val) {
    state.publicationStatuses = val;
  },
  updateIsCall(state, val) {
    state.selectedSchemeIsCall = val;
    state.selectedSchemeIsApplicationPeriod = !val;
  },
  enableRelationBox(state) {
    state.showAddRelationBox = true;
  },
  disableRelationBox(state) {
    state.showAddRelationBox = false;
  },
  updateSchemesList(state, list) {
    state.schemesList = list;
  },
  setSelectedScheme(state, scheme) {
    console.log(scheme.schemeStatus);
    state.selectedScheme = scheme;
    state.selectedSchemeOBDescription = scheme.obDescription;
    state.selectedSchemeIsOBScheme = scheme.isOBScheme;
    state.selectedSchemeOBTitle = scheme.obTitle;
    if (scheme.introduction != null)
      state.shortIntroduction = scheme.introduction.substring(0, 1500);
    if (scheme.body != null) state.shortBody = scheme.body.substring(0, 1500);
    state.selectedSchemeName = scheme.name;
    state.selectedSchemeStatus = scheme.schemeStatus;
    state.originalSchemeStatus = scheme.schemeStatus;
    state.selectedSchemeIdentifier = scheme.identifier;
    state.selectedSchemeIntroduction = scheme.introduction;
    state.selectedSchemeStartDate =
      scheme.schemeStart == null || scheme.schemeStart == ""
        ? null
        : moment(scheme.schemeStart).format("DD-MM-YYYY");
    state.selectedSchemeEndDate =
      scheme.schemeEnd == null || scheme.schemeEnd == ""
        ? null
        : moment(scheme.schemeEnd).format("DD-MM-YYYY");
    state.selectedSchemeCallStartDate =
      scheme.callStart == null || scheme.callStart == ""
        ? null
        : moment(scheme.callStart).format("DD-MM-YYYY");
    state.selectedSchemeCallEndDate =
      scheme.callEnd == null || scheme.callEnd == ""
        ? null
        : moment(scheme.callEnd).format("DD-MM-YYYY");
    state.selectedSchemeRecurringStartDate =
      scheme.recurringStartDate == null || scheme.recurringStartDate == ""
        ? null
        : moment(scheme.recurringStartDate).format("DD-MM-YYYY");

    state.selectedSchemeRecurringEndDate =
      scheme.recurringEndDate == null || scheme.recurringEndDate == ""
        ? null
        : moment(scheme.recurringEndDate).format("DD-MM-YYYY");
    state.selectedSchemeHasRecurringDeadline = scheme.hasRecurringDeadline;
    if (!scheme.hasRecurringDeadline) {
      state.selectedSchemeIsOneOffDeadline = true;
    }
    state.selectedSchemeRecurringStartingYear = scheme.recurringStartingYear;
    state.selectedSchemeRecurringYearsDuration = scheme.recurringYearsDuration;
    state.selectedSchemeBody = scheme.body ?? "";
    state.selectedTransBody = scheme.transBody;
    state.selectedSchemeCriteria = scheme.criteria ?? "";
    state.selectedTransApplicationProcedure = scheme.transApplicationProcedure;
    state.selectedTransApplicationInformation =
      scheme.transApplicationInformation;
    state.selectedTransIntroduction = scheme.transIntroduction;
    state.selectedTransCriteria = scheme.transCriteria;
    state.selectedTransFinancialinformation = scheme.transFinancialinformation;
    state.selectedTransName = scheme.transName;
    state.selectedSchemeArchivecode = scheme.archivecode;
    state.selectedSchemeAccronym = scheme.accronym;
    state.selectedSchemeAdministratingOrgs = scheme.administratingOrganisations;
    state.selectedSchemeAdvisingOrgs = scheme.advisingOrganisations;
    state.selectedSchemeFundingOrgs = scheme.fundingOrganisations;
    state.selectedSchemeRecipientOrgs = scheme.recipientOrganisations;
    state.selectedSchemeTags = scheme.tags;
    state.selectedSchemeIsCall = scheme.isCall;
    state.selectedSchemeIsApplicationPeriod = scheme.isApplicationPeriod;
    state.selectedSchemeBudget = scheme.budget;
    state.selectedSchemeMinimalAge = scheme.minimalAge;
    state.selectedSchemeMaximalAge = scheme.maximalAge;
    state.selectedSchemeMinAmount = scheme.minAmount;
    state.selectedSchemeMaxAmount = scheme.maxAmount;
    state.selectedSchemeICTU = scheme.isICTUScheme;
    state.selectedSchemeIsPortal = scheme.isPortalScheme;
    state.selectedSchemeIsScience = scheme.isScienceScheme;
    state.selectedSchemeIsEnglish = scheme.isEnglishScheme;
    state.selectedSchemeHasEnglishCalls = scheme.hasEnglishCalls;
    state.selectedSchemeIsAlwaysOpen = scheme.isAlwaysOpen;
    state.selectedSchemeExclusion = scheme.exclusion ?? "";
    state.selectedSchemeFinancialInformation =
      scheme.financialInformation ?? "";
    state.selectedSchemeApplicationProcedure = scheme.applicationProcedure;
    state.selectedSchemeApplicationInformation =
      scheme.applicationInformation ?? "";
    state.selectedSchemePortalBody = scheme.portalBody ?? "";
    state.selectedSchemeComplexityRating = scheme.complexityRating;
    state.selectedSchemeChanceOfSuccessRating = scheme.chanceOfSuccessRating;
    state.selectedSchemeTimeForApplicationRating =
      scheme.timeForApplicationRating;
    state.selectedSchemeScope = scheme.scope;
    state.selectedSchemeTerm = scheme.applicationTerm;
    state.selectedSchemeSME = scheme.smeType;
    state.selectedSchemePublicationStatus = scheme.publicationStatus;
    state.selectedSchemeDocuments = scheme.schemeDocuments;
    state.locationTagsSelectBulk = scheme.locationTags;

    if (scheme.locationTags != null && scheme.locationTags.length > 0)
      state.locationTagsSelectedIds = scheme.locationTags.map((l) => l.id);

    for (let i = 0; i < scheme.schemeSDGThemes.length; i++) {
      state.selectedSchemeSDGThemes.push(
        scheme.schemeSDGThemes[i].schemeSDGThemeType
      );
    }

    if (scheme.introduction != null && scheme.introduction.length > 0)
      state.selectedSchemeIntroductionPlain = scheme.introduction.replace(
        /<[^>]+>/g,
        ""
      );
    if (scheme.body != null && scheme.body.length > 0)
      state.selectedSchemeBodyPlain = scheme.body.replace(/<[^>]+>/g, "");

    state.selectedSchemeId = scheme.id;
    state.selectedSchemeDuration = scheme.duration;

    if (state.selectedSchemeLinks.length > 0) state.selectedSchemeLinks = [];

    for (let i = 0; i < scheme.schemeLinks.length; i++) {
      state.selectedSchemeLinks.push({
        id: scheme.schemeLinks[i].id,
        text: scheme.schemeLinks[i].text,
        url: scheme.schemeLinks[i].url,
        documentType: scheme.schemeLinks[i].documentType,
        documentTypeDescription: scheme.schemeLinks[i].documentTypeDescription
      });
    }
  },

  setSelectedSchemeAudienceTags(state, tags) {
    if (state.selectedSchemeAudienceTags.length > 0)
      state.selectedSchemeAudienceTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedSchemeAudienceTags.push(tags[i]);

    const selectedSchemeAudienceIds = tags.map((tag) => tag.id);
    state.schemeAudienceTagsCount = selectedSchemeAudienceIds.length;
    if (state.selectedSchemeAudienceTagsIds.length > 0)
      state.selectedSchemeAudienceTagsIds = [];
    for (let i = 0; i < selectedSchemeAudienceIds.length; i++)
      state.selectedSchemeAudienceTagsIds.push(selectedSchemeAudienceIds[i]);
  },
  setSelectedSchemePurposesTags(state, tags) {
    if (state.selectedSchemePurposesTags.length > 0)
      state.selectedSchemePurposesTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedSchemePurposesTags.push(tags[i]);

    const selectedSchemePurposesIds = tags.map((tag) => tag.id);
    state.schemePurposesTagsCount = selectedSchemePurposesIds.length;
    if (state.selectedSchemePurposesTagsIds.length > 0)
      state.selectedSchemePurposesTagsIds = [];
    for (let i = 0; i < selectedSchemePurposesIds.length; i++)
      state.selectedSchemePurposesTagsIds.push(selectedSchemePurposesIds[i]);
  },
  //This is just hidden for now, don't remove it
  // setSelectedSchemeIndustriesTags(state, tags) {
  //   if (state.selectedSchemeIndustriesTags.length > 0)
  //     state.selectedSchemeIndustriesTags = [];
  //   for (let i = 0; i < tags.length; i++)
  //     state.selectedSchemeIndustriesTags.push(tags[i]);

  //   const selectedSchemeIndustriesIds = tags.map(tag => tag.id);
  //   state.schemeIndustriesTagsCount = selectedSchemeIndustriesIds.length;
  //   if (state.selectedSchemeIndustriesTagsIds.length > 0)
  //     state.selectedSchemeIndustriesTagsIds = [];
  //   for (let i = 0; i < selectedSchemeIndustriesIds.length; i++)
  //     state.selectedSchemeIndustriesTagsIds.push(selectedSchemeIndustriesIds[i]);
  // },
  setSelectedSchemeLocationsTags(state, tags) {
    if (state.selectedSchemeLocationsTags.length > 0)
      state.selectedSchemeLocationsTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedSchemeLocationsTags.push(tags[i]);

    const selectedSchemeLocationsIds = tags.map((tag) => tag.id);
    state.schemeLocationsTagsCount = selectedSchemeLocationsIds.length;
    if (state.selectedSchemeLocationsTagsIds.length > 0)
      state.selectedSchemeLocationsTagsIds = [];
    for (let i = 0; i < selectedSchemeLocationsIds.length; i++)
      state.selectedSchemeLocationsTagsIds.push(selectedSchemeLocationsIds[i]);
  },
  setSelectedSchemeSchemeTypeTags(state, tags) {
    if (state.selectedSchemeSchemeTypesTags.length > 0)
      state.selectedSchemeSchemeTypesTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedSchemeSchemeTypesTags.push(tags[i]);

    const selectedSchemeSchemeTypesIds = tags.map((tag) => tag.id);
    state.schemeSchemeTypesTagsCount = selectedSchemeSchemeTypesIds.length;
    if (state.selectedSchemeSchemeTypesTagsIds.length > 0)
      state.selectedSchemeSchemeTypesTagsIds = [];
    for (let i = 0; i < selectedSchemeSchemeTypesIds.length; i++)
      state.selectedSchemeSchemeTypesTagsIds.push(
        selectedSchemeSchemeTypesIds[i]
      );
  },
  setSelectedSchemeIndustriesNEWTags(state, tags) {
    if (state.selectedSchemeIndustriesNewTags.length > 0)
      state.selectedSchemeIndustriesNewTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedSchemeIndustriesNewTags.push(tags[i]);

    const selectedSchemeIndustriesNewIds = tags.map((tag) => tag.id);
    state.schemeIndustriesNewTagsCount = selectedSchemeIndustriesNewIds.length;
    if (state.selectedSchemeIndustriesNewTagsIds.length > 0)
      state.selectedSchemeIndustriesNewTagsIds = [];
    for (let i = 0; i < selectedSchemeIndustriesNewIds.length; i++)
      state.selectedSchemeIndustriesNewTagsIds.push(
        selectedSchemeIndustriesNewIds[i]
      );
  },
  setSelectedSchemeUserStatusTags(state, tags) {
    if (state.selectedSchemeUserStatusTags.length > 0)
      state.selectedSchemeUserStatusTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedSchemeUserStatusTags.push(tags[i]);

    const selectedSchemeUserStatusIds = tags.map((tag) => tag.id);
    state.schemeUserStatusTagsCount = selectedSchemeUserStatusIds.length;
    if (state.selectedSchemeUserStatusTagsIds.length > 0)
      state.selectedSchemeUserStatusTagsIds = [];
    for (let i = 0; i < selectedSchemeUserStatusIds.length; i++)
      state.selectedSchemeUserStatusTagsIds.push(
        selectedSchemeUserStatusIds[i]
      );
  },
  setUserStatusTagsIds(state, val) {
    if (state.selectedSchemeUserStatusTagsIds.length > 0)
      state.selectedSchemeUserStatusTagsIds = [];

    for (let i = 0; i < val.length; i++)
      state.selectedSchemeUserStatusTagsIds.push(val[i]);

    state.schemeUserStatusTagsCount = val.length;
  },
  setSelectedSchemeEducationLevelTags(state, tags) {
    if (state.selectedSchemeEducationLevelTags.length > 0)
      state.selectedSchemeEducationLevelTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedSchemeEducationLevelTags.push(tags[i]);

    const selectedSchemeEducationLevelIds = tags.map((tag) => tag.id);
    state.schemeEducationLevelTagsCount =
      selectedSchemeEducationLevelIds.length;
    if (state.selectedSchemeEducationLevelTagsIds.length > 0)
      state.selectedSchemeEducationLevelTagsIds = [];
    for (let i = 0; i < selectedSchemeEducationLevelIds.length; i++)
      state.selectedSchemeEducationLevelTagsIds.push(
        selectedSchemeEducationLevelIds[i]
      );
  },
  setEducationLevelTagsIds(state, val) {
    if (state.selectedSchemeEducationLevelTagsIds.length > 0)
      state.selectedSchemeEducationLevelTagsIds = [];

    for (let i = 0; i < val.length; i++)
      state.selectedSchemeEducationLevelTagsIds.push(val[i]);

    state.schemeEducationLevelTagsCount = val.length;
  },
  setSelectedSchemeProjectTypeTags(state, tags) {
    if (state.selectedSchemeProjectTypesTags.length > 0)
      state.selectedSchemeProjectTypesTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedSchemeProjectTypesTags.push(tags[i]);

    const selectedSchemeProjectTypesIds = tags.map((tag) => tag.id);
    state.schemeProjectTypesTagsCount = selectedSchemeProjectTypesIds.length;
    if (state.selectedSchemeProjectTypesTagsIds.length > 0)
      state.selectedSchemeProjectTypesTagsIds = [];
    for (let i = 0; i < selectedSchemeProjectTypesIds.length; i++)
      state.selectedSchemeProjectTypesTagsIds.push(
        selectedSchemeProjectTypesIds[i]
      );
  },
  setSelectedSchemeKeywordsTags(state, tags) {
    state.selectedSchemeKeywordsTags = tags.map((tag) => tag.name);
  },
  updateAudienceCount(state, val) {
    state.schemeAudienceTagsCount = val;
  },
  //This is just hidden for now, don't remove it
  /*
    updateIndustriesCount(state, val)
    {
      state.schemeIndustriesTagsCount = val;
    },
    */
  updatePurposesCount(state, val) {
    state.schemePurposesTagsCount = val;
  },
  updateLocationsCount(state, val) {
    state.schemeLocationsTagsCount = val;
  },
  updateSchemeTypesCount(state, val) {
    state.schemeSchemeTypesTagsCount = val;
  },
  updateProjectTypesCount(state, val) {
    state.schemeProjectTypesTagsCount = val;
  },
  updateIndustriesNewCount(state, val) {
    state.schemeIndustriesNewTagsCount = val;
  },
  updateUserStatusCount(state, val) {
    state.schemeUserStatusTagsCount = val;
  },
  updateEducationLevelCount(state, val) {
    state.schemeEducationLevelTagsCount = val;
  },
  updateSchemeName(state, name) {
    state.selectedSchemeName = name;
  },
  updateSchemeStatus(state, value) {
    state.selectedSchemeStatus = value;
  },
  updateSchemeFundingOrgs(state, value) {
    state.selectedSchemeFundingOrgs = value;
  },
  updateSchemeAdministratingOrgs(state, value) {
    state.selectedSchemeAdministratingOrgs = value;
  },
  updateSchemeAdvisingOrgs(state, value) {
    state.selectedSchemeAdvisingOrgs = value;
  },
  updateSchemeIsPortal(state, val) {
    state.selectedSchemeIsPortal = val;
  },
  updateSchemeIsScience(state, val) {
    state.selectedSchemeIsScience = val;
  },
  updateSchemeIsEnglish(state, val) {
    state.selectedSchemeIsEnglish = val;
  },
  updateSchemeHasEnglishCalls(state, val) {
    state.selectedSchemeHasEnglishCalls = val;
  },
  updateSchemeIsAlwaysOpen(state, val) {
    state.selectedSchemeIsAlwaysOpen = val;
  },
  updateSchemeRecipientOrgs(state, value) {
    state.selectedSchemeRecipientOrgs = value;
  },
  updateSchemeIntroduction(state, value) {
    state.selectedSchemeIntroduction = value;
  },
  updateSchemeIntroductionPlain(state, value) {
    state.selectedSchemeIntroductionPlain = value;
  },

  updateSchemeStart(state, value) {
    state.selectedSchemeStartDate = value;
  },

  updateSchemeEnd(state, value) {
    state.selectedSchemeEndDate = value;
  },

  updateSchemeCallStart(state, value) {
    state.selectedSchemeCallStartDate = value;
  },

  updateSchemeCallEnd(state, value) {
    state.selectedSchemeCallEndDate = value;
  },

  updateSchemeBudget(state, value) {
    state.selectedSchemeBudget = value;
  },

  setSelectedNode(state, node) {
    state.selectedNode = node;
  },

  updateCreatedSchemeId(state, id) {
    state.createdSchemeId = id;
  },
  updateSchemeBody(state, value) {
    state.selectedSchemeBody = value;
  },
  updateSchemeBodyPlain(state, value) {
    state.selectedSchemeBodyPlain = value;
  },
  updateSchemeCriteria(state, value) {
    state.selectedSchemeCriteria = value;
  },
  updateSchemeIsOB(state, value) {
    state.selectedSchemeIsOBScheme = value;
  },
  updateSchemeOBDescription(state, value) {
    state.selectedSchemeOBDescription = value;
  },
  updateSchemeOBTitle(state, value) {
    state.selectedSchemeOBTitle = value;
  },
  updateSchemeMinimalAge(state, value) {
    state.selectedSchemeMinimalAge = value;
  },
  updateSchemeMaximalAge(state, value) {
    state.selectedSchemeMaximalAge = value;
  },
  updateSchemeMinAmount(state, value) {
    state.selectedSchemeMinAmount = value;
  },
  updateSchemeMaxAmount(state, value) {
    state.selectedSchemeMaxAmount = value;
  },
  updateSchemeICTU(state, value) {
    state.selectedSchemeICTU = value;
  },
  updateSchemeExclusion(state, value) {
    state.selectedSchemeExclusion = value;
  },
  updateSchemeFinancialInformation(state, value) {
    state.selectedSchemeFinancialInformation = value;
  },
  updateSchemeApplicationProcedure(state, value) {
    state.selectedSchemeApplicationProcedure = value;
  },
  updateSchemePortalBody(state, value) {
    state.selectedSchemePortalBody = value;
  },
  updateSchemeComplexityRating(state, value) {
    state.selectedSchemeComplexityRating = value;
  },
  updateSchemeChanceOfSuccessRating(state, value) {
    state.selectedSchemeChanceOfSuccessRating = value;
  },
  updateSchemeTimeForApplicationRating(state, value) {
    state.selectedSchemeTimeForApplicationRating = value;
  },
  updateSchemeApplicationInformation(state, value) {
    state.selectedSchemeApplicationInformation = value;
  },
  updateSchemeKeywordsTags(state, value) {
    state.selectedSchemeKeywordsTags = value;
  },
  updateLocationTagsSelectedBulk(state, value) {
    state.locationTagsSelectBulk = value;
    state.locationTagsSelectedIds.length = 0;
    state.locationTagsSelectedIds = value.map((l) => l.id);
  },
  updateSchemes(state, nodes) {
    state.schemesIndex = [];
    state.schemeNodes = nodes.allActivities.options;
    state.schemeTreeLabel = nodes.allActivities.label;
    for (let i = 0; i < state.schemeNodes.length; i++) {
      state.totalNodes++;
      if (state.schemeNodes[i].children.length > 0) {
        for (let j = 0; j < state.schemeNodes[i].children.length; j++) {
          state.totalNodes++;
        }
      }
    }

    var counter = 0;

    for (let i = 0; i < state.schemeNodes.length; i++) {
      if (
        typeof state.schemeNodes[i].label != undefined &&
        state.schemeNodes[i].label != undefined
      )
        state.schemesIndex[i] = {
          label: state.schemeNodes[i].label,
          index: counter,
          id: state.schemeNodes[i].id,
          parent: 0,
          children: [],
        };
      counter++;
      if (state.schemeNodes[i].children.length > 0) {
        for (let j = 0; j < state.schemeNodes[i].children.length; j++) {
          state.schemesIndex[i].children.push({
            label: state.schemeNodes[i].children[j].label,
            id: state.schemeNodes[i].children[j].id,
            index: counter,
            parent: state.schemeNodes[i],
          });
          counter++;
        }
      }
    }
  },

  enableSpinner(state) {
    state.spinner = true;
  },

  disableSpinner(state) {
    state.spinner = false;
  },

  resetModalError(state) {
    state.modalErrorMessage = "";
  },

  enableModalError(state) {
    state.hasModalError = true;
  },
  disableModalError(state) {
    state.hasModalError = false;
    state.modalErrorMessage = "";
  },
  updateModalError(state, message) {
    state.modalErrorMessage = message;
  },

  updateNodeAndParent(state, selected) {
    for (let i = 0; i < state.schemeNodes.length; i++) {
      if (state.schemesIndex[i].index == selected) {
        state.selectedNodeAndParent = {
          nodeId: state.schemesIndex[i].id,
          parent:
            state.schemesIndex[i].parent == 0
              ? 0
              : state.schemesIndex[i].parent.id,
        };
        break;
      } else if (state.schemesIndex[i].children.length > 0) {
        for (let j = 0; j < state.schemesIndex[i].children.length; j++) {
          if (state.schemesIndex[i].children[j].index == selected) {
            state.selectedNodeAndParent = {
              nodeId: state.schemesIndex[i].children[j].id,
              parent: state.schemesIndex[i].children[j].parent,
            };
            break;
          }
        }
      }
    }
  },
  //fill trees options and labels on edit scheme page
  updateAudienceTree(state, data) {
    state.audienceTags = data.allActivities;
  },
  updatePurposesTree(state, data) {
    state.purposesTags = data.allActivities;
  },
  updateLocationsTree(state, data) {
    state.locationsTags = data.allActivities;
  },
  //This is just hidden for now, don't remove it
  /*
    updateIndustriesTree(state, data)
    {
      state.industriesTags = data.allActivities;
    },
    */
  updateSchemetypeTree(state, data) {
    state.schemeTypesTags = data.allActivities;
  },
  updateProjecttypeTree(state, data) {
    state.projectTypesTags = data.allActivities;
  },
  updateIndustriesNEWTree(state, data) {
    state.industriesNewTags = data.allActivities;
  },
  updateUserStatusTree(state, data) {
    state.userStatusTags = data.allActivities;
  },
  updateEducationLevelTree(state, data) {
    state.educationLevelTags = data.allActivities;
  },
  updateSchemeCallsAndApplicationPeriodsList(state, list) {
    if (state.selectedSchemeCallsAndApplicationPeriods.length > 0)
      state.selectedSchemeCallsAndApplicationPeriods = [];

    for (let i = 0; i < list.length; i++) {
      state.selectedSchemeCallsAndApplicationPeriods.push({
        id: list[i].id,
        name: list[i].name,
        identifier: list[i].identifier,
        isAlwaysOpen: list[i].isAlwaysOpen,
        recurringYearsDuration: list[i].recurringYearsDuration,

        callStart:
          list[i].callStart == null || list[i].callStart == ""
            ? null
            : moment(list[i].callStart).format("DD-MM-YYYY"),
        callEnd:
          list[i].callEnd == null || list[i].callEnd == ""
            ? null
            : moment(list[i].callEnd).format("DD-MM-YYYY"),
      });
    }
  },
  updateSchemeCallsAndApplicationPeriodsPagination(state, pagination) {
    state.callsAndApplicationPeriodsPagination.rowsPerPage = pagination.size;
    state.callsAndApplicationPeriodsPagination.rowsNumber = pagination.count;
  },
  updateCallsAndApplicationPeriodsPaginationPage(state, pagination) {
    state.callsAndApplicationPeriodsPagination.sortBy = pagination.sortBy;
    state.callsAndApplicationPeriodsPagination.descending =
      pagination.descending;
    state.callsAndApplicationPeriodsPagination.page = pagination.page;
  },
  updateSelectedSchemeLinks(state, links) {
    if (state.selectedSchemeLinks.length > 0) state.selectedSchemeLinks = [];

    for (let i = 0; i < links.length; i++) {
      state.selectedSchemeLinks.push({
        id: links[i].id,
        text: links[i].text,
        url: links[i].url,
        documentType: links[i].documentType,
        documentTypeDescription: links[i].documentTypeDescription
      });
    }
  },
  refreshSchemeDocuments(state, docs) {
    if (state.selectedSchemeDocuments.length > 0)
      state.selectedSchemeDocuments = [];

    state.selectedSchemeDocuments = docs;
  },
  updateSchemeUpdatesList(state, list) {
    if (state.selectedSchemeUpdates.length > 0)
      state.selectedSchemeUpdates = [];

    for (let i = 0; i < list.length; i++) {
      state.selectedSchemeUpdates.push({
        id: list[i].id,
        updateReason: list[i].updateReason,
        updateDateTime:
          list[i].updateDateTime == null || list[i].updateDateTime == ""
            ? null
            : moment(list[i].updateDateTime).format("DD-MM-YYYY"),
        updateCreatedBy: list[i].updateCreatedBy,
        updateUpdatedBy: list[i].updateUpdatedBy,
        adding: false,
        editing: false,
      });
    }
  },
  updateSchemeUpdatesPagination(state, pagination) {
    state.schemeUpdatesPagination.rowsPerPage = pagination.size;
    state.schemeUpdatesPagination.rowsNumber = pagination.count;
  },
  updateSchemeUpdatesPaginationPage(state, pagination) {
    state.schemeUpdatesPagination.sortBy = pagination.sortBy;
    state.schemeUpdatesPagination.descending = pagination.descending;
    state.schemeUpdatesPagination.page = pagination.page;
  },
  updateSchemeUpdates(state, updates) {
    if (state.selectedSchemeUpdates.length > 0)
      state.selectedSchemeUpdates = [];

    for (let i = 0; i < updates.length; i++) {
      state.selectedSchemeUpdates.push({
        id: updates[i].id,
        updateReason: updates[i].updateReason,
        updateDateTime:
          updates[i].updateDateTime == null || updates[i].updateDateTime == ""
            ? null
            : moment(updates[i].updateDateTime).format("DD-MM-YYYY"),
        updateCreatedBy: updates[i].updateCreatedBy,
        updateUpdatedBy: updates[i].updateUpdatedBy,
        adding: updates[i].adding,
        editing: updates[i].editing,
      });
    }
  },
  enableShowSelectTags(state) {
    state.showSelectTags = true;
  },
  disableShowSelectTags(state) {
    state.showSelectTags = false;
  },
  updateShowAddLink(state, val) {
    state.showAddLink = val;
  },
  updateShowEditLink(state, val) {
    state.showEditLink = val;
  },
  updateShowAddDocument(state, val) {
    state.showAddDocument = val;
  },
  updateShowEditDocument(state, val) {
    state.showEditDocument = val;
  },
  setSelectedLink(state, val) {
    state.selectedLink = val;

    if (val !== null) {
      state.selectedLinkText = val.text;
      state.selectedLinkUrl = val.url;
      state.selectedLinkDocumentType = val.documentType;
    }
  },
  setSelectedDocument(state, val) {
    state.selectedDocument = val;

    if (val !== null) {
      state.selectedDocumentName = val.name;
      state.selectedDocumentDocumentType = val.documentType;

      if (val.name === "" && val.file !== undefined)
        state.selectedDocumentName = val.file.name;
    }
  },
  updateSelectedLinkText(state, val) {
    state.selectedLinkText = val;
  },
  updateSelectedLinkUrl(state, val) {
    state.selectedLinkUrl = val;
  },
  updateSelectedLinkDocumentType(state, val) {
    state.selectedLinkDocumentType = val;
  },
  updateSelectedDocumentName(state, val) {
    state.selectedDocumentName = val;
  },
  updateSelectedDocumentDocumentType(state, val) {
    state.selectedDocumentDocumentType = val;
  },
  updateShowVisualPingDialog(state, val) {
    state.showVisualPingDialog = val;
  },
  updateSchemeVisualPingLinks(state, links) {
    if (state.selectedSchemeVisualPingLinks.length > 0)
      state.selectedSchemeVisualPingLinks = [];

    for (let i = 0; i < links.length; i++) {
      state.selectedSchemeVisualPingLinks.push(links[i]);
    }
  },
  updateVisualPingLinkWithChanges(state, linkWithNotifications) {
    state.visualPingLinkWithChanges = linkWithNotifications;
  },
  updateShowAddVisualPingLink(state, val) {
    state.showAddVisualPingLink = val;
  },
  updateShowEditVisualPingLink(state, val) {
    state.showEditVisualPingLink = val;
  },
  setSelectedVpLinkToEdit(state, val) {
    state.selectedVpLinkToEdit = val;

    if (val !== null) {
      state.selectedVpLinkToEditText = val.text;
      state.selectedVpLinkToEditUrl = val.url;
    }
  },
  updateSelectedVpLinkToEditText(state, val) {
    state.selectedVpLinkToEditText = val;
  },
  updateSelectedVpLinkToEditUrl(state, val) {
    state.selectedVpLinkToEditUrl = val;
  },
  updateVisualpingColorForSelectedScheme(state, { schemeId, color }) {
    var itemIndex = state.schemes.findIndex((s) => s.id == schemeId);
    state.schemes[itemIndex] = {
      ...state.schemes[itemIndex],
      visualpingColor: color,
    };
  },
  updateSDGThemeTypes(state, val) {
    state.schemeSDGThemeTypes = val;
  },
  updateSelectedSchemeSDGThemes(state, val) {
    state.selectedSchemeSDGThemes = val;
  },
  fillSchemeStatusOptions(state,val) {
    state.schemeStatuses = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
