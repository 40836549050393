<template>
  <q-btn class="orange-btn bold-font" no-caps @click="dialog = true">
    <i class="las la-2x la-copy"></i>Copy scheme
  </q-btn>

  <q-dialog v-model="dialog" class="modal-general">
    <q-card class="bg-grey-1 text-black">
      <q-card-section>
        <label style="font-size: 16px"
          >Do you want to copy this schemes children/calls as well?</label
        >
      </q-card-section>

      <q-card-actions class="row justify-end">
        <q-btn
          class="orange-btn-filled bold-font"
          no-caps
          dense
          label="Yes"
          @click="copyScheme(id, true)"
        />
        <q-btn
          class="orange-btn-filled bold-font"
          no-caps
          dense
          label="No"
          @click="copyScheme(id, false)"
        />
        <q-btn
          dense
          class="orange-btn bold-font"
          no-caps
          label="Cancel"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue';
import SchemesServices from '../../services/schemes.services';
import { useRoute } from 'vue-router';
export default {
  setup() {
    const route = useRoute();
    const dialog = ref(false);
    const id = route.params.id;

    const copyScheme = async (id, copyChildren) => {
      await SchemesServices.copyScheme(id, copyChildren);
      dialog.value = false;
    };

    return {
      route,
      dialog,
      copyScheme,
      id,
    };
  },
};
</script>
