<template>
  <q-dialog class="add-box column no-wrap" v-model="showAddLocationGroup">
    <q-card>
      <q-card-section>
        <span>Add location group</span>
        <div class="column">
          <span>Name: </span>
          <q-input
            v-model="newNodeLabel"
            :rules="requiredFieldRule"
            placeholder="Enter name"
            dense
          />
        </div>
        <q-tree
          ref="locationsTree"
          class="col-12 col-sm-6"
          :nodes="locationsNodes"
          node-key="id"
          tick-strategy="leaf"
          v-model:ticked="tickedLocations"
          v-model:expanded="expandedLocations"
          @update:ticked="updateLocationsPairs(tickedLocations)"
          no-transition
        />
      </q-card-section>
      <q-card-actions>
        <q-btn
          class="orange-btn-filled bold-font"
          no-caps
          label="Save"
          type="submit"
          @click="createNewLocationGroup(newNodeLabel)"
        />
        <q-btn
          class="orange-btn bold-font"
          no-caps
          label="Cancel"
          @click="disableAdd()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, ref } from "vue";
import store from "../../store";
import TagService from "../../services/tags.service";

export default {
  setup() {
    const newNodeLabel = ref("");
    const tickedLocations = ref(0);
    const expandedLocations = ref(0);
    const locationsTree = ref(null);
    const locationsPairs = ref([]);
    const locationPairsIds = ref([]);
    const showAddLocationGroup = computed({
      get: () => store.state.tree.showCreateLocationGroup,
    });
    const locationsNodes = computed({
      get: () => store.state.tree.treeNodes,
    });
    const disableAdd = async () => {
      store.commit("tree/disableCreateLocationGroup");
      await TagService.getAllLocationGroupTags();
    };

    const createNewLocationGroup = async (locationGroupName) => {
      await TagService.createLocationGroupTree(
        locationGroupName,
        locationPairsIds.value
      );
      disableAdd();
    };
    /* old 
   const findParent = (nodes, childId) => {
      const stack = [...nodes];
      const parentMap = new Map(); // Map to store child-parent relationships

      // Build the parent mapping
      while (stack.length) {
        const node = stack.pop();
        if (node.children) {
          for (const child of node.children) {
            parentMap.set(child.id, node);
            stack.push(child);
          }
        }
      }

      // Find the parent of the given childId
      return parentMap.get(childId) || null;
    };

    const findGrandParent = (nodes, childId) => {
      const stack = [...nodes];

      //Parent-child relationship  mapping
      const parentMap = new Map();

      while (stack.length) {
        const node = stack.pop();
        if (node.children) {
          for (const child of node.children) {
            parentMap.set(child.id, node);
            stack.push(child);
          }
        }
      }

      // Finding parent and grandparent of the given childId
      const parent = parentMap.get(childId);
      if (parent) {
        return parentMap.get(parent.id) || null;
      }
      return null;
    };

    const updateLocationsPairs = (keys) => {
      let uniqueLocationsPairs = new Set();
      let uniqueLocationsPairsIds = new Set();

      if (keys.length > 0) {
        keys.forEach((key) => {
          const node = locationsTree.value.getNodeByKey(key);
          if (node) {
            const parentNode = findParent(locationsNodes.value, node.id);
            const grandParentNode = findGrandParent(
              locationsNodes.value,
              node.id
            );

            if (parentNode) {
              uniqueLocationsPairs.add(parentNode);
              uniqueLocationsPairsIds.add(parentNode.id);
            }

            if (grandParentNode) {
              uniqueLocationsPairs.add(grandParentNode);
              uniqueLocationsPairsIds.add(grandParentNode.id);
            }

            uniqueLocationsPairs.add(node);
            uniqueLocationsPairsIds.add(node.id);
          }
        });
      }

      locationsPairs.value = Array.from(uniqueLocationsPairs);
      locationPairsIds.value = Array.from(uniqueLocationsPairsIds);
      store.commit("tree/updateLocationTickedTags", tickedLocations);
    };
*/
    const updateLocationsPairs = (keys) => {
      locationsPairs.value.length = 0;
      locationPairsIds.value.length = 0;

      if (keys.length === 0) {
        return; // No keys to process
      }

      const checkedKeys = new Set(keys);
      const tickedLocations = new Set();

      const processNode = (node, parentNode = null, grandParentNode = null) => {
        if (!node.children || node.children.length === 0) {
          if (checkedKeys.has(node.id)) {
            locationsPairs.value.push(node);
            locationPairsIds.value.push(node.id);
            tickParentNodes(node, parentNode, grandParentNode);
          }
          return checkedKeys.has(node.id);
        }

        let anyChildTicked = false;
        for (const child of node.children) {
          const childTicked = processNode(child, node, parentNode);
          if (childTicked) {
            anyChildTicked = true;
          }
        }

        if (anyChildTicked) {
          locationsPairs.value.push(node);
          locationPairsIds.value.push(node.id);
          tickParentNodes(node, parentNode, grandParentNode);
        }

        return anyChildTicked;
      };

      const tickParentNodes = (node, parentNode, grandParentNode) => {
        if (parentNode) {
          tickedLocations.add(parentNode.id);
        }
        if (grandParentNode) {
          tickedLocations.add(grandParentNode.id);
        }
      };

      locationsNodes.value.forEach((node) => {
        processNode(node);
      });

      store.commit(
        "tree/updateLocationTickedTags",
        Array.from(tickedLocations)
      );
    };

    return {
      showAddLocationGroup,
      disableAdd,
      locationsNodes,
      newNodeLabel,
      expandedLocations,
      tickedLocations,
      createNewLocationGroup,
      locationPairsIds,
      locationsTree,
      locationsPairs,
      updateLocationsPairs,
      // findParent,
      // findGrandParent,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || "Field is required!",
      ],
    };
  },
};
</script>
