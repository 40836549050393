<template>
    <div class="tree-container">
    <div class="tree-search">
      <q-input
        ref="filterRef"
        v-model="filter"
        class="tree-searchbox"
        dense
        outlined
        bg-color="white"
        label="Search"
      >
        <template v-slot:append>
          <q-icon
            v-if="filter !== ''"
            name="clear"
            class="cursor-pointer"
            @click="resetFilter"
          />
        </template>
      </q-input>
    </div>
      <q-btn class="orange-btn-filled bold-font" no-caps @click="displayCreate()"
      >Add new</q-btn
    >
    <q-tree
      :nodes="treeNodesState"
      v-model:selected="selected"
      node-key="id"
      text-color="black"
      class="tree"
      :filter="filter"
      :filter-method="customFilterMethod"
      @update:selected="updateNodeParent()"
      v-model:ticked="ticked"
      @update:ticked="
        updatePairs(ticked);
        updateSelected(ticked);
      "
      ref="tree"
    >
      <template v-slot:default-header="prop">
        <div class="row items-center" style="width: max-content">
          <div
            class="text-weight-bold node-item-label"
            style="width: max-content; max-width: 350px"
          >
            {{ prop.node.label }}
          </div>
          <div class="edit-icon row">
            <div>
              <i
                class="las la-2x la-pen icon-color-vnd"
                @click="displayEdit(prop.node.id)"
              >
              </i>
              <q-tooltip> Edit </q-tooltip>
            </div>

            <div>
              <i
                class="las la-2x la-plus-square icon-color-vnd"
                @click="displayAdd(prop.node.id)"
              ></i>
              <q-tooltip> Add parent/child</q-tooltip>
            </div>
            <div @click="OrderUp(prop.node.id)">
              <i class="las la-2x la-caret-square-up icon-color-vnd"></i>
              <q-tooltip> Move Order Up</q-tooltip>
            </div>
            <div>
              <i
                class="las la-2x la-caret-square-down icon-color-vnd"
                @click="OrderDown(prop.node.id)"
              ></i>
              <q-tooltip> Move Order Down</q-tooltip>
            </div>
          </div>
        </div>
      </template>
    </q-tree>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import store from '../../store/index.js';
import TagsService from '../../services/tags.service';

export default {
  props: {
    languageCode: String,
    tagType: Number
  },

  setup(props) {
    const selected = ref(null);
    const langCode = ref(props.languageCode);

    const tagTypeValue = ref(props.tagType);

    const parent = computed({
      get: () => store.state.tree.selectedNodeAndParent.parent,
    });

    const updateNodeParent = () => {
      if (langCode.value == "nl")
        store.commit('tree/updateNodeAndParent', selected.value);
      else if (langCode.value == "de")
        store.commit('tree/updateNodeAndParentDe', selected.value);
    };

    const displayEdit = (nodeId) => {
      TagsService.getTagById(nodeId);

      if (langCode.value == "nl")
        store.commit('tree/setSelectedNode', nodeId);
      else if (langCode.value == "de")
        store.commit('tree/setSelectedNodeDe', nodeId);

      store.commit('tree/enableEdit', langCode.value);
    };

    const OrderUp = (nodeId) => {
      store.commit('tree/orderUp');
      setTimeout(async () => {
        await TagsService.updateTagOrder(
          nodeId,
          OrderUpState,
          OrderDownState,
          parent.value.id,
          tagTypeValue
        );

        if (langCode.value == "nl")
          store.commit('tree/setSelectedNode', nodeId);
        else if (langCode.value == "de")
          store.commit('tree/setSelectedNodeDe', nodeId);

        await TagsService.getTagsTree(tagTypeValue, langCode.value);
      }, 500);
    };
 
    const OrderDown = async (nodeId) => {
      store.commit('tree/orderDown');
      setTimeout(async () => {
        await TagsService.updateTagOrder(
          nodeId,
          OrderUpState,
          OrderDownState,
          parent.value.id,
          tagTypeValue
        );

        if (langCode.value == "nl")
          store.commit('tree/setSelectedNode', nodeId);
        else if (langCode.value == "de")
          store.commit('tree/setSelectedNodeDe', nodeId);

        await TagsService.getTagsTree(tagTypeValue, langCode.value);
      }, 500);
    };

    const displayAdd = (nodeId) => {
      if (langCode.value == "nl")
        store.commit('tree/setSelectedNode', nodeId);
      else if (langCode.value == "de")
        store.commit('tree/setSelectedNodeDe', nodeId);

      store.commit('tree/enableAdd', langCode.value);
    };
    const displayCreate = () => {
      store.commit('tree/enableCreate', langCode.value);
    };
    const OrderUpState = computed({
      get: () => store.state.tree.orderUp,
    });
    const OrderDownState = computed({
      get: () => store.state.tree.orderDown,
    });

    const parentNode = computed({
      get: () => store.state.tree.selectedNodeParent,
    });

    const showEdit = computed({
      get: () => store.state.tree.showEdit,
    });

    const showAdd = computed({
      get: () => store.state.tree.showAdd,
    });

    const showCreate = computed({
      get: () => store.state.tree.showCreate,
    });

    const nodeAndParent = computed({
      get: () => store.state.tree.selectedNodeParent,
    });

    const filter = ref('');
    const filterRef = ref(null);
    const ticked = ref([]);
    const pairs = ref([]);
    const tree = ref(null);

    //just to add parent to selected
    const updatePairs = (keys) => {
      pairs.value = [];
      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          let node = tree.value.getNodeByKey(keys[i]);
          pairs.value.push(node);
        }
        var toAdd = [];
        for (let i = 0; i < props.nodesState.length; i++) {
          if (
            props.nodesState[i].children &&
            props.nodesState[i].children.length > 0
          ) {
            var hasAllChildrenTicked = true;

            for (let j = 0; j < props.nodesState[i].children.length; j++) {
              if (
                props.nodesState[i].children[j] &&
                props.nodesState[i].children[j].length > 0
              ) {
                var hasAllSubChildrenTicked = true;
                for (
                  let k = 0;
                  k < props.nodesState[i].children[j].length;
                  k++
                ) {
                  if (
                    !keys.includes(
                      props.nodesState[i].children[j].children[k].id
                    )
                  ) {
                    hasAllSubChildrenTicked = false;
                    break;
                  }
                }
                if (hasAllSubChildrenTicked)
                  toAdd.push(props.nodesState[i].children[j]);
              }
              if (!keys.includes(props.nodesState[i].children[j].id)) {
                hasAllChildrenTicked = false;
                break;
              }

              if (hasAllChildrenTicked) toAdd.push(props.nodesState[i]);
            }
          } else {
            hasAllChildrenTicked = false;
          }
        }
        if (toAdd.length > 0) {
          for (let i = 0; i < toAdd.length; i++) pairs.value.push(toAdd[i]);
        }
      }
    };

    const treeNodesState = computed({
      get: () => langCode.value == "de" ? store.state.tree.treeNodesDe : store.state.tree.treeNodes,
    });

    return {
      langCode,
      treeNodesState,
      updatePairs,
      pairs,
      ticked,
      tagTypeValue,
      parent,
      filter,
      OrderUp,
      OrderDown,
      OrderUpState,
      OrderDownState,
      filterRef,
      parentNode,
      showEdit,
      showCreate,
      showAdd,
      selected,
      updateNodeParent,
      displayAdd,
      nodeAndParent,
      displayEdit,
      displayCreate,
      customFilterMethod(node, filter) {
        const filt = filter.toLowerCase();
        return node.label && node.label.toLowerCase().indexOf(filt) > -1;
      },
      resetFilter() {
        filter.value = '';
        filterRef.value.focus();
      },
    };
  },
};
</script>
