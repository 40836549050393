<template>
  <div v-if="show">
    <div class="advanced-filters">
      <div class="input-box">
        <span class="input-box-title"> Applies to SME? </span>
        <q-checkbox
          size="sm"
          v-model="sme"
          val="sm"
          label="SME"
          class="status-checkbox"
          @click="updateSme(sme)"
        />
        <q-checkbox
          size="sm"
          v-model="notSme"
          val="sm"
          label="Not SME"
          class="status-checkbox"
          @click="updateNotSme(notSme)"
        />
        <q-checkbox
          size="sm"
          v-model="bothSme"
          val="sm"
          label="Both"
          class="status-checkbox"
          @click="updateBothSme(bothSme)"
        />
      </div>

      <div class="input-box">
        <span class="input-box-title"> Application term? </span>
        <q-checkbox
          size="sm"
          v-model="before"
          val="sm"
          label="Vooraf"
          class="status-checkbox"
          @click="updateTermBefore(before)"
        />
        <q-checkbox
          size="sm"
          v-model="after"
          val="sm"
          label="Achteraf"
          class="status-checkbox"
          @click="updateTermAfter(After)"
        />
        <q-checkbox
          size="sm"
          v-model="beforeAndAfter"
          val="sm"
          label="Vooraf en achteraf"
          class="status-checkbox"
          @click="updateTermBoth(beforeAndAfter)"
        />
      </div>
      <div class="input-box">
        <span class="input-box-title"> Scope </span>
        <q-checkbox
          size="sm"
          v-model="european"
          val="sm"
          label="European"
          @click="updateEuropean(european)"
        />
        <q-checkbox
          size="sm"
          v-model="national"
          val="sm"
          label="National"
          @click="updateNational(national)"
        />
        <q-checkbox
          size="sm"
          v-model="regional"
          val="sm"
          label="Regional"
          @click="updateRegional(regional)"
        />
        <q-checkbox
          size="sm"
          v-model="local"
          val="sm"
          label="Local"
          @click="updateLocal(local)"
        />
      </div>
    </div>

    <div class="button-area-advanced">
      <q-btn
        color="secondary"
        label="Hide advanced filters"
        class="hide-button"
        style="width: 100%"
        @click="hideAdvanced()"
      />
    </div>
  </div>

  <div v-else class="button-show">
    <q-btn
      color="primary"
      label="Show advanced filters"
      style="width: 100%"
      @click="showAdvanced()"
    >
    </q-btn>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import store from '../../store/index';
import FiltersService from '../../services/filters.service';
import SchemeUtils from '../../utils/schemes';

export default {
  setup() {
    //sme load
    const sme = ref(false);
    const notSme = ref(false);
    const bothSme = ref(false);
    //term load
    const before = ref(false);
    const after = ref(false);
    const beforeAndAfter = ref(false);
    //scope load
    const european = ref(false);
    const regional = ref(false);
    const local = ref(false);
    const national = ref(false);
    //get show load
    const show = computed({
      get: () => store.state.advancedFilters.showAdvancedFilters,
    });

    //store commit show/hide
    const showAdvanced = () => {
      FiltersService.getApplicationTerms();
      store.commit('advancedFilters/enableAdvanced');
    };
    const hideAdvanced = () => {
      store.commit('advancedFilters/disableAdvanced');
    };

    //store commit Application terms
    const updateTermBefore = (val) => {
      store.commit('advancedFilters/updateTermBefore', val);
      SchemeUtils.searchSchemes();
    };
    const updateTermAfter = (val) => {
      store.commit('advancedFilters/updateTermAfter', val);
      SchemeUtils.searchSchemes();
    };
    const updateTermBoth = (val) => {
      store.commit('advancedFilters/updateTermBoth', val);
      SchemeUtils.searchSchemes();
    };

    //store commit SME
    const updateSme = (val) => {
      store.commit('advancedFilters/updateSme', val);
      SchemeUtils.searchSchemes();
    };
    const updateNotSme = (val) => {
      store.commit('advancedFilters/updateNotSme', val);
      SchemeUtils.searchSchemes();
    };
    const updateBothSme = (val) => {
      store.commit('advancedFilters/updateBothSme', val);
      SchemeUtils.searchSchemes();
    };

    //store commit Scope
    const updateNational = (val) => {
      store.commit('advancedFilters/updateNational', val);
      SchemeUtils.searchSchemes();
    };
    const updateRegional = (val) => {
      store.commit('advancedFilters/updateRegional', val);
      SchemeUtils.searchSchemes();
    };
    const updateEuropean = (val) => {
      store.commit('advancedFilters/updateEuropean', val);
      SchemeUtils.searchSchemes();
    };
    const updateLocal = (val) => {
      store.commit('advancedFilters/updateLocal', val);
      SchemeUtils.searchSchemes();
    };

    return {
      updateTermBefore,
      updateTermAfter,
      updateTermBoth,
      sme,
      notSme,
      bothSme,
      showAdvanced,
      before,
      show,
      after,
      beforeAndAfter,
      hideAdvanced,
      updateSme,
      updateNotSme,
      updateBothSme,
      european,
      local,
      national,
      regional,
      updateLocal,
      updateRegional,
      updateEuropean,
      updateNational,
      FiltersService,
    };
  },
};
</script>
