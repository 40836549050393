<template>
  <q-dialog class="users-list-modal" v-model="show" persistent>
    <q-card class="modal-card">
      <div class="row justify-end close-btn-area">
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
          @click="disableModal()"
          class="row justify-end close-btn"
        />
      </div>
      <div class="users-list-container">
        <div class="users-list" v-for="user in users" :key="user.id">
          <p class="user-name">
            {{ user.person.firstName }} {{ user.person.lastName }}
          </p>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed } from 'vue';
import store from '../../../store/index';

export default {
  props: {},

  setup() {
    const disableModal = () => {
      store.commit('userStats/updateShowModal', false);
    };

    const show = computed({
      get: () => store.state.userStats.showModal,
    });

    const users = computed({
      get: () => store.state.userStats.modalData,
    });

    return {
      users,
      show,
      disableModal,
    };
  },
};
</script>
