import axios from 'axios';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
import axiosUrl from './axios.service';
import AuthService from '../services/auth.service';
import router from '../router';

const API_URL = axiosUrl.getAxiosUrl();

class TagsService {
  getTagsTree(tabType, languageCode) {
    return axios
      .get(API_URL + `tags/getTagTree?tagType=${tabType.value}`, {
        headers: authHeader(),
        params: {
          language: languageCode,
          culture: 'nl',
        },
      })
      .then((response) => {
        if (languageCode === 'de')
          store.commit('tree/updateTreeDe', response.data);
        else if (languageCode === 'nl')
          store.commit('tree/updateTree', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'tree/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('tree/enableModalError');
          } else {
            store.commit('tree/updateModalError', error),
              store.commit('tree/enableModalError');
          }
        }
      });
  }
  //for edit scheme page
  async getTagTypeTree(tagType, tagTypeValue) {
    return await axios
      .get(API_URL + `tags/getTagTree?tagType=${tagTypeValue}`, {
        params: {
          culture: 'nl',
        },
        headers: authHeader(),
      })
      .then((response) => {
        var type = tagType.split(' ').join('');
        store.commit('scheme/update' + `${type}` + 'Tree', response.data);
        /*      if (tagType == "Locations")
          store.commit("scheme/updateAreTagsLoading", false); */
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'tree/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('tree/enableModalError');
          } else {
            store.commit('tree/updateModalError', error),
              store.commit('tree/enableModalError');
          }
        }
      });
  }

  async getTagTypeTreeCustomers(tagType, tagTypeValue) {
    return await axios
      .get(API_URL + `tags/getTagTree?tagType=${tagTypeValue}`, {
        params: {
          culture: 'nl',
        },
        headers: authHeader(),
      })
      .then((response) => {
        var type = tagType.split(' ').join('');
        store.commit('customers/update' + `${type}` + 'Tree', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'tree/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('tree/enableModalError');
          } else {
            store.commit('tree/updateModalError', error),
              store.commit('tree/enableModalError');
          }
        }
      });
  }

  async getTagTypes() {
    return await axios
      .get(API_URL + 'tags/getTagTypes', {
        params: {
          culture: 'nl',
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('tagsNavigation/updateTagsList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'tree/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('tree/enableModalError');
          } else {
            store.commit('tree/updateModalError', error),
              store.commit('tree/enableModalError');
          }
        }
      });
  }

  getTagById(id) {
    return axios
      .get(API_URL + 'tags/getTag', {
        params: {
          tagId: id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('tree/fillTagDetails', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'tree/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('tree/enableModalError');
          } else {
            store.commit('tree/updateModalError', error),
              store.commit('tree/enableModalError');
          }
        }
      });
  }
  async updateTagOrder(id, up, down, parentId, tagType) {
    return await axios
      .post(
        API_URL + 'tags/updateTagOrder',
        {
          Id: id,
          Up: up.value,
          Down: down.value,
          parentId: parentId,
          tagType: tagType.value,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        store.commit('tree/updateOrder', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'tree/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('tree/enableModalError');
          } else {
            store.commit('tree/updateModalError', error),
              store.commit('tree/enableModalError');
          }
        }
      });
  }
  async getTagsByType(tagType, tagTypeValue) {
    return await axios
      .get(API_URL + `tags/getTagsByType?tagType=${tagTypeValue}`, {
        params: {
          culture: 'nl',
        },
        headers: authHeader(),
      })
      .then((response) => {
        var type = tagType.split(' ').join('');
        store.commit('tagsNavigation/fill' + `${type}` + 'Tags', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'tree/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('tree/enableModalError');
          } else {
            store.commit('tree/updateModalError', error),
              store.commit('tree/enableModalError');
          }
        }
      });
  }
  async addChild(id, name) {
    return await axios
      .post(
        API_URL + `tags/addTagRelation`,
        {
          id: id.value,
          addAsChild: true,
          name: name.value,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'tree/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('tree/enableModalError');
          } else {
            store.commit('tree/updateModalError', error),
              store.commit('tree/enableModalError');
          }
        }
      });
  }

  async addParent(id, name, parentId) {
    return await axios
      .post(
        API_URL + `tags/addTagRelation`,
        {
          id: id.value,
          addAsParent: true,
          name: name.value,
          parentId: parentId,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'tree/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('tree/enableModalError');
          } else {
            store.commit('tree/updateModalError', error),
              store.commit('tree/enableModalError');
          }
        }
      });
  }

  async updateTag(id, name, order, isHidden, languageCode) {
    return await axios
      .post(
        API_URL + `tags/editTag`,
        {
          id: id.value,
          name: name.value,
          order: order.value,
          IsHidden: isHidden.value,
          languageCode: languageCode.value,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'tree/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('tree/enableModalError');
          } else {
            store.commit('tree/updateModalError', error),
              store.commit('tree/enableModalError');
          }
        }
      });
  }

  async createNewTag(
    newNodeLabel,
    newNodeOrder,
    currentTagType,
    newNodeHideOption,
    languageCode
  ) {
    return await axios({
      method: 'post',
      url: API_URL + 'tags/addNewTag',
      headers: authHeader(),
      data: {
        Name: newNodeLabel.value,
        Order: newNodeOrder.value,
        Type: currentTagType.value,
        IsHidden: newNodeHideOption.value,
        LanguageCode: languageCode.value,
      },
    }).catch((error) => {
      if (error.response.status === 401) {
        AuthService.logout();
        router.go();
      }
      if (error) {
        if (error.response.status === 403) {
          store.commit(
            'tree/updateModalError',
            "You don't have permission to perform this action!"
          ),
            store.commit('tree/enableModalError');
        } else {
          store.commit('tree/updateModalError', error),
            store.commit('tree/enableModalError');
        }
      }
    });
  }

  async createLocationGroupTree(name, locationTagsIds) {
    return await axios
      .post(
        API_URL + `tags/addLocationGroupTree`,
        {
          GroupName: name,
          selectedTagsIds: locationTagsIds,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit('tree/updateLocationGroupTree', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'tree/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('tree/enableModalError');
          } else {
            store.commit('tree/updateModalError', error),
              store.commit('tree/enableModalError');
          }
        }
      });
  }

  async editLocationGroup(id, selectedTagIds) {
    return await axios
      .post(
        API_URL + `tags/editLocationGroupTags`,
        {
          groupId: id,
          selectedTagsIds: selectedTagIds,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit('tree/disableEditGroupTag');
        store.commit('tree/updateLocationGroupTrees', response.data);
      });
  }
  async getAllLocationGroupTags() {
    return await axios
      .get(API_URL + `tags/getAllLocationGroupTags`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('tree/updateLocationGroupTrees', response.data);
      });
  }

  async getGroupsByType(groupType) {
    return await axios
      .get(API_URL + `tags/getGroupsByType?groupType=${groupType}`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('tree/setGroupObjectData', response.data);
      });
  }
}

export default new TagsService();
