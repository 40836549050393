<template>
  <div class="news-edit-page">
    <ErrorModal />

    <h5 class="q-pa-md bold-font q-ml-lg">Edit news: {{ newsItem.title }}</h5>
    <div class="main-page row ">
      <div class="main-inputs column">
        <q-input
          class="title-input box-input"
          borderless
          v-model="title"
          bg-color="white"
          label="Title"
          stack-label
          :rules="requiredFieldRule"
        />

        <div class="date-input title-input" style="max-width: 300px">
          <q-input
            class="title-input box-input"
            borderless
            bg-color="white"
            v-model="newsDate"
            label="Publish date"
            stack-label
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    v-model="newsDate"
                    mask="DD-MM-YYYY"
                    :options="dateOptionsFn"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>

        <q-select
          borderless
          bg-color="white"
          class="title-input box-input"
          v-model="status"
          :options="statusOptions"
          option-value="name"
          option-label="name"
          stack-label
          label="Status"
        />

        <q-select
          borderless
          bg-color="white"
          class="title-input box-input"
          v-model="newsSchemes"
          multiple
          :options="schemesOptions"
          use-chips
          use-input
          option-value="name"
          option-label="name"
          stack-label
          label="Select scheme(s)"
          @filter="filterSchemesFn"
          ref="editNewsSelectschemeInput"
          @update:model-value="setInput()"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-select
          borderless
          bg-color="white"
          class="title-input box-input q-mb-lg"
          v-model="scope"
          :options="scopeOptions"
          option-value="name"
          option-label="name"
          stack-label
          label="Select scope"
        />

        <q-select
          borderless
          bg-color="white"
          class="title-input box-input q-mb-lg"
          v-model="selectedNewsLetterTypes"
          :options="newsLetterTypesOptions"
          option-value="name"
          option-label="name"
          stack-label
          multiple
          label="Select news letter type(s)"
        />

        <q-select
          borderless
          bg-color="white"
          class="title-input box-input q-mb-lg"
          v-model="languageCode"
          :options="languageCodeOptions"
          option-value="name"
          option-label="name"
          stack-label
          label="Select language code"
        />

        <div
          v-if="!hasSelectedTags && hasSelectedScheme"
          class="checkboxes-tags"
        >
          <span class="checkboxes-title q-pl-md">Select tags to inherit</span>
          <q-btn
            color="primary"
            label="Select all"
            @click="selectAllInheritances()"
            v-if="!selectAll"
            class="select-btn float-right"
            no-caps
          />
          <q-btn
            color="primary"
            label="Unselect all"
            @click="selectAllInheritances()"
            v-if="selectAll"
            class="select-btn float-right"
            no-caps
          />
          <q-checkbox
            v-model="inheritAudiences"
            label="Audiences"
            color="primary"
          />
          <q-checkbox
            v-model="inheritPurposes"
            label="Purposes"
            color="primary"
          />
          <q-checkbox
            v-model="inheritLocations"
            label="Locations"
            color="primary"
          />
          <q-checkbox
            v-model="inheritSchemeTypes"
            label="Scheme types"
            color="primary"
          />
          <q-checkbox
            v-model="inheritProjectTypes"
            label="Project types"
            color="primary"
          />
          <q-checkbox
            v-model="inheritKeywords"
            label="Keywords"
            color="primary"
          />
          <q-checkbox
            v-model="inheritIndustriesNew"
            label="Industries NEW"
            color="primary"
          />
        </div>
      </div>

      <div class="column large-inputs">
        <div
          v-if="hasSelectedTags || !hasSelectedScheme"
          class="textarea-input tags-tabs"
        >
          <NewsSelectedTags />
        </div>

        <div class="introduction-input">
          <span class="input-title q-mt-lg"
            >Newsletter/search result text:</span
          >
          <q-input
            :rules="requiredFieldRule"
            class="textarea-input"
            v-model="introduction"
            borderless
            bg-color="white"
            type="textarea"
          />
          <span
            v-if="introductionCount"
            class="introduction-count row items-end"
            >{{ introductionCount }} characters</span
          >
        </div>

        <div class="introduction-input">
          <span class="input-title q-mt-md">Body:</span>

          <ckeditor
            :editor="editorRef.help.editor"
            v-model="body"
            :config="editorRef.help.editorConfig"
          ></ckeditor>
          <span v-if="bodyCount" class="introduction-count row items-end"
            >{{ bodyCount }} characters</span
          >
        </div>
      </div>
    </div>

    <div class="row justify-end">
      <q-btn
        class="q-mb-lg q-mt-lg q-mr-xl orange-btn-filled bold-font"
        no-caps
        label="Submit changes"
        @click="editNewsSubmit()"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import NewsService from "../../services/news.service";
import { useRoute } from "vue-router";
import store from "../../store/index";
import TagsService from "../../services/tags.service";
import ErrorModal from "../news-components/NewsModalError.vue";
import moment from "moment";
import NewsSelectedTags from "./NewsSelectedTags.vue";
import help from "../../helpers/editor";
import ETagType from "../../enums/ETagType";

export default {
  name: "NewsEdit",
  components: {
    ErrorModal,
    NewsSelectedTags,
  },
  setup() {
    const route = useRoute();

    const id = route.params.id;
    const inheritAudiences = ref(false);
    const inheritPurposes = ref(false);
    const inheritLocations = ref(false);
    const inheritSchemeTypes = ref(false);
    const inheritProjectTypes = ref(false);
    const inheritKeywords = ref(false);
    const inheritIndustriesNew = ref(false);
    const selectAll = ref(false);

    onMounted(async () => {
      await NewsService.getNewsById(id);
      NewsService.getNewsLetterTypesById(id);
      NewsService.getNewsLetterTypes();
      await TagsService.getTagsByType("Keywords", ETagType.Keywords);
      await NewsService.getPublicationStatuses();
      await NewsService.getSchemesForSelect();
      await NewsService.getScopes();
      await NewsService.getLanguageCodes();
      await NewsService.getNewsSelectedTags(id, "Audience", ETagType.Audience);
      await NewsService.getNewsSelectedTags(id, "Purposes", ETagType.Purposes);
      await NewsService.getNewsSelectedTags(
        id,
        "Locations",
        ETagType.Locations
      );
      await NewsService.getNewsSelectedTags(
        id,
        "Scheme Type",
        ETagType.SchemeType
      );
      await NewsService.getNewsSelectedTags(
        id,
        "Project Type",
        ETagType.ProjectType
      );
      await NewsService.getNewsSelectedTags(
        id,
        "Industries NEW",
        ETagType.IndustriesNew
      );
      await NewsService.getNewsSelectedTags(id, "Keywords", ETagType.Keywords);
    });

    const editorRef = help;

    const newsItem = computed({
      get: () => store.state.news.selectedNewsItem,
    });

    const audiencePairs = computed({
      get: () => store.state.news.selectedNewsAudienceTags,
    });

    const status = computed({
      get: () => store.state.news.selectedNewsStatus,
      set: (value) => store.commit("news/updateStatus", value),
    });

    const scope = computed({
      get: () => store.state.news.selectedNewsScope,
      set: (value) => store.commit("news/updateScope", value),
    });

    const languageCode = computed({
      get: () => store.state.news.selectedNewsLanguageCode,
      set: (value) => store.commit("news/updateLanguageCode", value),
    });

    const purposesPairs = computed({
      get: () => store.state.news.selectedNewsPurposesTags,
    });

    const locationsPairs = computed({
      get: () => store.state.news.selectedNewsLocationsTags,
    });

    const schemeTypesPairs = computed({
      get: () => store.state.news.selectedNewsSchemeTypesTags,
    });

    const newsKeywordsTags = computed({
      get: () => store.state.news.selectedNewsKeywordsTags,
    });

    const industriesNewPairs = computed({
      get: () => store.state.news.selectedNewsIndustriesNewTags,
    });

    const projectTypesPairs = computed({
      get: () => store.state.news.selectedNewsProjectTypesTags,
    });

    const selectedGermanLocationTagsIds = computed({
      get: () => store.state.news.selectedGermanLocationTags.map((l) => l.id),
    });

    const allSchemes = computed({
      get: () => store.state.news.allSchemes,
    });

    const schemesOptions = ref(allSchemes.value);

    const statusOptions = computed({
      get: () => store.state.news.publicationStatuses,
    });

    const scopeOptions = computed({
      get: () => store.state.news.scopes,
    });

    const languageCodeOptions = computed({
      get: () => store.state.news.languageCodes,
    });

    const introduction = computed({
      get: () => store.state.news.selectedIntroduction,
      set: (value) => store.commit("news/updateIntroduction", value),
    });

    const introductionCount = computed({
      get: () => store.state.news.selectedIntroduction.length,
    });
    const bodyCount = computed({
      get: () => store.state.news.selectedBody.length,
    });

    const newsDate = computed({
      get: () => store.state.news.selectedNewsDate,
      set: (value) => store.commit("news/updateSelectedDate", value),
    });

    const newsSchemes = computed({
      get: () => store.state.news.selectedNewsSchemes,
      set: (value) => store.commit("news/updateSelectedSchemes", value),
    });

    const body = computed({
      get: () => store.state.news.selectedBody,
      set: (value) => store.commit("news/updateBody", value),
    });

    const title = computed({
      get: () => store.state.news.selectedTitle,
      set: (value) => store.commit("news/updateTitle", value),
    });

    const selectedNewsLetterTypes = computed({
      get: () => store.state.news.selectedNewsLetterTypes,
      set: (val) => store.commit("news/updateSelectedNewsLetterTypes", val),
    });

    const updatedNews = {
      id: id,
      title: title,
      introduction: introduction,
      body: body,
      date: newsDate,
      status: status,
      schemes: newsSchemes,
      newsAudienceTags: audiencePairs,
      newsPurposesTags: purposesPairs,
      //This is just hidden for now, don't remove it
      //newsIndustriesTags: newsIndustriesTags,
      newsLocationsTags: locationsPairs,
      scope: scope,
      newsSchemeTypesTags: schemeTypesPairs,
      newsProjectTypesTags: projectTypesPairs,
      newsIndustriesNewTags: industriesNewPairs,
      newsKeywordsTags: newsKeywordsTags,
      newsGermanLocationsTags: selectedGermanLocationTagsIds,
      inheritAudiences: inheritAudiences,
      inheritPurposes: inheritPurposes,
      inheritLocations: inheritLocations,
      inheritSchemeTypes: inheritSchemeTypes,
      inheritProjectTypes: inheritProjectTypes,
      inheritIndustriesNew: inheritIndustriesNew,
      inheritKeywords: inheritKeywords,
      newsLetterTypes: selectedNewsLetterTypes,
      language: languageCode,
    };

    const editNewsSubmit = async () => {
      await NewsService.editNews(updatedNews);
    };

    const tab = ref("audiences");

    const selectAllInheritances = () => {
      selectAll.value = !selectAll.value;

      if (!selectAll.value) {
        inheritAudiences.value = false;
        inheritPurposes.value = false;
        inheritLocations.value = false;
        inheritSchemeTypes.value = false;
        inheritProjectTypes.value = false;
        inheritKeywords.value = false;
        inheritIndustriesNew.value = false;
      } else {
        inheritAudiences.value = true;
        inheritPurposes.value = true;
        inheritLocations.value = true;
        inheritSchemeTypes.value = true;
        inheritProjectTypes.value = true;
        inheritKeywords.value = true;
        inheritIndustriesNew.value = true;
      }
    };

    const hasSelectedScheme = computed(() => {
      return newsSchemes.value.length > 0;
    });

    const hasSelectedTags = computed(() => {
      return (
        audiencePairs.value.length > 0 ||
        purposesPairs.value.length > 0 ||
        newsKeywordsTags.value.length > 0 ||
        locationsPairs.value.length > 0 ||
        schemeTypesPairs.value.length > 0 ||
        projectTypesPairs.value.length > 0 ||
        industriesNewPairs.value.length > 0 ||
        selectedGermanLocationTagsIds.value.length > 0
      );
    });

    const newsLetterTypesOptions = computed({
      get: () => store.state.news.newsLetterTypesList,
    });

    const editNewsSelectschemeInput = ref(null);
    const setInput = () => {
      editNewsSelectschemeInput.value.updateInputValue("");
    };

    return {
      editNewsSelectschemeInput,
      setInput,
      languageCode,
      languageCodeOptions,
      selectedNewsLetterTypes,
      newsLetterTypesOptions,
      introductionCount,
      bodyCount,
      tab,
      id,
      newsItem,
      introduction,
      status,
      body,
      editNewsSubmit,
      title,
      updatedNews,
      newsKeywordsTags,
      allSchemes,
      scope,
      scopeOptions,
      statusOptions,
      newsDate,
      newsSchemes,
      schemesOptions,
      inheritAudiences,
      inheritIndustriesNew,
      inheritPurposes,
      inheritLocations,
      inheritSchemeTypes,
      inheritProjectTypes,
      inheritKeywords,
      selectAll,
      selectAllInheritances,
      audiencePairs,
      purposesPairs,
      locationsPairs,
      industriesNewPairs,
      schemeTypesPairs,
      projectTypesPairs,
      hasSelectedScheme,
      hasSelectedTags,
      editorRef,
      editorData: "<p></p>",
      selectedGermanLocationTagsIds,

      filterSchemesFn(val, update) {
        update(() => {
          if (val === "") {
            schemesOptions.value = allSchemes.value;
          } else {
            if (!isNaN(parseInt(val))) {
              const idVal = parseInt(val);
              schemesOptions.value = allSchemes.value.filter(
                (v) => v.id === idVal
              );
            } else {
              const needle = val.toLowerCase();

              schemesOptions.value = allSchemes.value.filter((v) => {
                if (v.name != null && v.name.toLowerCase().indexOf(needle) > -1)
                  return v;
              });
            }
          }
        });
      },
      requiredFieldRule: [
        (val) => (val && val.length > 0) || "Field is required!",
      ],
      dateOptionsFn(date) {
        return (
          date >= moment(newsDate.value, "DD-MM-YYYY").format("YYYY/MM/DD")
        );
      },
    };
  },
};
</script>
