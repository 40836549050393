import organisationsService from '../../services/organisations.service';

const state = () => ({
    organisationsIndex: [{}],
    organisationNodes: [],
    selectedNodeAndParent: {},
    organisationTreeLabel: '',
    totalNodes: 0,
    showCreateOrganisation: false,
    showEditOrganisation: false,
    modalErrorMessage: '',
    hasModalError: false,
    selectedNode: 0,
    currentName: '',
    currentEmail: '',
    currentUrl: '',
    currentPhone: '',
    currentAddresses: [{}],
    currentStreet: '',
    countriesList: [],
    allAddresses: [{}],
    selectedOrganisationName: '',
    organisationsList: [{}],
    organisationsPaginationList: [],
    pagination: {
        sortBy: '',
        descending: false,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: null,
    },
    showAdd: false,
    OrganisationID: 0,
    defaultCity: '',
    defaultCountry: '',
    selectedOrganisationParentId: "",
    parentOrganisation: {},
    organisationsListBasic: [{}]
});

const getters = {};

const actions = {};

const mutations = {
    updatePagination(state, val) {
        state.pagination.rowsPerPage = val.size;
        state.pagination.rowsNumber = val.count;
    },
    updateDefaultCountry(state, val) {
        state.defaultCountry = val;
    },
    updateDefaultCity(state, val) {
        state.defaultCity = val;
    },
    enableAdd(state, val) {
        state.showAdd = true;
        state.OrganisationID = val;
    },
    enableEdit(state, val) {
    
        state.OrganisationID = val;
    },
    disableAdd(state) {
        state.showAdd = false;
    },
    updatePaginationPage(state, val) {
        state.pagination.sortBy = val.sortBy;
        state.pagination.descending = val.descending;
        state.pagination.page = val.page;
    },

    updateOrganisationParentId(state, val) {
        state.selectedOrganisationParentId = val;
    },

    updateOrganisationParent(state, val) {
        state.selectedOrganisationParentId = val.id;
        state.parentOrganisation = {
            id: val.id,
            name: val.name
        }
    },
    fillOrganisationList(state, organisations) {
        state.organisationsList = [{}];
        for (let i = 0; i < organisations.length; i++) {
            state.organisationsList[i] = {
                id: organisations[i].id,
                name: organisations[i].name,
            };
        }
    },


    fillOrganisationListBasic(state, organisations) {
        state.organisationsListBasic = [{}];
        for (let i = 0; i < organisations.length; i++) {
            state.organisationsListBasic[i] = {
                id: organisations[i].id,
                name: organisations[i].name,
            };
        }
    },

    fillOrganisationPaginationList(state, organisations) {
        state.organisationsPaginationList = [];
        for (let i = 0; i < organisations.items.length; i++) {
            state.organisationsPaginationList[i] = {
                id: organisations.items[i].id,
                name: organisations.items[i].name,
                url: organisations.items[i].url,
                email: organisations.items[i].email,
                children: [],
            };
            if (organisations.items[i].childOrganisations.length > 0) {
                for (
                    let j = 0; j < organisations.items[i].childOrganisations.length; j++
                ) {
                    state.organisationsPaginationList[i].children.push({
                        id: organisations.items[i].childOrganisations[j].id,
                        name: organisations.items[i].childOrganisations[j].name,
                        url: organisations.items[i].childOrganisations[j].url,
                        email: organisations.items[i].childOrganisations[j].email,
                    });
                }
            }
        }
    },

    updateCountriesList(state, list) {
        state.countriesList = list;
    },

    toggleEditOrganisation(state) {
        state.showEditOrganisation = !state.showEditOrganisation;
    },

    enableEditOrganisation(state) {
        state.showCreateOrganisation = false;
        state.showEditOrganisation = true;
    },

    disableEditOrganisation(state) {
        state.showEditOrganisation = false;
    },

    toggleCreateOrganisation(state) {
        state.showCreateOrganisation = !state.showCreateOrganisation;
    },

    enableCreateOrganisation(state) {
        state.showEditOrganisation = false;
        state.showCreateOrganisation = true;
    },

    disableCreateOrganisation(state) {
        state.showCreateOrganisation = false;
    },

    disableAddOrganisation(state) {
        state.showAdd = false;
    },

    setselectedOrganisationName(state, node) {
        state.selectedOrganisationName = node;
    },

    setSelectedNode(state, node) {
        state.selectedNode = node;
        for (let i = 0; i < state.organisationNodes.length; i++) {
            if (state.organisationNodes[i].children.length > 0) {
                for (let j = 0; j < state.organisationNodes[i].children.length; j++) {
                    if (state.organisationNodes[i].children[j].id == node) {
                        state.selectedOrganisationName =
                            state.organisationNodes[i].children[j].label;
                        break;
                    }
                }
            }
            if (state.organisationNodes[i].id == node) {
                state.selectedOrganisationName = state.organisationNodes[i].label;
                break;
            }
        }
    },
    updateOrganisations(state, nodes) {
        state.organisationsIndex = [];
        state.organisationNodes = nodes.allActivities.options;
        state.organisationTreeLabel = nodes.allActivities.label;
        for (let i = 0; i < state.organisationNodes.length; i++) {
            state.totalNodes++;
            if (state.organisationNodes[i].children.length > 0) {
                for (let j = 0; j < state.organisationNodes[i].children.length; j++) {
                    state.totalNodes++;
                }
            }
        }

        var counter = 0;

        for (let i = 0; i < state.organisationNodes.length; i++) {
            if (
                typeof state.organisationNodes[i].label != undefined &&
                state.organisationNodes[i].label != undefined
            )
                state.organisationsIndex[i] = {
                    label: state.organisationNodes[i].label,
                    index: counter,
                    id: state.organisationNodes[i].id,
                    parent: 0,
                    children: [],
                };
            counter++;
            if (state.organisationNodes[i].children.length > 0) {
                for (let j = 0; j < state.organisationNodes[i].children.length; j++) {
                    state.organisationsIndex[i].children.push({
                        label: state.organisationNodes[i].children[j].label,
                        id: state.organisationNodes[i].children[j].id,
                        index: counter,
                        parent: state.organisationNodes[i],
                    });
                    counter++;
                }
            }
        }
    },

    fillOrganisationDetails(state, node) {
        state.currentName = node.name;
        state.currentEmail = node.email;
        state.currentPhone = node.phone;
        state.currentUrl = node.url;
        state.currentAddresses = node.addresses;
        state.selectedOrganisationParentId = node.parentOrganisationId;
        if (node.parentOrganisationId && node.parentOrganisationId != null) {
            organisationsService.getParentOrganisation(node.parentOrganisationId)
        } else {
            state.selectedOrganisationParentId = "";
            state.parentOrganisation = {
                id: null,
                name: ''
            }
        }
        //here will always be one address
        if (node.addresses != null && node.addresses.length > 0) {
            for (let i = 0; i < node.addresses.length; i++) {
                (state.currentStreet = node.addresses[0].addressLine1),
                (state.defaultCity = node.addresses[0].addressLine2),
                (state.defaultCountry = node.addresses[0].country),
                (state.allAddresses[i] = {
                    street: node.addresses[i].addressLine1,
                    city: node.addresses[i].addressLine2,
                    country: node.addresses[i].country,
                    id: node.addresses[i].id,
                });
            }
        } else {
            (state.defaultCity = ''),
            (state.defaultCountry = ''),
            (state.currentStreet = '');
        }
    },

    fillParentOrganisationData(state, org) {
        state.parentOrganisation = {
            id: org.id,
            name: org.name
        }
    },

    updateStreet(state, street) {
        state.currentStreet = street;
    },

    updateName(state, name) {
        state.currentName = name;
    },

    updateEmail(state, email) {
        state.currentEmail = email;
    },

    updatePhone(state, phone) {
        state.currentPhone = phone;
    },

    updateUrl(state, url) {
        state.currentUrl = url;
    },

    resetModalError(state) {
        state.modalErrorMessage = '';
    },

    enableModalError(state) {
        state.hasModalError = true;
    },
    disableModalError(state) {
        state.hasModalError = false;
    },
    updateModalError(state, message) {
        state.modalErrorMessage = message;
    },

    updateNodeAndParent(state, selected) {
        for (let i = 0; i < state.organisationNodes.length; i++) {
            if (state.organisationsIndex[i].index == selected) {
                state.selectedNodeAndParent = {
                    nodeId: state.organisationsIndex[i].id,
                    parent: state.organisationsIndex[i].parent == 0 ?
                        0 : state.organisationsIndex[i].parent.id,
                };
                break;
            } else if (state.organisationsIndex[i].children.length > 0) {
                for (let j = 0; j < state.organisationsIndex[i].children.length; j++) {
                    if (state.organisationsIndex[i].children[j].index == selected) {
                        state.selectedNodeAndParent = {
                            nodeId: state.organisationsIndex[i].children[j].id,
                            parent: state.organisationsIndex[i].children[j].parent,
                        };
                        break;
                    }
                }
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};