<template>
  <q-dialog v-model="showSelectTagsDialog" persistent full-height full-width>
    <q-card bordered>
      <q-card-section>
        <div class="row q-mb-md">
          <div class="column" style="width: 50%">
            <TreeSelectTags
              class="col-md-6"
              :treeNodes="propsAudienceNodes"
              :pairs="propsAudiencePairs"
              :tagType="'Audience'"
            />
            <div
              class="other-select q-mt-md"
              style="min-width: 250px; padding-top: 24px; margin-top: 48px; max-width: 260px"
            >
              <q-select
                bg-color="white"
                v-model="schemeSME"
                :options="smeOptions"
                option-value="name"
                option-label="name"
                stack-label
                label="Applies to SME?"
                v-if="isEditScheme"
              />
              <q-select
                bg-color="white"
                v-model="schemeSMECreate"
                :options="smeOptions"
                option-value="name"
                option-label="name"
                stack-label
                label="Applies to SME?"
                v-else
              />
            </div>
          </div>
          <div class="column">
            <div class="row">
              <TreeSelectTags
                class="col-md-5"
                :treeNodes="propsLocationsNodes"
                :pairs="propsLocationsPairs"
                :tagType="'Locations'"
                :locationGroupsSelected="locationGroupsSelected"
              />
              <div class="column" style="align-self: center;">
                <p class="q-mt-xl">Location groups</p>
                <q-select
                  class="q-mr-md"
                  style="width: 200px;"
                  multiple
                  v-model="locationGroupsSelected"
                  :options="locationGroupsList"
                  label="Select location group"
                />
              </div>
            </div>
            <!-- 
              v-model define - connect to store state
              options is going to be from backend - get only after search - also in store
              define search function etc

            options is empty, on search if 0-1-2 characters do nothing just return
            3 or more (or maybe 2 or more) characters then search tags and fill them
            -->
            <div class="row">
              <p class="q-mt-xl">German location tags</p>
            </div>
            <div class="row">
              <q-select
                class="q-mr-md"
                multiple
                v-model="locationTagsSelectBulk"
                :options="locationTagsBulkList"
                @filter="filterLocationTagsBulk"
                label="Filter locations"
                use-chips
                use-input
                input-debounce="500"
                behavior="menu"
                option-value="id"
                option-label="label"
              />
            </div>
          </div>
        </div>
        <div class="row q-mb-md">
          <TreeSelectTags
            class="col-md-6"
            :treeNodes="propsPurposesNodes"
            :pairs="propsPurposesPairs"
            :tagType="'Purposes'"
          />
          <TreeSelectTags
            class="col-md-6"
            :treeNodes="propsSchemeTypesNodes"
            :pairs="propsSchemeTypesPairs"
            :tagType="'SchemeTypes'"
          />
        </div>
        <div class="row q-mb-md">
          <TreeSelectTags
            class="col-md-6"
            :treeNodes="propsIndustriesNewNodes"
            :pairs="propsIndustriesNewPairs"
            :tagType="'IndustriesNew'"
          />
          <TreeSelectTags
            class="col-md-6"
            :treeNodes="propsProjectTypesNodes"
            :pairs="propsProjectTypesPairs"
            :tagType="'ProjectTypes'"
          />
        </div>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn color="primary" label="Ok" @click="disableShowSelectTags" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import store from "../../store/index";
import { ref, computed, onMounted } from "vue";
import TreeSelectTags from "../TreeSelectTags.vue";
import TagsService from "../../services/tags.service";
import schemesServices from "../../services/schemes.services";

export default {
  name: "SelectTags",
  components: {
    TreeSelectTags,
  },
  props: {
    audiencePairs: Array,
    purposesPairs: Array,
    locationsPairs: Array,
    industriesNewPairs: Array,
    schemeTypesPairs: Array,
    projectTypesPairs: Array,
    showSelectTags: Boolean,
    audienceNodes: Object,
    purposesNodes: Object,
    locationsNodes: Object,
    schemeTypesNodes: Object,
    projectTypesNodes: Object,
    industriesNewNodes: Object,
    isEdit: Boolean,
  },
  setup(props) {
    const propsAudiencePairs = ref(props.audiencePairs);
    const propsPurposesPairs = ref(props.purposesPairs);
    const propsLocationsPairs = ref(props.locationsPairs);
    const propsSchemeTypesPairs = ref(props.schemeTypesPairs);
    const propsProjectTypesPairs = ref(props.projectTypesPairs);
    const propsIndustriesNewPairs = ref(props.industriesNewPairs);
    const showSelectTagsDialog = ref(props.showSelectTags);
    const propsAudienceNodes = ref(props.audienceNodes);
    const propsLocationsNodes = ref(props.locationsNodes);
    const propsPurposesNodes = ref(props.purposesNodes);
    const propsSchemeTypesNodes = ref(props.schemeTypesNodes);
    const propsProjectTypesNodes = ref(props.projectTypesNodes);
    const propsIndustriesNewNodes = ref(props.industriesNewNodes);
    const isEditScheme = ref(props.isEdit);
    onMounted(async () => {
      await TagsService.getGroupsByType("Location");
    });
    const disableShowSelectTags = () => {
      store.commit("scheme/disableShowSelectTags");
    };

    const schemeSMECreate = computed({
      get: () => store.state.scheme.createSchemeSmeString,
      set: (val) => store.commit("scheme/updateCreateSMEString", val),
    });

    const locationGroupsList = computed({
      get: () => store.state.tree.groupDataNames,
    });
    const schemeSME = computed({
      get: () => store.state.scheme.selectedSchemeSME,
      set: (value) => store.commit("scheme/updateSelectedSME", value),
    });
    const locationGroupsSelected = computed({
      get: () => store.state.tree.locationGroupsSelected,
      set: (val) => store.commit("tree/setLocationGroupsSelected", val),
    });

    const locationTagsBulkList = computed({
      get: () => store.state.scheme.locationTagsBulkList,
    });

    const locationTagsSelectBulk = computed({
      get: () => store.state.scheme.locationTagsSelectBulk,
      set: (val) => store.commit("scheme/updateLocationTagsSelectedBulk", val),
    });

    const smeOptions = computed({
      get: () => store.state.scheme.smeTypes,
    });
    return {
      schemeSMECreate,
      isEditScheme,
      schemeSME,
      smeOptions,
      locationTagsBulkList,
      locationTagsSelectBulk,
      locationGroupsSelected,
      locationGroupsList,
      propsAudiencePairs,
      propsPurposesPairs,
      propsIndustriesNewPairs,
      propsSchemeTypesPairs,
      propsLocationsPairs,
      showSelectTagsDialog,
      disableShowSelectTags,
      propsAudienceNodes,
      propsPurposesNodes,
      propsLocationsNodes,
      propsSchemeTypesNodes,
      propsIndustriesNewNodes,
      propsProjectTypesNodes,
      propsProjectTypesPairs,
      filterLocationTagsBulk(val, update) {
        if (!val || val == "" || val.length < 2) return [];
        update(() => {
          if (!val || val == "" || val.length < 2) return [];
          else {
            schemesServices.searchLocationCodesBulk(val);
            //perform search from service
            // then set store to service return data
          }
        });
      },
    };
  },
};
</script>
