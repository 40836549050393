import moment from 'moment';
import EDeliveryMethod from '../../enums/EDeliveryMethod';

const state = () => ({
  customersList: [],
  showAdd: false,
  countriesList: [],
  customerObject: {
    id: 0,
    userId: 0,
    name: '',
    emailSuffix: '',
    email: '',
    phoneNumber: '',
    demo: false,
    isFomoAccess: false,
    languageCode: null,
    isAdmin: false,
    hasContent: false,
    hasSas: false,
    has2FA: false,
    useSSO: false,
    startDate: null,
    endDate: null,
    sasStartDate: null,
    sasEndDate: null,
    numberOfLicences: 0,
    isActive: null,
    crmIdentifier: '',
    customerType: '',
    environment: 0,
    twitterUrl: '',
    linkedinUrl: '',
    facebookUrl: '',
    customerLogoPath: '',
    baseUrl: '',
    debugEmail: '',
    selectedDeliveryMethod: EDeliveryMethod.SiBApi,
    address: {
      city: '',
      country: '',
    },
    persons: [
      {
        firstName: '',
        lastName: '',
        isActive: false,
        startDate: null,
        endDate: null,
      },
    ],
    accountManager: {
      id: null,
      firstName: '',
      lastName: '',
      fullName: '',
    },
    selectedCustomerAudienceTagsIds: [],
    selectedCustomerPurposesTagsIds: [],
    selectedCustomerLocationsTagsIds: [],
    tenantIdsList: [],
  },
  modalErrorMessage: '',
  hasModalError: false,
  pageSize: 0,
  pagination: {
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 50,
    rowsNumber: null,
  },
  totalResults: 0,
  showSuccessfullyUpdatedDialog: false,
  customerTypesList: [],
  portalCustomersList: [],
  selectedCustomerAudienceTags: [],
  selectedCustomerPurposesTags: [],
  selectedCustomerLocationsTags: [],
  audienceTags: { options: [], label: '' },
  purposesTags: { options: [], label: '' },
  locationsTags: { options: [], label: '' },
  pieChartCustomersData: [{}],
  showPieChartModal: false,
  languageCodes: [],
  deliveryMethodsList: [],

  createCustomerObject: {
    name: '',
    emailSuffix: '',
    crmIdentifier: '',
    debugEmail: '',
    // In onmounted of create component set lang code.
    //  Only show select for superadmin
    languageCode: 0,
    accountManager: {
      id: null,
      firstName: '',
      lastName: '',
      fullName: '',
    },
    customerType: null,
    //Delivery method -  dont show, autofill
    // Language code: dont show, autofill (except for super admin role)
    selectedProducts: null,
    // Selected products: pick from: Content, SAS, ISV/Business portal
    content: {
      startDate: moment().format('DD-MM-YYYY'),
      endDate: null,
      isDemo: false,
      numberOfLicences: 0,
    },
    sas: {
      sasStartDate: moment().format('DD-MM-YYYY'),
      sasEndDate: null,
      reminderInterval: 30,
      canAllCompleteTask: false,
      emailReplyAddress: '',
      emailSignature: '',
      categorization1: '',
      categorization2: '',
      useCustomEmailAddress: false,
      //only show if above is true
      emailsSendFrom: '',
      ssoOr2Fa: '',
      // tenant id only if SSO is picked
      tenantId: null,
    },
  },
  reminderIntervalOptions: [],
});

const getters = {};

const actions = {};

const mutations = {
  updateCreateCustomerObjectField(state, { field, value }) {
    if (
      Object.prototype.hasOwnProperty.call(state.createCustomerObject, field)
    ) {
      state.createCustomerObject[field] = value;
    }
  },

  resetCreateCustomerObject(state) {
    state.createCustomerObject = {
      name: '',
      emailSuffix: '',
      crmIdentifier: '',
      debugEmail: '',
      languageCode: 0,
      accountManager: {
        id: null,
        firstName: '',
        lastName: '',
        fullName: '',
      },
      customerType: null,
      selectedProducts: null,
      content: {
        startDate: moment().format('DD-MM-YYYY'),
        endDate: null,
        isDemo: false,
        numberOfLicences: 0,
      },
      sas: {
        sasStartDate: moment().format('DD-MM-YYYY'),
        sasEndDate: null,
        reminderInterval: 30,
        canAllCompleteTask: false,
        emailReplyAddress: '',
        emailSignature: '',
        categorization1: '',
        categorization2: '',
        useCustomEmailAddress: false,
        emailsSendFrom: '',
        ssoOr2Fa: '',
        tenantId: null,
      },
    };
  },

  updateReminderIntervalOptions(state, val) {
    state.reminderIntervalOptions = val;
  },
  updateLanguageCodes(state, val) {
    state.languageCodes = val;
  },
  updateAudienceTree(state, data) {
    state.audienceTags = data.allActivities;
  },
  updatePurposesTree(state, data) {
    state.purposesTags = data.allActivities;
  },
  updateLocationsTree(state, data) {
    state.locationsTags = data.allActivities;
  },
  updateModalPieChartCustomersData(state, data) {
    state.pieChartCustomersData = data;
  },
  setSelectedCustomerAudienceTags(state, tags) {
    if (state.selectedCustomerAudienceTags.length > 0)
      state.selectedCustomerAudienceTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedCustomerAudienceTags.push(tags[i]);

    const selectedCustomerAudienceTagsIds = tags.map((tag) => tag.id);
    if (state.customerObject.selectedCustomerAudienceTagsIds.length > 0)
      state.customerObject.selectedCustomerAudienceTagsIds = [];
    for (let i = 0; i < selectedCustomerAudienceTagsIds.length; i++)
      state.state.customerObject.selectedCustomerAudienceTagsIds.push(
        selectedCustomerAudienceTagsIds[i]
      );
  },
  setSelectedCustomerPurposesTags(state, tags) {
    if (state.selectedCustomerPurposesTags.length > 0)
      state.selectedCustomerPurposesTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedCustomerPurposesTags.push(tags[i]);

    const selectedCustomerPurposesTagsIds = tags.map((tag) => tag.id);
    if (state.customerObject.selectedCustomerPurposesTagsIds.length > 0)
      state.customerObject.selectedCustomerPurposesTagsIds = [];
    for (let i = 0; i < selectedCustomerPurposesTagsIds.length; i++)
      state.state.customerObject.selectedCustomerPurposesTagsIds.push(
        selectedCustomerPurposesTagsIds[i]
      );
  },

  setSelectedCustomerLocationsTags(state, tags) {
    if (state.selectedCustomerLocationsTags.length > 0)
      state.selectedCustomerLocationsTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedCustomerLocationsTags.push(tags[i]);

    const selectedCustomerLocationsTagsIds = tags.map((tag) => tag.id);
    if (state.customerObject.selectedCustomerLocationsTagsIds.length > 0)
      state.customerObject.selectedCustomerLocationsTagsIds = [];
    for (let i = 0; i < selectedCustomerLocationsTagsIds.length; i++)
      state.state.customerObject.selectedCustomerLocationsTagsIds.push(
        selectedCustomerLocationsTagsIds[i]
      );
  },
  updatePortalCustomersList(state, customers) {
    state.portalCustomersList = customers;
  },
  updateCustomersList(state, customers) {
    state.customersList = customers;
  },
  updateAllCustomers(state, val) {
    state.customersList = val.items;
    state.pageSize = val.size;
    state.totalResults = val.count;
  },
  updateAllCustomersFiltered(state, val) {
    state.customersList = val;
  },
  updateCustomerForUser(state, val) {
    state.customersList = [];
    state.customersList[0] = val;
  },
  enableAdd(state) {
    state.showAdd = true;
  },
  disableAdd(state) {
    state.showAdd = false;
  },
  updateCountriesList(state, countries) {
    state.countriesList = countries;
  },
  updateCustomerTypesList(state, customerTypes) {
    state.customerTypesList = customerTypes;
  },
  updateCustomer(state, customer) {
    state.customerObject = customer;
  },
  fillCustomerDetails(state, customer) {
    state.customerObject.name = customer.name;
    state.customerObject.demo = customer.demo;
    state.customerObject.isFomoAccess = customer.isFomoAccess;
    state.customerObject.isAdmin = customer.isAdmin;
    state.customerObject.emailSuffix = customer.emailSuffix;
    state.customerObject.twitterUrl = customer.twitterUrl;
    state.customerObject.facebookUrl = customer.facebookUrl;
    state.customerObject.linkedinUrl = customer.linkedinUrl;
    state.customerObject.phoneNumber = customer.phoneNumber;
    state.customerObject.email = customer.email;
    state.customerObject.useSSO = customer.useSSO;
    (state.customerObject.hasContent = customer.hasContent),
      (state.customerObject.hasSas = customer.hasSas),
      (state.customerObject.has2FA = customer.has2FA),
      (state.customerObject.endDate =
        customer.endDate == null || customer.endDate == ''
          ? null
          : moment(customer.endDate).format('DD-MM-YYYY'));
    state.customerObject.startDate =
      customer.startDate == null || customer.startDate == ''
        ? null
        : moment(customer.startDate).format('DD-MM-YYYY');
    state.customerObject.sasEndDate =
      customer.sasEndDate == null || customer.sasEndDate == ''
        ? null
        : moment(customer.sasEndDate).format('DD-MM-YYYY');
    state.customerObject.sasStartDate =
      customer.sasStartDate == null || customer.sasStartDate == ''
        ? null
        : moment(customer.sasStartDate).format('DD-MM-YYYY');
    state.customerObject.crmIdentifier = customer.crmIdentifier;
    state.customerObject.customerType = customer.customerType;
    state.customerObject.accountManager = customer.accountManager;
    state.customerObject.environment = customer.environment;
    state.customerObject.languageCode = customer.languageCode;
    if (customer.addresses != null && customer.addresses.length > 0) {
      state.customerObject.address.city = customer.addresses[0].addressLine2;
      state.customerObject.address.country = customer.addresses[0].country;
    } else {
      state.customerObject.address.city = '';
      state.customerObject.address.country = '';
    }
    state.customerObject.numberOfLicences = customer.numberOfLicences;
    state.customerObject.isActive = customer.isActive;
    state.customerObject.persons = [];
    if (customer.persons != null && customer.persons.length > 0) {
      for (let i = 0; i < customer.persons.length; i++) {
        state.customerObject.persons.push({
          id: customer.persons[i].id,
          firstName: customer.persons[i].firstName,
          lastName: customer.persons[i].lastName,
          isActive: customer.persons[i].isActive,
          startDate: customer.persons[i].startDate,
          endDate: customer.persons[i].endDate,
          userId: customer.persons[i].userId,
        });
      }
    }

    state.customerObject.tenantIdsList = customer.tenantIds;
    state.customerObject.debugEmail = customer.debugEmail;
    state.customerObject.selectedDeliveryMethod = customer.deliveryMethod;
    state.customerObject.customerLogoPath = customer.customerLogoPath;
  },
  updateCustomerDebugEmail(state, debugEmail) {
    state.customerObject.debugEmail = debugEmail;
  },
  updateCustomerTenantIdsList(state, tenantIds) {
    if (Array.isArray(tenantIds))
      state.customerObject.tenantIdsList = tenantIds;
  },
  updateDeliveryMethod(state, deliveryMethod) {
    state.customerObject.selectedDeliveryMethod = deliveryMethod;
  },
  setSelectedCustomerEnvironment(state, env) {
    state.customerObject.environment = env.id;
  },
  updateCustomerName(state, name) {
    state.customerObject.name = name;
  },
  updateCustomerDemo(state, demo) {
    state.customerObject.demo = demo;
  },
  updateCustomerIsAdmin(state, isAdmin) {
    state.customerObject.isAdmin = isAdmin;
  },
  updateCustomerIsFomoAccess(state, isFomoAccess) {
    state.customerObject.isFomoAccess = isFomoAccess;
  },
  updateCustomerHas2FA(state, has2FA) {
    state.customerObject.has2FA = has2FA;
  },
  updateCustomerEmailSuffix(state, emailSuffix) {
    state.customerObject.emailSuffix = emailSuffix;
  },
  updateCustomerEndDate(state, endDate) {
    state.customerObject.endDate = endDate;
  },
  updateCustomerStartDate(state, startDate) {
    state.customerObject.startDate = startDate;
  },
  updateCustomerSasEndDate(state, sasEndDate) {
    state.customerObject.sasEndDate = sasEndDate;
  },
  updateCustomerSasStartDate(state, sasStartDate) {
    state.customerObject.sasStartDate = sasStartDate;
  },
  updateCustomerCity(state, city) {
    state.customerObject.address.city = city;
  },
  updateCustomerCountry(state, country) {
    state.customerObject.address.country = country;
  },
  updateCustomerNumberOfLicences(state, number) {
    state.customerObject.numberOfLicences = number;
  },
  updateCustomerCrmIdentifier(state, crmIdentifier) {
    state.customerObject.crmIdentifier = crmIdentifier;
  },
  updateCustomerType(state, value) {
    state.customerObject.customerType = value;
  },
  updateCustomerLanguageCode(state, value) {
    state.customerObject.languageCode = value;
  },
  updateCustomerAccountManager(state, accountManager) {
    state.customerObject.accountManager = accountManager;
  },
  updateCustomerLinkedinUrl(state, linkedinUrl) {
    state.customerObject.linkedinUrl = linkedinUrl;
  },
  updateCustomerFacebookUrl(state, facebookUrl) {
    state.customerObject.facebookUrl = facebookUrl;
  },
  updateCustomerTwitterUrl(state, twitterUrl) {
    state.customerObject.twitterUrl = twitterUrl;
  },
  updateCustomerBaseUrl(state, baseUrl) {
    state.customerObject.baseUrl = baseUrl;
  },
  updateCustomerLogoPath(state, logoPath) {
    state.customerObject.customerLogoPath = logoPath;
  },
  updateCustomerUseSSO(state, useSSO) {
    state.customerObject.useSSO = useSSO;
  },
  updateCustomerEmail(state, email) {
    state.customerObject.email = email;
  },
  updateCustomerPhoneNumber(state, phoneNumber) {
    state.customerObject.phoneNumber = phoneNumber;
  },
  enableModalError(state) {
    state.hasModalError = true;
  },
  disableModalError(state) {
    state.hasModalError = false;
  },
  updateModalError(state, message) {
    state.modalErrorMessage = message;
  },
  updatePagination(state, val) {
    state.pagination.rowsPerPage = val.size;
    state.pagination.rowsNumber = val.count;
  },
  updatePaginationPage(state, val) {
    state.pagination.sortBy = val.sortBy;
    state.pagination.descending = val.descending;
    state.pagination.page = val.page;
  },
  enableSuccessfullyUpdatedDialog(state) {
    state.showSuccessfullyUpdatedDialog = true;
  },
  disableSuccessfullyUpdatedDialog(state) {
    state.showSuccessfullyUpdatedDialog = false;
  },
  updateShowPieChartModal(state, val) {
    state.showPieChartModal = val;
  },
  updateDeliveryMethodsList(state, value) {
    state.deliveryMethodsList = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
