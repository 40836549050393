<template>
  <div class="schemes-results-page">
    <q-table
      v-if="schemes.length > 0"
      class="sticky-header schemes-items"
      table-header-style="background-color: #0a1f8f"
      table-header-class="text-white"
      title="Schemes"
      :rows="schemes"
      :columns="columns"
      row-key="id"
      :filter="filter"
      :loading="loading"
      :visible-columns="visibleColumns"
      v-model:pagination="pagination"
      hide-bottom
      binary-state-sort
      @request="onRequest"
      dense
    >
      <template v-slot:top>
        <q-btn
          style="background: #25408f; color: white; text-decoration: none;"
          :disable="loading"
          label="Create Scheme"
          :to="{ name: 'CreateScheme' }"
          dense
        >
          <i class="las la-2x la-plus-circle la-fw"></i>
        </q-btn>
        <q-space />
        <p class="result-count">Results: {{ resultCount }}</p>
        <q-btn
          v-if="schemes.length > 0"
          style="background: #25408f; color: white"
          :disable="loading"
          label="Export schemes"
          @click="exportSchemes"
          dense
        >
          <i class="las la-2x la-file-download la-fw"></i>
        </q-btn>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props" :key="`m_${props.row.id}`">
          <q-td class="table-name-row" key="Title" :props="props">
            <router-link
              :to="{ name: 'EditScheme', params: { id: props.row.id } }"
              target="_blank"
              class="scheme-link"
            >
              <span v-html="props.row.name" class="scheme-name-span"></span>
            </router-link>
          </q-td>
          <q-td key="VisualPing" :props="props">
            <q-btn
              :color="props.row.visualpingColor"
              text-color="indigo-10"
              @click="
                selectedSchemeId = props.row.id;
                showVisualPingDialog = true;
              "
              size="sm"
              no-caps
              label="Visualping"
            >
            </q-btn>
          </q-td>
          <q-td key="Badges" :props="props">
            <q-chip
              outline
              color="primary"
              v-if="props.row.isIctuScheme"
              label="I"
            >
              <q-tooltip>
                ICTU scheme
              </q-tooltip>
            </q-chip>
            <q-chip
              outline
              color="secondary"
              v-if="props.row.isPortalScheme"
              label="P"
            >
              <q-tooltip>
                Portal scheme
              </q-tooltip>
            </q-chip>
            <q-chip
              outline
              color="deep-purple-6"
              v-if="props.row.isScienceScheme"
              label="S"
            >
              <q-tooltip>
                Science scheme
              </q-tooltip>
            </q-chip>
            <q-chip
              class="q-pa-none bg-transparent"
              v-if="props.row.isEnglishScheme"
            >
              <country-flag country="gb" class="country-flag-icon">
              </country-flag>
              <q-tooltip>
                English scheme
              </q-tooltip>
            </q-chip>
            <q-chip
              class="q-pa-none bg-transparent"
              v-if="!props.row.isEnglishScheme && props.row.hasEnglishCalls"
            >
              <country-flag country="gb" class="country-flag-calls-icon" />
              <q-tooltip>
                English calls scheme
              </q-tooltip>
            </q-chip>
          </q-td>
          <q-td class="table-accronym" key="Accronym" :props="props">{{
            props.row.accronym
          }}</q-td>

          <q-td class="table-lastUpdated" key="UpdatedDate" :props="props">{{
            formatDate(props.row.updatedDate)
          }}</q-td>
          <q-td class="table-callStart" key="CallStart" :props="props">{{
            formatDate(props.row.callStart)
          }}</q-td>
          <q-td class="table-callEnd" key="CallEnd" :props="props">{{
            formatDate(props.row.callEnd)
          }}</q-td>
          <q-td class="table-identifier" key="Identifier" :props="props">{{
            props.row.identifier
          }}</q-td>
          <q-td class="table-archiveCode" key="ArchieveCode" :props="props">{{
            props.row.archieveCode
          }}</q-td>
          <q-td class="table-status" key="Status" :props="props">{{
            props.row.status
          }}</q-td>
          <q-td class="table-duration" key="Duration" :props="props">{{
            props.row.duration
          }}</q-td>
          <q-td key="Datum" :props="props">
            {{ formatDate(props.row.createdDate) }}
          </q-td>
        </q-tr>
        <q-tr
          v-show="props.expand"
          :props="props"
          :key="`e_${props.row.id}`"
          class="q-tr--no-hover q-virtual-scroll--with-prev"
        >
          <q-td colspan="100%" v-if="showList">
            <q-item v-ripple v-for="calls in props.row.calls" :key="calls.id">
              <div class="scheme-card">
                <router-link
                  :to="{ name: 'EditScheme', params: { id: calls.id } }"
                  target="_blank"
                  class="scheme-link"
                >
                  <span v-html="calls.name"></span>
                </router-link>
              </div>
            </q-item>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <div v-else>
      <span class="no-results" @click="router.go(-1)">
        No results found. Click here to go back.
      </span>
    </div>
    <VisualPingDialog
      :selectedSchemeId="selectedSchemeId"
      v-if="showVisualPingDialog"
    />
  </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import store from '../store/index';
import moment from 'moment';
import router from '../router/index';
import { useRoute } from 'vue-router';
import SchemesServices from '../services/schemes.services';
import VisualPingDialog from '../components/schemes-components/VisualPingDialog.vue';

export default {
  name: 'SchemesResultsPage',
  components: {
    VisualPingDialog,
  },
  setup() {
    const route = useRoute();
    const searchText = route.query.searchtext;
    const durationPhrase = route.query.durationphrase;
    onMounted(() => {
      store.commit('scheme/setDefaultPagination');
    });

    const schemes = computed({
      get: () => store.state.scheme.schemes,
    });

    const selectedSchemeId = ref(null);

    const showListToggle = () => {
      store.commit('scheme/showListCalls');
    };

    const formatDate = (stringDate) => {
      if (stringDate == null || stringDate == '') return '-';
      const date = new Date(stringDate);
      return moment(date).format('DD-MM-YYYY');
    };

    const selectNode = (selected) => {
      store.commit('scheme/setSelectedNode', selected);
    };

    const editSchemeItem = (id) => {
      selectNode(id);
      const editroute = router.resolve({ path: `/admin/scheme/edit/${id}` });
      window.open(editroute.href, '_blank');
    };

    const showVisualPingDialog = computed({
      get: () => store.state.scheme.showVisualPingDialog,
      set: (value) => store.commit('scheme/updateShowVisualPingDialog', value),
    });

    const rows = ref(schemes);

    const columns = [
      {
        name: 'Title',
        required: true,
        label: 'Title',
        align: 'left',
        field: (row) => row.name,
        format: (val) => `${val}`,
        sortable: true,
      },
      {
        name: 'VisualPing',
        align: 'center',
      },
      {
        name: 'Badges',
        align: 'center',
      },
      {
        name: 'Accronym',
        label: 'Accronym',
        align: 'center',
        classes: 'table-accronym',
        class: 'table-accronym',
        sortable: true,
        field: 'Accronym',
      },
      {
        name: 'UpdatedDate',
        label: 'Last updated',
        align: 'center',

        classes: 'table-updatedDate',
        class: 'table-updatedDate',
        field: 'UpdatedDate',
        sortable: true,
      },
      {
        name: 'CallStart',
        label: 'Call start',
        align: 'center',
        classes: 'table-callStart',
        class: 'table-callStart',
        field: 'CallStart',
        sortable: true,
      },
      {
        name: 'CallEnd',
        label: 'Call end',
        align: 'center',
        classes: 'table-callEnd',
        class: 'table-schemeEnd',
        field: 'CallEnd',
        sortable: true,
      },

      {
        name: 'Identifier',
        label: 'Identifier',
        align: 'center',
        sortable: true,
        field: 'Identifier',
      },
      {
        name: 'ArchieveCode',
        label: 'ArchieveCode',
        align: 'center',
        sortable: true,
        field: 'ArchieveCode',
      },
      {
        name: 'Status',
        label: 'Status',
        align: 'center',
        sortable: true,
        field: 'Status',
      },
      {
        name: 'Duration',
        label: 'Duration',
        align: 'center',
        sortable: true,
        field: 'Duration',
      },
      {
        name: 'Datum',
        label: 'Date Created',
        align: 'center',
        field: 'Datum',
        sortable: true,
      },
    ];

    const loading = ref(false);
    const paginationVari = ref({
      page: 1,
      rowsPerPage: 5000,
      rowsNumber: 16,
    });
    const pagination = ref(
      computed({
        get: () => store.state.scheme.pagination,
        set: (paginationVar) =>
          store.commit('scheme/updatePagination', paginationVar),
      })
    );

    const showList = computed({
      get: () => store.state.scheme.showListCalls,
    });
    const maxPages = computed({
      get: () => store.state.advancedSearch.pageCount,
    });
    const pagesCount = computed({
      get: () => store.state.scheme.schemes.totalRecords,
    });
    const resultsCount = computed({
      get: () => store.state.advancedSearch.numberOfResults,
    });
    const currentPage = computed({
      get: () => store.state.advancedSearch.currentPage,
    });
    const current = ref(currentPage.value);

    const onRequest = async (props) => {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      store.commit('advancedSearch/updateSelectedSort', sortBy);
      await SchemesServices.getSearchResultsSorted(
        searchText,
      //  statusBoxes,
        audiences,
        selectedLocations,
        germanLocationsPaths,
        //This is just hidden for now, don't remove it
        //industries,
        purposes,
        schemeTypes,
        projectTypes,
        SME,
        applicationTerm,
        minBudget,
        maxBudget,
        scope,
        organisationsInclude,
        organisationsExclude,
        GetstartDate,
        GetendDate,
        page,
        rowsPerPage,
        sortBy,
        descending,
        searchOnlyName,
        publicationStatusesGroup.value.join(),
        filterByICTUFlag,
        durationPhrase,
        updatedDateGreaterThan,
        updatedDateLessThan,
        schemeStartGreaterThan,
        schemeStartLessThan,
        schemeEndGreaterThan,
        schemeEndLessThan,
        searchOnFundingOrgs,
        searchOnAdministratingOrgs,
        searchOnAdvisingOrgs,
        searchOnRecipientOrgs,
        showAllLanguageVersions,
        schemeStatusGroup
      );
      store.commit('scheme/updatePaginationPage', props.pagination);
    };

    const audiences = computed({
      get: () =>
        store.state.advancedSearch.selectedAudiences != ''
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });

    //This is just hidden for now, don't remove it
    /*
   
    const industries = computed({
      get: () => store.state.advancedSearch.selectedIndustries,
    });
    */

    const selectedLocations = computed({
      get: () =>
        store.state.advancedSearch.selectedLocations &&
        store.state.advancedSearch.selectedLocations.length > 0
          ? store.state.advancedSearch.selectedLocations
          : JSON.parse(sessionStorage.getItem('selectedLocations')),
    });
    const germanLocationsPaths = computed({
      get: () => store.state.advancedSearch.selectedGermanLocationsPaths
    });

    const purposes = computed({
      get: () =>
        store.state.advancedSearch.selectedPurposes != ''
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });

    const schemeTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedSchemeTypes != ''
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });

    const projectTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedProjectTypes != ''
          ? store.state.advancedSearch.selectedProjectTypes
          : sessionStorage.getItem('selectedProjectTypes'),
    });

    //get data AdvancedSearch - resultTypes
    const resultTypes = computed({
      get: () => store.state.advancedSearch.resultTypesString,
    });
    //get data AdvancedSearch - Status
    const schemeStatusGroup = computed({
      get: () =>
        store.state.advancedSearch.schemeStatusGroup &&
        store.state.advancedSearch.schemeStatusGroup.length > 0 &&
        (JSON.parse(sessionStorage.getItem('schemeStatusGroup')) ==
          null ||
          (JSON.parse(sessionStorage.getItem('schemeStatusGroup')) &&
            store.state.advancedSearch.schemeStatusGroup.join() ===
              JSON.parse(
                sessionStorage.getItem('schemeStatusGroup')
              ).join()))
          ? store.state.advancedSearch.schemeStatusGroup
          : JSON.parse(sessionStorage.getItem('schemeStatusGroup')),
    });

    //get data AdvancedSearch - Budget
    const minBudget = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : JSON.parse(sessionStorage.getItem('minBudget')),
    });
    const maxBudget = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : JSON.parse(sessionStorage.getItem('maxBudget')),
    });

    //get data advancedFilters - SME
    const SME = computed({
      get: () => store.state.advancedFilters.smeString,
    });
    //get data advancedFilters - ApplicationTerm
    const applicationTerm = computed({
      get: () => store.state.advancedFilters.termsString,
    });
    //get data advancedFilters - Scope
    const scope = computed({
      get: () => store.state.advancedFilters.scopesString,
    });
    const GetstartDate = computed({
      get: () =>
        store.state.advancedSearch.startDate != ''
          ? store.state.advancedSearch.startDate
          : JSON.parse(sessionStorage.getItem('startDate')),
    });
    const GetendDate = computed({
      get: () =>
        store.state.advancedSearch.endDate != ''
          ? store.state.advancedSearch.endDate
          : JSON.parse(sessionStorage.getItem('endDate')),
    });

    const organisationsInclude = computed({
      get: () =>
        store.state.advancedSearch.organisationsInclude &&
        store.state.advancedSearch.organisationsInclude.length > 0
          ? store.state.advancedSearch.organisationsInclude
          : JSON.parse(sessionStorage.getItem('organisationsInclude')) || [],
    });

    const organisationsExclude = computed({
      get: () =>
        store.state.advancedSearch.organisationsExclude &&
        store.state.advancedSearch.organisationsExclude.length > 0
          ? store.state.advancedSearch.organisationsExclude
          : JSON.parse(sessionStorage.getItem('organisationsExclude')) || [],
    });

    const searchOnlyName = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('searchOnlyName')) == null ||
        store.state.advancedSearch.searchOnlyName ===
          JSON.parse(sessionStorage.getItem('searchOnlyName'))
          ? store.state.advancedSearch.searchOnlyName
          : JSON.parse(sessionStorage.getItem('searchOnlyName')) === true,
    });
    const showAllLanguageVersions = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('showAllLanguageVersions')) == null ||
        store.state.advancedSearch.showAllLanguageVersions ===
          JSON.parse(sessionStorage.getItem('showAllLanguageVersions'))
          ? store.state.advancedSearch.showAllLanguageVersions
          : JSON.parse(sessionStorage.getItem('showAllLanguageVersions')) ===
            true,
    });

    const publicationStatusesGroup = computed({
      get: () =>
        store.state.advancedSearch.publicationStatusesGroup &&
        store.state.advancedSearch.publicationStatusesGroup.length > 0 &&
        store.state.advancedSearch.publicationStatusesGroup.join() ===
          JSON.parse(sessionStorage.getItem('publicationStatusesGroup')).join()
          ? store.state.advancedSearch.publicationStatusesGroup
          : JSON.parse(sessionStorage.getItem('publicationStatusesGroup')),
    });

    const filterByICTUFlag = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('filterByICTUFlag')) == null ||
        store.state.advancedSearch.filterByICTUFlag ===
          JSON.parse(sessionStorage.getItem('filterByICTUFlag'))
          ? store.state.advancedSearch.filterByICTUFlag
          : JSON.parse(sessionStorage.getItem('filterByICTUFlag')),
    });

    const updatedDateGreaterThan = computed({
      get: () =>
        store.state.advancedSearch.updatedDateGreaterThan != ''
          ? store.state.advancedSearch.updatedDateGreaterThan
          : JSON.parse(sessionStorage.getItem('updatedDateGreaterThan')),
    });

    const updatedDateLessThan = computed({
      get: () =>
        store.state.advancedSearch.updatedDateLessThan != ''
          ? store.state.advancedSearch.updatedDateLessThan
          : JSON.parse(sessionStorage.getItem('updatedDateLessThan')),
    });

    const schemeStartGreaterThan = computed({
      get: () =>
        store.state.advancedSearch.schemeStartGreaterThan != ''
          ? store.state.advancedSearch.schemeStartGreaterThan
          : JSON.parse(sessionStorage.getItem('schemeStartGreaterThan')),
    });
    const schemeStartLessThan = computed({
      get: () =>
        store.state.advancedSearch.schemeStartLessThan != ''
          ? store.state.advancedSearch.schemeStartLessThan
          : JSON.parse(sessionStorage.getItem('schemeStartLessThan')),
    });
    const schemeEndGreaterThan = computed({
      get: () =>
        store.state.advancedSearch.schemeEndGreaterThan != ''
          ? store.state.advancedSearch.schemeEndGreaterThan
          : JSON.parse(sessionStorage.getItem('schemeEndGreaterThan')),
    });
    const schemeEndLessThan = computed({
      get: () =>
        store.state.advancedSearch.schemeEndLessThan != ''
          ? store.state.advancedSearch.schemeEndLessThan
          : JSON.parse(sessionStorage.getItem('schemeEndLessThan')),
    });
    const searchOnFundingOrgs = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('searchOnFundingOrgs')) == null ||
        store.state.advancedSearch.searchOnFundingOrgs ===
          JSON.parse(sessionStorage.getItem('searchOnFundingOrgs'))
          ? store.state.advancedSearch.searchOnFundingOrgs
          : JSON.parse(sessionStorage.getItem('searchOnFundingOrgs')) === true,
    });
    const searchOnAdministratingOrgs = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('searchOnAdministratingOrgs')) ==
          null ||
        store.state.advancedSearch.searchOnAdministratingOrgs ===
          JSON.parse(sessionStorage.getItem('searchOnAdministratingOrgs'))
          ? store.state.advancedSearch.searchOnAdministratingOrgs
          : JSON.parse(sessionStorage.getItem('searchOnAdministratingOrgs')) ===
            true,
    });
    const searchOnAdvisingOrgs = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('searchOnAdvisingOrgs')) == null ||
        store.state.advancedSearch.searchOnAdvisingOrgs ===
          JSON.parse(sessionStorage.getItem('searchOnAdvisingOrgs'))
          ? store.state.advancedSearch.searchOnAdvisingOrgs
          : JSON.parse(sessionStorage.getItem('searchOnAdvisingOrgs')) === true,
    });
    const searchOnRecipientOrgs = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('searchOnRecipientOrgs')) == null ||
        store.state.advancedSearch.searchOnRecipientOrgs ===
          JSON.parse(sessionStorage.getItem('searchOnRecipientOrgs'))
          ? store.state.advancedSearch.searchOnRecipientOrgs
          : JSON.parse(sessionStorage.getItem('searchOnRecipientOrgs')) ===
            true,
    });

    const resultCount = computed({
      get: () => store.state.advancedSearch.numberOfResults,
    });

    const isScienceScheme = computed({
      get: () => store.state.advancedSearch.searchOnIsScienceScheme,
    });

    const isPortalScheme = computed({
      get: () => store.state.advancedSearch.searchOnIsPortalScheme,
    });

    const isEnglishScheme = computed({
      get: () => store.state.advancedSearch.searchOnIsEnglishScheme,
    });
    const isObScheme = computed({
      get: () => store.state.advancedSearch.searchOnIsObScheme,
    });
    const exportSchemes = async () => {
      let savedSort = sessionStorage.getItem('sortBy');
      const { page, rowsPerPage, descending } = pagination.value;
      await SchemesServices.exportSchemes(
        searchText,
        audiences,
        selectedLocations,
        germanLocationsPaths,
        purposes,
        schemeTypes,
        projectTypes,
        SME,
        applicationTerm,
        minBudget,
        maxBudget,
        scope,
        organisationsInclude,
        organisationsExclude,
        GetstartDate,
        GetendDate,
        page,
        rowsPerPage,
        savedSort != null ? savedSort : 'Title',
        descending,
        searchOnlyName,
        publicationStatusesGroup.value.join(),
        filterByICTUFlag,
        durationPhrase,
        updatedDateGreaterThan,
        updatedDateLessThan,
        schemeStartGreaterThan,
        schemeStartLessThan,
        schemeEndGreaterThan,
        schemeEndLessThan,
        searchOnFundingOrgs,
        searchOnAdministratingOrgs,
        searchOnAdvisingOrgs,
        searchOnRecipientOrgs,
        isScienceScheme,
        isPortalScheme,
        isEnglishScheme,
        isObScheme,
        showAllLanguageVersions,
        schemeStatusGroup.value.join(),
      ).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        var a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.download = 'Schemes.xlsx';
        a.href = fileURL;
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      });
    };

    return {
      isScienceScheme,
      isPortalScheme,
      isEnglishScheme,
      isObScheme,
      schemeStatusGroup,
      resultCount,
      onRequest,
      schemes,
      formatDate,
      showListToggle,
      editSchemeItem,
      columns,
      rows,
      paginationVari,
      pagination,
      loading,
      showList,
      maxPages,
      pagesCount,
      resultsCount,
      current,
      audiences,
      selectedLocations,
      schemeTypes,
      projectTypes,
      purposes,
      resultTypes,
      applicationTerm,
      scope,
      SME,
      minBudget,
      maxBudget,
      GetstartDate,
      GetendDate,
      router,
      searchOnlyName,
      publicationStatusesGroup,
      filterByICTUFlag,
      durationPhrase,
      updatedDateGreaterThan,
      updatedDateLessThan,
      schemeStartGreaterThan,
      schemeStartLessThan,
      schemeEndGreaterThan,
      schemeEndLessThan,
      searchOnFundingOrgs,
      searchOnAdministratingOrgs,
      searchOnAdvisingOrgs,
      searchOnRecipientOrgs,
      selectedSchemeId,
      showVisualPingDialog,
      exportSchemes,
      route,
      showAllLanguageVersions,
      germanLocationsPaths
    };
  },
};
</script>
