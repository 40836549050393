<template>
  <q-btn color="primary" @click="copyCall()">
    <i class="las la-2x la-copy"></i>Copy call
  </q-btn>
</template>

<script>
import SchemesServices from '../../services/schemes.services';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const id = route.params.id;

    const copyCall = async () => {
      await SchemesServices.copyCall(id);
    };

    return {
      route,
      id,
      copyCall,
    };
  },
};
</script>
