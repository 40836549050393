<template>
  <div class="selected-tags">
    <div class="tagsarea-input tags-tabs q-mb-xl q-mt-xl">
      <div class="row">
        <q-space />
        <q-btn
          label="Edit tags"
          class="orange-btn bold-font q-mb-sm"
          @click="enableShowSelectTags"
          no-caps
          dense
        >
        </q-btn>
      </div>
      <q-card>
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab name="audiences" label="Audiences">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              propsSelectedAudience.length
            }}</q-badge>
          </q-tab>
          <q-tab name="purposes" label="Purposes">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              propsSelectedPurposes.length
            }}</q-badge>
          </q-tab>
          <q-tab name="locations" label="Locations">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              propsSelectedLocations.length
            }}</q-badge>
          </q-tab>
          <q-tab name="schemetype" label="Scheme type">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              propsSelectedSchemeTypes.length
            }}</q-badge>
          </q-tab>
          <q-tab name="keywords" label="Keywords">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              propsSelectedKeywords.length
            }}</q-badge>
          </q-tab>
          <q-tab name="industriesnew" label="Industries NEW">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              propsSelectedIndustriesNew.length
            }}</q-badge>
          </q-tab>
          <q-tab name="projecttype" label="Project type">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              propsSelectedProjectTypes.length
            }}</q-badge>
          </q-tab>
        </q-tabs>
        <q-separator />

        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="audiences">
            <div
              class="row"
              v-if="
                selectedTagsAudience.length === 0 &&
                  excludedTagsAudience.length === 0
              "
            >
              <q-icon
                name="las la-exclamation-triangle"
                color="black"
                style="font-size: 1.5em;"
              ></q-icon>
              <span>No tags selected</span>
            </div>
            <div
              class="row"
              v-if="
                selectedTagsAudience.length > 0 ||
                  excludedTagsAudience.length > 0
              "
            >
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in selectedTagsAudience" :key="tag.id">
                    <q-icon
                      name="las la-check"
                      color="green"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in excludedTagsAudience" :key="tag.id">
                    <q-icon
                      name="las la-times"
                      color="red"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="purposes">
            <div
              class="row"
              v-if="
                selectedTagsPurposes.length === 0 &&
                  excludedTagsPurposes.length === 0
              "
            >
              <q-icon
                name="las la-exclamation-triangle"
                color="black"
                style="font-size: 1.5em;"
              ></q-icon>
              <span>No tags selected</span>
            </div>
            <div
              class="row"
              v-if="
                selectedTagsPurposes.length > 0 ||
                  excludedTagsPurposes.length > 0
              "
            >
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in selectedTagsPurposes" :key="tag.id">
                    <q-icon
                      name="las la-check"
                      color="green"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in excludedTagsPurposes" :key="tag.id">
                    <q-icon
                      name="las la-times"
                      color="red"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="locations">
            <div
              class="row"
              v-if="
                selectedTagsLocations.length === 0 &&
                  excludedTagsLocations.length === 0
              "
            >
              <q-icon
                name="las la-exclamation-triangle"
                color="black"
                style="font-size: 1.5em;"
              ></q-icon>
              <span>No tags selected</span>
            </div>
            <div
              class="row"
              v-if="
                selectedTagsLocations.length > 0 ||
                  excludedTagsLocations.length > 0
              "
            >
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in selectedTagsLocations" :key="tag.id">
                    <q-icon
                      name="las la-check"
                      color="green"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in excludedTagsLocations" :key="tag.id">
                    <q-icon
                      name="las la-times"
                      color="red"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="schemetype">
            <div
              class="row"
              v-if="
                selectedTagsSchemeTypes.length === 0 &&
                  excludedTagsSchemeTypes.length === 0
              "
            >
              <q-icon
                name="las la-exclamation-triangle"
                color="black"
                style="font-size: 1.5em;"
              ></q-icon>
              <span>No tags selected</span>
            </div>
            <div
              class="row"
              v-if="
                selectedTagsSchemeTypes.length > 0 ||
                  excludedTagsSchemeTypes.length > 0
              "
            >
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in selectedTagsSchemeTypes" :key="tag.id">
                    <q-icon
                      name="las la-check"
                      color="green"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in excludedTagsSchemeTypes" :key="tag.id">
                    <q-icon
                      name="las la-times"
                      color="red"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="keywords">
            <div style="min-width: 250px" class="tag-selector">
              <q-select
                outlined
                bg-color="white"
                v-model="props"
                multiple
                input-debounce="0"
                :options="propsSelectedKeywords"
                option-value="name"
                class="scheme-inputs"
                option-label="name"
                use-input
                use-chips
                stack-label
                label="Select or input keywords"
                @new-value="createValue"
                @filter="filterFn"
              />
            </div>
          </q-tab-panel>
          <q-tab-panel name="industriesnew">
            <div
              class="row"
              v-if="
                selectedTagsIndustriesNew.length === 0 &&
                  excludedTagsIndustriesNew.length === 0
              "
            >
              <q-icon
                name="las la-exclamation-triangle"
                color="black"
                style="font-size: 1.5em;"
              ></q-icon>
              <span>No tags selected</span>
            </div>
            <div
              class="row"
              v-if="
                selectedTagsIndustriesNew.length > 0 ||
                  excludedTagsIndustriesNew.length > 0
              "
            >
              <div class="col-md-6">
                <q-list>
                  <q-item
                    v-for="tag in selectedTagsIndustriesNew"
                    :key="tag.id"
                  >
                    <q-icon
                      name="las la-check"
                      color="green"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
              <div class="col-md-6">
                <q-list>
                  <q-item
                    v-for="tag in excludedTagsIndustriesNew"
                    :key="tag.id"
                  >
                    <q-icon
                      name="las la-times"
                      color="red"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="projecttype">
            <div
              class="row"
              v-if="
                selectedTagsProjectTypes.length === 0 &&
                  excludedTagsProjectTypes.length === 0
              "
            >
              <q-icon
                name="las la-exclamation-triangle"
                color="black"
                style="font-size: 1.5em;"
              ></q-icon>
              <span>No tags selected</span>
            </div>
            <div
              class="row"
              v-if="
                selectedTagsProjectTypes.length > 0 ||
                  excludedTagsProjectTypes.length > 0
              "
            >
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in selectedTagsProjectTypes" :key="tag.id">
                    <q-icon
                      name="las la-check"
                      color="green"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in excludedTagsProjectTypes" :key="tag.id">
                    <q-icon
                      name="las la-times"
                      color="red"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>
  </div>
  <SelectTags
    v-if="showSelectTags"
    :audiencePairs="propsSelectedAudience"
    :purposesPairs="propsSelectedPurposes"
    :locationsPairs="propsSelectedLocations"
    :schemeTypesPairs="propsSelectedSchemeTypes"
    :projectTypesPairs="propsSelectedProjectTypes"
    :industriesNewPairs="propsSelectedIndustriesNew"
    :showSelectTags="showSelectTags"
    :audienceNodes="audienceNodes"
    :purposesNodes="purposesNodes"
    :industriesNewNodes="industriesNewNodes"
    :locationsNodes="locationsNodes"
    :schemeTypesNodes="schemeTypesNodes"
    :projectTypesNodes="projectTypesNodes"
    :selectedGroups="propsSelectedGroups"
    :isEdit="false"
  />
</template>

<script>
import store from "../../store/index";
import { computed, onMounted, ref, watch } from "vue";
import TagsService from "../../services/tags.service";
import SelectTags from "./SelectTags.vue";
import ETagType from "../../enums/ETagType";

export default {
  name: "SelectedTagsCreateScheme",
  components: {
    SelectTags,
  },
  props: {
    selectedAudience: Array,
    selectedPurposes: Array,
    selectedLocations: Array,
    selectedIndustriesNew: Array,
    selectedSchemeTypes: Array,
    selectedProjectTypes: Array,
    selectedKeywords: Array,
    selectedGroups: Array,
  },
  setup(props) {
    const propsSelectedAudience = ref(props.selectedAudience);
    const propsSelectedPurposes = ref(props.selectedPurposes);
    const propsSelectedLocations = ref(props.selectedLocations);
    const propsSelectedSchemeTypes = ref(props.selectedSchemeTypes);
    const propsSelectedProjectTypes = ref(props.selectedProjectTypes);
    const propsSelectedIndustriesNew = ref(props.selectedIndustriesNew);
    const propsSelectedKeywords = ref(props.selectedKeywords);
    const propsSelectedGroups = ref(props.selectedGroups);
    const tab = ref("audiences");

    onMounted(async () => {
      await TagsService.getTagTypeTree(
        "Audience",
        ETagType.Audience
      );
      await TagsService.getTagTypeTree("Purposes", ETagType.Purposes);
      //This is just hidden for now, don't remove it
      // await TagsService.getTagTypeTree('Industries', ETagType.Industries);
      await TagsService.getTagTypeTree("Locations", ETagType.Locations);
      await TagsService.getTagTypeTree("Scheme type", ETagType.SchemeType);
      await TagsService.getTagTypeTree("Project type", ETagType.ProjectType);
      await TagsService.getTagTypeTree("Industries NEW", ETagType.IndustriesNew);
      await TagsService.getTagsByType("Keywords", ETagType.Keywords);
    });

    const enableShowSelectTags = () => {
      store.commit("scheme/enableShowSelectTags");
    };

    const showSelectTags = computed({
      get: () => store.state.scheme.showSelectTags,
    });

    const allKeywordsTags = computed({
      get: () => store.state.tagsNavigation.keywordsTags,
    });

    const filterOptions = ref(allKeywordsTags.value);

    const audienceNodes = computed({
      get: () => store.state.scheme.audienceTags,
    });

    const locationsNodes = computed({
      get: () => store.state.scheme.locationsTags,
    });

    const purposesNodes = computed({
      get: () => store.state.scheme.purposesTags,
    });

    const schemeTypesNodes = computed({
      get: () => store.state.scheme.schemeTypesTags,
    });

    const projectTypesNodes = computed({
      get: () => store.state.scheme.projectTypesTags,
    });

    const industriesNewNodes = computed({
      get: () => store.state.scheme.industriesNewTags,
    });

    const excludedTagsAudience = ref([]);
    const selectedTagsAudience = ref([]);
    const excludedTagsPurposes = ref([]);
    const selectedTagsPurposes = ref([]);
    const excludedTagsLocations = ref([]);
    const selectedTagsLocations = ref([]);
    const excludedTagsSchemeTypes = ref([]);
    const selectedTagsSchemeTypes = ref([]);
    const excludedTagsProjectTypes = ref([]);
    const selectedTagsProjectTypes = ref([]);
    const excludedTagsIndustriesNew = ref([]);
    const selectedTagsIndustriesNew = ref([]);

    watch([audienceNodes, props.selectedAudience], () => {
      selectedTagsAudience.value = [];
      excludedTagsAudience.value = [];
      var rootNodes = audienceNodes.value.options.filter(
        (element) =>
          element.children.length === 0 ||
          !element.children ||
          element.children === null
      );
      var firstLevelParentNodes = audienceNodes.value.options.filter(
        (element) => element.children !== null && element.children.length > 0
      );
      var secondLevelParentNodes = firstLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();
      var thirdLevelParentNodes = secondLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();
      var selectedRootTags = rootNodes.filter(({ id: id1 }) =>
        props.selectedAudience.some(({ id: id2 }) => id2 === id1)
      );
      selectedTagsAudience.value.push(...selectedRootTags);

      for (let i = 0; i < firstLevelParentNodes.length; i++) {
        let selectedChildren = firstLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedAudience.some(({ id: id2 }) => id1 === id2)
        );

        if (
          selectedChildren.length === firstLevelParentNodes[i].children.length
        ) {
          selectedTagsAudience.value.push(firstLevelParentNodes[i]);
        } else if (
          firstLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            firstLevelParentNodes[i].children.length - 1
        ) {
          selectedTagsAudience.value.push(firstLevelParentNodes[i]);
          let excludedTag = firstLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = firstLevelParentNodes[i].label;
          excludedTagsAudience.value.push(excludedTag[0]);
        } else {
          selectedChildren.forEach(function(v) {
            v.parentName = firstLevelParentNodes[i].label;
          });
          selectedTagsAudience.value.push(...selectedChildren);
        }
      }

      for (let i = 0; i < secondLevelParentNodes.length; i++) {
        let selectedChildren = secondLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedAudience.some(({ id: id2 }) => id1 === id2)
        );

        // if (selectedChildren.length === secondLevelParentNodes[i].children.length) {
        //   selectedTagsAudience.value.push(secondLevelParentNodes[i]); - alreday pushed as part of first level parent nodes
        // }
        if (
          secondLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            secondLevelParentNodes[i].children.length - 1
        ) {
          var parentOfSecondLevelParent = audienceNodes.value.options.find(
            (tag) => tag.children.includes(secondLevelParentNodes[i])
          );
          secondLevelParentNodes[i].parentName =
            parentOfSecondLevelParent.label;
          selectedTagsAudience.value.push(secondLevelParentNodes[i]);
          let excludedTag = secondLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = secondLevelParentNodes[i].label;
          excludedTagsAudience.value.push(excludedTag[0]);
        } else if (
          selectedChildren.length !== secondLevelParentNodes[i].children.length
        ) {
          selectedChildren.forEach(function(v) {
            v.parentName = secondLevelParentNodes[i].label;
          });
          selectedTagsAudience.value.push(...selectedChildren);
        }
      }

      for (let i = 0; i < thirdLevelParentNodes.length; i++) {
        let selectedChildren = thirdLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedAudience.some(({ id: id2 }) => id1 === id2)
        );

        if (
          thirdLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            thirdLevelParentNodes[i].children.length - 1
        ) {
          var parentOfThirdLevelParent = audienceNodes.value.options.find(
            (tag) => tag.children.includes(thirdLevelParentNodes[i])
          );
          thirdLevelParentNodes[i].parentName = parentOfThirdLevelParent.label;
          selectedTagsAudience.value.push(thirdLevelParentNodes[i]);
          let excludedTag = thirdLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = thirdLevelParentNodes[i].label;
          excludedTagsAudience.value.push(excludedTag[0]);
        } else if (
          selectedChildren.length !== thirdLevelParentNodes[i].children.length
        ) {
          selectedChildren.forEach(function(v) {
            v.parentName = thirdLevelParentNodes[i].label;
          });
          selectedTagsAudience.value.push(...selectedChildren);
        }
      }
    });

    watch([purposesNodes, props.selectedPurposes], () => {
      selectedTagsPurposes.value = [];
      excludedTagsPurposes.value = [];
      var rootNodes = purposesNodes.value.options.filter(
        (element) =>
          element.children.length === 0 ||
          !element.children ||
          element.children === null
      );
      var firstLevelParentNodes = purposesNodes.value.options.filter(
        (element) => element.children !== null && element.children.length > 0
      );
      var secondLevelParentNodes = firstLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();
      var thirdLevelParentNodes = secondLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();

      var selectedRootTags = rootNodes.filter(({ id: id1 }) =>
        props.selectedPurposes.some(({ id: id2 }) => id2 === id1)
      );
      selectedTagsPurposes.value.push(...selectedRootTags);

      for (let i = 0; i < firstLevelParentNodes.length; i++) {
        let selectedChildren = firstLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedPurposes.some(({ id: id2 }) => id1 === id2)
        );

        if (
          selectedChildren.length === firstLevelParentNodes[i].children.length
        ) {
          selectedTagsPurposes.value.push(firstLevelParentNodes[i]);
        } else if (
          firstLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            firstLevelParentNodes[i].children.length - 1
        ) {
          selectedTagsPurposes.value.push(firstLevelParentNodes[i]);
          let excludedTag = firstLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = firstLevelParentNodes[i].label;
          excludedTagsPurposes.value.push(excludedTag[0]);
        } else {
          selectedChildren.forEach(function(v) {
            v.parentName = firstLevelParentNodes[i].label;
          });
          selectedTagsPurposes.value.push(...selectedChildren);
        }
      }

      for (let i = 0; i < secondLevelParentNodes.length; i++) {
        let selectedChildren = secondLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedPurposes.some(({ id: id2 }) => id1 === id2)
        );

        if (
          secondLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            secondLevelParentNodes[i].children.length - 1
        ) {
          var parentOfSecondLevelParent = purposesNodes.value.options.find(
            (tag) => tag.children.includes(secondLevelParentNodes[i])
          );
          secondLevelParentNodes[i].parentName =
            parentOfSecondLevelParent.label;
          selectedTagsPurposes.value.push(secondLevelParentNodes[i]);
          let excludedTag = secondLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = secondLevelParentNodes[i].label;
          excludedTagsPurposes.value.push(excludedTag[0]);
        } else if (
          selectedChildren.length !== secondLevelParentNodes[i].children.length
        ) {
          selectedChildren.forEach(function(v) {
            v.parentName = secondLevelParentNodes[i].label;
          });
          selectedTagsPurposes.value.push(...selectedChildren);
        }
      }

      for (let i = 0; i < thirdLevelParentNodes.length; i++) {
        let selectedChildren = thirdLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedPurposes.some(({ id: id2 }) => id1 === id2)
        );

        if (
          thirdLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            thirdLevelParentNodes[i].children.length - 1
        ) {
          var parentOfThirdLevelParent = purposesNodes.value.options.find(
            (tag) => tag.children.includes(thirdLevelParentNodes[i])
          );
          thirdLevelParentNodes[i].parentName = parentOfThirdLevelParent.label;
          selectedTagsPurposes.value.push(thirdLevelParentNodes[i]);
          let excludedTag = thirdLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = thirdLevelParentNodes[i].label;
          excludedTagsPurposes.value.push(excludedTag[0]);
        } else if (
          selectedChildren.length !== thirdLevelParentNodes[i].children.length
        ) {
          selectedChildren.forEach(function(v) {
            v.parentName = thirdLevelParentNodes[i].label;
          });
          selectedTagsPurposes.value.push(...selectedChildren);
        }
      }
    });

    watch([locationsNodes, props.selectedLocations], () => {
      selectedTagsLocations.value = [];
      excludedTagsLocations.value = [];
      var rootNodes = locationsNodes.value.options.filter(
        (element) =>
          element.children.length === 0 ||
          !element.children ||
          element.children === null
      );
      var firstLevelParentNodes = locationsNodes.value.options.filter(
        (element) => element.children !== null && element.children.length > 0
      );
      var secondLevelParentNodes = firstLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();
      var thirdLevelParentNodes = secondLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();

      var selectedRootTags = rootNodes.filter(({ id: id1 }) =>
        props.selectedLocations.some(({ id: id2 }) => id2 === id1)
      );
      selectedTagsLocations.value.push(...selectedRootTags);

      for (let i = 0; i < firstLevelParentNodes.length; i++) {
        let selectedChildren = firstLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedLocations.some(({ id: id2 }) => id1 === id2)
        );

        if (
          selectedChildren.length === firstLevelParentNodes[i].children.length
        ) {
          selectedTagsLocations.value.push(firstLevelParentNodes[i]);
        } else if (
          firstLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            firstLevelParentNodes[i].children.length - 1
        ) {
          selectedTagsLocations.value.push(firstLevelParentNodes[i]);
          let excludedTag = firstLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = firstLevelParentNodes[i].label;
          excludedTagsLocations.value.push(excludedTag[0]);
        } else {
          selectedChildren.forEach(function(v) {
            v.parentName = firstLevelParentNodes[i].label;
          });
          selectedTagsLocations.value.push(...selectedChildren);
        }
      }

      for (let i = 0; i < secondLevelParentNodes.length; i++) {
        let selectedChildren = secondLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedLocations.some(({ id: id2 }) => id1 === id2)
        );

        if (
          secondLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            secondLevelParentNodes[i].children.length - 1
        ) {
          var parentOfSecondLevelParent = locationsNodes.value.options.find(
            (tag) => tag.children.includes(secondLevelParentNodes[i])
          );
          secondLevelParentNodes[i].parentName =
            parentOfSecondLevelParent.label;
          selectedTagsLocations.value.push(secondLevelParentNodes[i]);
          let excludedTag = secondLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = secondLevelParentNodes[i].label;
          excludedTagsLocations.value.push(excludedTag[0]);
        } else if (
          selectedChildren.length !== secondLevelParentNodes[i].children.length
        ) {
          selectedChildren.forEach(function(v) {
            v.parentName = secondLevelParentNodes[i].label;
          });
          selectedTagsLocations.value.push(...selectedChildren);
        }
      }

      for (let i = 0; i < thirdLevelParentNodes.length; i++) {
        let selectedChildren = thirdLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedLocations.some(({ id: id2 }) => id1 === id2)
        );

        if (
          thirdLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            thirdLevelParentNodes[i].children.length - 1
        ) {
          var parentOfThirdLevelParent = locationsNodes.value.options.find(
            (tag) => tag.children.includes(thirdLevelParentNodes[i])
          );
          thirdLevelParentNodes[i].parentName = parentOfThirdLevelParent.label;
          selectedTagsLocations.value.push(thirdLevelParentNodes[i]);
          let excludedTag = thirdLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = thirdLevelParentNodes[i].label;
          excludedTagsLocations.value.push(excludedTag[0]);
        } else if (
          selectedChildren.length !== thirdLevelParentNodes[i].children.length
        ) {
          selectedChildren.forEach(function(v) {
            v.parentName = thirdLevelParentNodes[i].label;
          });
          selectedTagsLocations.value.push(...selectedChildren);
        }
      }
    });

    watch([schemeTypesNodes, props.selectedSchemeTypes], () => {
      selectedTagsSchemeTypes.value = [];
      excludedTagsSchemeTypes.value = [];
      var rootNodes = schemeTypesNodes.value.options.filter(
        (element) =>
          element.children.length === 0 ||
          !element.children ||
          element.children === null
      );
      var firstLevelParentNodes = schemeTypesNodes.value.options.filter(
        (element) => element.children !== null && element.children.length > 0
      );
      var secondLevelParentNodes = firstLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();
      var thirdLevelParentNodes = secondLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();

      var selectedRootTags = rootNodes.filter(({ id: id1 }) =>
        props.selectedSchemeTypes.some(({ id: id2 }) => id2 === id1)
      );
      selectedTagsSchemeTypes.value.push(...selectedRootTags);

      for (let i = 0; i < firstLevelParentNodes.length; i++) {
        let selectedChildren = firstLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedSchemeTypes.some(({ id: id2 }) => id1 === id2)
        );

        if (
          selectedChildren.length === firstLevelParentNodes[i].children.length
        ) {
          selectedTagsSchemeTypes.value.push(firstLevelParentNodes[i]);
        } else if (
          firstLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            firstLevelParentNodes[i].children.length - 1
        ) {
          selectedTagsSchemeTypes.value.push(firstLevelParentNodes[i]);
          let excludedTag = firstLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = firstLevelParentNodes[i].label;
          excludedTagsSchemeTypes.value.push(excludedTag[0]);
        } else {
          selectedChildren.forEach(function(v) {
            v.parentName = firstLevelParentNodes[i].label;
          });
          selectedTagsSchemeTypes.value.push(...selectedChildren);
        }
      }

      for (let i = 0; i < secondLevelParentNodes.length; i++) {
        let selectedChildren = secondLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedSchemeTypes.some(({ id: id2 }) => id1 === id2)
        );

        if (
          secondLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            secondLevelParentNodes[i].children.length - 1
        ) {
          var parentOfSecondLevelParent = schemeTypesNodes.value.options.find(
            (tag) => tag.children.includes(secondLevelParentNodes[i])
          );
          secondLevelParentNodes[i].parentName =
            parentOfSecondLevelParent.label;
          selectedTagsSchemeTypes.value.push(secondLevelParentNodes[i]);
          let excludedTag = secondLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = secondLevelParentNodes[i].label;
          excludedTagsSchemeTypes.value.push(excludedTag[0]);
        } else if (
          selectedChildren.length !== secondLevelParentNodes[i].children.length
        ) {
          selectedChildren.forEach(function(v) {
            v.parentName = secondLevelParentNodes[i].label;
          });
          selectedTagsSchemeTypes.value.push(...selectedChildren);
        }
      }

      for (let i = 0; i < thirdLevelParentNodes.length; i++) {
        let selectedChildren = thirdLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedSchemeTypes.some(({ id: id2 }) => id1 === id2)
        );

        if (
          thirdLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            thirdLevelParentNodes[i].children.length - 1
        ) {
          var parentOfThirdLevelParent = schemeTypesNodes.value.options.find(
            (tag) => tag.children.includes(thirdLevelParentNodes[i])
          );
          thirdLevelParentNodes[i].parentName = parentOfThirdLevelParent.label;
          selectedTagsSchemeTypes.value.push(thirdLevelParentNodes[i]);
          let excludedTag = thirdLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = thirdLevelParentNodes[i].label;
          excludedTagsSchemeTypes.value.push(excludedTag[0]);
        } else if (
          selectedChildren.length !== thirdLevelParentNodes[i].children.length
        ) {
          selectedChildren.forEach(function(v) {
            v.parentName = thirdLevelParentNodes[i].label;
          });
          selectedTagsSchemeTypes.value.push(...selectedChildren);
        }
      }
    });

    watch([industriesNewNodes, props.selectedIndustriesNew], () => {
      selectedTagsIndustriesNew.value = [];
      excludedTagsIndustriesNew.value = [];

      var rootNodes = industriesNewNodes.value.options.filter(
        (element) =>
          element.children.length === 0 ||
          !element.children ||
          element.children === null
      );
      var firstLevelParentNodes = industriesNewNodes.value.options.filter(
        (element) => element.children !== null && element.children.length > 0
      );
      var secondLevelParentNodes = firstLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();
      var thirdLevelParentNodes = secondLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();
      var selectedRootTags = rootNodes.filter(({ id: id1 }) =>
        props.selectedIndustriesNew.some(({ id: id2 }) => id2 === id1)
      );

      selectedTagsIndustriesNew.value.push(...selectedRootTags);

      for (let i = 0; i < firstLevelParentNodes.length; i++) {
        let selectedChildren = firstLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedIndustriesNew.some(({ id: id2 }) => id1 === id2)
        );

        if (
          selectedChildren.length === firstLevelParentNodes[i].children.length
        ) {
          selectedTagsIndustriesNew.value.push(firstLevelParentNodes[i]);
        } else if (
          firstLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            firstLevelParentNodes[i].children.length - 1
        ) {
          selectedTagsIndustriesNew.value.push(firstLevelParentNodes[i]);
          let excludedTag = firstLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = firstLevelParentNodes[i].label;
          excludedTagsIndustriesNew.value.push(excludedTag[0]);
        } else {
          selectedChildren.forEach(function(v) {
            v.parentName = firstLevelParentNodes[i].label;
          });
          selectedTagsIndustriesNew.value.push(...selectedChildren);
        }
      }

      for (let i = 0; i < secondLevelParentNodes.length; i++) {
        let selectedChildren = secondLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedIndustriesNew.some(({ id: id2 }) => id1 === id2)
        );

        if (
          secondLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            secondLevelParentNodes[i].children.length - 1
        ) {
          var parentOfSecondLevelParent = industriesNewNodes.value.options.find(
            (tag) => tag.children.includes(secondLevelParentNodes[i])
          );
          secondLevelParentNodes[i].parentName =
            parentOfSecondLevelParent.label;
          selectedTagsIndustriesNew.value.push(secondLevelParentNodes[i]);
          let excludedTag = secondLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = secondLevelParentNodes[i].label;
          excludedTagsIndustriesNew.value.push(excludedTag[0]);
        } else if (
          selectedChildren.length !== secondLevelParentNodes[i].children.length
        ) {
          selectedChildren.forEach(function(v) {
            v.parentName = secondLevelParentNodes[i].label;
          });
          selectedTagsIndustriesNew.value.push(...selectedChildren);
        }
      }

      for (let i = 0; i < thirdLevelParentNodes.length; i++) {
        let selectedChildren = thirdLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedIndustriesNew.some(({ id: id2 }) => id1 === id2)
        );

        if (
          thirdLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            thirdLevelParentNodes[i].children.length - 1
        ) {
          var parentOfThirdLevelParent = industriesNewNodes.value.options.find(
            (tag) => tag.children.includes(thirdLevelParentNodes[i])
          );
          thirdLevelParentNodes[i].parentName = parentOfThirdLevelParent.label;
          selectedTagsIndustriesNew.value.push(thirdLevelParentNodes[i]);
          let excludedTag = thirdLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = thirdLevelParentNodes[i].label;
          excludedTagsIndustriesNew.value.push(excludedTag[0]);
        } else if (
          selectedChildren.length !== thirdLevelParentNodes[i].children.length
        ) {
          selectedChildren.forEach(function(v) {
            v.parentName = thirdLevelParentNodes[i].label;
          });
          selectedTagsIndustriesNew.value.push(...selectedChildren);
        }
      }
    });

    watch([projectTypesNodes, props.selectedProjectTypes], () => {
      selectedTagsProjectTypes.value = [];
      excludedTagsProjectTypes.value = [];
      var rootNodes = projectTypesNodes.value.options.filter(
        (element) =>
          element.children.length === 0 ||
          !element.children ||
          element.children === null
      );
      var firstLevelParentNodes = projectTypesNodes.value.options.filter(
        (element) => element.children !== null && element.children.length > 0
      );
      var secondLevelParentNodes = firstLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();

      var selectedRootTags = rootNodes.filter(({ id: id1 }) =>
        props.selectedProjectTypes.some(({ id: id2 }) => id2 === id1)
      );
      selectedTagsProjectTypes.value.push(...selectedRootTags);

      for (let i = 0; i < firstLevelParentNodes.length; i++) {
        let selectedChildren = firstLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedProjectTypes.some(({ id: id2 }) => id1 === id2)
        );

        if (
          selectedChildren.length === firstLevelParentNodes[i].children.length
        ) {
          selectedTagsProjectTypes.value.push(firstLevelParentNodes[i]);
        } else if (
          firstLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            firstLevelParentNodes[i].children.length - 1
        ) {
          selectedTagsProjectTypes.value.push(firstLevelParentNodes[i]);
          let excludedTag = firstLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = firstLevelParentNodes[i].label;
          excludedTagsProjectTypes.value.push(excludedTag[0]);
        } else {
          selectedChildren.forEach(function(v) {
            v.parentName = firstLevelParentNodes[i].label;
          });
          selectedTagsProjectTypes.value.push(...selectedChildren);
        }
      }

      for (let i = 0; i < secondLevelParentNodes.length; i++) {
        let selectedChildren = secondLevelParentNodes[
          i
        ].children.filter(({ id: id1 }) =>
          props.selectedProjectTypes.some(({ id: id2 }) => id1 === id2)
        );

        if (
          secondLevelParentNodes[i].children.length !== 1 &&
          selectedChildren.length ===
            secondLevelParentNodes[i].children.length - 1
        ) {
          var parentOfSecondLevelParent = projectTypesNodes.value.options.find(
            (tag) => tag.children.includes(secondLevelParentNodes[i])
          );
          secondLevelParentNodes[i].parentName =
            parentOfSecondLevelParent.label;
          selectedTagsProjectTypes.value.push(secondLevelParentNodes[i]);
          let excludedTag = secondLevelParentNodes[i].children.filter(
            ({ id: id1 }) =>
              !selectedChildren.some(({ id: id2 }) => id1 === id2)
          );
          excludedTag[0].parentName = secondLevelParentNodes[i].label;
          excludedTagsProjectTypes.value.push(excludedTag[0]);
        } else if (
          selectedChildren.length !== secondLevelParentNodes[i].children.length
        ) {
          selectedChildren.forEach(function(v) {
            v.parentName = secondLevelParentNodes[i].label;
          });
          selectedTagsProjectTypes.value.push(...selectedChildren);
        }
      }
    });

    return {
      propsSelectedAudience,
      propsSelectedPurposes,
      propsSelectedLocations,
      propsSelectedSchemeTypes,
      propsSelectedProjectTypes,
      propsSelectedIndustriesNew,
      propsSelectedKeywords,
      showSelectTags,
      enableShowSelectTags,
      audienceNodes,
      locationsNodes,
      purposesNodes,
      schemeTypesNodes,
      projectTypesNodes,
      industriesNewNodes,
      allKeywordsTags,
      filterOptions,
      selectedTagsAudience,
      tab,
      excludedTagsAudience,
      selectedTagsPurposes,
      excludedTagsPurposes,
      selectedTagsIndustriesNew,
      excludedTagsIndustriesNew,
      selectedTagsLocations,
      excludedTagsLocations,
      selectedTagsSchemeTypes,
      excludedTagsSchemeTypes,
      selectedTagsProjectTypes,
      excludedTagsProjectTypes,
      propsSelectedGroups,

      createValue(val, done) {
        if (val.length > 0) {
          if (!allKeywordsTags.value.includes(val)) {
            store.commit("tagsNavigation/updateAllKeywordsTags", val);
          }
          done(val, "add-unique");
        }
      },
      filterFn(val, update) {
        update(() => {
          if (val === "") {
            filterOptions.value = allKeywordsTags.value;
          } else {
            const needle = val.toLowerCase();
            filterOptions.value = allKeywordsTags.value.filter(
              (v) => v.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
    };
  },
};
</script>
