<template>
  <div class="tags-page">
    <TagsNavigation />
  </div>
</template>

<script>
import TagsNavigation from '../components/tags-components/TagsNavigation.vue';
export default {
  name: 'Tags',
  components: {
    TagsNavigation,
 
  },
  setup() {
  },
};
</script>
