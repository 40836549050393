import axios from 'axios';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
import axiosUrl from './axios.service';
import AuthService from '../services/auth.service';
import router from '../router';
import schemesServices from './schemes.services';

const API_URL = axiosUrl.getAxiosUrl();

class LinkCheckerService {
  async getVisualPingLinks(
    filter,
    showUnchangedLinks = true,
    showChangedLinks = true,
    showBrokenLinks = true,
    sortBy,
    descending,
    page,
    pageSize
  ) {
      return await axios
        .get(API_URL + 'linkChecker/getVisualPingLinks', {
          params: {
            filter: filter,
            showUnchangedLinks,
            showChangedLinks,
            showBrokenLinks,
            sortBy: sortBy,
            descending: descending,
            index: page ? page - 1 : 0,
            size: pageSize ?? 50,
        },
          headers: authHeader(),
        })
        .then((response) => {
          store.commit('linkChecker/updateVisualPingLinks', response.data.items);
          store.commit('linkChecker/updatePagination', response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            AuthService.logout();
            router.go();
          }
          if (error) {
            if (error.response.status === 403) {
              store.commit(
                'linkChecker/updateModalError',
                "You don't have permission to perform this action!"
              ),
              store.commit('linkChecker/enableModalError');
            } else {
                store.commit('linkChecker/updateModalError', error.response.data),
                store.commit('linkChecker/enableModalError');
            }
          }
        });
  }
     
  async addVisualPingLink(schemeId, link) {
    return await axios
      .post(API_URL + `linkChecker/addVisualPingLink`,
      {
        schemeId: schemeId.value != null ? schemeId.value : link.idOfSelectedScheme,
        url: link.url,
        text: link.text
      }, 
      {
        headers: authHeader(),
      })
      .then(async (response) => {
        if (response.status == 200) {
          if (schemeId.value != null)
          {
            await schemesServices.getSchemeVisualPingLinks(schemeId.value);
            await schemesServices.getColorBasedOnVisualpingLinksStatuses(schemeId.value);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (schemeId.value != null) {
            if (error.response.status === 403) {
              store.commit(
                'scheme/updateModalError',
                "You don't have permission to perform this action!"
              ),
              store.commit('scheme/enableModalError');
            } else {
                store.commit('scheme/updateModalError', error.response.data),
                store.commit('scheme/enableModalError');
            }
          }
          else {
            if (error.response.status === 403) {
              store.commit(
                'linkChecker/updateModalError',
                "You don't have permission to perform this action!"
              ),
              store.commit('linkChecker/enableModalError');
            } else {
                store.commit('linkChecker/updateModalError', error.response.data),
                store.commit('linkChecker/enableModalError');
            }
          }
        }
      });
  }
    
  async editVisualPingLink(link, schemeId) {
    return await axios
      .post(API_URL + `linkChecker/editVisualPingLink`,
      {
        linkId: link.id,
        url: link.url,
        text: link.text,
        schemeId: link.schemeId
      }, 
      {
        headers: authHeader(),
      })
      .then(async (response) => {
        if (response.status == 200) {
          if (schemeId != null) {
            await schemesServices.getSchemeVisualPingLinks(schemeId);
            await schemesServices.getColorBasedOnVisualpingLinksStatuses(schemeId);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (schemeId != null) {
            if (error.response.status === 403) {
              store.commit(
                'scheme/updateModalError',
                "You don't have permission to perform this action!"
              ),
              store.commit('scheme/enableModalError');
            } else {
                store.commit('scheme/updateModalError', error.response.data),
                store.commit('scheme/enableModalError');
            }
          }
          else {
            if (error.response.status === 403) {
              store.commit(
                'linkChecker/updateModalError',
                "You don't have permission to perform this action!"
              ),
              store.commit('linkChecker/enableModalError');
            } else {
                store.commit('linkChecker/updateModalError', error.response.data),
                store.commit('linkChecker/enableModalError');
            }
          }
        }
      });
  }

  async removeVisualPingLink(linkId, schemeId) {
    return await axios
      .post(API_URL + `linkChecker/removeVisualPingLink`,
      {
        id: linkId,
      }, 
      {
        headers: authHeader(),
      })
      .then(async (response) => {
        if (response.status == 200) {
          if (schemeId != null) {
            await schemesServices.getSchemeVisualPingLinks(schemeId);
            await schemesServices.getColorBasedOnVisualpingLinksStatuses(schemeId);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (schemeId != null) {
            if (error.response.status === 403) {
              store.commit(
                'scheme/updateModalError',
                "You don't have permission to perform this action!"
              ),
              store.commit('scheme/enableModalError');
            } else {
                store.commit('scheme/updateModalError', error.response.data),
                store.commit('scheme/enableModalError');
            }
          }
          else {
            if (error.response.status === 403) {
              store.commit(
                'linkChecker/updateModalError',
                "You don't have permission to perform this action!"
              ),
              store.commit('linkChecker/enableModalError');
            } else {
                store.commit('linkChecker/updateModalError', error.response.data),
                store.commit('linkChecker/enableModalError');
            }
          }
        }
      });
  }
  
  async getVisualPingLinkWithChanges(linkId) {
    return await axios
      .get(API_URL + 'linkChecker/getvisualpinglinkwithchanges', {
        params: {
          linkid: linkId
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('linkChecker/updateVisualPingLinkWithChanges', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'linkChecker/updateModalError',
              "You don't have permission to perform this action!"
            ),
            store.commit('linkChecker/enableModalError');
          } else {
              store.commit('linkChecker/updateModalError', error.response.data),
              store.commit('linkChecker/enableModalError');
          }
        }
      });
  }

  async markVPNotificationAsChecked(notificationId, linkId, schemeId) {
    return await axios
      .post(API_URL + `linkChecker/markVpNotificationAsChecked`,
      {
        id: notificationId,
      }, 
      {
        headers: authHeader(),
      })
      .then(async (response) => {
        if (response.status == 200) {
          if (schemeId != null)
          {
            await schemesServices.getVisualPingLinkWithChanges(linkId);
            await schemesServices.getColorBasedOnVisualpingLinksStatuses(schemeId);
          }
          else {
            await this.getVisualPingLinkWithChanges(linkId);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (schemeId != null) {
            if (error.response.status === 403) {
              store.commit(
                'scheme/updateModalError',
                "You don't have permission to perform this action!"
              ),
              store.commit('scheme/enableModalError');
            } else {
                store.commit('scheme/updateModalError', error.response.data),
                store.commit('scheme/enableModalError');
            }
          }
          else {
            if (error.response.status === 403) {
              store.commit(
                'linkChecker/updateModalError',
                "You don't have permission to perform this action!"
              ),
              store.commit('linkChecker/enableModalError');
            } else {
                store.commit('linkChecker/updateModalError', error.response.data),
                store.commit('linkChecker/enableModalError');
            }
          }
        }
      });
  } 
  
  async toggleVPJobStatus(jobId, status, schemeId) {
    return await axios
      .post(API_URL + `linkChecker/toggleVisualpingJobStatus`,
      {
        jobId: jobId,
        jobEnabled: status
      }, 
      {
        headers: authHeader(),
      })
      .then(async (response) => {
        if (response.status == 200) {
          if (schemeId != null) {
            await schemesServices.getSchemeVisualPingLinks(schemeId);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (schemeId != null) {
            if (error.response.status === 403) {
              store.commit(
                'scheme/updateModalError',
                "You don't have permission to perform this action!"
              ),
              store.commit('scheme/enableModalError');
            } else {
                store.commit('scheme/updateModalError', error.response.data),
                store.commit('scheme/enableModalError');
            }
          }
          else {
            if (error.response.status === 403) {
              store.commit(
                'linkChecker/updateModalError',
                "You don't have permission to perform this action!"
              ),
              store.commit('linkChecker/enableModalError');
            } else {
                store.commit('linkChecker/updateModalError', error.response.data),
                store.commit('linkChecker/enableModalError');
            }
          }
        }
      });
  }   
}

export default new LinkCheckerService();