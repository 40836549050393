import axios from 'axios';
import axiosUrl from './axios.service';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
const API_URL = axiosUrl.getAxiosUrl();
import moment from 'moment';
import router from '../router/index';
import AuthService from '../services/auth.service';

class NewsService {
  async getSearchResults(
    searchText,
    dateFrom,
    dateTo,
    audienceTags,
    schemesTags,
    projectTypesTags,
    locationsTags,
    germanLocationsPaths,
    purposesTags,
    //This is just hidden for now, don't remove it
    //industriesTags,
    orgsIncludeString,
    orgsExcludeString,
    selectedSchemesIds,
    page = 0,
    PageSize = 50,
    showDeletedItems = false
  ) {
    return await axios
      .get(API_URL + 'search/searchnews', {
        headers: authHeader(),
        params: {
          Term: searchText.value,
          DateFrom: moment(dateFrom, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          DateTo: moment(dateTo, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          AudienceTags: audienceTags.value,
          PurposesTags: purposesTags.value,
          SchemeTypesTags: schemesTags.value,
          ProjectTypesTags: projectTypesTags.value,
          LocationsTags: locationsTags.value != null && locationsTags.value.length > 0 ? locationsTags.value.join() : null,
          GermanLocationsTags: germanLocationsPaths.value != null && germanLocationsPaths.value.length > 0 ? germanLocationsPaths.value.join() : null,
          //This is just hidden for now, don't remove it
          //IndustriesTags: industriesTags.value,
          NotOrganisations:
            orgsExcludeString.value != null &&
            orgsExcludeString.value.length > 0
              ? orgsExcludeString.value.join()
              : null,
          Organisations:
            orgsIncludeString.value != null &&
            orgsIncludeString.value.length > 0
              ? orgsIncludeString.value.join()
              : null,
          Schemes:
            selectedSchemesIds.value != null &&
            selectedSchemesIds.value.length > 0
              ? selectedSchemesIds.value
              : null,
          PageSize: PageSize,
          Page: page,
          showDeletedItems: showDeletedItems,
        },
      })
      .then((response) => {
        store.commit('newsSearch/updateCurrentPage', 1);
        store.commit('news/updateSearchResults', response.data);
        store.commit('newsSearch/updatePageCount', response.data.pageCount);
        store.commit('news/updatePagination', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }
  async getAllNews(page, dateFrom, dateTo) {
    return await axios
      .get(API_URL + 'search/SearchNews', {
        params: {
          pageSize: 50,
          page: page - 1,
          DateFrom: moment(dateFrom, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          DateTo: moment(dateTo, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          Sort: 'Nieuwste',
          showDeletedItems: false,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('news/updateAllNews', response.data);
        store.commit('news/updatePagination', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }

  async sendInviteReminders() {
    return await axios
      .get(API_URL + 'news/GetStartConsultantInviteReminder', {
        headers: authHeader(),
      })
      .then(() => {
        alert('The reminders have been sent');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          store.commit(
            'news/updateModalError',
            'An error occured while sending the reminders!'
          ),
            store.commit('news/enableModalError');
        }
      });
  }

  async sendInvites() {
    return await axios
      .get(API_URL + 'news/GetStartConsultantInvite', {
        headers: authHeader(),
      })
      .then(() => {
        alert('The invites have been sent');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          store.commit(
            'news/updateModalError',
            'An error occured while sending the invitations!'
          ),
            store.commit('news/enableModalError');
        }
      });
  }

  async getPublicationStatuses() {
    return await axios
      .get(API_URL + 'news/getPublicationStatuses', { headers: authHeader() })
      .then((response) => {
        store.commit('news/updatePublicationStatuses', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }

  getScopes() {
    return axios
      .get(API_URL + `schemes/getAllScopes`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('news/updateScopes', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }

  async getLanguageCodes() {
    return await axios
      .get(API_URL + `users/getAllLanguageCodes`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('news/updateLanguageCodes', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }

  async getSchemesForSelect() {
    return await axios
      .get(API_URL + 'schemes/getAllSchemesForSelect', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('news/updateSchemesForSelect', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }

  async getNewsById(id) {
    return await axios
      .get(API_URL + 'news/getNewsById', {
        params: {
          newsId: id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('news/updateSelectedNews', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }

  async getNewsSelectedTags(newsId, tagType, tagTypeValue) {
    return await axios
      .get(
        API_URL +
          `news/getNewsSelectedTags?newsId=${newsId}&tagType=${tagTypeValue}`,
        {
          params: {
            culture: 'nl',
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var type = tagType.split(' ').join('');
          store.commit(
            'news/setSelectedNews' + `${type}` + 'Tags',
            response.data
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }

  async publishNews(id) {
    return await axios({
      method: 'post',
      url: API_URL + 'news/publishNews',
      headers: authHeader(),
      data: {
        Id: id,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          router.go();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }

  async createNewsItem(news) {
    return await axios({
      method: 'post',
      url: API_URL + 'news/addNews',
      headers: authHeader(),
      data: {
        Title: news.title,
        Introduction: news.introduction,
        Body: news.body,
        Date:
          news.date == null || news.date == ''
            ? null
            : moment(news.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        Status: news.status,
        Schemes: news.newsSchemes,
        Scope: news.selectedScope,
        NewsLetterTypes:
          (news.newsLetterTypes != null) & (news.newsLetterTypes.length > 0)
            ? news.newsLetterTypes
            : null,
        InheritAudience: news.audiences,
        InheritPurpose: news.purposes,
        //This is just hidden for now, don't remove it
        //InheritIndustry: news.industries,
        InheritLocation: news.locations,
        InheritSchemeType: news.schemetypes,
        InheritProjectType: news.projecttypes,
        InheritKeywords: news.keywords,
        InheritScope: news.scope,
        InheritIndustriesNew: news.industriesNew,
        Tags: news.newsAudienceTags

          .concat(news.newsPurposesTags)

          .concat(news.newsLocationsTags)

          .concat(news.newsSchemeTypesTags)

          .concat(news.newsProjectTypesTags)

          .concat(news.newsIndustriesNewTags),
        KeywordsTags: news.newsKeywordsTags,
        LocationTags: news.newsGermanLocationsTags,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          axios
            .get(API_URL + 'news/getNewsById', {
              params: {
                newsId: response.data.id,
              },
              headers: authHeader(),
            })
            .then((response) => {
              var id = response.data.id;
              router.push({ name: 'NewsEdit', params: { id: id } });
            });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            router.go(-1);
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }

  async editNews(news) {
    return await axios({
      method: 'post',
      url: API_URL + `news/editNews`,
      headers: authHeader(),
      data: {
        Id: news.id,
        Title: news.title.value,
        Introduction: news.introduction.value,
        Body: news.body.value,
        Date:
          news.date.value == null || news.date.value == ''
            ? null
            : moment(news.date.value, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        Status: news.status.value,
        Scope: news.scope.value,
        Schemes: news.schemes.value,
        InheritAudience: news.inheritAudiences.value,
        InheritPurpose: news.inheritPurposes.value,
        InheritIndustriesNew: news.inheritIndustriesNew.value,
        InheritLocation: news.inheritLocations.value,
        InheritSchemeType: news.inheritSchemeTypes.value,
        InheritProjectType: news.inheritProjectTypes.value,
        InheritKeywords: news.inheritKeywords.value,
        Language: news.language.value,
        Tags: news.newsAudienceTags.value

          .concat(news.newsPurposesTags.value)

          .concat(news.newsLocationsTags.value)

          .concat(news.newsSchemeTypesTags.value)

          .concat(news.newsProjectTypesTags.value)

          .concat(news.newsIndustriesNewTags.value),

        //This is just hidden for now, don't remove it
        //.concat(news.newsIndustriesTags.value)
        KeywordsTags: news.newsKeywordsTags.value,
        NewsLetterTypes: news.newsLetterTypes.value,
        LocationTags: news.newsGermanLocationsTags.value,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          router.push({ name: 'News' });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            router.go(-1);
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }

  getNewsLetterTypes() {
    return axios
      .get(API_URL + 'news/getNewsletterTypes', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('news/updateNewsLetterTypesList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
      });
  }

  getNewsLetterTypesById(id) {
    return axios
      .get(API_URL + 'news/getNewsletterTypesById', {
        headers: authHeader(),
        params: {
          id: id,
        },
      })
      .then((response) => {
        store.commit('news/updateSelectedNewsLetterTypes', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
      });
  }

  async getSchemesForSelectSearch() {
    return await axios
      .get(API_URL + 'schemes/getAllSchemesForSelect', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('newsSearch/updateSchemesForSelect', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }

  async deleteNewsItem(id) {
    return await axios({
      method: 'post',
      url: API_URL + `news/deleteNewsItem`,
      headers: authHeader(),
      data: {
        newsId: parseInt(id),
      },
    })
      .then((response) => {
        if (response.status == 200) {
          router.push({ name: 'News' });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            router.go(-1);
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('news/enableModalError');
          } else {
            store.commit('news/updateModalError', error.response.data),
              store.commit('news/enableModalError');
          }
        }
      });
  }
}

export default new NewsService();
