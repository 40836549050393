<template>
  <div class="schemes-search-page">
    <div v-if="loading" style="text-align: center;">
      <span style="display: inline-block">
        <q-spinner-bars color="primary" size="5em" />
      </span>
    </div>
    <div class="row justify-between">
      <q-btn
        class="button-search btn-vind-dark"
        @click="searchSchemes()"
        :disable="loading"
      >
        <i class="las la-search search-icon"></i> Search
      </q-btn>

      <q-btn
        style="background: #25408f; color: white; text-decoration: none; width: 180px"
        class="button-search btn-vind-dark"
        :disable="loading"
        label="Create Scheme"
        :to="{ name: 'CreateScheme' }"
        dense
      >
        <i class="las la-2x la-plus-circle la-fw"></i>
      </q-btn>
    </div>
    <div class="row"></div>

    <div class="row justify-between">
      <div class="input-group mb-3 searchbox">
        <q-input
          outlined
          v-model="searchText"
          label="Search"
          class="sidebar-margin search-input"
          stack-label
          bg-color="white"
          v-on:keyup.enter="searchSchemes()"
        >
          <template v-slot:append>
            <q-icon name="search" class="date-icon" />
          </template>
        </q-input>
      </div>
      <div class="input-group mb-3 searchbox">
        <q-input
          outlined
          v-model="durationPhrase"
          label="Duration phrase"
          class="sidebar-margin search-input"
          stack-label
          bg-color="white"
        >
          <template v-slot:append>
            <q-icon name="search" class="date-icon" />
          </template>
        </q-input>
      </div>
      <div class="toggles">
        <q-toggle
          v-model="searchOnlyName"
          label="Search only scheme name"
          color="indigo-10"
        />
        <q-toggle
          v-model="showAllLanguageVersions"
          label="Show all language versions (German and Dutch schemes)"
          color="indigo-10"
        />
        <div class="col-md-2">
          <q-checkbox
            v-model="isEnglishScheme"
            label="Show only english schemes"
            class="is-portal-checkbox"
          />
          <q-checkbox
            style="margin-left: 24px"
            v-model="isObScheme"
            label="Is OB Scheme"
            class="is-portal-checkbox"
          />
        </div>
      </div>
    </div>
    <div class="row q-mt-md">
      <div class="column">
        <span>Is portal scheme</span>
        <q-btn-group>
          <q-btn
            no-caps
            label="Hide"
            icon="las la-2x la-times"
            :disable="showOnlyICTU || showOnlyScience"
            @click="updatePortalShowing('hide')"
            v-model="showIsPortal"
            :class="!showIsPortal && !showOnlyPortal ? 'active-toggle-btn' : ''"
          />
          <q-btn
            no-caps
            label="Show"
            icon="visibility"
            :disable="showOnlyICTU || showOnlyScience"
            @click="updatePortalShowing('show')"
            v-model="showIsPortal"
            :class="showIsPortal && !showOnlyPortal ? 'active-toggle-btn' : ''"
          />
          <q-btn
            no-caps
            label="Only"
            icon="las la-2x la-filter"
            @click="updatePortalShowing('filter')"
            v-model="showOnlyPortal"
            :class="showOnlyPortal ? 'active-toggle-btn' : ''"
          />
        </q-btn-group>
      </div>

      <div class="column q-ml-xl">
        <span>Is science scheme</span>
        <q-btn-group>
          <q-btn
            no-caps
            label="Hide"
            icon="las la-2x la-times"
            :disable="showOnlyICTU || showOnlyPortal"
            @click="updateScienceShowing('hide')"
            v-model="showIsScience"
            :class="
              !showIsScience && !showOnlyScience ? 'active-toggle-btn' : ''
            "
          />
          <q-btn
            no-caps
            label="Show"
            icon="visibility"
            :disable="showOnlyICTU || showOnlyPortal"
            @click="updateScienceShowing('show')"
            v-model="showIsScience"
            :class="
              showIsScience && !showOnlyScience ? 'active-toggle-btn' : ''
            "
          />
          <q-btn
            no-caps
            label="Only"
            icon="las la-2x la-filter"
            @click="updateScienceShowing('filter')"
            v-model="showOnlyScience"
            :class="showOnlyScience ? 'active-toggle-btn' : ''"
          />
        </q-btn-group>
      </div>

      <div class="column q-ml-xl">
        <span>Is ICTU scheme</span>
        <q-btn-group>
          <q-btn
            no-caps
            label="Hide"
            icon="las la-2x la-times"
            :disable="showOnlyScience || showOnlyPortal"
            @click="updateICTUShowing('hide')"
            v-model="showIsICTU"
            :class="!showIsICTU && !showOnlyICTU ? 'active-toggle-btn' : ''"
          />
          <q-btn
            no-caps
            label="Show"
            icon="visibility"
            :disable="showOnlyScience || showOnlyPortal"
            @click="updateICTUShowing('show')"
            v-model="showIsICTU"
            :class="showIsICTU && !showOnlyICTU ? 'active-toggle-btn' : ''"
          />
          <q-btn
            no-caps
            label="Only"
            icon="las la-2x la-filter"
            @click="updateICTUShowing('filter')"
            v-model="showOnlyICTU"
            :class="showOnlyICTU ? 'active-toggle-btn' : ''"
          />
        </q-btn-group>
      </div>
    </div>

    <div class="row justify-between">
      <q-input
        class="sidebar-margin date-input"
        bg-color="white"
        v-model="startDate"
        label="Start date"
        stack-label
        outlined
      >
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer date-icon">
            <q-popup-proxy
              ref="qDateProxy"
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date v-model="startDate" mask="DD-MM-YYYY">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
      <q-input
        class="sidebar-margin date-input"
        bg-color="white"
        v-model="endDate"
        label="End date"
        stack-label
        outlined
      >
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer date-icon">
            <q-popup-proxy
              ref="qDateProxy"
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date v-model="endDate" mask="DD-MM-YYYY">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
      <q-input
        bg-color="white"
        v-model="updatedDateGreaterThan"
        outlined
        class="sidebar-margin small-date-input"
        label="Last update (from)"
        stack-label
      >
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              ref="qDateProxy"
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date v-model="updatedDateGreaterThan" mask="DD-MM-YYYY">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
      <q-input
        bg-color="white"
        v-model="updatedDateLessThan"
        outlined
        class="small-date-input sidebar-margin"
        label="Last update (to)"
        stack-label
      >
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              ref="qDateProxy"
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date v-model="updatedDateLessThan" mask="DD-MM-YYYY">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>

    <div class="row justify-between">
      <FilterCheckBox class="sidebar-margin item-width" />
      <div class="filter-box statuses-checkbox">
        <span class="filter-box-heading">Publication statuses</span>
        <q-option-group
          v-model="publicationStatusesGroup"
          :options="publicationStatuses"
          type="checkbox"
        />
      </div>
      <div class="filter-box sidebar-margin">
        <span class="filter-box-heading">Budgets </span>

        <div class="row start">
          <q-input
            v-model.number="min"
            type="number"
            min="0"
            label="Minimum budget"
            class="budget-input"
            outlined
          />
        </div>

        <div class="row start">
          <q-input
            outlined
            v-model.number="max"
            type="number"
            min="0"
            label="Maximum budget"
            class="budget-input"
          />
        </div>
      </div>

      <div class="filter-box sidebar-margin item-width orgs-box">
        <span class="filter-box-heading">Organisations </span>
        <div class="row start orgs-select">
          <q-select
            outlined
            bg-color="white"
            v-model="organisationsInclude"
            multiple
            :options="filterOrganisationsOptions"
            use-input
            use-chips
            option-value="id"
            class="budget-input"
            option-label="name"
            stack-label
            label="Include organisation(s)"
            @filter="filterOrganisationsFn"
            emit-value
            map-options
            @update:model-value="setInput()"
            ref="schemesSearchIncludeOrgSelect"
          />
        </div>
        <div class="row q-pl-sm">
          <span class="filter-box-heading-sm">Role of organisation: </span>
          <q-checkbox
            size="xs"
            v-model="searchOnAdministratingOrgs"
            label="Administrator"
          />
          <q-checkbox
            size="xs"
            v-model="searchOnFundingOrgs"
            label="Provider"
          />
          <q-checkbox
            size="xs"
            v-model="searchOnAdvisingOrgs"
            label="Advisor"
          />
          <q-checkbox
            size="xs"
            v-model="searchOnRecipientOrgs"
            label="Recipient"
          />
        </div>
        <div class="row start orgs-select">
          <q-select
            outlined
            bg-color="white"
            v-model="organisationsExclude"
            multiple
            :options="filterOrganisationsOptions"
            use-chips
            use-input
            option-value="id"
            class="budget-input"
            option-label="name"
            stack-label
            label="Exclude organisation(s)"
            @filter="filterOrganisationsFn"
            emit-value
            map-options
            @update:model-value="setInput()"
            ref="schemesSearchExcludeOrgSelect"
          />
        </div>
      </div>
      <div class="filter-box sidebar-margin">
        <span class="filter-box-heading">Tender start: </span>
        <div class="row justify-start">
          <q-input
            bg-color="white"
            v-model="schemeStartGreaterThan"
            outlined
            class="col-md-5 q-ml-xs"
          >
            <template v-slot:before> </template>
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date v-model="schemeStartGreaterThan" mask="DD-MM-YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <q-input
            bg-color="white"
            v-model="schemeStartLessThan"
            outlined
            class="col-md-5 q-ml-xs"
          >
            <template v-slot:before>
              <span>&nbsp;-</span>
            </template>
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date v-model="schemeStartLessThan" mask="DD-MM-YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
        <span class="filter-box-heading">Tender end: </span>
        <div class="row justify-start">
          <q-input
            bg-color="white"
            v-model="schemeEndGreaterThan"
            outlined
            class="col-md-5 q-ml-xs"
          >
            <template v-slot:before> </template>
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date v-model="schemeEndGreaterThan" mask="DD-MM-YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <q-input
            bg-color="white"
            v-model="schemeEndLessThan"
            outlined
            class="col-md-5 q-ml-xs"
          >
            <template v-slot:before>
              <span>&nbsp;-</span>
            </template>
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date v-model="schemeEndLessThan" mask="DD-MM-YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
      </div>
      <div class="filter-box sidebar-margin">
        <span class="filter-box-heading">Locations </span>
        <div class="row">
          <q-select
            outlined
            bg-color="white"
            v-model="selectedLocations"
            multiple
            :options="filterLocationsTags"
            option-value="id"
            class="budget-input"
            option-label="label"
            placeholder="Start typing locations"
            use-input
            use-chips
            input-debounce="0"
            behavior="menu"
            dense
            @filter="filterLocationsFn"
            emit-value
            map-options
            @update:model-value="setInput()"
            ref="schemesSearchPageLocationsSelect"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  No results
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <div class="row">
          <span class="filter-box-heading">German location tags</span>
        </div>
        <div class="row">
          <q-select
            outlined
            bg-color="white"
            class="budget-input"
            multiple
            v-model="selectedGermanLocationsPaths"
            :options="germanLocationTagsBulkList"
            @filter="filterGermanLocationTagsBulk"
            placeholder="Start typing German locations"
            use-chips
            use-input
            input-debounce="500"
            behavior="menu"
            option-value="path"
            option-label="label"
            dense
            emit-value
            map-options
          />
        </div>
      </div>
    </div>

    <div class="row justify-between">
      <default-tree
        class="sidebar-margin date-input"
        :nodesState="audienceNodes.options"
        :tagType="audienceNodes.value"
        :filtering="true"
        :prevSelected="audiences"
      />

      <default-tree
        class="sidebar-margin date-input"
        :nodesState="purposesNodes.options"
        :tagType="purposesNodes.value"
        :prevSelected="purposes"
      />

      <default-tree
        class="sidebar-margin date-input"
        :nodesState="schemeTypesNodes.options"
        :tagType="schemeTypesNodes.value"
        :prevSelected="schemeTypes"
      />

      <default-tree
        class="sidebar-margin date-input"
        :nodesState="projectTypesNodes.options"
        :tagType="projectTypesNodes.value"
        :prevSelected="projectTypes"
      />
    </div>

    <div class="sidebar-margin advanced-filters-container">
      <AdvancedFilters class="sidebar-margin item-width" />
      <q-btn
        class="button-search btn-vind-dark"
        @click="searchSchemes()"
        :disable="loading"
      >
        <i class="las la-search search-icon"></i> Search
      </q-btn>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { mapState } from 'vuex';
import { computed } from 'vue';
import store from '../../store/index.js';
import DefaultTree from './DefaultTree.vue';
import FilterCheckBox from './StatusesCheckBox.vue';
import FiltersService from '../../services/filters.service';
import SchemesService from '../../services/schemes.services';
import TagsService from '../../services/tags.service';
import AdvancedFilters from './AdvancedFilters.vue';
import OrganisationsService from '../../services/organisations.service';
import moment from 'moment';
import router from '../../router/index';
export default {
  name: 'SchemesSearchPage',
  components: {
    FilterCheckBox,
    DefaultTree,
    AdvancedFilters,
  },

  setup() {
    const loading = ref(false);

    //get data AdvancedSearch - scheme statuses
    const schemeStatusGroup = computed({
      get: () =>
        store.state.advancedSearch.schemeStatusGroup &&
        store.state.advancedSearch.schemeStatusGroup.length > 0 &&
        (JSON.parse(sessionStorage.getItem('schemeStatusGroup')) == null ||
          (JSON.parse(sessionStorage.getItem('schemeStatusGroup')) &&
            store.state.advancedSearch.schemeStatusGroup.join() ===
              JSON.parse(sessionStorage.getItem('schemeStatusGroup')).join()))
          ? store.state.advancedSearch.schemeStatusGroup
          : JSON.parse(sessionStorage.getItem('schemeStatusGroup')),
      set: (value) => {
        store.commit('advancedSearch/updateSchemeStatusGroup', value);
      },
    });

    //get data AdvancedSearch - audience
    const audienceNodes = computed({
      get: () => store.state.advancedSearch.audience,
    });
    const audiences = computed({
      get: () =>
        store.state.advancedSearch.selectedAudiences != ''
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });

    //This is just hidden for now, don't remove it
    /*
    const industriesNodes = computed({
      get: () => store.state.advancedSearch.industries,
    });
    const industries = computed({
      get: () => store.state.advancedSearch.selectedIndustries,
    });
    */

    //get data AdvancedSearch - locations
    /*const locationsNodes = computed({
      get: () => store.state.advancedSearch.locations,
    });*/
    //get data AdvancedSearch - purpose
    const purposesNodes = computed({
      get: () => store.state.advancedSearch.purposes,
    });
    const purposes = computed({
      get: () =>
        store.state.advancedSearch.selectedPurposes != ''
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });
    //get data AdvancedSearch - schemeTypes
    const schemeTypesNodes = computed({
      get: () => store.state.advancedSearch.schemeTypes,
    });
    const schemeTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedSchemeTypes != ''
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });
    // project types
    const projectTypesNodes = computed({
      get: () => store.state.advancedSearch.projectTypes,
    });
    const projectTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedProjectTypes != ''
          ? store.state.advancedSearch.selectedProjectTypes
          : sessionStorage.getItem('selectedProjectTypes'),
    });
    //get data AdvancedSearch - resultTypes
    const resultTypes = computed({
      get: () => store.state.advancedSearch.resultTypesString,
    });

    //get data AdvancedSearch - Budget
    const minBudget = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : JSON.parse(sessionStorage.getItem('minBudget')),
    });
    const maxBudget = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : JSON.parse(sessionStorage.getItem('maxBudget')),
    });

    //get data advancedFilters - SME
    const SME = computed({
      get: () => store.state.advancedFilters.smeString,
    });
    //get data advancedFilters - ApplicationTerm
    const applicationTerm = computed({
      get: () => store.state.advancedFilters.termsString,
    });
    //get data advancedFilters - Scope
    const scope = computed({
      get: () => store.state.advancedFilters.scopesString,
    });
    const GetstartDate = computed({
      get: () =>
        store.state.advancedSearch.startDate != ''
          ? store.state.advancedSearch.startDate
          : JSON.parse(sessionStorage.getItem('startDate')),
    });
    const GetendDate = computed({
      get: () =>
        store.state.advancedSearch.endDate != ''
          ? store.state.advancedSearch.endDate
          : JSON.parse(sessionStorage.getItem('endDate')),
    });

    const showSchemes = computed({
      get: () => store.state.advancedSearch.showSchemes,
      set: (value) => store.commit('advancedSearch/updateShowSchemes', value),
    });
    const showCalls = computed({
      get: () => store.state.advancedSearch.showCalls,
      set: (value) => store.commit('advancedSearch/updateShowCalls', value),
    });

    const searchText = computed({
      get: () =>
        store.state.advancedSearch.searchText != ''
          ? store.state.advancedSearch.searchText
          : sessionStorage.getItem('searchText'),
      set: (value) => store.commit('advancedSearch/updateSearchText', value),
    });
    const durationPhrase = computed({
      get: () =>
        store.state.advancedSearch.durationPhrase != ''
          ? store.state.advancedSearch.durationPhrase
          : sessionStorage.getItem('durationPhrase'),
      set: (value) =>
        store.commit('advancedSearch/updateDurationPhrase', value),
    });

    const selectedSort = computed({
      get: () => store.state.advancedSearch.selectedSort,
    });

    const filterByICTUFlag = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('filterByICTUFlag')) == null ||
        store.state.advancedSearch.filterByICTUFlag ===
          JSON.parse(sessionStorage.getItem('filterByICTUFlag'))
          ? store.state.advancedSearch.filterByICTUFlag
          : JSON.parse(sessionStorage.getItem('filterByICTUFlag')),
      set: (value) => {
        store.commit('advancedSearch/updateFilterByICTUFlag', value);
      },
    });

    //functions
    const searchSchemes = async () => {
      loading.value = true;
      //commit needed variables
      store.commit('advancedSearch/updateCurrentPage', 1);
      store.commit('advancedSearch/createResultTypesString');
      store.commit('advancedFilters/createScopesString');
      store.commit('advancedFilters/createSMEString');
      //store.commit('advancedFilters/createTermsString');
      let savedSort = sessionStorage.getItem('sortBy');

      await SchemesService.getSearchResultsSorted(
        searchText,
        audiences,
        selectedLocations,
        selectedGermanLocationsPaths,
        //This is just hidden for now, don't remove it
        //industries,
        purposes,
        schemeTypes,
        projectTypes,
        SME,
        applicationTerm,
        minBudget,
        maxBudget,
        scope,
        organisationsInclude,
        organisationsExclude,
        GetstartDate,
        GetendDate,
        0,
        5000,
        //  'Title',
        savedSort != null ? savedSort : 'Title',
        false,
        searchOnlyName,
        publicationStatusesGroup.value.join(),
        filterByICTUFlag,
        durationPhrase,
        updatedDateGreaterThan,
        updatedDateLessThan,
        schemeStartGreaterThan,
        schemeStartLessThan,
        schemeEndGreaterThan,
        schemeEndLessThan,
        searchOnFundingOrgs,
        searchOnAdministratingOrgs,
        searchOnAdvisingOrgs,
        searchOnRecipientOrgs,
        isScienceScheme,
        isPortalScheme,
        isEnglishScheme,
        isObScheme,
        showAllLanguageVersions,
        schemeStatusGroup.value.join()
      );

      loading.value = false;
      router.push({
        path: `/admin/schemes/search-results/`,
        query: {
          searchtext: searchText.value,
          durationphrase: durationPhrase.value,
        },
      });
    };

    onMounted(async () => {
      await SchemesService.searchLocationCodesBulk('');
      await FiltersService.getDefaultFilters();
      await TagsService.getTagTypes();
      await OrganisationsService.getAllOrganisationsList();
      await FiltersService.getAllPublicationStatuses();
    });

    const min = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : JSON.parse(sessionStorage.getItem('minBudget')),
      set: (value) => {
        store.commit('advancedSearch/updateMinBudget', value);
      },
    });

    const max = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : JSON.parse(sessionStorage.getItem('maxBudget')),
      set: (value) => {
        store.commit('advancedSearch/updateMaxBudget', value);
      },
    });

    const allOrganisations = computed({
      get: () => store.state.organisation.organisationsList,
    });

    const filterOrganisationsOptions = ref(allOrganisations.value);
    watch(
      () => allOrganisations.value,
      (loadedOrganisationsOptions) => {
        filterOrganisationsOptions.value = loadedOrganisationsOptions;
      },
      { immediate: true }
    );
    const organisationsInclude = computed({
      get: () =>
        store.state.advancedSearch.organisationsInclude &&
        store.state.advancedSearch.organisationsInclude.length > 0
          ? store.state.advancedSearch.organisationsInclude
          : JSON.parse(sessionStorage.getItem('organisationsInclude')),
      set: (val) => {
        store.commit('advancedSearch/updateOrganisationsInclude', val);
      },
    });

    const organisationsExclude = computed({
      get: () =>
        store.state.advancedSearch.organisationsExclude &&
        store.state.advancedSearch.organisationsExclude.length > 0
          ? store.state.advancedSearch.organisationsExclude
          : JSON.parse(sessionStorage.getItem('organisationsExclude')),
      set: (val) => {
        store.commit('advancedSearch/updateOrganisationsExclude', val);
      },
    });

    const startDate = computed({
      get: () =>
        store.state.advancedSearch.startDate != ''
          ? store.state.advancedSearch.startDate
          : JSON.parse(sessionStorage.getItem('startDate')),
      set: (value) => {
        store.commit('advancedSearch/updateStartDate', value);
      },
    });

    const endDate = computed({
      get: () =>
        store.state.advancedSearch.endDate != ''
          ? store.state.advancedSearch.endDate
          : JSON.parse(sessionStorage.getItem('endDate')),
      set: (value) => {
        store.commit('advancedSearch/updateEndDate', value);
      },
    });

    const updatedDateGreaterThan = computed({
      get: () =>
        store.state.advancedSearch.updatedDateGreaterThan != ''
          ? store.state.advancedSearch.updatedDateGreaterThan
          : JSON.parse(sessionStorage.getItem('updatedDateGreaterThan')),
      set: (value) => {
        store.commit('advancedSearch/updateUpdatedDateGreaterThan', value);
      },
    });

    const updatedDateLessThan = computed({
      get: () =>
        store.state.advancedSearch.updatedDateLessThan != ''
          ? store.state.advancedSearch.updatedDateLessThan
          : JSON.parse(sessionStorage.getItem('updatedDateLessThan')),
      set: (value) => {
        store.commit('advancedSearch/updateUpdatedDateLessThan', value);
      },
    });

    /* Tender start and tender end */

    const schemeStartGreaterThan = computed({
      get: () =>
        store.state.advancedSearch.schemeStartGreaterThan != ''
          ? store.state.advancedSearch.schemeStartGreaterThan
          : JSON.parse(sessionStorage.getItem('schemeStartGreaterThan')),
      set: (value) => {
        store.commit('advancedSearch/updateSchemeStartGreaterThan', value);
      },
    });
    const schemeStartLessThan = computed({
      get: () =>
        store.state.advancedSearch.schemeStartLessThan != ''
          ? store.state.advancedSearch.schemeStartLessThan
          : JSON.parse(sessionStorage.getItem('schemeStartLessThan')),
      set: (value) => {
        store.commit('advancedSearch/updateSchemeStartLessThan', value);
      },
    });
    const schemeEndGreaterThan = computed({
      get: () =>
        store.state.advancedSearch.schemeEndGreaterThan != ''
          ? store.state.advancedSearch.schemeEndGreaterThan
          : JSON.parse(sessionStorage.getItem('schemeEndGreaterThan')),
      set: (value) => {
        store.commit('advancedSearch/updateSchemeEndGreaterThan', value);
      },
    });
    const schemeEndLessThan = computed({
      get: () =>
        store.state.advancedSearch.schemeEndLessThan != ''
          ? store.state.advancedSearch.schemeEndLessThan
          : JSON.parse(sessionStorage.getItem('schemeEndLessThan')),
      set: (value) => {
        store.commit('advancedSearch/updateSchemeEndLessThan', value);
      },
    });

    const locationsOptions = computed({
      get: () => store.state.advancedSearch.locationsOptions,
    });
    const filterLocationsTags = ref(locationsOptions.value);

    const selectedLocations = computed({
      get: () =>
        store.state.advancedSearch.selectedLocations &&
        store.state.advancedSearch.selectedLocations.length > 0
          ? store.state.advancedSearch.selectedLocations
          : JSON.parse(sessionStorage.getItem('selectedLocations')),
      set: (value) => {
        store.commit('advancedSearch/updateSelectedLocations', value);
      },
    });

    watch(
      () => locationsOptions.value,
      (loadedLocationsOptions) => {
        filterLocationsTags.value = loadedLocationsOptions;
      },
      { immediate: true }
    );

    // German locations
    const germanLocationTagsBulkList = computed({
      get: () => store.state.scheme.locationTagsBulkList,
    });
    const selectedGermanLocationsPaths = computed({
      get: () => store.state.advancedSearch.selectedGermanLocationsPaths,
      set: (val) => {
        store.commit('advancedSearch/updateSelectedGermanLocationsPaths', val);
      },
    });

    const searchOnlyName = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('searchOnlyName')) == null ||
        store.state.advancedSearch.searchOnlyName ===
          JSON.parse(sessionStorage.getItem('searchOnlyName'))
          ? store.state.advancedSearch.searchOnlyName
          : JSON.parse(sessionStorage.getItem('searchOnlyName')) === true,
      set: (value) => {
        store.commit('advancedSearch/updateSearchOnlyName', value);
      },
    });

    const showAllLanguageVersions = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('showAllLanguageVersions')) == null ||
        store.state.advancedSearch.showAllLanguageVersions ===
          JSON.parse(sessionStorage.getItem('showAllLanguageVersions'))
          ? store.state.advancedSearch.showAllLanguageVersions
          : JSON.parse(sessionStorage.getItem('showAllLanguageVersions')) ===
            true,
      set: (value) => {
        store.commit('advancedSearch/updateShowAllLanguageVersions', value);
      },
    });

    const publicationStatuses = computed({
      get: () => store.state.advancedFilters.publicationStatusesEnums,
    });

    const publicationStatusesGroup = computed({
      get: () =>
        store.state.advancedSearch.publicationStatusesGroup &&
        store.state.advancedSearch.publicationStatusesGroup.length > 0 &&
        (JSON.parse(sessionStorage.getItem('publicationStatusesGroup')) ==
          null ||
          (JSON.parse(sessionStorage.getItem('publicationStatusesGroup')) &&
            store.state.advancedSearch.publicationStatusesGroup.join() ===
              JSON.parse(
                sessionStorage.getItem('publicationStatusesGroup')
              ).join()))
          ? store.state.advancedSearch.publicationStatusesGroup
          : JSON.parse(sessionStorage.getItem('publicationStatusesGroup')),
      set: (value) => {
        store.commit('advancedSearch/updatePublicationStatusesGroup', value);
      },
    });

    const searchOnAdministratingOrgs = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('searchOnAdministratingOrgs')) ==
          null ||
        store.state.advancedSearch.searchOnAdministratingOrgs ===
          JSON.parse(sessionStorage.getItem('searchOnAdministratingOrgs'))
          ? store.state.advancedSearch.searchOnAdministratingOrgs
          : JSON.parse(sessionStorage.getItem('searchOnAdministratingOrgs')) ===
            true,
      set: (value) => {
        store.commit('advancedSearch/updateSearchOnAdministratingOrgs', value);
      },
    });
    const searchOnFundingOrgs = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('searchOnFundingOrgs')) == null ||
        store.state.advancedSearch.searchOnFundingOrgs ===
          JSON.parse(sessionStorage.getItem('searchOnFundingOrgs'))
          ? store.state.advancedSearch.searchOnFundingOrgs
          : JSON.parse(sessionStorage.getItem('searchOnFundingOrgs')) === true,
      set: (value) => {
        store.commit('advancedSearch/updateSearchOnFundingOrgs', value);
      },
    });
    const searchOnAdvisingOrgs = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('searchOnAdvisingOrgs')) == null ||
        store.state.advancedSearch.searchOnAdvisingOrgs ===
          JSON.parse(sessionStorage.getItem('searchOnAdvisingOrgs'))
          ? store.state.advancedSearch.searchOnAdvisingOrgs
          : JSON.parse(sessionStorage.getItem('searchOnAdvisingOrgs')) === true,
      set: (value) => {
        store.commit('advancedSearch/updateSearchOnAdvisingOrgs', value);
      },
    });
    const searchOnRecipientOrgs = computed({
      get: () =>
        JSON.parse(sessionStorage.getItem('searchOnRecipientOrgs')) == null ||
        store.state.advancedSearch.searchOnRecipientOrgs ===
          JSON.parse(sessionStorage.getItem('searchOnRecipientOrgs'))
          ? store.state.advancedSearch.searchOnRecipientOrgs
          : JSON.parse(sessionStorage.getItem('searchOnRecipientOrgs')) ===
            true,
      set: (value) => {
        store.commit('advancedSearch/updateSearchOnRecipientOrgs', value);
      },
    });

    const isScienceScheme = computed({
      get: () => store.state.advancedSearch.searchOnIsScienceScheme,
      set: (val) => store.commit('advancedSearch/updateIsScienceScheme', val),
    });

    const isPortalScheme = computed({
      get: () => store.state.advancedSearch.searchOnIsPortalScheme,
      set: (val) => store.commit('advancedSearch/updateIsPortalScheme', val),
    });

    const isEnglishScheme = computed({
      get: () => store.state.advancedSearch.searchOnIsEnglishScheme,
      set: (val) => store.commit('advancedSearch/updateIsEnglishScheme', val),
    });
    const isObScheme = computed({
      get: () => store.state.advancedSearch.searchOnIsObScheme,
      set: (val) => store.commit('advancedSearch/updateIsObScheme', val),
    });
    const showIsPortal = computed({
      get: () => store.state.advancedSearch.showIsPortal,
      set: (val) => store.commit('advancedSearch/updatePortalShowing', val),
    });

    const showOnlyPortal = computed({
      get: () => store.state.advancedSearch.showOnlyPortal,
      set: (val) => store.commit('advancedSearch/updatePortalShowing', val),
    });

    const showIsScience = computed({
      get: () => store.state.advancedSearch.showIsScience,
    });

    const showOnlyScience = computed({
      get: () => store.state.advancedSearch.showOnlyScience,
    });

    const showIsICTU = computed({
      get: () => store.state.advancedSearch.showIsICTU,
    });

    const showOnlyICTU = computed({
      get: () => store.state.advancedSearch.showOnlyICTU,
    });

    const updatePortalShowing = (val) => {
      store.commit('advancedSearch/updatePortalShowing', val);
      store.commit('advancedSearch/setTogglesForSearch');
    };

    const updateScienceShowing = (val) => {
      store.commit('advancedSearch/updateScienceShowing', val);
      store.commit('advancedSearch/setTogglesForSearch');
    };

    const updateICTUShowing = (val) => {
      store.commit('advancedSearch/updateICTUShowing', val);
      store.commit('advancedSearch/setTogglesForSearch');
    };

    const schemesSearchPageLocationsSelect = ref(null);
    const schemesSearchIncludeOrgSelect = ref(null);
    const schemesSearchExcludeOrgSelect = ref(null);
    const setInput = () => {
      schemesSearchPageLocationsSelect.value.updateInputValue('');
      schemesSearchIncludeOrgSelect.value.updateInputValue('');
      schemesSearchExcludeOrgSelect.value.updateInputValue('');
    };
    return {
      isObScheme,
      schemesSearchExcludeOrgSelect,
      schemesSearchIncludeOrgSelect,
      setInput,
      schemesSearchPageLocationsSelect,
      schemeStatusGroup,
      showIsICTU,
      showOnlyICTU,
      showIsScience,
      showOnlyScience,
      updateScienceShowing,
      updateICTUShowing,
      updatePortalShowing,
      showIsPortal,
      showOnlyPortal,
      isPortalScheme,
      isScienceScheme,
      isEnglishScheme,
      searchOnAdministratingOrgs,
      searchOnAdvisingOrgs,
      searchOnFundingOrgs,
      searchOnRecipientOrgs,
      updatedDateGreaterThan,
      updatedDateLessThan,
      publicationStatusesGroup,
      publicationStatuses,
      searchOnlyName,
      selectedSort,
      filterLocationsTags,
      locationsOptions,
      selectedLocations,
      moment,
      startDate,
      endDate,
      allOrganisations,
      organisationsInclude,
      organisationsExclude,
      resultTypes,
      searchText,
      audiences,
      //This is just hidden for now, don't remove it
      //industries,
      purposes,
      schemeTypes,
      projectTypes,
      searchSchemes,
      store,
      showSchemes,
      showCalls,
      audienceNodes,
      //This is just hidden for now, don't remove it
      //industriesNodes,
      purposesNodes,
      //locationsNodes,
      min,
      max,
      schemeTypesNodes,
      projectTypesNodes,
      durationPhrase,
      schemeStartGreaterThan,
      schemeStartLessThan,
      schemeEndGreaterThan,
      schemeEndLessThan,
      filterOrganisationsOptions,
      loading,
      showAllLanguageVersions,
      statusOpen: ref(true),
      statusFuture: ref(true),
      statusClosed: ref(false),
      nodes: computed(() => store),
      selectedGermanLocationsPaths,
      germanLocationTagsBulkList,
      computed: {
        ...mapState({
          filterActivities: (state) => state.advancedSearch.filterActivities,
        }),
      },
      methods: {
        updateFilter: function() {
          store.commit('advancedSearch/updateFilterActivities');
        },
        updateTreeData: function() {},
      },
      filterLocationsFn(val, update, abort) {
        if (val.length < 1) {
          abort();
          return;
        }
        update(() => {
          if (val === '') {
            filterLocationsTags.value = locationsOptions.value;
          } else {
            const needle = val.toLowerCase();

            filterLocationsTags.value = locationsOptions.value.filter(
              (v) => v.label.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterOrganisationsFn(val, update) {
        update(() => {
          if (val === '') {
            filterOrganisationsOptions.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();
            filterOrganisationsOptions.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterGermanLocationTagsBulk(val, update) {
        if (!val || val == '' || val.length < 2) return [];
        update(async () => {
          if (!val || val == '' || val.length < 2) return [];
          else {
            //  await SchemesService.searchLocationCodesBulk(val);
            /* await */

            await SchemesService.searchLocationCodesBulk(val);
          }
        });
      },
    };
  },
};
</script>
