<template>
  <q-dialog v-model="showEditLinkDialog" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div>Edit link</div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none">
        <div class="row justify-around">
          <q-input
            dense
            v-model="editLinkUrl"
            label="Input url"
            :rules="requiredFieldRule"
            lazy-rules
          />
        </div>
        <div class="row justify-around">
          <q-input
            dense
            v-model="editLinkText"
            label="Input link text"
            :rules="requiredFieldRule"
            lazy-rules
          />
        </div>
         <q-select
            v-if="showCategories"
            outlined
            bg-color="white"
            v-model="editLinkDocumentType"
            :options="documentTypes"
            option-value="id"
            option-label="description"
            stack-label
            label="Document type"
            dense
            emit-value
            map-options
          />
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          color="primary"
          label="Save"
          @click="editLink(editLinkText, editLinkUrl, editLinkDocumentType)"
          :disable="editLinkUrl != '' ? null : true"
        />
        <q-btn
          color="primary"
          outline
          label="Cancel"
          @click="disableEditLink"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed } from 'vue';
import store from '../../store/index';
import EDocumentType from '../../enums/EDocumentType';

export default {
  name: 'EditLink',
  props: {
    showCategories: Boolean,
  },
  setup(props) {
    const showCategoriesOptions = ref(props.showCategories);

    const showEditLinkDialog = computed({
      get: () => store.state.scheme.showEditLink,
      set: (value) => store.commit('scheme/updateShowEditLink', value),
    });
    const documentTypes = computed({
      get: () => store.state.scheme.documentTypes,
    });
    
    const selectedLink = computed({
      get: () => store.state.scheme.selectedLink,
    });

    const editLinkText = computed({
      get: () => store.state.scheme.selectedLinkText,
      set: (value) => store.commit('scheme/updateSelectedLinkText', value),
    });

    const editLinkUrl = computed({
      get: () => store.state.scheme.selectedLinkUrl,
      set: (value) => store.commit('scheme/updateSelectedLinkUrl', value),
    });
    const editLinkDocumentType = computed({
      get: () => store.state.scheme.selectedLinkDocumentType,
      set: (value) => store.commit('scheme/updateSelectedLinkDocumentType', value),
    });

    const editLink = (linkText, linkUrl, linkDocumentType) => {
      if (linkText == '') linkText = linkUrl;

      selectedLink.value.text = linkText;
      selectedLink.value.url = linkUrl;
      selectedLink.value.documentType = linkDocumentType;
      selectedLink.value.documentTypeDescription = linkDocumentType && linkDocumentType !== EDocumentType.UsefulLinks ? documentTypes.value.find(({ id }) => id === linkDocumentType).description : '';

      disableEditLink();
    };

    const disableEditLink = () => {
      store.commit('scheme/setSelectedLink', null)
      showEditLinkDialog.value = false;
    };

    return {
      editLinkText,
      editLinkUrl,
      editLinkDocumentType,
      selectedLink,
      showEditLinkDialog,
      disableEditLink,
      showCategoriesOptions,
      editLink,
      documentTypes,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || 'Field is required!',
      ],
    }
  },
};
</script>
