const state = () => ({
  pageCount: 0,
  currentPage: 1,
  searchText: '',
  dateFrom: '',
  dateTo: '',
  selectedAudiences: '',
  selectedLocations: [],
  selectedGermanLocationsPaths: [],
  //This is just hidden for now, don't remove it
  //selectedIndustries: '',
  selectedPurposes: '',
  selectedSchemeTypes: '',
  selectedProjectTypes: '',
  selectedSchemes: [],
  selectedSchemesIds: '',
  schemesOptions: [],
  allSchemes: [],
});

const getters = {};

const actions = {};

const mutations = {
  updateSchemesForSelect(state, val) {
    state.allSchemes = val;
  },

  updateSchemesOptions(state, val) {
    state.schemesOptions = val;
  },
  updateSelectedSchemes(state, val) {
    state.selectedSchemes = val;

    // this.commit('newsSearch/updateSelectedSchemeIds');
  },

  // Call this before sending data
  updateSelectedSchemeIds(state) {
    state.selectedSchemesIds = '';
    var arr = [];
    for (let i = 0; i < state.selectedSchemes.length; i++) {
      arr.push(state.selectedSchemes[i].id);
    }

    state.selectedSchemesIds = arr.toString();
  },
  updateSearchText(state, val) {
    state.searchText = val;
  },

  updatePageCount(state, val) {
    state.pageCount = val;
  },

  updateCurrentPage(state, val) {
    state.currentPage = val;
  },

  updateDateFrom(state, val) {
    state.dateFrom = val;
  },

  updateDateTo(state, val) {
    state.dateTo = val;
  },
  updateSelectedAudiences(state, val) {
    state.selectedAudiences = '';
    if (val.length > 0) {
      for (let i = 0; i < val.length; i++) {
        if (i != val.length - 1) state.selectedAudiences += val[i] + ',';
        else state.selectedAudiences += val[i];
      }
    }
  },
  
  //change to update selected location for locations dropdown
  updateSelectedLocations(state, val) {
    state.selectedLocations = val;
    sessionStorage.setItem('newsSelectedLocations', JSON.stringify(val));
  },

  updateSelectedPurposes(state, val) {
    state.selectedPurposes = '';
    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedPurposes += val[i] + ',';
      else state.selectedPurposes += val[i];
    }
  },
  updateSelectedSchemeTypes(state, val) {
    state.selectedSchemeTypes = '';
    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedSchemeTypes += val[i] + ',';
      else state.selectedSchemeTypes += val[i];
    }
  },
  updateSelectedProjectTypes(state, val) {
    state.selectedProjectTypes = '';
    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedProjectTypes += val[i] + ',';
      else state.selectedProjectTypes += val[i];
    }
  },
  
  updateSelectedGermanLocationsPaths(state, val) {
    state.selectedGermanLocationsPaths = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
