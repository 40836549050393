<template>
  <q-dialog v-model="show" persistent class="modal-general add-org-dialog">
    <q-card style="min-width: 350px;">
      <q-form @submit="createNewOrganisation()">
        <q-card-section class="row">
          <q-icon name="las la-2x la-plus-square" class="icon-color-addorg" />
          <div class="add-org-heading bold-font">New organisation</div>
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pt-sm">
          <div class="add-org-box column">
            <div class="column">
              <div class="row justify-around q-pt-md">
                <q-input
                  v-model="newOrg.label"
                  label="Enter name"
                  :rules="requiredFieldRule"
                  style="width: 250px"
                  borderless
                  bg-color="white"
                  dense
                />
              </div>
              <div class="row justify-around">
                <q-input
                  v-model="newOrg.email"
                  label="Enter email"
                  style="width: 250px"
                  borderless
                  bg-color="white"
                  dense
                />
              </div>
              <div class="row justify-around q-pt-md">
                <q-input
                  v-model="newOrg.phone"
                  label="Enter phone"
                  style="width: 250px"
                  borderless
                  bg-color="white"
                  dense
                />
              </div>
              <div class="row justify-around q-pt-md">
                <q-input
                  v-model="newOrg.url"
                  label="Enter website"
                  style="width: 250px"
                  borderless
                  bg-color="white"
                  dense
                />
              </div>
              <div class="row justify-around q-pt-md">
                <q-input
                  v-model="newOrg.address.street"
                  placeholder="Street"
                  style="width: 250px"
                  borderless
                  bg-color="white"
                  dense
                />
              </div>
              <div class="row justify-around q-pt-md">
                <q-input
                  v-model="newOrg.address.city"
                  placeholder="City"
                  style="width: 250px"
                  borderless
                  bg-color="white"
                  dense
                />
              </div>
              <div class="row justify-around q-pa-md">
                <q-select
                  v-model="newOrg.address.country"
                  use-input
                  input-debounce="0"
                  :options="countryOptions"
                  @filter="filterFn"
                  style="width: 250px"
                  behavior="menu"
                  borderless
                  bg-color="white"
                  label="Country"
                  dense
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">
                        No results
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="row justify-around q-pa-md parentOrg-select">
                <q-select
                  v-model="newOrg.parentOrganisationId"
                  use-input
                  label="Select parent organisation"
                  :options="organisationOptions"
                  @filter="filterOrganisationsFn"
                  behavior="menu"
                  option-value="id"
                  option-label="name"
                  emit-value
                  map-options
                  style="width: 250px"
                  borderless
                  bg-color="white"
                  dense
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">
                        No results
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-separator />

        <q-card-actions align="around">
          <q-btn
            no-caps
            class="bold-font orange-btn-filled"
            label="Save"
            type="submit"
          />
          <q-btn
            no-caps
            label="Cancel"
            class="orange-btn bold-font"
            @click="disableCreateOrganisation()"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed, reactive, onMounted } from 'vue';
import store from '../../store/index.js';
import OrganisationsService from '../../services/organisations.service';
import CustomersService from '../../services/customers.service';

export default {
  props: {
    showCreate: Boolean,
  },
  setup(props) {
    const show = ref(props.showCreate);
    const disableCreateOrganisation = () => {
      store.commit('organisation/disableCreateOrganisation');
      OrganisationsService.getOrganisationsTree();
    };

    onMounted(() => {
      CustomersService.getAllCountries();
      OrganisationsService.getAllOrganisationsBasic();
    });

    const countries = computed({
      get: () => store.state.customers.countriesList,
    });

    const countryOptions = ref(countries.value);

    const newOrg = reactive({
      label: '',
      email: '',
      phone: '',
      url: '',
      address: {
        street: '',
        city: '',
        country: '',
      },
      parentOrganisationId: null,
    });

    const createNewOrganisation = async () => {
      await OrganisationsService.createNewOrganisation(newOrg);
      disableCreateOrganisation();
    };

    const allOrganisations = computed({
      get: () => store.state.organisation.organisationsListBasic,
    });

    const organisationOptions = ref(allOrganisations.value);

    return {
      show,
      disableCreateOrganisation,
      createNewOrganisation,
      newOrg,
      countries,
      countryOptions,
      allOrganisations,
      organisationOptions,
      filterFn(val, update) {
        update(() => {
          if (val === '') {
            countryOptions.value = countries.value;
          } else {
            const needle = val.toLowerCase();

            countryOptions.value = countries.value.filter(
              (v) => v.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterOrganisationsFn(val, update) {
        update(() => {
          if (val === '') {
            organisationOptions.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();
            organisationOptions.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      requiredFieldRule: [
        (val) => (val && val.length > 0) || 'Field is required!',
      ],
    };
  },
};
</script>
