import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";

import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat";
import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment.js";
import AutoformatPlugin from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
import AutolinkPlugin from "@ckeditor/ckeditor5-link/src/autolink.js";
import BlockQuotePlugin from "@ckeditor/ckeditor5-block-quote/src/blockquote.js";
import CKFinderUploadAdapterPlugin from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter.js";
import CloudServicesPlugin from "@ckeditor/ckeditor5-cloud-services/src/cloudservices.js";
import FontBackgroundColorPlugin from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js";
import FontColorPlugin from "@ckeditor/ckeditor5-font/src/fontcolor.js";
import FontSizePlugin from "@ckeditor/ckeditor5-font/src/fontsize.js";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading.js";

// import Image from '@ckeditor/ckeditor5-image/src/image';
// import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
// import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
// import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
// import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
// import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting';
// import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles';
// import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
// import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
// import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment.js';
// import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
// import AutolinkPlugin from '@ckeditor/ckeditor5-link/src/autolink.js';
// import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
// import CKFinderUploadAdapterPlugin from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter.js';
// import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices.js';
// import FontBackgroundColorPlugin from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js';
// import FontColorPlugin from '@ckeditor/ckeditor5-font/src/fontcolor.js';
// import FontSizePlugin from '@ckeditor/ckeditor5-font/src/fontsize.js';
// import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading.js';

import IndentPlugin from "@ckeditor/ckeditor5-indent/src/indent.js";
import ListPlugin from "@ckeditor/ckeditor5-list/src/list.js";
import MediaEmbedPlugin from "@ckeditor/ckeditor5-media-embed/src/mediaembed.js";
import MentionPlugin from "@ckeditor/ckeditor5-mention/src/mention.js";
import PasteFromOfficePlugin from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import TablePlugin from "@ckeditor/ckeditor5-table/src/table.js";
import TableToolbarPlugin from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
import TextTransformationPlugin from "@ckeditor/ckeditor5-typing/src/texttransformation.js";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
export default {
  help: {
    editor: ClassicEditor,
    editorConfig: {
      // The configuration of the editor.
      plugins: [
        EssentialsPlugin,
        BoldPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        Base64UploadAdapter,

        // Image,
        // ImageCaption,
        // ImageStyle,
        // ImageToolbar,
        // ImageUpload,
        // ImageResizeEditing,
        // ImageResizeHandles,
        HorizontalLine,
        AlignmentPlugin,
        AutoformatPlugin,
        AutolinkPlugin,
        BlockQuotePlugin,
        CKFinderUploadAdapterPlugin,
        CloudServicesPlugin,
        FontBackgroundColorPlugin,
        FontColorPlugin,
        FontSizePlugin,
        HeadingPlugin,

        // ImagePlugin,
        // ImageCaptionPlugin,
        // ImageStylePlugin,
        // ImageToolbarPlugin,
        // ImageUploadPlugin,
        IndentPlugin,
        ListPlugin,
        MediaEmbedPlugin,
        MentionPlugin,
        PasteFromOfficePlugin,
        TablePlugin,
        TableToolbarPlugin,
        TableProperties,
        TableCellProperties,
        TextTransformationPlugin,
        RemoveFormat,
      ],

      toolbar: {
        items: [
          "removeFormat",
          "undo",
          "redo",
          "bold",
          "italic",
          "heading",
          "link",
          "horizontalLine",
          "|",
          "fontColor",
          "fontSize",
          "fontBackgroundColor",
          "alignment",
          "|",
          "numberedList",
          "bulletedList",
          "|",
          "outdent",
          "indent",
          "|",
          "blockQuote",
          "insertTable",
        ],
      },
      image: {
        toolbar: [
          {
            // Grouping the buttons for the icon-like image styling
            // into one drop-down.
            name: "imageStyle:icons",
            items: [
              "imageStyle:alignLeft",
              "imageStyle:alignRight",
              "imageStyle:block",
            ],
            defaultItem: "imageStyle:block",
          },
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableProperties",
          "tableCellProperties",
        ],
      },
      list: {
        properties: {
          styles: true,
          startIndex: true,
          reversed: true,
        },
      },
    },
  },
};
