const EEnvironmentType = Object.freeze({
    Default : 0,
    ISV : 10,
    Entrepeneur : 20,
    SKS : 30,
    DRV : 40,
    ISVNoUser : 50,
    EntrepeneurNoUser : 60,
    Test : 200,
    SAS :500,
    All : 999 
});

export default EEnvironmentType;