<template>
  <q-dialog v-model="small" persistent>
    <q-card style="width: 300px">
      <q-card-section>
        <div class="text-h6">Error</div>
      </q-card-section>

      <q-card-section
        class="q-pt-none"
      >
        {{ errorMessage }}
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat label="OK" @click="disableModal()" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed } from 'vue';
import store from '../../store/index.js';

export default {
  setup() {
    const errorMessage = computed({
      get: () => store.state.news.modalErrorMessage,
    });

    const isModalActive = computed({
      get: () => store.state.news.hasModalError,
    });

    const disableModal = () => {
      store.commit('news/disableModalError');
    };

    return {
      small: ref(isModalActive),
      errorMessage,
      isModalActive,
      disableModal,
    };
  },
};
</script>
