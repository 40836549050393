<template>
  <ErrorModal />
  <q-dialog v-model="show" persistent class="modal-general add-user-modal">
    <q-card style="min-width: 350px">
      <q-form @submit="createNewUser()">
        <q-card-section class="row">
          <q-icon name="las la-2x la-plus-square" class="icon-color-addorg" />

          <div class="add-user-heading bold-font">Add new user</div>
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pt-none">
          <div class="row justify-around q-pt-md">
            <q-input
              v-model="user.email"
              borderless
              bg-color="white"
              type="email"
              label="Email"
              :rules="[isValidEmail, requiredFieldRule]"
              dense
              style="width: 250px"
            />
          </div>

          <div class="row justify-around">
            <q-input
              dense
              v-model="user.person.firstName"
              label="Input first name"
              :rules="requiredFieldRule"
              borderless
              bg-color="white"
              style="width: 250px"
            />
          </div>
          <div class="row justify-around">
            <q-input
              dense
              v-model="user.person.lastName"
              label="Input last name"
              :rules="requiredFieldRule"
              borderless
              bg-color="white"
              style="width: 250px"
            />
          </div>
          <div class="row justify-around q-mb-md">
            <q-input
              dense
              v-model="user.person.department"
              label="Input department"
              borderless
              bg-color="white"
              style="width: 250px"
            />
          </div>
          <div class="row justify-around q-mb-md">
            <q-input
              dense
              v-model="user.person.mobileNumber"
              label="Input mobile number"
              borderless
              bg-color="white"
              style="width: 250px"
            />
          </div>

          <div class="row justify-around">
            <q-select
              borderless
              bg-color="white"
              v-model="user.person.customerId"
              option-label="name"
              option-value="value"
              emit-value
              map-options
              use-input
              input-debounce="0"
              label="Select customer"
              :options="customersDropdownOptions"
              @filter="customerDropdownFilterFn"
              style="width: 250px"
              behavior="menu"
              dense
              :rules="haveToSelectRule"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    No results
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="row justify-around">
            <q-select
              borderless
              bg-color="white"
              v-model="user.person.salutation"
              use-input
              input-debounce="0"
              label="Select salutation"
              :options="salutationOptions"
              @filter="salutationFilterFn"
              option-label="description"
              option-value="id"
              emit-value
              map-options
              style="width: 250px"
              behavior="menu"
              dense
              :rules="requiredFieldRuleSalutation"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    No results
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="row justify-around">
            <q-select
              v-if="isVindCustomer"
              borderless
              bg-color="white"
              v-model="user.person.language"
              label="Select language"
              :options="languageOptions"
              style="width: 250px"
              behavior="menu"
              dense
            >
            </q-select>
          </div>
          <q-toggle
            class="q-ml-md"
            v-model="user.person.isReceivingSystemMails"
            label="Receiving e-mails"
            color="indigo-10"
          >
            <q-tooltip>
              These are the in-activity and onboarding emails.
            </q-tooltip>
          </q-toggle>
          <q-checkbox
            v-show="isSuperAdmin"
            class="q-ml-md"
            v-model="user.person.isAccountManager"
            label="Accountmanager"
            color="indigo-10"
          >
            <q-tooltip>
              This user is AccountManager.
            </q-tooltip>
          </q-checkbox>
          <!--     <div class="row justify-around">
          <q-select
            filled
            v-model="user.person.address.country"
            use-input
            input-debounce="0"
            label="Select country"
            :options="options"
            @filter="filterFn"
            style="width: 250px"
            behavior="menu"
            dense
            :rules="requiredFieldRule"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  No results
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div> -->
        </q-card-section>
        <q-separator />
        <q-card-actions align="around">
          <q-btn
            class="orange-btn-filled bold-font"
            no-caps
            label="Save"
            type="submit"
          />
          <q-btn
            class="orange-btn bold-font"
            no-caps
            label="Cancel"
            @click="disableAdd()"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted, computed, reactive } from 'vue';
import store from '../../store/index.js';
import { useRouter } from 'vue-router';
import ErrorModal from './ErrorModal';
import UserService from '../../services/user.service.js';

export default {
  props: {
    showAdd: Boolean,
  },
  components: {
    ErrorModal,
  },
  setup(props) {
    const router = useRouter();
    const show = ref(props.showAdd);
    const user = reactive({
      email: '',
      person: {
        firstName: '',
        lastName: '',
        salutation: '',
        customer: '',
        customerId: null,
        department: '',
        mobileNumber: '',
        isReceivingSystemMails: true,
        isAccountManager: false,
        language: '',
      },
    });

    const isVindCustomer = computed({
      get: () => store.state.auth.custid === 2,
    });

    const salutationOptions = ref(salutations);
    const customerOptions = ref(customers);
    const customersDropdownOptions = ref(customersDropdown);
    let loggedInUser = JSON.parse(localStorage.getItem('user'));

    const prefilledCustomer = computed({
      get: () => store.state.user.prefilledCustomerId,
    });

    onMounted(async () => {
      UserService.getCustomersDropdown();
      UserService.getAllSalutations();
      UserService.getLanguageCodes();
      if (prefilledCustomer.value.id > 0) {
        user.person.customerId = {
          name: prefilledCustomer.value.name,
          value: prefilledCustomer.value.id,
        };
      }
    });

    const disableAdd = () => {
      store.commit('user/disableAdd');
    };

    const createNewUser = async () => {
      if (prefilledCustomer.value != null && prefilledCustomer.value.id > 0) {
        user.person.customerId = prefilledCustomer.value.id;
      }
      await UserService.createUser(user);
      disableAdd();
    };

    const isSuperAdmin = computed({
      get: () => store.state.user.isSuperAdmin,
    });

    const salutations = computed({
      get: () => store.state.user.salutationsList,
    });

    const languageCodes = computed({
      get: () => store.state.user.languageCodesList,
    });
    const languageOptions = ref(languageCodes);

    const customers = computed({
      get: () => store.state.user.customersList,
    });

    const customersDropdown = computed({
      get: () => store.state.user.customersDropdownList,
    });

    return {
      isVindCustomer,
      prefilledCustomer,
      show,
      disableAdd,
      createNewUser,
      user,
      salutations,
      salutationOptions,
      languageCodes,
      languageOptions,
      customerOptions,
      router,
      customers,
      customersDropdown,
      customersDropdownOptions,
      isSuperAdmin,
      loggedInUser,
      salutationFilterFn(val, update) {
        if (val === '') {
          update(() => {
            salutationOptions.value = salutations.value;
          });
          return;
        }
        update(() => {
          const needle = val.toLowerCase();
          salutationOptions.value = salutations.value.filter(
            (v) => v.description.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      customerFilterFn(val, update) {
        if (val === '') {
          update(() => {
            customerOptions.value = customers.value;
          });
          return;
        }
        update(() => {
          const needle = val.toLowerCase();
          customerOptions.value = customers.value.filter(
            (v) => v.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      customerDropdownFilterFn(val, update) {
        if (val === '') {
          update(() => {
            customersDropdownOptions.value = customersDropdown.value;
          });
          return;
        }
        update(() => {
          customersDropdownOptions.value = customersDropdown.value.filter(
            (v) => v.name.toLowerCase().indexOf(val.toLowerCase()) > -1
          );
        });
      },

      haveToSelectRule: [(val) => !!val || 'Please select customer!'],
      requiredFieldRule: [
        (val) => (val && val.length > 0) || 'Field is required!',
      ],
      requiredFieldRuleSalutation: [
        (val) => {
          (val != null && val.value != null && val && val.value.length > 0) ||
            'Field is required';
        },
      ],
      isValidEmail(val) {
        const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || 'Invalid email';
      },
    };
  },
};
</script>
