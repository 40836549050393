const state = () => ({
  schemes: [],
  selectedScheme: [{}],
  shortIntro: '',
  visibleColumns: [
    'toggleHide',
    'name',
    'accronym',
    'identifier',
    'date',
    'actions',
  ],
});

const getters = {
  getAllScheme: (state) => {
    
    return state.schemes;
  },

  getNumberOfRecords: (state) => {
    return state.schemes.totalRecords;
  },
};

const actions = {};

const mutations = {
  updateGrants(state, schemesData) {
    state.schemes = schemesData.results;
  },

  updateVisibleColumns(state) {
    if (window.screen.availWidth > 750)
      state.visibleColumns = [
        'toggleHide',
        'name',
        'accronym',
        'identifier',
        'date',
        'actions',
      ];
    else if (window.screen.availWidth < 750 && window.screen.availWidth > 600)
      state.visibleColumns = [
        'toggleHide',
        'name',
        'identifier',
        'date',
        'actions',
      ];
    else state.visibleColumns = ['toggleHide', 'name', 'date', 'actions'];
  },

  updateSelectedGrant(state, val) {
    state.selectedScheme = val;
    state.shortIntro = val.introduction.substring(0, 850) + '...';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
