<template>
  <div class="scheme-details-page">
    <div class="row heading-scheme">
      <i
        class="las la-arrow-circle-left la-3x back-arrow-schemes"
        @click="router.go(-1)"
      >
        <q-tooltip>
          Go to previous page
        </q-tooltip>
      </i>
      <h4 class="schemename-heading">{{ selectedScheme.name }}</h4>
    </div>
    <div class="details-compone">
      <SchemeDetailsComponent :scheme="selectedScheme" />
    </div>
  </div>
</template>
<script>
import { onMounted, computed } from 'vue';
import store from '../store/index';
import SchemeDetailsComponent from '../components/schemes-components/SchemeDetailsComponent.vue';
import { useRoute } from 'vue-router';
import router from '../router/index';
import SchemeServices from '../services/schemes.services';

export default {
  name: 'SchemeDetails',
  components: {
    SchemeDetailsComponent,
  },
  setup() {
    const selectedNodeId = computed({
      get: () => store.state.scheme.selectedNode,
    });

    const route = useRoute();
    const schemeId = route.params.id;

    onMounted(() => {
      SchemeServices.getSchemeById(schemeId, true);
    });

    const selectedScheme = computed({
      get: () => store.state.scheme.selectedScheme,
    });

    return {
      selectedScheme,
      selectedNodeId,
      schemeId,
      router,
    };
  },
};
</script>
