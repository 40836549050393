<template>
  <div class="ictu-section">
    <div class="ictu-checkbox">
      <q-checkbox v-model="ICTUschemeState" label="ICTU regeling" />
    </div>
    <div class="row edit-age-inputs q-pt-md">
      <div class="col-6">
        <q-input
          v-model.number="schemeMinimalAgeState"
          type="number"
          outlined
          bg-color="white"
          min="0"
          label="Minimum age"
          stack-label
          dense
        />
      </div>
      <div class="col-6 q-pl-xs">
        <q-input
          v-model.number="schemeMaximalAgeState"
          type="number"
          outlined
          bg-color="white"
          min="0"
          label="Maximum age"
          stack-label
          dense
        />
      </div>
    </div>
    <div class="tagsarea-input tags-tabs q-pt-md">
      <q-card>
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab name="userstatus" label="Status client">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              userStatusCount
            }}</q-badge>
          </q-tab>
          <q-tab name="educationlevel" label="Level of education">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              educationLevelCount
            }}</q-badge>
          </q-tab>
        </q-tabs>
        
        <q-separator />

        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="userstatus">
            <div style="min-width: 250px;">
              <div
                class="sidebar-margin item-width"
                style="min-width: 280px; width: 27vw"
              >
                <div class="tree q-mt-lg">
                  <div class="row">
                    <span
                      class="filter-box-heading"
                      v-text="userStatusNodes.label"
                    ></span>
                    <q-space></q-space>
                    <i
                      class="las la-2x la-times-circle clear-icon on-right"
                      @click="resetSelectedUserStatus()"
                    >
                      <q-tooltip>
                        Clear selected tags
                      </q-tooltip>
                    </i>
                    <i
                      class="las la-2x la-check-square clear-icon on-right"
                      @click="selectAllUserStatus()"
                    >
                      <q-tooltip>
                        Select all tags
                      </q-tooltip>
                    </i>
                  </div>
                  <q-input
                    dense
                    outlined
                    v-model="filterUserStatus"
                    label="Search"
                    class="tree-searchbox"
                  >
                  </q-input>
                  <div class="test">
                    <q-scroll-area
                      :bar-style="barStyle"
                      :thumb-style="thumbStyle"
                      visible
                      class="tree-scroll-area"
                    >
                      <q-tree
                        ref="userStatusTree"
                        :filter="filterUserStatus"
                        :filter-method="myFilterMethod"
                        :nodes="userStatusNodes.options"
                        node-key="id"
                        tick-strategy="leaf"
                        v-model:ticked="tickedUserStatus"
                        v-model:expanded="expandedUserStatus"
                        v-model:selected="selectedUserStatus"
                        @update:ticked="
                          updateUserStatusPairs(tickedUserStatus)
                        "
                        class="default-tree"
                      />
                    </q-scroll-area>
                  </div>
                </div>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="educationlevel">
            <div style="min-width: 250px;">
              <div
                class="sidebar-margin item-width"
                style="min-width: 280px; width: 27vw"
              >
                <div class="tree q-mt-lg">
                  <div class="row">
                    <span
                      class="filter-box-heading"
                      v-text="educationLevelNodes.label"
                    ></span>
                    <q-space></q-space>
                    <i
                      class="las la-2x la-times-circle clear-icon on-right"
                      @click="resetSelectedEducationLevel()"
                    >
                      <q-tooltip>
                        Clear selected tags
                      </q-tooltip>
                    </i>
                    <i
                      class="las la-2x la-check-square clear-icon on-right"
                      @click="selectAllEducationLevel()"
                    >
                      <q-tooltip>
                        Select all tags
                      </q-tooltip>
                    </i>
                  </div>
                  <q-input
                    dense
                    outlined
                    v-model="filterEducationLevel"
                    label="Search"
                    class="tree-searchbox"
                  >
                  </q-input>
                  <div class="test">
                    <q-scroll-area
                      :bar-style="barStyle"
                      :thumb-style="thumbStyle"
                      visible
                      class="tree-scroll-area"
                    >
                      <q-tree
                        ref="educationLevelTree"
                        :filter="filterEducationLevel"
                        :filter-method="myFilterMethod"
                        :nodes="educationLevelNodes.options"
                        node-key="id"
                        tick-strategy="leaf"
                        v-model:ticked="tickedEducationLevel"
                        v-model:expanded="expandedEducationLevel"
                        v-model:selected="selectedEducationLevel"
                        @update:ticked="
                          updateEducationLevelPairs(tickedEducationLevel)
                        "
                        class="default-tree"
                      />
                    </q-scroll-area>
                  </div>
                </div>
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>
  </div>
</template>

<script>
import { useVModel } from '../../composables/useVModel.js'
import { ref, computed } from 'vue';
import store from '../../store/index';

export default {
  ICTUscheme: 'ICTUSection',
  emits: ['update:ICTUscheme'],
  props: {
    ICTUscheme: Boolean,
    schemeMinimalAge: Number,
    schemeMaximalAge: Number,
    userStatusPairs: Array,
    educationLevelPairs: Array,
  },
  
  setup(props) {
    const tab = ref('userstatus');
    const educationLevelTree = ref(null);
    const expandedEducationLevel = ref([]);
    const selectedEducationLevel = ref([]);
    const filterEducationLevel = ref('');
    const userStatusTree = ref(null);
    const expandedUserStatus = ref([]);
    const selectedUserStatus = ref([]);
    const filterUserStatus = ref('');

    const educationLevelNodes = computed({
      get: () => store.state.scheme.educationLevelTags,
    });
    const userStatusNodes = computed({
      get: () => store.state.scheme.userStatusTags,
    });

    const educationLevelCount = computed({
      get: () => store.state.scheme.schemeEducationLevelTagsCount,
    });
    const userStatusCount = computed({
      get: () => store.state.scheme.schemeUserStatusTagsCount,
    });

    const tickedEducationLevel = computed({
      get: () => store.state.scheme.selectedSchemeEducationLevelTagsIds,
      set: (val) => store.commit('scheme/setEducationLevelTagsIds', val)
    });

    const tickedUserStatus = computed({
      get: () => store.state.scheme.selectedSchemeUserStatusTagsIds,
      set: (val) => store.commit('scheme/setUserStatusTagsIds', val)
    });
 
    const educationLevelPairsState = ref(useVModel(props, 'educationLevelPairs'));
    const userStatusPairsState = ref(useVModel(props, 'userStatusPairs'));


    //add parent if all children are selected
    const updateEducationLevelPairs = (keys) => {
      educationLevelPairsState.value.length = 0;

      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          let node = educationLevelTree.value.getNodeByKey(keys[i]);
          educationLevelPairsState.value.push(node);
        }
        var toAdd = [];
        for (let i = 0; i < educationLevelNodes.value.options.length; i++) {
          if (
            educationLevelNodes.value.options[i].children &&
            educationLevelNodes.value.options[i].children.length > 0
          ) {
            var hasAllChildrenTicked = true;

            for (
              let j = 0;
              j < educationLevelNodes.value.options[i].children.length;
              j++
            ) {
              // about subchild
              if (
                educationLevelNodes.value.options[i].children[j].children &&
                educationLevelNodes.value.options[i].children[j].children
                  .length > 0
              ) {
                var hasAllSubChildrenTicked = true;

                for (
                  let k = 0;
                  k <
                  educationLevelNodes.value.options[i].children[j].children
                    .length;
                  k++
                ) {
                  if (
                    !keys.includes(
                      educationLevelNodes.value.options[i].children[j].children[
                        k
                      ].id
                    )
                  ) {
                    hasAllSubChildrenTicked = false;
                    break;
                  }
                }
                if (
                  hasAllSubChildrenTicked &&
                  !educationLevelPairsState.value.includes(
                    educationLevelNodes.value.options[i].children[j]
                  )
                ) {
                  toAdd.push(educationLevelNodes.value.options[i].children[j]);
                }
                if (
                  !hasAllSubChildrenTicked &&
                  educationLevelPairsState.value.includes(
                    educationLevelNodes.value.options[i].children[j]
                  )
                ) {
                  const index = educationLevelPairsState.value.indexOf(
                    educationLevelNodes.value.options[i].children[j]
                  );
                  educationLevelPairsState.value.splice(index, 1);
                  hasAllChildrenTicked = false;
                }
              } else {
                hasAllSubChildrenTicked = false;
              }
              //subchild end

              if (
                !keys.includes(
                  educationLevelNodes.value.options[i].children[j].id
                )
              ) {
                if (
                  !toAdd.includes(
                    educationLevelNodes.value.options[i].children[j]
                  )
                ) {
                  hasAllChildrenTicked = false;
                  continue;
                }
              }
            }
            if (
              hasAllChildrenTicked &&
              !educationLevelPairsState.value.includes(
                educationLevelNodes.value.options[i]
              )
            )
              toAdd.push(educationLevelNodes.value.options[i]);

            if (
              !hasAllChildrenTicked &&
              educationLevelPairsState.value.includes(
                educationLevelNodes.value.options[i]
              )
            ) {
              const index = educationLevelPairsState.value.indexOf(
                educationLevelNodes.value.options[i]
              );
              educationLevelPairsState.value.splice(index, 1);
            }
          } else {
            hasAllChildrenTicked = false;
          }
        }
        if (toAdd.length > 0) {
          for (let i = 0; i < toAdd.length; i++)
            educationLevelPairsState.value.push(toAdd[i]);
        }
      }
      store.commit(
        'scheme/updateEducationLevelCount',
        educationLevelPairsState.value.length
      );
    };
    
    const selectAllEducationLevel = () => {
      tickedEducationLevel.value = [];

      var toTick = [];
      for (let i = 0; i < educationLevelNodes.value.options.length; i++) {
        toTick.push(educationLevelNodes.value.options[i].id);

        if (
          educationLevelNodes.value.options[i].children &&
          educationLevelNodes.value.options[i].children.length > 0
        ) {
          for (
            let j = 0;
            j < educationLevelNodes.value.options[i].children.length;
            j++
          ) {
            toTick.push(educationLevelNodes.value.options[i].children[j].id);

            if (
              educationLevelNodes.value.options[i].children[j].children &&
              educationLevelNodes.value.options[i].children[j].children.length >
                0
            ) {
              for (
                let k = 0;
                k <
                educationLevelNodes.value.options[i].children[j].children
                  .length;
                k++
              ) {
                toTick.push(
                  educationLevelNodes.value.options[i].children[j].children[k]
                    .id
                );
              }
            }
          }
        }
      }

      if (toTick.length > 0) {
        for (let i = 0; i < toTick.length; i++)
          tickedEducationLevel.value.push(toTick[i]);
      }

      updateEducationLevelPairs(tickedEducationLevel.value);
    };

    const resetSelectedEducationLevel = () => {
      tickedEducationLevel.value = [];
      updateEducationLevelPairs(tickedEducationLevel.value);
    };


    //add parent if all children are selected
    const updateUserStatusPairs = (keys) => {
      userStatusPairsState.value.length = 0;
      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          let node = userStatusTree.value.getNodeByKey(keys[i]);
          userStatusPairsState.value.push(node);
        }
        var toAdd = [];
        for (let i = 0; i < userStatusNodes.value.options.length; i++) {
          if (
            userStatusNodes.value.options[i].children &&
            userStatusNodes.value.options[i].children.length > 0
          ) {
            var hasAllChildrenTicked = true;

            for (
              let j = 0;
              j < userStatusNodes.value.options[i].children.length;
              j++
            ) {
              // about subchild
              if (
                userStatusNodes.value.options[i].children[j].children &&
                userStatusNodes.value.options[i].children[j].children.length > 0
              ) {
                var hasAllSubChildrenTicked = true;

                for (
                  let k = 0;
                  k <
                  userStatusNodes.value.options[i].children[j].children.length;
                  k++
                ) {
                  if (
                    !keys.includes(
                      userStatusNodes.value.options[i].children[j].children[k]
                        .id
                    )
                  ) {
                    hasAllSubChildrenTicked = false;
                    break;
                  }
                }
                if (
                  hasAllSubChildrenTicked &&
                  !userStatusPairsState.value.includes(
                    userStatusNodes.value.options[i].children[j]
                  )
                ) {
                  toAdd.push(userStatusNodes.value.options[i].children[j]);
                }
                if (
                  !hasAllSubChildrenTicked &&
                  userStatusPairsState.value.includes(
                    userStatusNodes.value.options[i].children[j]
                  )
                ) {
                  const index = userStatusPairsState.value.indexOf(
                    userStatusNodes.value.options[i].children[j]
                  );
                  userStatusPairsState.value.splice(index, 1);
                  hasAllChildrenTicked = false;
                }
              } else {
                hasAllSubChildrenTicked = false;
              }
              //subchild end
              if (
                !keys.includes(userStatusNodes.value.options[i].children[j].id)
              ) {
                if (
                  !toAdd.includes(userStatusNodes.value.options[i].children[j])
                ) {
                  hasAllChildrenTicked = false;
                  continue;
                }
              }
            }

            if (
              hasAllChildrenTicked &&
              !userStatusPairsState.value.includes(userStatusNodes.value.options[i])
            )
              toAdd.push(userStatusNodes.value.options[i]);

            if (
              !hasAllChildrenTicked &&
              userStatusPairsState.value.includes(userStatusNodes.value.options[i])
            ) {
              const index = userStatusPairsState.value.indexOf(
                userStatusNodes.value.options[i]
              );
              userStatusPairsState.value.splice(index, 1);
            }
          } else {
            hasAllChildrenTicked = false;
          }
        }
        if (toAdd.length > 0) {
          for (let i = 0; i < toAdd.length; i++)
            userStatusPairsState.value.push(toAdd[i]);
        }
      }
      store.commit(
        'scheme/updateUserStatusCount',
        userStatusPairsState.value.length
      );
    };
    
    const selectAllUserStatus = () => {
      tickedUserStatus.value = [];

      var toTick = [];
      for (let i = 0; i < userStatusNodes.value.options.length; i++) {
        toTick.push(userStatusNodes.value.options[i].id);

        if (
          userStatusNodes.value.options[i].children &&
          userStatusNodes.value.options[i].children.length > 0
        ) {
          for (
            let j = 0;
            j < userStatusNodes.value.options[i].children.length;
            j++
          ) {
            toTick.push(userStatusNodes.value.options[i].children[j].id);

            if (
              userStatusNodes.value.options[i].children[j].children &&
              userStatusNodes.value.options[i].children[j].children.length > 0
            ) {
              for (
                let k = 0;
                k <
                userStatusNodes.value.options[i].children[j].children.length;
                k++
              ) {
                toTick.push(
                  userStatusNodes.value.options[i].children[j].children[k].id
                );
              }
            }
          }
        }
      }

      if (toTick.length > 0) {
        for (let i = 0; i < toTick.length; i++)
          tickedUserStatus.value.push(toTick[i]);
      }

      updateUserStatusPairs(tickedUserStatus.value);
    };
    
    const resetSelectedUserStatus = () => {
      tickedUserStatus.value = [];
      updateUserStatusPairs(tickedUserStatus.value);
    };

    return {
      ICTUschemeState: useVModel(props, 'ICTUscheme'),
      schemeMinimalAgeState: useVModel(props, 'schemeMinimalAge'),
      schemeMaximalAgeState: useVModel(props, 'schemeMaximalAge'),
      tab,
      userStatusCount,
      educationLevelCount,
      selectedEducationLevel,
      expandedEducationLevel,
      educationLevelPairsState,
      updateEducationLevelPairs,
      educationLevelTree,
      educationLevelNodes,
      selectAllEducationLevel,
      resetSelectedEducationLevel,
      tickedEducationLevel,
      filterEducationLevel,
      selectedUserStatus,
      expandedUserStatus,
      userStatusPairsState,
      updateUserStatusPairs,
      userStatusTree,
      userStatusNodes,
      selectAllUserStatus,
      resetSelectedUserStatus,
      tickedUserStatus,
      filterUserStatus,

      myFilterMethod(node, filter) {
        const filt = filter.toLowerCase();
        return node.label && node.label.toLowerCase().indexOf(filt) > -1;
      },
    };
  },
};
</script>
