<script>
import { computed, onMounted } from 'vue';
import store from '../store/index.js';
import { useRouter } from 'vue-router'
import axios from 'axios';
import axiosUrl from '../services/axios.service';

const API_URL = axiosUrl.getAxiosUrl();

export default {
  name: 'OidcAuth',
  setup() {
    const router = useRouter();

    const isLoggedIn = computed({
      get: () => store.state.auth.isLoggedIn,
    });

    if (isLoggedIn.value == true) {
      router.push({
        name: 'TagTab',
        params: {
          tab: 'Audience'
        }
      });
    }
    const urlParams = new URLSearchParams(window.location.search);
    
    onMounted(async () => {
      axios
        .post(API_URL + 'users/authenticate-oidc', {
          username: urlParams.get('username'),
          code: urlParams.get('code'),
          sid: urlParams.get('sid')
        })
        .then(response => {
          if (response.data.token) {
            localStorage.setItem('user', JSON.stringify(response.data));
            store.state.auth.isLoggedIn = true;
            store.state.auth.user = response.data;
          }

            router.push({
              name: 'TagTab',
              params: {
                tab: 'Audience'
              }
            });
        });
    });

    return {
      router,
      isLoggedIn,
    };
  },
};
</script>
