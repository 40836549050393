import store from "../index";

const state = () => ({
  selectedCustomers: [],
  totalActiveUsersCount: 0,
  usersWithVisitInLastMonthCount: 0,
  usersWithoutNewsletterCount: 0,
  usersWithoutProfileCount: 0,
  activeAccountsList: [],
  noNewsLetterAccountsList: [],
  noProfileAccountsList: [],
  usersWithVisitList: [],
  selectedCustomerOwner: null,
  selectedCustomerOwnerId: null,
  selectedYear: 2023,
  selectedMonths: [],
  selectedMonthsIds: [],
  schemeVisitsData: [],
  siteVisitsData: [],
  newsVisitsData: [],
  chartData: {},
  chartDataLabels: [],
  tableStats: [],
  selectedUsers: [],
  showModal: false,
  modalData: [],
  areBoxesLoading: true,
  isTableLoading: true,
  isFilterSelected: false,
  selectedMonthsIdsServer: [],
  yearFrom: 2023,
  yearTo: 2023,
  monthFrom: 0,
  monthTo: 0,
  monthFromId: 0,
  monthToId: 0,
  optionsYearsFrom: [],
  optionsYearsTo: [],
});

const getters = {};

const actions = {};

import moment from "moment";

const mutations = {
  updateYearsOptions(state) {
    state.optionsYearsFrom = [];
    state.optionsYearsTo = [];
    var minYear = 2017;
    var current = moment().year();
    state.yearFrom = current;
    state.yearTo = current;
    while (current >= minYear) {
      state.optionsYearsFrom.push(current);
      state.optionsYearsTo.push(current);
      current--;
    }
  },
  updateYearsToOptions(state) {
    state.yearFrom == moment().year()
      ? (state.optionsYearsTo = [state.yearFrom])
      : (state.optionsYearsTo = [state.yearFrom, state.yearFrom + 1]);
  },
  updateYearFrom(state, val) {
    state.yearFrom = val;
  },
  updateYearTo(state, val) {
    state.yearTo = val;
  },
  updateMonthFrom(state, val) {
    state.monthFrom = val.name;
    state.monthFromId = val.id + 1;
  },
  updateMonthTo(state, val) {
    state.monthTo = val.name;
    state.monthToId = val.id + 1;
  },
  updateIsFilterSelected(state) {
    if (
      state.selectedCustomerOwnerId != null ||
      state.selectedCustomers.length > 0 ||
      state.selectedUsers.length > 0
    )
      state.isFilterSelected = true;
    else state.isFilterSelected = false;
  },
  resetFilters(state) {
    state.selectedCustomers = [];
    state.selectedUsers = [];
    state.selectedCustomerOwner = null;
    state.selectedCustomerOwnerId = null;
  },
  updateModalData(state, val) {
    state.modalData = [];
    for (let i = 0; i < val.length; i++) state.modalData.push(val[i]);
  },
  updateShowModal(state, val) {
    state.showModal = val;
  },
  updateTableStats(state, val) {
    state.tableStats = [];
    for (let i = 0; i < val.length; i++) {
      state.tableStats.push(val[i]);
    }
    state.isTableLoading = false;
  },
  updateSelectedCustomers(state, val) {
    state.selectedCustomers = val;
  },

  updateSelectedUsers(state, val) {
    state.selectedUsers = val;
  },

  updateUserStatsBoxes(state, val) {
    store.commit("userStats/resetStatesForBoxes");
    state.totalActiveUsersCount = val.totalActiveUsersCount;
    state.usersWithVisitInLastMonthCount = val.usersWithVisitInLastMonthCount;
    state.usersWithoutNewsletterCount = val.usersWithoutNewsletterCount;
    state.usersWithoutProfileCount = val.usersWithoutProfileCount;
    state.activeAccountsList = [];
    state.usersWithVisitList = [];
    state.noNewsLetterAccountsList = [];
    state.noProfileAccountsList = [];

    if (val.activeAccountsList && val.activeAccountsList.length > 0)
      for (const el of val.activeAccountsList) {
        state.activeAccountsList.push(el);
      }

    if (val.noNewsLetterAccountsList && val.noNewsLetterAccountsList.length > 0)
      for (const el of val.noNewsLetterAccountsList) {
        state.noNewsLetterAccountsList.push(el);
      }

    if (val.noProfileAccountsList && val.noProfileAccountsList.length > 0)
      for (const el of val.noProfileAccountsList) {
        state.noProfileAccountsList.push(el);
      }

    if (val.usersWithVisitList && val.usersWithVisitList.length > 0)
      for (const el of val.usersWithVisitList) {
        state.usersWithVisitList.push(el);
      }

    state.areBoxesLoading = false;
  },

  resetStatesForBoxes(state) {
    state.totalActiveUsersCount = 0;
    state.usersWithVisitInLastMonthCount = 0;
    state.usersWithoutNewsletterCount = 0;
    state.usersWithoutProfileCount = 0;
    state.activeAccountsList = [];
    state.noNewsLetterAccountsList = [];
    state.noProfileAccountsList = [];
  },

  updateSelectedCustomerOwner(state, val) {
    if (val && val != null) {
      state.selectedCustomerOwner = val;
      state.selectedCustomerOwnerId = val.id;
    } else {
      state.selectedCustomerOwner = null;
      state.selectedCustomerOwnerId = null;
    }
  },

  updateSelectedYear(state, val) {
    state.selectedYear = val;
  },

  updateSelectedMonths(state, val) {
    state.selectedMonths = [];
    state.chartDataLabels = [];
    state.selectedMonthsIds = [];
    state.selectedMonthsIdsServer = [];
    for (let i = 0; i < val.length; i++) {
      state.selectedMonths.push(val[i]);
      state.selectedMonthsIds.push(val[i].id);
      state.selectedMonthsIdsServer.push(val[i].id + 1);
    }
  },

  updateChartDataBasic(state, data) {
    state.siteVisitsData = [];
    store.commit("userStats/updateDataSets", data);
    state.chartDataLabels = [];
    state.chartData = {
      labels: state.chartDataLabels,
      datasets: [],
    };

    if (data.length > 0)
      for (let i = 0; i < data[0].siteVisitsPerUser.length; i++) {
        state.chartDataLabels.push(
          data[0].siteVisitsPerUser[i].monthName +
            " " +
            data[0].siteVisitsPerUser[i].year
        );
      }

    for (let i = 0; i < data.length; i++) {
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);
      var countsData = [];
      for (let j = 0; j < data[i].siteVisitsPerUser.length; j++) {
        countsData.push(data[i].siteVisitsPerUser[j].count);
      }

      state.chartData.datasets.push({
        label: data[i].username,
        data: countsData,
        borderColor: "#" + randomColor,
        color: "#" + randomColor,
        backgroundColor: "#" + randomColor,
      });
      countsData = [];
    }
  },

  updateChartDataSchemeVisits(state, val) {
    state.chartDataLabels = [];
    if (val.length > 0)
      for (let i = 0; i < val[0].userVisitsSchemes.length; i++) {
        state.chartDataLabels.push(
          val[0].userVisitsSchemes[i].monthName +
            " " +
            val[0].userVisitsSchemes[i].year
        );
      }
    state.chartData = {
      labels: state.chartDataLabels,
      datasets: [],
    };

    for (let i = 0; i < val.length; i++) {
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);
      var countsData = [];
      for (let j = 0; j < val[i].userVisitsSchemes.length; j++) {
        countsData.push(val[i].userVisitsSchemes[j].count);
      }

      state.chartData.datasets.push({
        label: val[i].username,
        data: countsData,
        borderColor: "#" + randomColor,
        color: "#" + randomColor,
        backgroundColor: "#" + randomColor,
      });
      countsData = [];
    }
  },

  updateChartDataNewsVisits(state, val) {
    state.chartDataLabels = [];
    if (val.length > 0)
      for (let i = 0; i < val[0].userVisitsNews.length; i++) {
        state.chartDataLabels.push(
          val[0].userVisitsNews[i].monthName +
            " " +
            val[0].userVisitsNews[i].year
        );
      }
    state.chartData = {
      labels: state.chartDataLabels,
      datasets: [],
    };

    for (let i = 0; i < val.length; i++) {
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);
      var countsData = [];
      for (let j = 0; j < val[i].userVisitsNews.length; j++) {
        countsData.push(val[i].userVisitsNews[j].count);
      }

      state.chartData.datasets.push({
        label: val[i].username,
        data: countsData,
        borderColor: "#" + randomColor,
        color: "#" + randomColor,
        backgroundColor: "#" + randomColor,
      });
      countsData = [];
    }
  },

  updateDataSets(state, data) {
    for (let i = 0; i < data.length; i++) {
      state.siteVisitsData.push(data[i].count);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
