import AuthService from '../../services/auth.service';

const localStorageUser = JSON.parse(localStorage.getItem('user'));

const state = () => ({
  isLoggedIn: localStorageUser ? true : false,
  user : localStorageUser ? localStorageUser : null,
  modalErrorMessage: '',
  hasModalError: false,
  custid: localStorageUser
    ? Number.parseInt(
        JSON.parse(Buffer.from(localStorageUser.token.split('.')[1], 'base64'))
          .custid
      )
    : 0,
});

const getters = {
  isLoggedIn: (state) => {
    return state.isLoggedIn;
  },
};

const actions = {
  login({ commit }, user) {
    return AuthService.login(user).then(
      user => {
        commit('loginSuccess', user);
        return Promise.resolve(user);
      },
      error => {
        commit('loginFailure');
        return Promise.reject(error);
      }
    );
  },
  logout({ commit }) {
    AuthService.logout();
    commit('logout');
  },
};

const mutations = {
  loginSuccess(state, user) {
    state.isLoggedIn = true;
    state.user = user;
  },
  loginFailure(state) {
    state.isLoggedIn = false;
    state.user = null;
  },
  logout(state) {
    state.isLoggedIn = false;
    state.user = null;
  },
  resetModalError(state) {
    state.modalErrorMessage = '';
  },

  enableModalError(state) {
    state.hasModalError = true;
  },
  disableModalError(state) {
    state.hasModalError = false;
  },
  updateModalError(state, message) {
    state.modalErrorMessage = message;
  },
  setCustid(state, val) {
    state.custid = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
