<template>
  <div class="nav-container">
    <div class="logo-header cursor-pointer"><a class="logo" href="/"></a></div>
    <q-badge v-if="url.indexOf('staging') > -1">Staging</q-badge>

    <div v-bind:class="{ show: isExpanded }">
      <div class="menu-buttons">
        <Slide right class="burger-menu">
          <div v-if="!isLoggedIn">
            <router-link :to="{ name: 'Login' }">
              <q-btn
                outline
                color="primary"
                label="Login"
                class="burgermenu-button full-width"
              >
              </q-btn>
            </router-link>
          </div>

          <router-link
            :to="{ name: 'TagTab', params: { tab: 'Audience' } }"
            v-if="isLoggedIn"
          >
            <q-btn
              outline
              color="primary"
              label="Tags"
              class="burgermenu-button full-width"
            >
            </q-btn>
          </router-link>

          <router-link :to="{ name: 'SchemesSearchPage' }" v-if="isLoggedIn">
            <q-btn
              outline
              color="primary"
              label="Schemes"
              class="burgermenu-button"
            >
            </q-btn>
          </router-link>

          <router-link :to="{ name: 'News' }" v-if="isLoggedIn">
            <q-btn
              outline
              color="primary"
              label="News"
              class="burgermenu-button"
            >
            </q-btn>
          </router-link>

          <router-link :to="{ name: 'Organisations' }" v-if="isLoggedIn">
            <q-btn
              outline
              color="primary"
              label="Organisations"
              class="burgermenu-button"
            >
            </q-btn>
          </router-link>

          <router-link
            :to="{ name: 'News' }"
            class="navmenu-link"
            v-if="isLoggedIn"
          >
            <q-btn outline color="primary" label="News"> </q-btn>
          </router-link>
          <router-link :to="{ name: 'CustomersGrid' }" v-if="isLoggedIn">
            <q-btn
              outline
              color="primary"
              label="Customers"
              class="burgermenu-button"
            >
            </q-btn>
          </router-link>
          <router-link :to="{ name: 'UsersGrid' }" v-if="isLoggedIn">
            <q-btn
              outline
              color="primary"
              label="Users"
              class="burgermenu-button"
            >
            </q-btn>
          </router-link>

          <router-link :to="{ name: 'AccountManagersGrid' }" v-if="isLoggedIn">
            <q-btn
              outline
              color="primary"
              label="Account managers"
              class="burgermenu-button"
            >
            </q-btn>
          </router-link>

          <router-link :to="{ name: 'VisualPingLinks' }" v-if="isLoggedIn">
            <q-btn
              outline
              color="primary"
              label="Visualping links"
              class="burgermenu-button"
            >
            </q-btn>
          </router-link>
          <router-link :to="{ name: 'EditEmailTemplates' }" v-if="isLoggedIn && isSuperAdmin">
            <q-btn
              outline
              color="primary"
              label="Email templates"
              class="burgermenu-button"
            >
            </q-btn>
          </router-link>
          <router-link :to="{ name: 'Login' }" v-if="isLoggedIn">
            <q-btn
              outline
              color="primary"
              label="Logout"
              class="burgermenu-button full-width"
              @click="logout"
            >
            </q-btn>
          </router-link>
        </Slide>

        <div>
          <router-link :to="{ name: 'Login' }" class="navmenu-link">
            <q-btn
              label="Login"
              v-if="!isLoggedIn"
              flat
              color="white"
              no-caps
              class="navmenu-link q-ml-sm"
            >
            </q-btn>
          </router-link>
        </div>

        <router-link :to="{ name: 'GeneralStats' }" class="navmenu-link">
          <q-btn
            :class="isUnderlined('stats')"
            label="Stats"
            v-if="isLoggedIn"
            flat
            color="white"
            no-caps
            class="navmenu-link q-ml-sm"
          >
          </q-btn>
        </router-link>

        <router-link
          :to="{ name: 'TagTab', params: { tab: 'Audience' } }"
          class="navmenu-link"
        >
          <q-btn
            flat
            color="white"
            no-caps
            class="navmenu-link q-ml-sm"
            :class="isUnderlined('tags')"
            label="Tags"
            v-if="isLoggedIn"
          >
          </q-btn>
        </router-link>

        <router-link :to="{ name: 'SchemesSearchPage' }" class="navmenu-link">
          <q-btn
            flat
            color="white"
            no-caps
            class="navmenu-link q-ml-sm"
            :class="isUnderlined('schemes')"
            label="Schemes"
            v-if="isLoggedIn"
          >
          </q-btn>
        </router-link>

        <router-link :to="{ name: 'News' }" class="navmenu-link">
          <q-btn
            flat
            color="white"
            no-caps
            class="navmenu-link q-ml-sm"
            :class="isUnderlined('news')"
            label="News"
            v-if="isLoggedIn"
          >
          </q-btn>
        </router-link>

        <router-link :to="{ name: 'Organisations' }" class="navmenu-link">
          <q-btn
            flat
            color="white"
            no-caps
            class="navmenu-link q-ml-sm"
            :class="isUnderlined('organisations')"
            label="Organisations"
            v-if="isLoggedIn"
          >
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'CustomersGrid' }" class="navmenu-link">
          <q-btn
            flat
            color="white"
            no-caps
            class="navmenu-link q-ml-sm"
            :class="isUnderlined('customers')"
            label="Customers"
            v-if="isLoggedIn"
          >
          </q-btn>
        </router-link>

        <router-link :to="{ name: 'UsersGrid' }" class="navmenu-link">
          <q-btn
            flat
            color="white"
            no-caps
            class="navmenu-link q-ml-sm"
            :class="isUnderlined('users')"
            label="Users"
            v-if="isLoggedIn"
          >
          </q-btn>
        </router-link>

        <router-link :to="{ name: 'AccountManagersGrid' }" class="navmenu-link">
          <q-btn
            flat
            color="white"
            no-caps
            class="navmenu-link q-ml-sm"
            :class="isUnderlined('account-managers')"
            label="Account managers"
            v-if="isLoggedIn"
          >
          </q-btn>
        </router-link>

        <router-link :to="{ name: 'VisualPingLinks' }" class="navmenu-link">
          <q-btn
            flat
            color="white"
            no-caps
            class="navmenu-link q-ml-sm"
            :class="isUnderlined('visualping-links')"
            label="Visualping links"
            v-if="isLoggedIn"
          >
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'EditEmailTemplates' }" class="navmenu-link">
          <q-btn
            flat
            color="white"
            no-caps
            class="navmenu-link q-ml-sm"
            :class="isUnderlined('edit-templates')"
            label="Email templates"
            v-if="isLoggedIn && isSuperAdmin"
          >
          </q-btn>
        </router-link>
        <q-btn
          flat
          color="white"
          no-caps
          class="navmenu-link q-ml-sm"
          :class="isUnderlined('logout')"
          label="Logout"
          v-if="isLoggedIn"
          @click="logout"
        >
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { Slide } from 'vue3-burger-menu';
import store from '../store/index.js';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'NavMenu',
  components: {
    Slide,
  },
  data() {
    const route = useRoute();
    const router = useRouter();

    return {
      isExpanded: false,
      url: window.location.href,
      route,
      router,
    };
  },
  methods: {
    collapse() {
      this.isExpanded = false;
    },

    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    updated() {},
    logout() {
      store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    isUnderlined(buttonRoute) {
      if (this.route.fullPath.includes(buttonRoute))
        return 'btn-nav-underlined';
      return '';
    },
  },
  computed: {
    isLoggedIn() {
      return store.state.auth.isLoggedIn;
    },
    isSuperAdmin(){
      return store.state.user.isSuperAdmin;
    }
  },
};
</script>
