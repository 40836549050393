<template>
  <div class="users-stats-table">
    <q-table
      class="my-sticky-header-table"
      :rows="tableStatsRows"
      :columns="columns"
      row-key="name"
      flat
      bordered
      :separator="'cell'"
      :pagination="pagination"
      style="max-heigt: 300px"
      virtual-scroll
      :virtual-scroll-sticky-size-start="30"
      :virtual-scroll-sticky-size-end="30"
      hide-pagination
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="username" :props="props" :class="props.row.isActive ? '' : 'line-red'">
            {{ props.row.username }}
          </q-td>
          <q-td key="newsletterFrequency" :props="props" :class="props.row.isActive ? '' : 'line-red'">
            {{ props.row.newsletterFrequency }}
          </q-td>
          <q-td key="isProfileSet" :props="props" :class="props.row.isActive ? '' : 'line-red'">
            <i v-if="props.row.isProfileSet ">Yes</i>
            <i v-else> No</i>
          </q-td>
          <q-td key="visitsInLast30Days" :props="props" :class="props.row.isActive ? '' : 'line-red'">
            {{ props.row.visitsInLast30Days }}
          </q-td>
          <q-td
            key="isActive"
            :props="props"
            :class="props.row.isActive  ? 'text-black' : 'text-red line-red'"  
          >
          <i v-if="props.row.isActive ">Yes</i>
            <i v-else> No</i>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import store from '../../../store/index';
import { computed } from 'vue';

export default {
  setup() {
    const tableStatsRows = computed({
      get: () => store.state.userStats.tableStats,
    });

    const columns = [
      {
        name: 'username',
        field: 'username',
        label: 'Username',
        align: 'left',
      },
      {
        name: 'newsletterFrequency',
        label: 'Newsletter frequency',
        field: 'newsletterFrequency',
        align: 'left',
      },
      {
        name: 'isProfileSet',
        label: 'Profile set',
        field: 'isProfileSet',
        align: 'left',
      },
      {
        name: 'visitsInLast30Days',
        label: 'Number of visits last 30 days',
        field: 'visitsInLast30Days',
        align: 'left',
      },
      {
        name: 'isActive',
        label: 'Is active',
        field: 'isActive',
        align: 'left',
      },
    ];
    return {
      columns,
      tableStatsRows,
      pagination: {
        rowsPerPage: 500,
        page: 1,
      },
    };
  },
};
</script>

<style lang="sass">
.my-sticky-header-table
  height: 400px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    background-color: #25408f

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  &.q-table--loading thead tr:last-child th
    top: 48px
</style>
