<template>
  <div class="customer-edit-page">
    <q-card>
      <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-tab name="general" label="General " />
        <q-tab
          v-if="customerEnvironment == environmentType.Default"
          name="content"
          label="Content"
          :class="getTabBasedOnStartDateClass()"
        />
        <q-tab name="sas" label="SAS" :class="getTabClassSasStartDate()" />
        <q-tab
          v-if="
            customerEnvironment == environmentType.ISV ||
              customerEnvironment == environmentType.ISVNoUser
          "
          name="isv"
          label="ISV"
          :class="getTabBasedOnStartDateClass()"
        />
        <q-tab
          v-if="
            customerEnvironment == environmentType.Entrepeneur ||
              customerEnvironment == environmentType.EntrepeneurNoUser
          "
          name="business"
          label="Business portal"
          :class="getTabBasedOnStartDateClass()"
        />
        <q-tab name="persons" label="Users" />

      </q-tabs>
      <q-separator />

      <q-tab-panels v-model="tab" animated>

        <!-- GENERAL -->
        <q-tab-panel name="general">
          <ErrorModal />
          <q-form @submit.stop.prevent="updateCustomer" class="q-gutter-md">
            <div class="row">
              <div class="col-md-4 q-pa-md">
                <q-input
                  filled
                  v-model="customerName"
                  label="Customer name *"
                  lazy-rules
                  :rules="requiredFieldRule"
                />
              </div>
              <div class="col-md-4 q-pa-md">
                <q-input
                  filled
                  v-model="customerEmailSuffix"
                  label="Customer email suffix *"
                  lazy-rules
                  :rules="[requiredFieldRule, suffixRule]"
                />
              </div>
              <div class="col-md-4 q-pa-md">
                <q-input
                  filled
                  v-model="customerCrmIdentifier"
                  label="Input CRM identifier"
                  :rules="[requiredFieldRule, crmRule]"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 q-pa-md">
                <q-select
                  filled
                  v-model="customerType"
                  label="Select type"
                  :options="customerTypes"
                  style="min-width: 300px"
                  behavior="menu"
                  :rules="[requiredSelect]"
                >
                </q-select>
              </div>
              <div class="col-md-4 q-pa-md">
                <q-select
                  filled
                  :disable="!hasAdminRole"
                  v-model="selectedCustomerEnvironment"
                  label="Customer portal type"
                  :options="environmentTypes"
                  option-value="id"
                  option-label="value"
                  :rules="[requiredSelect]"
                />
              </div>
              <div class="col-md-4 q-pa-md">
                <q-select
                  filled
                  v-model="customerAccountManager"
                  use-input
                  input-debounce="0"
                  label="Select account manager"
                  :options="accountManagerOptions"
                  style="min-width: 300px"
                  behavior="menu"
                  @filter="filterAccountManagersFn"
                  option-label="fullName"
                  :rules="[requiredSelect]"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">
                        No results
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-md-4 q-pa-md">
                <q-input
                  v-model="customerDebugEmail"
                  label="Debug email"
                  filled
                  style="min-width: 300px"
                />
              </div>
              <div class="col-md-4 q-pa-md">
                <q-select
                  filled
                  v-model="customerDeliveryMethod"
                  label="Select delivery method"
                  :options="deliveryMethodList"
                  style="min-width: 300px"
                  behavior="menu"
                  option-value="id"
                  option-label="value"
                  emit-value
                  map-options
                >
                </q-select>
              </div>
              <div
                class="col q-pa-md"
                style="min-width: 300px;"
                v-show="hasSuperAdminRole"
              >
                <q-select
                  filled
                  v-model="customerLanguageCode"
                  label="Language code"
                  :options="languageCodes"
                  style="min-width: 300px"
                  behavior="menu"
                  :rules="[requiredSelect]"
                  option-value="id"
                  option-label="description"
                  emit-value
                  map-options
                >
                </q-select>
              </div>
            </div>
            <div class="row">
              <div
                class="col q-pa-md"
                style="max-width: 350px;"
                v-show="hasSuperAdminRole"
              >
                <q-toggle
                  v-model="customerHas2FA"
                  label="Has Two-Factor Authentication (2FA)"
                  color="indigo-10"
                />
              </div>
              <div
                class="col q-pa-md"
                style="max-width: 350px;"
                v-show="hasSuperAdminRole"
              >
                <q-toggle v-model="useSSO" label="Uses SSO" color="indigo-10" />
              </div>
            </div>
            <q-separator />

            <span class="q-pa-md" style="color: #25408f; font-size: medium;">
              Tenants:
              <q-btn
                color="grey"
                round
                flat
                dense
                :icon="
                  expandedTenants ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
                "
                @click="expandedTenants = !expandedTenants"
              />
            </span>

            <div v-show="expandedTenants" class="row">
              <div class="col-md-7 q-pa-md">
                <q-table
                  class="sticky-header"
                  title="Users"
                  :rows="tenantList"
                  :columns="tenantColumns"
                  row-key="id"
                  flat
                  bordered
                >
                  <template v-slot:top>
                    <q-input
                      style="min-width: 450px; margin-right: 5px;"
                      filled
                      dense
                      label="Tenant id"
                      v-model="newCustomerTenantId"
                      lazy-rules
                    />
                    <q-btn
                      class="btn-vind-dark"
                      no-caps
                      label="Save"
                      icon="las la-plus-square"
                      @click="addTenant(newCustomerTenantId)"
                    />
                  </template>
                  <template v-slot:body="props">
                    <q-tr :props="props">
                      <q-td key="id" :props="props">{{ props.row }}</q-td>
                      <q-td key="actions" :props="props" class="q-gutter-xs">
                        <q-btn
                          color="indigo-10"
                          @click="
                            (confirmDeleteTenant = true), (tenantId = props.row)
                          "
                          size="sm"
                          no-caps
                        >
                          <i class="las la-2x la-trash la-fw"></i>
                          <q-tooltip>
                            Remove
                          </q-tooltip>
                        </q-btn>
                      </q-td>
                    </q-tr>
                  </template>
                </q-table>
              </div>
            </div>
            <div>
              <q-btn no-caps label="Save" type="submit" class="btn-vind-dark" />
              <q-btn
                no-caps
                label="Cancel"
                outline
                class="q-ml-sm btn-cancel-dark"
                @click="cancelUpdate"
              />
            </div>
            <q-banner v-if="showErrorBanner" class="text-white bg-red">
              Please fill in Start date or Sas start date!
            </q-banner>
          </q-form>
        </q-tab-panel>

        <!-- CONTENT -->
        <q-tab-panel name="content">
          <div class="row">
            <div class="col-md-4 q-pa-md" style="min-width: 300px;">
              <q-input filled v-model="customerStartDate" label="Start date">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="customerStartDate"
                        mask="DD-MM-YYYY"
                        :options="startDateOptionsFn"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="col-md-4 q-pa-md" style="min-width: 300px;">
              <q-input filled v-model="customerEndDate" label="End date">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="customerEndDate"
                        :options="optionsFn"
                        mask="DD-MM-YYYY"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="col-md-4 q-pa-md">
              <q-input
                filled
                v-model.number="customerNumberOfLicences"
                type="number"
                min="0"
                label="Input number of licences"
                :rules="licencesRules"
              />
            </div>
            <div class="col q-pa-md" style="max-width: 250px;">
              <q-toggle v-model="customerDemo" label="Demo" color="indigo-10" />
            </div>
            <div class="col-md-3 q-pa-md" style="max-width: 250px;">
              <q-toggle
                disable
                v-model="customerHasContent"
                label="Has content"
                color="indigo-10"
              />
            </div>
          </div>
          <q-separator />

          <span class="q-pa-md" style="color: #25408f; font-size: medium;">
            Tags:
            <q-btn
              color="grey"
              round
              flat
              dense
              :icon="expandedTags ? 'keyboard_arrow_down' : 'keyboard_arrow_right'"
              @click="expandedTags = !expandedTags"
            />
          </span>
          <div
            v-show="expandedTags"
            class="q-ml-md row justify-between tags-panel"
          >
            <TreeSelectTags
              :treeNodes="audienceNodes"
              :pairs="audiencePairs"
              :tagType="'Audience'"
              class="customer-tag-tree"
            />
            <TreeSelectTags
              :treeNodes="purposesNodes"
              :pairs="purposesPairs"
              :tagType="'Purposes'"
              class="customer-tag-tree"
            />
            <TreeSelectTags
              :treeNodes="locationsNodes"
              :pairs="locationsPairs"
              :tagType="'Locations'"
              class="customer-tag-tree q-mt-lg"
            />
          </div>
        </q-tab-panel>

        <!-- SAS -->
        <q-tab-panel name="sas">
          <div class="row">
            <div class="col-md-4 q-pa-md" style="min-width: 300px;">
              <q-input
                filled
                v-model="customerSasStartDate"
                label="Sas start date"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="customerSasStartDate"
                        mask="DD-MM-YYYY"
                        :options="sasStartDateOptionsFn"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="col-md-4 q-pa-md" style="min-width: 300px;">
              <q-input filled v-model="customerSasEndDate" label="Sas end date">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="customerSasEndDate"
                        :options="sasEndDateOptionsFn"
                        mask="DD-MM-YYYY"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 q-pa-md" style="max-width: 250px;">
              <q-toggle
                disable
                v-model="customerHasSas"
                label="Has sas"
                color="indigo-10"
              />
            </div>
          </div>
        </q-tab-panel>

        <!-- ISV -->
        <q-tab-panel name="isv">
          <div class="row">
            <div class="col-md-4 q-pa-md" style="min-width: 300px;">
              <q-input filled v-model="customerStartDate" label="Start date">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="customerStartDate"
                        mask="DD-MM-YYYY"
                        :options="startDateOptionsFn"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="col-md-4 q-pa-md" style="min-width: 300px;">
              <q-input filled v-model="customerEndDate" label="End date">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="customerEndDate"
                        :options="optionsFn"
                        mask="DD-MM-YYYY"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="col q-pa-md" style="max-width: 350px;">
              <q-toggle
                v-model="customerHasISV"
                label="Has ISV Portal"
                color="indigo-10"
                disable
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input filled v-model="facebookUrl" label="Facebook url" />
            </div>
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input filled v-model="twitterUrl" label="X url" />
            </div>
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input filled v-model="linkedinUrl" label="Linkedin url" />
            </div>
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input filled disable v-model="customerLogoPath" label="Logo url" />
            </div> 
          </div>
          <div class="row">
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input filled v-model="baseUrl" label="ISV url" />
            </div>
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input filled v-model="customerEmail" label="Email" />
            </div>
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input
                filled
                v-model="customerPhoneNumber"
                label="Phone number"
              />
            </div>
          </div>
          <div class="q-ml-md row">
            <TreeSelectTags
              :treeNodes="locationsNodes"
              :pairs="locationsPairs"
              :tagType="'Locations'"
              class="customer-tag-tree q-mt-lg"
            />
          </div>
        </q-tab-panel>

        <!-- Business portal -->
        <q-tab-panel name="business">
          <div class="row">
            <div class="col-md-4 q-pa-md" style="min-width: 300px;">
              <q-input filled v-model="customerStartDate" label="Start date">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="customerStartDate"
                        mask="DD-MM-YYYY"
                        :options="startDateOptionsFn"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="col-md-4 q-pa-md" style="min-width: 300px;">
              <q-input filled v-model="customerEndDate" label="End date">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="customerEndDate"
                        :options="optionsFn"
                        mask="DD-MM-YYYY"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <q-toggle
              v-model="customerHasBusiness"
              label="Has Business Portal"
              color="indigo-10"
              disable
            />
          </div>
          <div class="row">
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input filled v-model="facebookUrl" label="Facebook url" />
            </div>
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input filled v-model="twitterUrl" label="X url" />
            </div>
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input filled v-model="linkedinUrl" label="Linkedin url" />
            </div>
              <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input disable filled v-model="customerLogoPath" label="Logo url" />
            </div> 
          </div>
          <div class="row">
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input filled v-model="baseUrl" label="Business url" />
            </div>
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input filled v-model="customerEmail" label="Email" />
            </div>
            <div class="col-md-4 q-pa-md" style="max-width: 350px;">
              <q-input
                filled
                v-model="customerPhoneNumber"
                label="Phone number"
              />
            </div>
          </div>
          <div class="q-ml-md row">
            <TreeSelectTags
              :treeNodes="locationsNodes"
              :pairs="locationsPairs"
              :tagType="'Locations'"
              class="customer-tag-tree q-mt-lg"
            />
          </div>
        </q-tab-panel>

        <q-tab-panel name="persons">
          <div class="row justify-center">
            <!--    <q-list
              bordered
              separator
              class="rounded-borders"
              style="max-width: 350px"
            >
              <q-item v-for="person in customer.persons" :key="person">
                <q-item-section avatar top>
                  <q-avatar color="info" text-color="white">
                    {{ person.firstName.charAt(0) }}
                  </q-avatar>
                </q-item-section>
                <q-item-section>
                  <router-link
                    :to="{ name: 'EditUser', params: { id: person.userId } }"
                    class="edit-user-link"
                  >
                    <q-item-label lines="1">
                      {{ person.firstName }} {{ person.lastName }}
                    </q-item-label>
                  </router-link>
                  <q-checkbox
                    class="justify-center"
                    left-label
                    disable
                    v-model="person.isActive"
                    label="Is active:"
                    color="green"
                  />
                </q-item-section>
              </q-item>
            </q-list> -->
            <UsersTableForCustomers
              :id="customerId"
              :customerName="customerName"
              style="width: 95%"
            />
          </div>
        </q-tab-panel>
        
      </q-tab-panels>
    </q-card>
  </div>
  <q-dialog v-model="successfullyUpdated" persistent>
    <q-card style="width: 300px">
      <q-card-section class="row items-center">
        <span>Customer has been updated!</span>
      </q-card-section>
      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat label="OK" @click="disableDialog()" />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="confirmDeleteTenant" persistent class="modal-general">
    <q-card>
      <q-toolbar>
        <i style="color: #25408f;" class="las la-2x la-trash la-fw"></i>
        <q-toolbar-title
          ><span class="text-weight-bold" style="color: #25408f;"
            >Warning</span
          ></q-toolbar-title
        >
      </q-toolbar>
      <q-separator />
      <q-card-section class="row items-center">
        <span class="q-ml-sm">
          Deleting this Tenant ID is a permanent action and cannot be undone.
          <br />
          Please confirm if you want to proceed.</span
        >
      </q-card-section>
      <q-card-actions class="q-mb-md" align="right">
        <q-btn
          label="Confirm"
          no-caps
          class="orange-btn-filled bold-font"
          v-close-popup
          @click="deleteTenantId(tenantId)"
        />
        <q-btn
          class="orange-btn bold-font"
          no-caps
          label="Cancel"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, onMounted, computed, reactive } from "vue";
import store from "../../store/index";
import ErrorModal from "./ErrorModal";
import { useRouter } from "vue-router";
import CustomersService from "../../services/customers.service";
import { useRoute } from "vue-router";
import moment from "moment";
import AccountManagersService from "../../services/account.managers.service";
import TreeSelectTags from "../TreeSelectTags.vue";
import tagsService from "../../services/tags.service";
import UsersTableForCustomers from "../../components/customers-components/UsersTableForCustomers.vue";
import ETagType from "../../enums/ETagType";
import EEnvironmentType from "../../enums/EEnvironmentType";
export default {
  props: {
    id: Number,
  },
  name: "CustomersEditTabs",
  components: {
    ErrorModal,
    TreeSelectTags,
    UsersTableForCustomers,
  },
  setup(props) {
    const router = useRouter();
    const customerId = ref(props.id);
    const confirmDeleteTenant = ref(false);
    const tenantId = ref(null);
    const environmentType = reactive(EEnvironmentType);
    const successfullyUpdated = computed({
      get: () => store.state.customers.showSuccessfullyUpdatedDialog,
    });

    const countries = computed({
      get: () => store.state.customers.countriesList,
    });
    const options = ref(countries.value);
    const expandedTags = ref(false);
    const expandedTenants = ref(false);

    const route = useRoute();

    const showErrorBanner = ref(false);
    const tenantColumns = [
      {
        name: "id",
        align: "left",
        label: "Tenant id",
        field: (row) => row,
      },
      { name: "actions", align: "center", label: "Actions", field: "actions" },
    ];
    onMounted(async () => {
      await CustomersService.getCustomerById(route.params.id);
      await CustomersService.getLanguageCodes();
      CustomersService.getAllCountries();
      CustomersService.getCustomerTypesNew();
      CustomersService.getDeliveryMethods();
      AccountManagersService.getAccountManagersWithBasicInfo();
      CustomersService.getCustomerSelectedTags(
        route.params.id,
        "Audience",
        ETagType.Audience
      );
      CustomersService.getCustomerSelectedTags(
        route.params.id,
        "Purposes",
        ETagType.Purposes
      );
      CustomersService.getCustomerSelectedTags(
        route.params.id,
        "Locations",
        ETagType.Locations
      );
      await tagsService.getTagTypeTreeCustomers("Audience", ETagType.Audience);
      await tagsService.getTagTypeTreeCustomers("Purposes", ETagType.Purposes);
      await tagsService.getTagTypeTreeCustomers(
        "Locations",
        ETagType.Locations
      );
      await CustomersService.getEnvironmentTypes();
    });

    let user = JSON.parse(localStorage.getItem("user"));

    const hasSuperAdminRole =
      user && user.roles
        ? user.roles.some((element) => {
            if (element.name == "Super administrator") {
              return true;
            }

            return false;
          })
        : false;

    const hasAdminRole =
      user && user.roles
        ? user.roles.some((element) => {
            if (element.name == "Administrator") {
              return true;
            }

            return false;
          })
        : false;
    const customer = computed({
      get: () => store.state.customers.customerObject,
    });

    const environmentTypes = computed({
      get: () => store.state.enums.environmentTypes,
    });
    const deliveryMethodList = computed({
      get: () => store.state.customers.deliveryMethodsList,
    });
    const customerDeliveryMethod = computed({
      get: () => store.state.customers.customerObject.selectedDeliveryMethod,
      set: (value) => store.commit("customers/updateDeliveryMethod", value),
    });
    const customerDebugEmail = computed({
      get: () => store.state.customers.customerObject.debugEmail,
      set: (value) => store.commit("customers/updateCustomerDebugEmail", value),
    });
    const customerName = computed({
      get: () => store.state.customers.customerObject.name,
      set: (value) => store.commit("customers/updateCustomerName", value),
    });
    const tenantList = computed({
      get: () => store.state.customers.customerObject.tenantIdsList,
    });
    const customerDemo = computed({
      get: () => store.state.customers.customerObject.demo,
      set: (value) => store.commit("customers/updateCustomerDemo", value),
    });
    const customerIsFomoAccess = computed({
      get: () => store.state.customers.customerObject.isFomoAccess,
      set: (value) =>
        store.commit("customers/updateCustomerIsFomoAccess", value),
    });
    const isAdmin = computed({
      get: () => store.state.customers.customerObject.isAdmin,
      set: (value) => store.commit("customers/updateCustomerIsAdmin", value),
    });
    const customerHas2FA = computed({
      get: () => store.state.customers.customerObject.has2FA,
      set: (value) => store.commit("customers/updateCustomerHas2FA", value),
    });
    const useSSO = computed({
      get: () => store.state.customers.customerObject.useSSO,
      set: (value) => store.commit("customers/updateCustomerUseSSO", value),
    });
    const customerEmailSuffix = computed({
      get: () => store.state.customers.customerObject.emailSuffix,
      set: (value) =>
        store.commit("customers/updateCustomerEmailSuffix", value),
    });
    const customerEmail = computed({
      get: () => store.state.customers.customerObject.email,
      set: (value) => store.commit("customers/updateCustomerEmail", value),
    });
    const customerPhoneNumber = computed({
      get: () => store.state.customers.customerObject.phoneNumber,
      set: (value) =>
        store.commit("customers/updateCustomerPhoneNumber", value),
    });
    const customerHasContent = computed({
      get: () => store.state.customers.customerObject.hasContent,
    });
    const customerHasSas = computed({
      get: () => store.state.customers.customerObject.hasSas,
    });
    const customerEndDate = computed({
      get: () => store.state.customers.customerObject.endDate,
      set: (value) => store.commit("customers/updateCustomerEndDate", value),
    });
    const customerStartDate = computed({
      get: () => store.state.customers.customerObject.startDate,
      set: (value) => store.commit("customers/updateCustomerStartDate", value),
    });
    const customerSasEndDate = computed({
      get: () => store.state.customers.customerObject.sasEndDate,
      set: (value) => store.commit("customers/updateCustomerSasEndDate", value),
    });
    const customerSasStartDate = computed({
      get: () => store.state.customers.customerObject.sasStartDate,
      set: (value) =>
        store.commit("customers/updateCustomerSasStartDate", value),
    });

    const getTabColor = (startDateStr, endDateStr, format = "DD-MM-YYYY") => {
      const today = moment().startOf("day");
      const tomorrow = moment()
        .clone()
        .add(1, "days")
        .startOf("day");

      // If no start date, return grey
      if (!startDateStr) {
        return "bg-tab-grey";
      }

      // Parse the start date using the specified format
      const startDate = moment(startDateStr, format).startOf("day");

      // If startDate is tomorrow or later => red
      if (tomorrow.isSameOrBefore(startDate)) {
        return "bg-tab-red";
      }

      // If there's an end date, do further checks
      if (endDateStr) {
        const endDate = moment(endDateStr, format).startOf("day");

        // If the end date has already passed => red
        if (today.isAfter(endDate)) {
          return "bg-tab-red";
        }

        // If the end date is within the next two months => yellow
        const twoMonthsFromNow = moment()
          .clone()
          .add(2, "months")
          .startOf("day");
        if (endDate.isSameOrBefore(twoMonthsFromNow)) {
          return "bg-tab-yellow";
        }
      }

      // Otherwise => green
      return "bg-tab-green";
    };
    const getTabBasedOnStartDateClass = () => {
      return getTabColor(
        customerStartDate.value,
        customerEndDate.value,
        "DD-MM-YYYY"
      );
    };

    const getTabClassSasStartDate = () => {
      return getTabColor(
        customerSasStartDate.value,
        customerSasEndDate.value,
        "DD-MM-YYYY"
      );
    };

    const customerNumberOfLicences = computed({
      get: () => store.state.customers.customerObject.numberOfLicences,
      set: (value) =>
        store.commit("customers/updateCustomerNumberOfLicences", value),
    });

    const customerCrmIdentifier = computed({
      get: () => store.state.customers.customerObject.crmIdentifier,
      set: (value) =>
        store.commit("customers/updateCustomerCrmIdentifier", value),
    });

    const customerType = computed({
      get: () => store.state.customers.customerObject.customerType,
      set: (value) => store.commit("customers/updateCustomerType", value),
    });

    const customerLanguageCode = computed({
      get: () => store.state.customers.customerObject.languageCode,
      set: (value) =>
        store.commit("customers/updateCustomerLanguageCode", value),
    });

    const customerAccountManager = computed({
      get: () => store.state.customers.customerObject.accountManager,
      set: (value) =>
        store.commit("customers/updateCustomerAccountManager", value),
    });

    const customerTypes = computed({
      get: () => store.state.customers.customerTypesList,
    });

    const languageCodes = computed({
      get: () => store.state.customers.languageCodes,
    });
    const accountManagers = computed({
      get: () => store.state.accountManagers.accountManagersBasicInfoList,
    });

    const accountManagerOptions = ref(accountManagers.value);
    const audiencePairs = computed({
      get: () => store.state.customers.selectedCustomerAudienceTags,
      set: (val) =>
        store.commit("customers/setSelectedCustomerAudienceTags", val),
    });
    const audienceNodes = computed({
      get: () => store.state.customers.audienceTags,
    });

    const purposesPairs = computed({
      get: () => store.state.customers.selectedCustomerPurposesTags,
      set: (val) =>
        store.commit("customers/setSelectedCustomerPurposesTags", val),
    });
    const purposesNodes = computed({
      get: () => store.state.customers.purposesTags,
    });

    const locationsPairs = computed({
      get: () => store.state.customers.selectedCustomerLocationsTags,
      set: (val) =>
        store.commit("customers/setSelectedCustomerPurposesTags", val),
    });
    const locationsNodes = computed({
      get: () => store.state.customers.locationsTags,
    });

    const selectedCustomerEnvironment = computed({
      get: () => {
        const envId = store.state.customers.customerObject.environment;
        return (
          environmentTypes.value.find((env) => env.id === envId)?.value || null
        );
      },
      set: (value) =>
        store.commit("customers/setSelectedCustomerEnvironment", value),
    });
    const customerEnvironment = computed({
      get: () => {
        return store.state.customers.customerObject.environment;
      },
    });
    const customerHasBusiness = computed({
      get: () => {
        return (
          customerEnvironment.value == 20 || customerEnvironment.value == 60
        );
      },
    });
    const customerHasISV = computed({
      get: () => {
        return (
          customerEnvironment.value == 10 || customerEnvironment.value == 50
        );
      },
    });
    const facebookUrl = computed({
      get: () => store.state.customers.customerObject.facebookUrl,
      set: (val) => store.commit("customers/updateCustomerFacebookUrl", val),
    });
    const twitterUrl = computed({
      get: () => store.state.customers.customerObject.twitterUrl,
      set: (val) => store.commit("customers/updateCustomerTwitterUrl", val),
    });
    const linkedinUrl = computed({
      get: () => store.state.customers.customerObject.linkedinUrl,
      set: (val) => store.commit("customers/updateCustomerLinkedinUrl", val),
    });
    const customerLogoPath = computed({
      get: () => store.state.customers.customerObject.customerLogoPath,
      set: (val) => store.commit("customers/updateCustomerLogoPath", val),
    });
    const baseUrl = computed({
      get: () => store.state.customers.customerObject.baseUrl,
      set: (val) => store.commit("customers/updateCustomerBaseUrl", val),
    });

    const updatedCustomer = {
      id: customerId,
      name: customerName,
      demo: customerDemo,
      emailSuffix: customerEmailSuffix,
      endDate: customerEndDate,
      startDate: customerStartDate,
      sasStartDate: customerSasStartDate,
      sasEndDate: customerSasEndDate,
      numberOfLicences: customerNumberOfLicences,
      crmIdentifier: customerCrmIdentifier,
      customerType: customerType,
      accountManager: customerAccountManager,
      customerAudienceTags: audiencePairs,
      customerPurposesTags: purposesPairs,
      customerLocationsTags: locationsPairs,
      isFomoAccess: customerIsFomoAccess,
      has2FA: customerHas2FA,
      languageCode: customerLanguageCode,
      environment: customerEnvironment,
      linkedinUrl: linkedinUrl,
      twitterUrl: twitterUrl,
      facebookUrl: facebookUrl,
      baseUrl: baseUrl,
      customerLogoPath: customerLogoPath,
      useSSO: useSSO,
      emailAddress: customerEmail,
      phoneNumber: customerPhoneNumber,
      debugEmail: customerDebugEmail,
      deliveryMethod: customerDeliveryMethod,
    };

    const updateCustomer = async () => {
      if (
        !updatedCustomer.startDate.value &&
        !updatedCustomer.sasStartDate.value
      ) {
        showErrorBanner.value = true;
        return;
      } else {
        showErrorBanner.value = false;
        await CustomersService.updateCustomer(updatedCustomer);
      }
    };

    const cancelUpdate = () => {
      router.push({
        name: "CustomersGrid",
      });
    };

    const disableDialog = () => {
      store.commit("customers/disableSuccessfullyUpdatedDialog");
    };

    const newCustomerTenantId = ref("");

    const addTenant = async (newTenantId) => {
      await CustomersService.addCustomerTenant(newTenantId);
      newCustomerTenantId.value = "";
    };
    const deleteTenantId = async (tenantId) => {
      await CustomersService.deleteCustomerTenantId(tenantId);
      confirmDeleteTenant.value = false;
    };
    return {
      expandedTenants,
      expandedTags,
      getTabColor,
      getTabBasedOnStartDateClass,
      getTabClassSasStartDate,
      hasAdminRole,
      customerEmail,
      customerPhoneNumber,
      useSSO,
      customerLogoPath,
      baseUrl,
      facebookUrl,
      linkedinUrl,
      twitterUrl,
      customerHasISV,
      customerHasBusiness,
      customerEnvironment,
      selectedCustomerEnvironment,
      environmentType,
      environmentTypes,
      customerDebugEmail,
      tenantId,
      confirmDeleteTenant,
      newCustomerTenantId,
      tenantList,
      deleteTenantId,
      tenantColumns,
      addTenant,
      customerDeliveryMethod,
      deliveryMethodList,
      languageCodes,
      customerLanguageCode,
      showErrorBanner,
      user,
      hasSuperAdminRole,
      locationsPairs,
      locationsNodes,
      purposesPairs,
      purposesNodes,
      audienceNodes,
      audiencePairs,
      accountManagers,
      disableDialog,
      tab: ref("general"),
      customerId,
      customer,
      customerName,
      customerIsFomoAccess,
      customerDemo,
      customerEmailSuffix,
      customerHasContent,
      customerHasSas,
      customerHas2FA,
      customerSasStartDate,
      customerSasEndDate,
      customerEndDate,
      customerStartDate,
      customerCrmIdentifier,
      moment,
      isAdmin,
      updateCustomer,
      cancelUpdate,
      router,
      confirm,
      options,
      countries,
      customerNumberOfLicences,
      successfullyUpdated,
      customerTypes,
      customerType,
      accountManagerOptions,
      customerAccountManager,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || "Field is required!",
      ],
      licencesRules: [
        (val) =>
          (val !== null && val !== "") || "Please type number of licences!",
        (val) => val >= 0 || "Please type a real number of licences",
      ],
      optionsFn(date) {
        return (
          date >=
          moment(customerStartDate.value, "DD-MM-YYYY").format("YYYY/MM/DD")
        );
      },
      startDateOptionsFn(date) {
        return (
          date <=
          moment(customerEndDate.value, "DD-MM-YYYY").format("YYYY/MM/DD")
        );
      },
      sasEndDateOptionsFn(date) {
        return (
          date >=
          moment(customerSasStartDate.value, "DD-MM-YYYY").format("YYYY/MM/DD")
        );
      },
      sasStartDateOptionsFn(date) {
        return (
          date <=
          moment(customerSasEndDate.value, "DD-MM-YYYY").format("YYYY/MM/DD")
        );
      },
      suffixRule(val) {
        const hasAtChar = /^@/;
        return !hasAtChar.test(val) || "Please fill without @";
      },
      crmRule(val) {
        return val.length > 5 || " ID not valid. Please fill SF ID";
      },
      requiredSelect(val) {
        if (val == null) return "Field is required";
      },
      filterCountriesFn(val, update) {
        update(() => {
          if (val === "") {
            options.value = countries.value;
          } else {
            const needle = val.toLowerCase();

            options.value = countries.value.filter(
              (v) => v.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterAccountManagersFn(val, update) {
        update(() => {
          if (val === "") {
            accountManagerOptions.value = accountManagers.value;
          } else {
            const needle = val.toLowerCase();

            accountManagerOptions.value = accountManagers.value.filter(
              (v) => v.fullName.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
    };
  },
};
</script>
