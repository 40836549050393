const state = () => ({
  newsItemsSentCount: 0,
  receiversCount: 0,
  receivingAccountsCount: 0,
  consultantsCount: 0,
  tableData: [],
  isChartView: true,
  consultantsFilter: [],
  selectedConsultants: [],
  selectedConsultantIds: [],
  barLabelsWeeks: [],
  barData: {},
  departments: [],
  selectedTeam: '',
  onzeBarData: {},
  onzeBarLabelsWeeks: [],
  numberOfNewsItemsPrepared: 0,
  numberOfUniqueMessagesSent: 0,
  numberOfBounces: 0,
  numberOfContactsOBHasOpened: 0,
  numberOfContactsWhoClicked: 0,
  onzeBarCustomersReceivedData: {},
  onzeBarContactsReceivedData: {},
  onzeBarDataConsultantSelected: {},
  onzeActiveConsultantsList: [],
  onzeInactiveConsultantsList: [],
});

const getters = {};

const actions = {};

const mutations = {
  updateSelectedTeam(state, val) {
    state.selectedTeam = val;
  },
  updateDepartments(state, val) {
    state.departments = val;
  },
  updateOnzeBarData(state, val) {
    state.onzeBarData = {};
    state.onzeBarCustomersReceivedData = {};
    state.onzeBarDataConsultantSelected = {};
    state.onzeActiveConsultantsList = [];
    state.onzeInactiveConsultantsList = [];
    for (let i = 0; i < val.activeConsultants.length; i++) {
      state.onzeActiveConsultantsList.push({
        name: val.activeConsultants[i].name,
        department: val.activeConsultants[i].department,
      });
    }

    for (let i = 0; i < val.inactiveConsultants.length; i++) {
      state.onzeInactiveConsultantsList.push({
        name: val.inactiveConsultants[i].name,
        department: val.inactiveConsultants[i].department,
      });
    }

    var barDataLabels = [];
    var barDataNewsItemsSent = [];
    var barDataNewsLetterBounce = [];
    var barDataNewsLetterOpen = [];
    var barDataNewsLetterClick = [];
    var barDataNewsLetterClickUnique = [];
    var barDataContacts = [];
    var barDataCustomerReceivedCount = [];
    var barDataContactsReceivedCount = [];
    var barDataObSelectedCount = [];
    var barDataContactReceivedCount = [];
    var lastWeekData = val.weeks[val.weeks.length - 1];
    var barDataCustomers = [];
    var barNewsLettersSentCount = [];

    state.numberOfBounces = lastWeekData.newsLetterBounceCount;
    state.numberOfUniqueMessagesSent = lastWeekData.newsItemSentCount;
    state.numberOfContactsOBHasOpened = lastWeekData.newsLetterOpenCount;
    state.numberOfContactsWhoClicked = lastWeekData.newsLetterClickCount;

    if (val != null) {
      for (let i = 0; i < val.weeks.length; i++) {
        barDataLabels.push('Week ' + val.weeks[i].week);
        barDataNewsItemsSent.push(val.weeks[i].newsItemSentCount);
        barDataNewsLetterBounce.push(val.weeks[i].newsLetterBounceCount);
        barDataNewsLetterOpen.push(val.weeks[i].newsLetterOpenCount);
        barDataNewsLetterClick.push(val.weeks[i].newsLetterClickCount);
        barDataNewsLetterClickUnique.push(
          val.weeks[i].newsLetterClickUniqueCount
        );
        barDataContacts.push(val.weeks[i].contactCount);
        barDataCustomerReceivedCount.push(val.weeks[i].customerReceivedCount);
        barDataContactsReceivedCount.push(val.weeks[i].consultantCount);
        barDataObSelectedCount.push(val.weeks[i].consultantSelectedCount);
        barDataContactReceivedCount.push(val.weeks[i].contactedReceivedCount);
        barDataCustomers.push(val.weeks[i].customerCount);
        barNewsLettersSentCount.push(val.weeks[i].newsLettersSent);
      }

      state.onzeBarData = {
        type: 'bar',
        labels: barDataLabels,
        datasets: [
          {
            label: 'Newsletters sent',
            backgroundColor: '#2362a6',
            data: barNewsLettersSentCount,
            color: '#2362a6',
            borderColor: '#2362a6',
            type: 'bar',
          },
          {
            label: 'Newsletter bounce count',
            backgroundColor: '#0a108f',
            data: barDataNewsLetterBounce,
            color: '#0a108f',
            borderColor: '#0a108f',
            type: 'bar',
          },

          {
            label: 'Newsletter open count',
            backgroundColor: '#1299d7',
            data: barDataNewsLetterOpen,
            color: '#1299d7',
            borderColor: '#1299d7',
            type: 'bar',
          },
          {
            label: 'Newsletter click count',
            backgroundColor: '#503447',
            data: barDataNewsLetterClick,
            color: '#503447',
            borderColor: '#503447',
            type: 'bar',
          },
          {
            label: 'Newsletter unique click count',
            backgroundColor: '#311555',
            data: barDataNewsLetterClickUnique,
            color: '#311555',
            borderColor: '#311555',
            type: 'bar',
          },
          {
            label: 'Contact count',
            backgroundColor: '#FF5B33',
            data: barDataContacts,
            color: '#FF5B33',
            borderColor: '#FF5B33',
            type: 'line',
          },
        ],
      };

      state.onzeBarDataConsultantSelected = {
        type: 'bar',
        labels: barDataLabels,
        datasets: [
          {
            label: 'No. of people who selected at least 1 news item',
            backgroundColor: '#0a108f',
            data: barDataObSelectedCount,
            color: '#0a108f',
            borderColor: '#0a108f',
            type: 'bar',
          },

          {
            label: 'Consultants count',
            backgroundColor: '#FF5B33',
            data: barDataContactsReceivedCount,
            color: '#FF5B33',
            borderColor: '#FF5B33',
            type: 'line',
          },
        ],
      };

      state.onzeBarCustomersReceivedData = {
        type: 'bar',
        labels: barDataLabels,
        datasets: [
          {
            label: 'Customers received count',
            backgroundColor: '#0a108f',
            data: barDataCustomerReceivedCount,
            color: '#0a108f',
            borderColor: '#0a108f',
            type: 'bar',
          },

          {
            label: 'Customers count',
            backgroundColor: '#FF5B33',
            data: barDataCustomers,
            color: '#FF5B33',
            borderColor: '#FF5B33',
            type: 'line',
          },
        ],
      };
    }
  },

  updateBarData(state, val) {
    var barLabels = [];
    var barDataConsultants = [];
    var barDataAccounts = [];
    var barDataReceivers = [];
    var barDataNewsItems = [];

    if (val != null) {
      if (val.consultants != null)
        for (let i = 0; i < val.consultants.length; i++) {
          barDataConsultants.push(val.consultants[i].count);
          barLabels.push('Week ' + val.consultants[i].weekNumber);
        }
      if (val.uniqueAccounts != null)
        for (let i = 0; i < val.uniqueAccounts.length; i++) {
          barDataAccounts.push(val.uniqueAccounts[i].count);
        }
      if (val.uniqueReceivers != null)
        for (let i = 0; i < val.uniqueReceivers.length; i++) {
          barDataReceivers.push(val.uniqueReceivers[i].count);
        }
      if (val.newsItemsSent != null)
        for (let i = 0; i < val.newsItemsSent.length; i++) {
          barDataNewsItems.push(val.newsItemsSent[i].count);
        }
    }

    state.barData = {
      labels: barLabels,
      datasets: [
        {
          label: 'Number of consultants',
          backgroundColor: '#FF5B33',
          data: barDataConsultants,
          color: '#FF5B33',
          borderColor: '#FF5B33',
        },
        {
          label: 'Number of unique accounts',
          backgroundColor: '#0a108f',
          data: barDataAccounts,
          color: '#0a108f',
          borderColor: '#0a108f',
        },
        {
          label: 'Number of unique receivers',
          backgroundColor: '#1299d7',
          data: barDataReceivers,
          color: '#1299d7',
          borderColor: '#1299d7',
        },
        {
          label: 'Number of news items sent',
          backgroundColor: '#503447',
          data: barDataNewsItems,
          color: '#503447',
          borderColor: '#503447',
        },
      ],
    };
  },
  updateSelectedConsultant(state, val) {
    state.selectedConsultants = val;
    state.selectedConsultantIds = [];
    if (val != null)
      for (let i = 0; i < val.length; i++)
        state.selectedConsultantIds.push(val[i].id);
    else state.selectedConsultantIds = [];
  },
  updateConsultants(state, val) {
    state.consultantsFilter = val.consultants;
  },
  updateIsChartView(state, val) {
    state.isChartView = val;
  },
  updateBoxesData(state, val) {
    state.newsItemsSentCount = val.newsItemsSentCount;
    state.receiversCount = val.receiversCount;
    state.consultantsCount = val.consultantsCount;
    state.receivingAccountsCount = val.receivingAccountsCount;
  },

  updateTableData(state, val) {
    state.tableData = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
