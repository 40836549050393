const state = () => ({
  isNewsSelectPage: true,
});

const getters = {};

const actions = {};

const mutations = {
  updateIsNewsSelectPage(state, val) {
    state.isNewsSelectPage = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
