<template>
  <div class="portals-table-export">
    <q-table
      class="my-sticky-header-table"
      row-key="name"
      flat
      bordered
      :separator="'cell'"
      :pagination="pagination"
      :rows="data"
      :columns="columns"
      :rows-per-page-options="[]"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="username" :props="props">
            {{ props.row.username }}
          </q-td>
          <q-td key="email" :props="props">
            {{ props.row.email }}
          </q-td>
          <q-td key="portalName" :props="props">
            {{ props.row.portalName }}
          </q-td>
          <q-td key="registrationDate" :props="props">
            {{ formatDate(props.row.registrationDate) }}
          </q-td>
          <q-td key="lastLogin" :props="props">
            {{ formatDate(props.row.lastLogin) }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-btn class="orange-btn q-mt-md" @click="exportData()"
      >Export table data</q-btn
    >
  </div>
</template>

<script>
import { computed } from 'vue';
import store from '../../../store/index';
import moment from 'moment';
import functionalitiesUseService from '../../../services/functionalitiesUse.service';
export default {
  setup() {
    const columns = [
      {
        name: 'username',
        field: 'username',
        label: 'Name',
        align: 'left',
        sortable: true,
      },
      {
        name: 'email',
        field: 'email',
        label: 'Email address',
        align: 'left',
        sortable: true,
      },
      {
        name: 'portalName',
        field: 'portalName',
        label: 'Portal name',
        align: 'left',
        sortable: true,
      },
      {
        name: 'registrationDate',
        field: 'registrationDate',
        label: 'Registration date (of user)',
        align: 'left',
        sortable: true,
        format: (val) => formatDate(val),
      },
      {
        name: 'lastLogin',
        field: 'lastLogin',
        label: 'Last login',
        align: 'left',
        sortable: true,
        format: (val) => formatDate(val),
      },
    ];

    const data = computed({
      get: () => store.state.portalStats.usersTableExportStats,
    });
    const formatDate = (stringDate) => {
      const date = new Date(stringDate);
      return moment(date).format('DD-MM-YYYY');
    };

    const selectedCustomersIds = computed({
      get: () => store.state.portalStats.selectedCustomersIds,
    });

    const exportData = async () => {
      await functionalitiesUseService
        .exportPortalUsersTable(selectedCustomersIds)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: response.data.type })
          );
          var a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = 'PortalUsers.xlsx';
          a.href = fileURL;
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        });
    };
    return {
      selectedCustomersIds,
      exportData,
      columns,
      data,
      pagination: {
        rowsPerPage: 10,
        page: 1,
      },
      formatDate,
    };
  },
};
</script>
