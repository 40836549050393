<template>
  <ErrorModal />
  <div class="email-templates-edit-page column q-ml-xl q-my-md">
    <h2 class="title">Edit email templates</h2>
    <div class="column">
      <span>Select language: </span>
      <div class="q-gutter-xl q-mb-xl">
        <q-radio v-model="language" label="Dutch" val="nl" />
        <q-radio v-model="language" label="German" val="de" />
      </div>
    </div>
    <div class="column">
      <span>Select template category: </span>
      <div class="q-gutter-sm">
        <q-radio
          v-for="(category, index) in emailTemplateCategories"
          :key="index"
          v-model="emailTemplateCategory"
          :val="category"
          :label="category"
          :disable="language == ''"
          @click="getEmailTemplateOptions(emailTemplateCategory, language)"
        />
      </div>
      <div class="column q-my-xl">
        <span>E-mails: </span>
        <q-select
          label="Select template"
          transition-show="jump-up"
          transition-hide="jump-up"
          filled
          v-model="emailTemplateSelected"
          :options="emailTemplateOptions"
          style="width: 250px"
          :disable="emailTemplateCategory == ''"
          @update:model-value="getTemplateByType"
        />
      </div>
    </div>
    <div class="column">
      <span>Subject:</span>
      <froala
        class="froala-editor"
        id="sample3"
        v-model:value="templateSubject"
      ></froala>
    </div>
    <div class="column q-my-xl">
      <span>Body:</span>
      <froala
        class="froala-editor"
        id="sample3"
        v-model:value="templateBody"
      ></froala>
    </div>
    <q-btn
      class="orange-btn-filled q-mb-lg q-mt-lg bold-font q-mr-xl"
      label="Submit changes"
      no-caps
      style="width: auto; align-self: end;"
      @click="updateEmailTemplate()"
    />
  </div>
  <q-dialog v-model="successfullyUpdated" persistent>
    <q-card style="width: 300px">
      <q-card-section class="row items-center">
        <span>Template has been updated!</span>
      </q-card-section>
      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat label="OK" @click="disableDialog()" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, onMounted, computed, watch } from "vue";
import store from "../store/index";
import EmailTemplateService from "../services/email.templates.service";
import ErrorModal from "./ErrorModal.vue";
export default {
  name: "EditEmailTemplates",
  components: {
    ErrorModal,
  },
  setup() {
    onMounted(() => {
      EmailTemplateService.getEmailTemplateCategories();
    });
    const successfullyUpdated = computed({
      get: () => store.state.emailTemplate.showSuccessfullyUpdatedDialog,
    });
    const emailTemplateCategory = ref("");
    const language = ref("");
    const emailTemplateSelected = computed({
      get: () => store.state.emailTemplate.emailTemplateSelected,
      set: (val) =>
        store.commit("emailTemplate/updateSelectedTemplateType", val),
    });
    const emailTemplateOptions = computed({
      get: () => store.state.emailTemplate.emailTemplateOptions,
    });
    const templateBody = computed({
      get: () => store.state.emailTemplate.selectedTemplateBody,
      set: (body) => store.commit("emailTemplate/updateTemplateBody", body),
    });

    const templateSubject = computed({
      get: () => store.state.emailTemplate.selectedTemplateSubject,
      set: (subject) =>
        store.commit("emailTemplate/updateTemplateSubject", subject),
    });
    const getEmailTemplateOptions = (emailTemplateCategory, languageCode) => {
      EmailTemplateService.getEmailTemplatesByCategory(
        emailTemplateCategory,
        languageCode
      );
    };
    const getTemplateByType = () => {
      if (emailTemplateSelected.value != "") {
        EmailTemplateService.getEmailTemplateByType(
          emailTemplateSelected,
          language
        );
      }
    };
    const selectedTemplateId = computed({
      get: () => store.state.emailTemplate.selectedTemplateId,
    });

    const updateTemplate = {
      id: selectedTemplateId,
      subject: templateSubject,
      body: templateBody,
      languageCode: language, 
    };
    const updateEmailTemplate = async () => {
      await EmailTemplateService.updateEmailTemplate(updateTemplate);
    };
    const disableDialog = () => {
      store.commit("emailTemplate/disableSuccessfullyUpdatedDialog");
    };
    const emailTemplateCategories = computed({
      get: () => store.state.emailTemplate.emailTemplateCategories,
    });

    let prevLanguage = ref("");
    watch(
      [emailTemplateCategory, language],
      ([newLanguage], [oldEmailTemplateCategory, oldLanguage]) => {
        if (oldEmailTemplateCategory !== "" && newLanguage !== oldLanguage) {
          emailTemplateCategory.value = "";
        }
        store.commit("emailTemplate/resetState");
        prevLanguage.value = oldLanguage;
      }
    );
    return {
      language,
      emailTemplateCategories,
      successfullyUpdated,
      disableDialog,
      updateTemplate,
      selectedTemplateId,
      emailTemplateSelected,
      emailTemplateOptions,
      templateBody,
      templateSubject,
      emailTemplateCategory,
      getEmailTemplateOptions,
      getTemplateByType,
      updateEmailTemplate,
    };
  },
  beforeRouteEnter(to, from, next) {
    store.commit("emailTemplate/resetState");
    next();
  },
};
</script>
