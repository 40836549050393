<template>
  <ModalError />
  <q-dialog v-model="showLinkChanges" persistent full-height full-width>
    <div class="link-changes">
      <q-card bordered flat>
        <q-item>
          <q-toolbar>
            <q-toolbar-title>
              <span class="text-weight-bold" />Changes</q-toolbar-title>

            <q-btn flat round dense icon="close" @click="closeDialog"><q-tooltip>Close</q-tooltip></q-btn>
          </q-toolbar>
        </q-item>

        <q-separator></q-separator>

        <q-card-section style="height: 80vh; width: 100%;">
          <q-list dense bordered padding class="rounded-borders" style="height:100%">
            <q-item v-if="selectedVisualPingLinkWithChanges &&
              selectedVisualPingLinkWithChanges.isBroken &&
              selectedVisualPingLinkWithChanges.failureReason != null &&
              selectedVisualPingLinkWithChanges.failureReason != ''">
              <q-card class="full-width" style="background: rgba(255, 139, 139, 0.5);">
                <q-card-section>
                  <div class="row q-mb-sm">
                    <span>
                      {{ formatDate(selectedVisualPingLinkWithChanges.visualPingNotifications[0].datetime) }}
                    </span>
                    <q-space></q-space>
                    <q-btn
                      @click="markVPNotificationAsChecked(selectedVisualPingLinkWithChanges.visualPingNotifications[0].id, selectedVisualPingLinkWithChanges.visualPingNotifications[0].linkId)"
                      dense size="12px" round flat color="indigo-10" icon="las la-check-square">
                      <q-tooltip>
                        Mark as checked
                      </q-tooltip>
                    </q-btn>
                  </div>
                  <q-separator></q-separator>
                  {{ selectedVisualPingLinkWithChanges.failureReason }}
                </q-card-section>
              </q-card>
            </q-item>

            <template
              v-if="selectedVisualPingLinkWithChanges && selectedVisualPingLinkWithChanges.isBroken == false && selectedVisualPingLinkWithChanges.visualPingNotifications.length > 0">
              <q-scroll-area style="height: 100%;">
                <template v-for="notification in selectedVisualPingLinkWithChanges.visualPingNotifications"
                  :key="notification">
                  <q-separator></q-separator>
                  <div class="row q-pa-sm" style="position: relative">
                    <span class="centered-element">
                      {{ formatDate(notification.datetime) }}
                    </span>
                    <q-space></q-space>
                    <q-btn @click="markVPNotificationAsChecked(notification.id, notification.linkId)" dense size="12px"
                      round flat color="indigo-10" icon="las la-check-square">
                      <q-tooltip>
                        Mark as checked
                      </q-tooltip>
                    </q-btn>
                  </div>
                  <q-separator></q-separator>
                  <iframe class="q-pr-md" :src="notification.textChanges" style="width: 100%; height: 60vh">
                  </iframe>
                </template>
              </q-scroll-area>
            </template>
          </q-list>
        </q-card-section>
      </q-card>
    </div>
  </q-dialog>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import store from '../../store/index';
import moment from 'moment';
import ModalError from './ErrorModal.vue';
import linkcheckerService from '../../services/linkchecker.service';

export default {
  name: 'LinkChanges',
  components: {
    ModalError
  },
  props: {
    selectedLinkId: Number,
    searchFilter: String,
    toggleUnchangedLinks: Boolean,
    toggleChangedLinks: Boolean,
    toggleBrokenLinks: Boolean,
  },
  setup(props) {
    const linkId = computed(() => {
      return props.selectedLinkId
    });

    onMounted(async () => {
      await linkcheckerService.getVisualPingLinkWithChanges(linkId.value);
    });

    const showLinkChanges = computed({
      get: () => store.state.linkChecker.showLinkChanges,
      set: (value) => store.commit('linkChecker/updateShowLinkChanges', value),
    });

    const closeDialog = () => {
      showLinkChanges.value = false;
    };
    const searchFilterTerm = computed(() => {
      return props.searchFilter;
    })
    const toggleUnchangedLinksShow = computed(() => {
      return props.toggleUnchangedLinks;
    });

    const toggleChangedLinksShow = computed(() => {
      return props.toggleChangedLinks;
    });

    const toggleBrokenLinksShow = computed(() => {
      return props.toggleBrokenLinks;
    });

    const getVisualPingLinkWithChanges = async () => {
      await linkcheckerService.getVisualPingLinkWithChanges(linkId.value);
    };

    const selectedVisualPingLinkWithChanges = computed({
      get: () => store.state.linkChecker.visualPingLinkWithChanges,
    });
    const pagination = ref(
      computed({
        get: () => store.state.linkChecker.pagination,
        set: (paginationVar) =>
          store.commit('linkChecker/updatePagination', paginationVar),
      })
    );
    const getFetchCount = () => {
      return pagination.value.rowsPerPage === 0
        ? pagination.value.rowsNumber
        : pagination.value.rowsPerPage;
    };
    const refreshPingLinks = async () => {
      await linkcheckerService.getVisualPingLinks(
        searchFilterTerm.value,
        toggleUnchangedLinksShow.value,
        toggleChangedLinksShow.value,
        toggleBrokenLinksShow.value,
        pagination.value.sortBy,
        pagination.value.descending,
        pagination.value.page,
        getFetchCount()
      );
    };

    const markVPNotificationAsChecked = async (notificationId, linkId) => {
      await linkcheckerService.markVPNotificationAsChecked(notificationId, linkId, null)
        .then(async () => {
          await refreshPingLinks();
        });
    };

    return {
      showLinkChanges,
      linkId,
      pagination,
      refreshPingLinks,
      getFetchCount,
      toggleBrokenLinksShow,
      toggleChangedLinksShow,
      toggleUnchangedLinksShow,
      searchFilterTerm,
      getVisualPingLinkWithChanges,
      selectedVisualPingLinkWithChanges,
      closeDialog,
      markVPNotificationAsChecked,
      formatDate(stringDate) {
        if (stringDate != null)
          return moment(stringDate.split("GMT")[0]).format('DD-MM-YYYY');
      },
    }
  },
};
</script>
