import moment from 'moment';
import { ref } from 'vue';

const state = () => ({
  currentPage: 1,
  allNews: [{}],
  maxPages: 0,
  totalResults: 10,
  selectedNewsItem: {},
  selectedIntroduction: '',
  selectedBody: '',
  selectedTitle: '',
  selectedNewsAudienceTags: [],
  selectedNewsPurposesTags: [],
  //This is just hidden for now, don't remove it
  //selectedNewsIndustriesTags: [{}],
  selectedNewsLocationsTags: [],
  selectedNewsSchemeTypesTags: [],
  selectedNewsProjectTypesTags: [],
  selectedNewsIndustriesNewTags: [],
  selectedNewsKeywordsTags: [],
  selectedNewsLinks: [{}],
  selectedNewsDate: '',
  publicationStatuses: [],
  scopes: [],
  languageCodes: [],
  selectedNewsScope: '',
  selectedNewsStatus: '',
  selectedNewsLanguageCode: '',
  schemesForSelect: [{}],
  selectedNewsSchemes: [{}],
  allSchemes: [{}],
  newsOrganisations: [{}],
  modalErrorMessage: [],
  hasModalError: false,
  pageSize: 0,
  pagination: {},
  showSelectTags: false,
  newsAudienceTagsCount: 0,
  newsPurposesTagsCount: 0,
  newsLocationsTagsCount: 0,
  newsSchemeTypesTagsCount: 0,
  newsProjectTypesTagsCount: 0,
  newsIndustriesNewTagsCount: 0,
  selectedNewsAudienceTagsIds: [],
  selectedNewsPurposesTagsIds: [],
  selectedNewsLocationsTagsIds: [],
  selectedNewsSchemeTypesTagsIds: [],
  selectedNewsProjectTypesTagsIds: [],
  selectedNewsIndustriesNewTagsIds: [],
  newsLetterTypesList: [],
  selectedNewsLetterTypes: [],
  selectedCreateNewsScope: '',
  // German locations
  selectedGermanLocationTags: [],
});

const getters = {};

const actions = {};

const mutations = {
  updateCreateNewsScope(state, val) {
    state.selectedCreateNewsScope = val;
  },
  updateSelectedNewsLetterTypes(state, val) {
    state.selectedNewsLetterTypes = val;
  },
  updateNewsLetterTypesList(state, val) {
    state.newsLetterTypesList = val;
  },
  setDefaultPagination(state) {
    state.pagination = ref({
      page: 1,
      rowsPerPage: 50,
      rowsNumber: 10,
    });
  },
  setDefaultPagination50(state) {
    state.pagination = ref({
      page: 1,
      rowsPerPage: 50,
      rowsNumber: 10,
    });
  },
  updatePagination(state, val) {
    state.pagination = {
      rowsPerPage: val.pageSize,
      rowsNumber: val.resultCount,
    };
  },
  updatePaginationPage(state, val) {
    state.pagination = {
      page: val.page,
      rowsPerPage: val.rowsPerPage,
      rowsNumber: val.rowsNumber,
    };
  },
  updateSearchResults(state, val) {
    state.allNews = val.results;
    state.maxPages = val.pageCount;
    state.totalResults = val.resultCount;
    state.pageSize = val.pageSize;
  },
  updateCurrentPage(state, val) {
    state.currentPage = val;
  },
  updateTotalResults(state, val) {
    state.totalResults = val;
  },
  updateAllNews(state, val) {
    state.allNews = val.results;
    state.maxPages = val.pageCount;
    state.totalResults = val.resultCount;
    state.pageSize = val.pageSize;
  },

  updateSelectedNews(state, val) {
    state.selectedNewsItem = val;
    state.selectedIntroduction = val.introduction;
    state.selectedBody = val.body;
    state.selectedTitle = val.title;
    state.selectedNewsDate =
      val.date == null || val.date == ''
        ? null
        : moment(val.date).format('DD-MM-YYYY');
    state.selectedNewsStatus = val.status;
    state.selectedNewsSchemes = val.schemes;
    state.newsOrganisations = val.organisations;
    state.selectedNewsScope = val.scope;
    state.selectedNewsLanguageCode = val.language;
    state.selectedGermanLocationTags = val.locationTags;
  },
  updateStatus(state, val) {
    state.selectedNewsStatus = val;
  },

  updateScope(state, val) {
    state.selectedNewsScope = val;
  },

  updateLanguageCode(state, val) {
    state.selectedNewsLanguageCode = val;
  },

  updateOrganisations(state, val) {
    state.newsOrganisations = val;
  },

  updateSchemesForSelect(state, val) {
    state.allSchemes = val;
  },

  updateSelectedSchemes(state, val) {
    state.selectedNewsSchemes = val;
  },

  updateIntroduction(state, val) {
    state.selectedIntroduction = val;
  },
  updateBody(state, val) {
    state.selectedBody = val;
  },
  updateTitle(state, val) {
    state.selectedTitle = val;
  },

  updateSelectedDate(state, val) {
    state.selectedNewsDate = val;
  },

  updatePublicationStatuses(state, val) {
    state.publicationStatuses = val;
  },

  updateScopes(state, val) {
    state.scopes = val;
  },

  updateLanguageCodes(state, val) {
    state.languageCodes = val;
  },

  updateNewsKeywordsTags(state, value) {
    state.selectedNewsKeywordsTags = value;
  },

  resetModalError(state) {
    state.modalErrorMessage = '';
  },

  enableModalError(state) {
    state.hasModalError = true;
  },
  disableModalError(state) {
    state.hasModalError = false;
    state.modalErrorMessage = '';
  },
  updateModalError(state, message) {
    state.modalErrorMessage = message;
  },
  enableShowSelectTags(state) {
    state.showSelectTags = true;
  },
  disableShowSelectTags(state) {
    state.showSelectTags = false;
  },
  updateAudienceCount(state, val) {
    state.newsAudienceTagsCount = val;
  },
  updatePurposesCount(state, val) {
    state.newsPurposesTagsCount = val;
  },
  updateLocationsCount(state, val) {
    state.newsLocationsTagsCount = val;
  },
  updateSchemeTypesCount(state, val) {
    state.newsSchemeTypesTagsCount = val;
  },
  updateProjectTypesCount(state, val) {
    state.newsProjectTypesTagsCount = val;
  },
  updateIndustriesNewCount(state, val) {
    state.newsIndustriesNewTagsCount = val;
  },
  setSelectedNewsAudienceTags(state, tags) {
    if (state.selectedNewsAudienceTags.length > 0)
      state.selectedNewsAudienceTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedNewsAudienceTags.push(tags[i]);

    const selectedNewsAudienceIds = tags.map((tag) => tag.id);
    state.newsAudienceTagsCount = selectedNewsAudienceIds.length;
    if (state.selectedNewsAudienceTagsIds.length > 0)
      state.selectedNewsAudienceTagsIds = [];
    for (let i = 0; i < selectedNewsAudienceIds.length; i++)
      state.selectedNewsAudienceTagsIds.push(selectedNewsAudienceIds[i]);
  },
  setSelectedNewsPurposesTags(state, tags) {
    if (state.selectedNewsPurposesTags.length > 0)
      state.selectedNewsPurposesTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedNewsPurposesTags.push(tags[i]);

    const selectedNewsPurposesIds = tags.map((tag) => tag.id);
    state.newsPurposesTagsCount = selectedNewsPurposesIds.length;
    if (state.selectedNewsPurposesTagsIds.length > 0)
      state.selectedNewsPurposesTagsIds = [];
    for (let i = 0; i < selectedNewsPurposesIds.length; i++)
      state.selectedNewsPurposesTagsIds.push(selectedNewsPurposesIds[i]);
  },
  setSelectedNewsLocationsTags(state, tags) {
    if (state.selectedNewsLocationsTags.length > 0)
      state.selectedNewsLocationsTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedNewsLocationsTags.push(tags[i]);

    const selectedNewsLocationsIds = tags.map((tag) => tag.id);
    state.newsLocationsTagsCount = selectedNewsLocationsIds.length;
    if (state.selectedNewsLocationsTagsIds.length > 0)
      state.selectedNewsLocationsTagsIds = [];
    for (let i = 0; i < selectedNewsLocationsIds.length; i++)
      state.selectedNewsLocationsTagsIds.push(selectedNewsLocationsIds[i]);
  },
  setSelectedNewsSchemeTypeTags(state, tags) {
    if (state.selectedNewsSchemeTypesTags.length > 0)
      state.selectedNewsSchemeTypesTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedNewsSchemeTypesTags.push(tags[i]);

    const selectedNewsSchemeTypesIds = tags.map((tag) => tag.id);
    state.newsSchemeTypesTagsCount = selectedNewsSchemeTypesIds.length;
    if (state.selectedNewsSchemeTypesTagsIds.length > 0)
      state.selectedNewsSchemeTypesTagsIds = [];
    for (let i = 0; i < selectedNewsSchemeTypesIds.length; i++)
      state.selectedNewsSchemeTypesTagsIds.push(selectedNewsSchemeTypesIds[i]);
  },
  setSelectedNewsProjectTypeTags(state, tags) {
    if (state.selectedNewsProjectTypesTags.length > 0)
      state.selectedNewsProjectTypesTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedNewsProjectTypesTags.push(tags[i]);

    const selectedNewsProjectTypesIds = tags.map((tag) => tag.id);
    state.newsProjectTypesTagsCount = selectedNewsProjectTypesIds.length;
    if (state.selectedNewsProjectTypesTagsIds.length > 0)
      state.selectedNewsProjectTypesTagsIds = [];
    for (let i = 0; i < selectedNewsProjectTypesIds.length; i++)
      state.selectedNewsProjectTypesTagsIds.push(
        selectedNewsProjectTypesIds[i]
      );
  },
  setSelectedNewsIndustriesNEWTags(state, tags) {
    if (state.selectedNewsIndustriesNewTags.length > 0)
      state.selectedNewsIndustriesNewTags = [];
    for (let i = 0; i < tags.length; i++)
      state.selectedNewsIndustriesNewTags.push(tags[i]);

    const selectedNewsIndustriesNewIds = tags.map((tag) => tag.id);
    state.newsIndustriesNewTagsCount = selectedNewsIndustriesNewIds.length;
    if (state.selectedNewsIndustriesNewTagsIds.length > 0)
      state.selectedNewsIndustriesNewTagsIds = [];
    for (let i = 0; i < selectedNewsIndustriesNewIds.length; i++)
      state.selectedNewsIndustriesNewTagsIds.push(
        selectedNewsIndustriesNewIds[i]
      );
  },
  setSelectedNewsKeywordsTags(state, tags) {
    state.selectedNewsKeywordsTags = tags.map((tag) => tag.name);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
