<template>
  <div class="general-stats-page">
    <GeneralStatsModal />
    <InactiveUsersModal />
    <PieChartModal />
    <div class="row page-heading">
      <h2 class="title">
        General stats and usage
      </h2>
      <div class="row">
        <router-link :to="{ name: 'GeneralStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('general')">
            General stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'UserStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('user')">
            User stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'PortalStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('portal')">
            Portal stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'ConsumptionStats' }" class="page-link">
          <q-btn
            flat
            class="btn-navigation"
            :class="isUnderlined('consumption')"
          >
            Consumption stats
          </q-btn>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="column statfilters-area">
        <div class="search-button">
          <div class="warning-container">
            <p class="warning">
              Select filter(s) to show graph
            </p>
          </div>
          <q-btn
            class="orange-btn bold-font"
            flat
            label="Search"
            @click="graphFilters()"
            no-caps
          />
          <q-btn
            class="orange-btn bold-font"
            flat
            label="Export data"
            @click="exportData()"
            no-caps
          />
        </div>

        <div class="stats-filters">
          <div class="filter-box">
            <q-select
              class="filter-select"
              bg-color="white"
              v-model="modelYearFrom"
              :options="optionsYearsFrom"
              label="Year from"
              borderless
            />
          </div>
          <div class="filter-box">
            <q-select
              class="filter-select"
              borderless
              v-model="modelMonthFrom"
              :options="optionsMonths"
              label="Month from"
              option-value="id"
              option-label="name"
              bg-color="white"
            >
            </q-select>
          </div>
        </div>
        <div class="stats-filters">
          <div class="filter-box">
            <q-select
              class="filter-select"
              borderless
              v-model="modelYearTo"
              :options="optionsYearsTo"
              label="Year to"
              bg-color="white"
            />
          </div>
          <div class="filter-box">
            <q-select
              class="filter-select"
              borderless
              v-model="modelMonthTo"
              :options="optionsMonths"
              label="Month to"
              option-value="id"
              option-label="name"
              bg-color="white"
            >
            </q-select>
          </div>
        </div>

        <div class="stats-filters">
          <div class="filter-box top-margin">
            <q-select
              class="filter-select"
              borderless
              v-model="selectedCustomerTypes"
              :options="customerTypes"
              label="Type of customer"
              clearable
              bg-color="white"
            />
          </div>

          <div class="filter-box">
            <q-select
              class="filter-select"
              borderless
              v-model="selectedCustomerOwner"
              :options="customerOwnersList"
              label="Customer owner"
              option-value="fullName"
              option-label="fullName"
              clearable
              @popup-show="width = 200"
              :popup-content-style="`width: 200px; word-break: break-all;`"
              style="word-break: break-all;"
              bg-color="white"
            >
            </q-select>
          </div>
        </div>
      </div>

      <div class="column" style="width: min-content; margin-left: 2vw">
        <div v-if="isLoading" style="text-align: center;">
          <span style="display: inline-block">
            <q-spinner-bars color="primary" size="2em" />
          </span>
        </div>
        <div v-else class="graph-box">
          <LineGraph class="line-graph" ref="lineGraph" v-if="isShowing" />
        </div>
        <div class="row">
          <div class="row data-container">
            <div class="stat-boxes">
              <div class="stats-boxes">
                <div class="row">
                  <div class="stat-section">
                    <span
                      class="stat-section-data cursor-pointer"
                      @click="updateModalWithData(activeDemoList, 'activeDemo')"
                    >
                      {{
                        userStatsGeneral.demoAccountsActive
                          ? userStatsGeneral.demoAccountsActive
                          : 0
                      }}
                    </span>
                    <span class="stat-section-text cursor-pointer">
                      demo accounts active
                    </span>
                  </div>
                  <div class="stat-section">
                    <span class="stat-section-data">
                      {{
                        userStatsGeneral.activeCustomersCount
                          ? userStatsGeneral.activeCustomersCount
                          : 0
                      }}
                    </span>
                    <span class="stat-section-text">
                      active customers
                    </span>
                  </div>
                  <div class="stat-section">
                    <span class="stat-section-data">
                      {{
                        userStatsGeneral.totalActiveUsersCount
                          ? userStatsGeneral.totalActiveUsersCount
                          : 0
                      }}
                    </span>
                    <span class="stat-section-text">
                      users active
                    </span>
                  </div>
                  <div class="stat-section noborder-section topborder">
                    <span class="stat-section-data">
                      {{
                        userStatsGeneral.averageActiveUsers
                          ? userStatsGeneral.averageActiveUsers
                          : 0
                      }}
                    </span>
                    <span class="stat-section-text ">
                      average active users per account
                    </span>
                  </div>

                  <div class="stat-section">
                    <span
                      class="stat-section-data cursor-pointer negative-data "
                      @click="
                        updateModalWithData(
                          noNewsLetterAccountsList,
                          'noNewsletter'
                        )
                      "
                    >
                      {{
                        userStatsGeneral.usersWithoutNewsletterCount
                          ? userStatsGeneral.usersWithoutNewsletterCount
                          : 0
                      }}
                    </span>
                    <span class="stat-section-text cursor-pointer">
                      users without newsletter
                    </span>
                  </div>
                  <div class="stat-section">
                    <span
                      class="stat-section-data negative-data"
                      @click="
                        updateModalWithData(noProfileAccountsList, 'noProfile')
                      "
                    >
                      {{
                        userStatsGeneral.usersWithoutProfileCount
                          ? userStatsGeneral.usersWithoutProfileCount
                          : 0
                      }}
                    </span>
                    <span class="stat-section-text cursor-pointer">
                      users without profile
                    </span>
                  </div>
                  <div class="stat-section noborder-section">
                    <span
                      class="stat-section-data cursor-pointer negative-data"
                      @click="
                        updateModalWithData(
                          customersInDangerList,
                          'customersInDanger'
                        )
                      "
                    >
                      {{
                        userStatsGeneral.customersInDangerCount
                          ? userStatsGeneral.customersInDangerCount
                          : 0
                      }}
                    </span>
                    <span class="stat-section-text cursor-pointer">
                      customers in danger
                    </span>
                  </div>
                </div>
              </div>

              <InactiveUsersLine class="q-mt-xl" />

              <PieChart
                style="width: 550px; height: 270px; margin: auto"
                class="pie-chart q-pt-lg"
              />
            </div>
          </div>
          <div class="row page-heading portal-stats-container">
            <h2 class="title portal-heading">
              General portal stats
            </h2>
            <div class="stats-boxes" style="flex-direction: row">
              <div class="stat-section">
                <span class="stat-section-data">
                  {{ portalUsersCount ? portalUsersCount : 0 }}
                </span>
                <span class="stat-section-text">
                  portal users
                </span>
              </div>
              <div class="stat-section">
                <span class="stat-section-data">
                  {{
                    activePortalCustomersCount ? activePortalCustomersCount : 0
                  }}
                </span>
                <span class="stat-section-text">
                  active portal customers
                </span>
              </div>
              <div class="stat-section">
                <span
                  class="stat-section-data cursor-pointer"
                  @click="
                    updateModalWithData(newPortalUsersList, 'newPortalUsers')
                  "
                >
                  {{ newPortalUsers ? newPortalUsers : 0 }}
                </span>
                <span class="stat-section-text">
                  new portal users in last 30 days
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="data-container column"></div>
  </div>
</template>
<script>
import { ref, onMounted, computed } from "vue";
import store from "../../store/index.js";
import LineGraph from "./General/LineGraph.vue";
import PieChart from "./General/PieChart.vue";
import CustomersService from "../../services/customers.service";
import statsService from "../../services/stats.service";
import GeneralStatsModal from "./General/GeneralStatsModal.vue";
import PieChartModal from "./General/PieChartModal.vue";
import { useRoute } from "vue-router";
import moment from "moment";
import InactiveUsersLine from "./General/InactiveUsersLine.vue";
export default {
  name: "GeneralStats",
  components: {
    LineGraph,
    PieChart,
    GeneralStatsModal,
    PieChartModal,
    InactiveUsersLine,
  },
  setup() {
    const isLoading = ref(false);
    const isShowing = ref(false);
    const route = useRoute();

    onMounted(async () => {
      store.commit("generalStats/updateYearsOptions");
      let thisMonth = new Date().getMonth();
      let date1 = new Date();
      let date2 = new Date();
      let date3 = new Date();
      thisMonth;
      date1.setMonth(thisMonth - 1, 0);
      date2.setMonth(thisMonth, 0);
      date3.setMonth(thisMonth + 1, 0);

      if (date3.getMonth() == 0 || date3.getMonth() == 1) {
        store.commit("generalStats/updateYearFrom", moment().year() - 1);
      }

      store.commit("generalStats/updateYearsToOptions");

      store.commit("generalStats/updateMonthTo", {
        id: date3.getMonth(),
        name: date3.toLocaleString("nl-NL", { month: "long" }),
      });

      store.commit("generalStats/updateMonthFrom", {
        id: date1.getMonth(),
        name: date1.toLocaleString("nl-NL", { month: "long" }),
      });

      if (modelYear.value != 2023) {
        store.commit("generalStats/updateSelectedMonths", [
          {
            id: date1.getMonth(),
            name: date1.toLocaleString("nl-NL", { month: "long" }),
          },
          {
            id: date2.getMonth(),
            name: date2.toLocaleString("nl-NL", { month: "long" }),
          },
          {
            id: date3.getMonth(),
            name: date3.toLocaleString("nl-NL", { month: "long" }),
          },
        ]);
      } else {
        store.commit("generalStats/updateSelectedMonths", [
          {
            id: date3.getMonth(),
            name: date3.toLocaleString("nl-NL", { month: "long" }),
          },

          {
            id: date1.getMonth(),
            name: date1.toLocaleString("nl-NL", { month: "long" }),
          },
          {
            id: date2.getMonth(),
            name: date2.toLocaleString("nl-NL", { month: "long" }),
          },
        ]);
      }
      store.commit("generalStats/resetFilters");
      await statsService.getGeneralStatsPieChartCustomerTypes();
      await statsService.getGeneralStatsForUsers();
      await CustomersService.getCustomerTypes();
      await CustomersService.getCustomerOwners();
      await statsService.getGeneralPortalStats();
      await statsService.getInactiveUsersGraph(
        modelYearFrom,
        modelYearTo,
        modelMonthIdFrom,
        modelMonthIdTo
      );
    });
    const graphFilters = async () => {
      isShowing.value = true;
      isLoading.value = true;
      await statsService.XgetStatsLineGraphGeneral(
        modelYearFrom,
        modelYearTo,
        modelMonthIdFrom,
        modelMonthIdTo,
        selectedCustomerOwnerId,
        selectedCustomerTypes
      );
      await statsService.getInactiveUsersGraph(
        modelYearFrom,
        modelYearTo,
        modelMonthIdFrom,
        modelMonthIdTo
      );

      isLoading.value = false;
    };

    const userStatsGeneral = computed({
      get: () => store.state.generalStats.userStatsGeneral,
    });

    const selectedCustomerTypes = computed({
      get: () => store.state.generalStats.customerType,
      set: async (val) => {
        store.commit("generalStats/updateCustomerType", val),
          await statsService.getGeneralStatsForUsers(
            selectedCustomerOwnerId,
            selectedCustomerTypes
          );
      },
    });

    const customerTypes = computed({
      get: () => store.state.customers.customerTypesList,
    });

    const lineGraph = ref(null);
    const selectedCustomerOwnerId = computed({
      get: () => store.state.generalStats.selectedCustomerOwnerId,
    });
    const modelYear = computed({
      get: () => store.state.generalStats.selectedYear,
      set: async (val) => {
        store.commit("generalStats/updateSelectedYear", val);
      },
    });
    const modelMonths = computed({
      get: () => store.state.generalStats.selectedMonths,
      set: async (val) => {
        store.commit(
          "generalStats/updateSelectedMonths",
          val.sort(function(a, b) {
            return a.id - b.id;
          })
        );
      },
    });

    const customerOwnersList = computed({
      get: () => store.state.accountManagers.accountManagersBasicInfoList,
    });

    const selectedCustomerOwner = computed({
      get: () => store.state.generalStats.selectedCustomerOwner,
      set: async (val) => {
        store.commit("generalStats/updateSelectedCustomerOwner", val);

        await statsService.getGeneralStatsForUsers(
          selectedCustomerOwnerId,
          selectedCustomerTypes
        );

        await statsService.getGeneralStatsPieChartCustomerTypes(
          selectedCustomerOwnerId
        );
      },
    });

    const clearCustomerOwner = async () => {
      store.commit("generalStats/updateSelectedCustomerOwner", null);
      await statsService.getGeneralStatsForUsers(
        selectedCustomerOwnerId,
        selectedCustomerTypes
      );
    };

    const activeUsersList = computed({
      get: () => store.state.generalStats.activeUsersList,
    });

    const noNewsLetterAccountsList = computed({
      get: () => store.state.generalStats.noNewsLetterAccountsList,
    });
    const noProfileAccountsList = computed({
      get: () => store.state.generalStats.noProfileAccountsList,
    });
    const activeCustomersList = computed({
      get: () => store.state.generalStats.activeCustomersList,
    });
    const activeDemoList = computed({
      get: () => store.state.generalStats.activeDemoList,
    });
    const customersInDangerList = computed({
      get: () => store.state.generalStats.customersInDangerList,
    });

    const noVisitUsersList = computed({
      get: () => store.state.generalStats.noVisitUsersList,
    });

    const updateModalWithData = (arr, type) => {
      if (arr && arr.length > 0) {
        store.commit("generalStats/updateShowModal", true);
        store.commit("generalStats/updateModalData", arr);
        store.commit("generalStats/updateModalDataType", type);
      }
    };
    const updateModalWithDataCustomers = (arr) => {
      if (arr && arr.length > 0) {
        store.commit("generalStats/updateShowModal", true);
        store.commit("generalStats/updateModalDataCustomers", arr);
      }
    };
    const portalUsersCount = computed({
      get: () => store.state.generalStats.portalUsersCount,
    });
    const activePortalCustomersCount = computed({
      get: () => store.state.generalStats.activePortalCustomersCount,
    });
    const newPortalUsers = computed({
      get: () => store.state.generalStats.newPortalUsers,
    });
    const newPortalUsersList = computed({
      get: () => store.state.generalStats.newPoralUsersList,
    });

    const modelYearFrom = computed({
      get: () => store.state.generalStats.yearFrom,
      set: async (val) => {
        store.commit("generalStats/updateYearFrom", val);
        store.commit("generalStats/updateYearsToOptions");
      },
    });

    const modelYearTo = computed({
      get: () => store.state.generalStats.yearTo,
      set: async (val) => {
        store.commit("generalStats/updateYearTo", val);
      },
    });

    const modelMonthFrom = computed({
      get: () => store.state.generalStats.monthFrom,
      set: async (val) => {
        store.commit("generalStats/updateMonthFrom", val);
      },
    });

    const modelMonthTo = computed({
      get: () => store.state.generalStats.monthTo,
      set: async (val) => {
        store.commit("generalStats/updateMonthTo", val);
      },
    });

    const modelMonthIdFrom = computed({
      get: () => store.state.generalStats.monthFromId,
    });

    const modelMonthIdTo = computed({
      get: () => store.state.generalStats.monthToId,
    });

    const optionsYearsFrom = computed({
      get: () => store.state.generalStats.optionsYearsFrom,
    });

    const optionsYearsTo = computed({
      get: () => store.state.generalStats.optionsYearsTo,
    });

    const exportData = async () => {
      await statsService.exportGeneralStatsData().then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        var a = document.createElement("a");
        a.setAttribute("style", "display:none;");
        document.body.appendChild(a);
        a.download = "GeneralStatsExport.xlsx";
        a.href = fileURL;
        a.target = "_blank";
        a.click();
        document.body.removeChild(a);
      });
    };

    return {
      exportData,
      optionsYearsFrom,
      route,
      optionsYearsTo,
      modelMonthIdFrom,
      modelMonthIdTo,
      modelYearFrom,
      modelYearTo,
      modelMonthFrom,
      modelMonthTo,
      newPortalUsersList,
      newPortalUsers,
      portalUsersCount,
      activePortalCustomersCount,
      updateModalWithDataCustomers,
      customersInDangerList,
      noVisitUsersList,
      activeDemoList,
      activeCustomersList,
      activeUsersList,
      noNewsLetterAccountsList,
      noProfileAccountsList,
      updateModalWithData,
      clearCustomerOwner,
      userStatsGeneral,
      selectedCustomerOwnerId,
      selectedCustomerOwner,
      customerOwnersList,
      customerTypes,
      selectedCustomerTypes,
      modelYear,
      modelMonths,
      isLoading,
      isShowing,
      lineGraph,
      graphFilters,
      optionsMonths: [
        { id: 0, name: "January" },
        { id: 1, name: "February" },
        { id: 2, name: "March" },
        { id: 3, name: "April" },
        { id: 4, name: "May" },
        { id: 5, name: "June" },
        { id: 6, name: "July" },
        { id: 7, name: "August" },
        { id: 8, name: "September" },
        { id: 9, name: "October" },
        { id: 10, name: "November" },
        { id: 11, name: "December" },
      ],
    };
  },
  methods: {
    isUnderlined(buttonRoute) {
      if (this.route.fullPath.includes(buttonRoute))
        return "btn-nav-underlined";
      return "";
    },
  },
};
</script>
