import axios from 'axios';
import authHeader from '../helpers/auth-header';
import moment from 'moment';
import store from '../store/index';
import axiosUrl from './axios.service';
import AuthService from '../services/auth.service';
import router from '../router';

const API_URL = axiosUrl.getAxiosUrl();

class UserService {
  updateUser(user) {
    return axios
      .post(
        API_URL + 'users/updateUser',
        {
          id: user.id.value,
          username: user.email.value,
          email: user.email.value,
          newsletterFrequency: user.newsletterFrequency.value,
          person: {
            startDate: moment(user.person.startDate.value, 'DD-MM-YYYY').format(
              'YYYY-MM-DD'
            ),
            endDate:
              user.person.endDate.value == null ||
              user.person.endDate.value == ''
                ? null
                : moment(user.person.endDate.value, 'DD-MM-YYYY').format(
                    'YYYY-MM-DD'
                  ),
            firstName: user.person.firstName.value,
            lastName: user.person.lastName.value,
            salutation: user.person.salutation.value,
            isReceivingSystemMails: user.person.isReceivingSystemMails.value,
            isAccountManager: user.person.isAccountManager.value,
            customer: user.person.customer.value,
            customerId: user.person.customerId.value,
            department: user.person.department.value,
            mobileNumber: user.person.mobileNumber.value,
            language: user.person.language.value,
            hasSasTaskEmails: user.person.hasSasTaskEmails.value,
          },
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit('user/updateUserObject', response.data);
        store.commit('user/enableSuccessfullyUpdatedDialog');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
            router.go(-1);
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async getPaginationUsers(
    filterSearchTerm,
    filterByActivityStatus,
    sortBy,
    descending,
    page,
    pageSize
  ) {
    return await axios
      .get(API_URL + 'users/getPaginationUsers', {
        params: {
          filterSearchTerm: filterSearchTerm,
          isActive: filterByActivityStatus,
          sortBy: sortBy,
          descending: descending,
          index: page ?? 0,
          size: pageSize ?? 50,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateUsersList', response.data.items);
        store.commit('user/updatePagination', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async createUser(user) {
    return await axios
      .post(
        API_URL + 'users/addNewUser',
        {
          username: user.email,
          email: user.email,
          person: {
            firstName: user.person.firstName,
            lastName: user.person.lastName,
            salutation: user.person.salutation,
            customer: user.person.customer,
            customerId: user.person.customerId,
            department: user.person.department,
            mobileNumber: user.person.mobileNumber,
            isReceivingSystemMails: user.person.isReceivingSystemMails,
            isAccountManager: user.person.isAccountManager,
            language: user.person.language,
          },
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status === 200) {
          router.push({
            name: 'EditUser',
            params: {
              id: response.data.id,
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  addRoleToUser(selectedUserId, addRoleId) {
    return axios
      .post(
        API_URL + 'users/addRoleToUser',
        {
          userId: selectedUserId,
          roleId: addRoleId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit('user/updateUserObject', response.data);
        this.getAllNonRelatedRoles(response.data.id);
        store.commit('user/enableSuccessfullyUpdatedDialog');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  removeRoleFromUser(selectedUserId, roleId) {
    return axios
      .post(
        API_URL + 'users/removeRoleFromUser',
        {
          userId: selectedUserId,
          roleId: roleId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit('user/updateUserObject', response.data);
        this.getAllNonRelatedRoles(response.data.id);
        store.commit('user/enableSuccessfullyUpdatedDialog');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  deactivateUser(userId) {
    return axios
      .post(
        API_URL + 'users/deactivateUser',
        {
          id: userId,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  reactivateUser(userId) {
    return axios
      .post(
        API_URL + 'users/reactivateUser',
        {
          id: userId,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }
  async anonymizeUser(userId) {
    return await axios
      .post(
        API_URL + 'users/anonymizeUser',
        {
          id: userId,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }
  async getRolesAndPermissions() {
    return await axios
      .get(API_URL + 'users/getRolesAndPermissions', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateAllRolesAndPermissions', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getUser(userId) {
    return await axios
      .get(API_URL + `users/getUser?userId=${userId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateUserObject', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            router.go(-1);
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  getUsers() {
    return axios
      .get(API_URL + 'users/getUsers', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateUsersList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  getUsersDefaultEnv() {
    return axios
      .get(API_URL + 'users/getUsersDefaultEnv', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateUsersListDefaultEnv', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  getUsersByFilters(customerOwnerId) {
    return axios
      .get(API_URL + 'users/getUsersByFilters', {
        params: {
          customerOwnerId: customerOwnerId,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateUsersListDefaultEnv', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  getUsersByUser(customers, customerOwnerId) {
    return axios
      .get(API_URL + 'users/getUsersByUser', {
        params: {
          customers: customers != null ? customers + '' : '',
          customerOwnerId: customerOwnerId,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateUsersListDefaultEnv', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async getUsersByCustomerId(
    customerId,
    filterByActivityStatus,
    sortBy,
    page,
    pageSize
  ) {
    return await axios
      .get(API_URL + 'users/getUsersByCustomer', {
        params: {
          customerId: customerId,
          isActive: filterByActivityStatus,
          sortBy: sortBy,
          index: page ?? 0,
          size: pageSize ?? 50,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateUsersListCustomersPage', response.data.items);
        store.commit('user/updatePaginationCustomers', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  // currently not in use
  getAllCountries() {
    return axios
      .get(API_URL + 'customers/getAllCountries', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateCountriesList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async getAllSalutations() {
    return await axios
      .get(API_URL + 'users/getAllSalutations', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateSalutationsList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async getLanguageCodes() {
    return await axios
      .get(API_URL + 'users/getAllLanguageCodes', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateLanguageCodesList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  getAllCustomers() {
    return axios
      .get(API_URL + 'users/getAllCustomers', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateCustomersList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async getCustomersDropdown() {
    return await axios
      .get(API_URL + 'users/getCustomersDropdown', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateCustomersDropdownList', response.data);

        // There is literally no way to do make name-value pair work properly in "q-select" tag
        // So I have this hack to click on this dropdown, because aparently that makes it load name of the item and not the id
        // Then I deselect it 10 miliseconds after. If you find a better way please do remove this and fix "UsersEditTabs.vue"
        // I have already lost 2 hours trying to make this work.
        document.getElementById('customersDropdownId').click();
        setTimeout(function() {
          document
            .getElementById('customersDropdownId')
            .parentNode.parentNode.click();
        }, 10);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async getNewsletterFrequencyOptions() {
    return await axios
      .get(API_URL + 'users/getNewsletterFrequencyOptions', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit(
          'user/updateNewsletterFrequencyOptionsList',
          response.data
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async getNewsletterFrequencyType(userId) {
    return await axios
      .get(API_URL + 'users/getFrequencyType', {
        headers: authHeader(),
        params: {
          userId: userId,
        },
      })
      .then((response) => {
        store.commit('user/updateNewsletterFrequency', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async getAllNonRelatedRoles(userId) {
    return await axios
      .get(API_URL + `users/getNonRelatedRoles?userId=${userId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateRolesList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async requestResetPassword(id) {
    return axios
      .post(
        API_URL + 'users/requestResetPassword',
        {
          id: id,
        },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        store.commit('user/setPasswordResetSuccessModal', true);
      })
      .catch((error) => {
        if (error)
          store.commit('user/updateModalError', error.response.data),
            store.commit('user/enableModalError');
      });
  }

  async sendPasswordSetupMail(selectedUserId) {
    return await axios
      .post(
        API_URL + 'users/sendPasswordSetupMail',
        {
          id: selectedUserId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status === 200) store.commit('user/enableEmailSentDialog');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async deleteUser(id) {
    return await axios
      .post(
        API_URL + `users/deleteUser`,
        {
          id: id,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async getFavoriteAudienceTagsById(userId) {
    return await axios
      .get(API_URL + 'Tags/GetFavoriteTagsByUserId?tagType=1', {
        headers: authHeader(),
        params: {
          userId: userId.value,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit('user/updateFavoriteAudiencesIdsArray', response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async getFavoritePurposesTagsById(userId) {
    return await axios
      .get(API_URL + 'Tags/GetFavoriteTagsByUserId?tagType=2', {
        headers: authHeader(),
        params: {
          userId: userId.value,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit('user/updateFavoritePurposesIdsArray', response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }
  //This is just hidden for now, don't remove it:
  /*
      async getFavoriteIndustriesTags() {
        return await axios
          .get(API_URL + 'Tags/GetFavoriteTags?tagType=4', {
            headers: authHeader(),
          })
          .then((response) => {
            if (response.status === 200) {
              store.commit('user/updateFavoriteIndustriesIdsArray', response.data);
            }
          })
          .catch((error) => {
            if (error.response.status === 401 || error.response.status === 403) {
              AuthService.logout();
              router.go();
            }
          });
      }
    */

  async getFavoriteLocationsTagsById(userId) {
    return await axios
      .get(API_URL + 'Tags/GetFavoriteLocationsTagsByUserId', {
        headers: authHeader(),
        params: {
          userId: userId.value,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit('user/updateSelectedFavoriteLocations', response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async getDefaultFilters() {
    return await axios
      .get(API_URL + `Tags/tags`, {
        params: {
          culture: 'nl',
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('user/updateFilterActivities', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }

  async exportUsers(
    filterSearchTerm,
    filterByActivityStatus,
    sortBy,
    descending,
    customerId
  ) {
    return await axios
      .get(API_URL + `users/exportUsers`, {
        params: {
          filterSearchTerm: filterSearchTerm,
          isActive: filterByActivityStatus,
          sortBy: sortBy,
          descending: descending,
          customerId: customerId ? customerId : null,
        },
        headers: authHeader(),
        responseType: 'blob',
        reportProgress: true,
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'user/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('user/enableModalError');
          } else {
            store.commit('user/updateModalError', error.response.data),
              store.commit('user/enableModalError');
          }
        }
      });
  }
}

export default new UserService();
