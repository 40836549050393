import { createStore } from 'vuex';
import tree from './modules/tree';
import tagsNavigation from './modules/tagsNavigation';
import customers from './modules/customers';
import organisation from './modules/organisation';
import scheme from './modules/scheme';
import auth from './modules/auth';
import user from './modules/user';
import news from './modules/news';
import newsSearch from './modules/newsSearch';
import advancedFilters from './modules/advancedFilters';
import advancedSearch from './modules/advancedSearch';
import schemeItems from './modules/schemeItems';
import generalStats from './modules/generalStats';
import userStats from './modules/userStats';
import accountManagers from './modules/accountManagers';
import linkChecker from './modules/linkChecker';
import portalStats from './modules/portalStats';
import consumptionStats from './modules/consumptionStats';
import newsSelect from './modules/newsSelect';
import salesForce from './modules/salesForce';
import onzeStats from './modules/onzeStats';
import emailTemplate from './modules/emailTemplate';
import enums from './modules/enums';

export default createStore({
  modules: {
    tree: tree,
    organisation: organisation,
    tagsNavigation: tagsNavigation,
    customers: customers,
    scheme: scheme,
    auth: auth,
    user: user,
    news: news,
    newsSearch: newsSearch,
    advancedFilters: advancedFilters,
    advancedSearch: advancedSearch,
    schemeItems: schemeItems,
    generalStats: generalStats,
    userStats: userStats,
    accountManagers: accountManagers,
    linkChecker: linkChecker,
    portalStats: portalStats,
    consumptionStats: consumptionStats,
    newsSelect: newsSelect,
    salesForce: salesForce,
    onzeStats: onzeStats,
    emailTemplate: emailTemplate,
    enums: enums,
  },
  strict: false,
  plugins: [],
});
