import axios from 'axios';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
import axiosUrl from './axios.service';
import router from '../router';
import AuthService from '../services/auth.service';

const API_URL = axiosUrl.getAxiosUrl();

class OrganisationService {
  async getOrganisationsTree() {
    return await axios
      .get(API_URL + 'organisation/getOrganisationsTree', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('organisation/updateOrganisations', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'organisation/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('organisation/enableModalError');
          }
          else {
            store.commit('organisation/updateModalError', error.response.data),
            store.commit('organisation/enableModalError');
          }
        }
      });
  }

  getOrganisationById(id) {
    return axios
      .get(API_URL + 'organisation/getOrganisation', {
        params: {
          orgId: id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('organisation/fillOrganisationDetails', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'organisation/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('organisation/enableModalError');
          }
          else {
            store.commit('organisation/updateModalError', error.response.data),
            store.commit('organisation/enableModalError');
          }
        }
      });
  }

  getParentOrganisation(id) {
    return axios
      .get(API_URL + 'organisation/getOrganisation', {
        params: {
          orgId: id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('organisation/fillParentOrganisationData', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'organisation/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('organisation/enableModalError');
          }
          else {
            store.commit('organisation/updateModalError', error.response.data),
            store.commit('organisation/enableModalError');
          }
        }
      });
  }

  async getAllOrganisationsList() {
    return await axios
      .get(API_URL + 'organisation/getAllOrganisations', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('organisation/fillOrganisationList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'organisation/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('organisation/enableModalError');
          }
          else {
            store.commit('organisation/updateModalError', error.response.data),
            store.commit('organisation/enableModalError');
          }
        }
      });
  }

  async getAllOrganisationsBasic() {
    return await axios
      .get(API_URL + 'organisation/getAllOrganisationsBasic', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('organisation/fillOrganisationListBasic', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            router.go(-1);
            store.commit(
              'organisation/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('organisation/enableModalError');
          }
          else {
            store.commit('organisation/updateModalError', error.response.data),
            store.commit('organisation/enableModalError');
          }
        }
      });
  }
  async getPaginationOrganisations(filter, page = 0, PageSize = 50) {
    return await axios
      .get(API_URL + 'organisation/getPaginationOrganisations', {
        params: {
          filter: filter,
          index: page,
          size: PageSize,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit(
          'organisation/fillOrganisationPaginationList',
          response.data
        );
        store.commit('organisation/updatePagination', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'organisation/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('organisation/enableModalError');
          }
          else {
            store.commit('organisation/updateModalError', error.response.data),
            store.commit('organisation/enableModalError');
          }
        }
      });
  }

  async editOrganisation(
    id,
    name,
    email,
    phone,
    url,
    street,
    city,
    country,
    parentOrganisationId
  ) {
    return await axios
      .post(
        API_URL + `organisation/editOrganisation`,
        {
          id: id.value,
          name: name.value,
          email: email.value,
          phone: phone.value,
          url: url.value,
          address: {
            addressLine1: street.value,
            addressLine2: city.value,
            country: country.value,
          },
          parentOrganisationId:
            parentOrganisationId.value != ''
              ? parentOrganisationId.value
              : null,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          router.push({ name: "Organisations"});
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'organisation/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('organisation/enableModalError');
          }
          else {
            store.commit('organisation/updateModalError', error.response.data),
            store.commit('organisation/enableModalError');
          }
        }
      });
  }

  async addChild(
    newNodeLabel,
    newNodeEmail,
    newNodeUrl,
    newNodePhone,
    parentNode
  ) {
    return await axios({
      method: 'post',
      url: API_URL + 'organisation/addOrganisationRelation',
      headers: authHeader(),
      data: {
        Name: newNodeLabel.value,
        Email: newNodeEmail.value,
        Url: newNodeUrl.value,
        Phone: newNodePhone.value,
        AddAsChild: true,
        ParentId: parentNode.value,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          router.go();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'organisation/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('organisation/enableModalError');
          }
          else {
            store.commit('organisation/updateModalError', error.response.data),
            store.commit('organisation/enableModalError');
          }
        }
      });
  }

  async addParent(
    newNodeLabel,
    newNodeEmail,
    newNodeUrl,
    newNodePhone,
    parentNode
  ) {
    return await axios({
      method: 'post',
      url: API_URL + 'organisation/addOrganisationRelation',
      headers: authHeader(),
      data: {
        Name: newNodeLabel.value,
        Email: newNodeEmail.value,
        Url: newNodeUrl.value,
        Phone: newNodePhone.value,
        AddAsParent: true,
        ParentId: parentNode.value,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          router.go();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'organisation/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('organisation/enableModalError');
          }
          else {
            store.commit('organisation/updateModalError', error.response.data),
            store.commit('organisation/enableModalError');
          }
        }
      });
  }

  async createNewOrganisation(newOrganisation) {
    return await axios({
      method: 'post',
      url: API_URL + 'organisation/addNewOrganisation',
      headers: authHeader(),
      data: {
        Name: newOrganisation.label,
        Email: newOrganisation.email,
        Url: newOrganisation.url,
        Phone: newOrganisation.phone,
        ParentOrganisationId: newOrganisation.parentOrganisationId,
        Address: {
          addressLine1: newOrganisation.address.street,
          addressLine2: newOrganisation.address.city,
          country: newOrganisation.address.country,
        },
      },
    })
      .then((response) => {
        if (response.status == 200) {
          router.go();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'organisation/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('organisation/enableModalError');
          }
          else {
            store.commit('organisation/updateModalError', error.response.data),
            store.commit('organisation/enableModalError');
          }
        }
      });
  }

  async copyOrganisation(id) {
    return await axios
      .post(
        API_URL + 'organisation/copyOrganisation',
        {
          id: id,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        router.push({
          name: 'OrganisationsEdit',
          params: { id: response.data.id },
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'organisation/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('organisation/enableModalError');
          } else {
            store.commit('organisation/updateModalError', error.response.data),
              store.commit('organisation/enableModalError');
          }
        }
      });
  }
}

export default new OrganisationService();
