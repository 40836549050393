import SchemesService from '../services/schemes.services';
import store from '../store/index';
import { computed } from 'vue';
class SchemesUtils {
  async searchSchemes() {
  
    const schemeStatusGroup = computed({
      get: () =>
        store.state.advancedSearch.schemeStatusGroup &&
        store.state.advancedSearch.schemeStatusGroup.length > 0 &&
        (JSON.parse(sessionStorage.getItem('schemeStatusGroup')) ==
          null ||
          (JSON.parse(sessionStorage.getItem('schemeStatusGroup')) &&
            store.state.advancedSearch.schemeStatusGroup.join() ===
              JSON.parse(
                sessionStorage.getItem('schemeStatusGroup')
              ).join()))
          ? store.state.advancedSearch.schemeStatusGroup
          : JSON.parse(sessionStorage.getItem('schemeStatusGroup')),
    });

    const audiences = computed({
      get: () =>
        store.state.advancedSearch.selectedAudiences != ''
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });
    const selectedLocations = computed({
      get: () =>
        store.state.advancedSearch.selectedLocations &&
        store.state.advancedSearch.selectedLocations.length > 0
          ? store.state.advancedSearch.selectedLocations
          : JSON.parse(sessionStorage.getItem('selectedLocations')),
    });
    const germanLocationsPaths = computed({
      get: () => store.state.advancedSearch.selectedGermanLocationsPaths,
    });

    //This is just hidden for now, don't remove it
    /*
    const industries = computed({
      get: () => store.state.advancedSearch.selectedIndustries,
    });
    */

    const purposes = computed({
      get: () =>
        store.state.advancedSearch.selectedPurposes != ''
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });
    const schemeTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedSchemeTypes != ''
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });
    const projectTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedProjectTypes != ''
          ? store.state.advancedSearch.selectedProjectTypes
          : sessionStorage.getItem('selectedProjectTypes'),
    });
    const smeString = computed({
      get: () => store.state.advancedFilters.smeString,
    });
    const applicationTermsString = computed({
      get: () => store.state.advancedFilters.termsString,
    });

    const minBudget = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : JSON.parse(sessionStorage.getItem('minBudget')),
    });

    const maxBudget = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : JSON.parse(sessionStorage.getItem('maxBudget')),
    });

    const scopesString = computed({
      get: () => store.state.advancedFilters.scopesString,
    });

    const startDate = computed({
      get: () =>
        store.state.advancedSearch.startDate != ''
          ? store.state.advancedSearch.startDate
          : JSON.parse(sessionStorage.getItem('startDate')),
    });

    const endDate = computed({
      get: () =>
        store.state.advancedSearch.endDate != ''
          ? store.state.advancedSearch.endDate
          : JSON.parse(sessionStorage.getItem('endDate')),
    });

    const searchText = computed({
      get: () =>
        store.state.advancedSearch.searchText != ''
          ? store.state.advancedSearch.searchText
          : sessionStorage.getItem('searchText'),
    });

    const organisationsInclude = computed({
      get: () =>
        store.state.advancedSearch.organisationsInclude &&
        store.state.advancedSearch.organisationsInclude.length > 0
          ? store.state.advancedSearch.organisationsInclude
          : JSON.parse(sessionStorage.getItem('organisationsInclude')),
    });

    const organisationsExclude = computed({
      get: () =>
        store.state.advancedSearch.organisationsExclude &&
        store.state.advancedSearch.organisationsExclude.length > 0
          ? store.state.advancedSearch.organisationsExclude
          : JSON.parse(sessionStorage.getItem('organisationsExclude')),
    });

    store.commit('advancedSearch/updateCurrentPage', 1);
    store.commit('advancedFilters/createSMEString');
    store.commit('advancedFilters/createScopesString');
    await SchemesService.getSearchResults(
      searchText,
      audiences,
      selectedLocations,
      germanLocationsPaths,
      //This is just hidden for now, don't remove it
      //industries,
      purposes,
      schemeTypes,
      projectTypes,
      smeString,
      applicationTermsString,
      minBudget,
      maxBudget,
      scopesString,
      organisationsInclude,
      organisationsExclude,
      startDate,
      endDate,
      schemeStatusGroup.value.join()
    );
  }
}

export default new SchemesUtils();
