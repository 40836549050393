<template>
    <div class="tree-container">
      <q-tree
      :nodes="treeNodesState"
      v-model:selected="selected"
      :ticked="tickedKeys"
       node-key="id"
      text-color="black"
      class="tree"    
      ref="tree"
      tick-strategy="leaf"
    />
  </div>
</template>

<script>
import { ref } from 'vue';


export default {
  props: {
    nodes: Array,
    ticked:Array,
  },
  components: {
  },
  setup(props) {
    const selected = ref(null);
    const tree = ref(null);
    const treeNodesState = ref(props.nodes);
    const tickedKeys = ref(props.ticked);
    return {
      treeNodesState,
        tree,
        selected,
        tickedKeys,   
    };
  },
};
</script>
