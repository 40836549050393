<template>
  <div>
    Test
  </div>
</template>

<script>
export default {
  name: 'Grants',

  setup() {},
};
</script>
