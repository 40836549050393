<template>
  <ErrorModal />
  <q-dialog
    v-model="show"
    persistent
    class="modal-general add-org-dialog customer-add"
  >
    <q-card
      style="width: 700px; min-width: 350px; max-width: 80vw; overflow: hidden; height: 530px"
    >
      <q-form ref="createCustomerFormPopup" @submit="createNewCustomer()">
        <q-card-section class="row">
          <q-icon class="icon-color-addorg" name="las la-2x la-plus-square" />
          <div class="add-org-heading bold-font">
            Add new customer
          </div>
          <div v-if="productTitle != ''" class="add-org-heading bold-font">
            &nbsp; ({{ productTitle }})
          </div>
        </q-card-section>

        <q-separator />

        <transition :name="transitionName">
          <div :key="step">
            <q-card-section class="q-pt-md" v-if="step === 1" key="step1">
              <div class="row justify-around">
                <q-input
                  v-model="customer.name"
                  label="Input name"
                  :rules="requiredFieldRule"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'name')
                  "
                />
                <q-input
                  v-model="customer.emailSuffix"
                  label="Input email-suffix"
                  :rules="[suffixRule]"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'emailSuffix')
                  "
                />
              </div>
              <div class="row justify-around">
                <q-input
                  v-model="customer.crmIdentifier"
                  label="Input CRM identifier"
                  :rules="[requiredFieldRule, crmRule]"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'crmIdentifier')
                  "
                />

                <q-select
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  v-model="customer.customerType"
                  label="Select type"
                  :options="customerTypes"
                  behavior="menu"
                  :rules="[requiredSelect]"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'customerType')
                  "
                ></q-select>
              </div>

              <div class="row justify-around">
                <q-select
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  v-model="customer.accountManager"
                  use-input
                  input-debounce="0"
                  label="Select account manager"
                  :options="accountManagerOptions"
                  behavior="menu"
                  @filter="filterAccountManagersFn"
                  option-label="fullName"
                  :rules="[requiredSelectManager]"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'accountManager')
                  "
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">
                        No results
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>

                <q-input
                  v-model="customer.debugEmail"
                  :rules="[isValidEmailEmptyAllowed]"
                  label="Debug email"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'debugEmail')
                  "
                />
              </div>

              <div class="row justify-around">
                <q-select
                  borderless
                  dense
                  bg-color="white"
                  multiple
                  style="width: 280px"
                  v-model="customer.selectedProducts"
                  label="Select product(s)"
                  :options="products"
                  behavior="menu"
                  :rules="[requiredSelect]"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'selectedProducts')
                  "
                ></q-select>

                <q-select
                  v-if="hasSuperAdminRole"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  v-model="customer.languageCode"
                  label="Language code"
                  :options="languageCodes"
                  behavior="menu"
                  :rules="[requiredSelectLangCode]"
                  option-value="id"
                  option-label="description"
                  emit-value
                  map-options
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'languageCode')
                  "
                ></q-select>
              </div>

              <q-card-actions class="add-customer-actions">
                <q-btn
                  class="orange-btn bold-font"
                  no-caps
                  label="Cancel"
                  @click="openCancelPopup()"
                />
                <q-btn
                  class="orange-btn-filled bold-font"
                  no-caps
                  label="Next"
                  @click="goToNextStep"
                />
              </q-card-actions>
            </q-card-section>
            <q-card-section class="q-pt-md" v-if="step === 2" key="step2">
              <div class="row justify-around">
                <q-input
                  v-model="customer.content.startDate"
                  :rules="requiredFieldRule"
                  label="Start date"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'content.startDate')
                  "
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="customer.content.startDate"
                          mask="DD-MM-YYYY"
                          :options="startDateOptionsFn"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
                <q-input
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  v-model="customer.content.endDate"
                  label="End date"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'content.endDate')
                  "
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="customer.content.endDate"
                          :options="optionsFn"
                          mask="DD-MM-YYYY"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>

              <div class="row justify-around">
                <q-input
                  v-model.number="customer.content.numberOfLicences"
                  type="number"
                  min="0"
                  label="Input number of licences"
                  :rules="licencesRules"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) =>
                      updateCreateCustomerField(e, 'content.numberOfLicences')
                  "
                />

                <q-checkbox
                  style="width: 280px;  align-self: baseline"
                  v-model="customer.content.isDemo"
                  label="Demo"
                  color="indigo"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'content.isDemo')
                  "
                />
              </div>

              <q-card-actions class="add-customer-actions">
                <q-btn
                  class="orange-btn bold-font"
                  no-caps
                  label="Cancel"
                  @click="openCancelPopup()"
                />
                <q-btn
                  class="orange-btn bold-font"
                  no-caps
                  @click="goToPreviousStep"
                >
                  <i class="las la-arrow-left" style="font-size: 22px"></i
                  >Previous
                </q-btn>
                <q-btn
                  v-if="step != totalSteps"
                  class="orange-btn-filled bold-font"
                  no-caps
                  label="Next"
                  @click="goToNextStep"
                />
                <q-btn
                  v-else
                  class="orange-btn-filled bold-font"
                  no-caps
                  type="submit"
                  label="Finish"
                />
              </q-card-actions>
            </q-card-section>

            <q-card-section class="q-pt-md" v-if="step === 3" key="step3">
              <div class="row justify-around">
                <q-input
                  v-model="customer.sas.sasStartDate"
                  :rules="requiredFieldRule"
                  label="Sas start date"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'sas.sasStartDate')
                  "
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="customer.sas.sasStartDate"
                          mask="DD-MM-YYYY"
                          :options="startDateOptionsFnSas"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
                <q-input
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  v-model="customer.sas.sasEndDate"
                  label="End date"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'sas.sasEndDate')
                  "
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="customer.sas.sasEndDate"
                          :options="optionsFnSas"
                          mask="DD-MM-YYYY"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="row justify-around">
                <q-select
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  v-model="customer.sas.ssoOr2Fa"
                  label="SSO/2FA"
                  :options="ssoOptions"
                  behavior="menu"
                  :rules="[requiredSelect]"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'sas.ssoOr2Fa')
                  "
                ></q-select>

                <q-input
                  v-if="customer.sas.ssoOr2Fa == 'SSO'"
                  v-model="customer.sas.tenantId"
                  label="Tenant ID"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'sas.tenantId')
                  "
                />
                <div v-else style="width: 280px"></div>
              </div>
              <div class="row justify-around" style="height: 60px">
                <q-select
                  borderless
                  bg-color="white"
                  v-model="customer.sas.reminderInterval"
                  label="Select reminder interval"
                  :options="reminderIntervalsList"
                  option-label="description"
                  option-value="id"
                  emit-value
                  map-options
                  style="width: 280px"
                  behavior="menu"
                  dense
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'sas.reminderInterval')
                  "
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">
                        No results
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>

                <q-checkbox
                  style="width: 280px; align-self: baseline"
                  v-model="customer.sas.canAllCompleteTask"
                  label="Project user can set task to finished"
                  color="indigo"
                  @update:model-value="
                    (e) =>
                      updateCreateCustomerField(e, 'sas.canAllCompleteTask')
                  "
                />
              </div>

              <div class="row justify-around">
                <q-input
                  v-model="customer.sas.emailReplyAddress"
                  label="Email reply address"
                  :rules="[isValidEmail, requiredFieldRule]"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'sas.emailReplyAddress')
                  "
                />

                <q-input
                  v-model="customer.sas.emailSignature"
                  label="Email signature"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'sas.emailSignature')
                  "
                />
              </div>
              <div class="row justify-around" style="height: 60px">
                <q-input
                  v-model="customer.sas.categorization1"
                  label="Categorization 1"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'sas.categorization1')
                  "
                />
                <q-input
                  v-model="customer.sas.categorization2"
                  label="Categorization 2"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'sas.categorization2')
                  "
                />
              </div>
              <div class="row justify-around" style="height: 60px">
                <q-checkbox
                  style="width: 280px;  align-self: baseline"
                  v-model="customer.sas.useCustomEmailAddress"
                  label="Use custom email address"
                  color="indigo"
                  @update:model-value="
                    (e) =>
                      updateCreateCustomerField(e, 'sas.useCustomEmailAddress')
                  "
                />

                <q-input
                  v-model="customer.sas.emailsSendFrom"
                  v-if="customer.sas.useCustomEmailAddress"
                  label="Email sending address"
                  :rules="[isValidEmail, requiredFieldRule]"
                  borderless
                  dense
                  bg-color="white"
                  style="width: 280px"
                  @update:model-value="
                    (e) => updateCreateCustomerField(e, 'sas.emailsSendFrom')
                  "
                />
                <div v-else style="width: 280px"></div>
              </div>
              <q-card-actions class="add-customer-actions">
                <q-btn
                  class="orange-btn bold-font"
                  no-caps
                  label="Cancel"
                  @click="openCancelPopup()"
                />
                <q-btn
                  class="orange-btn bold-font"
                  no-caps
                  @click="goToPreviousStep"
                >
                  <i class="las la-arrow-left" style="font-size: 22px"></i
                  >Previous
                </q-btn>
                <q-btn
                  v-if="step != totalSteps"
                  class="orange-btn-filled bold-font"
                  no-caps
                  label="Next"
                  @click="goToNextStep"
                />
                <q-btn
                  v-else
                  class="orange-btn-filled bold-font"
                  no-caps
                  type="submit"
                  label="Finish"
                />
              </q-card-actions>
            </q-card-section>
          </div>
        </transition>
      </q-form>
    </q-card>
  </q-dialog>

  <q-dialog
    v-model="cancelDialog"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card style="width: 300px">
      <q-card-section>
        <div>Are you sure you want to cancel?</div>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn
          no-caps
          flat
          label="No"
          class="orange-btn bold-font"
          v-close-popup
        />
        <q-btn
          no-caps
          flat
          label="Yes"
          @click="disableAdd()"
          class="orange-btn-filled bold-font"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted, computed, watch /* , reactive */ } from 'vue';
import store from '../../store/index.js';
import { useRouter } from 'vue-router';
import ErrorModal from './ErrorModal';
import CustomersService from '../../services/customers.service';
import AccountManagersService from '../../services/account.managers.service';
import moment from 'moment';
/* import EDeliveryMethod from '../../enums/EDeliveryMethod.js';
 */
export default {
  props: {
    showAdd: Boolean,
  },
  components: {
    ErrorModal,
  },
  setup(props) {
    let user = JSON.parse(localStorage.getItem('user'));
    const router = useRouter();
    const show = ref(props.showAdd);
    const step = ref(1);
    const products = ref(['Content', 'SAS']);

    const customer = computed({
      get: () => store.state.customers.createCustomerObject,
    });

    const updateCreateCustomerField = (e, fieldName) => {
      store.commit('customers/updateCreateCustomerObjectField', {
        field: fieldName,
        value: e,
      });
    };

    onMounted(async () => {
      await CustomersService.getLanguageCodes();
      await CustomersService.getCustomerTypesNew();
      await AccountManagersService.getAccountManagersWithBasicInfo();
      await CustomersService.getDeliveryMethods();
      await CustomersService.getReminderIntervals();
    });

    const reminderIntervalsList = computed({
      get: () => store.state.customers.reminderIntervalOptions,
    });

    const deliveryMethodList = computed({
      get: () => store.state.customers.deliveryMethodsList,
    });
    const languageCodes = computed({
      get: () => store.state.customers.languageCodes,
    });
    const disableAdd = () => {
      store.commit('customers/resetCreateCustomerObject');
      store.commit('customers/disableAdd');
    };

    const createNewCustomer = async () => {
      await CustomersService.createNewCustomer(customer.value);
      // reset all values
      // update store fn to reset values - 1 by 1 proci
      disableAdd();
    };

    const customerTypes = computed({
      get: () => store.state.customers.customerTypesList,
    });

    const accountManagers = computed({
      get: () => store.state.accountManagers.accountManagersBasicInfoList,
    });

    const accountManagerOptions = ref(accountManagers.value);

    const hasSuperAdminRole =
      user && user.roles
        ? user.roles.some((element) => {
            if (element.name == 'Super administrator') {
              return true;
            }

            return false;
          })
        : false;

    const cancelDialog = ref(false);

    const openCancelPopup = () => {
      cancelDialog.value = true;
    };
    const totalSteps = ref(2);
    const productTitle = ref('');

    const createCustomerFormPopup = ref(null);
    watch(customer.value, (newValue) => {
      if (newValue.selectedProducts && newValue.selectedProducts != null)
        totalSteps.value = 1 + newValue.selectedProducts.length;
    });

    const goToNextStep = async () => {
      direction.value = 'forward';

      const isValid = await createCustomerFormPopup.value.validate();
      if (isValid) {
        if (
          customer.value.selectedProducts.includes('Content') &&
          step.value == 1
        ) {
          step.value = 2;
          productTitle.value = 'Content';
        } else if (
          customer.value.selectedProducts.includes('SAS') &&
          step.value < 3
        ) {
          step.value = 3;
          productTitle.value = 'SAS';
        }
      }
    };
    const goToPreviousStep = async () => {
      direction.value = 'backward';
      if (customer.value.selectedProducts.length == 1) step.value = 1;
      else if (customer.value.selectedProducts.length == 2) step.value -= 1;

      if (step.value == 1) productTitle.value = '';
      else if (step.value == 2) productTitle.value = 'Content';
      else if (step.value == 3) productTitle.value = 'SAS';
    };

    const ssoOptions = ref(['SSO', '2FA']);
    const direction = ref('forward');

    const transitionName = computed(() =>
      direction.value === 'forward' ? 'slide-left' : 'slide-right'
    );
    return {
      productTitle,
      goToPreviousStep,
      direction,
      transitionName,
      ssoOptions,
      reminderIntervalsList,
      totalSteps,
      createCustomerFormPopup,
      goToNextStep,
      cancelDialog,
      openCancelPopup,
      products,
      step,
      updateCreateCustomerField,
      deliveryMethodList,
      languageCodes,
      user,
      hasSuperAdminRole,
      accountManagerOptions,
      accountManagers,
      show,
      customer,
      disableAdd,
      createNewCustomer,
      router,
      moment,
      customerTypes,
      optionsFn(date) {
        return (
          date >=
          moment(customer.value.content.startDate, 'DD-MM-YYYY').format(
            'YYYY/MM/DD'
          )
        );
      },
      startDateOptionsFn(date) {
        return (
          date <=
          moment(customer.value.content.endDate, 'DD-MM-YYYY').format(
            'YYYY/MM/DD'
          )
        );
      },

      startDateOptionsFnSas(date) {
        return (
          date <=
          moment(customer.value.sas.sasEndDate, 'DD-MM-YYYY').format(
            'YYYY/MM/DD'
          )
        );
      },
      optionsFnSas(date) {
        return (
          date >=
          moment(customer.value.sas.sasStartDate, 'DD-MM-YYYY').format(
            'YYYY/MM/DD'
          )
        );
      },
      requiredFieldRule: [
        (val) => (val && val.length > 0) || 'Field is required!',
      ],
      isValidEmail(val) {
        const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || 'Ongeldig e-mailadres';
      },
      isValidEmailEmptyAllowed(val) {
        const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return (
          emailPattern.test(val) || val.length == 0 || 'Ongeldig e-mailadres'
        );
      },
      suffixRule(val) {
        const hasAtChar = /^@/;
        return (
          (!hasAtChar.test(val) && val.length > 0) ||
          'Field is required: please fill without @'
        );
      },
      crmRule(val) {
        return val.length > 5 || ' ID not valid. Please fill SF ID';
      },
      requiredSelect(val) {
        if (val == null || val.length == 0) return 'Field is required';
      },
      requiredSelectLangCode(val) {
        if (
          val == null &&
          hasSuperAdminRole.value &&
          hasSuperAdminRole.value == true
        )
          return 'Field is required';
      },
      licencesRules: [
        (val) =>
          (val !== null && val !== '') || 'Please type number of licences!',
        (val) => val >= 0 || 'Please type a real number of licences',
      ],
      filterAccountManagersFn(val, update) {
        update(() => {
          if (val === '') {
            accountManagerOptions.value = accountManagers.value;
          } else {
            const needle = val.toLowerCase();

            accountManagerOptions.value = accountManagers.value.filter(
              (v) => v.fullName.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      requiredSelectManager(val) {
        if (val.id == null) return 'Field is required';
      },
    };
  },
};
</script>
<style>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.4s ease-in-out, opacity 0.3s;
}

.slide-left-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-left-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.4s ease-in-out, opacity 0.3s;
}

.slide-right-enter-from {
  transform: translateX(100%);
  opacity: 0;
}
.slide-right-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.step-container {
  min-height: 150px;
}
</style>
