<template>
  <ErrorModal />
  <AddUser v-if="showAdd === true" :showAdd="showAdd" />
  <div class="q-pa-md">
    <q-table
      class="sticky-header"
      table-header-style="background-color: #0a1f8f"
      table-header-class="text-white"
      title="Users"
      :rows="usersList"
      :columns="columns"
      row-key="id"
      :filter="filter"
      :loading="loading"
      :dense="$q.screen.lt.md"
      :filter-method="customFilter"
      v-model:pagination="pagination"
      @request="onRequest"
      binary-state-sort
    >
      <template v-slot:top>
        <q-btn
          no-caps
          class="orange-btn-filled bold-font"
          :disable="loading"
          label="Add new user"
          @click="enableAdd"
          dense
        >
          <i class="las la-2x la-plus-circle la-fw"></i>
        </q-btn>
        <q-space />
        <q-toggle
          v-model="filter.filterToggleActive"
          val="active"
          label="Show active users"
          size="xs"
          color="indigo-10"
        />
        <q-toggle
          v-model="filter.filterToggleInactive"
          val="inactive"
          label="Show inactive users"
          size="xs"
          color="indigo-10"
        />
        <q-space />
        <q-input
          borderless
          dense
          debounce="300"
          color="primary"
          placeholder="Search"
          v-model="filter.searchTerm"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
        <q-space />
        <q-btn
          v-if="usersList.length > 0"
          no-caps
          class="orange-btn bold-font"
          :disable="loading"
          label="Export users"
          @click="exportUsers"
          dense
        >
          <i class="las la-2x la-file-download la-fw"></i>
        </q-btn>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="username" :props="props">{{ props.row.username }}</q-td>
          <q-td key="firstName" :props="props">{{
            props.row.person.firstName
          }}</q-td>
          <q-td key="lastName" :props="props">{{
            props.row.person.lastName
          }}</q-td>
          <q-td key="lastLogin" :props="props">
            <span>{{ formatDate(props.row.lastLogin) }}</span>
          </q-td>
          <q-td key="department" :props="props">{{
            props.row.person.department
          }}</q-td>
          <q-td key="customer" :props="props">{{
            props.row.person.customer
          }}</q-td>
          <q-td key="customerActivityStatus" :props="props">
            <span v-if="props.row.person.customerActivityStatus == true"
              >Yes</span
            >
            <span v-else>No</span>
          </q-td>
          <q-td key="roles" :props="props">{{
            props.row.roles.map((role) => role.name).join(', ')
          }}</q-td>
          <q-td key="isActive" :props="props">
            <q-checkbox
              disable
              v-model="props.row.person.isActive"
              color="primary"
            />
          </q-td>
          <q-td key="startDate" :props="props">
            <span>{{ formatDate(props.row.person.createdDate) }}</span>
          </q-td>
          <q-td key="endDate" :props="props">
            <span
              v-if="
                props.row.person.endDate != null &&
                  props.row.person.endDate != ''
              "
              >{{ formatDate(props.row.person.endDate) }}</span
            >
            <span
              v-if="
                props.row.person.endDate == null ||
                  props.row.person.endDate == ''
              "
              >Not specified</span
            >
          </q-td>
          <q-td key="hasPasswordToken" :props="props">
            <q-checkbox
              disable
              v-model="props.row.hasPasswordToken"
              color="positive"
            />
          </q-td>
          <q-td key="actions" :props="props" class="q-gutter-xs">
            <q-btn
              v-if="!isUserAnonymous(props.row.person)"
              color="positive"
              size="sm"
              no-caps
              @click="
                sendPasswordSetupMail(props.row.id, props.row.hasPassword)
              "
            >
              <i class="las la-2x la-mail-bulk la-fw"></i>
              <q-tooltip v-if="props.row.hasPassword">
                Send password reset mail
              </q-tooltip>
              <q-tooltip v-else>
                Send password setup mail
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="!isUserAnonymous(props.row.person)"
              color="primary"
              size="sm"
              no-caps
              @click="goToEditUser(props.row.id)"
            >
              <i class="las la-2x la-pencil-alt la-fw"></i>
              <q-tooltip>
                Edit
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="props.row.person.isActive"
              color="red"
              @click="(confirm = true), (userId = props.row.id)"
              size="sm"
              no-caps
            >
              <i class="las la-2x la-ban la-fw"></i>
              <q-tooltip>
                Deactivate
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="
                !props.row.person.isActive && !isUserAnonymous(props.row.person)
              "
              color="info"
              @click="(confirmReactivation = true), (userId = props.row.id)"
              size="sm"
              no-caps
            >
              <i class="las la-2x la-sync la-fw"></i>
              <q-tooltip>
                Reactivate
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="
                hasSuperAdminRole &&
                  !props.row.person.isActive &&
                  !isUserAnonymous(props.row.person)
              "
              size="sm"
              color="orange"
              no-caps
              @click="
                (showWarningAnonymizeDialog = true), (userId = props.row.id)
              "
            >
              <i class="las la-2x la-user-slash la-fw"></i>
              <q-tooltip>
                Anonymize
              </q-tooltip>
            </q-btn>
            <q-btn
              color="indigo-10"
              @click="(confirmDeleteUser = true), (userId = props.row.id)"
              size="sm"
              no-caps
            >
              <i class="las la-2x la-trash la-fw"></i>
              <q-tooltip>
                Remove
              </q-tooltip>
            </q-btn>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>

  <q-dialog v-model="confirm" persistent class="modal-general">
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-ban la-fw"></i>
        <span class="q-ml-sm">Do you want to deactivate this user?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          label="Deactivate"
          no-caps
          class="orange-btn-filled bold-font"
          v-close-popup
          @click="deactivateUser(userId)"
        />
        <q-btn
          label="Cancel"
          class="orange-btn bold-font"
          no-caps
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="confirmReactivation" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-sync la-fw"></i>
        <span class="q-ml-sm">Do you want to reactivate this user?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          label="Reactivate"
          no-caps
          class="btn-vind-dark"
          v-close-popup
          @click="reactivateUser(userId)"
        />
        <q-btn label="Cancel" no-caps class="btn-cancel-gray" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="showEmailSentDialog" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-check la-fw"></i>
        <span class="q-ml-sm">Email verstuurd!</span>
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          outline
          label="Ok"
          color="primary"
          @click="disableEmailSentDialog()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="confirmDeleteUser" persistent class="modal-general">
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-trash la-fw"></i>
        <span class="q-ml-sm">Do you want to remove this user?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          label="Remove"
          no-caps
          class="orange-btn-filled bold-font"
          v-close-popup
          @click="deleteUser(userId)"
        />
        <q-btn
          class="orange-btn bold-font"
          no-caps
          label="Cancel"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="showWarningAnonymizeDialog" persistent>
    <q-card style="width: 350px;">
      <q-card-section>
        <div style="color:#061356; font-weight: bold;">Anonymize user</div>
      </q-card-section>
      <q-separator />
      <q-card-section class="row items-center">
        <div style="color:#061356" class="text-h7">
          Are you sure you want to anonymize this user? There is no way to
          retrieve this information anymore!
        </div>
      </q-card-section>
      <q-card-actions class="q-mb-md" align="center">
        <q-btn
          label="Anonymize"
          no-caps
          class="orange-btn-filled bold-font"
          v-close-popup
          @click="anonymizeUser(userId)"
        />
        <q-btn
          class="orange-btn bold-font"
          no-caps
          label="Cancel"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="passwordResetSuccessModal">
    <q-card style="width: 300px">
      <q-card-section>
        <div class="text-h6">Password reset</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        Password reset mail has been sent!</q-card-section
      >

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat label="OK" @click="disablePasswordResetModal()" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, onMounted, computed, reactive } from 'vue';
import store from '../store/index.js';
import AddUser from '../components/user-components/AddUser.vue';
import ErrorModal from '../components/user-components/ErrorModal.vue';
import UserService from '../services/user.service.js';
import moment from 'moment';
import router from '../router/index';

const columns = [
  {
    name: 'username',
    required: true,
    label: 'Username',
    align: 'left',
    sortable: true,
    field: (row) => row.username,
    format: (val) => `${val}`,
  },
  {
    name: 'firstName',
    align: 'left',
    sortable: true,
    label: 'First name',
    field: (row) => row.person.firstName,
  },
  {
    name: 'lastName',
    align: 'left',
    sortable: true,
    label: 'Last name',
    field: (row) => row.person.lastName,
  },
  {
    name: 'lastLogin',
    align: 'left',
    sortable: true,
    label: 'Last login',
    field: (row) => row.lastLogin,
  },

  {
    name: 'department',
    align: 'left',
    sortable: true,
    label: 'Department',
    field: (row) => row.person.department,
  },
  {
    name: 'customer',
    align: 'left',
    sortable: true,
    label: 'Customer',
    field: (row) => row.person.customer,
  },
  {
    name: 'customerActivityStatus',
    align: 'left',
    sortable: true,
    label: 'Account active',
    field: (row) => row.person.customerActivityStatus,
  },
  { name: 'roles', align: 'left', label: 'Role(s)', field: (row) => row.roles },
  {
    name: 'isActive',
    align: 'center',
    sortable: true,
    label: 'Active',
    field: (row) => row.person.isActive,
  },
  {
    name: 'startDate',
    align: 'center',
    sortable: true,
    label: 'Start date',
    field: (row) => row.person.createdDate,
  },
  {
    name: 'endDate',
    align: 'center',
    sortable: true,
    label: 'End date',
    field: (row) => row.person.endDate,
  },
  {
    name: 'hasPasswordToken',
    align: 'center',
    sortable: true,
    label: 'Has password token',
    field: (row) => row.hasPasswordToken,
  },
  { name: 'actions', align: 'center', label: 'Actions', field: 'actions' },
];

export default {
  name: 'UsersGrid',
  components: {
    AddUser,
    ErrorModal,
  },
  setup() {
    const loading = ref(false);
    const filter = reactive({
      searchTerm: store.state.user.filter.searchTerm,
      filterToggleActive: store.state.user.filter.filterToggleActive,
      filterToggleInactive: store.state.user.filter.filterToggleInactive,
    });
    const rows = ref(usersList);

    const confirm = ref(false);
    const confirmReactivation = ref(false);
    const userId = ref(null);
    const confirmDeleteUser = ref(false);
    const showWarningAnonymizeDialog = ref(false);
    let user = JSON.parse(localStorage.getItem('user'));

    const hasSuperAdminRole =
      user && user.roles
        ? user.roles.some((element) => {
            if (element.name == 'Super administrator') {
              return true;
            }

            return false;
          })
        : false;

    const getFilterByActivityStatus = () => {
      let filterByActivityStatus = ref(null);
      //Gather toggle conditions
      if (showAll.value == false) {
        if (filter.filterToggleActive == true)
          filterByActivityStatus.value = true;
        if (filter.filterToggleInactive == true)
          filterByActivityStatus.value = false;
      } else filterByActivityStatus.value = null;

      return filterByActivityStatus.value;
    };

    const getFetchCount = () => {
      return pagination.value.rowsPerPage === 0
        ? pagination.value.rowsNumber
        : pagination.value.rowsPerPage;
    };
    const passwordResetSuccessModal = computed({
      get: () => store.state.user.passwordResetSuccessModal,
    });

    const disablePasswordResetModal = () => {
      store.commit('user/setPasswordResetSuccessModal', false);
    };

    onMounted(async () => {
      loading.value = true;
      await UserService.getPaginationUsers(
        filter.searchTerm,
        getFilterByActivityStatus(),
        pagination.value.sortBy,
        pagination.value.descending,
        pagination.value.page - 1,
        getFetchCount()
      );
      loading.value = false;
    });

    const showAll = computed({
      get: () => {
        return filter.filterToggleActive && filter.filterToggleInactive;
      },
    });

    const onRequest = async (props) => {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const fetchCount =
        rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage;

      const filterSearchTerm = props.filter.searchTerm;
      const filterByActivityStatus = ref(null);
      //Gather toggle conditions
      if (showAll.value == false) {
        if (props.filter.filterToggleActive == true)
          filterByActivityStatus.value = true;
        if (props.filter.filterToggleInactive == true)
          filterByActivityStatus.value = false;
      } else filterByActivityStatus.value = null;

      loading.value = true;
      await UserService.getPaginationUsers(
        filterSearchTerm,
        filterByActivityStatus.value,
        sortBy,
        descending,
        page - 1,
        fetchCount
      );
      store.commit('user/updatePaginationPage', props.pagination);
      loading.value = false;
    };

    const pagination = ref(
      computed({
        get: () => store.state.user.pagination,
        set: (paginationVar) =>
          store.commit('user/updatePagination', paginationVar),
      })
    );

    const usersList = computed({
      get: () => store.state.user.usersList,
    });

    const enableAdd = () => {
      store.commit('user/enableAdd');
      store.commit('user/removePrefilledCustomer');
    };

    const showAdd = computed({
      get: () => store.state.user.showAdd,
    });

    const deactivateUser = async (userId) => {
      await UserService.deactivateUser(userId);
      confirm.value = false;
      refreshUsersList();
    };

    const reactivateUser = async (userId) => {
      await UserService.reactivateUser(userId);
      confirmReactivation.value = false;
      refreshUsersList();
    };

    const refreshUsersList = async () => {
      loading.value = true;
      await UserService.getPaginationUsers(
        filter.searchTerm,
        getFilterByActivityStatus(),
        pagination.value.sortBy,
        pagination.value.descending,
        pagination.value.page - 1,
        getFetchCount()
      );
      loading.value = false;
    };

    const sendPasswordSetupMail = async (userId, hasPassword) => {
      if (!hasPassword) await UserService.sendPasswordSetupMail(userId);
      else await UserService.requestResetPassword(userId);
      refreshUsersList();
    };

    const customFilter = () => {
      return usersList.value;
    };

    const showEmailSentDialog = computed({
      get: () => store.state.user.showEmailSentDialog,
    });

    const disableEmailSentDialog = () => {
      store.commit('user/disableEmailSentDialog');
    };

    const deleteUser = async (userId) => {
      await UserService.deleteUser(userId);
      refreshUsersList();
    };

    const exportUsers = async () => {
      const { sortBy, descending } = pagination.value;

      const filterSearchTerm = filter.searchTerm;
      const filterByActivityStatus = ref(null);
      //Gather toggle conditions
      if (showAll.value == false) {
        if (filter.filterToggleActive == true)
          filterByActivityStatus.value = true;
        if (filter.filterToggleInactive == true)
          filterByActivityStatus.value = false;
      } else filterByActivityStatus.value = null;

      loading.value = true;
      await UserService.exportUsers(
        filterSearchTerm,
        filterByActivityStatus.value,
        sortBy,
        descending
      ).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        var a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.download = 'Users.xlsx';
        a.href = fileURL;
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      });
      loading.value = false;
    };

    const goToEditUser = (userId) => {
      store.commit('user/updateFilter', filter);
      router.push({ name: 'EditUser', params: { id: userId } });
    };
    const anonymizeUser = async (userId) => {
      await UserService.anonymizeUser(userId);
      refreshUsersList();
    };

    const isUserAnonymous = (person) => {
      return person?.firstName?.includes('Anonymous') || false;
    };
    return {
      disablePasswordResetModal,
      passwordResetSuccessModal,
      user,
      hasSuperAdminRole,
      isUserAnonymous,
      anonymizeUser,
      showWarningAnonymizeDialog,
      getFilterByActivityStatus,
      getFetchCount,
      goToEditUser,
      pagination,
      onRequest,
      columns,
      loading,
      filter,
      rows,
      usersList,
      enableAdd,
      showAdd,
      confirm,
      deactivateUser,
      refreshUsersList,
      userId,
      sendPasswordSetupMail,
      confirmReactivation,
      reactivateUser,
      customFilter,
      showEmailSentDialog,
      disableEmailSentDialog,
      confirmDeleteUser,
      deleteUser,
      exportUsers,
      formatDate(stringDate) {
        return moment(stringDate).format('DD-MM-YYYY');
      },
    };
  },
};
</script>
