import axios from "axios";
import authHeader from "../helpers/auth-header";
import axiosUrl from "./axios.service";
import AuthService from "../services/auth.service";
import store from "../store";

const API_URL = axiosUrl.getAxiosUrl();

class StatsService {
  async getStatsLineGraphGeneral(year, months, customerOwnerId, customerType) {
    return await axios
      .get(API_URL + "stats/getGeneralStats", {
        headers: authHeader(),
        params: {
          year: year.value,
          months: months.value + "",
          customerOwnerId: customerOwnerId.value,
          customerType: customerType.value,
        },
      })
      .then((response) => {
        store.commit("generalStats/updateChartData", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
        }
      });
  }

  async XgetStatsLineGraphGeneral(
    yearFrom,
    yearTo,
    monthFrom,
    monthTo,
    customerOwnerId,
    customerType
  ) {
    return await axios
      .get(API_URL + "stats/getGeneralStats", {
        headers: authHeader(),
        params: {
          yearFrom: yearFrom.value,
          yearTo: yearTo.value,
          monthFrom: monthFrom.value,
          monthTo: monthTo.value,
          customerOwnerId: customerOwnerId.value,
          customerType: customerType.value,
        },
      })
      .then((response) => {
        store.commit("generalStats/updateChartData", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
        }
      });
  }

  async getGeneralStatsPieChartCustomerTypes(selectedCustomerOwnerId) {
    return await axios
      .get(API_URL + "stats/getCustomerTypesPieChartData", {
        headers: authHeader(),
        params: {
          customerOwnerId:
            selectedCustomerOwnerId && selectedCustomerOwnerId != null
              ? selectedCustomerOwnerId.value
              : null,
        },
      })
      .then((response) => {
        store.commit(
          "generalStats/updateFullPieChartData",
          response.data.customersTypesCount
        );
      });
  }

  async getGeneralStatsForUsers(customerOwnerId, customerType) {
    return await axios
      .get(API_URL + "stats/getGeneralStatsUsers", {
        headers: authHeader(),
        params: {
          customerOwnerId:
            customerOwnerId && customerOwnerId.value > 0
              ? customerOwnerId.value
              : null,
          customerType:
            customerType && customerType != null ? customerType.value : null,
        },
      })
      .then((response) => {
        store.commit(
          "generalStats/updateUserStatsGeneral",
          response.data.generalUserStats
        );
      });
  }

  async getInactiveUsersGraph(
    modelYearFrom,
    modelYearTo,
    modelMonthIdFrom,
    modelMonthIdTo
  ) {
    return await axios
      .get(API_URL + "stats/getInactiveUsersGraph", {
        headers: authHeader(),
        params: {
          yearFrom: modelYearFrom.value,
          yearTo: modelYearTo.value,
          monthFrom: modelMonthIdFrom.value,
          monthTo: modelMonthIdTo.value,
        },
      })
      .then((response) => {
        store.commit(
          "generalStats/updateChartDataInactiveUsers",
          response.data
        );
      });
  }

  async getUsersStatsBoxes(customersList, selectedCustomerOwnerId, usersList) {
    return await axios
      .get(API_URL + "stats/getUsersStats", {
        headers: authHeader(),
        params: {
          selectedCustomerOwnerId:
            selectedCustomerOwnerId && selectedCustomerOwnerId.value != null
              ? selectedCustomerOwnerId.value
              : null,
          customersList:
            customersList && customersList.value.length > 0
              ? customersList.value + ""
              : null,
          usersList:
            usersList && usersList.value.length > 0
              ? usersList.value + ""
              : null,
        },
      })
      .then((response) => {
        store.commit(
          "userStats/updateUserStatsBoxes",
          response.data.userStatsBoxes
        );
      });
  }

  async getUsersTableStats(usersList, selectedCustomersIds) {
    return await axios
      .get(API_URL + "stats/getUsersTableStats", {
        headers: authHeader(),
        params: {
          usersList:
            usersList && usersList.value.length > 0
              ? usersList.value + ""
              : null,
          customersList:
            selectedCustomersIds && selectedCustomersIds.value.length > 0
              ? selectedCustomersIds.value + ""
              : null,
        },
      })
      .then((response) => {
        store.commit("userStats/updateTableStats", response.data.userStats);
      });
  }

  async getStatsLineGraphUsersGeneral(year, months, customerOwnerId) {
    return await axios
      .get(API_URL + "stats/getGeneralStats", {
        headers: authHeader(),
        params: {
          year: year.value,
          months: months.value + "",
          customerOwnerId: customerOwnerId.value,
        },
      })
      .then((response) => {
        store.commit("userStats/updateChartDataBasic", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
        }
      });
  }

  async getUserVsisitsStats(
    modelYearFrom,
    modelYearTo,
    modelMonthIdFrom,
    modelMonthIdTo,
    users,
    customerOwnerId,
    selectedCustomersIds
  ) {
    return await axios
      .get(API_URL + "stats/getUserVsisitsStats", {
        headers: authHeader(),
        params: {
          yearFrom: modelYearFrom.value,
          yearTo: modelYearTo.value,
          monthFrom: modelMonthIdFrom.value,
          monthTo: modelMonthIdTo.value,
          users: users.value + "",
          customers: selectedCustomersIds.value + "",
          customerOwnerId: customerOwnerId.value,
        },
      })
      .then((response) => {
        store.commit("userStats/updateChartDataSchemeVisits", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getUserNewsVsisitsStats(
    modelYearFrom,
    modelYearTo,
    modelMonthIdFrom,
    modelMonthIdTo,
    users,
    customerOwnerId,
    selectedCustomersIds
  ) {
    return await axios
      .get(API_URL + "stats/getUserNewsVsisitsStats", {
        headers: authHeader(),
        params: {
          yearFrom: modelYearFrom.value,
          yearTo: modelYearTo.value,
          monthFrom: modelMonthIdFrom.value,
          monthTo: modelMonthIdTo.value,
          users: users.value + "",
          customers: selectedCustomersIds.value + "",
          customerOwnerId: customerOwnerId.value,
        },
      })
      .then((response) => {
        store.commit("userStats/updateChartDataNewsVisits", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getGeneralUsersGraph(
    modelYearFrom,
    modelYearTo,
    modelMonthIdFrom,
    modelMonthIdTo,
    users,
    customerOwnerId,
    selectedCustomersIds
  ) {
    return await axios
      .get(API_URL + "stats/getGeneralUsersGraph", {
        headers: authHeader(),
        params: {
          yearFrom: modelYearFrom.value,
          yearTo: modelYearTo.value,
          monthFrom: modelMonthIdFrom.value,
          monthTo: modelMonthIdTo.value,
          users: users.value + "",
          customers: selectedCustomersIds.value + "",
          customerOwnerId: customerOwnerId.value,
        },
      })
      .then((response) => {
        store.commit("userStats/updateChartDataBasic", response.data);
      });
  }

  async getPortalBoxesData(
    yearFrom,
    yearTo,
    monthFrom,
    monthTo,
    selectedCustomersIds,
    selectedPortalUsersIds
  ) {
    return await axios
      .get(API_URL + "stats/getPortalBoxesData", {
        headers: authHeader(),
        params: {
          yearFrom: yearFrom.value,
          yearTo: yearTo.value,
          monthFrom: monthFrom.value,
          monthTo: monthTo.value,
          customers: selectedCustomersIds
            ? selectedCustomersIds.value + ""
            : null,
          users: selectedPortalUsersIds
            ? selectedPortalUsersIds.value + ""
            : null,
        },
      })
      .then((response) => {
        store.commit("portalStats/updateBoxesData", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getPortalBarGraphMain() {
    return await axios
      .get(API_URL + "stats/getPortalBarGraphMain", {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit("portalStats/updateMainPortalGraph", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getPortalUsersGraph(
    yearFrom,
    yearTo,
    monthFrom,
    monthTo,
    selectedCustomersIds,
    selectedPortalUsersIds
  ) {
    return await axios
      .get(API_URL + "stats/getPortalUsersGraph", {
        headers: authHeader(),
        params: {
          yearFrom: yearFrom.value,
          yearTo: yearTo.value,
          monthFrom: monthFrom.value,
          monthTo: monthTo.value,
          customers: selectedCustomersIds
            ? selectedCustomersIds.value + ""
            : null,
          users: selectedPortalUsersIds
            ? selectedPortalUsersIds.value + ""
            : null,
        },
      })
      .then((response) => {
        store.commit("portalStats/updateChartDataUsers", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getPortalVisitsGraph(
    yearFrom,
    yearTo,
    monthFrom,
    monthTo,
    selectedCustomersIds,
    selectedPortalUsersIds
  ) {
    return await axios
      .get(API_URL + "stats/getPortalVisitsGraph", {
        headers: authHeader(),
        params: {
          yearFrom: yearFrom.value,
          yearTo: yearTo.value,
          monthFrom: monthFrom.value,
          monthTo: monthTo.value,
          customers: selectedCustomersIds
            ? selectedCustomersIds.value + ""
            : null,
          users: selectedPortalUsersIds
            ? selectedPortalUsersIds.value + ""
            : null,
        },
      })
      .then((response) => {
        store.commit("portalStats/updateChartDataVisits", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getPortalSchemeVisitsTable(
    yearFrom,
    yearTo,
    monthFrom,
    monthTo,
    customers,
    selectedPortalUsersIds
  ) {
    return await axios
      .get(API_URL + "stats/getPortalSchemeVisitsTable", {
        headers: authHeader(),
        params: {
          yearFrom: yearFrom.value,
          yearTo: yearTo.value,
          monthFrom: monthFrom.value,
          monthTo: monthTo.value,
          customers: customers ? customers.value + "" : null,
          users: selectedPortalUsersIds
            ? selectedPortalUsersIds.value + ""
            : null,
        },
      })
      .then((response) => {
        store.commit(
          "portalStats/updateSchemeVisitsBarData",
          response.data.schemeVisits
        );
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getPortalUsersTableExportable(selectedCustomersIds) {
    return await axios
      .get(API_URL + "stats/getPortalUsersTableExportable", {
        headers: authHeader(),
        params: {
          customers: selectedCustomersIds
            ? selectedCustomersIds.value + ""
            : null,
        },
      })
      .then((response) => {
        store.commit(
          "portalStats/updatePortalUsersTableExportable",
          response.data.users
        );
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }
  async exportGeneralStatsData() {
    return await axios
      .get(API_URL + "functionalitiesUse/exportGeneralStatsData", {
        headers: authHeader(),
        responseType: "blob",
        reportProgress: true,
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getPortalSchemePurposesCounts(
    yearFrom,
    yearTo,
    monthFrom,
    monthTo,
    customers,
    selectedPortalUsersIds
  ) {
    return await axios
      .get(API_URL + "stats/getPortalStatsPurposes", {
        headers: authHeader(),
        params: {
          yearFrom: yearFrom.value,
          yearTo: yearTo.value,
          monthFrom: monthFrom.value,
          monthTo: monthTo.value,
          customers: customers ? customers.value + "" : null,
          users: selectedPortalUsersIds
            ? selectedPortalUsersIds.value + ""
            : null,
        },
      })
      .then((response) => {
        store.commit(
          "portalStats/updatePortalSchemePurposesData",
          response.data.purposes
        );
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  // Consumption stats
  async getConsumptionStatsPurposes(
    yearFrom,
    yearTo,
    monthFrom,
    monthTo,
    customers
  ) {
    return await axios
      .get(API_URL + "stats/getConsumptionStatsPurposes", {
        headers: authHeader(),
        params: {
          yearFrom: yearFrom.value,
          yearTo: yearTo.value,
          monthFrom: monthFrom.value,
          monthTo: monthTo.value,
          customers: customers ? customers.value + "" : null,
        },
      })
      .then((response) => {
        store.commit(
          "consumptionStats/updateConsumptionSchemePurposesData",
          response.data.purposes
        );
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getNewAndEditSchemesCount(year, months) {
    return await axios
      .get(API_URL + "stats/getNewAndEditSchemesCount", {
        headers: authHeader(),
        params: {
          year: year.value,
          months: months ? months.value + "" : null,
        },
      })
      .then((response) => {
        store.commit(
          "consumptionStats/updateNewAndEditedSchemesCounts",
          response.data
        );
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getNewPublishedNews(year, months) {
    return await axios
      .get(API_URL + "stats/getNewPublishedNews", {
        headers: authHeader(),
        params: {
          year: year.value,
          months: months ? months.value + "" : null,
        },
      })
      .then((response) => {
        store.commit("consumptionStats/updateNewsBarData", response.data.news);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }
  async getKeywordSearchTableConsumption() {
    return await axios
      .get(API_URL + "stats/getKeywordSearchTableConsumption", {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit(
          "consumptionStats/updateKeywordSearchTable",
          response.data
        );
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }
  async getSchemeVisitsTableConsumption() {
    return await axios
      .get(API_URL + "stats/getSchemeVisitsTableConsumption", {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit("consumptionStats/updateSchemeVisitsTable", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getNewsVisitsTableConsumption() {
    return await axios
      .get(API_URL + "stats/getNewsVisitsTableConsumption", {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit("consumptionStats/updateNewsVisitsTable", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getSchemesNewsVisitsConsumption() {
    return await axios
      .get(API_URL + "stats/getSchemesNewsVisitsConsumption", {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit("consumptionStats/updateChartData", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getSchemesAndNewsConsumptionGraph() {
    return await axios
      .get(API_URL + "stats/getSchemesAndNewsConsumptionGraph", {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit(
          "consumptionStats/updateSchemesAndNewsBarData",
          response.data
        );
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getGeneralPortalStats() {
    return await axios
      .get(API_URL + "stats/getGeneralPortalStats", {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit("generalStats/updateGeneralPortalStats", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  // Onze stats

  async getOnzeBoxesData(consultantIds, team) {
    return await axios
      .get(API_URL + "stats/getOnzeBoxesData", {
        headers: authHeader(),
        params: {
          consultants: consultantIds ? consultantIds + "" : null,
          team: team ? team : "",
        },
      })
      .then((response) => {
        store.commit("onzeStats/updateBoxesData", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
        }
      });
  }

  async getOnzeBar(consultantIds, team) {
    return await axios
      .get(API_URL + "stats/getOnzeBarGraph", {
        headers: authHeader(),
        params: {
          consultants: consultantIds ? consultantIds + "" : null,
          team: team ? team : "",
        },
      })
      .then((response) => {
        store.commit("onzeStats/updateBarData", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
        }
      });
  }

  async getOnzeTable(consultantIds, team) {
    return await axios
      .get(API_URL + "stats/getOnzeTableData", {
        headers: authHeader(),
        params: {
          consultants: consultantIds ? consultantIds + "" : null,
          team: team ? team : "",
        },
      })
      .then((response) => {
        store.commit("onzeStats/updateTableData", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
        }
      });
  }

  async getOnzeConsultants(team) {
    return await axios
      .get(API_URL + "stats/getConsultants", {
        headers: authHeader(),
        params: {
          team: team,
        },
      })
      .then((response) => {
        store.commit("onzeStats/updateConsultants", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
        }
      });
  }

  async getDepartments() {
    return await axios
      .get(API_URL + "stats/getDepartments", {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit("onzeStats/updateDepartments", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
        }
      });
  }

  async getOBData(selectedTeam) {
    return await axios
      .get(API_URL + "stats/getOBData", {
        headers: authHeader(),
        params: {
          department:
            selectedTeam && selectedTeam != null ? selectedTeam.value : null,
        },
      })
      .then((response) => {
        store.commit("onzeStats/updateOnzeBarData", response.data);
      });
    /*   .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      }); */
  }

  async getPortalUsers() {
    return await axios
      .get(API_URL + "users/getPortalUsers", {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit("portalStats/updatePortalUsers", response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
        }
      });
  }
}

export default new StatsService();
