import { createWebHistory, createRouter } from 'vue-router';
import Tags from '@/views/Tags.vue';
import Grants from '@/views/Grants.vue';
import News from '@/views/News.vue';
import CustomersGrid from '@/views/CustomersGrid.vue';
import EditCustomer from '@/components/customers-components/EditCustomer.vue';
import Organisations from '@/views/Organisations.vue';
import SchemeDetails from '@/views/SchemeDetails.vue';
import Login from '@/views/Login.vue';
import CreateSchemePage from '@/views/CreateScheme.vue';
import UsersGrid from '@/views/UsersGrid.vue';
import EditUser from '@/components/user-components/EditUser.vue';
import EditScheme from '@/components/schemes-components/EditScheme.vue';
import NewsDetails from '@/views/NewsDetails.vue';
import EditNews from '../components/news-components/EditNews.vue';
import AddNews from '@/views/CreateNews.vue';
import GrantDetails from '@/views/GrantDetails.vue';
import OrganisationsEdit from '@/components/organisation-components/OrganisationsEditPage.vue';
import SchemesSearchPage from '@/components/search-components/SchemesSearchPage.vue';
import SchemesResultsPage from '@/views/SchemesResultsPage.vue';
import GeneralStats from '@/components/stats/GeneralStats.vue';
import UserStats from '@/components/stats/UserStats.vue';
import AccountManagersGrid from '@/views/AccountManagersGrid.vue';
import VisualPingLinks from '@/components/link-checker-components/VisualPingLinks.vue';
import PortalStats from '@/components/stats/PortalStats.vue';
import ConsumptionStats from '@/components/stats/ConsumptionStats.vue';
import OidcAuth from '@/views/OidcAuth.vue';
import OidcSignOut from '@/views/OidcSignOut.vue';
import NewsSelectPage from '@/views/NewsSelectPage.vue';
import store from '../store';
import SalesForce from '@/views/SalesForce.vue';
import SalesForceCallback from '@/views/SalesForceCallback.vue';
import NewsSelectFinal from '@/components/news-select-components/NewsSelectFinal.vue';
import NewsSelectHome from '@/components/news-select-components/NewsSelectHome.vue';
import NewsForCustomersFinal from '@/components/news-select-components/NewsForCustomersFinal.vue';
import OBStats from '@/components/stats/OBStats.vue';
import EditEmailTemplates from '@/views/EditEmailTemplates.vue';

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/admin/grants',
    name: 'Grants',
    component: Grants,
  },
  {
    path: '/admin/news',
    name: 'News',
    component: News,
  },
  {
    path: '/admin/tags',
    name: 'Tags',
    component: Tags,
  },
  {
    path: '/admin/tags/:tab',
    name: 'TagTab',
    component: Tags,
  },
  {
    path: '/admin/customers',
    name: 'CustomersGrid',
    component: CustomersGrid,
  },
  {
    path: '/admin/organisations',
    name: 'Organisations',
    component: Organisations,
  },
  {
    path: '/admin/customer/edit/:id',
    name: 'EditCustomer',
    component: EditCustomer,
  },
  {
    path: '/admin/scheme/edit/:id',
    name: 'EditScheme',
    component: EditScheme,
  },
  {
    path: '/admin/news/details/:id',
    name: 'NewsDetails',
    component: NewsDetails,
  },
  {
    path: '/admin/news/edit/:id',
    name: 'NewsEdit',
    component: EditNews,
  },
  {
    path: '/admin/edit-templates',
    name: 'EditEmailTemplates',
    component: EditEmailTemplates,
  },
  {
    path: '/admin/users',
    name: 'UsersGrid',
    component: UsersGrid,
  },
  {
    path: '/admin/stats/general',
    name: 'GeneralStats',
    component: GeneralStats,
  },
  {
    path: '/admin/stats/user',
    name: 'UserStats',
    component: UserStats,
  },
  {
    path: '/admin/stats/portal',
    name: 'PortalStats',
    component: PortalStats,
  },
  {
    path: '/admin/stats/consumption',
    name: 'ConsumptionStats',
    component: ConsumptionStats,
  },
  {
    path: '/admin/users/edit/:id',
    name: 'EditUser',
    component: EditUser,
  },
  {
    path: '/admin/scheme/details/:id',
    name: 'SchemeDetails',
    component: SchemeDetails,
  },
  {
    path: '/admin/scheme/create-scheme',
    name: 'CreateScheme',
    component: CreateSchemePage,
  },
  {
    path: '/admin/scheme/:schemeId/details',
    name: 'GrantDetails',
    component: GrantDetails,
  },
  {
    path: '/admin/news/add',
    name: 'AddNews',
    component: AddNews,
  },
  {
    path: '/admin/organisation/edit/:id',
    name: 'OrganisationsEdit',
    component: OrganisationsEdit,
  },
  {
    path: '/admin/schemes/search',
    name: 'SchemesSearchPage',
    component: SchemesSearchPage,
  },
  {
    path: '/admin/schemes/search-results/:query?',
    name: 'SchemesResultsPage',
    component: SchemesResultsPage,
  },
  {
    path: '/admin/account-managers',
    name: 'AccountManagersGrid',
    component: AccountManagersGrid,
  },
  {
    path: '/admin/visualping-links',
    name: 'VisualPingLinks',
    component: VisualPingLinks,
  },
  {
    path: '/oidc-auth',
    name: 'OidcAuth',
    component: OidcAuth,
  },
  {
    path: '/oidc-signout',
    name: 'OidcSignOut',
    component: OidcSignOut,
  },
  {
    path: '/admin/news-select',
    name: 'NewsSelectPage',
    component: NewsSelectPage,
  },
  {
    path: '/salesforce',
    name: 'SalesForce',
    component: SalesForce,
  },
  {
    path: '/salesforce/callback',
    name: 'SalesForceCallback',
    component: SalesForceCallback,
  },
  {
    path: '/admin/news-select/finish',
    name: 'NewsSelectFinal',
    component: NewsSelectFinal,
  },
  {
    path: '/admin/news-select/home',
    name: 'NewsSelectHome',
    component: NewsSelectHome,
  },
  {
    path: '/admin/news-select/news-finish',
    name: 'NewsForCustomersFinal',
    component: NewsForCustomersFinal,
  },
  {
    path: '/admin/stats/onze',
    name: 'OnzeStats',
    component: OBStats,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/', '/oidc-auth', '/oidc-signout'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (to.name !== 'EditUser' && to.name !== 'UsersGrid')
    store.commit('user/updateFilter', {
      searchTerm: '',
      filterToggleActive: true,
      filterToggleInactive: true,
    });

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
