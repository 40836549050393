<template>
  <div class="scheme-visits-table">
    <q-table
      class="my-sticky-header-table"
      :rows="tableStatsRows.news"
      :columns="columns"
      row-key="name"
      flat
      bordered
      :separator="'cell'"
      :pagination="pagination"
      style="max-heigt: 300px"
      virtual-scroll
      :virtual-scroll-sticky-size-start="30"
      :virtual-scroll-sticky-size-end="30"
      binary-state-sort
      :rows-per-page-options="[]"
    />
  </div>
</template>

<script>
import store from '../../../store/index';
import { computed } from 'vue';
import moment from 'moment';

export default {
  setup() {
    const tableStatsRows = computed({
      get: () => store.state.consumptionStats.newsVisitsTableData,
    });

    const columns = [
      {
        name: 'newsTitle',
        field: 'newsTitle',
        label: 'News title',
        align: 'left',
      },
      {
        name: 'visitCount',
        label: 'Visits in last 30 days',
        field: 'visitCount',
        align: 'left',
        sortable: true,
      },
      {
        name: 'publicationDate',
        field: 'publicationDate',
        label: 'Publication date',
        align: 'left',
        format: (val) =>
          val != null ? moment(val.split('GMT')[0]).format('DD-MM-YYYY') : null,
      },
    ];

    return {
      columns,
      tableStatsRows,
      pagination: {
        rowsPerPage: 500,
        page: 1,
      },
      formatDate(stringDate) {
        if (stringDate != null)
          return moment(stringDate.split('GMT')[0]).format('DD-MM-YYYY');
      },
    };
  },
};
</script>

<style lang="sass">
.my-sticky-header-table
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    background-color: #25408f

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  &.q-table--loading thead tr:last-child th
    top: 48px
</style>
