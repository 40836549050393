<template>
  <div class="filter-box statuses-checkbox">
    <span class="filter-box-heading">Statuses</span>
    <q-option-group
          v-model="schemeStatusGroup"
          :options="schemeStatusOptions"
          type="checkbox"
        />
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import store from '../../store/index';
import SchemesServices from '../../services/schemes.services';
export default {
  setup() {
    onMounted(() => {
      SchemesServices.getSchemeStatuses();
    });
    const onCheck = () => {};

    const schemeStatusOptions = computed({
      get: () => store.state.scheme.schemeStatuses
    });
    const schemeStatusGroup = computed({
      get: () =>
        store.state.advancedSearch.schemeStatusGroup &&
        store.state.advancedSearch.schemeStatusGroup.length > 0 &&
        (JSON.parse(sessionStorage.getItem('schemeStatusGroup')) ==
          null ||
          (JSON.parse(sessionStorage.getItem('schemeStatusGroup')) &&
            store.state.advancedSearch.schemeStatusGroup.join() ===
              JSON.parse(
                sessionStorage.getItem('schemeStatusGroup')
              ).join()))
          ? store.state.advancedSearch.schemeStatusGroup
          : JSON.parse(sessionStorage.getItem('schemeStatusGroup')),
      set: (value) => {
        store.commit('advancedSearch/updateSchemeStatusGroup', value);
      },
    });

    return {
      group: ref([]),
      onCheck,
      schemeStatusOptions,
      schemeStatusGroup,
    };
  },
};
</script>

<style></style>
