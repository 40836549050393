<template>
  <div class="consumption-stats-page">
    <SchemesNewsModal />
    <ExportModal />
    <div class="row page-heading">
      <h2 class="title">
        Consumption
      </h2>
      <div class="row">
        <router-link :to="{ name: 'GeneralStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('general')">
            General stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'UserStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('user')">
            User stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'PortalStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('portal')">
            Portal stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'ConsumptionStats' }" class="page-link">
          <q-btn
            flat
            class="btn-navigation"
            :class="isUnderlined('consumption')"
          >
            Consumption stats
          </q-btn>
        </router-link>
    <!--     <router-link :to="{ name: 'OnzeStats' }" class="page-link">
          <q-btn flat class="btn-navigation" :class="isUnderlined('onze')">
            ONZE stats
          </q-btn>
        </router-link> -->
        <div class="export q-ml-md">
          <q-btn
            style="margin-top: 20px"
            label="Export data"
            class="orange-btn"
            no-caps
            @click="showExportModal()"
            dense
          >
            <i class="las la-2x la-file-download la-fw"></i>
          </q-btn>
        </div>
      </div>
    </div>
    <SchemeAndNewsBar class="q-mb-lg" />
    <div class="page-main  justify-center">
      <div class="data-container">
        <div class="tables-container row no-wrap justify-between q-mt-xl">
          <div class="column table-container">
            <SchemeVisitsTable />
            <p
              class="see-all-btn"
              v-if="
                schemeTableData.schemes && schemeTableData.schemes.length > 0
              "
              @click="enableModalSchemes()"
            >
              See all
            </p>
          </div>

          <div class="column table-container q-mx-xl">
            <NewsVisitsTable />
            <p
              class="see-all-btn"
              v-if="newsTableData.news && newsTableData.news.length > 0"
              @click="enableModalNews()"
            >
              See all
            </p>
          </div>
          <div class="column table-container">
            <KeywordSearchTable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import statsService from '../../services/stats.service';
import store from '../../store/index';
import SchemeVisitsTable from '../stats/Consumption/SchemeVisitsTable.vue';
import NewsVisitsTable from '../stats/Consumption/NewsVisitsTable.vue';
import KeywordSearchTable from './Consumption/KeywordSearchTable.vue';
import SchemeAndNewsBar from '../stats/Consumption/SchemeAndNewsBar.vue';
import SchemesNewsModal from '../stats/Consumption/SchemeAndNewsModalTable.vue';
import ExportModal from '../stats/Consumption/ExportModal.vue';
import customersService from '../../services/customers.service';
import { useRoute } from 'vue-router';
import moment from 'moment';
export default {
  name: 'ConsumptionStats',
  components: {
    SchemeVisitsTable,
    NewsVisitsTable,
    KeywordSearchTable,
    SchemeAndNewsBar,
    SchemesNewsModal,
    ExportModal,
  },
  setup() {
    const isLoading = ref(false);
    const isShowing = ref(false);
    const route = useRoute();

    onMounted(async () => {
      store.commit('consumptionStats/updateYearsOptions');
      let thisMonth = new Date().getMonth();
      let date1 = new Date();
      let date2 = new Date();
      let date3 = new Date();
      thisMonth;
      date1.setMonth(thisMonth - 1, 0);
      date2.setMonth(thisMonth, 0);
      date3.setMonth(thisMonth + 1, 0);
      if (date3.getMonth() == 0 || date3.getMonth() == 1) {
        store.commit('consumptionStats/updateYearFrom', moment().year() - 1);
      }
      store.commit('consumptionStats/updateYearsToOptions');

      store.commit('consumptionStats/updateMonthTo', {
        id: date3.getMonth(),
        name: date3.toLocaleString('nl-NL', { month: 'long' }),
      });

      store.commit('consumptionStats/updateMonthFrom', {
        id: date1.getMonth(),
        name: date1.toLocaleString('nl-NL', { month: 'long' }),
      });

      await statsService.getSchemeVisitsTableConsumption();
      await statsService.getNewsVisitsTableConsumption();
      await statsService.getSchemesAndNewsConsumptionGraph();
      await statsService.getKeywordSearchTableConsumption();

      await customersService.getNonPortalCustomers();
    });

    const enableModalSchemes = () => {
      store.commit(
        'consumptionStats/updateModalDataSchemes',
        schemeTableData.value
      );
      store.commit('consumptionStats/updateShowDataModal', true);
    };
    const enableModalNews = () => {
      store.commit('consumptionStats/updateModalDataNews', newsTableData.value);
      store.commit('consumptionStats/updateShowDataModal', true);
    };

    const graphFilters = async () => {
      isShowing.value = true;
      isLoading.value = true;

      isLoading.value = false;
    };
    const schemeTableData = computed({
      get: () => store.state.consumptionStats.schemeVisitsTableData,
    });

    const newsTableData = computed({
      get: () => store.state.consumptionStats.newsVisitsTableData,
    });

    const showExportModal = () => {
      store.commit('consumptionStats/updateShowExportModal', true);
    };

    const setInput = () => {
      customersMultiselect.value.updateInputValue('');
    };
    const customersMultiselect = ref(null);

    const selectedMonthsIds = computed({
      get: () => store.state.consumptionStats.selectedMonthsIds,
    });

    const selectedCustomersIds = computed({
      get: () => store.state.consumptionStats.selectedCustomersIds,
    });

    const customersList = computed({
      get: () => store.state.consumptionStats.customersList,
    });
    const selectedCustomers = computed({
      get: () => store.state.consumptionStats.selectedCustomers,
      set: async (val) => {
        store.commit('consumptionStats/updateSelectedCustomers', val);
      },
    });
    const filteredCustomersList = ref(customersList.value);
    const selectedMonthsIdsServer = computed({
      get: () => store.state.consumptionStats.selectedMonthsIdsServer,
    });

    const modelYearFrom = computed({
      get: () => store.state.consumptionStats.yearFrom,
      set: async (val) => {
        store.commit('consumptionStats/updateYearFrom', val);
        store.commit('consumptionStats/updateYearsToOptions');
      },
    });

    const modelYearTo = computed({
      get: () => store.state.consumptionStats.yearTo,
      set: async (val) => {
        store.commit('consumptionStats/updateYearTo', val);
      },
    });

    const modelMonthFrom = computed({
      get: () => store.state.consumptionStats.monthFrom,
      set: async (val) => {
        store.commit('consumptionStats/updateMonthFrom', val);
      },
    });

    const modelMonthTo = computed({
      get: () => store.state.consumptionStats.monthTo,
      set: async (val) => {
        store.commit('consumptionStats/updateMonthTo', val);
      },
    });

    const optionsYearsFrom = computed({
      get: () => store.state.consumptionStats.optionsYearsFrom,
    });

    const optionsYearsTo = computed({
      get: () => store.state.consumptionStats.optionsYearsTo,
    });

    return {
      route,
      modelYearFrom,
      isLoading,
      isShowing,
      modelYearTo,
      modelMonthFrom,
      modelMonthTo,
      optionsYearsFrom,
      optionsYearsTo,
      selectedMonthsIdsServer,
      filteredCustomersList,
      customersList,
      selectedCustomers,
      selectedMonthsIds,
      selectedCustomersIds,
      customersMultiselect,
      setInput,
      showExportModal,
      schemeTableData,
      graphFilters,
      newsTableData,
      enableModalSchemes,
      enableModalNews,
      optionsMonths: [
        { id: 0, name: 'Januari' },
        { id: 1, name: 'Februari' },
        { id: 2, name: 'Maart' },
        { id: 3, name: 'April' },
        { id: 4, name: 'Mei' },
        { id: 5, name: 'Juni' },
        { id: 6, name: 'Juli' },
        { id: 7, name: 'Augustus' },
        { id: 8, name: 'September' },
        { id: 9, name: 'Oktober' },
        { id: 10, name: 'November' },
        { id: 11, name: 'December' },
      ],
      filterCustomersFn(val, update) {
        update(() => {
          if (val === '') {
            filteredCustomersList.value = customersList.value;
          } else {
            const needle = val.toLowerCase();

            filteredCustomersList.value = customersList.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
    };
  },
  methods: {
    isUnderlined(buttonRoute) {
      if (this.route.fullPath.includes(buttonRoute))
        return 'btn-nav-underlined';
      return '';
    },
  },
};
</script>
