<template>
  <div class="news-details-page">
    <h5 class="details-title">{{ newsItem.title }}</h5>
    <div class="top-infosection">
      <div class="infobox">
        <q-input
          class="date"
          label="Date"
          stack-label
          outlined
          bg-color="white"
          v-model="date"
          readonly
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer"> </q-icon>
          </template>
        </q-input>
      </div>
      <div class="infobox">
        <q-input
          class="input-readonly"
          label="Title"
          stack-label
          outlined
          bg-color="white"
          v-model="newsItem.title"
          readonly
        >
        </q-input>
      </div>

      <div class="infobox">
        <q-input
          class="input-readonly"
          label="Status"
          stack-label
          outlined
          bg-color="white"
          v-model="newsItem.status"
          readonly
        >
        </q-input>
      </div>
      <div class="infobox">
        <q-input
          class="input-readonly"
          label="Scope"
          stack-label
          outlined
          bg-color="white"
          v-model="newsItem.scope"
          readonly
        >
        </q-input>
      </div>
    </div>

    <div class="tags-details q-pa-md">
      <span class="tags-heading">Tags:</span>
      <div class="row justify-around">
        <div class="q-pa-md q-gutter-md">
          <q-badge
            outline
            color="primary"
            v-for="tag in newsItem.tags"
            :key="tag.id"
          >
            {{ tag.name }}
          </q-badge>
        </div>
      </div>
    </div>

    <div class="tables-info">
      <div class="schemes-table table">
        <q-table
          title="Schemes"
          dense
          :rows="newsItem.schemes"
          :columns="schemeColumns"
          row-key="name"
        />
      </div>
      <div class="organisations-table table">
        <q-table
          title="Organisations"
          dense
          :rows="newsItem.organisations"
          :columns="orgColumns"
          row-key="name"
        />
      </div>
    </div>

    <div class="large-textfields">
      <div class="introduction">
        <div v-html="newsItem.introduction"></div>
      </div>
      <div class="body introduction">
        <div v-html="newsItem.body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { onMounted, computed } from 'vue';
import NewsService from '../services/news.service';
import store from '../store/index';

export default {
  name: 'NewsDetails',
  setup() {
    const route = useRoute();

    onMounted(async () => {
      const id = route.params.id;
      await NewsService.getNewsById(id);
    });

    const newsItem = computed({
      get: () => store.state.news.selectedNewsItem,
    });

    const date = computed({
      get: () => store.state.news.selectedNewsDate,
    });

    const orgColumns = [
      {
        name: 'Id',
        label: 'Id',
        field: 'id',
        sortable: true,
        align: 'left',
      },
      {
        name: 'Organisation name',
        label: 'Organisation name',
        field: 'name',
        sortable: true,
        align: 'left',
      },
    ];

    const schemeColumns = [
      {
        name: 'Id',
        label: 'Id',
        field: 'id',
        sortable: true,
        align: 'left',
      },
      {
        name: 'Scheme name',
        label: 'Scheme name',
        field: 'name',
        sortable: true,
        align: 'left',
      },
    ];

    return {
      newsItem,
      date,
      orgColumns,
      schemeColumns,
    };
  },
};
</script>
