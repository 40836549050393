const EDocumentType = Object.freeze({
    Brochures: 0,
    Wetteksten: 1,
    Aanvraagformulieren: 2,
    Jurisprudence: 3,
    Overig: 4,
    UsefulLinks: 5,
    DeprecatedBrochures: 6,
    DeprecatedWetteksten: 7,
    DeprecatedAanvraagformulieren: 8,
    DeprecatedOther: 9,
    DeprecatedJurisprudence: 10,
    DeprecatedUsefulLinks: 11,
    VisualPingLink: 12
});

export default EDocumentType;