<template>
  <div class="selected-tags">
    <q-spinner-dots v-if="areTagsLoading" color="primary" size="2em" />
    <div v-else class="tagsarea-input tags-tabs q-mb-xl q-mt-xl">
      <div class="row">
        <q-space />
        <q-btn
          label="Edit tags"
          class="orange-btn bold-font q-mb-sm"
          @click="enableShowSelectTags"
          no-caps
          dense
        >
        </q-btn>
      </div>
      <q-card>
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab name="audiences" label="Audiences">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              audienceCount
            }}</q-badge>
          </q-tab>
          <q-tab name="purposes" label="Purposes">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              purposesCount
            }}</q-badge>
          </q-tab>
          <q-tab name="locations" label="Locations">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              locationsCount
            }}</q-badge>
          </q-tab>
          <q-tab name="schemetype" label="Scheme type">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              schemeTypesCount
            }}</q-badge>
          </q-tab>
          <q-tab name="keywords" label="Keywords">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              schemeKeywordsTags.length
            }}</q-badge>
          </q-tab>
          <q-tab name="industriesnew" label="Industries NEW">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              industriesNewCount
            }}</q-badge>
          </q-tab>
          <q-tab name="projecttype" label="Project type">
            <q-badge color="#0a1f8f" class="tag-badge" floating>{{
              projectTypesCount
            }}</q-badge>
          </q-tab>
        </q-tabs>
        <q-separator />

        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="audiences">
            <div
              class="row"
              v-if="
                selectedTagsAudience.length === 0 &&
                  excludedTagsAudience.length === 0
              "
            >
              <q-icon
                name="las la-exclamation-triangle"
                color="black"
                style="font-size: 1.5em;"
              ></q-icon>
              <span>No tags selected</span>
            </div>
            <div
              class="row"
              v-if="
                selectedTagsAudience.length > 0 ||
                  excludedTagsAudience.length > 0
              "
            >
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in selectedTagsAudience" :key="tag.id">
                    <q-icon
                      name="las la-check"
                      color="green"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in excludedTagsAudience" :key="tag.id">
                    <q-icon
                      name="las la-times"
                      color="red"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="purposes">
            <div
              class="row"
              v-if="
                selectedTagsPurposes.length === 0 &&
                  excludedTagsPurposes.length === 0
              "
            >
              <q-icon
                name="las la-exclamation-triangle"
                color="black"
                style="font-size: 1.5em;"
              ></q-icon>
              <span>No tags selected</span>
            </div>
            <div
              class="row"
              v-if="
                selectedTagsPurposes.length > 0 ||
                  excludedTagsPurposes.length > 0
              "
            >
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in selectedTagsPurposes" :key="tag.id">
                    <q-icon
                      name="las la-check"
                      color="green"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in excludedTagsPurposes" :key="tag.id">
                    <q-icon
                      name="las la-times"
                      color="red"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="locations">
            <div
              class="row"
              v-if="
                selectedTagsLocations.length === 0 &&
                  excludedTagsLocations.length === 0
              "
            >
              <q-icon
                name="las la-exclamation-triangle"
                color="black"
                style="font-size: 1.5em;"
              ></q-icon>
              <span>No tags selected</span>
            </div>
            <div
              class="row"
              v-if="
                selectedTagsLocations.length > 0 ||
                  excludedTagsLocations.length > 0
              "
            >
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in selectedTagsLocations" :key="tag.id">
                    <q-icon
                      name="las la-check"
                      color="green"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in excludedTagsLocations" :key="tag.id">
                    <q-icon
                      name="las la-times"
                      color="red"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="schemetype">
            <div
              class="row"
              v-if="
                selectedTagsSchemeTypes.length === 0 &&
                  excludedTagsSchemeTypes.length === 0
              "
            >
              <q-icon
                name="las la-exclamation-triangle"
                color="black"
                style="font-size: 1.5em;"
              ></q-icon>
              <span>No tags selected</span>
            </div>
            <div
              class="row"
              v-if="
                selectedTagsSchemeTypes.length > 0 ||
                  excludedTagsSchemeTypes.length > 0
              "
            >
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in selectedTagsSchemeTypes" :key="tag.id">
                    <q-icon
                      name="las la-check"
                      color="green"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in excludedTagsSchemeTypes" :key="tag.id">
                    <q-icon
                      name="las la-times"
                      color="red"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="keywords">
            <div style="min-width: 250px" class="tag-selector">
              <q-select
                outlined
                bg-color="white"
                v-model="schemeKeywordsTags"
                multiple
                input-debounce="0"
                :options="filterOptions"
                option-value="name"
                class="scheme-inputs"
                option-label="name"
                use-input
                use-chips
                stack-label
                label="Select or input keywords"
                @new-value="createValue"
                @filter="filterFn"
              />
            </div>
          </q-tab-panel>
          <q-tab-panel name="industriesnew">
            <div
              class="row"
              v-if="
                selectedTagsIndustriesNew.length === 0 &&
                  excludedTagsIndustriesNew.length === 0
              "
            >
              <q-icon
                name="las la-exclamation-triangle"
                color="black"
                style="font-size: 1.5em;"
              ></q-icon>
              <span>No tags selected</span>
            </div>
            <div
              class="row"
              v-if="
                selectedTagsIndustriesNew.length > 0 ||
                  excludedTagsIndustriesNew.length > 0
              "
            >
              <div class="col-md-6">
                <q-list>
                  <q-item
                    v-for="tag in selectedTagsIndustriesNew"
                    :key="tag.id"
                  >
                    <q-icon
                      name="las la-check"
                      color="green"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
              <div class="col-md-6">
                <q-list>
                  <q-item
                    v-for="tag in excludedTagsIndustriesNew"
                    :key="tag.id"
                  >
                    <q-icon
                      name="las la-times"
                      color="red"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="projecttype">
            <div
              class="row"
              v-if="
                selectedTagsProjectTypes.length === 0 &&
                  excludedTagsProjectTypes.length === 0
              "
            >
              <q-icon
                name="las la-exclamation-triangle"
                color="black"
                style="font-size: 1.5em;"
              ></q-icon>
              <span>No tags selected</span>
            </div>
            <div
              class="row"
              v-if="
                selectedTagsProjectTypes.length > 0 ||
                  excludedTagsProjectTypes.length > 0
              "
            >
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in selectedTagsProjectTypes" :key="tag.id">
                    <q-icon
                      name="las la-check"
                      color="green"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
              <div class="col-md-6">
                <q-list>
                  <q-item v-for="tag in excludedTagsProjectTypes" :key="tag.id">
                    <q-icon
                      name="las la-times"
                      color="red"
                      style="font-size: 1.5em;"
                    ></q-icon>
                    <span>{{ tag.label }}</span>
                    <span v-if="tag.parentName">
                      &nbsp;({{ tag.parentName }})</span
                    >
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>
  </div>
  <SelectTags
    v-if="showSelectTags"
    :audiencePairs="audiencePairs"
    :purposesPairs="purposesPairs"
    :locationsPairs="locationsPairs"
    :schemeTypesPairs="schemeTypesPairs"
    :projectTypesPairs="projectTypesPairs"
    :industriesNewPairs="industriesNewPairs"
    :showSelectTags="showSelectTags"
    :audienceNodes="audienceNodes"
    :purposesNodes="purposesNodes"
    :industriesNewNodes="industriesNewNodes"
    :locationsNodes="locationsNodes"
    :schemeTypesNodes="schemeTypesNodes"
    :projectTypesNodes="projectTypesNodes"
    :selectedGroups="locationGroupsSelected"
    :isEdit="true"
  />
</template>

<script>
import store from "../../store/index";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import TagsService from "../../services/tags.service";
import SchemesServices from "../../services/schemes.services";
import SelectTags from "./SelectTags.vue";
import ETagType from "../../enums/ETagType";

export default {
  name: "SelectedTags",
  components: {
    SelectTags,
  },
  setup() {
    const route = useRoute();
    const uniqueFilteredListIds = ref([]);
    const audiencePairs = computed({
      get: () => store.state.scheme.selectedSchemeAudienceTags,
      set: (val) => store.commit("scheme/setSelectedSchemeAudienceTags", val),
    });

    const purposesPairs = computed({
      get: () => store.state.scheme.selectedSchemePurposesTags,
      set: (val) => store.commit("scheme/setSelectedSchemePurposesTags", val),
    });

    const locationGroupsSelected = computed({
      get: () => store.state.tree.locationGroupsSelected,
      set: (val) => store.commit("tree/setLocationGroupsSelected", val),
    });

    const locationsPairs = computed({
      get: () => {
        store.commit(
          "scheme/updateDisabledNodesinTree",
          locationGroupsSelected.value
        );
        return store.state.scheme.selectedSchemeLocationsTags;
      },
      set: (val) => store.commit("scheme/setSelectedSchemeLocationsTags", val),
    });

    const schemeTypesPairs = computed({
      get: () => store.state.scheme.selectedSchemeSchemeTypesTags,
      set: (val) => store.commit("scheme/setSelectedSchemeSchemeTypeTags", val),
    });

    const projectTypesPairs = computed({
      get: () => store.state.scheme.selectedSchemeProjectTypesTags,
      set: (val) =>
        store.commit("scheme/setSelectedSchemeProjectTypeTags", val),
    });

    const industriesNewPairs = computed({
      get: () => store.state.scheme.selectedSchemeIndustriesNewTags,
      set: (val) =>
        store.commit("scheme/setSelectedSchemeIndustriesNEWTags", val),
    });

    const tab = ref("audiences");

    onMounted(async () => {
      store.commit("scheme/updateAreTagsLoading", true);
      await TagsService.getTagTypeTree(
        "Audience",
        ETagType.Audience
      );
      await TagsService.getTagTypeTree("Purposes", ETagType.Purposes);
      //This is just hidden for now, don't remove it
      // await TagsService.getTagTypeTree('Industries', ETagType.Industries);
      await TagsService.getTagTypeTree("Locations", ETagType.Locations);
      await TagsService.getTagTypeTree("Scheme type", ETagType.SchemeType);
      await TagsService.getTagTypeTree("Project type", ETagType.ProjectType);
      await TagsService.getTagTypeTree("Industries NEW", ETagType.IndustriesNew);
      await TagsService.getTagsByType("Keywords", ETagType.Keywords);

      var sessionTags = JSON.parse(sessionStorage.getItem("AudienceNewTags"));
      if (sessionTags && sessionTags.length > 0) {
        store.commit("scheme/setSelectedSchemeAudienceTags", sessionTags);
      } else {
        await SchemesServices.getSchemeSelectedTags(
          route.params.id,
          "Audience",
          ETagType.Audience
        );
      }

      sessionTags = JSON.parse(sessionStorage.getItem("PurposesNewTags"));
      if (sessionTags && sessionTags.length > 0) {
        store.commit("scheme/setSelectedSchemePurposesTags", sessionTags);
      } else {
        await SchemesServices.getSchemeSelectedTags(
          route.params.id,
          "Purposes",
          ETagType.Purposes
        );
      }
      //This is just hidden for now, don't remove it
      //await SchemesServices.getSchemeSelectedTags(route.params.id, 'Industries', ETagType.Industries);

      sessionTags = JSON.parse(sessionStorage.getItem("LocationsNewTags"));
      if (sessionTags && sessionTags.length > 0) {
        store.commit("scheme/setSelectedSchemeLocationsTags", sessionTags);
      } else {
        await SchemesServices.getSchemeSelectedTags(
          route.params.id,
          "Locations",
          ETagType.Locations
        );
      }

      sessionTags = JSON.parse(sessionStorage.getItem("SchemeTypesNewTags"));
      if (sessionTags && sessionTags.length > 0) {
        store.commit("scheme/setSelectedSchemeSchemeTypeTags", sessionTags);
      } else {
        await SchemesServices.getSchemeSelectedTags(
          route.params.id,
          "Scheme Type",
          ETagType.SchemeType
        );
      }

      sessionTags = JSON.parse(sessionStorage.getItem("ProjectTypesNewTags"));
      if (sessionTags && sessionTags.length > 0) {
        store.commit("scheme/setSelectedSchemeProjectTypeTags", sessionTags);
      } else {
        await SchemesServices.getSchemeSelectedTags(
          route.params.id,
          "Project Type",
          ETagType.ProjectType
        );
      }

      sessionTags = JSON.parse(sessionStorage.getItem("IndustriesNewNewTags"));
      if (sessionTags && sessionTags.length > 0) {
        store.commit("scheme/setSelectedSchemeIndustriesNEWTags", sessionTags);
      } else {
        await SchemesServices.getSchemeSelectedTags(
          route.params.id,
          "Industries NEW",
          ETagType.IndustriesNew
        );
      }

      await SchemesServices.getSchemeSelectedTags(
        route.params.id,
        "Keywords",
        ETagType.Keywords
      );

      store.commit("scheme/updateAreTagsLoading", false);
    });

    const enableShowSelectTags = () => {
      store.commit("scheme/enableShowSelectTags");
    };

    const showSelectTags = computed({
      get: () => store.state.scheme.showSelectTags,
    });

    const schemeKeywordsTags = computed({
      get: () => store.state.scheme.selectedSchemeKeywordsTags,
      set: (value) => store.commit("scheme/updateSchemeKeywordsTags", value),
    });

    const audienceCount = computed({
      get: () => store.state.scheme.schemeAudienceTagsCount,
    });
    const purposesCount = computed({
      get: () => store.state.scheme.schemePurposesTagsCount,
    });
    const locationsCount = computed({
      get: () => store.state.scheme.schemeLocationsTagsCount,
    });
    const schemeTypesCount = computed({
      get: () => store.state.scheme.schemeSchemeTypesTagsCount,
    });
    const projectTypesCount = computed({
      get: () => store.state.scheme.schemeProjectTypesTagsCount,
    });
    const industriesNewCount = computed({
      get: () => store.state.scheme.schemeIndustriesNewTagsCount,
    });

    const audienceNodes = computed({
      get: () => store.state.scheme.audienceTags,
    });

    const locationsNodes = computed({
      get: () => store.state.scheme.locationsTags,
    });

    const purposesNodes = computed({
      get: () => store.state.scheme.purposesTags,
    });

    const schemeTypesNodes = computed({
      get: () => store.state.scheme.schemeTypesTags,
    });

    const projectTypesNodes = computed({
      get: () => store.state.scheme.projectTypesTags,
    });

    const industriesNewNodes = computed({
      get: () => store.state.scheme.industriesNewTags,
    });

    const allKeywordsTags = computed({
      get: () => store.state.tagsNavigation.keywordsTags,
    });

    const fillSelectedTags = (nodes, pairs) => {
      var selectedAndExcludedTags = {
        selectedTags: [],
        excludedTags: [],
      };

      var rootNodes = nodes.value.options.filter(
        (element) =>
          element.children.length === 0 ||
          !element.children ||
          element.children === null
      );
      var firstLevelParentNodes = nodes.value.options.filter(
        (element) => element.children !== null && element.children.length > 0
      );
      var secondLevelParentNodes = firstLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();
      var thirdLevelParentNodes = secondLevelParentNodes
        .map((n) =>
          n.children.filter(
            (element) =>
              element.children !== null && element.children.length > 0
          )
        )
        .filter((el) => el.length > 0)
        .flat();
      var selectedRootTags = rootNodes.filter(({ id: id1 }) =>
        pairs.value.some(({ id: id2 }) => id2 === id1)
      );
      selectedAndExcludedTags.selectedTags.push(...selectedRootTags);

      let pairIds = new Set(pairs.value.map((pair) => pair.id));

      for (let node of firstLevelParentNodes) {
        let selectedChildren = node.children.filter((child) =>
          pairIds.has(child.id)
        );

        if (selectedChildren.length === node.children.length) {
          selectedAndExcludedTags.selectedTags.push(node);
        } else if (
          node.children.length !== 1 &&
          selectedChildren.length === node.children.length - 1
        ) {
          selectedAndExcludedTags.selectedTags.push(node);
          let excludedTag = node.children.find(
            (child) => !pairIds.has(child.id)
          );
          excludedTag.parentName = node.label;
          selectedAndExcludedTags.excludedTags.push(excludedTag);
        } else {
          for (let child of selectedChildren) {
            child.parentName = node.label;
          }
          selectedAndExcludedTags.selectedTags.push(...selectedChildren);
        }
      }

      for (let node of secondLevelParentNodes) {
        let selectedChildren = node.children.filter((child) =>
          pairIds.has(child.id)
        );

        if (
          node.children.length !== 1 &&
          selectedChildren.length === node.children.length - 1
        ) {
          let parentOfSecondLevelParent = nodes.value.options.find((tag) =>
            tag.children.includes(node)
          );
          node.parentName = parentOfSecondLevelParent.label;
          selectedAndExcludedTags.selectedTags.push(node);
          let excludedTag = node.children.find(
            (child) => !pairIds.has(child.id)
          );
          excludedTag.parentName = node.label;
          selectedAndExcludedTags.excludedTags.push(excludedTag);
        } else if (selectedChildren.length !== node.children.length) {
          for (let child of selectedChildren) {
            child.parentName = node.label;
          }
          selectedAndExcludedTags.selectedTags.push(...selectedChildren);
        }
      }

      for (let node of thirdLevelParentNodes) {
        let selectedChildren = node.children.filter((child) =>
          pairIds.has(child.id)
        );

        if (
          node.children.length !== 1 &&
          selectedChildren.length === node.children.length - 1
        ) {
          let parentOfThirdLevelParent = nodes.value.options.find((tag) =>
            tag.children.includes(node)
          );
          node.parentName = parentOfThirdLevelParent.label;
          selectedAndExcludedTags.selectedTags.push(node);
          let excludedTag = node.children.find(
            (child) => !pairIds.has(child.id)
          );
          excludedTag.parentName = node.label;
          selectedAndExcludedTags.excludedTags.push(excludedTag);
        } else if (selectedChildren.length !== node.children.length) {
          for (let child of selectedChildren) {
            child.parentName = node.label;
          }
          selectedAndExcludedTags.selectedTags.push(...selectedChildren);
        }
      }

      return selectedAndExcludedTags;
    };

    const filterOptions = ref(allKeywordsTags.value);

    const selectedTagsAudience = computed({
      get: () => {
        return fillSelectedTags(audienceNodes, audiencePairs).selectedTags;
      },
    });
    const excludedTagsAudience = computed({
      get: () => {
        return fillSelectedTags(audienceNodes, audiencePairs).excludedTags;
      },
    });
    const excludedTagsPurposes = computed({
      get: () => {
        return fillSelectedTags(purposesNodes, purposesPairs).excludedTags;
      },
    });
    const selectedTagsPurposes = computed({
      get: () => {
        return fillSelectedTags(purposesNodes, purposesPairs).selectedTags;
      },
    });
    const excludedTagsLocations = computed({
      get: () => {
        return fillSelectedTags(locationsNodes, locationsPairs).excludedTags;
      },
    });
    const selectedTagsLocations = computed({
      get: () => {
        return fillSelectedTags(locationsNodes, locationsPairs).selectedTags;
      },
    });
    const excludedTagsSchemeTypes = computed({
      get: () => {
        return fillSelectedTags(schemeTypesNodes, schemeTypesPairs)
          .excludedTags;
      },
    });
    const selectedTagsSchemeTypes = computed({
      get: () => {
        return fillSelectedTags(schemeTypesNodes, schemeTypesPairs)
          .selectedTags;
      },
    });
    const excludedTagsProjectTypes = computed({
      get: () => {
        return fillSelectedTags(projectTypesNodes, projectTypesPairs)
          .excludedTags;
      },
    });
    const selectedTagsProjectTypes = computed({
      get: () => {
        return fillSelectedTags(projectTypesNodes, projectTypesPairs)
          .selectedTags;
      },
    });
    const excludedTagsIndustriesNew = computed({
      get: () => {
        return fillSelectedTags(industriesNewNodes, industriesNewPairs)
          .excludedTags;
      },
    });
    const selectedTagsIndustriesNew = computed({
      get: () => {
        return fillSelectedTags(industriesNewNodes, industriesNewPairs)
          .selectedTags;
      },
    });

    const areTagsLoading = computed({
      get: () => store.state.scheme.areTagsLoading,
    });
    return {
      areTagsLoading,
      fillSelectedTags,
      locationGroupsSelected,
      schemeKeywordsTags,
      audienceCount,
      purposesCount,
      locationsCount,
      schemeTypesCount,
      projectTypesCount,
      industriesNewCount,
      showSelectTags,
      audiencePairs,
      purposesPairs,
      industriesNewPairs,
      schemeTypesPairs,
      locationsPairs,
      projectTypesPairs,
      enableShowSelectTags,
      audienceNodes,
      locationsNodes,
      purposesNodes,
      schemeTypesNodes,
      projectTypesNodes,
      industriesNewNodes,
      selectedTagsAudience,
      tab,
      excludedTagsAudience,
      selectedTagsPurposes,
      excludedTagsPurposes,
      selectedTagsIndustriesNew,
      excludedTagsIndustriesNew,
      selectedTagsLocations,
      excludedTagsLocations,
      selectedTagsSchemeTypes,
      excludedTagsSchemeTypes,
      selectedTagsProjectTypes,
      excludedTagsProjectTypes,
      allKeywordsTags,
      uniqueFilteredListIds,
      filterOptions,
      createValue(val, done) {
        if (val.length > 0) {
          if (!allKeywordsTags.value.includes(val)) {
            store.commit("tagsNavigation/updateAllKeywordsTags", val);
          }
          done(val, "add-unique");
        }
      },
      filterFn(val, update) {
        update(() => {
          if (val === "") {
            filterOptions.value = allKeywordsTags.value;
          } else {
            const needle = val.toLowerCase();
            filterOptions.value = allKeywordsTags.value.filter(
              (v) => v.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
    };
  },
};
</script>
