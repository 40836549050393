import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import axios from 'axios';
import CKEditor from '@ckeditor/ckeditor5-vue';
import CountryFlag from 'vue-country-flag-next';
import VueFroala from 'vue-froala-wysiwyg';
//Import Froala Editor 
import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';

createApp(App).use(Quasar, quasarUserOptions)
  .use(router)
  .use(CKEditor)
  .use(VueFroala)
  .component('country-flag', CountryFlag)
  .mount('#app');
  
  axios.interceptors.request.use(function (config) {
    config.headers["Accept-Language"] = 'en-US';
    return config;
  });
