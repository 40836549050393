import ESchemeStatus from '../../enums/ESchemeStatus';
if (sessionStorage.getItem('publicationStatusesGroup') == null)
  sessionStorage.setItem(
    'publicationStatusesGroup',
    JSON.stringify([10, 20, 30, 40])
  );
if (sessionStorage.getItem('schemeStatusGroup') == null)
  sessionStorage.setItem(
    'schemeStatusGroup',
    JSON.stringify([
      ESchemeStatus.Open,
      ESchemeStatus.Forthcoming,
      ESchemeStatus.Closed,
    ])
  );
if (sessionStorage.getItem('searchOnAdministratingOrgs') == null)
  sessionStorage.setItem('searchOnAdministratingOrgs', JSON.stringify(true));
if (sessionStorage.getItem('searchOnAdvisingOrgs') == null)
  sessionStorage.setItem('searchOnAdvisingOrgs', JSON.stringify(true));
if (sessionStorage.getItem('searchOnFundingOrgs') == null)
  sessionStorage.setItem('searchOnFundingOrgs', JSON.stringify(true));
if (sessionStorage.getItem('searchOnRecipientOrgs') == null)
  sessionStorage.setItem('searchOnRecipientOrgs', JSON.stringify(true));

const state = () => ({
  //state search
  searchText: '',
  durationPhrase: '',
  //state ResultTypes
  resultTypesString: '',
  showSchemes: true,
  showCalls: false,
  //state Statuses
  schemeStatusGroup: [
    ESchemeStatus.Open,
    ESchemeStatus.Forthcoming,
    ESchemeStatus.Closed,
  ],
  //state start/end date
  startDate: '',
  endDate: '',
  //state budget
  minBudget: null,
  maxBudget: null,
  //state organisations
  organisationsInclude: [],
  organisationsExclude: [],
  //state audience
  selectedAudiences: '',
  audience: { options: [], label: '' },
  //state purposes
  selectedPurposes: '',
  purposes: { options: [], label: '' },
  //state SchemeType
  selectedSchemeTypes: '',
  schemeTypes: { options: [], label: '' },
  selectedProjectTypes: '',
  projectTypes: { options: [], label: '' },
  //state Locations
  locations: { options: [], label: '' },
  //state pagination
  pageCount: 0,
  numberOfResults: 0,
  currentPage: 1,
  general: [],
  //state location
  locationsOptions: [{}],
  selectedLocations: [],
  selectedGermanLocationsPaths: [],
  germanLocationTagsBulkList: [],
  //state industries
  //This is just hidden for now, don't remove it
  //selectedIndustries: '',
  //industries: { options: [], label: '' },
  selectedSort: '',
  updatedDateGreaterThan: '',
  updatedDateLessThan: '',
  searchOnlyName: false,
  publicationStatusesGroup: [10, 20, 30, 40],
  filterByICTUFlag: null,
  //Tender filters:
  schemeStartGreaterThan: '',
  schemeStartLessThan: '',
  schemeEndGreaterThan: '',
  schemeEndLessThan: '',
  searchOnAdministratingOrgs: true,
  searchOnAdvisingOrgs: true,
  searchOnFundingOrgs: true,
  searchOnRecipientOrgs: true,
  searchOnIsScienceScheme: false,
  searchOnIsPortalScheme: false,
  searchOnIsEnglishScheme: false,
  showAllLanguageVersions: false,
  searchOnIsObScheme: false,

  //three way toggles
  showIsPortal: true,
  hideIsPortal: false,
  showOnlyPortal: false,
  showIsScience: true,
  hideIsScience: false,
  showOnlyScience: false,
  showIsICTU: true,
  hideIsICTU: false,
  showOnlyICTU: false,
});

const getters = {
  showAdvancedSearch: (state) => {
    return state.showAdvancedSearch;
  },
  getAllFilters: (state) => {
    return state.filterActivities;
  },
};

const actions = {};

const mutations = {
  setTogglesForSearch(state) {
    state.searchOnIsPortalScheme = state.showIsPortal;
    state.searchOnIsScienceScheme = state.showIsScience;
    state.filterByICTUFlag = state.showIsICTU;
    if (state.showOnlyPortal) {
      state.searchOnIsScienceScheme = false;
      state.filterByICTUFlag = false;
      state.searchOnIsPortalScheme = true;
    } else if (state.showOnlyICTU) {
      state.searchOnIsPortalScheme = false;
      state.filterByICTUFlag = true;
      state.searchOnIsScienceScheme = false;
    } else if (state.showOnlyScience) {
      state.searchOnIsScienceScheme = true;
      state.searchOnIsPortalScheme = false;
      state.filterByICTUFlag = false;
    }
  },

  updatePortalShowing(state, val) {
    if (val == 'show') {
      state.showIsPortal = true;
      state.showOnlyPortal = false;
    }
    if (val == 'hide') {
      state.showIsPortal = false;
      state.showOnlyPortal = false;
    }
    if (val == 'filter') {
      state.showIsPortal = true;
      state.showOnlyPortal = true;
      state.showIsScience = false;
      state.showIsICTU = false;
      state.showOnlyICTU = false;
      state.showOnlyScience = false;
    }
  },
  updateScienceShowing(state, val) {
    if (val == 'show') {
      state.showIsScience = true;
      state.showOnlyScience = false;
    }
    if (val == 'hide') {
      state.showIsScience = false;
      state.showOnlyScience = false;
    }
    if (val == 'filter') {
      state.showIsScience = true;
      state.showOnlyScience = true;
      state.showIsPortal = false;
      state.showOnlyPortal = false;
      state.showIsICTU = false;
      state.showOnlyICTU = false;
    }
  },
  updateICTUShowing(state, val) {
    if (val == 'show') {
      state.showIsICTU = true;
      state.showOnlyICTU = false;
    }
    if (val == 'hide') {
      state.showIsICTU = false;
      state.showOnlyICTU = false;
    }
    if (val == 'filter') {
      state.showIsICTU = true;
      state.showOnlyICTU = true;
      state.showIsPortal = false;
      state.showOnlyPortal = false;
      state.showIsScience = false;
      state.showOnlyScience = false;
    }
  },

  updateIsScienceScheme(state, val) {
    state.searchOnIsScienceScheme = val;
  },
  updateIsPortalScheme(state, val) {
    state.searchOnIsPortalScheme = val;
  },
  updateSelectedSort(state, sort) {
    state.selectedSort = sort;
    sessionStorage.setItem('sortBy', sort);
  },
  updateIsEnglishScheme(state, val) {
    state.searchOnIsEnglishScheme = val;
  },
  updateIsObScheme(state, val) {
    state.searchOnIsObScheme = val;
  },

  resetAllFilters(state) {
    state.searchText = '';
    state.durationPhrase = '';
    state.resultTypesString = '';
    state.selectedAudiences = '';
    state.selectedLocations.length = 0;
    state.selectedGermanLocationsPaths.length = 0;
    //This is just hidden for now, don't remove it
    //state.selectedIndustries = '';
    state.selectedPurposes = '';
    state.selectedSchemeTypes = '';
    state.selectedProjectTypes = '';
    state.minBudget = null;
    state.maxBudget = null;
    state.organisationsExclude = [];
    state.organisationsInclude = [];
    state.startDate = '';
    state.endDate = '';
    state.updatedDateGreaterThan = '';
    state.updatedDateLessThan = '';
    state.searchOnlyName = false;
    state.publicationStatusesGroup = [10, 20, 30, 40];
    state.schemeStatusGroup = [
      ESchemeStatus.Open,
      ESchemeStatus.Forthcoming,
      ESchemeStatus.Closed,
    ]; //open,forthcoming, closed
    state.filterByICTUFlag = null;
    state.schemeStartGreaterThan = '';
    state.schemeStartLessThan = '';
    state.schemeEndGreaterThan = '';
    state.schemeEndLessThan = '';
    state.searchOnFundingOrgs = true;
    state.searchOnAdministratingOrgs = true;
    state.searchOnAdvisingOrgs = true;
    state.searchOnRecipientOrgs = true;
    state.searchOnIsScienceScheme = false;
    state.searchOnIsEnglishScheme = false;
    state.showAllLanguageVersions = false;
  },
  //mutations search
  updateSearchText(state, val) {
    state.searchText = val;
    sessionStorage.setItem('searchText', val);
  },
  updateDurationPhrase(state, val) {
    state.durationPhrase = val;
    sessionStorage.setItem('durationPhrase', val);
  },
  updateSearchOnlyName(state, val) {
    state.searchOnlyName = val;
    sessionStorage.setItem('searchOnlyName', JSON.stringify(val));
  },
  updateSearchOnFundingOrgs(state, val) {
    state.searchOnFundingOrgs = val;
    sessionStorage.setItem('searchOnFundingOrgs', JSON.stringify(val));
  },
  updateSearchOnAdministratingOrgs(state, val) {
    state.searchOnAdministratingOrgs = val;
    sessionStorage.setItem('searchOnAdministratingOrgs', JSON.stringify(val));
  },
  updateSearchOnAdvisingOrgs(state, val) {
    state.searchOnAdvisingOrgs = val;
    sessionStorage.setItem('searchOnAdvisingOrgs', JSON.stringify(val));
  },
  updateSearchOnRecipientOrgs(state, val) {
    state.searchOnRecipientOrgs = val;
    sessionStorage.setItem('searchOnRecipientOrgs', JSON.stringify(val));
  },
  updatePublicationStatusesGroup(state, val) {
    state.publicationStatusesGroup = val;
    sessionStorage.setItem('publicationStatusesGroup', JSON.stringify(val));
  },
  updateSchemeStatusGroup(state, val) {
    state.schemeStatusGroup = val;
    sessionStorage.setItem('schemeStatusGroup', JSON.stringify(val));
  },
  updateFilterByICTUFlag(state, val) {
    state.filterByICTUFlag = val;
    sessionStorage.setItem('filterByICTUFlag', JSON.stringify(val));
  },
  //mutations Result types
  updateShowSchemes(state, value) {
    state.showSchemes = value;
  },
  updateShowCalls(state, value) {
    state.showCalls = value;
  },
  updateShowAllLanguageVersions(state, val) {
    state.showAllLanguageVersions = val;
    sessionStorage.setItem('showAllLanguageVersions', JSON.stringify(val));
  },
  createResultTypesString(state) {
    state.resultTypesString = '';
    if (state.showSchemes && state.showCalls) state.resultTypesString = '0, 1';
    if (state.showSchemes && !state.showCalls) state.resultTypesString = '0';
    if (!state.showSchemes && state.showCalls) state.resultTypesString = '1';
  },

  //mutations date
  updateStartDate(state, val) {
    state.startDate = val;
    sessionStorage.setItem('startDate', JSON.stringify(val));
  },
  updateEndDate(state, val) {
    state.endDate = val;
    sessionStorage.setItem('endDate', JSON.stringify(val));
  },
  updateUpdatedDateGreaterThan(state, val) {
    state.updatedDateGreaterThan = val;
    sessionStorage.setItem('updatedDateGreaterThan', JSON.stringify(val));
  },
  updateUpdatedDateLessThan(state, val) {
    state.updatedDateLessThan = val;
    sessionStorage.setItem('updatedDateLessThan', JSON.stringify(val));
  },
  updateSchemeStartGreaterThan(state, val) {
    state.schemeStartGreaterThan = val;
    sessionStorage.setItem('schemeStartGreaterThan', JSON.stringify(val));
  },
  updateSchemeStartLessThan(state, val) {
    state.schemeStartLessThan = val;
    sessionStorage.setItem('schemeStartLessThan', JSON.stringify(val));
  },
  updateSchemeEndGreaterThan(state, val) {
    state.schemeEndGreaterThan = val;
    sessionStorage.setItem('schemeEndGreaterThan', JSON.stringify(val));
  },
  updateSchemeEndLessThan(state, val) {
    state.schemeEndLessThan = val;
    sessionStorage.setItem('schemeEndLessThan', JSON.stringify(val));
  },
  //mutations Budget
  updateMinBudget(state, val) {
    state.minBudget = val;
    sessionStorage.setItem('minBudget', JSON.stringify(val));
  },
  updateMaxBudget(state, val) {
    state.maxBudget = val;
    sessionStorage.setItem('maxBudget', JSON.stringify(val));
  },
  //mutations Organisations
  updateOrganisationsInclude(state, val) {
    state.organisationsInclude = val;
    sessionStorage.setItem('organisationsInclude', JSON.stringify(val));
  },
  updateOrganisationsExclude(state, val) {
    state.organisationsExclude = val;
    sessionStorage.setItem('organisationsExclude', JSON.stringify(val));
  },

  updateSelectedAudiences(state, val) {
    state.selectedAudiences = '';
    var audiences = state.audience.options;
    var toAdd = '';
    if (val.length > 0) {
      for (let i = 0; i < val.length; i++) {
        if (i != val.length - 1) state.selectedAudiences += val[i] + ',';
        else state.selectedAudiences += val[i];
      }
      for (let i = 0; i < audiences.length; i++) {
        if (audiences[i].children && audiences[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < audiences[i].children.length; j++) {
            if (
              audiences[i].children[j].children &&
              audiences[i].children[j].children.length > 0
            ) {
              var hasAllSubChildrenTicked = true;
              for (
                let k = 0;
                k < audiences[i].children[j].children.length;
                k++
              ) {
                if (!val.includes(audiences[i].children[j].children[k].id)) {
                  hasAllSubChildrenTicked = false;
                  break;
                }
              }
              if (
                hasAllSubChildrenTicked &&
                audiences[i].children[j].children &&
                audiences[i].children[j].children.length > 0
              ) {
                toAdd += ',' + audiences[i].children[j].id;
                val.push(audiences[i].children[j].id);
              }
            }
            if (!val.includes(audiences[i].children[j].id)) {
              hasAllChildrenTicked = false;
              continue;
            }
          }
          if (hasAllChildrenTicked) toAdd += ',' + audiences[i].id;
        }
      }
      if (toAdd != '' && toAdd.length > 0) state.selectedAudiences += toAdd;
    }

    var uniqueArr = state.selectedAudiences;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedAudiences = uniqueArr;

    sessionStorage.setItem('selectedAudiences', uniqueArr);
  },

  //mutations Industries
  /*  updateSelectedIndustries(state, val) {
    state.selectedIndustries = '';
    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedIndustries += val[i] + ',';
      else state.selectedIndustries += val[i];
    }
  }, */

  //This is just hidden for now, don't remove it
  /*
  updateSelectedIndustries(state, val) {
    state.selectedIndustries = '';
    var industries = state.industries.options;
    var toAdd = '';
    if (val.length > 0) {
      for (let i = 0; i < val.length; i++) {
        if (i != val.length - 1) state.selectedIndustries += val[i] + ',';
        else state.selectedIndustries += val[i];
      }

      for (let i = 0; i < industries.length; i++) {
        if (industries[i].children && industries[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < industries[i].children.length; j++) {
            if (
              industries[i].children[j].children &&
              industries[i].children[j].children.length > 0
            ) {
              var hasAllSubChildrenTicked = true;
              for (
                let k = 0;
                k < industries[i].children[j].children.length;
                k++
              ) {
                if (!val.includes(industries[i].children[j].children[k].id)) {
                  hasAllSubChildrenTicked = false;
                  break;
                }
              }
            }

            if (
              hasAllSubChildrenTicked &&
              industries[i].children[j].children &&
              industries[i].children[j].children.length > 0
            ) {
              toAdd += ',' + industries[i].children[j].id;
              val.push(industries[i].children[j].id);
            }

            if (!val.includes(industries[i].children[j].id)) {
              hasAllChildrenTicked = false;
              continue;
            }
          }
          if (hasAllChildrenTicked) {
            toAdd += ',' + industries[i].id;
          }
        }
      }
      if (toAdd != '' && toAdd.length > 0) state.selectedIndustries += toAdd;
    }

    var uniqueArr = state.selectedIndustries;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedIndustries = uniqueArr;
  },
  */

  //mutations Purpose
  updateSelectedPurposes(state, val) {
    state.selectedPurposes = '';
    var purposes = state.purposes.options;
    var toAdd = '';
    if (val.length > 0) {
      for (let i = 0; i < val.length; i++) {
        if (i != val.length - 1) state.selectedPurposes += val[i] + ',';
        else state.selectedPurposes += val[i];
      }

      for (let i = 0; i < purposes.length; i++) {
        if (purposes[i].children && purposes[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < purposes[i].children.length; j++) {
            if (
              purposes[i].children[j].children &&
              purposes[i].children[j].children.length > 0
            ) {
              var hasAllSubChildrenTicked = true;
              for (
                let k = 0;
                k < purposes[i].children[j].children.length;
                k++
              ) {
                if (!val.includes(purposes[i].children[j].children[k].id)) {
                  hasAllSubChildrenTicked = false;
                  break;
                }
              }
              if (
                hasAllSubChildrenTicked &&
                purposes[i].children[j].children &&
                purposes[i].children[j].children.length > 0
              ) {
                toAdd += ',' + purposes[i].children[j].id;
                val.push(purposes[i].children[j].id);
              }
            }

            if (!val.includes(purposes[i].children[j].id)) {
              hasAllChildrenTicked = false;
              continue;
            }
          }
          if (hasAllChildrenTicked) {
            toAdd += ',' + purposes[i].id;
          }
        }
      }
      if (toAdd != '' && toAdd.length > 0) state.selectedPurposes += toAdd;
    }
    var uniqueArr = state.selectedPurposes;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedPurposes = uniqueArr;

    sessionStorage.setItem('selectedPurposes', state.selectedPurposes);
  },
  //mutations Scheme Type
  updateSelectedSchemeTypes(state, val) {
    state.selectedSchemeTypes = '';
    var schemesTypes = state.schemeTypes.options;
    var toAdd = '';
    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedSchemeTypes += val[i] + ',';
      else state.selectedSchemeTypes += val[i];
      for (let i = 0; i < schemesTypes.length; i++) {
        if (schemesTypes[i].children && schemesTypes[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < schemesTypes[i].children.length; j++) {
            if (!val.includes(schemesTypes[i].children[j].id)) {
              hasAllChildrenTicked = false;
              break;
            }
          }
          if (hasAllChildrenTicked) toAdd += ',' + schemesTypes[i].id;
        }
      }
      if (toAdd != '' && toAdd.length > 0) state.selectedSchemeTypes += toAdd;
    }

    var uniqueArr = state.selectedSchemeTypes;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedSchemeTypes = uniqueArr;

    sessionStorage.setItem('selectedSchemeTypes', state.selectedSchemeTypes);
  },
  //mutations Scheme Type
  updateSelectedProjectTypes(state, val) {
    state.selectedProjectTypes = '';
    var projectsTypes = state.projectTypes.options;
    var toAdd = '';
    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedProjectTypes += val[i] + ',';
      else state.selectedProjectTypes += val[i];
      for (let i = 0; i < projectsTypes.length; i++) {
        if (projectsTypes[i].children && projectsTypes[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < projectsTypes[i].children.length; j++) {
            if (!val.includes(projectsTypes[i].children[j].id)) {
              hasAllChildrenTicked = false;
              break;
            }
          }
          if (hasAllChildrenTicked) toAdd += ',' + projectsTypes[i].id;
        }
      }
      if (toAdd != '' && toAdd.length > 0) state.selectedProjectTypes += toAdd;
    }

    var uniqueArr = state.selectedProjectTypes;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedProjectTypes = uniqueArr;

    sessionStorage.setItem('selectedProjectTypes', state.selectedProjectTypes);
  },
  //mutations Locations
  updateSelectedLocations(state, val) {
    state.selectedLocations = val;
    sessionStorage.setItem('selectedLocations', JSON.stringify(val));
  },
  createLocationsOptions(state) {
    state.locationsOptions = [];
    for (let i = 0; i < state.locations.options.length; i++) {
      state.locationsOptions.push({
        id: state.locations.options[i].id,
        label: state.locations.options[i].label,
      });
      if (
        state.locations.options[i].children &&
        state.locations.options[i].children.length
      ) {
        for (let j = 0; j < state.locations.options[i].children.length; j++)
          state.locationsOptions.push({
            id: state.locations.options[i].children[j].id,
            label:
              state.locations.options[i].children[j].label +
              ', ' +
              state.locations.options[i].label,
            parent: state.locations.options[i],
          });
      }
    }
  },
  //mutations Pagination
  updatePageCount(state, val) {
    state.pageCount = val;
  },
  updateResultsCount(state, val) {
    state.numberOfResults = val;
  },
  updateCurrentPage(state, val) {
    state.currentPage = val;
  },
  toggleAdvancedSearch(state) {
    state.showAdvancedSearch = !state.showAdvancedSearch;
  },
  //mutations Other
  updateFilterActivities(state, activities) {
    state.general = activities;
    state.audience = activities.audience;
    //This is just hidden for now, don't remove it
    //state.industries = activities.industries;
    state.locations = activities.locations;
    state.purposes = activities.purposes;
    state.schemeTypes = activities.schemeTypes;
    state.projectTypes = activities.projectTypes;
  },
  updateSelectedGermanLocationsPaths(state, val) {
    state.selectedGermanLocationsPaths = val;
  },
  updateGermanLocationTagsBulkList(state, val) {
    state.germanLocationTagsBulkList = val;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
