<template>
  <div class="organisations-edit-page">
    <OrganisationsErrorModal />
    <h2 class="title">Edit organisation: {{ organisationName }}</h2>
    <div class="inputs-box">
      <q-input
        borderless
        class="edit-input-lg q-mt-md"
        label="Name"
        v-model="organisationName"
        bg-color="white"
      />
      <div class="row justify-between inputs-container">
        <q-input
          borderless
          class="edit-input-md  q-mt-md"
          v-model="organisationEmail"
          label="Email"
          bg-color="white"
        />
        <q-input
          borderless
          class="edit-input-md  q-mt-md"
          v-model="organisationPhone"
          label="Phone"
          bg-color="white"
        />
      </div>

      <q-input
        borderless
        class="edit-input-lg  q-mt-md"
        v-model="organisationUrl"
        label="Website"
        bg-color="white"
      />
      <q-input
        borderless
        class="edit-input-lg  q-mt-md"
        v-model="street"
        label="Street"
        bg-color="white"
      />
      <q-input
        borderless
        class="edit-input-lg q-mt-md"
        v-model="city"
        label="City"
        bg-color="white"
      />

      <q-select
        v-model="country"
        use-input
        input-debounce="0"
        label="Select country"
        :options="options"
        @filter="filterFn"
        behavior="menu"
        borderless
        bg-color="white"
        class="edit-input-lg q-mt-md"
      >
        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey">
              No results
            </q-item-section>
          </q-item>
        </template>
      </q-select>
      <q-select
        v-model="parentOrganisationObject"
        use-input
        label="Select parent organisation"
        :options="organisationOptions"
        @filter="filterOrganisationsFn"
        behavior="menu"
        borderless
        bg-color="white"
        class="edit-input-lg q-mt-md"
        option-value="id"
        option-label="name"
      >
        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey">
              No results
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>
    <div class="submit-box">
      <q-btn
        class="orange-btn-filled bold-font"
        label="Save edit"
        @click="updateOrganisationEdit()"
        no-caps
      />
      <q-btn @click="copyOrganisation(id)" class="orange-btn bold-font q-ml-md">
        <i class="las la-2x la-copy"></i>Copy organisation
      </q-btn>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import CustomersService from '../../services/customers.service';
import store from '../../store/index.js';
import OrganisationsService from '../../services/organisations.service';
import OrganisationsErrorModal from '../organisation-components/OrganisationErrorModal.vue';
export default {
  name: 'OrganisationsEdit',
  components: {
    OrganisationsErrorModal,
  },
  setup() {
    const parentOrganisationId = computed({
      get: () => store.state.organisation.selectedOrganisationParentId,
      set: (value) =>
        store.commit('organisation/updateOrganisationParent', value),
    });

    const parentOrganisationObject = computed({
      get: () => store.state.organisation.parentOrganisation.name,
      set: (value) =>
        store.commit('organisation/updateOrganisationParent', value),
    });
    onMounted(() => {
      CustomersService.getAllCountries();
      OrganisationsService.getAllOrganisationsBasic();
      OrganisationsService.getOrganisationById(id);
    });

    const countries = computed({
      get: () => store.state.customers.countriesList,
    });

    const options = ref(countries.value);

    const organisationName = computed({
      get: () => store.state.organisation.currentName,
      set: (value) => store.commit('organisation/updateName', value),
    });

    const organisationEmail = computed({
      get: () => store.state.organisation.currentEmail,
      set: (value) => store.commit('organisation/updateEmail', value),
    });

    const organisationPhone = computed({
      get: () => store.state.organisation.currentPhone,
      set: (value) => store.commit('organisation/updatePhone', value),
    });

    const organisationUrl = computed({
      get: () => store.state.organisation.currentUrl,
      set: (value) => store.commit('organisation/updateUrl', value),
    });

    const street = computed({
      get: () => store.state.organisation.currentStreet,
      set: (value) => store.commit('organisation/updateStreet', value),
    });

    const city = computed({
      get: () => store.state.organisation.defaultCity,
      set: (value) => store.commit('organisation/updateDefaultCity', value),
    });

    const country = computed({
      get: () => store.state.organisation.defaultCountry,
      set: (value) => store.commit('organisation/updateDefaultCountry', value),
    });

    const orgID = computed({
      get: () => store.state.organisation.OrganisationID,
    });

    const allOrganisations = computed({
      get: () => store.state.organisation.organisationsListBasic,
    });

    const organisationOptions = ref(allOrganisations.value);

    const updateOrganisationEdit = async () => {
      await OrganisationsService.editOrganisation(
        orgID,
        organisationName,
        organisationEmail,
        organisationPhone,
        organisationUrl,
        street,
        city,
        country,
        parentOrganisationId
      );
    };
    const route = useRoute();
    const id = route.params.id;
    const copyOrganisation = async (id) => {
      await OrganisationsService.copyOrganisation(id);
    };

    return {
      id,
      route,
      copyOrganisation,
      parentOrganisationObject,
      organisationOptions,
      parentOrganisationId,
      allOrganisations,
      updateOrganisationEdit,
      options,
      country,
      city,
      countries,
      street,
      organisationName,
      organisationEmail,
      organisationUrl,
      orgID,
      organisationPhone,
      filterFn(val, update) {
        update(() => {
          if (val === '') {
            options.value = countries.value;
          } else {
            const needle = val.toLowerCase();

            options.value = countries.value.filter(
              (v) => v.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterOrganisationsFn(val, update) {
        update(() => {
          if (val === '') {
            organisationOptions.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();
            organisationOptions.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
    };
  },
};
</script>
