import axios from 'axios';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
import axiosUrl from './axios.service';
import moment from 'moment';
import AuthService from '../services/auth.service';
import router from '../router';

const API_URL = axiosUrl.getAxiosUrl();

class CustomersService {
  async getAllCountries() {
    return await axios
      .get(API_URL + `customers/getAllCountries`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('customers/updateCountriesList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async getCustomerById(id) {
    return await axios
      .get(API_URL + `customers/getCustomer?customerId=${parseInt(id)}`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('customers/fillCustomerDetails', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
            router.go(-1);
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async getAllCustomers(
    filterSearchTerm,
    filterByActivityStatus,
    sortBy,
    descending,
    page,
    pageSize
  ) {
    return await axios
      .get(API_URL + 'customers/getAllCustomers', {
        params: {
          filterSearchTerm: filterSearchTerm,
          isActive: filterByActivityStatus,
          sortBy: sortBy,
          descending: descending,
          index: page ? page - 1 : 0,
          size: pageSize ?? 50,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('customers/updateAllCustomers', response.data);
        store.commit('customers/updatePagination', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async getCustomersByFilters(customerOwnerId) {
    return await axios
      .get(API_URL + 'customers/getCustomersByFilters', {
        params: {
          customerOwnerId: customerOwnerId,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('customers/updateAllCustomersFiltered', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async getCustomerForUser(userId) {
    return await axios
      .get(API_URL + 'customers/getCustomerForUser', {
        params: {
          userId: userId,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('customers/updateCustomerForUser', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async getCustomerOwnerForUser(userId) {
    return await axios
      .get(API_URL + 'accountManagers/getAccountManagerForUser', {
        params: {
          userId: userId,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit(
          'accountManagers/updateAccountManagerForUser',
          response.data
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async deactivateCustomer(id) {
    return await axios
      .post(
        API_URL + `customers/deactivateCustomer`,
        {
          id: id,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async createNewCustomer(customer) {
    return await axios
      .post(
        API_URL + `customers/addNewCustomer`,
        {
          name: customer.name,
          emailSuffix: customer.emailSuffix,
          startDate: moment(customer.content.startDate, 'DD-MM-YYYY').format(
            'YYYY-MM-DD'
          ),
          endDate:
            customer.content.endDate == null || customer.content.endDate == ''
              ? null
              : moment(customer.content.endDate, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                ),
          demo: customer.content.demo,
          numberOfLicences: customer.content.numberOfLicences,
          crmIdentifier: customer.crmIdentifier,
          customerType: customer.customerType,
          accountManager: customer.accountManager,
          // isFomoAccess: customer.isFomoAccess,
          isFomoAccess: false, // THIS REMOVE???
          languageCode: customer.languageCode,
          debugEmail: customer.debugEmail,
          deliveryMethod: customer.deliveryMethod,
          customerSettings: {
            reminderInterval: customer.sas.reminderInterval,
            canAllCompleteTask: customer.sas.canAllCompleteTask,
            senderAddress: customer.sas.emailSendFrom,
            emailSignature: customer.sas.emailSignature,
            replyToEmail: customer.sas.emailReplyAddress,
            useCustomEmail: customer.sas.useCustomEmailAddress,
            useSso: customer.sas.ssoOr2Fa.toLowerCase() == 'sso',
            use2Fa: customer.sas.ssoOr2Fa.toLowerCase() == '2fa',
          },
          firstCategorization: customer.sas.categorization1,
          secondCategorization: customer.sas.categorization2,
          tenantId: customer.sas.tenantId,
          sasStartDate: moment(customer.sas.sasStartDate, 'DD-MM-YYYY').format(
            'YYYY-MM-DD'
          ),
          sasEndDate:
            customer.sas.sasEndDate == null || customer.sas.sasEndDate == ''
              ? null
              : moment(customer.sas.sasEndDate, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                ),
          hasSelectedProductSas: customer.selectedProducts.includes('SAS'),
          hasSelectedProductContent: customer.selectedProducts.includes(
            'Content'
          ),
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.status === 200) {
          router.push({
            name: 'EditCustomer',
            params: {
              id: res.data.id,
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async updateCustomer(customer) {
    return await axios
      .post(
        API_URL + `customers/updateCustomer`,
        {
          id: customer.id.value,
          name: customer.name.value,
          demo: customer.demo.value,
          linkedinUrl: customer.linkedinUrl.value,
          twitterUrl: customer.twitterUrl.value,
          facebookUrl: customer.facebookUrl.value,
          baseUrl: customer.baseUrl.value,
          customerLogoPath: customer.customerLogoPath.value,
          isFomoAccess: customer.isFomoAccess.value,
          languageCode: customer.languageCode.value,
          deliveryMethod: customer.deliveryMethod.value,
          has2FA: customer.has2FA.value,
          useSSO: customer.useSSO.value,
          emailAddress: customer.emailAddress.value,
          phoneNumber: customer.phoneNumber.value,
          emailSuffix: customer.emailSuffix.value,
          debugEmail: customer.debugEmail.value,
          endDate:
            customer.endDate.value == null ||
            customer.endDate.value == '' ||
            customer.endDate.value === undefined
              ? null
              : moment(customer.endDate.value, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                ),
          startDate:
            customer.startDate.value == null ||
            customer.startDate.value == '' ||
            customer.startDate.value === undefined
              ? null
              : moment(customer.startDate.value, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                ),
          sasEndDate:
            customer.sasEndDate.value == null ||
            customer.sasEndDate.value == '' ||
            customer.sasEndDate.value === undefined
              ? null
              : moment(customer.sasEndDate.value, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                ),
          sasStartDate:
            customer.sasStartDate.value == null ||
            customer.sasStartDate.value == '' ||
            customer.sasStartDate.value === undefined
              ? null
              : moment(customer.sasStartDate.value, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                ),
          numberOfLicences: customer.numberOfLicences.value,
          crmIdentifier: customer.crmIdentifier.value,
          customerType: customer.customerType.value,
          environmentType: customer.environment.value,
          accountManager: customer.accountManager.value,
          tags: customer.customerAudienceTags.value
            .concat(customer.customerPurposesTags.value)
            .concat(customer.customerLocationsTags.value),
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit('customers/fillCustomerDetails', response.data);
        store.commit('customers/enableSuccessfullyUpdatedDialog');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async getCustomerSelectedTags(customerId, tagType, tagTypeValue) {
    return await axios
      .get(
        API_URL +
          `customers/getCustomerSelectedTags?customerId=${customerId}&tagType=${tagTypeValue}`,
        {
          params: {
            culture: 'nl',
          },
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var type = tagType.split(' ').join('');
          store.commit(
            'customers/setSelectedCustomer' + `${type}` + 'Tags',
            response.data
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'scheme/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('scheme/enableModalError');
          } else {
            store.commit('scheme/updateModalError', error),
              store.commit('scheme/enableModalError');
          }
        }
      });
  }

  async deleteCustomer(id) {
    return await axios
      .post(
        API_URL + `customers/deleteCustomer`,
        {
          id: id,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error.response.status === 401) {
          // auto logout if 401 response returned from api
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  getCustomerTypes() {
    return axios
      .get(API_URL + `customers/getCustomerTypes`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('customers/updateCustomerTypesList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async getLanguageCodes() {
    return await axios
      .get(API_URL + `customers/getAllLanguageCodesEnums`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('customers/updateLanguageCodes', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'news/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async getCustomerTypesNew() {
    return await axios
      .get(API_URL + `customers/getCustomerTypesNew`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('customers/updateCustomerTypesList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }
  getEnvironmentTypes() {
    return axios
      .get(API_URL + `customers/getEnvironmentTypes`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('enums/updateEnvironmentTypesList', response.data);
      });
  }
  // getAccountManagersWithBasicInfo
  async getCustomerOwners() {
    return await axios
      .get(API_URL + `accountManagers/getAccountManagersWithBasicInfo`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit(
          'accountManagers/updateAccountManagersBasicInfoList',
          response.data
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async getCustomersByType(type) {
    return await axios
      .get(API_URL + `customers/getCustomersByType?customerType=${type}`, {
        headers: authHeader(),
        'Content-Type': 'application/json',
      })
      .then((response) => {
        store.commit(
          'customers/updateModalPieChartCustomersData',
          response.data
        );
        store.commit('customers/updateShowPieChartModal', true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }

  async getPortalCustomers() {
    return await axios
      .get(API_URL + 'customers/getPortalCustomers', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('customers/updatePortalCustomersList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
          router.go();
        }
        if (error) {
          store.commit(
            'customers/updateModalError',
            error.response.data.message
          ),
            store.commit('customers/enableModalError');
        }
      });
  }

  async getNonPortalCustomers() {
    return await axios
      .get(API_URL + 'customers/getNonPortalCustomers', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('consumptionStats/updateCustomersList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
          router.go();
        }
        if (error) {
          store.commit(
            'customers/updateModalError',
            error.response.data.message
          ),
            store.commit('customers/enableModalError');
        }
      });
  }
  async deleteCustomerTenantId(id) {
    return await axios
      .post(
        API_URL + `customers/deleteCustomerTenant`,
        {
          id: id,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit('customers/updateCustomerTenantIdsList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // auto logout if 401 response returned from api
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }
  async getDeliveryMethods() {
    return await axios
      .get(API_URL + `customers/getDeliveryMethods`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('customers/updateDeliveryMethodsList', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }
  async addCustomerTenant(id) {
    return await axios
      .post(
        API_URL + `customers/addCustomerTenant`,
        {
          tenantId: id,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit('customers/updateCustomerTenantIdsList', response.data);
      })
      .catch((error) => {
        store.commit('customers/updateModalError', error.response.data),
          store.commit('customers/enableModalError');
      });
  }

  async getReminderIntervals() {
    return await axios
      .get(API_URL + `customers/getAllReminderIntervals`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('customers/updateReminderIntervalOptions', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'customers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('customers/enableModalError');
          } else {
            store.commit('customers/updateModalError', error.response.data),
              store.commit('customers/enableModalError');
          }
        }
      });
  }
}

export default new CustomersService();
