<template>
  <div class="news-sidebar">
    <q-btn
      class="orange-btn-filled bold-font newsSearch-btn"
      no-caps
      @click="searchNews()"
    >
      <i class="las la-search search-icon-news"></i> Search
    </q-btn>
    <div class="sidebar-items">
      <q-list class="filters-list">
        <q-expansion-item
          label="Keyword"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <div class="input-group mb-3 searchbox">
            <q-input
              class="search-input q-mt-sm"
              borderless
              stack-label
              bg-color="white"
              v-model="searchText"
              label="Search news"
              @change="updateSearchText(searchText)"
              v-on:keyup.enter="searchNews()"
            >
              <template v-slot:append>
                <q-icon name="search" class="search-input-icon" />
              </template>
            </q-input>
          </div>
        </q-expansion-item>

        <q-expansion-item
          label="Dates"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <q-input
            class=" date-input q-mt-md"
            borderless
            bg-color="white"
            v-model="dateFrom"
            label="Date from"
            stack-label
            :change="updateFrom(dateFrom)"
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer date-icon">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date v-model="dateFrom" mask="DD-MM-YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>

          <q-input
            class=" date-input q-mt-sm"
            borderless
            bg-color="white"
            v-model="dateTo"
            label="Date to"
            stack-label
            :change="updateTo(dateTo)"
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer date-icon">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date v-model="dateTo" mask="DD-MM-YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </q-expansion-item>

        <q-expansion-item
          label="Organisations"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <div class="filter-box  item-width orgs-box">
            <div class="row start orgs-select">
              <q-select
                borderless
                bg-color="white"
                v-model="organisationsInclude"
                multiple
                :options="allOrganisations"
                use-chips
                option-value="id"
                class="budget-input"
                option-label="name"
                stack-label
                label="Include organisation(s)"
                map-options
                emit-value
              />
            </div>

            <div class="row start orgs-select q-mt-sm">
              <q-select
                borderless
                bg-color="white"
                v-model="organisationsExclude"
                multiple
                :options="allOrganisations"
                use-chips
                option-value="id"
                class="budget-input"
                option-label="name"
                stack-label
                label="Exclude organisation(s)"
                map-options
                emit-value
              />
            </div>
          </div>
        </q-expansion-item>

        <q-expansion-item
          label="Scheme(s)"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <div class="filter-box  item-width orgs-box">
            <div class="row start orgs-select">
              <q-select
                borderless
                bg-color="white"
                class="budget-input"
                v-model="selectedSchemes"
                option-value="id"
                option-label="name"
                multiple
                label="Select scheme(s)"
                :options="schemesOptions"
                stack-label
                use-input
                input-debounce="0"
                @filter="filterSchemesFn"
                use-chips
                ref="searchNewsSechemeSelect"
                @update:model-value="setInput()"
              />
            </div>
          </div>
        </q-expansion-item>

        <q-expansion-item
          label="For who are you searching?"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <default-tree
            class=" item-width expandItem-tree"
            :nodesState="audienceNodes.options"
            :filtering="true"
            :news="'news'"
            :key="'1'"
            :tagType="audienceNodes.value"
          />
        </q-expansion-item>

        <q-expansion-item
          label="Subject"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <default-tree
            class=" item-width"
            :nodesState="purposesNodes.options"
            :news="'news'"
            :tagType="purposesNodes.value"
            :key="'3'"
          />
        </q-expansion-item>

        <q-expansion-item
          label="Type of support"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <default-tree
            class=" item-width"
            :nodesState="schemeTypesNodes.options"
            :news="'news'"
            :tagType="schemeTypesNodes.value"
            :key="'4'"
          />
        </q-expansion-item>

        <q-expansion-item
          label="Project types"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <default-tree
            class="item-width"
            :nodesState="projectTypesNodes.options"
            :news="'news'"
            :tagType="projectTypesNodes.value"
            :key="'6'"
          />
        </q-expansion-item>

        <q-expansion-item
          label="Locations"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <div class="filter-box item-width orgs-box">
            <div class="row start orgs-select">
              <q-select
                borderless
                bg-color="white"
                v-model="selectedLocations"
                multiple
                :options="filterLocationsTags"
                option-value="id"
                class="budget-input"
                option-label="label"
                stack-label
                label="Select locations"
                use-input
                use-chips
                input-debounce="0"
                behavior="menu"
                @filter="filterLocationsFn"
                emit-value
                map-options
                ref="newsSearchLocationsSelect"
                @update:model-value="setInput()"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
          </div>
        </q-expansion-item>

        <q-expansion-item
          label="German locations"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <div class="filter-box item-width orgs-box">
            <div class="row start orgs-select">
              <q-select
                borderless
                bg-color="white"
                class="budget-input"
                multiple
                v-model="selectedGermanLocationsPaths"
                :options="germanLocationTagsBulkList"
                @filter="filterGermanLocationTagsBulk"
                placeholder="Start typing German locations"
                use-chips
                use-input
                input-debounce="500"
                behavior="menu"
                option-value="path"
                option-label="label"
                dense
                emit-value
                map-options
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
          </div>
        </q-expansion-item>
      </q-list>
    </div>
    <q-btn
      class="orange-btn-filled bold-font newsSearch-btn q-mb-xl"
      no-caps
      @click="searchNews()"
    >
      <i class="las la-search search-icon-news"></i> Search
    </q-btn>
  </div>
</template>

<script>
import store from '../../store/index';
import { computed, onMounted, ref, watch } from 'vue';
import moment from 'moment';
import NewsService from '../../services/news.service';
import DefaultTree from '../search-components/DefaultTree.vue';
import FiltersService from '../../services/filters.service';
import OrganisationsService from '../../services/organisations.service';
import SchemesService from '../../services/schemes.services';

export default {
  components: {
    DefaultTree,
  },
  setup() {
    onMounted(async () => {
      await NewsService.getSchemesForSelectSearch();
      FiltersService.getDefaultFilters();
      OrganisationsService.getAllOrganisationsList();
    });

    const dateFrom = ref(
      moment()
        .add(-1, 'year')
        .format('DD-MM-YYYY')
    );
    const dateTo = ref(moment().format('DD-MM-YYYY'));

    const searchNews = async () => {
      store.commit('newsSearch/updateSelectedSchemeIds');
      await NewsService.getSearchResults(
        searchText,
        dateFrom.value,
        dateTo.value,
        audienceTags,
        schemesTags,
        projectTypesTags,
        selectedLocations,
        selectedGermanLocationsPaths,
        purposesTags,
        //This is just hidden for now, don't remove it
        //industriesTags,
        organisationsInclude,
        organisationsExclude,
        selectedSchemesIds
      );
    };

    const updateFrom = (value) => {
      store.commit('newsSearch/updateDateFrom', value);
    };
    const updateTo = (value) => {
      store.commit('newsSearch/updateDateTo', value);
    };
    const updateSearchText = (searchText) => {
      store.commit('newsSearch/updateSearchText', searchText);
    };
    const audienceNodes = computed({
      get: () => store.state.advancedSearch.audience,
    });

    //This is just hidden for now, don't remove it
    /*
    const industriesNodes = computed({
      get: () => store.state.advancedSearch.industries,
    });
    */
    /*const locationsNodes = computed({
      get: () => store.state.advancedSearch.locations,
    });*/
    const purposesNodes = computed({
      get: () => store.state.advancedSearch.purposes,
    });
    const schemeTypesNodes = computed({
      get: () => store.state.advancedSearch.schemeTypes,
    });
    const projectTypesNodes = computed({
      get: () => store.state.advancedSearch.projectTypes,
    });
    //get selected nodes
    const audienceTags = computed({
      get: () => store.state.newsSearch.selectedAudiences,
    });
    const purposesTags = computed({
      get: () => store.state.newsSearch.selectedPurposes,
    });
    const schemesTags = computed({
      get: () => store.state.newsSearch.selectedSchemeTypes,
    });
    const projectTypesTags = computed({
      get: () => store.state.newsSearch.selectedProjectTypes,
    });

    //This is just hidden for now, don't remove it
    /*
    const industriesTags = computed({
      get: () => store.state.newsSearch.selectedIndustries,
    });
    */
    const allOrganisations = computed({
      get: () => store.state.organisation.organisationsList,
    });
    const organisationsInclude = computed({
      get: () => store.state.advancedSearch.organisationsInclude,
      set: (val) =>
        store.commit('advancedSearch/updateOrganisationsInclude', val),
    });
    const organisationsExclude = computed({
      get: () => store.state.advancedSearch.organisationsExclude,
      set: (val) =>
        store.commit('advancedSearch/updateOrganisationsExclude', val),
    });
    //get data AdvancedSearch - locations
    const locationsOptions = computed({
      get: () => store.state.advancedSearch.locationsOptions,
    });
    const filterLocationsTags = ref(locationsOptions.value);
    const selectedLocations = computed({
      get: () =>
        store.state.newsSearch.selectedLocations &&
        store.state.newsSearch.selectedLocations.length > 0
          ? store.state.newsSearch.selectedLocations
          : JSON.parse(sessionStorage.getItem('newsSelectedLocations')),
      set: (value) => {
        store.commit('newsSearch/updateSelectedLocations', value);
      },
    });

    // Update filterLocationsTags after refreshing the page. It will not be updated automatically (it will be empty) because Vue does not persist the state of reactive variables refreshing the page
    watch(
      () => locationsOptions.value,
      (loadedLocationsOptions) => {
        filterLocationsTags.value = loadedLocationsOptions;
      },
      { immediate: true }
    );

    // German locations
    const germanLocationTagsBulkList = computed({
      get: () => store.state.scheme.locationTagsBulkList,
    });
    const selectedGermanLocationsPaths = computed({
      get: () => store.state.newsSearch.selectedGermanLocationsPaths,
      set: (val) => {
        store.commit("newsSearch/updateSelectedGermanLocationsPaths", val);
      },
    });

    const searchText = ref('');

    const selectedSchemes = computed({
      get: () => store.state.newsSearch.selectedSchemes,
      set: async (val) => {
        store.commit('newsSearch/updateSelectedSchemes', val);
      },
    });

    const allSchemes = computed({
      get: () => store.state.newsSearch.allSchemes,
    });

    const schemesOptions = ref(allSchemes.value);

    const selectedSchemesIds = computed({
      get: () => store.state.newsSearch.selectedSchemesIds,
    });

    const searchNewsSechemeSelect = ref(null);
    const newsSearchLocationsSelect = ref(null);
    const setInput = () => {
      searchNewsSechemeSelect.value.updateInputValue('');
      newsSearchLocationsSelect.value.updateInputValue('');
    };

    return {
      newsSearchLocationsSelect,
      searchNewsSechemeSelect,
      setInput,
      selectedSchemesIds,
      schemesOptions,
      selectedSchemes,
      filterLocationsTags,
      locationsOptions,
      selectedLocations,
      OrganisationsService,
      audienceNodes,
      //This is just hidden for now, don't remove it
      //industriesNodes,
      //locationsNodes,
      purposesNodes,
      schemeTypesNodes,
      projectTypesNodes,
      searchText,
      updateFrom,
      updateSearchText,
      dateFrom,
      dateTo,
      updateTo,
      searchNews,
      allOrganisations,
      organisationsInclude,
      organisationsExclude,
      germanLocationTagsBulkList,
      selectedGermanLocationsPaths,
      filterLocationsFn(val, update, abort) {
        if (val.length < 1) {
          abort();
          return;
        }
        update(() => {
          if (val === '') {
            filterLocationsTags.value = locationsOptions.value;
          } else {
            const needle = val.toLowerCase();

            filterLocationsTags.value = locationsOptions.value.filter(
              (v) => v.label.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },

      filterGermanLocationTagsBulk(val, update) {
        if (!val || val == '' || val.length < 2) return [];
        update(() => {
          if (!val || val == '' || val.length < 2) return [];
          else {
            SchemesService.searchLocationCodesBulk(val);
          }
        });
      },

      filterSchemesFn(val, update) {
        update(() => {
          if (val === '') {
            schemesOptions.value = [];
          } else {
            if (!isNaN(parseInt(val))) {
              const idVal = parseInt(val);
              schemesOptions.value = allSchemes.value.filter(
                (v) => v.id === idVal
              );
            } else {
              const needle = val.toLowerCase();

              schemesOptions.value = allSchemes.value.filter((v) => {
                if (v.name != null && v.name.toLowerCase().indexOf(needle) > -1)
                  return v;
              });
            }
          }
        });
      },
    };
  },
};
</script>
