<template>
  <q-dialog v-model="showAddLinkDialog" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div>New link</div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none">
        <div class="row justify-around">
          <q-input
            dense
            v-model="addLink.url"
            label="Input url"
            :rules="requiredFieldRule"
            lazy-rules
          />
        </div>
        <div class="row justify-around">
          <q-input
            dense
            v-model="addLink.text"
            label="Input link text"
            :rules="requiredFieldRule"
            lazy-rules
          />
        </div>
         <q-select
            v-if="showCategoriesOptions"
            outlined
            bg-color="white"
            v-model="addLink.documentType"
            :options="documentTypes"
            option-value="id"
            option-label="description"
            stack-label
            label="Document type"
            dense
            emit-value
            map-options
          />
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          color="primary"
          label="Save"
          @click="addLinkToScheme"
          :disable="addLink.url != '' ? null : true"
        />
        <q-btn
          color="primary"
          outline
          label="Cancel"
          @click="disableAddLink"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import store from '../../store/index';
import { useVModel } from '../../composables/useVModel';
import EDocumentType from '../../enums/EDocumentType';

export default {
  name: 'AddLink',
  emits: ['update:selectedSchemeLinks'],
  props: {
    selectedSchemeLinks: Array,
    showCategories: Boolean
  },
  setup(props) {
    const selectedSchemeLinksState = ref(useVModel(props, 'selectedSchemeLinks'));
    const showCategoriesOptions = computed(() => {return props.showCategories});

    const showAddLinkDialog = computed({
      get: () => store.state.scheme.showAddLink,
      set: (value) => store.commit('scheme/updateShowAddLink', value),
    });
    const documentTypes = computed({
      get: () => store.state.scheme.documentTypes,
    });

    const addLink = reactive({
      url: '',
      text: '',
      documentType: null
    });
    
    const resetAddLink = () => {
      addLink.text = '';
      addLink.url = '';
      addLink.documentType = null
    };

    const addLinkToScheme = async () => {
      if (addLink.text == '') addLink.text = addLink.url;
      let docTypeDesc = addLink.documentType && addLink.documentType !== EDocumentType.UsefulLinks ? documentTypes.value.find(({ id }) => id === addLink.documentType).description : '';

      selectedSchemeLinksState.value.push({ url: addLink.url, text: addLink.text, documentType: addLink.documentType, documentTypeDescription: docTypeDesc });
      disableAddLink();
    };

    const disableAddLink = () => {
      resetAddLink();
      showAddLinkDialog.value = false;
    };

    return {
      selectedSchemeLinksState,
      showAddLinkDialog,
      addLinkToScheme,
      disableAddLink,
      addLink,
      showCategoriesOptions,
      resetAddLink,
      documentTypes,
      EDocumentType,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || 'Field is required!',
      ],
    }
  },
};
</script>
