const state = () => ({
  accountManagersBasicInfoList: [],
  modalErrorMessage: '',
  hasModalError: false,
  showAdd: false,
});

const getters = {};

const actions = {};

const mutations = {
  updateAccountManagersBasicInfoList(state, data) {
    if (state.accountManagersBasicInfoList.length > 0)
      state.accountManagersBasicInfoList = [];

    state.accountManagersBasicInfoList = data;
  },
  updateAccountManagerForUser(state, val) {
    state.accountManagersBasicInfoList = [];
    state.accountManagersBasicInfoList[0] = val;
  },

  enableAdd(state) {
    state.showAdd = true;
  },
  disableAdd(state) {
    state.showAdd = false;
  },
  resetModalError(state) {
    state.modalErrorMessage = '';
  },
  enableModalError(state) {
    state.hasModalError = true;
  },
  disableModalError(state) {
    state.hasModalError = false;
    state.modalErrorMessage = '';
  },
  updateModalError(state, message) {
    state.modalErrorMessage = message;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
