<template>
  <ErrorModal />
  <q-dialog v-model="showAddVisualPingLink" persistent class="modal-general">
    <q-card style="min-width: 50vw">
      <q-card-section>
        <div>Add link</div>
      </q-card-section>

      <q-separator />
      <q-card-section class="q-pt-none">
        <div class="row justify-around">
          <q-input
            dense
            style="min-width: 30vw; max-width: 50vw"
            v-model="addLink.url"
            label="Input url"
            :rules="[requiredFieldRule, isValidUrl]"
            lazy-rules
            borderless
            bg-color="white"
            class="q-mt-md"
          />
        </div>
        <div class="row justify-around">
          <q-input
            dense
            style="min-width: 30vw; max-width: 50vw"
            v-model="addLink.text"
            label="Input link text"
            :rules="requiredFieldRule"
            lazy-rules
            borderless
            bg-color="white"
          />
        </div>

        <q-select
          v-if="schemeId == null"
          borderless
          bg-color="white"
          style="min-width: 30vw; max-width: 50vw"
          use-input
          hide-selected
          fill-input
          input-debounce="0"
          label="Select scheme"
          stack-label
          v-model="addLink.idOfSelectedScheme"
          :options="schemesOptions"
          @filter="filterDebounce"
          @filter-abort="abortFilterFn"
          option-value="id"
          option-label="name"
          emit-value
          map-options
          hint="If you don't select a scheme, this link will be saved as a 'General Visualping link'"
          placeholder="Start typing to search"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          class="orange-btn-filled bold-font"
          no-caps
          label="Save"
          @click="addLinkToSchemeAndVisualPing"
          :disable="addLink.url != '' ? null : true"
        />
        <q-btn
          class="orange-btn bold-font"
          no-caps
          label="Cancel"
          @click="disableAddVisualPingLink"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import store from '../../store/index';
import ErrorModal from './SchemeModalError.vue';
import linkcheckerService from '../../services/linkchecker.service';
import schemesServices from '../../services/schemes.services';
import { debounce } from 'quasar';
import LinkcheckerService from '../../services/linkchecker.service';

export default {
  name: 'AddViusalPingLink',
  components: {
    ErrorModal,
  },
  props: {
    selectedSchemeId: Number,
    searchFilter: String,
    toggleUnchangedLinks: Boolean,
    toggleChangedLinks: Boolean,
    toggleBrokenLinks: Boolean,
  },
  setup(props) {
    const schemesOptions = ref([]);
    const schemeId = computed(() => {
      return props.selectedSchemeId;
    });
    const searchFilterTerm = computed(() => {
      return props.searchFilter;
    })
    const toggleUnchangedLinksShow = computed(() => {
      return props.toggleUnchangedLinks;
    });

    const toggleChangedLinksShow = computed(() => {
      return props.toggleChangedLinks;
    });

    const toggleBrokenLinksShow = computed(() => {
      return props.toggleBrokenLinks;
    });

    const showAddVisualPingLink = computed({
      get: () => store.state.scheme.showAddVisualPingLink,
      set: (value) => store.commit('scheme/updateShowAddVisualPingLink', value),
    });

    const addLink = reactive({
      url: '',
      text: '',
      idOfSelectedScheme: null,
    });

    const resetAddLink = () => {
      addLink.text = '';
      addLink.url = '';
      addLink.idOfSelectedScheme = null;
    };

    const isValidUrl = (urlString) => {
      var urlPattern = new RegExp(
        '^(https?:\\/\\/)?' +
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
          '((\\d{1,3}\\.){3}\\d{1,3}))' +
          '(\\:\\d+)?(\\/[-a-z\\d%_.,~+]*)*' +
          '(\\?[;&a-z\\d%_.,~+=-]*)?' +
          '(\\#[-a-z\\d_]*)?$',
        'i'
      );

      return urlPattern.test(urlString) || 'Invalid URL';
    };
    const addLinkToSchemeAndVisualPing = async () => {
      if (addLink.text == '') addLink.text = addLink.url;
     linkcheckerService.addVisualPingLink(schemeId, addLink);
       await refreshPingLinks();
      disableAddVisualPingLink();
    };

    const disableAddVisualPingLink = () => {
      resetAddLink();
      showAddVisualPingLink.value = false;
    };

    const filterFn = async (val, update, abort) => {
      if (val.length > 0) {
        const term = val.toLowerCase();
        const response = await new Promise((resolve) => {
          resolve(schemesServices.getFilteredSchemesForSelect(term));
        });

        let list = response.data;

        update(() => {
          schemesOptions.value = list;
        });
      } else {
        abort();
      }
    };

    const filterDebounce = debounce(filterFn, 1500);
    const pagination = ref(
      computed({
        get: () => store.state.linkChecker.pagination,
        set: (paginationVar) =>
          store.commit('linkChecker/updatePagination', paginationVar),
      })
    );
    const getFetchCount = () => {
      return pagination.value.rowsPerPage === 0
        ? pagination.value.rowsNumber
        : pagination.value.rowsPerPage;
    };
    const refreshPingLinks = async () => {
      await LinkcheckerService.getVisualPingLinks(
        searchFilterTerm.value,
        toggleUnchangedLinksShow.value,
        toggleChangedLinksShow.value,
        toggleBrokenLinksShow.value,
        pagination.value.sortBy,
        pagination.value.descending,
        pagination.value.page,
        getFetchCount()
      );
    };
    return {
      refreshPingLinks,
      searchFilterTerm,
      toggleUnchangedLinksShow,
      toggleChangedLinksShow,
      toggleBrokenLinksShow,
      schemeId,
      showAddVisualPingLink,
      addLinkToSchemeAndVisualPing,
      disableAddVisualPingLink,
      addLink,
      resetAddLink,
      schemesOptions,
      filterDebounce,
      filterFn,
      pagination,
      getFetchCount,
      isValidUrl,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || 'Field is required!',
      ],
      abortFilterFn() {},
    };
  },
};
</script>
