<template>
  <q-tab-panel class="panel-main" :name="tabName">
    <div class="text-h6">{{ treeLabel }}</div>
    <ErrorModal />
    <div class="panel-container row justify-between q-mr-xl">
      <Tree :nodesState="treeNodes" languageCode="nl" :tagType="tagTypeValue"/>
      <Tree :nodesState="treeNodesDe" languageCode="de" :tagType="tagTypeValue" v-if="tagTypeValue === ETagType.Audience || tagTypeValue === ETagType.IndustriesNew"/>
      <div class="column boxes-column">
        <EditBox v-if="showEdit" :tagType="tagTypeValue"/>
        <AddBox v-if="showAdd" :tagType="tagTypeValue"/>
        <CreateBox v-if="showCreate" :tagType="tagTypeValue"/>
        <AddLocationGroup v-if="showCreateLocationGroup"/>
        <EditGroupTags v-if="showEditGroupTag"/>
      </div>
      <LocationGroupTab  v-if="tagTypeValue == ETagType.Locations" />
    </div>
  </q-tab-panel>
</template>

<script>
import Tree from "../tags-components/Tree.vue";
import EditBox from "../tags-components/EditBox.vue";
import AddBox from "../tags-components/AddBox.vue";
import CreateBox from "../tags-components/CreateBox.vue";
import ErrorModal from "../tags-components/ErrorModal";
import AddLocationGroup from "../tags-components/AddLocationGroup.vue";
import LocationGroupTab from "../tags-components/LocationGroupTab.vue";
import EditGroupTags from "../tags-components/EditGroupTags.vue";
import store from "../../store/index";
import { computed } from "vue";
import TagsService from "../../services/tags.service";
import { ref, onMounted } from "vue";
import ETagType from "../../enums/ETagType";

export default {
  props: {
    name: String,
    tagType: Number
  },
  components: {
    Tree,
    EditBox,
    AddBox,
    ErrorModal,
    CreateBox,
    AddLocationGroup,
    EditGroupTags,
    LocationGroupTab,
},

  setup(props) {
    const showEdit = computed({
      get: () => store.state.tree.showEdit,
    });

    const showAdd = computed({
      get: () => store.state.tree.showAdd,
    });

    const showCreate = computed({
      get: () => store.state.tree.showCreate,
    });

    const treeNodes = computed({
      get: () => store.state.tree.treeNodes,
    });

    const treeNodesDe = computed({
      get: () => store.state.tree.treeNodesDe,
    });

    const treeLabel = computed({
      get: () => store.state.tree.treeLabel,
    });

    const treeLocationGroupLabel = computed({
      get: () => store.state.tree.treeNodesLocationGroupLabel,
    });
    const tagTypeValue = ref(props.tagType);
    const tabName = ref(props.name);
  
    onMounted(async () => {
      store.commit("tree/disableAllBoxes");

      await TagsService.getTagsTree(tagTypeValue, 'nl');

      if (tagTypeValue.value === ETagType.Audience || tagTypeValue.value === ETagType.IndustriesNew)
        await TagsService.getTagsTree(tagTypeValue, 'de');
    });

    const displayCreateLocationGroup = () => {
      store.commit("tree/enableCreateLocationGroup");
    };

    const displayEditGroup = (id, tickedTags) => {
      store.commit("tree/enableEditGroupTag");
      store.commit("tree/updateLocationTickedTags", tickedTags);
      store.commit("tree/updateSelectedTreeGroupId", id);
    };

    const showCreateLocationGroup = computed({
      get: () => store.state.tree.showCreateLocationGroup,
    });

    const showEditGroupTag = computed({
      get: () => store.state.tree.showEditGroupTag,
    });
    const locationGroupTreeList = computed({
      get: () => store.state.tree.locationGroupTreeList,
    });

    return {
      treeNodes,
      treeNodesDe,
      showCreateLocationGroup,
      locationGroupTreeList,
      tabName,
      showEdit,
      showAdd,
      showCreate,
      treeLabel,
      displayCreateLocationGroup,
      displayEditGroup,
      treeLocationGroupLabel,
      showEditGroupTag,
      tagTypeValue,
      ETagType,
    };
  },
};
</script>
