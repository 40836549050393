const state = () => ({
  tagsList: [],
  audienceTags: [{}],
  purposesTags: [{}],
  //This is just hidden for now, don't remove it
  //industriesTags: [{}],
  locationsTags: [{}],
  schemeTypesTags: [{}],
  projectTypesTags: [{}],
  keywordsTags: [],
  industriesNewTags: [{}],
  userStatusTags: [{}],
  educationLevelTags: [{}]
});

const getters = {};

const actions = {};

const mutations = {
  updateTagsList(state, tags) {
    state.tagsList = tags;
  },
  fillAudienceTags(state, tags) {
    state.audienceTags = [{}];
    for (let i = 0; i < tags.length; i++) {
      state.audienceTags[i] = {
        id: tags[i].id,
        name: tags[i].name,
      };
    }
  },
  fillPurposesTags(state, tags) {
    state.purposesTags = [{}];
    for (let i = 0; i < tags.length; i++) {
      state.purposesTags[i] = {
        id: tags[i].id,
        name: tags[i].name,
      };
    }
  },
  //This is just hidden for now, don't remove it
  /*fillIndustriesTags(state, tags) {
    state.industriesTags = [{}];
    for (let i = 0; i < tags.length; i++) {
      state.industriesTags[i] = {
        id: tags[i].id,
        name: tags[i].name,
      };
    }
  },
  */
  fillLocationsTags(state, tags) {
    state.locationsTags = [{}];
    for (let i = 0; i < tags.length; i++) {
      state.locationsTags[i] = {
        id: tags[i].id,
        name: tags[i].name,
      };
    }
  },
  fillSchemetypeTags(state, tags) {
    state.schemeTypesTags = [{}];
    for (let i = 0; i < tags.length; i++) {
      state.schemeTypesTags[i] = {
        id: tags[i].id,
        name: tags[i].name,
      };
    }
  },
  fillProjecttypeTags(state, tags) {
    state.projectTypesTags = [{}];
    for (let i = 0; i < tags.length; i++) {
      state.projectTypesTags[i] = {
        id: tags[i].id,
        name: tags[i].name,
      };
    }
  },
  fillKeywordsTags(state, tags) {
    state.keywordsTags = [];
    for (let i = 0; i < tags.length; i++) {
      state.keywordsTags[i] = tags[i].name;
    }
  },
  updateAllKeywordsTags(state, value) {
    state.keywordsTags.push(value);
  },
  fillIndustriesNEWTags(state, tags) {
    state.industriesNewTags = [{}];
    for (let i = 0; i < tags.length; i++) {
      state.industriesNewTags[i] = {
        id: tags[i].id,
        name: tags[i].name,
      };
    }
  },
  fillUserStatusTags(state, tags) {
    state.userStatusTags = [{}];
    for (let i = 0; i < tags.length; i++) {
      state.userStatusTags[i] = {
        id: tags[i].id,
        name: tags[i].name,
      };
    }
  },
  fillEducationLevelTags(state, tags) {
    state.educationLevelTags = [{}];
    for (let i = 0; i < tags.length; i++) {
      state.educationLevelTags[i] = {
        id: tags[i].id,
        name: tags[i].name,
      };
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
