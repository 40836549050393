<template>
  <div>
    <q-dialog v-model="showAddDocumentDialog" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div>Add document</div>
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pt-none">
          <q-file 
            outlined 
            v-model="selectedFile"
            dense
            class="q-pa-md"
            label="Pick one file"
          >
            <template v-slot:prepend>
              <q-icon name="las la-paperclip" />
            </template>
          </q-file>
          <q-input
            dense
            outlined
            v-model="documentName"
            label="New name:"
            lazy-rules
            class="q-pa-md"
          />
         <q-select
            outlined
            bg-color="white"
            v-model="documentType"
            :options="documentTypes"
            option-value="id"
            option-label="description"
            stack-label
            label="Document type"
            dense
            class="q-pa-md"
            emit-value
            map-options
          />
        </q-card-section>  
        <q-card-actions align="around">
          <q-btn
            color="primary"
            label="Add"
            @click="uploadFile(selectedFile, documentName, documentType)"
            :disable="selectedFile == null || documentType == null"
          />
          <q-btn
            color="primary"
            outline
            label="Cancel"
            @click="disableAddDocument"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import SchemesServices from '../../services/schemes.services';
import { useRoute } from 'vue-router';
import { ref, computed } from 'vue';
import store from '../../store';
import { useVModel } from '../../composables/useVModel';
import EDocumentType from '../../enums/EDocumentType';


export default {
  name: 'AddFile',
  props: {
    selectedSchemeDocuments: Array,
  },
  setup(props) {
    const route = useRoute();
    const id = route.params.id;
    const selectedFile = ref(null);
    const documentName = ref('');
    const documentType = ref(EDocumentType.Overig);
    const selectedSchemeDocumentsState = ref(useVModel(props, 'selectedSchemeDocuments'));
  
    const showAddDocumentDialog = computed({
      get: () => store.state.scheme.showAddDocument,
      set: (value) => store.commit('scheme/updateShowAddDocument', value),
    });

    const documentTypes = computed({
      get: () => store.state.scheme.documentTypes,
    });

    const uploadFile = async (file, name, type) => {
      if (id === null || id === undefined) {
        let docTypeDesc = type ? documentTypes.value.find(({ id }) => id === type).description : '';
        selectedSchemeDocumentsState.value.push({file: file, name: name, documentType: type, documentTypeDescription: docTypeDesc});
      } else {
        let fd = new FormData();
        const schemeId = id;

        fd.append('file', file);
        fd.append("schemeId", schemeId);
        fd.append("documentName", name);
        fd.append("documentType", type);

        await SchemesServices.uploadSingleFile(fd, id);
      }

      selectedFile.value = null;
      disableAddDocument();
    };
    
    const resetData = () => {
      selectedFile.value = null;
      documentName.value = '';
      documentType.value = EDocumentType.Overig;
    };

    const disableAddDocument = () => {
      resetData();
      showAddDocumentDialog.value = false;
    };

    return {
      uploadFile,
      id,
      selectedFile,
      documentName,
      documentType,
      showAddDocumentDialog,
      disableAddDocument,
      resetData,
      documentTypes,
      selectedSchemeDocumentsState
    };
  },
};
</script>
