<template>
        <ErrorModal />
          <div class="column">
        <div class="text-h6">{{ treeLocationGroupLabel }}</div>
        <q-btn
         
          class="orange-btn-filled bold-font"
          no-caps
          @click="displayCreateLocationGroup()"
          >Add new location group</q-btn
        >
        <div v-for="nodes in locationGroupTreeList" :key="nodes">
          <div class="row q-mt-md">
            <LocationGroupTree :nodes="nodes" :ticked="nodes[0].tickedTags" />
            <i
              style="width:20px; height:20px; padding-top:19px"
              class="las la-2x la-pen icon-color-vnd"
              @click="displayEditGroup(nodes[0].id, nodes[0].tickedTags)"
            >
              <q-tooltip> Edit </q-tooltip>
            </i>
          </div>
        </div>
      </div>
</template>
<script>
import store from "../../store/index";
import { computed } from "vue";
import TagsService from "../../services/tags.service";
import ErrorModal from "../tags-components/ErrorModal";
import LocationGroupTree from "../tags-components/LocationGroupTree.vue";
import { onMounted } from "vue";

export default {
  components: {
    ErrorModal,
    LocationGroupTree,
  },

  setup() {
    const treeLocationGroupLabel = computed({
      get: () => store.state.tree.treeNodesLocationGroupLabel,
    });
    onMounted(async () => {
      await TagsService.getAllLocationGroupTags();
    });
    const displayCreateLocationGroup = () => {
      store.commit("tree/enableCreateLocationGroup");
    };
    const displayEditGroup = (id, tickedTags) => {
      store.commit("tree/enableEditGroupTag");
      store.commit("tree/updateLocationTickedTags", tickedTags);
      store.commit("tree/updateSelectedTreeGroupId", id);
    };
    const showCreateLocationGroup = computed({
      get: () => store.state.tree.showCreateLocationGroup,
    });

    const showEditGroupTag = computed({
      get: () => store.state.tree.showEditGroupTag,
    });
    const locationGroupTreeList = computed({
      get: () => store.state.tree.locationGroupTreeList,
    });

    return {
      showCreateLocationGroup,
      locationGroupTreeList,
      displayCreateLocationGroup,
      displayEditGroup,
      treeLocationGroupLabel,
      showEditGroupTag,
    };
  },
};
</script>
