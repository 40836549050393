<template>
  <div class="tabs-nav" style="width: 100vw">
    <q-card v-if="tagsList.length > 0">
      <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-route-tab
          v-for="tag in tagsList"
          :key="tag.id"
          :name="tag.description"
          :to="{ name: 'TagTab', params: { tab: tag.description } }"
          :label="tag.description"
        />
      </q-tabs>

      <q-tab-panels v-model="tab">
        <TabPanel v-for="tag in tagsList" :key="tag.id" :name="tag.description" :tagType="tag.id"/>
      </q-tab-panels>
      <q-separator />
    </q-card>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import TabPanel from '../tags-components/TabPanel.vue';
import store from '../../store/index';
import TagsService from '../../services/tags.service';

export default {
  props: {
    propTab: String,
  },
  components: {
    TabPanel,
  },

  setup(props) {
    onMounted(() => {
      TagsService.getTagTypes();
    });

    const tagsList = computed({
      get: () => store.state.tagsNavigation.tagsList,
    });
    return {
      tab: ref(props.propTab),
      tagsList,
    };
  },
};
</script>
