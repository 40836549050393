<template>
  <q-dialog v-model="showSelectTagsDialog" persistent full-height full-width>
    <q-card bordered>
      <q-card-section>
        <div class="row q-mb-md">
          <NewsSelectTagsTree
            class="col-md-6"
            :treeNodes="propsAudienceNodes"
            :pairs="propsAudiencePairs"
            :tagType="'Audience'"
          />
          <div class="col-md-6">
            <div class="row">
              <NewsSelectTagsTree 
                :treeNodes="propsLocationsNodes"
                :pairs="propsLocationsPairs"
                :tagType="'Locations'"
              />
            </div>
            
            <div class="row">
              <p class="q-mt-xl">German location tags</p>
            </div>
            <div class="row">
              <q-select
                class="q-mr-md"
                multiple
                v-model="selectedGermanLocationsRef"
                :options="germanLocationTagsBulkList"
                @filter="filterGermanLocationTagsBulk"
                @add="updateGermanLocations"
                @remove="removeGermanLocations"
                label="Filter locations"
                use-chips
                use-input
                input-debounce="500"
                behavior="menu"
                option-value="id"
                option-label="label"
              />
            </div>

          </div>
        </div>
        <div class="row q-mb-md">
          <NewsSelectTagsTree
            class="col-md-6"
            :treeNodes="propsPurposesNodes"
            :pairs="propsPurposesPairs"
            :tagType="'Purposes'"
          />
          <NewsSelectTagsTree
            class="col-md-6"
            :treeNodes="propsSchemeTypesNodes"
            :pairs="propsSchemeTypesPairs"
            :tagType="'SchemeTypes'"
          />
        </div>
        <div class="row q-mb-md">
          <NewsSelectTagsTree 
            class="col-md-6"
            :treeNodes="propsIndustriesNewNodes"
            :pairs="propsIndustriesNewPairs"
            :tagType="'IndustriesNew'"
          />
          <NewsSelectTagsTree
            class="col-md-6"
            :treeNodes="propsProjectTypesNodes"
            :pairs="propsProjectTypesPairs"
            :tagType="'ProjectTypes'"
          />
        </div>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn color="primary" label="Ok" @click="disableShowSelectTags"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import store from '../../store/index';
import { ref, computed } from 'vue';
import { useVModel } from '../../composables/useVModel';
import NewsSelectTagsTree from './NewsSelectTagsTree.vue';
import schemesServices from '../../services/schemes.services';

export default {
  name: 'NewsSelectTags',
  components: {
    NewsSelectTagsTree,
  },
  props: {
    audiencePairs: Array,
    purposesPairs: Array,
    locationsPairs: Array,
    industriesNewPairs: Array,
    schemeTypesPairs: Array,
    projectTypesPairs: Array,
    selectedGermanLocations: Array,
    showSelectTags: Boolean,
    audienceNodes: Object,
    purposesNodes: Object,
    locationsNodes: Object,
    schemeTypesNodes: Object,
    projectTypesNodes: Object,
    industriesNewNodes: Object
  },
  setup(props) {
    const propsAudiencePairs = ref(props.audiencePairs);
    const propsPurposesPairs = ref(props.purposesPairs);
    const propsLocationsPairs = ref(props.locationsPairs);
    const propsSchemeTypesPairs = ref(props.schemeTypesPairs);
    const propsProjectTypesPairs = ref(props.projectTypesPairs);
    const propsIndustriesNewPairs = ref(props.industriesNewPairs);
    const showSelectTagsDialog = ref(props.showSelectTags);
    const propsAudienceNodes = ref(props.audienceNodes);
    const propsLocationsNodes = ref(props.locationsNodes);
    const propsPurposesNodes = ref(props.purposesNodes);
    const propsSchemeTypesNodes = ref(props.schemeTypesNodes);
    const propsProjectTypesNodes = ref(props.projectTypesNodes);
    const propsIndustriesNewNodes  = ref(props.industriesNewNodes);
    
    const propsSelectedGermanLocations = ref(useVModel(props, 'selectedGermanLocations'));

    const selectedGermanLocationsRef = ref(props.selectedGermanLocations);


    const disableShowSelectTags = () => {
      store.commit('news/disableShowSelectTags');
    };

    const germanLocationTagsBulkList = computed({
      get: () => store.state.scheme.locationTagsBulkList,
    });

    const updateGermanLocations = (val) => {
      if (!propsSelectedGermanLocations.value.includes(val.value))
        propsSelectedGermanLocations.value.push(val.value);
    };
    const removeGermanLocations = (val) => {
      if(propsSelectedGermanLocations.value.includes(val.value)){
        const index = propsSelectedGermanLocations.value.indexOf(val.value);
         if (index !== -1) {
           propsSelectedGermanLocations.value.splice(index, 1); 
         }
      }
    }

    return {
      removeGermanLocations,
      propsAudiencePairs,
      propsPurposesPairs,
      propsIndustriesNewPairs,
      propsSchemeTypesPairs,
      propsLocationsPairs,
      showSelectTagsDialog,
      disableShowSelectTags,
      propsAudienceNodes,
      propsPurposesNodes,
      propsLocationsNodes,
      propsSchemeTypesNodes,
      propsIndustriesNewNodes,
      propsProjectTypesNodes,
      propsProjectTypesPairs,
      propsSelectedGermanLocations,
      selectedGermanLocationsRef,
      germanLocationTagsBulkList,
      updateGermanLocations,
      filterGermanLocationTagsBulk(val, update) {
        if (!val || val == "" || val.length < 2) return [];
        update(() => {
            schemesServices.searchLocationCodesBulk(val);
        });
      },
    };
  }
};
</script>
