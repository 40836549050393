<template>
  <div class="add-box q-mr-xl column no-wrap">
    <span class="add-label bold-font">Create a new node</span>
    <div class="add-node-box column">
      <div class="column">
        <div class="row justify-around">
          <span class="main-label">Name: </span>
          <q-input v-model="newNodeLabel" placeholder="Enter name" dense />
        </div>
        <div class="row justify-around">
          <span class="main-label">Order: </span>
          <q-input
            type="number"
            v-model="newNodeOrder"
            placeholder="Enter order"
            dense
          />
        </div>
      </div>
      <div class="row q-mt-md">
        <span class="main-label hide-checkbox">Hide node: </span>
        <q-checkbox v-model="newNodeHideOption" color="indigo" dense />
      </div>
    </div>

    <div class="row save-buttons">
      <q-btn
        class="orange-btn-filled bold-font"
        no-caps
        label="Save"
        @click="createNewNode()"
      />
      <q-btn
        no-caps
        label="Cancel"
        class="orange-btn bold-font q-ml-lg"
        @click="disableCreate()"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import store from "../../store/index.js";
import TagsServices from "../../services/tags.service";

export default {
  props: {
    tagType: Number,
  },
  setup(props) {
    const languageCode = computed({
      get: () => store.state.tree.languageVersion,
    });

    const disableCreate = async () => {
      store.commit("tree/disableCreate");
      await TagsServices.getTagsTree(currentTagType, languageCode.value);
    };
    const newNodeLabel = ref("");
    const newNodeOrder = ref(0);
    const newNodeHideOption = ref(false);
    const currentTagType = ref(props.tagType);

    const createNewNode = async () => {
      await TagsServices.createNewTag(
        newNodeLabel,
        newNodeOrder,
        currentTagType,
        newNodeHideOption,
        languageCode
      ).then(async () => {
        await disableCreate();
      });
    };

    return {
      newNodeHideOption,
      disableCreate,
      newNodeLabel,
      newNodeOrder,
      createNewNode,
      currentTagType,
      languageCode,
    };
  },
};
</script>
