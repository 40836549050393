import moment from 'moment';
let user = JSON.parse(localStorage.getItem('user'));
const state = () => ({
  usersList: [],
  usersListCustomersPage: [],
  selectedSchemeResults: [],
  salutationsList: [],
  languageCodesList: [],
  customersList: [],
  customersDropdownList: [],
  rolesList: [],
  nesletterFrequencyList: [],
  showAdd: false,
  userObject: {
    email: '',
    person: {
      firstName: '',
      lastName: '',
      endDate: null,
      startDate: null,
      createdDate: null,
      salutation: null,
      language: '',
      customer: '',
      department: '',
      mobileNumber: '',
      isReceivingSystemMails: true,
      isAccountManager: false,
      address: {
        street: '',
        city: '',
        country: '',
      },
      hasSasTaskEmails: false,
    },
    roles: [{}],
  },
  isSuperAdmin: user && user.id === 128 ? true : false,
  newsletterFrequencyOfTheSelectedUser: '',
  modalErrorMessage: '',
  hasModalError: false,
  showSuccessfullyUpdatedDialog: false,
  pagination: {
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 50,
    rowsNumber: null,
  },
  showEmailSentDialog: false,
  selectedFavoriteLocations: [],
  favoriteAudienceTagsString: '',
  //This is just hidden for now, don't remove it:
  //favoriteIndustriesTagsString: '',
  favoritePurposesTagsString: '',
  favoriteAudiencesIdsArray: [],
  //This is just hidden for now, don't remove it:
  //favoriteIndustriesIdsArray: [],
  favoritePurposesIdsArray: [],
  audience: { options: [], label: '' },
  //This is just hidden for now, don't remove it:
  //industries: { options: [], label: '' },
  locations: { options: [], label: '' },
  purposes: { options: [], label: '' },
  schemeTypes: { options: [], label: '' },
  projectTypes: { options: [], label: '' },
  general: [],
  //countriesList: [],
  usersListDefaultEnv: [],
  filter: {
    searchTerm: '',
    filterToggleActive: true,
    filterToggleInactive: true,
  },
  allRoles: [],
  paginationCustomers: {
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 50,
    rowsNumber: null,
  },
  prefilledCustomerId: {
    name: '',
    id: '',
  },
  passwordResetSuccessModal: false,
});

const getters = {};
const actions = {};

const mutations = {
  setPasswordResetSuccessModal(state, val) {
    state.passwordResetSuccessModal = val;
  },
  prefillCustomer(state, val) {
    state.prefilledCustomerId.name = val.name;
    state.prefilledCustomerId.id = val.id;
  },
  removePrefilledCustomer(state) {
    state.prefilledCustomerId.name = '';
    state.prefilledCustomerId.id = 0;
  },

  updateAllRolesAndPermissions(state, val) {
    if (val != null && val.length > 0) {
      for (let i = 0; i < val.length; i++) {
        state.allRoles.push({
          id: val[i].id,
          name: val[i].name,
          permissions: val[i].permissions,
        });
      }
    }
  },
  updateFilter(state, filter) {
    state.filter.searchTerm = filter.searchTerm;
    state.filter.filterToggleActive = filter.filterToggleActive;
    state.filter.filterToggleInactive = filter.filterToggleInactive;
  },
  updateFilterActivities(state, activities) {
    state.general = activities;
    state.audience = activities.audience;
    //This is just hidden for now, don't remove it:
    //state.industries = activities.industries;
    state.locations = activities.locations;
    state.purposes = activities.purposes;
    state.schemeTypes = activities.schemeTypes;
    state.projectTypes = activities.projectTypes;
  },
  updateFavoriteAudiencesIdsArray(state, val) {
    if (state.favoriteAudiencesIdsArray.length > 0)
      state.favoriteAudiencesIdsArray = [];
    for (let i = 0; i < val.length; i++)
      state.favoriteAudiencesIdsArray.push(val[i]);

    state.favoriteAudienceTagsString = String(state.favoriteAudiencesIdsArray);
  },
  //This is just hidden for now, don't remove it:
  /*
  updateFavoriteIndustriesIdsArray(state, val) {
    if (state.favoriteIndustriesIdsArray.length > 0)
      state.favoriteIndustriesIdsArray = [];
    for (let i = 0; i < val.length; i++)
      state.favoriteIndustriesIdsArray.push(val[i]);
  },
  */
  updateFavoritePurposesIdsArray(state, val) {
    if (state.favoritePurposesIdsArray.length > 0)
      state.favoritePurposesIdsArray = [];
    for (let i = 0; i < val.length; i++)
      state.favoritePurposesIdsArray.push(val[i]);

    state.favoritePurposesTagsString = String(state.favoritePurposesIdsArray);
  },

  updateSelectedFavoriteLocations(state, val) {
    state.selectedFavoriteLocations = val;
  },

  updatePagination(state, val) {
    state.pagination.rowsPerPage = val.size;
    state.pagination.rowsNumber = val.count;
  },
  updatePaginationCustomers(state, val) {
    state.paginationCustomers.rowsPerPage = val.size;
    state.paginationCustomers.rowsNumber = val.count;
  },
  updatePaginationPage(state, val) {
    state.pagination.sortBy = val.sortBy;
    state.pagination.descending = val.descending;
    state.pagination.page = val.page;
  },
  updatePaginationPageCustomers(state, val) {
    state.pagination.sortBy = val.sortBy;
    state.pagination.descending = val.descending;
    state.pagination.page = val.page;
  },
  updateUsersList(state, users) {
    state.usersList = users;
  },

  updateUsersListCustomersPage(state, users) {
    state.usersListCustomersPage = users;
  },

  updateUsersListDefaultEnv(state, users) {
    state.usersListDefaultEnv = users;
  },
  updateSalutationsList(state, salutations) {
    state.salutationsList = salutations;
  },
  updateLanguageCodesList(state, languageCodes) {
    state.languageCodesList = languageCodes;
  },
  updateCustomersList(state, customers) {
    state.customersList = customers;
  },
  updateCustomersDropdownList(state, customers) {
    state.customersDropdownList = customers;
  },
  updateRolesList(state, roles) {
    state.rolesList = roles;
  },
  updateNewsletterFrequencyOptionsList(state, list) {
    state.newsletterFrequencyList = list;
  },
  enableAdd(state) {
    state.showAdd = true;
  },
  disableAdd(state) {
    state.showAdd = false;
  },
  updateUserObject(state, user) {
    state.userObject = user;
    state.userObject.person.endDate =
      user.person.endDate == null || user.person.endDate == ''
        ? null
        : moment(user.person.endDate).format('DD-MM-YYYY');
    state.userObject.person.startDate =
      user.person.startDate == null || user.person.startDate == ''
        ? null
        : moment(user.person.createdDate).format('DD-MM-YYYY');
    state.userObject.person.createdDate =
      user.person.createdDate == null || user.person.createdDate == ''
        ? null
        : moment(user.person.createdDate).format('DD-MM-YYYY');
    state.userObject.person.salutation = user.person.salutation;
  },
  fillUserDetails(state, user) {
    state.userObject.email = user.email;
    state.userObject.person.firstName = user.person.firstName;
    state.userObject.person.lastName = user.person.lastName;
    state.userObject.person.endDate =
      user.person.endDate == null || user.person.endDate == ''
        ? null
        : moment(user.person.endDate).format('DD-MM-YYYY');
    state.userObject.person.startDate =
      user.person.startDate == null || user.person.startDate == ''
        ? null
        : moment(user.person.startDate).format('DD-MM-YYYY');
    state.userObject.person.createdDate =
      user.person.createdDate == null || user.person.createdDate == ''
        ? null
        : moment(user.person.createdDate).format('DD-MM-YYYY');
    //  state.userObject.person.salutation = user.person.salutation;
    state.userObject.person.salutation = state.salutationsList.find(
      (s) => s.id == user.person.salutation
    );

    console.log('now its ', state.userObject.person.salutation);
    state.userObject.person.language = user.person.language;
    state.userObject.person.address.street = user.person.address.street;
    state.userObject.person.address.city = user.person.address.city;
    state.userobject.person.address.country = user.person.address.country;
    state.userObject.person.department = user.person.department;
    state.userObject.person.mobileNumber = user.person.mobileNumber;
  },
  updateEmail(state, email) {
    state.userObject.email = email;
  },
  updateFirstName(state, firstName) {
    state.userObject.person.firstName = firstName;
  },
  updateLastName(state, lastName) {
    state.userObject.person.lastName = lastName;
  },
  updateDepartment(state, department) {
    state.userObject.person.department = department;
  },
  updateMobileNumber(state, mobNumber) {
    state.userObject.person.mobileNumber = mobNumber;
  },
  updateSalutation(state, salutation) {
    state.userObject.person.salutation = salutation;
  },
  updateLanguage(state, language) {
    state.userObject.person.language = language;
  },
  updateReceivingSystemMails(state, isReceivingSystemMails) {
    state.userObject.person.isReceivingSystemMails = isReceivingSystemMails;
  },
  updateHasSasTaskEmails(state, val) {
    state.userObject.person.hasSasTaskEmails = val;
  },
  updateIsAccountManager(state, isAccountManager) {
    state.userObject.person.isAccountManager = isAccountManager;
  },
  updateCustomer(state, customer) {
    state.userObject.person.customer = customer;
  },
  updateCustomerId(state, customerId) {
    state.userObject.person.customerId = customerId;
  },
  updateNewsletterFrequency(state, selectedFrequency) {
    state.newsletterFrequencyOfTheSelectedUser = selectedFrequency;
  },
  updateEndDate(state, endDate) {
    state.userObject.person.endDate = endDate;
  },
  updateStartDate(state, startDate) {
    state.userObject.person.startDate = startDate;
  },
  updateStreet(state, street) {
    state.userObject.person.address.street = street;
  },
  updateCity(state, city) {
    state.userObject.person.address.city = city;
  },
  enableModalError(state) {
    state.hasModalError = true;
  },
  disableModalError(state) {
    state.hasModalError = false;
  },
  updateModalError(state, message) {
    state.modalErrorMessage = message;
  },
  updateSelectedSchemeResults(state, val) {
    const index = state.selectedSchemeResults.indexOf(val);
    if (index > -1) {
      state.selectedSchemeResults.splice(index, 1);
    } else {
      state.selectedSchemeResults.push(val);
    }
  },
  enableSuccessfullyUpdatedDialog(state) {
    state.showSuccessfullyUpdatedDialog = true;
  },
  disableSuccessfullyUpdatedDialog(state) {
    state.showSuccessfullyUpdatedDialog = false;
  },
  enableEmailSentDialog(state) {
    state.showEmailSentDialog = true;
  },
  disableEmailSentDialog(state) {
    state.showEmailSentDialog = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
