<template>
  <div class="create-news-page">
    <ErrorModal />
    <div class="row q-ml-lg heading">
      <h5 class="row bold-font">Create news item</h5>
    </div>
    <div class="row q-pa-md main-page">
      <div class="q-gutter-y-md  q-pa-md centered main-inputs">
        <q-input
          class="title-input"
          style="max-width: 400px"
          borderless
          v-model="news.title"
          bg-color="white"
          label="Title"
          stack-label
          :rules="requiredFieldRule"
        />

        <div class="date-input title-input" style="max-width: 400px">
          <q-input
            class="title-input"
            borderless
            bg-color="white"
            v-model="news.date"
            label="Publish date"
            stack-label
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    v-model="news.date"
                    mask="DD-MM-YYYY"
                    :options="validDates"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>

        <q-select
          borderless
          style="max-width: 400px"
          bg-color="white"
          class="title-input"
          v-model="news.status"
          :options="statusOptions"
          option-value="name"
          option-label="name"
          stack-label
          label="Status"
        />

        <q-select
          borderless
          bg-color="white"
          class="title-input box-input q-mb-lg"
          v-model="news.selectedScope"
          :options="scopeOptions"
          option-value="name"
          style="max-width: 400px"
          option-label="name"
          stack-label
          label="Select scope"
        />
        <q-select
          borderless
          bg-color="white"
          class="title-input box-input q-mb-lg"
          style="max-width: 400px"
          v-model="news.newsLetterTypes"
          :options="newsLetterTypesOptions"
          option-value="name"
          option-label="name"
          stack-label
          multiple
          label="Select news letter type(s)"
        />

        <q-select
          borderless
          bg-color="white"
          style="max-width: 400px"
          class="title-input scheme-select"
          v-model="news.newsSchemes"
          multiple
          :options="schemesOptions"
          use-chips
          use-input
          option-value="label"
          option-label="label"
          stack-label
          label="Select scheme(s)"
          @filter="filterFn"
          ref="createNewsSchemeSelect"
          @update:model-value="setInput()"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <div
          class="checkboxes-tags"
          v-if="hasSelectedScheme && !hasSelectedTags"
        >
          <span class="checkboxes-title">Select tags to inherit</span>
          <q-btn
            color="primary"
            label="Select all"
            @click="selectAllTags()"
            v-if="!news.selectAll"
            class="select-btn"
            no-caps
          />
          <q-btn
            color="primary"
            label="Unselect all"
            @click="selectAllTags()"
            v-if="news.selectAll"
            class="select-btn"
            no-caps
          />
          <q-checkbox
            v-model="news.audiences"
            label="Audiences"
            color="primary"
          />
          <q-checkbox
            v-model="news.purposes"
            label="Purposes"
            color="primary"
          />
          <q-checkbox
            v-model="news.locations"
            label="Locations"
            color="primary"
          />
          <q-checkbox
            v-model="news.schemetypes"
            label="Scheme types"
            color="primary"
          />
          <q-checkbox
            v-model="news.projecttypes"
            label="Project types"
            color="primary"
          />
          <q-checkbox
            v-model="news.keywords"
            label="Keywords"
            color="primary"
          />
          <q-checkbox
            v-model="news.industriesNew"
            label="Industries NEW"
            color="primary"
          />
          <q-checkbox v-model="news.scope" label="Scopes" color="primary" />
        </div>
      </div>
      <div class="large-text">
        <div
          v-if="!hasSelectedScheme || hasSelectedTags"
          class="textarea-input tags-tabs"
        >
          <SelectedTagsCreateNews
            :selectedAudience="news.newsAudienceTags"
            :selectedPurposes="news.newsPurposesTags"
            :selectedLocations="news.newsLocationsTags"
            :selectedSchemeTypes="news.newsSchemeTypesTags"
            :selectedIndustriesNew="news.newsIndustriesNewTags"
            :selectedProjectTypes="news.newsProjectTypesTags"
            :selectedKeywords="news.newsKeywordsTags"
            :selectedGermanLocations="news.newsGermanLocationsTags"
          />
        </div>
        <div class="introduction-input">
          <span class="input-title">
            Newsletter/search result text:
          </span>
          <q-input
            :rules="requiredFieldRule"
            class="textarea-input"
            v-model="news.introduction"
            borderless
            bg-color="white"
            type="textarea"
          />
        </div>
        <div class="introduction-input q-mt-lg">
          <span class="input-title q-mt-sm">
            <p class="section-labels">
              Body:
            </p>
          </span>
          <!--<q-editor
            v-model="news.body"
            :dense="$q.screen.lt.md"
            class="textarea-input"
            :toolbar="[
              [
                {
                  label: $q.lang.editor.align,
                  icon: $q.iconSet.editor.align,
                  fixedLabel: true,
                  list: 'only-icons',
                  options: ['left', 'center', 'right', 'justify'],
                },
              ],
              ['bold', 'italic'],
              ['token', 'hr', 'link', 'custom_btn'],
              ['print', 'fullscreen'],
              [
                {
                  label: $q.lang.editor.formatting,
                  icon: $q.iconSet.editor.formatting,
                  list: 'no-icons',
                  options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code'],
                },
                {
                  label: $q.lang.editor.fontSize,
                  icon: $q.iconSet.editor.fontSize,
                  fixedLabel: true,
                  fixedIcon: true,
                  list: 'no-icons',
                  options: [
                    'size-1',
                    'size-2',
                    'size-3',
                    'size-4',
                    'size-5',
                    'size-6',
                    'size-7',
                  ],
                },
                'removeFormat',
              ],
              ['unordered', 'ordered'],

              ['undo', 'redo'],
              ['viewsource'],
            ]"
            :fonts="{
              arial: 'Arial',
              arial_black: 'Arial Black',
              comic_sans: 'Comic Sans MS',
              courier_new: 'Courier New',
              impact: 'Impact',
              lucida_grande: 'Lucida Grande',
              times_new_roman: 'Times New Roman',
              verdana: 'Verdana',
            }"
          />-->
          <ckeditor
            :editor="editorRef.help.editor"
            v-model="news.body"
            :config="editorRef.help.editorConfig"
          ></ckeditor>
        </div>
      </div>

      <div class="row justify-end q-mr-md q-mt-lg" style="width: 100%">
        <div class="column">
          <q-btn
            class="q-mb-lg orange-btn-filled bold-font"
            no-caps
            label="Submit changes"
            @click="createNewsSubmit()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted, ref } from "vue";
import moment from "moment";
import store from "../store/index";
import NewsService from "../services/news.service";
import ErrorModal from "../components/news-components/NewsModalError.vue";
import SelectedTagsCreateNews from "../components/news-components/SelectedTagsCreateNews.vue";
import help from "../helpers/editor";

export default {
  name: "AddNews",
  components: {
    ErrorModal,
    SelectedTagsCreateNews,
  },
  setup() {
    onMounted(async () => {
      await NewsService.getPublicationStatuses();
      await NewsService.getSchemesForSelect();
      await NewsService.getScopes();

      NewsService.getNewsLetterTypes();
    });

    const selectAllTags = () => {
      news.selectAll = !news.selectAll;

      if (!news.selectAll) {
        news.audiences = false;
        news.purposes = false;
        //This is just hidden for now, don't remove it
        //news.industries = false;
        news.locations = false;
        news.schemetypes = false;
        news.projecttypes = false;
        news.keywords = false;
        news.scope = false;
        news.industriesNew = false;
      } else {
        news.audiences = true;
        news.purposes = true;
        //This is just hidden for now, don't remove it
        //news.industries = true;
        news.locations = true;
        news.schemetypes = true;
        news.projecttypes = true;
        news.keywords = true;
        news.scope = true;
        news.industriesNew = true;
      }
    };
    const editorRef = help;
    const news = reactive({
      title: "",
      date: moment().format("DD-MM-YYYY"),
      newsSchemes: null,
      introduction: "",
      body: "",
      audiences: false,
      purposes: false,
      //This is just hidden for now, don't remove it
      //industries: false,
      locations: false,
      schemetypes: false,
      projecttypes: false,
      keywords: false,
      scope: false,
      industriesNew: false,
      selectAll: false,
      status: null,
      newsAudienceTags: [],
      newsPurposesTags: [],
      newsLocationsTags: [],
      newsSchemeTypesTags: [],
      newsProjectTypesTags: [],
      newsIndustriesNewTags: [],
      newsGermanLocationsTags: [],
      newsKeywordsTags: [],
      newsLetterTypes: [],
      selectedScope: "",
    });

    const allSchemes = computed({
      get: () => store.state.news.allSchemes,
    });

    const schemesOptions = ref(allSchemes.value);

    const statusOptions = computed({
      get: () => store.state.news.publicationStatuses,
    });

    const createNewsSubmit = async () => {
      if (news.newsSchemes == null || news.newsSchemes.length == 0) {
        news.audiences = false;
        news.purposes = false;
        //This is just hidden for now, don't remove it
        //news.industries = false;
        news.locations = false;
        news.schemetypes = false;
        news.projecttypes = false;
        news.keywords = false;
        news.scope = false;
        news.industriesNew = false;
      }

      if (
        news.newsSchemes != null &&
        news.newsSchemes.length > 0 &&
        !news.audiences &&
        !news.purposes &&
        //This is just hidden for now, don't remove it
        //!news.industries &&
        !news.locations &&
        !news.schemetypes &&
        !news.projecttypes &&
        !news.keywords &&
        !news.scope &&
        !news.industriesNew
      ) {
        news.audiences = true;
        news.purposes = true;
        //This is just hidden for now, don't remove it
        //news.industries = true;
        news.locations = true;
        news.schemetypes = true;
        news.projecttypes = true;
        news.keywords = true;
        news.scope = true;
        news.industriesNew = true;
      }

      if (news.newsGermanLocationsTags.length > 0)
        news.newsGermanLocationsTags = news.newsGermanLocationsTags.map(
          (l) => l.id
        );
      await NewsService.createNewsItem(news);
    };

    const hasSelectedScheme = computed(() => {
      return news.newsSchemes != null && news.newsSchemes.length > 0;
    });

    const hasSelectedTags = computed(() => {
      return (
        news.newsAudienceTags.length > 0 ||
        news.newsPurposesTags.length > 0 ||
        news.newsKeywordsTags.length > 0 ||
        news.newsLocationsTags.length > 0 ||
        news.newsSchemeTypesTags.length > 0 ||
        news.newsProjectTypesTags.length > 0 ||
        news.newsIndustriesNewTags.length > 0 ||
        news.newsGermanLocationsTags.length > 0
      );
    });

    const newsLetterTypesOptions = computed({
      get: () => store.state.news.newsLetterTypesList,
    });

    const scopeOptions = computed({
      get: () => store.state.news.scopes,
    });

    const createNewsSchemeSelect = ref(null);
    const setInput = () => {
      createNewsSchemeSelect.value.updateInputValue("");
    };

    return {
      createNewsSchemeSelect,
      setInput,
      scopeOptions,
      newsLetterTypesOptions,
      editorRef,
      editorData: "<p></p>",
      hasSelectedScheme,
      hasSelectedTags,
      news,
      allSchemes,
      createNewsSubmit,
      selectAllTags,
      schemesOptions,
      statusOptions,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || "Field is required!",
      ],
      validDates(date) {
        return date >= moment().format("YYYY/MM/DD");
      },
      filterFn(val, update) {
        update(() => {
          if (val === "") {
            schemesOptions.value = allSchemes.value;
          } else {
            if (!isNaN(parseInt(val))) {
              const idVal = parseInt(val);
              schemesOptions.value = allSchemes.value.filter(
                (v) => v.id === idVal
              );
            } else {
              const needle = val.toLowerCase();

              schemesOptions.value = allSchemes.value.filter((v) => {
                if (v.name != null && v.name.toLowerCase().indexOf(needle) > -1)
                  return v;
              });
            }
          }
        });
      },
    };
  },
};
</script>
