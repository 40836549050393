<template>
  <ModalError />
  <q-dialog v-model="showVisualPingDialog" persistent full-height full-width>
    <div class="visual-ping-dialog">
      <q-card bordered flat>
        <q-item>
          <q-toolbar>
            <q-toolbar-title>
              <span class="text-weight-bold" />Visualping</q-toolbar-title>

            <q-btn flat round dense icon="close" @click="closeDialog"><q-tooltip>Close</q-tooltip></q-btn>
          </q-toolbar>
        </q-item>

        <q-separator></q-separator>

        <q-card-section horizontal>
          <q-card-section class="col-6" style="height:75vh">
            <q-list dense bordered padding class="rounded-borders" style="height:100%">
              <q-scroll-area style="height: 100%;">
                <div v-if="isLoading" style="text-align: center;">
                  <span style="display: inline-block">
                    <q-spinner-bars color="primary" size="2em" />
                  </span>
                </div>
                <q-item v-for="vplink in selectedSchemeVisualPingLinks" :key="vplink.id">
                  <q-item-section avatar>
                    <q-icon name="las la-square" size="xs" :color="vplink.statusColor" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label class="q-mt-xs link-text">
                      <span class="cursor-pointer" @click="getVisualPingLinkWithChanges(vplink.id)">
                        {{ vplink.text + ' (' + vplink.url + ')' }}
                      </span>
                    </q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <div class="q-gutter-xs">
                      <q-btn color="red" size="12px" flat dense round icon="delete" :disable="isLoading"
                        @click="removeVisualPingLink(vplink.id)">
                        <q-tooltip>
                          Remove link
                        </q-tooltip>
                      </q-btn>
                      <q-btn
                        @click="showEditVisualPingLink = true; hideUrlField = true; selectedVpLinkToEdit = vplink; if (selectedVisualPingLinkWithChanges && selectedVisualPingLinkWithChanges.id === vplink.id) { unsetSelectedVisualPingLinkWithChanges() }"
                        dense size="12px" round flat color="indigo-10" icon="las la-edit">
                        <q-tooltip>
                          Rename link
                        </q-tooltip>
                      </q-btn>
                      <q-btn
                        @click="showEditVisualPingLink = true; hideUrlField = false; selectedVpLinkToEdit = vplink; if (selectedVisualPingLinkWithChanges && selectedVisualPingLinkWithChanges.id === vplink.id) { unsetSelectedVisualPingLinkWithChanges() }"
                        dense size="12px" round flat color="indigo-10" icon="las la-pencil-alt">
                        <q-tooltip>
                          Edit link
                        </q-tooltip>
                      </q-btn>
                      <q-btn :href="vplink.url" target="_blank" type="a" dense size="12px" round flat color="indigo-10"
                        icon="las la-link" style="text-decoration: none">
                        <q-tooltip>
                          Go to link
                        </q-tooltip>
                      </q-btn>
                    </div>
                  </q-item-section>
                </q-item>
              </q-scroll-area>
            </q-list>
          </q-card-section>

          <q-card-section class="col-6" style="height:75vh">
            <q-list dense bordered padding class="rounded-borders" style="height:100%">
              <q-item v-if="selectedVisualPingLinkWithChanges &&
                selectedVisualPingLinkWithChanges.isBroken &&
                selectedVisualPingLinkWithChanges.failureReason != null &&
                selectedVisualPingLinkWithChanges.failureReason != ''">
                <q-card class="full-width" style="background: rgba(255, 139, 139, 0.5);">
                  <q-card-section>
                    <div class="row q-mb-sm">
                      <span>
                        {{ formatDate(selectedVisualPingLinkWithChanges.visualPingNotifications[0].datetime) }}
                      </span>
                      <q-space></q-space>
                      <q-btn
                        @click="markVPNotificationAsChecked(selectedVisualPingLinkWithChanges.visualPingNotifications[0].id, selectedVisualPingLinkWithChanges.visualPingNotifications[0].linkId)"
                        dense size="12px" round flat color="indigo-10" icon="las la-check-square">
                        <q-tooltip>
                          Mark as checked
                        </q-tooltip>
                      </q-btn>
                    </div>
                    <q-separator></q-separator>
                    {{ selectedVisualPingLinkWithChanges.failureReason }}
                  </q-card-section>
                </q-card>
              </q-item>

              <template
                v-if="selectedVisualPingLinkWithChanges && selectedVisualPingLinkWithChanges.isBroken == false && selectedVisualPingLinkWithChanges.visualPingNotifications.length > 0">
                <q-scroll-area style="height: 100%;">
                  <template v-for="notification in selectedVisualPingLinkWithChanges.visualPingNotifications"
                    :key="notification">
                    <q-separator></q-separator>
                    <div class="row q-pa-sm" style="position: relative">
                      <span class="centered-element">
                        {{ formatDate(notification.datetime) }}
                      </span>
                      <q-space></q-space>
                      <q-btn @click="markVPNotificationAsChecked(notification.id, notification.linkId)" dense
                        size="12px" round flat color="indigo-10" icon="las la-check-square">
                        <q-tooltip>
                          Mark as checked
                        </q-tooltip>
                      </q-btn>
                    </div>
                    <q-separator></q-separator>
                    <iframe class="q-pr-md" :src="notification.textChanges" style="width: 100%; height: 60vh">
                    </iframe>
                  </template>
                </q-scroll-area>
              </template>
            </q-list>
          </q-card-section>
        </q-card-section>

        <q-card-actions class="q-pl-md q-pr-md">
          <q-btn color="indigo-10" label="Add link" @click="showAddVisualPingLink = true" outline dense no-caps />
          <q-space></q-space>
          <q-btn :href="jobUrl" type="a" color="indigo-10" outline label="Go to link on Visualping" dense no-caps
            target="_blank" :disable="jobUrl != '' ? null : true" style="text-decoration: none;" />
        </q-card-actions>
      </q-card>
      <AddVisualPingLink v-if="showAddVisualPingLink" :selectedSchemeId="schemeId" />
      <EditVisualPingLink v-if="showEditVisualPingLink" :selectedSchemeId="schemeId" :hideUrlField="hideUrlField" />
    </div>
  </q-dialog>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import store from '../../store/index';
import SchemesServices from '../../services/schemes.services';
import AddVisualPingLink from '../schemes-components/AddVisualPingLink.vue';
import EditVisualPingLink from '../schemes-components/EditVisualPingLink.vue';
import moment from 'moment';
import ModalError from './SchemeModalError.vue';
import linkcheckerService from '../../services/linkchecker.service';

export default {
  name: 'VisualPingDialog',
  components: {
    AddVisualPingLink,
    EditVisualPingLink,
    ModalError
  },
  props: {
    selectedSchemeId: Number,
  },
  setup(props) {
    const hideUrlField = ref(false);
    const isLoading = ref(false);
    const schemeId = computed(() => {
      return props.selectedSchemeId
    });

    onMounted(async () => {
      await SchemesServices.getSchemeVisualPingLinks(schemeId.value);
    });

    const showVisualPingDialog = computed({
      get: () => store.state.scheme.showVisualPingDialog,
      set: (value) => store.commit('scheme/updateShowVisualPingDialog', value),
    });

    const selectedSchemeVisualPingLinks = computed({
      get: () => store.state.scheme.selectedSchemeVisualPingLinks,
    });

    const showAddVisualPingLink = computed({
      get: () => store.state.scheme.showAddVisualPingLink,
      set: (value) => store.commit('scheme/updateShowAddVisualPingLink', value),
    });

    const showEditVisualPingLink = computed({
      get: () => store.state.scheme.showEditVisualPingLink,
      set: (value) => store.commit('scheme/updateShowEditVisualPingLink', value),
    });

    const unsetSelectedVisualPingLinkWithChanges = () => {
      store.commit('scheme/updateVisualPingLinkWithChanges', null);
    };

    const getVisualPingLinkWithChanges = async (linkId) => {
      unsetSelectedVisualPingLinkWithChanges();
      await SchemesServices.getVisualPingLinkWithChanges(linkId);
    };

    const selectedVisualPingLinkWithChanges = computed({
      get: () => store.state.scheme.visualPingLinkWithChanges,
    });

    const closeDialog = () => {
      unsetSelectedVisualPingLinkWithChanges();
      showVisualPingDialog.value = false;
    };

    const markVPNotificationAsChecked = async (notificationId, linkId) => {
      await linkcheckerService.markVPNotificationAsChecked(notificationId, linkId, schemeId.value)
        .then(async () => {
          await SchemesServices.getSchemeVisualPingLinks(schemeId.value);
        });
    };

    const jobUrl = computed(() => {
      if (selectedVisualPingLinkWithChanges.value && selectedVisualPingLinkWithChanges.value.jobId && selectedVisualPingLinkWithChanges.value.jobId != 0)
        return 'https://visualping.io/jobs/' + selectedVisualPingLinkWithChanges.value.jobId;
      else
        return "";
    });

    const removeVisualPingLink = async (linkId) => {
      if (selectedVisualPingLinkWithChanges.value && selectedVisualPingLinkWithChanges.value.id === linkId)
        unsetSelectedVisualPingLinkWithChanges();

      isLoading.value = true;
      await linkcheckerService.removeVisualPingLink(linkId, schemeId.value);
      isLoading.value = false;
    };

    const selectedVpLinkToEdit = computed({
      get: () => store.state.scheme.selectedVpLinkToEdit,
      set: (value) => store.commit('scheme/setSelectedVpLinkToEdit', value)
    });

    return {
      showVisualPingDialog,
      schemeId,
      showAddVisualPingLink,
      selectedSchemeVisualPingLinks,
      getVisualPingLinkWithChanges,
      selectedVisualPingLinkWithChanges,
      unsetSelectedVisualPingLinkWithChanges,
      closeDialog,
      markVPNotificationAsChecked,
      jobUrl,
      removeVisualPingLink,
      showEditVisualPingLink,
      hideUrlField,
      selectedVpLinkToEdit,
      isLoading,
      formatDate(stringDate) {
        if (stringDate != null)
          return moment(stringDate.split("GMT")[0]).format('DD-MM-YYYY');
      },
    }
  },
};
</script>
