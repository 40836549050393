const state = () => ({
  emailTemplateOptions: [],
  emailTemplateSelected: "",
  selectedTemplateSubject: "",
  selectedTemplateBody: "",
  selectedTemplateId: 0,
  showSuccessfullyUpdatedDialog: false,
  emailTemplateCategories: [],
});

const getters = {};

const actions = {};

const mutations = {
  updateEmailTemplateCategories(state,val){
    state.emailTemplateCategories = val;
  },
  disableSuccessfullyUpdatedDialog(state) {
    state.showSuccessfullyUpdatedDialog = false;
  },
  enableSuccessfullyUpdatedDialog(state) {
    state.showSuccessfullyUpdatedDialog = true;
  },
  updateEmailTemplateOptions(state, val) {
    state.emailTemplateOptions = val;
  },
  updateSelectedTemplateType(state, val) {
    state.emailTemplateSelected = val;
  },
  updateTemplateSubject(state, val) {
    state.selectedTemplateSubject = val;
  },
  updateTemplateBody(state, val) {
    state.selectedTemplateBody = val;
  },
  updateSelectedTemplateId(state, val) {
    state.selectedTemplateId = val;
  },
  resetState(state){
    state.selectedTemplateBody = "";
    state.selectedTemplateSubject = "";
    state.emailTemplateSelected = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
