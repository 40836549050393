const ETagType = Object.freeze({
    Unknown : 0,
    Audience : 1,
    Purposes : 2,
    Industries : 4,
    Locations : 8,
    SchemeType : 16,
    Keywords : 32,
    IndustriesNew : 64,
    UserStatus : 128,
    EducationLevel : 256,
    ProjectType : 512,
});

export default ETagType;