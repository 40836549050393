<template>
  <q-dialog v-model="show" persistent>
    <q-card style="min-width: 350px">
      <q-card-section style="text-align: center">
        <span class="add-label bold-font">Edit organisation</span>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none">
        <div class="add-org-box column">
          <div class="column">
            <div class="row justify-around">
              <span class="main-label">Name: </span>
              <q-input
                v-model="organisationName"
                placeholder="Enter name"
                dense
              />
            </div>
            <div class="row justify-around">
              <span class="main-label">Email: </span>
              <q-input
                v-model="organisationEmail"
                placeholder="Enter email"
                dense
              />
            </div>
            <div class="row justify-around">
              <span class="main-label">Phone: </span>
              <q-input
                v-model="organisationPhone"
                placeholder="Enter phone"
                dense
              />
            </div>
            <div class="row justify-around">
              <span class="main-label">Website: </span>
              <q-input
                v-model="organisationUrl"
                placeholder="Enter website"
                dense
              />
            </div>
            <div class="row justify-around">
              <span class="main-label">Street: </span>
              <q-input v-model="street" placeholder="Enter street" dense />
            </div>
            <div class="row justify-around">
              <span class="main-label">City: </span>
              <q-input v-model="city" placeholder="Enter city" dense />
            </div>
            <div class="row justify-around q-pa-md">
              <q-select
                v-model="country"
                use-input
                input-debounce="0"
                label="Select country"
                :options="options"
                @filter="filterFn"
                style="width: 250px"
                behavior="menu"
                dense
                outlined
                bg-color="white"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
          </div>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          no-caps
          color="primary"
          label="Save edit "
          @click="updateOrganisationEdit()"
        />

        <q-btn
          color="primary"
          outline
          label="Cancel"
          class="cancel-btn"
          @click="disableEdit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import store from '../../store/index.js';
import CustomersService from '../../services/customers.service';
import OrganisationsService from '../../services/organisations.service';

export default {
  props: {
    showEdit: Boolean,
  },
  setup(props) {
    const show = ref(props.showEdit);
    const node = computed({
      get: () => store.state.organisation.selectedNode,
    });

    onMounted(() => {
      CustomersService.getAllCountries();
    });

    const countries = computed({
      get: () => store.state.customers.countriesList,
    });

    const options = ref(countries.value);

    const disableEdit = () => {
      store.commit('organisation/disableEditOrganisation');
      OrganisationsService.getOrganisationsTree();
    };

    const organisationName = computed({
      get: () => store.state.organisation.currentName,
      set: (value) => store.commit('organisation/updateName', value),
    });

    const organisationEmail = computed({
      get: () => store.state.organisation.currentEmail,
      set: (value) => store.commit('organisation/updateEmail', value),
    });

    const organisationPhone = computed({
      get: () => store.state.organisation.currentPhone,
      set: (value) => store.commit('organisation/updatePhone', value),
    });

    const organisationUrl = computed({
      get: () => store.state.organisation.currentUrl,
      set: (value) => store.commit('organisation/updateUrl', value),
    });

    const street = computed({
      get: () => store.state.organisation.currentStreet,
      set: (value) => store.commit('organisation/updateStreet', value),
    });

    const city = computed({
      get: () => store.state.organisation.defaultCity,
      set: (value) => store.commit('organisation/updateDefaultCity', value),
    });

    const country = computed({
      get: () => store.state.organisation.defaultCountry,
      set: (value) => store.commit('organisation/updateDefaultCountry', value),
    });

    const orgID = computed({
      get: () => store.state.organisation.OrganisationID,
    });

    const updateOrganisationEdit = async () => {
      await OrganisationsService.editOrganisation(
        orgID,
        organisationName,
        organisationEmail,
        organisationPhone,
        organisationUrl,
        street,
        city,
        country
      );
      disableEdit();
    };

    return {
      country,
      city,
      orgID,
      show,
      node,
      text: ref(node),
      updateOrganisationEdit,
      disableEdit,
      organisationName,
      organisationEmail,
      organisationPhone,
      organisationUrl,
      countries,
      options,
      street,
      filterFn(val, update) {
        update(() => {
          if (val === '') {
            options.value = countries.value;
          } else {
            const needle = val.toLowerCase();

            country.value = countries.value.filter(
              (v) => v.toLowerCase().indexOf(needle) > -1
            );
            options.value = countries.value.filter(
              (v) => v.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
    };
  },
};
</script>
