<template>
  <div class="news-select-final">
    <h1 class="heading row justify-center">
      Klaar! De nieuwsbrief wordt aanstaande vrijdag aan je klanten verstuurd
    </h1>
    <div v-for="item in selectedNewsPerCustomer" :key="item.id">
      <p class="news-title">
        {{ item.customerName }} - {{ item.news.length }} selected news items
      </p>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import store from '../../store/index.js';
import salesForceService from '../../services/salesForceService';

export default {
  name: 'NewsForCustomersFinal',
  setup() {
    const selectedNewsPerCustomer = computed({
      get: () => store.state.salesForce.selectedNewsPerCustomer,
    });

    const serverData = computed({
      get: () => store.state.salesForce.newsItemsPerCustomerListForServer,
    });

    onMounted(() => {
      salesForceService.postNewsSelection(serverData.value);
    });
    return {
      serverData,
      selectedNewsPerCustomer,
    };
  },
};
</script>
