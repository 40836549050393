<template>
  <div class="add-box q-mr-xl column no-wrap">
    <span class="add-label bold-font"
      >Add parent/child to {{ selectedNodeName }}</span
    >
    <div class="add-node-box column">
      <div class="column">
        <div class="row justify-around">
          <span class="main-label">Name: </span>
          <q-input v-model="relationTagName" placeholder="Enter name" dense />
        </div>
      </div>

      <div class="row add-buttons justify-around">
        <q-btn
          label="Add as child"
          @click="addChild()"
          no-caps
          class="orange-btn-filled bold-font"
          dense
        />
        <q-btn
          label="Add as parent"
          @click="addParent()"
          class="orange-btn-filled bold-font"
          no-caps
          dense
        />
      </div>
    </div>

    <div class="row save-buttons">
      <q-btn
        no-caps
        label="Cancel"
        class="orange-btn bold-font"
        @click="disableAdd()"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import store from '../../store/index.js';
import TagsService from '../../services/tags.service';

export default {
  props: {
    tagType: Number,
  },
  setup(props) {
    const relationTagName = ref('');
    const currentTagType = ref(props.tagType);
    const languageCode = computed({
      get: () => store.state.tree.languageVersion,
    });
    const parent = computed({
      get: () => store.state.tree.selectedNodeAndParent.parent,
    });
    const node = computed({
      get: () => store.state.tree.selectedNode,
    });

    const disableAdd = async () => {
      store.commit('tree/disableAdd');
      await TagsService.getTagsTree(currentTagType, languageCode.value);
    };

    const selectedNodeName = computed({
      get: () => store.state.tree.selectedNodeName,
    });

    const addChild = async () => {
      await TagsService.addChild(node, relationTagName)
        .then(() => {
          disableAdd()
      });
    };

    const addParent = async () => {
      await TagsService.addParent(node, relationTagName, parent.value.id)
        .then(async () => {
          await disableAdd()
        });
    };

    return {
      node,
      text: ref(node),
      disableAdd,
      relationTagName,
      addChild,
      addParent,
      currentTagType,
      selectedNodeName,
      parent,
      languageCode
    };
  },
};
</script>
