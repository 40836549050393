<template>
  <q-table
    class="calls-and-application-periods-table"
    :rows="callsAndApplicationPeriods"
    :columns="callsAndApplicationPeriodsColumns"
    row-key="id"
    :filter="filter"
    :loading="loading"
    :dense="$q.screen.lt.md"
    v-model:pagination="pagination"
    @request="onRequest"
    binary-state-sort
    title="Calls and application periods"
  >
    <template v-slot:loading>
      <q-inner-loading showing color="primary"></q-inner-loading>
    </template>
    <template v-slot:top-right>
      <q-input
        borderless
        dense
        debounce="300"
        color="primary"
        placeholder="Search"
        v-model="filter"
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
    </template>

    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="name" :props="props">
          <router-link
            class="edit-link"
            :to="{
              name: 'EditScheme',
              params: { id: props.row.id },
            }"
            v-bind:target="'_blank'"
          >
            {{ props.row.name }}
          </router-link>
        </q-td>
        <q-td key="recurringYearsDuration" :props="props">
          <i
            :class="
              getClassForIcon(
                props.row.recurringYearsDuration,
                props.row.isAlwaysOpen
              )
            "
          >
            <q-tooltip>
              {{
                getToolTipData(
                  props.row.recurringYearsDuration,
                  props.row.isAlwaysOpen
                )
              }}
            </q-tooltip></i
          >
          <span
            class="recurring-years-data"
            v-if="props.row.recurringYearsDuration > 0"
          >
            {{ props.row.recurringYearsDuration }}
          </span>
        </q-td>
        <q-td key="identifier" :props="props">
          <router-link
            class="edit-link"
            :to="{
              name: 'EditScheme',
              params: { id: props.row.id },
            }"
            v-bind:target="'_blank'"
          >
            {{ props.row.identifier }}
          </router-link>
        </q-td>
        <q-td key="startDate" :props="props">
          <router-link
            v-if="
              !props.row.isAlwaysOpen && props.row.recurringYearsDuration < 1
            "
            class="edit-link"
            :to="{
              name: 'EditScheme',
              params: { id: props.row.id },
            }"
            v-bind:target="'_blank'"
          >
            {{ props.row.callStart }}
          </router-link>
          <router-link
            v-if="
              !props.row.isAlwaysOpen &&
                props.row.recurringYearsDuration > 0 &&
                props.row.callStart
            "
            class="edit-link"
            :to="{
              name: 'EditScheme',
              params: { id: props.row.id },
            }"
            v-bind:target="'_blank'"
          >
            {{ props.row.callStart.substring(0, 5) }}
          </router-link>
          <span v-else></span>
        </q-td>
        <q-td key="endDate" :props="props">
          <router-link
            v-if="
              !props.row.isAlwaysOpen && props.row.recurringYearsDuration < 1
            "
            class="edit-link"
            :to="{
              name: 'EditScheme',
              params: { id: props.row.id },
            }"
            v-bind:target="'_blank'"
          >
            {{ props.row.callEnd }}
          </router-link>
          <router-link
            v-if="
              !props.row.isAlwaysOpen &&
                props.row.recurringYearsDuration > 0 &&
                props.row.callEnd
            "
            class="edit-link"
            :to="{
              name: 'EditScheme',
              params: { id: props.row.id },
            }"
            v-bind:target="'_blank'"
          >
            {{ props.row.callEnd.substring(0, 5) }}
          </router-link>
          <span v-else></span>
        </q-td>
        <q-td key="actions" :props="props" class="q-gutter-xs">
          <q-btn
            color="indigo-10"
            @click="(confirmDeleteCall = true), (itemId = props.row.id)"
            size="sm"
            no-caps
          >
            <i class="las la-2x la-trash la-fw"></i>
            <q-tooltip>
              Remove
            </q-tooltip>
          </q-btn>
        </q-td>
      </q-tr>
    </template>
  </q-table>
  <q-dialog v-model="confirmDeleteCall" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-trash la-fw"></i>
        <span class="q-ml-sm">Do you want to remove this item?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn outline label="Cancel" color="primary" v-close-popup />
        <q-btn
          label="Remove"
          color="primary"
          v-close-popup
          @click="deleteCallOrApplicationPeriod(itemId)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import store from '../../store/index.js';
import SchemesService from '../../services/schemes.services.js';
import { useRoute } from 'vue-router';

const callsAndApplicationPeriodsColumns = [
  {
    name: 'name',
    required: true,
    label: 'Title',
    align: 'left',
    sortable: true,
    field: (row) => row.name,
    format: (val) => `${val}`,
  },
  {
    name: 'recurringYearsDuration',
    label: 'Application type',
    align: 'left',
    field: (row) => row.name,
  },
  {
    name: 'identifier',
    align: 'left',
    sortable: true,
    label: 'Identifier',
    field: (row) => row.identifier,
  },
  {
    name: 'startDate',
    align: 'center',
    sortable: true,
    label: 'Start date',
    field: (row) => row.callStart,
  },
  {
    name: 'endDate',
    align: 'center',
    sortable: true,
    label: 'End date',
    field: (row) => row.callEnd,
  },
  { name: 'actions', align: 'center', field: 'actions' },
];

export default {
  name: 'CallsAndApplicationPeriods',
  setup() {
    const route = useRoute();
    const loading = ref(false);
    const filter = ref('');
    const parentSchemeId = route.params.id;
    const confirmDeleteCall = ref(false);
    const itemId = ref(null);

    onMounted(async () => {
      (loading.value = true),
        await SchemesService.getSchemeCallsAndApplicationPeriods(
          parentSchemeId
        );
      loading.value = false;
    });

    const callsAndApplicationPeriods = computed({
      get: () => store.state.scheme.selectedSchemeCallsAndApplicationPeriods,
    });

    const pagination = ref(
      computed({
        get: () => store.state.scheme.callsAndApplicationPeriodsPagination,
        set: (paginationVar) =>
          store.commit(
            'scheme/updateSchemeCallsAndApplicationPeriodsPagination',
            paginationVar
          ),
      })
    );

    const onRequest = async (props) => {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const fetchCount =
        rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage;
      const filterSearchTerm = props.filter;
      loading.value = true;

      await SchemesService.getSchemeCallsAndApplicationPeriods(
        parentSchemeId,
        filterSearchTerm,
        sortBy,
        descending,
        page - 1,
        fetchCount
      );
      store.commit(
        'scheme/updateCallsAndApplicationPeriodsPaginationPage',
        props.pagination
      );
      loading.value = false;
    };

    const deleteCallOrApplicationPeriod = async (itemId) => {
      await SchemesService.deleteCallOrApplicationPeriod(itemId);
      (loading.value = true),
        await SchemesService.getSchemeCallsAndApplicationPeriods(
          parentSchemeId
        );
      loading.value = false;
    };

    getClassForIcon;

    const getClassForIcon = (recurringDuration, isAlwaysOpen) => {
      if (isAlwaysOpen && recurringDuration == 0) {
        return 'las la-2x la-infinity';
      } else if (recurringDuration > 0 && !isAlwaysOpen) {
        return 'las la-2x la-redo-alt';
      } else {
        return 'las la-2x la-hourglass';
      }
    };

    const getToolTipData = (recurringDuration, isAlwaysOpen) => {
      if (isAlwaysOpen) {
        return 'Always open';
      } else if (recurringDuration > 0 && !isAlwaysOpen) {
        return 'Recurring deadline';
      } else {
        return 'One-off application period';
      }
    };

    return {
      getToolTipData,
      getClassForIcon,
      callsAndApplicationPeriodsColumns,
      loading,
      filter,
      onRequest,
      pagination,
      callsAndApplicationPeriods,
      route,
      parentSchemeId,
      confirmDeleteCall,
      itemId,
      deleteCallOrApplicationPeriod,
    };
  },
};
</script>
