import axios from 'axios';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
import axiosUrl from './axios.service';
import router from '../router';
import AuthService from '../services/auth.service';

const API_URL = axiosUrl.getAxiosUrl();

class AccountManagersService {
  async getAccountManagersWithBasicInfo() {
    return await axios
      .get(API_URL + `accountManagers/getAccountManagersWithBasicInfo`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit(
          'accountManagers/updateAccountManagersBasicInfoList',
          response.data
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          AuthService.logout();
          router.go();
        }
        if (error) {
          if (error.response.status === 403) {
            store.commit(
              'accountManagers/updateModalError',
              "You don't have permission to perform this action!"
            ),
              store.commit('accountManagers/enableModalError');
          } else {
            store.commit(
              'accountManagers/updateModalError',
              error.response.data
            ),
              store.commit('accountManagers/enableModalError');
          }
        }
      });
  }
}

export default new AccountManagersService();
