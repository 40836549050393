<template>
  <div class="q-pa-md">
    <q-toolbar class="bg-primary text-white">
      <i
        class="las la-arrow-circle-left la-2x back-arrow"
        @click="router.go(-1)"
      >
        <q-tooltip>
          Go to previous page
        </q-tooltip>
      </i>

      <q-toolbar-title>
        Edit user
        <i class="las la-angle-double-down"></i>
      </q-toolbar-title>
    </q-toolbar>
    <UsersEditTabs :id="userId" />
  </div>
</template>
<script>
import UsersEditTabs from './UsersEditTabs.vue';
import { useRoute } from 'vue-router';
import router from '../../router/index';
export default {
  name: 'EditUser',
  components: {
    UsersEditTabs,
  },
  setup() {
    const route = useRoute();
    const userId = route.params.id;
    
    return {
      route,
      userId,
      router,
    };
  },
};
</script>
