import axios from "axios";
import authHeader from "../helpers/auth-header";
import axiosUrl from "./axios.service";
import store from "../store/index";
const API_URL = axiosUrl.getAxiosUrl();

class EmailTemplatesService {
  getEmailTemplateCategories() {
    return axios
      .get(API_URL + `emailTemplates/getEmailTemplateCategories`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit(
          "emailTemplate/updateEmailTemplateCategories",
          response.data
        );
      })
      .catch((error) => {
        store.commit("auth/updateModalError", error.response.data),
          store.commit("auth/enableModalError");
      });
  }
  getEmailTemplatesByCategory(category, languageCode) {
    return axios
      .get(API_URL + `emailTemplates/getEmailTemplatesByCategory`, {
        params: {
          type: category,
          languageCode: languageCode,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit("emailTemplate/updateEmailTemplateOptions", response.data);
      })
      .catch((error) => {
        store.commit("auth/updateModalError", error.response.data),
          store.commit("auth/enableModalError");
      });
  }

  getEmailTemplateByType(templateType, languageCode) {
    return axios
      .get(API_URL + `emailTemplates/getEmailTemplateByType`, {
        params: {
          templateType: templateType.value,
          languageCode: languageCode.value,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit(
          "emailTemplate/updateTemplateSubject",
          response.data.subject
        );
        store.commit("emailTemplate/updateTemplateBody", response.data.body);
        store.commit(
          "emailTemplate/updateSelectedTemplateId",
          response.data.id
        );
      })
      .catch((error) => {
        store.commit("auth/updateModalError", error.response.data),
          store.commit("auth/enableModalError");
      });
  }
  async updateEmailTemplate(updateTemplate) {
    return await axios
      .post(
        API_URL + `emailTemplates/updateEmailTemplateById`,
        {
          id: updateTemplate.id.value,
          body: updateTemplate.body.value,
          subject: updateTemplate.subject.value,
          languageCode: updateTemplate.languageCode.value,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200)
          store.commit("emailTemplate/enableSuccessfullyUpdatedDialog");
      })
      .catch((error) => {
        store.commit("auth/updateModalError", error.response.data),
          store.commit("auth/enableModalError");
      });
  }
}
export default new EmailTemplatesService();
