const state = () => ({
  modalErrorMessage: '',
  hasModalError: false,
  visualPingLinks: [],
  pagination: {
    sortBy: '',
    descending: false,
    page: 1,
    rowsPerPage: 50,
    rowsNumber: null,
  },
  visualPingLinkWithChanges: {},
  showLinkChanges: false,
});

const getters = {};

const actions = {};

const mutations = {
  resetModalError(state) {
    state.modalErrorMessage = '';
  },
  enableModalError(state) {
    state.hasModalError = true;
  },
  disableModalError(state) {
    state.hasModalError = false;
    state.modalErrorMessage = '';
  },
  updateModalError(state, message) {
    state.modalErrorMessage = message;
  },
  updatePagination(state, val) {
    state.pagination.rowsPerPage = val.size;
    state.pagination.rowsNumber = val.count;
  },
  updatePaginationPage(state, val) {
    state.pagination.sortBy = val.sortBy;
    state.pagination.descending = val.descending;
    state.pagination.page = val.page;
  },
  updateVisualPingLinks(state, links) {
    if (state.visualPingLinks.length > 0)
      state.visualPingLinks = [];

    for (let i = 0; i < links.length; i++)
    {
        state.visualPingLinks.push(links[i]);
    }
  },
  updateVisualPingLinkWithChanges(state, linkWithNotifications) {
    state.visualPingLinkWithChanges = linkWithNotifications;
  },
  updateShowLinkChanges(state, val) {
    state.showLinkChanges = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};