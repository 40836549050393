<template>
  <ErrorModal />
  <AddCustomer v-if="showAdd === true" :showAdd="showAdd" />
  <div class="q-pa-md">
    <q-table
      class="sticky-header"
      table-header-style="background-color: #0a1f8f"
      table-header-class="text-white"
      title="Customers"
      :rows="customersList"
      :columns="columns"
      row-key="id"
      :filter="filter"
      :loading="loading"
      :dense="$q.screen.lt.md"
      :filter-method="customFilter"
      v-model:pagination="pagination"
      @request="onRequest"
      binary-state-sort
    >
      <template v-slot:loading>
        <q-inner-loading showing color="primary"></q-inner-loading>
      </template>
      <template v-slot:top>
        <q-btn
          class="orange-btn-filled bold-font"
          no-caps
          :disable="loading"
          label="Add new customer"
          @click="enableAdd"
          dense
        >
          <i class="las la-2x la-plus-circle la-fw"></i>
        </q-btn>
        <q-space />
        <q-toggle
          v-model="filter.filterToggleActive"
          val="active"
          label="Show active cutomers"
          size="xs"
          color="indigo-10"
        />
        <q-toggle
          v-model="filter.filterToggleInactive"
          val="inactive"
          label="Show inactive customers"
          size="xs"
          color="indigo-10"
        />
        <q-space />
        <q-input
          borderless
          dense
          debounce="300"
          color="primary"
          placeholder="Search"
          v-model="filter.searchTerm"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="name" :props="props">{{ props.row.name }}</q-td>
          <q-td key="isActive" :props="props">
            <i
              v-if="props.row.isActive"
              class="las la-2x la-check"
              style="color: green;"
            ></i>
            <i
              v-if="!props.row.isActive"
              class="las la-2x la-times"
              style="color: red;"
            ></i>
          </q-td>
          <q-td key="numberOfLicences" :props="props">
            <q-badge rounded color="green">
              {{ props.row.numberOfLicences }} licences
            </q-badge>
          </q-td>
          <q-td key="numberOfActiveUsers" :props="props">
            <q-badge color="positive" rounded>
              {{ props.row.numberOfActiveUsers }}
              <q-icon name="las la-user la-lg" color="white" class="q-ml-xs" />
            </q-badge>
          </q-td>
          <q-td key="endDate" :props="props">
            <span v-if="props.row.endDate != null && props.row.endDate != ''">{{
              formatDate(props.row.endDate)
            }}</span>
            <span v-if="props.row.endDate == null || props.row.endDate == ''"
              >Rolling contract<q-tooltip>End date not set</q-tooltip></span
            >
          </q-td>
          <q-td key="actions" :props="props" class="q-gutter-xs">
            <q-btn
              color="primary"
              icon-right="edit"
              size="sm"
              no-caps
              :to="{ name: 'EditCustomer', params: { id: props.row.id } }"
            >
              <q-tooltip>
                Edit
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="props.row.isActive"
              color="red"
              @click="(confirm = true), (customerId = props.row.id)"
              size="sm"
              no-caps
            >
              <i class="las la-2x la-ban la-fw"></i>
              <q-tooltip>
                Deactivate
              </q-tooltip>
            </q-btn>
            <q-btn
              color="indigo-10"
              @click="
                (confirmDeleteCustomer = true), (customerId = props.row.id)
              "
              size="sm"
              no-caps
            >
              <i class="las la-2x la-trash la-fw"></i>
              <q-tooltip>
                Remove
              </q-tooltip>
            </q-btn>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
  <q-dialog v-model="confirm" persistent class="modal-general">
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-ban la-fw"></i>
        <span class="q-ml-sm">Do you want to deactivate this customer?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          no-caps
          label="Deactivate"
          class="orange-btn-filled bold-font"
          v-close-popup
          @click="deactivateCustomer(customerId)"
        />
        <q-btn
          label="Cancel"
          class="orange-btn bold-font"
          no-caps
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="confirmDeleteCustomer" persistent class="modal-general">
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-trash la-fw"></i>
        <span class="q-ml-sm">Do you want to remove this customer?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          label="Remove"
          no-caps
          class="orange-btn-filled bold-font"
          v-close-popup
          @click="deleteCustomer(customerId)"
        />
        <q-btn
          label="Cancel"
          no-caps
          class="orange-btn bold-font"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, onMounted, computed, reactive } from 'vue';
import store from '../store/index.js';
import AddCustomer from '../components/customers-components/AddCustomer.vue';
import ErrorModal from '../components/customers-components/ErrorModal.vue';
import CustomersService from '../services/customers.service';
import moment from 'moment';

const columns = [
  {
    name: 'name',
    required: true,
    label: 'Name',
    align: 'left',
    sortable: true,
    field: (row) => row.name,
    format: (val) => `${val}`,
  },
  {
    name: 'isActive',
    align: 'center',
    sortable: true,
    label: 'Active',
    field: 'isActive',
  },
  {
    name: 'numberOfLicences',
    align: 'center',
    sortable: true,
    label: 'Number of licences',
    field: 'numberOfLicences',
  },
  {
    name: 'numberOfActiveUsers',
    align: 'center',
    sortable: true,
    label: 'Number of active users',
  },
  {
    name: 'endDate',
    align: 'center',
    label: 'End date',
    sortable: true,
    field: 'endDate',
  },
  { name: 'actions', align: 'center', label: 'Actions', field: 'actions' },
];

export default {
  name: 'CustomersGrid',
  components: {
    AddCustomer,
    ErrorModal,
  },
  setup() {
    const loading = ref(false);
    const filter = reactive({
      searchTerm: '',
      filterToggleActive: true,
      filterToggleInactive: true,
    });
    const rows = ref(customersList);
    const customerId = ref(null);

    const confirm = ref(false);
    const confirmDeleteCustomer = ref(false);

    onMounted(async () => {
      await CustomersService.getAllCustomers();
    });

    const customersList = computed({
      get: () => store.state.customers.customersList,
    });

    const enableAdd = () => {
      store.commit('customers/enableAdd');
    };

    const showAdd = computed({
      get: () => store.state.customers.showAdd,
    });

    const deactivateCustomer = async (customerId) => {
      await CustomersService.deactivateCustomer(customerId);

      refreshCustomersList();
    };

    const deleteCustomer = async (customerId) => {
      await CustomersService.deleteCustomer(customerId);
      refreshCustomersList();
    };

    const refreshCustomersList = () => {
      CustomersService.getAllCustomers();
    };

    const pagination = ref(
      computed({
        get: () => store.state.customers.pagination,
        set: (paginationVar) =>
          store.commit('customers/updatePagination', paginationVar),
      })
    );

    const onRequest = async (props) => {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const fetchCount =
        rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage;
      const filterSearchTerm = props.filter.searchTerm;

      const filterByActivityStatus = ref(null);
      loading.value = true;
      //Gather toggle conditions
      if (showAll.value == false) {
        if (props.filter.filterToggleActive == true)
          filterByActivityStatus.value = true;
        if (props.filter.filterToggleInactive == true)
          filterByActivityStatus.value = false;
      } else filterByActivityStatus.value = null;

      await CustomersService.getAllCustomers(
        filterSearchTerm,
        filterByActivityStatus.value,
        sortBy,
        descending,
        page,
        fetchCount
      );
      store.commit('customers/updatePaginationPage', props.pagination);
      loading.value = false;
    };

    const showAll = computed({
      get: () => {
        return filter.filterToggleActive && filter.filterToggleInactive;
      },
    });

    const customFilter = () => {
      return customersList.value;
    };

    return {
      showAll,
      columns,
      loading,
      filter,
      rows,
      customersList,
      enableAdd,
      showAdd,
      confirm,
      confirmDeleteCustomer,
      deleteCustomer,
      deactivateCustomer,
      refreshCustomersList,
      customerId,
      pagination,
      onRequest,
      customFilter,
      formatDate(stringDate) {
        const date = new Date(stringDate);
        return moment(date).format('DD-MM-YYYY');
      },
    };
  },
};
</script>
