const ESchemeStatus = Object.freeze({
    Unknown : 0,
    Open : 5,
    Closed : 10,
    Discontinued : 15,
    Future : 20,
    Sleeping : 25,
    Forthcoming : 30,
});

export default ESchemeStatus;