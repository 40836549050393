<template>
  <ModalError />
  <q-spinner
    v-if="areSchemeChangesLoading"
    size="4em"
    color="primary"
  ></q-spinner>
  <div v-else>
    <div v-if="isCall || isApplicationPeriod" class="call-edit-page">
      <CopyCall v-if="isCall || isApplicationPeriod" class="q-mt-md" />

      <div class="inputs-box">
        <q-input
          class="q-mt-md"
          outlined
          v-model="schemeName"
          bg-color="white"
          label="Call name"
          stack-label
          :rules="requiredFieldRule"
          lazy-rules
        />

        <q-input
          outlined
          v-model="schemeIdentifier"
          bg-color="white"
          label="Call identifier"
          stack-label
        />

        <q-input
          outlined
          v-model="schemeBudget"
          bg-color="white"
          label="Call budget"
          stack-label
          class="q-mt-md"
        />

        <q-select
          outlined
          bg-color="white"
          v-model="schemePublicationStatus"
          :options="publicationStatusOptions"
          class="q-mt-md scheme-inputs"
          option-value="name"
          option-label="name"
          stack-label
          label="Publication status"
        />
        <div class="application-periods-row">
          <div class="oneoff-application-period">
            <div class="row">
              <span class="app-period-heading">One-off application period</span>
              <q-checkbox v-model="isOneOffDeadline"></q-checkbox>
            </div>

            <q-input
              class="q-mt-md"
              outlined
              v-model="callStart"
              label="Start date"
              stack-label
              bg-color="white"
              :disable="!isOneOffDeadline"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="callStart"
                      mask="DD-MM-YYYY"
                      :options="startDateOptionsFn"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>

            <q-input
              class="q-mt-md"
              outlined
              v-model="callEnd"
              label="End date"
              bg-color="white"
              stack-label
              :rules="requiredFieldRule"
              :disable="!isOneOffDeadline"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="callEnd"
                      mask="DD-MM-YYYY"
                      :options="endDateOptionsFn"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="recurring-periods-row">
            <div class="column">
              <div class="row">
                <span class="app-period-heading"
                  >Recurring application period</span
                >
                <q-checkbox v-model="hasRecurringDeadline"> </q-checkbox>
              </div>

              <div class="yearinput-recurring row q-mt-md">
                Once per
                <q-input
                  class="q-pl-sm"
                  style="width: 80px"
                  outlined
                  bg-color="white"
                  type="number"
                  v-model="selectedSchemeRecurringYearsDuration"
                  :disable="!hasRecurringDeadline"
                  :rules="[requiredFieldRule, recurringRule]"
                >
                </q-input>
                <span class="q-pl-sm">
                  year(s)
                </span>
              </div>

              <div class="yearinput-recurring row q-mt-md">
                Starting in
                <q-input
                  class="q-pl-sm"
                  style="width: 100px"
                  outlined
                  bg-color="white"
                  type="number"
                  v-model="selectedSchemeRecurringStartingYear"
                  :disable="!hasRecurringDeadline"
                  :rules="[requiredFieldRule, recurringRule]"
                >
                </q-input>
              </div>
            </div>
            <div class="column recurring-dates q-mt-md q-ml-lg">
              <q-input
                class="q-mt-md"
                outlined
                v-model="selectedSchemeRecurringStartDate"
                label="Start date"
                stack-label
                bg-color="white"
                :disable="!hasRecurringDeadline"
                clearable
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="selectedSchemeRecurringStartDate"
                        mask="DD-MM"
                        :options="startDateOptionsFn"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>

              <q-input
                class="q-mt-md"
                outlined
                v-model="selectedSchemeRecurringEndDate"
                label="End date"
                stack-label
                bg-color="white"
                :disable="!hasRecurringDeadline"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="selectedSchemeRecurringEndDate"
                        mask="DD-MM"
                        :options="startDateOptionsFn"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
          </div>

          <div class="row is-open">
            <q-checkbox v-model="isAlwaysOpen">Is always open</q-checkbox>
          </div>
        </div>
      </div>

      <q-checkbox v-model="isEnglishScheme" label="Is english call" />
      <div class="row" style="align-items: center;">
        <q-checkbox
          v-show="isCall || isApplicationPeriod"
          v-model="isCall"
          label="Is call"
        />
        <div class="text-caption q-mt-xs q-ml-md">
          This checkbox is set to true if this item is a call, otherwise it is
          an application period.
        </div>
      </div>
      <span>
        <p class="section-lables q-mt-md">
          Body:
        </p>
      </span>
      <ckeditor
        :editor="editorRef.help.editor"
        v-model="schemeBody"
        :config="editorRef.help.editorConfig"
      ></ckeditor>

      <div v-if="transBody != null">
        <q-card class="my-card" flat bordered>
          <div
            style="padding-left:10px;"
            class="text-overline text-orange-9"
            @click="expanded = !expanded"
          >
            Show / hide translation
          </div>
          <q-slide-transition>
            <div v-show="expanded">
              <q-separator></q-separator>
              <q-card-section class="text-subtitle2">
                <div v-html="transBody"></div>
              </q-card-section>
            </div>
          </q-slide-transition>
        </q-card>
      </div>
      <div class="links-docs q-mt-lg">
        <div class="links-list scheme-inputs">
          <q-list bordered>
            <q-item-label class="tags-heading" header>
              Important links:
              <q-btn
                color="primary"
                :disable="loading"
                @click="
                  showAddLink = true;
                  showCategories = false;
                "
                dense
                no-caps
              >
                <i class="las la-1x la-plus-circle la-fw"></i>
                <q-tooltip>
                  Add new link
                </q-tooltip>
              </q-btn>
            </q-item-label>
            <q-item v-for="link in selectedSchemeImportantLinks" :key="link">
              <q-item-section avatar>
                <q-icon name="link" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="q-mt-xs link-text">
                  <span class="cursor-pointer">
                    <a :href="link.url" target="_blank">
                      {{ link.text + " (" + link.url + ")" }}
                    </a>
                  </span>
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <div class="q-gutter-xs">
                  <q-btn
                    color="red"
                    size="12px"
                    flat
                    dense
                    round
                    icon="delete"
                    @click="removeLink(link)"
                  >
                    <q-tooltip>
                      Remove link
                    </q-tooltip>
                  </q-btn>
                  <q-btn
                    @click="
                      showEditLink = true;
                      selectedLink = link;
                      showCategories = false;
                    "
                    dense
                    size="12px"
                    round
                    flat
                    color="indigo-10"
                    icon="las la-pencil-alt"
                  >
                    <q-tooltip>
                      Edit link
                    </q-tooltip>
                  </q-btn>
                </div>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>

      <AddLink
        :selectedSchemeLinks="selectedSchemeLinks"
        :showCategories="showCategories"
      />
      <AddFile :selectedSchemeDocuments="[]" />

      <SelectedTags />

      <div class="docs-and-other-links q-pb-sm">
        <q-list bordered separator>
          <q-item-label class="tags-heading" header>
            <div class="q-gutter-xs">
              Documents and other links:
              <q-btn
                color="primary"
                :disable="loading"
                @click="
                  showAddLink = true;
                  showCategories = true;
                "
                dense
                no-caps
              >
                <i class="las la-1x la-link la-fw"></i>
                <q-tooltip>
                  Add new link
                </q-tooltip>
              </q-btn>
              <q-btn
                color="primary"
                :disable="loading"
                @click="showAddDocument = true"
                dense
                no-caps
              >
                <i class="las la-1x la-paperclip la-fw"></i>
                <q-tooltip>
                  Add new document
                </q-tooltip>
              </q-btn>
            </div>
          </q-item-label>
          <q-item v-ripple v-for="item in sortedSchemeDocsAndLinks" :key="item">
            <template v-if="'extension' in item && 'name' in item">
              <q-item-section side style="width:150px;">
                {{ item.documentTypeDescription }}
              </q-item-section>
              <q-item-section avatar>
                <i
                  :class="iconBasedOnExtension(item.extension)"
                  style="color: blue;"
                ></i>
              </q-item-section>
              <q-item-section>{{ nameSubstring(item.name) }}</q-item-section>
              <q-item-section top side>
                <q-btn
                  class="gt-xs"
                  size="12px"
                  flat
                  dense
                  round
                  icon="delete"
                  color="red"
                  @click="
                    confirmRemovingFile = true;
                    documentId = item.id;
                  "
                >
                  <q-tooltip>Remove file</q-tooltip>
                </q-btn>
              </q-item-section>
              <q-item-section top side>
                <q-btn
                  class="gt-xs"
                  size="12px"
                  flat
                  dense
                  round
                  icon="download"
                  color="secondary"
                  @click="download(item.id, item.name)"
                >
                  <q-tooltip>Download file</q-tooltip>
                </q-btn>
              </q-item-section>
              <q-item-section top side>
                <q-btn
                  class="gt-xs"
                  size="12px"
                  flat
                  dense
                  round
                  icon="las la-pencil-alt"
                  color="indigo-10"
                  @click="
                    showEditDocument = true;
                    selectedDocument = item;
                  "
                >
                  <q-tooltip>Edit document</q-tooltip>
                </q-btn>
              </q-item-section>
              <q-tooltip>{{ item.name }}</q-tooltip>
            </template>
            <template v-if="'url' in item && 'text' in item">
              <q-item-section side style="width:150px;">
                {{ item.documentTypeDescription }}
              </q-item-section>
              <q-item-section avatar>
                <i class="las la-2x la-link" style="color: blue;"></i>
              </q-item-section>
              <q-item-section>
                <a :href="item.url" target="_blank">
                  {{ item.text + " (" + item.url + ")" }}
                </a>
              </q-item-section>
              <q-item-section top side>
                <q-btn
                  class="gt-xs"
                  size="12px"
                  flat
                  dense
                  round
                  icon="delete"
                  color="red"
                  @click="removeLink(item)"
                >
                  <q-tooltip>Remove link</q-tooltip>
                </q-btn>
              </q-item-section>
              <q-item-section top side>
                <q-btn
                  class="gt-xs"
                  size="12px"
                  flat
                  dense
                  round
                  icon="las la-pencil-alt"
                  color="indigo-10"
                  @click="
                    showEditLink = true;
                    selectedLink = item;
                    showCategories = true;
                  "
                >
                  <q-tooltip>Edit link</q-tooltip>
                </q-btn>
              </q-item-section>
            </template>
          </q-item>
        </q-list>
      </div>

      <q-dialog v-model="confirmRemovingFile" persistent>
        <q-card>
          <q-card-section class="row items-center">
            <q-avatar icon="delete" color="primary" text-color="white" />
            <span class="q-ml-sm"
              >Do you want to remove this file from the call?</span
            >
          </q-card-section>

          <q-card-actions align="right">
            <q-btn flat label="Cancel" color="primary" v-close-popup />
            <q-btn
              flat
              label="Delete"
              color="primary"
              v-close-popup
              @click="removeDocument(documentId)"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <div class="row justify-end submit-btn-container">
        <q-btn
          class="q-mb-lg btn-vind-dark"
          label="Submit changes"
          @click="editSchemeSubmit()"
        />
      </div>
      <EditLink v-if="selectedLink" :showCategories="showCategories" />
      <EditDocument v-if="selectedDocument" />
    </div>

    <div v-if="!isCall && !isApplicationPeriod" class="edit-scheme-page">
      <div class="row q-pt-md q-pl-md">
        <div class="col-md-5">
          <q-input
            class="q-mt-md"
            borderless
            v-model="schemeName"
            bg-color="white"
            label="Name"
            stack-label
            :rules="requiredFieldRule"
            style="min-width: 350px"
            lazy-rules
          />
        </div>

        <div class="col-md-5">
          <div class="row q-pa-md items-center justify-around">
            <div class="col-md-4 ratings q-gutter-x-lg q-pr-md">
              <div>
                <span>Complexity</span>
              </div>
              <q-rating
                v-model="schemeComplexityRating"
                size="sm"
                :max="5"
                color="primary"
                icon="star_border"
                icon-selected="star"
              ></q-rating>
            </div>
            <div class="col-md-4 ratings q-gutter-x-lg q-pr-md">
              <div>
                <span>Success rate</span>
              </div>
              <q-rating
                v-model="schemeChanceOfSuccessRating"
                size="sm"
                :max="5"
                color="primary"
                icon="star_border"
                icon-selected="star"
              ></q-rating>
            </div>
            <div class="col-md-4 ratings q-gutter-x-lg q-pr-md">
              <div>
                <span>Application duration</span>
              </div>
              <q-rating
                v-model="schemeTimeForApplicationRating"
                size="sm"
                :max="5"
                color="primary"
                icon="star_border"
                icon-selected="star"
              ></q-rating>
            </div>
          </div>
        </div>
        <div class="column q-pa-md">
          <CopyScheme v-if="!isCall && !isApplicationPeriod" />
          <CreateCall :parentSchemeName="schemeName" />
        </div>
      </div>
      <div class="row main-page">
        <div class="row justify-between scheme-top-inputs">
          <div class="scheme-inputs" style="width: 400px">
            <q-input
              borderless
              v-model="schemeIdentifier"
              bg-color="white"
              label="Identifier"
              stack-label
              disable
            />

            <q-input
              class="q-mt-md"
              v-if="!isCall && !isApplicationPeriod"
              borderless
              v-model="schemeAccronym"
              bg-color="white"
              label="Accronym"
              stack-label
              :rules="maxLengthRule"
              lazy-rules
            />

            <q-input
              v-if="!isCall && !isApplicationPeriod"
              borderless
              v-model="schemeArchiveCode"
              bg-color="white"
              label="Archive code"
              stack-label
              :rules="maxLengthRule"
              lazy-rules
            />

            <q-input
              v-model.number="schemeMinAmount"
              type="number"
              min="0"
              label="Minimum amount"
              stack-label
              bg-color="white"
              borderless
            />
            <q-input
              class="q-mt-md"
              v-model.number="schemeMaxAmount"
              type="number"
              min="0"
              label="Maximum amount"
              bg-color="white"
              stack-label
              borderless
            />
            <div class="date-input q-mt-md">
              <q-input
                borderless
                bg-color="white"
                v-model="schemeStart"
                label="Start date"
                stack-label
                class="date-input"
                v-if="!isCall && !isApplicationPeriod"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date v-model="schemeStart" mask="DD-MM-YYYY">
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>

            <div class="date-input q-mt-md">
              <q-input
                borderless
                class="date-input"
                bg-color="white"
                v-model="schemeEnd"
                label="End date"
                stack-label
                v-if="!isCall && !isApplicationPeriod"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date v-model="schemeEnd" mask="DD-MM-YYYY">
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>

            <div
              v-if="!isCall && !isApplicationPeriod"
              class="other-select q-mt-md"
              style="min-width: 250px"
            >
              <q-select
                borderless
                bg-color="white"
                v-model="schemeScope"
                :options="scopeOptions"
                class="scheme-inputs"
                option-value="name"
                option-label="name"
                stack-label
                label="Scheme scope"
              />
            </div>
          </div>

          <div class="edit-date-inputs q-mb-md">
            <div class="dropdowns-edit">
              <div class="other-select" style="min-width: 250px">
                <q-select
                  v-if="!isCall && !isApplicationPeriod"
                  borderless
                  bg-color="white"
                  v-model="schemeTerm"
                  :options="termOptions"
                  class="scheme-inputs"
                  option-value="name"
                  option-label="name"
                  stack-label
                  label="Application term"
                />
              </div>
              <div class="other-select q-mt-md" style="min-width: 250px">
                <q-select
                  borderless
                  bg-color="white"
                  v-model="schemePublicationStatus"
                  :options="publicationStatusOptions"
                  class="scheme-inputs"
                  option-value="name"
                  option-label="name"
                  stack-label
                  label="Publication status"
                />
              </div>

              <div class="other-select q-mt-md" style="min-width: 250px">
                <q-select
                  borderless
                  bg-color="white"
                  v-model="schemeStatus"
                  :options="schemeStatusOptions"
                  :display-value="displayStatus"
                  class="scheme-inputs"
                  option-value="value"
                  option-label="label"
                  stack-label
                  label="Status"
                  emit-value
                  map-options
                />
              </div>
              <div class="q-mt-md" style="min-width: 250px">
                <q-select
                  borderless
                  bg-color="white"
                  v-model="schemeFundingOrganisations"
                  multiple
                  :options="filterOrganisationsFundingOptions"
                  use-chips
                  use-input
                  option-value="name"
                  class="scheme-inputs"
                  option-label="name"
                  stack-label
                  label="Select funding organisation(s)"
                  @filter="filterFundingOrganisationsFn"
                  :rules="requiredFieldRule"
                  @update:model-value="setInput()"
                  ref="fundingOrgSelect"
                />
              </div>
              <div class="q-mt-sm" style="min-width: 250px">
                <q-select
                  borderless
                  bg-color="white"
                  v-model="schemeAdministratingOrganisations"
                  multiple
                  :options="filterOrganisationsAdministratingOptions"
                  use-chips
                  use-input
                  option-value="name"
                  class="scheme-inputs"
                  option-label="name"
                  stack-label
                  label="Select administrating organisation(s)"
                  @filter="filterAdministratingOrganisationsFn"
                  :rules="requiredFieldRule"
                  @update:model-value="setInput()"
                  ref="adminOrgsSelect"
                />
              </div>
              <div class="q-mt-md" style="min-width: 250px">
                <q-select
                  borderless
                  bg-color="white"
                  v-model="schemeAdvisingOrganisations"
                  multiple
                  :options="filterOrganisationsAdvisingOptions"
                  use-chips
                  use-input
                  option-value="name"
                  class="scheme-inputs"
                  option-label="name"
                  stack-label
                  label="Select advising organisation(s)"
                  @filter="filterAdvisingOrganisationsFn"
                  @update:model-value="setInput()"
                  ref="advisingOrgsSelect"
                />
              </div>
              <div class=" q-mt-sm" style="min-width: 250px">
                <q-select
                  borderless
                  bg-color="white"
                  v-model="schemeRecipientOrganisations"
                  multiple
                  :options="filterOrganisationsRecipientOptions"
                  use-chips
                  use-input
                  option-value="name"
                  class="scheme-inputs"
                  option-label="name"
                  stack-label
                  label="Select recipient organisation(s)"
                  @filter="filterRecipientOrganisationsFn"
                  @update:model-value="setInput()"
                  ref="recipientOrgsSelect"
                />
              </div>
              <div class="duration-input">
                <q-input
                  class="q-mt-sm"
                  borderless
                  v-model="schemeDuration"
                  bg-color="white"
                  label="Duration"
                  stack-label
                />
              </div>
              <div class="SDG-theme-select q-mt-sm" style="min-width: 250px">
                <q-select
                  borderless
                  bg-color="white"
                  v-model="schemeSDGTheme"
                  multiple
                  :options="filterThemesSDGOptions"
                  use-chips
                  use-input
                  class="scheme-inputs"
                  stack-label
                  label="Select SDG theme(s)"
                  @filter="filterThemesSDGFn"
                  ref="sdgThemeSelect"
                  @update:model-value="setInput()"
                />
              </div>
            </div>
          </div>

          <div class="scheme-checkboxes column" style="min-width: 240px">
            <q-checkbox v-model="isPortalScheme" label="Is portal scheme" />

            <q-checkbox v-model="isScienceScheme" label="Is science scheme" />
            <q-checkbox v-model="isEnglishScheme" label="Is english scheme" />
            <q-checkbox v-model="hasEnglishCalls" label="Has english calls" />
            <q-checkbox v-model="schemeIsOBScheme" label="Is OB scheme" />

            <q-input
              v-if="!isCall && !isApplicationPeriod && schemeIsOBScheme"
              borderless
              v-model="schemeOBTitle"
              bg-color="white"
              label="Scheme OB title"
              stack-label
              :rules="maxLengthRule"
              lazy-rules
              style="min-width: 240px"
            />
            <q-input
              v-if="!isCall && !isApplicationPeriod && schemeIsOBScheme"
              borderless
              v-model="schemeOBDescription"
              bg-color="white"
              label="Scheme OB description"
              stack-label
              lazy-rules
              style="min-width: 240px"
              type="textarea"
            />
          </div>
        </div>

        <div class="inputs-column" style="margin-left: 50px">
          <q-separator class="q-mb-md" />

          <span class="input-title">
            <p class="section-lables q-mt-lg">
              Exclusions:
            </p>
          </span>
          <!-- <ckeditor
          v-if="!isCall && !isApplicationPeriod"
          :editor="editorRef.help.editor"
          v-model="schemeExclusion"
          :config="editorRef.help.editorConfig"
        ></ckeditor> -->
          <q-separator class="q-mt-md q-mb-md" />

          <div class="links-list scheme-inputs">
            <q-list bordered>
              <q-item-label class="tags-heading" header>
                Important links:
                <q-btn
                  color="primary"
                  :disable="loading"
                  @click="
                    showAddLink = true;
                    showCategories = false;
                  "
                  dense
                  no-caps
                >
                  <i class="las la-1x la-plus-circle la-fw"></i>
                  <q-tooltip>
                    Add new link
                  </q-tooltip>
                </q-btn>
              </q-item-label>
              <q-item v-for="link in selectedSchemeImportantLinks" :key="link">
                <q-item-section avatar>
                  <q-icon name="link" />
                </q-item-section>
                <q-item-section>
                  <q-item-label class="q-mt-xs link-text">
                    <span class="cursor-pointer">
                      <a :href="link.url" target="_blank">
                        {{ link.text + " (" + link.url + ")" }}
                      </a>
                    </span>
                  </q-item-label>
                </q-item-section>
                <q-item-section side>
                  <div class="q-gutter-xs">
                    <q-btn
                      color="red"
                      size="12px"
                      flat
                      dense
                      round
                      icon="delete"
                      @click="removeLink(link)"
                    >
                      <q-tooltip>
                        Remove link
                      </q-tooltip>
                    </q-btn>
                    <q-btn
                      @click="
                        showEditLink = true;
                        selectedLink = link;
                        showCategories = false;
                      "
                      dense
                      size="12px"
                      round
                      flat
                      color="indigo-10"
                      icon="las la-pencil-alt"
                    >
                      <q-tooltip>
                        Edit link
                      </q-tooltip>
                    </q-btn>
                  </div>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>

        <div style="margin-left: 50px">
          <q-separator class="q-mt-md q-mb-md" />
          <span class="input-title">
            <p class="section-lables q-mt-lg">
              ICTU:
            </p>
          </span>
          <ICTUSection
            v-model:ICTUscheme="ICTUscheme"
            v-model:schemeMinimalAge="schemeMinimalAge"
            v-model:schemeMaximalAge="schemeMaximalAge"
            :educationLevelPairs="educationLevelPairs"
            :userStatusPairs="userStatusPairs"
          />

          <q-separator class="q-mt-md q-mb-md" />

          <div class="introduction-input">
            <span class="input-title">
              <p class="section-lables q-mt-lg">
                Introduction:
              </p>
            </span>
            <q-input
              class="q-mt-md"
              borderless
              v-model="schemeIntroduction"
              bg-color="white"
              type="textarea"
            />
          </div>
          <div v-if="transIntroduction != null">
            <q-card class="my-card" flat bordered>
              <div
                style="padding-left:10px;"
                class="text-overline text-orange-9"
                @click="expanded = !expanded"
              >
                Show / hide translation
              </div>
              <q-slide-transition>
                <div class="translation-box" v-show="expanded">
                  <q-separator></q-separator>
                  <q-card-section class="text-subtitle2">
                    <div v-html="transIntroduction"></div>
                  </q-card-section>
                </div>
              </q-slide-transition>
            </q-card>
          </div>
          <div class="introduction-input">
            <span class="input-title">
              <p class="section-lables q-mt-lg">
                Body:
              </p>
            </span>
            <ckeditor
              :editor="editorRef.help.editor"
              v-model="schemeBody"
              :config="editorRef.help.editorConfig"
            >
            </ckeditor>
          </div>
          <div v-if="transBody != null">
            <q-card class="my-card" flat bordered>
              <div
                style="padding-left:10px;"
                class="text-overline text-orange-9"
                @click="expanded = !expanded"
              >
                Show / hide translation
              </div>
              <q-slide-transition>
                <div class="translation-box" v-show="expanded">
                  <q-separator></q-separator>
                  <q-card-section class="text-subtitle2">
                    <div v-html="transBody"></div>
                  </q-card-section>
                </div>
              </q-slide-transition>
            </q-card>
          </div>
          <!-- 
        <div class="introduction-input">
          <span class="input-title">
            <p class="section-lables q-mt-lg">
              Application information:
            </p>
          </span>
          <ckeditor
            :editor="editorRef.help.editor"
            v-model="schemeApplicationInformation"
            :config="editorRef.help.editorConfig"
          ></ckeditor>
        </div> -->
          <span class="input-title">
            <p class="section-lables q-mt-lg">
              Application information:
            </p>
          </span>
          <ckeditor
            :editor="editorRef.help.editor"
            v-model="schemeApplicationInformation"
            :config="editorRef.help.editorConfig"
          ></ckeditor>
          <div v-if="selectedTransApplicationInformation != null">
            <q-card class="my-card" flat bordered>
              <div
                style="padding-left:10px;"
                class="text-overline text-orange-9"
                @click="expanded = !expanded"
              >
                Show / hide translation
              </div>
              <q-slide-transition>
                <div v-show="expanded">
                  <q-separator></q-separator>
                  <q-card-section class="text-subtitle2">
                    <div v-html="selectedTransApplicationInformation"></div>
                  </q-card-section>
                </div>
              </q-slide-transition>
            </q-card>
          </div>

          <div class="introduction-input">
            <span class="input-title">
              <p class="section-lables q-mt-lg">
                Application procedure:
              </p>
            </span>
            <q-input
              class="q-mt-md applicationprocedure-textarea"
              borderless
              v-model="schemeApplicationProcedure"
              bg-color="white"
              type="textarea"
            />
          </div>
          <div v-if="transApplicationProcedure != null">
            <q-card class="my-card" flat bordered>
              <div
                style="padding-left:10px;"
                class="text-overline text-orange-9"
                @click="expanded = !expanded"
              >
                Show / hide translation
              </div>
              <q-slide-transition>
                <div class="translation-box" v-show="expanded">
                  <q-separator></q-separator>
                  <q-card-section class="text-subtitle2">
                    <div v-html="transApplicationProcedure"></div>
                  </q-card-section>
                </div>
              </q-slide-transition>
            </q-card>
          </div>

          <div class="introduction-input q-mt-md">
            <span class="input-title">
              <p class="section-lables">
                Criteria:
              </p>
            </span>
            <ckeditor
              :editor="editorRef.help.editor"
              v-model="schemeCriteria"
              :config="editorRef.help.editorConfig"
            >
            </ckeditor>
          </div>
          <div v-if="transCriteria != null">
            <q-card class="my-card" flat bordered>
              <div
                style="padding-left:10px;"
                class="text-overline text-orange-9"
                @click="expanded = !expanded"
              >
                Show / hide translation
              </div>
              <q-slide-transition>
                <div class="translation-box" v-show="expanded">
                  <q-separator></q-separator>
                  <q-card-section class="text-subtitle2">
                    <div v-html="transCriteria"></div>
                  </q-card-section>
                </div>
              </q-slide-transition>
            </q-card>
          </div>

          <div class="introduction-input">
            <span class="input-title">
              <p class="section-lables q-mt-lg">
                Budget:
              </p>
            </span>
            <q-input
              class="q-mt-md budget-textarea"
              borderless
              v-model="schemeBudget"
              bg-color="white"
              type="textarea"
            />
          </div>
          <div class="introduction-input">
            <span class="input-title">
              <p class="section-lables q-mt-lg">
                Financial information:
              </p>
            </span>

            <ckeditor
              :editor="editorRef.help.editor"
              v-model="schemeFinancialInformation"
              :config="editorRef.help.editorConfig"
            ></ckeditor>
          </div>
          <div v-if="transFinancialinformation != null">
            <q-card class="my-card" flat bordered>
              <div
                style="padding-left:10px;"
                class="text-overline text-orange-9"
                @click="expanded = !expanded"
              >
                Show / hide translation
              </div>
              <q-slide-transition>
                <div class="translation-box" v-show="expanded">
                  <q-separator></q-separator>
                  <q-card-section class="text-subtitle2">
                    <div v-html="transFinancialinformation"></div>
                  </q-card-section>
                </div>
              </q-slide-transition>
            </q-card>
          </div>

          <div class="introduction-input">
            <span class="input-title">
              <p class="section-lables q-mt-lg">
                Portal body:
              </p>
            </span>

            <ckeditor
              :editor="editorRef.help.editor"
              v-model="schemePortalBody"
              :config="editorRef.help.editorConfig"
            >
            </ckeditor>
          </div>

          <SelectedTags />

          <div class="textarea-input" style="background: white">
            <q-list bordered separator class="textarea-input">
              <q-item-label class="tags-heading" header>
                <div class="q-gutter-xs">
                  Documents and other links:
                  <q-btn
                    color="primary"
                    :disable="loading"
                    @click="
                      showAddLink = true;
                      showCategories = true;
                    "
                    dense
                    no-caps
                  >
                    <i class="las la-1x la-link la-fw"></i>
                    <q-tooltip>
                      Add new link
                    </q-tooltip>
                  </q-btn>
                  <q-btn
                    color="primary"
                    :disable="loading"
                    @click="showAddDocument = true"
                    dense
                    no-caps
                  >
                    <i class="las la-1x la-paperclip la-fw"></i>
                    <q-tooltip>
                      Add new document
                    </q-tooltip>
                  </q-btn>
                </div>
              </q-item-label>
              <q-item
                v-ripple
                v-for="item in sortedSchemeDocsAndLinks"
                :key="item"
                class="textarea-input"
              >
                <template v-if="'extension' in item && 'name' in item">
                  <q-item-section side style="width:150px;">
                    {{ item.documentTypeDescription }}
                  </q-item-section>
                  <q-item-section avatar>
                    <i
                      :class="iconBasedOnExtension(item.extension)"
                      style="color: blue;"
                    ></i>
                  </q-item-section>
                  <q-item-section>{{
                    nameSubstring(item.name)
                  }}</q-item-section>
                  <q-item-section top side>
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      flat
                      dense
                      round
                      icon="delete"
                      color="red"
                      @click="
                        confirmRemovingFile = true;
                        documentId = item.id;
                      "
                    >
                      <q-tooltip>Remove file</q-tooltip>
                    </q-btn>
                  </q-item-section>
                  <q-item-section top side>
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      flat
                      dense
                      round
                      icon="download"
                      color="secondary"
                      @click="download(item.id, item.name)"
                    >
                      <q-tooltip>Download file</q-tooltip>
                    </q-btn>
                  </q-item-section>
                  <q-item-section top side>
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      flat
                      dense
                      round
                      icon="las la-pencil-alt"
                      color="indigo-10"
                      @click="
                        showEditDocument = true;
                        selectedDocument = item;
                      "
                    >
                      <q-tooltip>Edit document</q-tooltip>
                    </q-btn>
                  </q-item-section>
                  <q-tooltip>{{ item.name }}</q-tooltip>
                </template>
                <template v-if="'url' in item && 'text' in item">
                  <q-item-section side style="width:150px;">
                    {{ item.documentTypeDescription }}
                  </q-item-section>
                  <q-item-section avatar>
                    <i class="las la-2x la-link" style="color: blue;"></i>
                  </q-item-section>
                  <q-item-section>
                    <a :href="item.url" target="_blank">
                      {{ item.text + " (" + item.url + ")" }}
                    </a>
                  </q-item-section>
                  <q-item-section top side>
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      flat
                      dense
                      round
                      icon="delete"
                      color="red"
                      @click="removeLink(item)"
                    >
                      <q-tooltip>Remove link</q-tooltip>
                    </q-btn>
                  </q-item-section>
                  <q-item-section top side>
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      flat
                      dense
                      round
                      icon="las la-pencil-alt"
                      color="indigo-10"
                      @click="
                        showEditLink = true;
                        selectedLink = item;
                        showCategories = true;
                      "
                    >
                      <q-tooltip>Edit link</q-tooltip>
                    </q-btn>
                  </q-item-section>
                </template>
              </q-item>
            </q-list>
          </div>
        </div>

        <AddLink
          :selectedSchemeLinks="selectedSchemeLinks"
          :showCategories="showCategories"
        />
        <AddFile :selectedSchemeDocuments="[]" />

        <div
          class="tagsarea-input q-ml-md q-mt-lg"
          style="margin-left: 50px"
          v-if="!isCall && !isApplicationPeriod"
        >
          <SchemeUpdates :schemeUpdates="schemeUpdates" action="Editing" />
        </div>

        <div class="column">
          <div class="call-create-section row justify-end q-mt-lg">
            <CreateCall
              :parentSchemeName="schemeName"
              class="createCall-bottom"
            />
          </div>

          <div
            class="q-mt-lg"
            style="margin-left: 50px"
            v-if="!isCall && !isApplicationPeriod"
          >
            <CallsAndApplicationPeriods />
          </div>
        </div>

        <q-dialog v-model="confirmRemovingFile" persistent>
          <q-card>
            <q-card-section class="row items-center">
              <q-avatar icon="delete" color="primary" text-color="white" />
              <span class="q-ml-sm"
                >Do you want to remove this file from scheme?</span
              >
            </q-card-section>

            <q-card-actions align="right">
              <q-btn flat label="Cancel" color="primary" v-close-popup />
              <q-btn
                flat
                label="Delete"
                color="primary"
                v-close-popup
                @click="removeDocument(documentId)"
              />
            </q-card-actions>
          </q-card>
        </q-dialog>

        <q-dialog v-model="successfullyCreatedCall" persistent>
          <q-card style="width: 300px">
            <q-card-section class="row items-center">
              <span>Successfully created!</span>
            </q-card-section>
            <q-card-actions align="right" class="bg-white text-teal">
              <q-btn flat label="OK" @click="disableDialog()" />
            </q-card-actions>
          </q-card>
        </q-dialog>
        <EditLink v-if="selectedLink" :showCategories="showCategories" />
        <EditDocument v-if="selectedDocument" />

        <div class="row justify-end q-mr-md" style="width: 100%">
          <div class="column">
            <q-btn
              class="q-mb-xl q-mt-lg orange-btn-filled bold-font"
              no-caps
              label="Submit changes"
              @click="editSchemeSubmit()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <q-dialog v-model="showDiscontinuedModal" persistent>
    <q-card style="width: 300px">
      <q-card-section>
        <div class="text-h6">Warning</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        Are you sure you want to set this scheme, including all childs, to
        discontinued?
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat label="Yes" @click="setDiscontinued()" />
        <q-btn flat label="No" @click="disableWarning()" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import SchemesServices from "../../services/schemes.services";
import TagsService from "../../services/tags.service";
import OrganisationsService from "../../services/organisations.service";
import { onMounted, computed, watch, ref } from "vue";
import store from "../../store/index";
import { useRoute } from "vue-router";
import ModalError from "../schemes-components/SchemeModalError.vue";
import CallsAndApplicationPeriods from "./CallsAndApplicationPeriods.vue";
import ICTUSection from "./ICTUSection.vue";
import SchemeUpdates from "./SchemeUpdates.vue";
import SelectedTags from "./SelectedTags.vue";
import CopyScheme from "./CopyScheme.vue";
import CreateCall from "./CreateCall.vue";
import CopyCall from "./CopyCall.vue";
import AddLink from "./AddLink.vue";
import EditLink from "./EditLink.vue";
import AddFile from "./AddFile.vue";
import EditDocument from "./EditDocument.vue";
import help from "../../helpers/editor";
import ETagType from "../../enums/ETagType";
import ESchemeStatus from "../../enums/ESchemeStatus";
import EDocumentType from "../../enums/EDocumentType";

export default {
  name: "EditScheme",
  components: {
    ModalError,
    CallsAndApplicationPeriods,
    ICTUSection,
    CreateCall,
    SchemeUpdates,
    SelectedTags,
    CopyScheme,
    CopyCall,
    AddLink,
    EditLink,
    AddFile,
    EditDocument,
  },
  setup() {
    const route = useRoute();
    const documentId = ref(null);
    const confirm = ref(false);
    const confirmRemovingFile = ref(false);
    const showCategories = ref(false);
    const editorRef = help;

    //This is just hidden for now, don't remove it
    //const filterIndustries = ref('');

    onMounted(async () => {
      await TagsService.getTagTypeTree("User Status", ETagType.UserStatus);
      await TagsService.getTagTypeTree(
        "Education Level",
        ETagType.EducationLevel
      );
      await SchemesServices.getSchemeById(route.params.id, false);
      await SchemesServices.getSchemeSelectedTags(
        route.params.id,
        "User Status",
        ETagType.UserStatus
      );
      await SchemesServices.getSchemeSelectedTags(
        route.params.id,
        "Education Level",
        ETagType.EducationLevel
      );
      await OrganisationsService.getAllOrganisationsList();
      SchemesServices.getSMETypes();
      SchemesServices.getScopes();
      SchemesServices.getTerms();
      SchemesServices.getDocumentTypes();
      await SchemesServices.getPublicationStatuses();
      await SchemesServices.getSchemeSDGThemeTypes();
      SchemesServices.getSchemeStatuses();
    });

    const schemeStatusOptions = computed({
      get: () => store.state.scheme.schemeStatuses
    });

    const displayStatus = computed(() => {
      const status = schemeStatus.value == 0;
      return status ? 'Unknown' : schemeStatus.value.label;
});
 
    const selectedLink = computed({
      get: () => store.state.scheme.selectedLink,
      set: (value) => store.commit("scheme/setSelectedLink", value),
    });

    const selectedDocument = computed({
      get: () => store.state.scheme.selectedDocument,
      set: (value) => store.commit("scheme/setSelectedDocument", value),
    });

    const selectedSchemeLinks = computed({
      get: () => store.state.scheme.selectedSchemeLinks,
      set: (value) => store.commit("scheme/updateSelectedSchemeLinks", value),
    });

    const selectedSchemeImportantLinks = computed(() => {
      return selectedSchemeLinks.value.filter(
        (el) => el.documentType === EDocumentType.UsefulLinks || el.documentType === null
      );
    });

    const selectedSchemeOtherLinks = computed(() => {
      return selectedSchemeLinks.value.filter(
        (el) =>
          el.documentType !== EDocumentType.UsefulLinks &&
          el.documentType !== null &&
          el.documentType !== EDocumentType.VisualPingLink
      );
    });

    const schemeName = computed({
      get: () => store.state.scheme.selectedSchemeName,
      set: (value) => store.commit("scheme/updateSchemeName", value),
    });

    const selectedScheme = computed({
      get: () => store.state.scheme.selectedScheme,
    });

    const isCall = computed({
      get: () => store.state.scheme.selectedSchemeIsCall,
      set: (value) => store.commit("scheme/updateIsCall", value),
    });

    const isApplicationPeriod = computed({
      get: () => store.state.scheme.selectedSchemeIsApplicationPeriod,
    });

    const schemeIdentifier = computed({
      get: () => store.state.scheme.selectedSchemeIdentifier,
      set: (value) => store.commit("scheme/updateSchemeIdentifier", value),
    });

    const schemeIntroduction = computed({
      get: () => store.state.scheme.selectedSchemeIntroduction,
      set: (value) => store.commit("scheme/updateSchemeIntroduction", value),
    });

    const schemeBody = computed({
      get: () => store.state.scheme.selectedSchemeBody,
      set: (value) => store.commit("scheme/updateSchemeBody", value),
    });

    const transBody = computed({
      get: () => store.state.scheme.selectedTransBody,
    });

    const transCriteria = computed({
      get: () => store.state.scheme.selectedTransCriteria,
    });

    const transName = computed({
      get: () => store.state.scheme.selectedTransName,
    });

    const transApplicationProcedure = computed({
      get: () => store.state.scheme.selectedTransApplicationProcedure,
    });

    const transIntroduction = computed({
      get: () => store.state.scheme.selectedTransIntroduction,
    });

    const selectedTransApplicationInformation = computed({
      get: () => store.state.scheme.selectedTransApplicationInformation,
    });

    const transFinancialinformation = computed({
      get: () => store.state.scheme.selectedTransFinancialinformation,
    });

    const schemeCriteria = computed({
      get: () => store.state.scheme.selectedSchemeCriteria,
      set: (value) => store.commit("scheme/updateSchemeCriteria", value),
    });

    const schemeIsOBScheme = computed({
      get: () => store.state.scheme.selectedSchemeIsOBScheme,
      set: (value) => store.commit("scheme/updateSchemeIsOB", value),
    });

    const schemeOBTitle = computed({
      get: () => store.state.scheme.selectedSchemeOBTitle,
      set: (value) => store.commit("scheme/updateSchemeOBTitle", value),
    });

    const schemeOBDescription = computed({
      get: () => store.state.scheme.selectedSchemeOBDescription,
      set: (value) => store.commit("scheme/updateSchemeOBDescription", value),
    });

    const schemeBudget = computed({
      get: () => store.state.scheme.selectedSchemeBudget,
      set: (value) => store.commit("scheme/updateSchemeBudget", value),
    });

    const schemeMinimalAge = computed({
      get: () => store.state.scheme.selectedSchemeMinimalAge,
      set: (value) => store.commit("scheme/updateSchemeMinimalAge", value),
    });

    const schemeMaximalAge = computed({
      get: () => store.state.scheme.selectedSchemeMaximalAge,
      set: (value) => store.commit("scheme/updateSchemeMaximalAge", value),
    });

    const schemeMinAmount = computed({
      get: () => store.state.scheme.selectedSchemeMinAmount,
      set: (value) => store.commit("scheme/updateSchemeMinAmount", value),
    });

    const schemeMaxAmount = computed({
      get: () => store.state.scheme.selectedSchemeMaxAmount,
      set: (value) => store.commit("scheme/updateSchemeMaxAmount", value),
    });

    const isPortalScheme = computed({
      get: () => store.state.scheme.selectedSchemeIsPortal,
      set: (val) => store.commit("scheme/updateSchemeIsPortal", val),
    });

    const isAlwaysOpen = computed({
      get: () => store.state.scheme.selectedSchemeIsAlwaysOpen,
      //set: (val) => store.commit('scheme/updateSchemeIsAlwaysOpen', val),
      set: (val) =>
        store.commit("scheme/updateSelectedSchemeDeadlineType", {
          type: "recurring",
          value: val,
        }),
    });

    const isEnglishScheme = computed({
      get: () => store.state.scheme.selectedSchemeIsEnglish,
      set: (val) => store.commit("scheme/updateSchemeIsEnglish", val),
    });

    const hasEnglishCalls = computed({
      get: () => store.state.scheme.selectedSchemeHasEnglishCalls,
      set: (val) => store.commit("scheme/updateSchemeHasEnglishCalls", val),
    });

    const isScienceScheme = computed({
      get: () => store.state.scheme.selectedSchemeIsScience,
      set: (val) => store.commit("scheme/updateSchemeIsScience", val),
    });

    const ICTUscheme = computed({
      get: () => store.state.scheme.selectedSchemeICTU,
      set: (value) => store.commit("scheme/updateSchemeICTU", value),
    });

    const showAddLink = computed({
      get: () => store.state.scheme.showAddLink,
      set: (value) => store.commit("scheme/updateShowAddLink", value),
    });

    const showEditLink = computed({
      get: () => store.state.scheme.showEditLink,
      set: (value) => store.commit("scheme/updateShowEditLink", value),
    });

    const showAddDocument = computed({
      get: () => store.state.scheme.showAddDocument,
      set: (value) => store.commit("scheme/updateShowAddDocument", value),
    });

    const showEditDocument = computed({
      get: () => store.state.scheme.showEditDocument,
      set: (value) => store.commit("scheme/updateShowEditDocument", value),
    });

    const schemeStart = computed({
      get: () => store.state.scheme.selectedSchemeStartDate,
      set: (value) => store.commit("scheme/updateSchemeStart", value),
    });
    const schemeEnd = computed({
      get: () => store.state.scheme.selectedSchemeEndDate,
      set: (value) => store.commit("scheme/updateSchemeEnd", value),
    });
    const locationTagsSelectBulk = computed({
      get: () => store.state.scheme.locationTagsSelectBulk,
    });
    const locationTagsSelectedIds = computed(
      () => store.state.scheme.locationTagsSelectedIds
    );

    const callStart = computed({
      get: () => store.state.scheme.selectedSchemeCallStartDate,
      set: (value) => store.commit("scheme/updateSchemeCallStart", value),
    });
    const callEnd = computed({
      get: () => store.state.scheme.selectedSchemeCallEndDate,
      set: (value) => store.commit("scheme/updateSchemeCallEnd", value),
    });
    const schemeUpdates = computed({
      get: () => store.state.scheme.selectedSchemeUpdates,
      set: (value) => store.commit("scheme/updateSchemeUpdates", value),
    });
    const schemeFundingOrganisations = computed({
      get: () => store.state.scheme.selectedSchemeFundingOrgs,
      set: (value) => store.commit("scheme/updateSchemeFundingOrgs", value),
    });
    const schemeAdministratingOrganisations = computed({
      get: () => store.state.scheme.selectedSchemeAdministratingOrgs,
      set: (value) =>
        store.commit("scheme/updateSchemeAdministratingOrgs", value),
    });
    const schemeAdvisingOrganisations = computed({
      get: () => store.state.scheme.selectedSchemeAdvisingOrgs,
      set: (value) => store.commit("scheme/updateSchemeAdvisingOrgs", value),
    });
    const schemeSDGTheme = computed({
      get: () => store.state.scheme.selectedSchemeSDGThemes,
      set: (value) =>
        store.commit("scheme/updateSelectedSchemeSDGThemes", value),
    });
    const schemeRecipientOrganisations = computed({
      get: () => store.state.scheme.selectedSchemeRecipientOrgs,
      set: (value) => store.commit("scheme/updateSchemeRecipientOrgs", value),
    });

    const schemeScope = computed({
      get: () => store.state.scheme.selectedSchemeScope,
      set: (value) => store.commit("scheme/updateScope", value),
    });

    const schemeTerm = computed({
      get: () => store.state.scheme.selectedSchemeTerm,
      set: (value) => store.commit("scheme/updateTerm", value),
    });

    const schemeSME = computed({
      get: () => store.state.scheme.selectedSchemeSME,
      set: (value) => store.commit("scheme/updateSelectedSME", value),
    });

    const schemeExclusion = computed({
      get: () => store.state.scheme.selectedSchemeExclusion,
      set: (value) => store.commit("scheme/updateSchemeExclusion", value),
    });

    const schemeFinancialInformation = computed({
      get: () => store.state.scheme.selectedSchemeFinancialInformation,
      set: (value) =>
        store.commit("scheme/updateSchemeFinancialInformation", value),
    });

    const schemeApplicationProcedure = computed({
      get: () => store.state.scheme.selectedSchemeApplicationProcedure,
      set: (value) =>
        store.commit("scheme/updateSchemeApplicationProcedure", value),
    });

    const schemeApplicationInformation = computed({
      get: () => store.state.scheme.selectedSchemeApplicationInformation,
      set: (value) =>
        store.commit("scheme/updateSchemeApplicationInformation", value),
    });

    const schemePortalBody = computed({
      get: () => store.state.scheme.selectedSchemePortalBody,
      set: (value) => store.commit("scheme/updateSchemePortalBody", value),
    });

    const schemeComplexityRating = computed({
      get: () => store.state.scheme.selectedSchemeComplexityRating,
      set: (value) =>
        store.commit("scheme/updateSchemeComplexityRating", value),
    });

    const schemeChanceOfSuccessRating = computed({
      get: () => store.state.scheme.selectedSchemeChanceOfSuccessRating,
      set: (value) =>
        store.commit("scheme/updateSchemeChanceOfSuccessRating", value),
    });

    const schemeTimeForApplicationRating = computed({
      get: () => store.state.scheme.selectedSchemeTimeForApplicationRating,
      set: (value) =>
        store.commit("scheme/updateSchemeTimeForApplicationRating", value),
    });

    const audiencePairs = computed({
      get: () => store.state.scheme.selectedSchemeAudienceTags,
      set: (val) => store.commit("scheme/setSelectedSchemeAudienceTags", val),
    });

    const purposesPairs = computed({
      get: () => store.state.scheme.selectedSchemePurposesTags,
      set: (val) => store.commit("scheme/setSelectedSchemePurposesTags", val),
    });

    const locationsPairs = computed({
      get: () => store.state.scheme.selectedSchemeLocationsTags,
      set: (val) => store.commit("scheme/setSelectedSchemeLocationsTags", val),
    });

    const schemeTypesPairs = computed({
      get: () => store.state.scheme.selectedSchemeSchemeTypesTags,
      set: (val) => store.commit("scheme/setSelectedSchemeSchemeTypeTags", val),
    });

    const projectTypesPairs = computed({
      get: () => store.state.scheme.selectedSchemeProjectTypesTags,
      set: (val) =>
        store.commit("scheme/setSelectedSchemeProjectTypeTags", val),
    });

    const industriesNewPairs = computed({
      get: () => store.state.scheme.selectedSchemeIndustriesNewTags,
      set: (val) =>
        store.commit("scheme/setSelectedSchemeIndustriesNEWTags", val),
    });

    const userStatusPairs = computed({
      get: () => store.state.scheme.selectedSchemeUserStatusTags,
      set: (val) => store.commit("scheme/setSelectedSchemeUserStatusTags", val),
    });

    const educationLevelPairs = computed({
      get: () => store.state.scheme.selectedSchemeEducationLevelTags,
      set: (val) =>
        store.commit("scheme/setSelectedSchemeEducationLevelTags", val),
    });

    const schemeKeywordsTags = computed({
      get: () => store.state.scheme.selectedSchemeKeywordsTags,
    });

    const schemePublicationStatus = computed({
      get: () => store.state.scheme.selectedSchemePublicationStatus,
      set: (value) => store.commit("scheme/updatePublicationStatus", value),
    });

    const schemeStatus = computed({
      get: () => store.state.scheme.selectedSchemeStatus,
      set: (value) => store.commit("scheme/updateSchemeStatus", value),
    });

    const publicationStatusOptions = computed({
      get: () => store.state.scheme.publicationStatuses,
    });

    const id = route.params.id;

    const allOrganisations = computed({
      get: () => store.state.organisation.organisationsList,
    });

    const schemeSDGThemeTypesList = computed({
      get: () => store.state.scheme.schemeSDGThemeTypes,
    });

    const filterOrganisationsFundingOptions = ref(allOrganisations.value);
    const filterOrganisationsAdministratingOptions = ref(
      allOrganisations.value
    );
    const filterOrganisationsAdvisingOptions = ref(allOrganisations.value);
    const filterOrganisationsRecipientOptions = ref(allOrganisations.value);
    const filterThemesSDGOptions = ref(schemeSDGThemeTypesList.value);

    const scopeOptions = computed({
      get: () => store.state.scheme.scopes,
    });
    const termOptions = computed({
      get: () => store.state.scheme.applicationTerms,
    });

    const schemeAccronym = computed({
      get: () => store.state.scheme.selectedSchemeAccronym,
      set: (value) => store.commit("scheme/updateSelectedAccronym", value),
    });

    const schemeArchiveCode = computed({
      get: () => store.state.scheme.selectedSchemeArchivecode,
      set: (value) => store.commit("scheme/updateSelectedArchivecode", value),
    });

    const schemeDuration = computed({
      get: () => store.state.scheme.selectedSchemeDuration,
      set: (value) => store.commit("scheme/updateSchemeDuration", value),
    });

    const hasRecurringDeadline = computed({
      get: () => store.state.scheme.selectedSchemeHasRecurringDeadline,
      set: (val) =>
        store.commit("scheme/updateSelectedSchemeDeadlineType", {
          type: "recurring",
          value: val,
        }),
    });

    const selectedSchemeRecurringStartingYear = computed({
      get: () => store.state.scheme.selectedSchemeRecurringStartingYear,
      set: (val) =>
        store.commit("scheme/updateSelectedSchemeRecurringStartingYear", val),
    });

    const selectedSchemeRecurringYearsDuration = computed({
      get: () => store.state.scheme.selectedSchemeRecurringYearsDuration,
      set: (val) =>
        store.commit("scheme/updateSelectedSchemeRecurringYearsDuration", val),
    });
    const selectedSchemeRecurringStartDate = computed({
      get: () => store.state.scheme.selectedSchemeRecurringStartDate,
      set: (val) =>
        store.commit("scheme/updateSelectedSchemeRecurringStartDate", val),
    });

    const selectedSchemeRecurringEndDate = computed({
      get: () => store.state.scheme.selectedSchemeRecurringEndDate,
      set: (val) =>
        store.commit("scheme/updateSelectedSchemeRecurringEndDate", val),
    });

    const locationGroups = computed({
      get: () => store.state.tree.locationGroupsSelected,
    });

    const areSchemeChangesLoading = computed({
      get: () => store.state.scheme.areSchemeChangesLoading,
    });

    const updatedScheme = {
      id: id,
      schemeName: schemeName,
      schemeIntroduction: schemeIntroduction,
      schemeStart: schemeStart,
      schemeEnd: schemeEnd,
      callStart: callStart,
      callEnd: callEnd,
      schemeBody: schemeBody,
      schemeCriteria: schemeCriteria,
      schemeBudget: schemeBudget,
      schemeFundingOrganisations: schemeFundingOrganisations,
      schemeAdministratingOrganisations: schemeAdministratingOrganisations,
      schemeAdvisingOrganisations: schemeAdvisingOrganisations,
      schemeRecipientOrganisations: schemeRecipientOrganisations,
      schemeAudienceTags: audiencePairs,
      schemePurposesTags: purposesPairs,
      //This is just hidden for now, don't remove it
      //schemeIndustriesTags: industriesPairs,
      schemeLocationsTags: locationsPairs,
      schemeSchemeTypesTags: schemeTypesPairs,
      schemeProjectTypesTags: projectTypesPairs,
      schemeKeywordsTags: schemeKeywordsTags,
      schemeIndustriesNewTags: industriesNewPairs,
      schemeUserStatusTags: userStatusPairs,
      schemeEducationLevelTags: educationLevelPairs,
      schemeScope: schemeScope,
      schemeTerm: schemeTerm,
      schemeSME: schemeSME,
      schemeAccronym: schemeAccronym,
      schemeArchiveCode: schemeArchiveCode,
      schemePublicationStatus: schemePublicationStatus,
      schemeStatus: schemeStatus,
      isCall: isCall,
      isApplicationPeriod: isApplicationPeriod,
      schemeMinimalAge: schemeMinimalAge,
      schemeMaximalAge: schemeMaximalAge,
      schemeMinAmount: schemeMinAmount,
      schemeMaxAmount: schemeMaxAmount,
      ICTUscheme: ICTUscheme,
      schemeExclusion: schemeExclusion,
      schemeFinancialInformation: schemeFinancialInformation,
      schemePortalBody: schemePortalBody,
      schemeApplicationProcedure: schemeApplicationProcedure,
      schemeComplexityRating: schemeComplexityRating,
      schemeChanceOfSuccessRating: schemeChanceOfSuccessRating,
      schemeTimeForApplicationRating: schemeTimeForApplicationRating,
      schemeDuration: schemeDuration,
      schemeIdentifier: schemeIdentifier,
      schemeLinks: selectedSchemeLinks,
      schemeUpdates: schemeUpdates,
      schemeApplicationInformation: schemeApplicationInformation,
      isPortalScheme: isPortalScheme,
      isScienceScheme: isScienceScheme,
      isEnglishScheme: isEnglishScheme,
      hasEnglishCalls: hasEnglishCalls,
      isAlwaysOpen: isAlwaysOpen,
      schemeSDGThemes: schemeSDGTheme,
      hasRecurringDeadline: hasRecurringDeadline,
      selectedSchemeRecurringStartingYear: selectedSchemeRecurringStartingYear,
      selectedSchemeRecurringYearsDuration: selectedSchemeRecurringYearsDuration,
      selectedSchemeRecurringStartDate: selectedSchemeRecurringStartDate,
      selectedSchemeRecurringEndDate: selectedSchemeRecurringEndDate,
      groupsList: locationGroups,
      locationTags: locationTagsSelectedIds,
      isOBScheme: schemeIsOBScheme,
      oBDescription: schemeOBDescription,
      oBTitle: schemeOBTitle,
    };
    watch(locationGroups, (newValue) => {
      updatedScheme.groupsList = newValue;
    });
    const editSchemeSubmit = async () => {
      if (
        !updatedScheme.schemeFundingOrganisations.value.length > 0 &&
        isCall.value == false &&
        isApplicationPeriod.value == false
      ) {
        store.commit("scheme/enableModalError");
        store.commit(
          "scheme/updateModalError",
          "Select funding organisation(s)!"
        );
      } else if (
        !updatedScheme.schemeLocationsTags.value.length > 0 &&
        isCall.value == false &&
        isApplicationPeriod.value == false
      ) {
        store.commit("scheme/enableModalError");
        store.commit(
          "scheme/updateModalError",
          "Please select at least one location tag."
        );
      } else await SchemesServices.editScheme(updatedScheme);
    };

    const selected = ref([]);

    const schemeDocuments = computed({
      get: () => store.state.scheme.selectedSchemeDocuments,
    });

    const sortedSchemeDocsAndLinks = computed(() => {
      var schemeDocsAndLinks = [
        ...schemeDocuments.value,
        ...selectedSchemeOtherLinks.value,
      ];
      return schemeDocsAndLinks.sort((a, b) =>
        a.documentType > b.documentType ? 1 : -1
      );
    });

    const removeDocument = async (documentId) => {
      await SchemesServices.removeDocument(id, documentId);
    };

    const nameSubstring = (name) => {
      return String(name).slice(0, 150);
    };

    const download = async (documentId, documentName) => {
      await SchemesServices.downloadDocument(documentId).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        var a = document.createElement("a");
        a.setAttribute("style", "display:none;");
        document.body.appendChild(a);
        a.download = documentName;
        a.href = fileURL;
        a.target = "_blank";
        a.click();
        document.body.removeChild(a);
      });
    };

    const tab = ref("audiences");

    // new values from word doc here
    const isVS = computed({
      get: () => store.state.scheme.selectedSchemeVS,
      set: (value) => store.commit("scheme/updateSelectedSchemeVS", value),
    });

    const schemeId = computed({
      get: () => store.state.scheme.selectedSchemeId,
    });

    const successfullyCreatedCall = computed({
      get: () => store.state.scheme.showSuccessfullyCreatedCallDialog,
    });

    const disableDialog = () => {
      store.commit("scheme/disableSuccessfullyCreatedCallDialog");
    };

    const removeLink = (link) => {
      const index = selectedSchemeLinks.value.indexOf(link);

      if (index > -1) selectedSchemeLinks.value.splice(index, 1);
    };

    const isOneOffDeadline = computed({
      get: () => store.state.scheme.selectedSchemeIsOneOffDeadline,
      set: (val) =>
        store.commit("scheme/updateSelectedSchemeDeadlineType", {
          type: "oneOff",
          value: val,
        }),
    });

    const fundingOrgSelect = ref(null);
    const adminOrgsSelect = ref(null);
    const advisingOrgsSelect = ref(null);
    const recipientOrgsSelect = ref(null);
    const sdgThemeSelect = ref(null);
    const setInput = () => {
      fundingOrgSelect.value.updateInputValue("");
      adminOrgsSelect.value.updateInputValue("");
      advisingOrgsSelect.value.updateInputValue("");
      recipientOrgsSelect.value.updateInputValue("");
      sdgThemeSelect.value.updateInputValue("");
    };

    const originalSchemeStatus = computed({
      get: () => store.state.scheme.originalSchemeStatus,
    });

    const showDiscontinuedModal = computed({
      get: () => store.state.scheme.showDiscontinuedModal,
      set: (val) => store.commit("scheme/updatedShowDiscontinuedModal", val),
    });

    watch(schemeStatus, (newValue, oldValue) => {
      if (
        newValue == ESchemeStatus.Discontinued &&
        oldValue != ESchemeStatus.Discontinued &&
        originalSchemeStatus.value != ESchemeStatus.Discontinued
      ) {
        store.commit("scheme/updatedShowDiscontinuedModal", true);
      }
    });

    const disableWarning = () => {
      store.commit("scheme/updatedShowDiscontinuedModal", false);
      store.commit("scheme/resetSchemeStatus");
    };

    const setDiscontinued = () => {
      store.commit("scheme/updateSchemeStatus", ESchemeStatus.Discontinued);
      store.commit("scheme/updatedShowDiscontinuedModal", false);
    };

    return {
      displayStatus,
      schemeStatusOptions,
      showDiscontinuedModal,
      setDiscontinued,
      disableWarning,
      schemeIsOBScheme,
      schemeOBTitle,
      schemeOBDescription,
      adminOrgsSelect,
      advisingOrgsSelect,
      recipientOrgsSelect,
      sdgThemeSelect,
      setInput,
      fundingOrgSelect,
      areSchemeChangesLoading,
      locationGroups,
      selectedTransApplicationInformation,
      isAlwaysOpen,
      expanded: ref(false),
      isOneOffDeadline,
      editorRef,
      isScienceScheme,
      schemeSDGTheme,
      filterThemesSDGOptions,
      schemeSDGThemeTypesList,
      isPortalScheme,
      // new values from word start here
      isVS,
      schemeId,
      schemeDuration,
      // end here
      tab,
      SchemesServices,
      selectedScheme,
      schemeName,
      schemeIdentifier,
      schemeIntroduction,
      editSchemeSubmit,
      download,
      schemeStart,
      schemeEnd,
      callStart,
      callEnd,
      schemeBody,
      transBody,
      transApplicationProcedure,
      transCriteria,
      transFinancialinformation,
      transIntroduction,
      transName,
      schemeCriteria,
      schemeBudget,
      allOrganisations,
      schemeFundingOrganisations,
      schemeAdministratingOrganisations,
      schemeAdvisingOrganisations,
      schemeRecipientOrganisations,
      isEnglishScheme,
      hasEnglishCalls,
      selected,
      schemeScope,
      schemeTerm,
      schemeSME,
      scopeOptions,
      termOptions,
      schemeAccronym,
      schemeArchiveCode,
      isCall,
      isApplicationPeriod,
      confirmRemovingFile,
      selectedDocument,
      schemeApplicationInformation,
      hasRecurringDeadline,
      selectedSchemeRecurringEndDate,
      selectedSchemeRecurringStartDate,
      selectedSchemeRecurringStartingYear,
      selectedSchemeRecurringYearsDuration,
      locationTagsSelectBulk,
      locationTagsSelectedIds,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || "Field is required!",
      ],
      maxLengthRule: [
        (val) => !!val || "Type something",
        (val) => val.length < 100 || "Max length is 100 characters!",
      ],
      //This is just hidden for now, don't remove it
      //schemeIndustriesTags,
      //industriesNodes,
      //tickedIndustries,
      //industriesTree,
      //filterIndustries,
      //industriesPairs,
      //updateIndustriesPairs,
      //schemeIndustriesTagsObjects,
      //industriesCount,
      //resetSelectedIndustries,
      //selectAllIndustries,
      schemeKeywordsTags,
      schemePublicationStatus,
      publicationStatusOptions,
      schemeStatus,
      showAddLink,
      showEditLink,
      showAddDocument,
      showEditDocument,
      documentId,
      removeDocument,
      removeLink,
      confirm,
      filterOrganisationsFundingOptions,
      filterOrganisationsAdministratingOptions,
      filterOrganisationsAdvisingOptions,
      filterOrganisationsRecipientOptions,
      schemeDocuments,
      schemeMaximalAge,
      schemeMinimalAge,
      ICTUscheme,
      nameSubstring,
      audiencePairs,
      purposesPairs,
      locationsPairs,
      schemeTypesPairs,
      projectTypesPairs,
      industriesNewPairs,
      educationLevelPairs,
      userStatusPairs,
      schemeMinAmount,
      schemeMaxAmount,
      schemeExclusion,
      schemeComplexityRating,
      schemeChanceOfSuccessRating,
      schemeTimeForApplicationRating,
      schemeApplicationProcedure,
      schemePortalBody,
      schemeFinancialInformation,
      successfullyCreatedCall,
      selectedSchemeLinks,
      disableDialog,
      schemeUpdates,
      selectedLink,
      showCategories,
      selectedSchemeImportantLinks,
      selectedSchemeOtherLinks,
      sortedSchemeDocsAndLinks,

      filterFundingOrganisationsFn(val, update) {
        update(() => {
          if (val === "") {
            filterOrganisationsFundingOptions.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();
            filterOrganisationsFundingOptions.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterAdministratingOrganisationsFn(val, update) {
        update(() => {
          if (val === "") {
            filterOrganisationsAdministratingOptions.value =
              allOrganisations.value;
          } else {
            const needle = val.toLowerCase();
            filterOrganisationsAdministratingOptions.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterAdvisingOrganisationsFn(val, update) {
        update(() => {
          if (val === "") {
            filterOrganisationsAdvisingOptions.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();
            filterOrganisationsAdvisingOptions.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterRecipientOrganisationsFn(val, update) {
        update(() => {
          if (val === "") {
            filterOrganisationsRecipientOptions.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();
            filterOrganisationsRecipientOptions.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterThemesSDGFn(val, update) {
        update(() => {
          if (val === "") {
            filterThemesSDGOptions.value = schemeSDGThemeTypesList.value;
          } else {
            const needle = val.toLowerCase();
            filterThemesSDGOptions.value = schemeSDGThemeTypesList.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      iconBasedOnExtension(extension) {
        switch (extension) {
          case ".pdf":
            return "las la-2x la-file-pdf";
          case ".doc":
            return "las la-2x la-file-word";
          case ".docx":
            return "las la-2x la-file-word";
          case ".xls":
            return "las la-2x la-file-excel";
          case ".xlsx":
            return "las la-2x la-file-excel";
          case ".csv":
            return "las la-2x la-file-csv";
          case ".txt":
            return "las la-2x la-file";
          default:
            return "las la-2x la-file";
        }
      },
    };
  },
};
</script>
