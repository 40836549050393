<template>
  <div class="q-pa-md login-page">
    <ErrorModal v-if="hasModalError && !isPrd" />
    <q-form @submit="onSubmit" @reset="onReset" class="q-gutter-md login-form" v-if="!isPrd">
      <h4>Login</h4>
      <q-input outlined v-model="user.email" label="Email" />
      <div class="password-fields">
        <q-input outlined type="password" v-model="user.password" label="Password" lazy-rules :rules="[
  (val) =>
    (val && val.length > 5) || 'Password must have 6 characters',
]" />
        <div class="password-options">
          <q-checkbox v-model="user.right" label="Remember me" />
          <q-btn no-caps flat color="dark" label="Forgot password?" />
        </div>
      </div>
      <div class="row justify-center">
        <q-btn label="LOGIN" type="submit" color="#0a1f8f" style="background:#14157e;" />
        <q-btn label="Reset" type="reset" color="primary" flat class="q-ml-sm" />
      </div>
    </q-form>
    <div class="row justify-center">
      <div class="q-pt-lg">
        <div @click="loginms" class="btn-ms-signin"></div>
      </div>
    </div>
  </div>
</template>
<script>

import { computed, onMounted, reactive, ref } from 'vue';
import store from '../store/index.js';
import { useRouter } from 'vue-router'
import ErrorModal from './ErrorModal.vue';
import axios from 'axios';
import axiosUrl from '../services/axios.service';

const API_URL = axiosUrl.getAxiosUrl();

export default {
  components: {
    ErrorModal
  },
  setup() {

    onMounted(() => {
      axios
        .get(`${API_URL}info/GetMode`)
        .then((response) => {
          isPrd.value = response.data == 'production';
        });
    });

    const isPrd = ref(true);
    const user = reactive({
      email: null,
      password: null,
      right: false
    });
    const router = useRouter();

    const isLoggedIn = computed({
      get: () => store.state.auth.isLoggedIn,
    });
    const hasModalError = computed({
      get: () => store.state.auth.hasModalError,
    });


    if (isLoggedIn.value == true) {
      router.push({
        name: 'TagTab',
        params: {
          tab: 'Audience'
        }
      });
    }
    const loginms = () => {
      return axios
      .get(`${API_URL}users/GetAzureAdUrl`)
      .then((response) => {
        location.href = response.data;
      })
      .catch((error) => {
        if (error)
            store.commit('auth/updateModalError', error.response.data),
              store.commit('auth/enableModalError');
      });
    }
    const onSubmit = () => {
      store.dispatch("auth/login", user).then(
        () => {
          const localStorageUser = JSON.parse(localStorage.getItem('user'));
          let token = JSON.parse(
            Buffer.from(localStorageUser.token.split('.')[1], 'base64')
          );
          store.commit('auth/setCustid', Number.parseInt(token.custid));
          router.push({
            name: 'TagTab',
            params: {
              tab: 'Audience'
            }
          });
        }
      )
        .catch((error) => {
          if (error)
            store.commit('auth/updateModalError', error.response.data),
              store.commit('auth/enableModalError');
        });
    };

    return {
      isPrd,
      onSubmit,
      router,
      isLoggedIn,
      hasModalError,
      user,
      loginms,
      onReset() {
        user.email.value = null;
        user.password.value = null;
      },
    };
  },
};
</script>
