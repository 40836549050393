import store from "../index";

const state = () => ({
  selectedYear: 2023,
  selectedMonths: [],
  selectedMonthsIds: [],
  selectedCustomers: [],
  registeredUsers: 0,
  registeredUsersInTimeFrame: 0,
  usersThatHaveRevisited: 0,
  usersWithLoginInLast60Days: 0,
  registeredUsersData: [],
  chartData: {},
  chartDataLabels: [],
  activeUsersData: [],
  chartDataVisits: {},
  chartDataVisitsLabels: [],
  visitsData: [],
  tableStats: [],
  schemeVisitsBarData: {},
  schemePurposesBarData: {},
  barLabelsAllUsers: [],
  barDataAllusers: [],
  mainBarData: [],
  selectedMonthsIdsServer: [],
  monthFrom: 0,
  monthTo: 0,
  monthFromId: 0,
  monthToId: 0,
  optionsYearsFrom: [],
  optionsYearsTo: [],
  yearFrom: 2023,
  yearTo: 2023,
  usersTableExportStats: [],
  portalUsers: [],
  selectedPortalUsers: [],
});

const getters = {};

const actions = {};
import moment from "moment";
const mutations = {
  updatePortalUsers(state, portalUsers) {
    state.portalUsers = portalUsers;
  },

  updateSelectedPortalUsers(state, val) {
    state.selectedPortalUsers = val;
  },

  updateYearsOptions(state) {
    state.optionsYearsFrom = [];
    state.optionsYearsTo = [];
    var minYear = 2017;
    var current = moment().year();
    state.yearFrom = current;
    state.yearTo = current;
    while (current >= minYear) {
      state.optionsYearsFrom.push(current);
      state.optionsYearsTo.push(current);
      current--;
    }
  },
  updatePortalUsersTableExportable(state, val) {
    state.usersTableExportStats = [];

    for (let i = 0; i < val.length; i++) {
      state.usersTableExportStats.push(val[i]);
    }
  },
  updateYearsToOptions(state) {
    state.yearFrom == moment().year()
      ? (state.optionsYearsTo = [state.yearFrom])
      : (state.optionsYearsTo = [state.yearFrom, state.yearFrom + 1]);
  },
  updateYearFrom(state, val) {
    state.yearFrom = val;
  },
  updateYearTo(state, val) {
    state.yearTo = val;
  },
  updateMonthFrom(state, val) {
    state.monthFrom = val.name;
    state.monthFromId = val.id + 1;
  },
  updateMonthTo(state, val) {
    state.monthTo = val.name;
    state.monthToId = val.id + 1;
  },
  updateMainPortalGraph(state, val) {
    var barLabelsAllUsers = [];
    var barDataAllusers = [];
    var barLabelsVisitsByUser = [];
    var barDataVisitsByUser = [];
    var barLabelsNewUsers = [];
    var barDataNewUsers = [];
    var barLabelsVisitsByNewUsers = [];
    var barDataVisitsByNewUsers = [];
    if (val != null) {
      if (val.registeredAndActiveUsers != null)
        for (let i = 0; i < val.registeredAndActiveUsers.length; i++) {
          barLabelsAllUsers.push(
            val.registeredAndActiveUsers[i].environmentString
          );
          barDataAllusers.push(val.registeredAndActiveUsers[i].count);
        }
      if (val.visitsByUsers != null)
        for (let i = 0; i < val.visitsByUsers.length; i++) {
          barLabelsVisitsByUser.push(val.visitsByUsers[i].environmentString);
          barDataVisitsByUser.push(val.visitsByUsers[i].count);
          barLabelsAllUsers.push(val.visitsByUsers[i].environmentString);
        }
      if (val.registeredInLast30Days != null)
        for (let i = 0; i < val.registeredInLast30Days.length; i++) {
          barLabelsNewUsers.push(
            val.registeredInLast30Days[i].environmentString
          );
          barDataNewUsers.push(val.registeredInLast30Days[i].count);
          barLabelsAllUsers.push(
            val.registeredInLast30Days[i].environmentString
          );
        }
      if (val.visitsByNewUsers != null)
        for (let i = 0; i < val.visitsByNewUsers.length; i++) {
          barLabelsVisitsByNewUsers.push(
            val.visitsByNewUsers[i].environmentString
          );
          barDataVisitsByNewUsers.push(val.visitsByNewUsers[i].count);
          barLabelsAllUsers.push(val.visitsByNewUsers[i].environmentString);
        }
    }

    let labelsFinal = [...new Set(barLabelsAllUsers)];
    state.mainBarData = {
      labels: labelsFinal,

      datasets: [
        {
          label: "Total active users",
          backgroundColor: "#FF5B33",
          data: barDataAllusers,
          color: "#FF5B33",
          borderColor: "#FF5B33",
        },
        {
          label: "Total visits by users",
          backgroundColor: "#0a108f",
          data: barDataVisitsByUser,
          color: "#0a108f",
          borderColor: "#0a108f",
        },
        {
          label: "Users registered in last 30 days",
          backgroundColor: "#1299d7",
          data: barDataNewUsers,
          color: "#1299d7",
          borderColor: "#1299d7",
        },
        {
          label: "Visits by new users",
          backgroundColor: "#503447",
          data: barDataVisitsByNewUsers,
          color: "#503447",
          borderColor: "#503447",
        },
      ],
    };
  },
  resetFilters(state) {
    state.selectedCustomers = [];
  },
  updateTableStats(state, val) {
    state.tableStats = [];
    for (let i = 0; i < val.length; i++) {
      state.tableStats.push(val[i]);
    }

    state.isTableLoading = false;
  },

  updateSchemeVisitsBarData(state, val) {
    var barLabels = [];
    var barData = [];
    if (val && val.length > 16)
      for (let i = 0; i < 16; i++) {
        barLabels.push(val[i].schemeName);
        barData.push(val[i].visitCount);
      }
    else {
      for (let i = 0; i < val.length; i++) {
        barLabels.push(val[i].schemeName);
        barData.push(val[i].visitCount);
      }
    }
    state.schemeVisitsBarData = {
      labels: barLabels,

      datasets: [
        {
          label: "Scheme visits",
          backgroundColor: "#0a108f",
          data: barData,
          color: "#0a108f",
          borderColor: "#0a108f",
        },
      ],
    };
  },

  updatePortalSchemePurposesData(state, val) {
    var barLabels = [];
    var barData = [];

    if (val)
      for (let i = 0; i < val.length && i < 16; i++) {
        barLabels.push(val[i].name);
        barData.push(val[i].count);
      }

    state.schemePurposesBarData = {
      labels: barLabels,

      datasets: [
        {
          label: "Most popular purposes",
          backgroundColor: "#0a108f",
          data: barData,
          color: "#0a108f",
          borderColor: "#0a108f",
        },
      ],
    };
  },

  updateSelectedMonths(state, val) {
    function toMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber, 0);

      return date.toLocaleString("nl-NL", {
        month: "long",
      });
    }
    state.selectedMonths = [];
    state.chartDataLabels = [];
    state.chartDataVisitsLabels = [];
    state.selectedMonthsIds = [];
    state.selectedMonthsIdsServer = [];
    for (let i = 0; i < val.length; i++) {
      state.selectedMonths.push(val[i]);
      state.selectedMonthsIds.push(val[i].id);
      state.selectedMonthsIdsServer.push(val[i].id + 1);
      state.chartDataLabels.push(val[i].monthName + " " + val[i].year);

      state.chartDataVisitsLabels.push(
        toMonthName(val[i].id + 1).substring(0, 3)
      );
    }
  },

  updateSelectedYear(state, val) {
    state.selectedYear = val;
  },

  updateSelectedCustomers(state, val) {
    state.selectedCustomers = val;
  },

  updateBoxesData(state, val) {
    state.registeredUsers = val.registeredUsers;
    state.registeredUsersInTimeFrame = val.registeredUsersInTimeFrame;
    state.usersThatHaveRevisited = val.usersThatHaveRevisited;
    state.usersWithLoginInLast60Days = val.usersWithLoginInLast60Days;
  },

  updateChartDataUsers(state, data) {
    state.registeredUsersData = [];
    state.activeUsersData = [];
    store.commit("portalStats/updateUsersDatasets", data);

    state.chartDataLabels = [];
    for (let i = 0; i < data.registeredUsers.length; i++) {
      state.chartDataLabels.push(
        data.registeredUsers[i].monthName + " " + data.registeredUsers[i].year
      );
    }

    state.chartData = {
      labels: state.chartDataLabels,

      datasets: [
        {
          label: "Registered users",
          backgroundColor: "#1299d7",
          data: state.registeredUsersData,
          color: "#1299d7",
          borderColor: "#1299d7",
        },
        {
          label: "Active users",
          backgroundColor: "#0a108f",
          data: state.activeUsersData,
          color: "#0a108f",
          borderColor: "#0a108f",
        },
      ],
    };
  },

  updateUsersDatasets(state, data) {
    for (let i = 0; i < data.registeredUsers.length; i++) {
      state.registeredUsersData.push(data.registeredUsers[i].count);
    }
    for (let i = 0; i < data.activeUsers.length; i++) {
      state.activeUsersData.push(data.activeUsers[i].count);
    }
  },

  updateChartDataVisits(state, data) {
    state.visitsData = [];
    store.commit("portalStats/updateVisitsDatasets", data);

    state.chartDataVisitsLabels = [];
    for (let i = 0; i < data.totalVisits.length; i++) {
      state.chartDataVisitsLabels.push(
        data.totalVisits[i].monthName + " " + data.totalVisits[i].year
      );
    }

    state.chartDataVisits = {
      labels: state.chartDataVisitsLabels,
      datasets: [
        {
          label: "Total visits",
          backgroundColor: "#1299d7",
          data: state.visitsData,
          color: "#1299d7",
          borderColor: "#1299d7",
        },
      ],
    };
  },

  updateVisitsDatasets(state, data) {
    for (let i = 0; i < data.totalVisits.length; i++) {
      state.visitsData.push(data.totalVisits[i].count);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
