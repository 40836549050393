<template>
  <div class="news-select-page">
    <div class="page-top">
      <div class="logo-container row justify-end">
        <img
          class="logo"
          src="https://www.vindsubsidies.nl/frontend/assets/static/logo.svg"
        />
      </div>
      <p class="page-intro">
        Gebaseerd op het profiel van deze klant, zijn
        {{ currentNewsOptions.length }} nieuwsberichten klaargezet
      </p>
    </div>
  </div>

  <div class="news-select customers-select">
    <NewsDialogPerCustomer />
    <div class="select-page-top">
      <div class="row justify-between fixed-width">
        <div class="column">
          <h3 class="page-heading">
            {{ currentSalesForceCustomer.name }}
          </h3>
          <div class="page-sub">
            Selecteer de nieuwsberichten voor deze klant
          </div>
        </div>

        <div
          class="counter"
          v-if="salesForceCustomersCount - currentCustomersIndex - 1 > 1"
        >
          Nog {{ salesForceCustomersCount - currentCustomersIndex - 1 }} klanten
          te gaan
        </div>
        <div
          class="counter"
          v-else-if="salesForceCustomersCount - currentCustomersIndex - 1 == 1"
        >
          Nog {{ salesForceCustomersCount - currentCustomersIndex - 1 }} klant
          te gaan
        </div>
        <div class="counter" v-else>
          Dit is de laatste
        </div>
      </div>
    </div>

    <div class="column">
      <q-btn-group class="btn-group" outline>
        <q-btn
          no-caps
          class="btn-vind-dark"
          @click="selectAllNews()"
          label="Selecteer alles"
        />
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Deselecteer alles"
          @click="deselectAllNews()"
        />
      </q-btn-group>
      <div class="container-customers-box">
        <div class="customers-box">
          <q-item-label class="list-header" header>News title</q-item-label>
          <q-tree
            :nodes="currentNewsOptions"
            v-model:ticked="tickedNews"
            node-key="id"
            tick-strategy="strict"
            default-expand-all
            v-model:selected="selectedNews"
            @update:selected="showNewsDetails()"
          />
        </div>
      </div>
    </div>

    <div class="next-item-group row">
      <q-btn-group v-if="currentCustomersIndex == salesForceCustomersCount - 1">
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Vorige item"
          @click="decreaseCustomersIndex()"
          :disable="currentCustomersIndex == 0 ? true : false"
        />
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Finish"
          @click="goToFinishPageCustomers()"
        />
      </q-btn-group>
      <q-btn-group v-else outline>
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Vorige item"
          @click="decreaseCustomersIndex()"
          :disable="currentCustomersIndex == 0 ? true : false"
        />
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Volgende item"
          @click="increaseCustomersIndex()"
          :disable="
            currentCustomersIndex == salesForceCustomersCount - 1 ? true : false
          "
        />
      </q-btn-group>
    </div>
  </div>

  <NewsDialog />
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import store from '../store/index';
import NewsDialog from '../components/news-select-components/NewsDialog.vue';
import { useRouter } from 'vue-router';
import NewsDialogPerCustomer from '../components/news-select-components/NewsDialogPerCustomer.vue';

export default {
  name: 'NewsSelectPage',
  components: {
    NewsDialog,
    NewsDialogPerCustomer,
  },
  setup() {
    onMounted(async () => {
      store.commit('salesForce/setDefaultCustomerItem');
      store.commit('salesForce/resetSelectedNewsPerCustomer');
    });
    const user = computed({
      get: () => store.state.auth.user,
    });

    // This part is for selecting customers per news item
    const currentNewsItem = computed({
      get: () => store.state.salesForce.currentNewsItem,
    });
    const currentIndex = computed({
      get: () => store.state.salesForce.currentIndex,
    });

    const isNewsSelectPage = computed({
      get: () => store.state.newsSelect.isNewsSelectPage,
    });
    const itemsCount = computed({
      get: () => store.state.salesForce.newsItemsCount,
    });
    const decreaseIndex = () => {
      store.commit('salesForce/updateCustomersForNewsItem', ticked.value);
      store.commit('salesForce/decreaseIndex');
      ticked.value = [];
    };

    const increaseIndex = () => {
      store.commit('salesForce/updateCustomersForNewsItem', ticked.value);
      store.commit('salesForce/increaseIndex');
      ticked.value = [];
    };

    const enableNewsDialog = () => {
      store.commit('salesForce/enableNewsModal');
    };
    const router = useRouter();

    const goToFinishPage = () => {
      store.commit('salesForce/updateCustomersForNewsItem', ticked.value);
      ticked.value = [];

      store.commit('salesForce/setIndex', 0);
      router.push({
        name: 'NewsSelectFinal',
      });
    };

    const customerTags = computed({
      get: () => store.state.salesForce.accountsList.customerTags,
    });

    const customersList = computed({
      get: () => store.state.salesForce.customersList,
    });

    const ticked = ref([]);

    const deselectAll = () => {
      ticked.value = [];
    };

    const selectAll = () => {
      for (let i = 0; i < customersList.value.length; i++)
        ticked.value.push(customersList.value[i].id);
    };

    // This part is for selecting news items per customer
    const tickedNews = ref([]);
    const accountsList = computed({
      get: () => store.state.salesForce.accountsList,
    });

    const salesForceCustomersList = computed({
      get: () => store.state.salesForce.salesForceCustomersList,
    });

    const salesForceCustomersCount = computed({
      get: () => store.state.salesForce.salesForceCustomersCount,
    });

    const currentSalesForceCustomer = computed({
      get: () => store.state.salesForce.currentSalesForceCustomer,
    });

    const currentCustomersIndex = computed({
      get: () => store.state.salesForce.currentCustomersIndex,
    });

    const decreaseCustomersIndex = () => {
      store.commit('salesForce/updateNewsForCustomer', tickedNews.value);

      store.commit('salesForce/decreaseCustomerIndex');
      store.commit('salesForce/updateNewsForCurrentCustomer');
      tickedNews.value = store.state.salesForce.selectedNewsForCurrentCustomer;
    };

    const increaseCustomersIndex = () => {
      store.commit('salesForce/updateNewsForCustomer', tickedNews.value);

      store.commit('salesForce/increaseCustomerIndex');
      store.commit('salesForce/updateNewsForCurrentCustomer');
      tickedNews.value = store.state.salesForce.selectedNewsForCurrentCustomer;
    };

    const currentNewsOptions = computed({
      get: () => store.state.salesForce.currentNewsOptions,
    });

    const deselectAllNews = () => {
      tickedNews.value = [];
    };

    const selectAllNews = () => {
      for (let i = 0; i < currentNewsOptions.value.length; i++)
        tickedNews.value.push(currentNewsOptions.value[i].id);
    };

    const selectedNewsPerCustomer = computed({
      get: () => store.state.salesForce.selectedNewsPerCustomer,
    });

    const goToFinishPageCustomers = () => {
      store.commit('salesForce/updateNewsForCustomer', tickedNews.value);

      store.commit('salesForce/setCustomersIndex', 0);
      store.commit('salesForce/createNewsItemsPerCustomerListForServer');
      router.push({
        name: 'NewsForCustomersFinal',
      });
    };

    const showNewsDetails = () => {
      if (selectedNews.value != null) {
        store.commit(
          'salesForce/updateCurrentNewsItemPerCustomer',
          selectedNews.value
        );
        store.commit('salesForce/enableNewsModalPerCustomer');
      }
    };

    const selectedNews = ref(null);

    return {
      // Select news items per customer
      selectedNews,
      showNewsDetails,
      goToFinishPageCustomers,
      selectedNewsPerCustomer,
      accountsList,
      salesForceCustomersList,
      salesForceCustomersCount,
      currentSalesForceCustomer,
      currentCustomersIndex,
      increaseCustomersIndex,
      decreaseCustomersIndex,
      currentNewsOptions,
      tickedNews,
      selectAllNews,
      deselectAllNews,
      // Select customers per news item
      selectAll,
      deselectAll,
      ticked,
      customersList,
      customerTags,
      goToFinishPage,
      enableNewsDialog,
      increaseIndex,
      decreaseIndex,
      currentNewsItem,
      currentIndex,
      itemsCount,
      user,
      isNewsSelectPage,
    };
  },
};
</script>
