<template>
  <div class="ob-stats-page">
    <!--     <div class="row page-heading">
      <h2 class="title">
        Onze stats
      </h2>

      <div class="row nav-main">
        <router-link :to="{ name: 'GeneralStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('general')">
            General stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'UserStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('user')">
            User stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'PortalStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('portal')">
            Portal stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'ConsumptionStats' }" class="page-link">
          <q-btn
            flat
            class="btn-navigation"
            :class="isUnderlined('consumption')"
          >
            Consumption stats
          </q-btn>
        </router-link>
      </div>
    </div>

    <div class="row" style="width: 98vw">
      <div class="filters">
        <div class="search-button">
          <div class="warning-container">
            <p class="warning">
              Select filter(s) to show data
            </p>
          </div>
          <q-btn
            class="orange-btn bold-font"
            no-caps
            label="Search"
            @click="dataFilters()"
          />
        </div>
        <q-select
          class="select-filter"
          borderless
          bg-color="white"
          v-model="selectedTeam"
          label="Select team"
          :options="departments"
          behavior="menu"
          option-value="name"
          option-label="name"
          clearable
        ></q-select>
      </div>
      <div class="stats-boxes row q-mb-lg" style="max-width: 74vw">
        <div class="stat-section">
          <span class="stat-section-data">
            {{ numberOfUniqueMessagesSent }}
          </span>
          <span class="stat-section-text">
            aantal unieke berichten gestuurd
          </span>
        </div>
        <div class="stat-section">
          <span class="stat-section-data">
            {{ numberOfBounces }}
          </span>
          <span class="stat-section-text">
            aantal bounces
          </span>
        </div>
        <div class="stat-section">
          <span class="stat-section-data">
            {{ numberOfContactsOBHasOpened }}
          </span>
          <span class="stat-section-text">
            aantal contacten dat OB heeft geopend
          </span>
        </div>

        <div class="stat-section">
          <span class="stat-section-data">
            {{ numberOfContactsWhoClicked }}
          </span>
          <span class="stat-section-text">
            aantal contacten dat heeft geklikt
          </span>
        </div>
      </div>
    </div>
    <div class="column" style="width: 96vw">
      <div class="row justify-center">
        <OnzeNewSelectedMixed
          class="q-ml-lg scheme-visits-bar-row"
          style="width: 42vw"
        />
        <OnzeMixed
          class="q-ml-lg q-mt-lg scheme-visits-bar-row"
          style="width: 42vw"
        />
      </div>
      <OnzeMixedSecondData class="q-ml-lg q-mt-lg" />
    </div>
    <div class="row">
      <div class="tables q-mt-xl">
        <q-table
          class="my-sticky-header-table q-mb-lg"
          row-key="name"
          flat
          bordered
          :separator="'cell'"
          :pagination="pagination"
          :rows="activeConsultants"
          :columns="activeConsultantsColumns"
          :rows-per-page-options="[]"
          title="Actieve consultants"
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="name" :props="props">
                {{ props.row.name }}
              </q-td>
              <q-td key="department" :props="props">
                {{ props.row.department }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
        
        <q-table
          class="my-sticky-header-table q-mb-lg"
          row-key="name"
          flat
          bordered
          :separator="'cell'"
          :pagination="pagination"
          :rows="inactiveConsultants"
          :columns="activeConsultantsColumns"
          :rows-per-page-options="[]"
          title="Niet actieve consultants"
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="name" :props="props">
                {{ props.row.name }}
              </q-td>
              <q-td key="department" :props="props">
                {{ props.row.department }}
              </q-td>
            </q-tr>
          </template>
        </q-table> 
      </div>
    </div> -->
    <h1>This page was moved to front office.</h1>
  </div>
</template>

<script>
export default {
  name: 'OnzeStats',
  components: {
    /*     OnzeMixed,
    OnzeMixedSecondData,
    OnzeNewSelectedMixed, */
  },
  setup() {
    /*  onMounted(() => {
      statsService.getOnzeConsultants();
      statsService.getDepartments();
      statsService.getOBData();
    });

    const consultantsColumns = [
      {
        name: 'name',
        field: 'name',
        label: 'Name',
        align: 'left',
        sortable: true,
      },
      {
        name: 'department',
        field: 'department',
        label: 'Department',
        align: 'left',
        sortable: true,
      },
    ];

    const consultants = computed({
      get: () => store.state.onzeStats.consultantsFilter,
    });

    const selectedConsultantId = computed({
      get: () => store.state.onzeStats.selectedConsultantIds,
    });
    const selectedConsultant = computed({
      get: () => store.state.onzeStats.selectedConsultants,
      set: (val) => {
        store.commit('onzeStats/updateSelectedConsultant', val);
      },
    });

    const departments = computed({
      get: () => store.state.onzeStats.departments,
    });

    const selectedTeam = computed({
      get: () => store.state.onzeStats.selectedTeam,
      set: (val) => {
        store.commit('onzeStats/updateSelectedTeam', val);
        selectedTeam.value != null
          ? statsService.getOnzeConsultants(selectedTeam.value)
          : statsService.getOnzeConsultants('');
      },
    });

    const route = useRoute();

    const dataFilters = async () => {
      statsService.getOBData(selectedTeam);
    };

    const numberOfBounces = computed({
      get: () => store.state.onzeStats.numberOfBounces,
    });

    const numberOfUniqueMessagesSent = computed({
      get: () => store.state.onzeStats.numberOfUniqueMessagesSent,
    });

    const numberOfContactsOBHasOpened = computed({
      get: () => store.state.onzeStats.numberOfContactsOBHasOpened,
    });

    const numberOfContactsWhoClicked = computed({
      get: () => store.state.onzeStats.numberOfContactsWhoClicked,
    });

    const activeConsultants = computed({
      get: () => store.state.onzeStats.onzeActiveConsultantsList,
    });

    const inactiveConsultants = computed({
      get: () => store.state.onzeStats.onzeInactiveConsultantsList,
    }); */

    return {
      /*  inactiveConsultants,
      pagination: {
        rowsPerPage: 10,
        page: 1,
      },
      activeConsultants,
      numberOfContactsOBHasOpened,
      numberOfContactsWhoClicked,
      numberOfUniqueMessagesSent,
      numberOfBounces,
      consultantsColumns,
      route,
      consultants,
      selectedConsultant,
      selectedConsultantId,
      selectedTeam,
      departments,
      dataFilters,
      isUnderlined(buttonRoute) {
        if (this.route.fullPath.includes(buttonRoute))
          return 'btn-nav-underlined';
        return '';
      },
    }; */
    };
  },
};
</script>
