const state = () => ({
  //show state
  showAdvancedFilters: false,
  //sme state
  sme: false,
  notSme: false,
  bothSme: false,
  smeString: '',
  //applicationTerm state
  before: false,
  after: false,
  beforeAndAfter: false,
  termOptions: [{}],
  termsString: '',
  //scope state
  scopesString: '',
  regional: false,
  national: false,
  local: false,
  european: false,
  publicationStatusesEnums: []
});

const getters = {};

const actions = {};

const mutations = {

  //show/hide mutations
  enableAdvanced(state) {
    state.showAdvancedFilters = true;
  },
  disableAdvanced(state) {
    state.showAdvancedFilters = false;
  },

  //sme mutations
  updateSme(state, val) {
    state.sme = val;
  },
  updateNotSme(state, val) {
    state.notSme = val;
  },
  updateBothSme(state, val) {
    state.bothSme = val;
  },
  createSMEString(state) {
    state.smeString = '';
    if(state.sme) state.smeString += '10,';
    if(state.notSme) state.smeString += '20,';
    if(state.bothSme) state.smeString += '30';
    if(state.smeString[state.smeString.length - 1] == ',')
      state.smeString = state.smeString.substring(
        0,
        state.smeString.length - 1 
      );
  },

  //scope mutations
  updateNational(state, val) {
    state.national = val;
  },
  updateRegional(state, val) {
    state.regional = val;
  },
  updateEuropean(state, val) {
    state.european = val;
  },
  updateLocal(state, val) {
    state.local = val;
  },
  createScopesString(state) {
    state.scopesString = '';
    if (state.european) state.scopesString += '10,';
    if (state.national) state.scopesString += '20,';
    if (state.regional) state.scopesString += '30,';
    if (state.local) state.scopesString += '40';
    if (state.scopesString[state.scopesString.length - 1] == ',')
      state.scopesString = state.scopesString.substring(
        0,
        state.scopesString.length - 1
      );
  },

  //terms mutations
  updateTerms(state, val) {
    state.termOptions = val;
  },
  updateTermBefore(state, val){
    state.before = val;
  },
  updateTermAfter(state, val){
    state.after = val;
  },
  updateTermBoth(state, val){
    state.beforeAndAfter = val;
  },
 /* createTermsString(state) {
    state.termsString = '';
    if (state.before) state.termsString += '10,';
    if (state.after) state.termsString += '20,';
    if (state.beforeAndAfter) state.termsString += '30';
    if (state.termsString[state.termsString.length - 1] == ',')
      state.termsString = state.termsString.substring(
        0,
        state.termsString.length - 1
      );
  },*/  
  updatePublicationStatusesEnums(state, val) {
    state.publicationStatusesEnums = val;
},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
