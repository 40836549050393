<template>
  <div
    :class="isContrast != 'false' && isContrast != false ? 'high-contrast' : ''"
  >
    <div class="homepage-main-container homepage-main">
      <span>{{ statusText }}</span>
    </div>
  </div>
</template>

<script>
import axiosUrl from '../services/axios.service';
import axios from 'axios';
import { onMounted, computed, ref } from 'vue';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
import router from '../router/index';
import AuthService from '../services/auth.service';

const API_URL = axiosUrl.getAxiosUrl();

export default {
  name: 'SalesForceCallback',
  setup() {
    onMounted(async () => {
      var urlParams = new URLSearchParams(window.location.search);
      return await axios
        .get(
          `${API_URL}SalesForce/HandleCallBack?code=${urlParams.get('code')}`,
          {
            headers: authHeader(),
          }
        )
        .then(() => {
          location.href = '/salesforce';
        })
        .catch((error) => {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            // auto logout if 400 or 401 or 403 response returned from api
            AuthService.logout();
            router.go();
          }
        });
    });

    const statusText = ref('Handeling callback');

    const isContrast = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });
    return {
      isContrast,
      statusText,
    };
  },
};
</script>
