import store from "../index";

const state = () => ({
  chartData: {},
  schemeVisitsData: [],
  siteVisitsData: [],
  newsVisitsData: [],
  selectedYear: 2023,
  selectedMonths: [],
  selectedMonthsIds: [],

  chartDataLabels: [],
  pieChartData: {
    labels: [],
    datasets: [
      {
        backgroundColor: [],
        data: [],
      },
    ],
  },
  inactiveUsersChartData: {},
  inactiveUsersData: [],
  pieChartLabels: [],
  pieChartColors: ["#41B883", "#41B883", "#E46651", "#00D8FF", "#DD1B16"],
  selectedCustomerOwner: null,
  selectedCustomerOwnerId: null,
  userStatsGeneral: {},
  activeUsersList: [],
  activeDemoList: [],
  noProfileAccountsList: [],
  noNewsLetterAccountsList: [],
  activeCustomersList: [],
  noVisitUsersList: [],
  customersInDangerList: [],
  showModal: false,
  modalData: [],
  customerType: null,
  isCustomerModalData: false,
  activePortalCustomersCount: 0,
  newPortalUsers: 0,
  portalUsersCount: 0,
  modalDataType: "",
  newPortalUsersList: [],
  selectedMonthsIdsServer: [],
  yearFrom: 2023,
  yearTo: 2023,
  monthFrom: 0,
  monthTo: 0,
  monthFromId: 0,
  monthToId: 0,
  optionsYearsFrom: [],
  optionsYearsTo: [],
  showModalInactiveUsers: false,
  noVisits: [],
  olderThan90Count: [],
  from60To90Count: [],
  from30To60Count: [],
  last30DaysCount: [],
});

const getters = {};

const actions = {};
import moment from "moment";

const mutations = {
  updateShowModalInactiveUsers(state, val) {
    state.showModalInactiveUsers = val;
  },

  updateYearsOptions(state) {
    state.optionsYearsFrom = [];
    state.optionsYearsTo = [];
    var minYear = 2017;
    var current = moment().year();
    state.yearFrom = current;
    state.yearTo = current;
    while (current >= minYear) {
      state.optionsYearsFrom.push(current);
      state.optionsYearsTo.push(current);
      current--;
    }
  },
  updateYearsToOptions(state) {
    state.yearFrom == moment().year()
      ? (state.optionsYearsTo = [state.yearFrom])
      : (state.optionsYearsTo = [state.yearFrom, state.yearFrom + 1]);
  },
  updateYearFrom(state, val) {
    state.yearFrom = val;
  },
  updateYearTo(state, val) {
    state.yearTo = val;
  },
  updateMonthFrom(state, val) {
    state.monthFrom = val.name;
    state.monthFromId = val.id + 1;
  },
  updateMonthTo(state, val) {
    state.monthTo = val.name;
    state.monthToId = val.id + 1;
  },
  updateGeneralPortalStats(state, val) {
    state.activePortalCustomersCount = val.activePortalCustomersCount;
    state.newPortalUsers = val.newPortalUsers;
    state.portalUsersCount = val.portalUsersCount;
    state.newPoralUsersList = val.usersList;
  },
  resetFilters(state) {
    state.selectedCustomerOwner = null;
    state.selectedCustomerOwnerId = null;
    state.customerType = null;
  },
  updateCustomerType(state, val) {
    state.customerType = val;
  },

  updateModalData(state, val) {
    state.modalData = [];
    state.isCustomerModalData = false;
    for (let i = 0; i < val.length; i++) state.modalData.push(val[i]);
  },
  updateModalDataType(state, val) {
    state.modalDataType = val;
  },
  updateModalDataCustomers(state, val) {
    state.modalData = [];
    state.isCustomerModalData = true;
    for (let i = 0; i < val.length; i++) state.modalData.push(val[i]);
  },
  updateShowModal(state, val) {
    state.showModal = val;
  },
  updateUserStatsGeneral(state, val) {
    state.userStatsGeneral = {
      activeCustomersCount: val.activeCustomersCount,
      demoAccountsActive: val.demoAccountsActive,
      totalActiveUsersCount: val.totalActiveUsersCount,
      usersWithoutNewsletterCount: val.usersWithoutNewsletterCount,
      usersWithoutVisitInLastMonthCount: val.usersWithoutVisitInLastMonthCount,
      usersWithoutProfileCount: val.usersWithoutProfileCount,
      averageActiveUsers: val.averageActiveUsersPerAccount,
      usersWithoutVisit: val.usersWithoutVisitInLastMonthCount,
      customersInDangerCount: val.customersInDangerCount,
    };

    state.activeUsersList = [];
    state.activeDemoList = [];
    state.noProfileAccountsList = [];
    state.noNewsLetterAccountsList = [];
    state.noVisitUsersList = [];
    state.modalData = [];
    state.customersInDangerList = [];

    if (
      val.noVisitLastMonthUsersList &&
      val.noVisitLastMonthUsersList.length > 0
    )
      for (const el of val.noVisitLastMonthUsersList) {
        state.noVisitUsersList.push(el);
      }

    if (val.activeUsersList && val.activeUsersList.length > 0)
      for (const el of val.activeUsersList) {
        state.activeUsersList.push(el);
      }

    if (val.activeDemoList && val.activeDemoList.length > 0)
      for (const el of val.activeDemoList) {
        state.activeDemoList.push(el);
      }

    if (val.noProfileAccountsList && val.noProfileAccountsList.length > 0)
      for (const el of val.noProfileAccountsList) {
        state.noProfileAccountsList.push(el);
      }
    if (val.noNewsLetterAccountsList && val.noNewsLetterAccountsList.length > 0)
      for (const el of val.noNewsLetterAccountsList) {
        state.noNewsLetterAccountsList.push(el);
      }
    if (val.activeCustomersList && val.activeCustomersList.length > 0)
      for (const el of val.activeCustomersList) {
        state.activeCustomersList.push(el);
      }
    if (val.customersInDangerList && val.customersInDangerList.length > 0) {
      for (const el of val.customersInDangerList) {
        state.customersInDangerList.push(el);
      }
    }
  },

  updateSelectedCustomerOwner(state, val) {
    if (val != null) {
      state.selectedCustomerOwner = val;
      state.selectedCustomerOwnerId = val.id;
    } else {
      state.selectedCustomerOwner = null;
      state.selectedCustomerOwnerId = null;
    }
  },

  updateFullPieChartData(state, val) {
    state.pieChartData = {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: [],
        },
      ],
    };

    for (let i = 0; i < val.length; i++) {
      if (val[i].label != "Undefined" && val[i].label.length > 0) {
        state.pieChartData.labels.push(val[i].label);
        state.pieChartData.datasets[0].backgroundColor.push(
          "#" + Math.floor(Math.random() * 16777215).toString(16)
        );
        state.pieChartData.datasets[0].data.push(val[i].count);
      }
    }
  },

  updateSelectedYear(state, val) {
    state.selectedYear = val;
  },

  updateSelectedMonths(state, val) {
    state.selectedMonths = [];
    state.chartDataLabels = [];
    state.selectedMonthsIds = [];
    state.selectedMonthsIdsServer = [];
    for (let i = 0; i < val.length; i++) {
      state.selectedMonths.push(val[i]);
      state.selectedMonthsIds.push(val[i].id);
      state.selectedMonthsIdsServer.push(val[i].id + 1);
    }
  },

  updateChartData(state, data) {
    state.schemeVisitsData = [];
    state.newsVisitsData = [];
    state.siteVisitsData = [];
    store.commit("generalStats/updateDataSets", data);
    state.chartDataLabels = [];

    for (let i = 0; i < data.siteVisits.length; i++) {
      state.chartDataLabels.push(
        data.siteVisits[i].monthName + " " + data.siteVisits[i].year
      );
    }

    state.chartData = {
      labels: state.chartDataLabels,

      datasets: [
        {
          label: "Visits",
          backgroundColor: "#FF5B33",
          data: state.siteVisitsData,
          color: "#FF5B33",
          borderColor: "#FF5B33",
        },
        {
          label: "Scheme views",
          backgroundColor: "#0a108f",
          data: state.schemeVisitsData,
          borderColor: "#0a108f",
          color: "#0a108f",
        },
        {
          label: "News views",
          backgroundColor: "#1299d7",
          data: state.newsVisitsData,
          borderColor: "#1299d7",
          color: "#1299d7",
        },
      ],
    };
  },

  updateDataSets(state, data) {
    for (let i = 0; i < data.schemeVisits.length; i++) {
      state.schemeVisitsData.push(data.schemeVisits[i].count);
    }
    for (let i = 0; i < data.newsVisits.length; i++) {
      state.newsVisitsData.push(data.newsVisits[i].count);
    }
    for (let i = 0; i < data.siteVisits.length; i++) {
      state.siteVisitsData.push(data.siteVisits[i].count);
    }
  },

  updateChartDataInactiveUsers(state, data) {
    state.noVisits = [];
    state.olderThan90Count = [];
    state.from60To90Count = [];
    state.from30To60Count = [];
    state.last30DaysCount = [];
    state.chartDataLabels = [];
    for (let i = 0; i < data.inactiveUsers.length; i++) {
      state.noVisits.push(data.inactiveUsers[i].noVisitCount);
      state.olderThan90Count.push(data.inactiveUsers[i].olderThan90Count);
      state.from60To90Count.push(data.inactiveUsers[i].from60To90Count);
      state.from30To60Count.push(data.inactiveUsers[i].from30To60Count);
      state.last30DaysCount.push(data.inactiveUsers[i].last30DaysCount);
    }

    state.chartDataLabels = [];

    for (let i = 0; i < data.inactiveUsers.length; i++) {
      state.chartDataLabels.push(
        data.inactiveUsers[i].monthName + " " + data.inactiveUsers[i].year
      );
    }

    state.inactiveUsersChartData = {
      labels: state.chartDataLabels,

      datasets: [
        {
          label: "No visits",
          backgroundColor: "#FF5B33",
          data: state.noVisits,
          color: "#FF5B33",
          borderColor: "#FF5B33",
        },
        {
          label: "Last visit older than 90 days",
          backgroundColor: "#854B3E",
          data: state.olderThan90Count,
          color: "#854B3E",
          borderColor: "#854B3E",
        },

        {
          label: "Last visit between 60 and 90 days ago",
          backgroundColor: "#9C2589",
          data: state.from60To90Count,
          color: "#9C2589",
          borderColor: "#9C2589",
        },
        {
          label: "Last visit between 30 and 60 days ago",
          backgroundColor: "#0a108f",
          data: state.from30To60Count,
          color: "#0a108f",
          borderColor: "#0a108f",
        },
        {
          label: "Visited in last 30 days",
          backgroundColor: "#17B4E3",
          data: state.last30DaysCount,
          color: "#17B4E3",
          borderColor: "#17B4E3",
        },
      ],
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
