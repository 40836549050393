<template>
  <q-dialog class="export-modal modal-general" v-model="show" persistent>
    <q-card class="export-modal-card">
      <div class="row justify-end close-btn-area">
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
          @click="disableModal()"
          class="row justify-end close-btn"
        />
      </div>

      <div class="stats-filters">
        <div class="filter-box">
          <q-select
            class="filter-select q-mt-md"
            borderless
            bg-color="white"
            v-model="modelYear"
            :options="optionsYears"
            label="Year"
          />
        </div>
        <div class="filter-box">
          <q-select
            class="filter-select q-mt-md"
            multiple
            borderless
            bg-color="white"
            v-model="modelMonths"
            :options="optionsMonths"
            label="Months"
            option-value="id"
            option-label="name"
          >
          </q-select>
        </div>
        <div class="filter-box">
          <q-select
            class="filter-select q-mt-md"
            borderless
            bg-color="white"
            v-model="modelType"
            :options="optionsTypes"
            label="Type of data to export"
          >
          </q-select>
        </div>
        <div class="export-btn-container">
          <q-btn
            flat
            style="background: #25408f; color: white"
            class="q-mt-md orange-btn-filled"
            @click="exportData()"
            :loading="isLoadingExport"
            no-caps
            >Export data
          </q-btn>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed } from 'vue';
import store from '../../../store/index';
import functionalitiesUseService from '../../../services/functionalitiesUse.service';
export default {
  setup() {
    const show = computed({
      get: () => store.state.consumptionStats.showExportModal,
    });

    const disableModal = () => {
      store.commit('consumptionStats/updateShowExportModal', false);
    };

    const optionsYears = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017];
    const modelYear = computed({
      get: () => store.state.consumptionStats.selectedYearExport,
      set: (val) =>
        store.commit('consumptionStats/updateSelectedYearExport', val),
    });
    const modelMonths = computed({
      get: () => store.state.consumptionStats.selectedMonthsExport,
      set: (val) =>
        store.commit('consumptionStats/updateSelectedMonthsExport', val),
    });

    const modelType = computed({
      get: () => store.state.consumptionStats.selectedDataExport,
      set: (val) =>
        store.commit('consumptionStats/updateSelectedDataExport', val),
    });

    const optionsTypes = [
      'Shared items data',
      'Functionalities used',
      'Schemes and news visits',
      'Keyword searches',
    ];

    const exportData = async () => {
      store.commit('consumptionStats/updateIsLoadingExport', true);
      if (modelType.value == 'Shared items data')
        await functionalitiesUseService
          .exportSharedItemsData(modelYear, selectedMonthsIds)
          .then((response) => {
            var fileURL = window.URL.createObjectURL(
              new Blob([response.data], { type: response.data.type })
            );
            var a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = 'SharedItems.xlsx';
            a.href = fileURL;
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            store.commit('consumptionStats/updateIsLoadingExport', false);
            disableModal();
          });
      else if (modelType.value == 'Functionalities used') {
        await functionalitiesUseService
          .exportFunctionalitiesUsedData(modelYear, selectedMonthsIds)
          .then((response) => {
            var fileURL = window.URL.createObjectURL(
              new Blob([response.data], { type: response.data.type })
            );
            var a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = 'FunctionalitiesData.xlsx';
            a.href = fileURL;
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            store.commit('consumptionStats/updateIsLoadingExport', false);
            disableModal();
          });
      } else if (modelType.value == 'Keyword searches') {
        await functionalitiesUseService
          .exportKeywordSearches(modelYear, selectedMonthsIds)
          .then((response) => {
            var fileURL = window.URL.createObjectURL(
              new Blob([response.data], { type: response.data.type })
            );
            var a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = 'KeywordSearches.xlsx';
            a.href = fileURL;
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            store.commit('consumptionStats/updateIsLoadingExport', false);
            disableModal();
          });
      } else {
        await functionalitiesUseService
          .exportSchemesNewsVisits(modelYear, selectedMonthsIds)
          .then((response) => {
            var fileURL = window.URL.createObjectURL(
              new Blob([response.data], { type: response.data.type })
            );
            var a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = 'SchemesNewsVisits.xlsx';
            a.href = fileURL;
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            store.commit('consumptionStats/updateIsLoadingExport', false);
            disableModal();
          });
      }
    };

    const selectedMonthsIds = computed({
      get: () => store.state.consumptionStats.selectedMonthsExportIds,
    });

    const isLoadingExport = computed({
      get: () => store.state.consumptionStats.isLoadingExport,
    });

    return {
      isLoadingExport,
      selectedMonthsIds,
      exportData,
      optionsTypes,
      modelType,
      modelYear,
      modelMonths,
      optionsYears,
      disableModal,
      show,
      optionsMonths: [
        { id: 1, name: 'Januari' },
        { id: 2, name: 'Februari' },
        { id: 3, name: 'Maart' },
        { id: 4, name: 'April' },
        { id: 5, name: 'Mei' },
        { id: 6, name: 'Juni' },
        { id: 7, name: 'Juli' },
        { id: 8, name: 'Augustus' },
        { id: 9, name: 'September' },
        { id: 10, name: 'Oktober' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' },
      ],
    };
  },
};
</script>
