<template>
  <div class="add-box q-mr-xl column">
    <div class="row justify-around">
      <span class="main-label">Name: </span>
      <q-input v-model="tagName" placeholder="Enter name" dense />
    </div>
    <div class="row justify-around">
      <span class="main-label">Order: </span>
      <q-input
        v-model.number="tagOrder"
        type="number"
        min="1"
        placeholder="Enter order"
        dense
      />
    </div>
    <div class="row q-mt-md">
        <span class="main-label edit-hide-checkbox">Hide node: </span>
        <q-checkbox v-model="isHiddenTag" color="indigo" dense />
    </div>
    <div class="row save-buttons">
      <q-btn
        class="orange-btn-filled bold-font"
        no-caps
        label="Save"
        @click="updateTag()"
      />
      <q-btn
        no-caps
        label="Cancel"
        class="orange-btn bold-font q-ml-lg"
        @click="disableEdit()"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import store from '../../store/index.js';

import TagsServices from '../../services/tags.service';

export default {
  props: {
    tagType: Number,
  },
  setup(props) {
    const currentTagType = ref(props.tagType);

    const languageCode = computed({
      get: () => store.state.tree.languageVersion,
    });

    const node = computed({
      get: () => store.state.tree.selectedNode,
    });

    const disableEdit = async () => {
      store.commit('tree/disableEdit');
      await TagsServices.getTagsTree(currentTagType, languageCode.value);
    };

    const tagName = computed({
      get: () => store.state.tree.tagName,
      set: (value) => store.commit('tree/updateTagName', value),
    });

    const tagOrder = computed({
      get: () => store.state.tree.tagOrder,
      set: (value) => store.commit('tree/updateTagOrder', value),
    });
    const isHiddenTag = computed({
      get: () => store.state.tree.isHiddenTag,
      set: (value) => store.commit('tree/updateIsHiddenTag', value),
    });
    const updateTag = async () => {
      await TagsServices.updateTag(node, tagName, tagOrder, isHiddenTag, languageCode)
        .then(async () => {
          await disableEdit();
        });
    };

    return {
      isHiddenTag,
      node,
      text: ref(node),
      tagName,
      tagOrder,
      updateTag,
      disableEdit,
      newNodeLabel: ref(''),
      currentTagType,
      languageCode
    };
  },
};
</script>
