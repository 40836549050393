<template>
  <div>
    <q-card>
      <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-tab name="general" label="General info" />
        <q-tab name="roles" label="Roles" />
        <q-tab name="tags" label="Tags" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="general">
          <div class="q-pa-md">
            <q-form @submit.stop.prevent="updateUser" class="q-gutter-md">
              <div class="row">
                <div class="col-md-6 q-pa-md">
                  <q-input
                    filled
                    v-model="username"
                    label="Username *"
                    lazy-rules
                    :rules="requiredFieldRule"
                    disable
                  />
                </div>
                <div class="col-md-6 q-pa-md">
                  <q-input
                    filled
                    v-model="email"
                    label="Email *"
                    lazy-rules
                    :rules="[isValidEmail, requiredFieldRule]"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 q-pa-md">
                  <q-input
                    filled
                    v-model="firstName"
                    label="First Name *"
                    lazy-rules
                    :rules="requiredFieldRule"
                  />
                </div>
                <div class="col-md-6 q-pa-md">
                  <q-input
                    filled
                    v-model="lastName"
                    label="Last Name *"
                    lazy-rules
                    :rules="requiredFieldRule"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 q-pa-md">
                  <q-input
                    filled
                    v-model="department"
                    label="Department *"
                    lazy-rules
                  />
                </div>
                <div class="col-md-6 q-pa-md">
                  <q-input
                    filled
                    v-model="mobileNumber"
                    label="Mobile number"
                    lazy-rules
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 q-pa-md">
                  <q-select
                    filled
                    v-model="salutation"
                    use-input
                    input-debounce="0"
                    label="Select salutation"
                    :options="salutationOptions"
                    style="width: 250px"
                    behavior="menu"
                    :rules="requiredFieldRuleSalutation"
                    @filter="salutationFilterFn"
                    dense
                    option-label="description"
                    option-value="id"
                    map-options
                    emit-value
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No results
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div class="col-md-3 q-pa-md">
                  <q-select
                    id="customersDropdownId"
                    filled
                    v-model="customerId"
                    option-label="name"
                    option-value="value"
                    emit-value
                    map-options
                    use-input
                    input-debounce="0"
                    label="Select customer"
                    :options="customersDropdownOptions"
                    @filter="customerDropdownFilterFn"
                    style="width: 250px"
                    behavior="menu"
                    dense
                    :rules="haveToSelectRule"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No results
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6 q-pa-md" style="max-width: 300px">
                      <q-input
                        filled
                        v-model="startDate"
                        label="Start date"
                        :rules="requiredFieldRule"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateProxy"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="startDate"
                                mask="DD-MM-YYYY"
                                :options="startDateOptionsFn"
                              >
                                <div class="row items-center justify-end">
                                  <q-btn
                                    v-close-popup
                                    label="Close"
                                    color="primary"
                                    flat
                                  />
                                </div>
                              </q-date>
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                      </q-input>
                    </div>
                    <div class="col-md-6 q-pa-md" style="max-width: 300px">
                      <q-input filled v-model="endDate" label="End date">
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateProxy"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="endDate"
                                :options="optionsFn"
                                mask="DD-MM-YYYY"
                              >
                                <div class="row items-center justify-end">
                                  <q-btn
                                    v-close-popup
                                    label="Close"
                                    color="primary"
                                    flat
                                  />
                                </div>
                              </q-date>
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                      </q-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 q-pa-md">
                  <q-select
                    filled
                    v-model="newsletterFrequency"
                    use-input
                    input-debounce="0"
                    label="Select newsletter frequency"
                    :options="newsletterFrequencyOptions"
                    style="width: 250px"
                    behavior="menu"
                    @filter="newsletterFrequencyFilterFn"
                    dense
                    :rules="requiredFieldRule"
                    stack-label
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No results
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>
                <!-- <div class="col-md-3 q-pa-md">
                  <q-select v-if="isVindCustomer" filled v-model="language" label="Select language"
                    :options="languageOptions" style="width: 250px" behavior="menu" dense stack-label>
                  </q-select>
                </div> -->
              </div>

              <q-toggle
                class="q-ml-md"
                v-model="isReceivingSystemMails"
                label="Receiving e-mails"
                color="indigo-10"
              >
                <q-tooltip>
                  These are the in-activity and onboarding emails.
                </q-tooltip>
              </q-toggle>

              <q-toggle
                class="q-ml-md"
                v-model="hasSasTaskEmails"
                label="Receive SAS task emails"
                color="indigo-10"
              >
              </q-toggle>

              <q-checkbox
                v-show="isSuperAdmin"
                class="q-ml-md"
                v-model="isAccountManager"
                label="Accountmanager"
                color="indigo-10"
              >
                <q-tooltip>
                  This user is AccountManager.
                </q-tooltip>
              </q-checkbox>

              <!--         <q-card style="min-width: 350px">
                <q-card-section class="q-pt-none">
                  <div class="row justify-around">
                    <div class="col-md-3 q-pa-md justify-around">
                      <q-input
                        dense
                        v-model="street"
                        label="Input street"
                        :rules="requiredFieldRule"
                      />
                    </div>
                    <div class="col-md-3 q-pa-md">
                      <q-input
                        dense
                        v-model.number="houseNumber"
                        type="number"
                        min="1"
                        label="Input house number"
                        :rules="houseNumberRules"
                      />
                    </div>
                    <div class="col-md-3 q-pa-md">
                      <q-input
                        dense
                        v-model="postCode"
                        label="Input post code"
                        :rules="requiredFieldRule"
                      />
                    </div>
                    <div class="col-md-3 q-pa-md">
                      <q-input
                        dense
                        v-model="city"
                        label="Input city"
                        :rules="requiredFieldRule"
                      />
                    </div>
                  </div>
                  <div class="row justify-around q-pa-md">
                    <q-select
                      filled
                      v-model="country"
                      use-input
                      input-debounce="0"
                      label="Select country"
                      :options="options"
                      @filter="filterFn"
                      style="width: 250px"
                      behavior="menu"
                      dense
                      :rules="requiredFieldRule"
                    >
                      <template v-slot:no-option>
                        <q-item>
                          <q-item-section class="text-grey">
                            No results
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </div>
                </q-card-section>
              </q-card> -->
              <div>
                <q-btn
                  no-caps
                  label="Save"
                  type="submit"
                  class="btn-vind-dark"
                />
                <q-btn
                  label="Cancel"
                  outline
                  class="q-ml-sm btn-cancel-dark"
                  no-caps
                  @click="cancelUpdate"
                />
              </div>
            </q-form>
          </div>
        </q-tab-panel>

        <q-tab-panel name="roles">
          <div class="row justify-around">
            <div class="roles-list-user-edit column">
              <span class="list-heading bold-font">Rights per role:</span>
              <q-list
                class="list-of-roles"
                v-for="role in roles"
                :key="role.id"
              >
                <q-expansion-item
                  dense
                  expand-icon="chevron_right"
                  :label="role.name"
                  class="q-pt-sm"
                >
                  <div
                    class="list-of-permissions q-pl-lg q-pt-sm"
                    v-for="permission in role.permissions"
                    :key="permission.id"
                  >
                    <span class="permission">{{ permission.name }} </span>
                  </div>
                </q-expansion-item>
              </q-list>
            </div>
            <div class="q-pa-md">
              <q-item-label header>User roles:</q-item-label>
              <q-list bordered class="rounded-borders" style="max-width: 350px">
                <q-item v-for="role in dbUser.roles" :key="role">
                  <q-item-section avatar top>
                    <q-avatar color="info" text-color="white">
                      {{ role.name.charAt(0) }}
                    </q-avatar>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label lines="1">{{ role.name }}</q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-btn
                      color="red"
                      icon="delete"
                      flat
                      dense
                      round
                      @click="
                        confirm = true;
                        roleId = role.id;
                      "
                    />
                  </q-item-section>
                </q-item>
              </q-list>

              <q-separator />

              <q-item-label header>Add roles to user:</q-item-label>
              <q-list bordered class="rounded-borders" style="max-width: 350px">
                <q-item v-for="singleRole in otherRoles" :key="singleRole">
                  <q-item-section avatar top>
                    <q-avatar color="info" text-color="white">
                      {{ singleRole.name.charAt(0) }}
                    </q-avatar>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label lines="1">{{ singleRole.name }}</q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-btn
                      color="primary"
                      flat
                      dense
                      round
                      @click="
                        confirmAdd = true;
                        addRoleId = singleRole.id;
                      "
                    >
                      <i class="las la-2x la-plus-circle la-fw"></i>
                    </q-btn>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </div>
        </q-tab-panel>

        <q-tab-panel name="tags">
          <div class="tags">
            <div style="min-width: 250px">
              <div
                class="sidebar-margin item-width"
                style="min-width: 280px; width: 27vw"
              >
                <div class="tree q-mt-lg">
                  <span
                    class="filter-box-heading"
                    v-text="translateTabType(audienceNodes.label)"
                  ></span>
                  <q-input
                    dense
                    outlined
                    v-model="filterAudience"
                    label="Zoeken"
                    class="tree-searchbox"
                  >
                  </q-input>
                  <div class="test">
                    <q-scroll-area
                      :bar-style="barStyle"
                      :thumb-style="thumbStyle"
                      visible
                      class="tree-scroll-area"
                    >
                      <q-tree
                        ref="audienceTree"
                        :filter="filterAudience"
                        :filter-method="myFilterMethod"
                        :nodes="audienceNodes.options"
                        node-key="id"
                        tick-strategy="leaf"
                        v-model:ticked="favoriteAudience"
                        @update:ticked="
                          updateAudiencePairs(tickedAudience);
                          sendAudienceUpdates(tickedAudience);
                        "
                        class="default-tree"
                      />
                    </q-scroll-area>
                  </div>
                </div>
              </div>
            </div>

            <div style="min-width: 250px">
              <div
                class="sidebar-margin item-width"
                style="min-width: 280px; width: 27vw"
              >
                <div class="tree q-mt-lg">
                  <span
                    class="filter-box-heading"
                    v-text="translateTabType(purposesNodes.label)"
                  ></span>
                  <q-input
                    dense
                    outlined
                    v-model="filterPurposes"
                    label="Zoeken"
                    class="tree-searchbox"
                  >
                  </q-input>
                  <div class="test">
                    <q-scroll-area
                      :bar-style="barStyle"
                      :thumb-style="thumbStyle"
                      visible
                      class="tree-scroll-area"
                    >
                      <q-tree
                        ref="purposesTree"
                        :filter="filterPurposes"
                        :filter-method="myFilterMethod"
                        :nodes="purposesNodes.options"
                        node-key="id"
                        tick-strategy="leaf"
                        v-model:ticked="favoritePurposes"
                        v-model:expanded="expandedPurposes"
                        v-model:selected="selectedPurposes"
                        @update:ticked="
                          updatePurposesPairs(tickedPurposes);
                          sendPurposesUpdates(tickedPurposes);
                        "
                        class="default-tree"
                      />
                    </q-scroll-area>
                  </div>
                </div>
              </div>
            </div>

            <div style="min-width: 250px" class="tag-selector q-mt-lg">
              <q-select
                outlined
                bg-color="white"
                v-model="selectedFavoriteLocations"
                multiple
                :options="filterFavoriteLocationsTags"
                option-value="label"
                class="budget-input locations-disable"
                option-label="label"
                use-input
                input-debounce="0"
                behavior="menu"
                use-chips
                @filter="filterLocationsFn"
                dense
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
          </div>
        </q-tab-panel>
      </q-tab-panels>
      <ErrorModal />
    </q-card>
  </div>

  <q-dialog v-model="confirmAdd" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <span class="q-ml-sm">Do you want to add this role to user?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          label="Add"
          no-caps
          class="btn-vind-dark"
          v-close-popup
          @click="addRoleToUser(selectedUserId, addRoleId)"
        />
        <q-btn label="Cancel" no-caps class="btn-cancel-gray" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <span class="q-ml-sm">Do you want to remove this role from user?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          label="Remove"
          class="btn-vind-dark"
          no-caps
          v-close-popup
          @click="removeRole(selectedUserId, roleId)"
        />
        <q-btn class="btn-cancel-gray" no-caps label="Cancel" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="successfullyUpdated" persistent>
    <q-card style="width: 300px">
      <q-card-section class="row items-center">
        <span>User has been updated!</span>
      </q-card-section>
      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat label="OK" @click="disableDialog()" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, onMounted, computed } from 'vue';
import store from '../../store/index';
import ErrorModal from './ErrorModal';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import UserService from '../../services/user.service';
import translateTabType from '../../helpers/translate.js';

export default {
  props: {
    id: Number,
  },
  name: 'UsersEditTabs',
  components: {
    ErrorModal,
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const userId = ref(props.id);
    const addRoleId = ref(null);
    const roleId = ref(null);
    const confirmAdd = ref(false);
    const confirm = ref(false);
    const selectedUserId = parseInt(route.params.id);

    const otherRoles = computed({
      get: () => store.state.user.rolesList,
    });

    const customers = computed({
      get: () => store.state.user.customersList,
    });

    const newsletterFrequencyList = computed({
      get: () => store.state.user.newsletterFrequencyList,
    });

    const customersOptions = ref(customers.value);
    const newsletterFrequencyOptions = ref(newsletterFrequencyList.value);
    const customersDropdownOptions = ref(customersDropdown);
    const salutations = computed({
      get: () => store.state.user.salutationsList,
    });
    const salutationOptions = ref(salutations.value);

    const successfullyUpdated = computed({
      get: () => store.state.user.showSuccessfullyUpdatedDialog,
    });

    const disableDialog = () => {
      store.commit('user/disableSuccessfullyUpdatedDialog');
    };

    onMounted(async () => {
      await UserService.getAllSalutations();
      salutationOptions.value = salutations.value;
      await UserService.getRolesAndPermissions();
      await UserService.getNewsletterFrequencyType(selectedUserId);
      await UserService.getNewsletterFrequencyOptions();
      await UserService.getCustomersDropdown();
      await UserService.getAllNonRelatedRoles(selectedUserId);
      await UserService.getFavoriteAudienceTagsById(userId);
      await UserService.getFavoritePurposesTagsById(userId);
      await UserService.getFavoriteLocationsTagsById(userId);
      await UserService.getDefaultFilters();
      await UserService.getLanguageCodes();
      await UserService.getUser(selectedUserId);
    });

    const isSuperAdmin = computed({
      get: () => store.state.user.isSuperAdmin,
    });
    const username = computed({
      get: () => store.state.user.userObject.username,
    });
    const email = computed({
      get: () => store.state.user.userObject.email,
      set: (value) => store.commit('user/updateEmail', value),
    });
    const firstName = computed({
      get: () => store.state.user.userObject.person.firstName,
      set: (value) => store.commit('user/updateFirstName', value),
    });
    const lastName = computed({
      get: () => store.state.user.userObject.person.lastName,
      set: (value) => store.commit('user/updateLastName', value),
    });
    const endDate = computed({
      get: () => store.state.user.userObject.person.endDate,
      set: (value) => store.commit('user/updateEndDate', value),
    });
    const startDate = computed({
      get: () => store.state.user.userObject.person.startDate,
      set: (value) => store.commit('user/updateStartDate', value),
    });
    const salutation = computed({
      get: () => store.state.user.userObject.person.salutation,
      set: (value) => store.commit('user/updateSalutation', value),
    });

    const department = computed({
      get: () => store.state.user.userObject.person.department,
      set: (value) => store.commit('user/updateDepartment', value),
    });

    const mobileNumber = computed({
      get: () => store.state.user.userObject.person.mobileNumber,
      set: (value) => store.commit('user/updateMobileNumber', value),
    });

    const isReceivingSystemMails = computed({
      get: () => store.state.user.userObject.person.isReceivingSystemMails,
      set: (value) => store.commit('user/updateReceivingSystemMails', value),
    });

    const hasSasTaskEmails = computed({
      get: () => store.state.user.userObject.person.hasSasTaskEmails,
      set: (value) => store.commit('user/updateHasSasTaskEmails', value),
    });

    const isAccountManager = computed({
      get: () => store.state.user.userObject.person.isAccountManager,
      set: (value) => store.commit('user/updateIsAccountManager', value),
    });

    const newsletterFrequency = computed({
      get: () => store.state.user.newsletterFrequencyOfTheSelectedUser,
      set: (value) => store.commit('user/updateNewsletterFrequency', value),
    });

    const customer = computed({
      get: () => store.state.user.userObject.person.customer,
      set: (value) => store.commit('user/updateCustomer', value),
    });
    const customerId = computed({
      get: () => store.state.user.userObject.person.customerId,
      set: (value) => store.commit('user/updateCustomerId', value),
    });

    const dbUser = computed({
      get: () => store.state.user.userObject,
    });

    const isVindCustomer = computed({
      get: () => store.state.auth.custid === 2,
    });

    const language = computed({
      get: () => store.state.user.userObject.person.language,
      set: (value) => store.commit('user/updateLanguage', value),
    });

    const user = {
      id: userId,
      email: email,
      newsletterFrequency: newsletterFrequency,
      person: {
        firstName: firstName,
        lastName: lastName,
        startDate: startDate,
        endDate: endDate,
        salutation: salutation,
        language: language,
        customer: customer,
        customerId: customerId,
        department: department,
        mobileNumber: mobileNumber,
        isReceivingSystemMails: isReceivingSystemMails,
        isAccountManager: isAccountManager,
        hasSasTaskEmails: hasSasTaskEmails,
      },
    };

    const updateUser = async () => {
      await UserService.updateUser(user);
    };

    const addRoleToUser = async (selectedUserId, addRoleId) => {
      await UserService.addRoleToUser(selectedUserId, addRoleId);
    };

    const removeRole = async (selectedUserId, roleId) => {
      await UserService.removeRoleFromUser(selectedUserId, roleId);
    };

    const cancelUpdate = () => {
      router.push({
        name: 'UsersGrid',
      });
    };

    const audienceNodes = computed({
      get: () => store.state.user.audience,
    });

    //This is just hidden for now, don't remove it:
    /*
    const industriesNodes = computed({
      get: () => store.state.advancedSearch.industries,
    });
    */

    const locationsNodes = computed({
      get: () => store.state.user.locations,
    });

    const purposesNodes = computed({
      get: () => store.state.user.purposes,
    });

    const locationsOptions = computed({
      get: () => store.state.user.locationsOptions,
    });

    const favoriteAudience = computed({
      get: () => store.state.user.favoriteAudiencesIdsArray,
    });
    const favoritePurposes = computed({
      get: () => store.state.user.favoritePurposesIdsArray,
    });
    const tickedAudience = ref(favoriteAudience.value);
    const filterAudience = ref('');
    const filterPurposes = ref('');
    const tickedPurposes = ref(favoritePurposes.value);

    const selectedFavoriteLocations = computed({
      get: () => store.state.user.selectedFavoriteLocations,
      set: (value) => {
        store.commit('user/updateSelectedFavoriteLocations', value);
      },
    });

    const customersDropdown = computed({
      get: () => store.state.user.customersDropdownList,
    });

    const roles = computed({
      get: () => store.state.user.allRoles,
    });

    const languageCodes = computed({
      get: () => store.state.user.languageCodesList,
    });
    const languageOptions = ref(languageCodes);

    return {
      translateTabType,
      username,
      hasSasTaskEmails,
      language,
      languageCodes,
      languageOptions,
      isVindCustomer,
      roles,
      selectedFavoriteLocations,
      filterAudience,
      tickedPurposes,
      filterPurposes,
      tickedAudience,
      favoritePurposes,
      department,
      mobileNumber,
      myFilterMethod(node, filter) {
        const filt = filter.toLowerCase();
        return node.label && node.label.toLowerCase().indexOf(filt) > -1;
      },
      favoriteAudience,
      audienceNodes,
      locationsNodes,
      purposesNodes,
      locationsOptions,
      tab: ref('general'),
      moment,
      updateUser,
      cancelUpdate,
      addRoleToUser,
      salutationOptions,
      confirm,
      roleId,
      router,
      otherRoles,
      email,
      firstName,
      lastName,
      salutation,
      isReceivingSystemMails,
      isAccountManager,
      isSuperAdmin,
      salutations,
      startDate,
      endDate,
      dbUser,
      addRoleId,
      confirmAdd,
      selectedUserId,
      removeRole,
      customersOptions,
      customers,
      customer,
      successfullyUpdated,
      disableDialog,
      newsletterFrequencyList,
      newsletterFrequencyOptions,
      newsletterFrequency,
      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#f0f2f5',
        width: '8px',
        opacity: 0.2,
      },
      thumbStyle: {
        right: '4px',
        borderRadius: '7px',
        backgroundColor: '#0a108f',
        width: '4px',
        opacity: 0.75,
      },
      requiredFieldRule: [
        (val) => (val && val.length > 0) || 'Field is required!',
      ],
      optionsFn(date) {
        return (
          date >= moment(startDate.value, 'DD-MM-YYYY').format('YYYY/MM/DD')
        );
      },
      startDateOptionsFn(date) {
        return date <= moment(endDate.value, 'DD-MM-YYYY').format('YYYY/MM/DD');
      },
      isValidEmail(val) {
        const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || 'Invalid email';
      },

      salutationFilterFn(val, update) {
        update(() => {
          if (val === '') {
            salutationOptions.value = salutations.value;
          } else {
            const needle = val.toLowerCase();

            salutationOptions.value = salutations.value.filter(
              (v) => v.description.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },

      filterCustomersFn(val, update) {
        update(() => {
          if (val === '') {
            customersOptions.value = customers.value;
          } else {
            const needle = val.toLowerCase();

            customersOptions.value = customers.value.filter(
              (v) => v.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      requiredFieldRuleSalutation: [
        (val) => {
          (val != null && val.value != null && val && val.value.length > 0) ||
            'Field is required';
        },
      ],
      customerId,
      customersDropdown,
      customersDropdownOptions,
      customerDropdownFilterFn(val, update) {
        if (val === '') {
          update(() => {
            customersDropdownOptions.value = customersDropdown.value;
          });
          return;
        }
        update(() => {
          customersDropdownOptions.value = customersDropdown.value.filter(
            (v) => v.name.toLowerCase().indexOf(val.toLowerCase()) > -1
          );
        });
      },
      haveToSelectRule: [(val) => !!val || 'Please select customer!'],

      newsletterFrequencyFilterFn(val, update) {
        update(() => {
          if (val === '') {
            newsletterFrequencyOptions.value = newsletterFrequencyList.value;
          } else {
            const needle = val.toLowerCase();

            newsletterFrequencyOptions.value = newsletterFrequencyList.value.filter(
              (v) => v.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
    };
  },
};
</script>
