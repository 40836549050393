<template>
  <q-dialog class="add-box column no-wrap" v-model="showEditGroupTag">
    <q-card>
      <q-card-section>
        <span>Edit location group</span>
        <q-tree
          ref="locationsTree"
          class="col-12 col-sm-6"
          :nodes="locationsNodes"
          node-key="id"
          tick-strategy="leaf"
          v-model:ticked="ticked"
          v-model:expanded="expandedLocations"
          @update:ticked="updateLocationsPairs(ticked)"
        />
      </q-card-section>
      <q-card-actions>
        <q-btn
          class="orange-btn-filled bold-font"
          no-caps
          label="Save"
          type="submit"
          @click="editLocationGroup()"
        />
        <q-btn
          class="orange-btn bold-font"
          no-caps
          label="Cancel"
          @click="disableEdit()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import store from "../../store";
import TagsService from "../../services/tags.service";

export default {
  setup(props) {
    onMounted(() => {});

    const expandedLocations = ref(0);
    const locationsTree = ref(null);
    const locationsPairs = ref([]);
    const locationPairsIds = ref([]);
    const showEditGroupTag = computed({
      get: () => store.state.tree.showEditGroupTag,
    });
    const locationsNodes = computed({
      get: () => store.state.tree.treeNodes,
    });

    const treeNodeId = computed({
      get: () => store.state.tree.editSelectedTreeGroupId,
    });
    const tickedKeys = computed({
      get: () => store.state.tree.tickedLocationGroups,
    });

    const ticked = ref(tickedKeys.value);

    const disableEdit = () => {
      store.commit("tree/disableEditGroupTag");
    };

    const editLocationGroup = async () => {
      if (locationPairsIds.value.length > 0) {
        await TagsService.editLocationGroup(
          treeNodeId.value,
          locationPairsIds.value
        );
      }
      disableEdit();
    };

 /* old  
   const findParent = (nodes, childId) => {
      const stack = [...nodes];
      const parentMap = new Map(); // Map to store child-parent relationships

      // Build the parent mapping
      while (stack.length) {
        const node = stack.pop();
        if (node.children) {
          for (const child of node.children) {
            parentMap.set(child.id, node);
            stack.push(child);
          }
        }
      }

      // Find the parent of the given childId
      return parentMap.get(childId) || null;
    };

    const findGrandParent = (nodes, childId) => {
      const stack = [...nodes];

      //Parent-child relationship  mapping
      const parentMap = new Map();

      while (stack.length) {
        const node = stack.pop();
        if (node.children) {
          for (const child of node.children) {
            parentMap.set(child.id, node);
            stack.push(child);
          }
        }
      }

      // Finding parent and grandparent of the given childId
      const parent = parentMap.get(childId);
      if (parent) {
        return parentMap.get(parent.id) || null;
      }
      return null;
    };

    const updateLocationsPairs = (keys) => {
      let uniqueLocationsPairs = new Set();
      let uniqueLocationsPairsIds = new Set();

      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          let node = locationsTree.value.getNodeByKey(keys[i]);
          let parentNode = findParent(locationsNodes.value, node.id);
          let grandParentNode = findGrandParent(locationsNodes.value, node.id);

          if (parentNode) {
            uniqueLocationsPairs.add(parentNode);
            uniqueLocationsPairsIds.add(parentNode.id);
          }

          if (grandParentNode) {
            uniqueLocationsPairs.add(grandParentNode);
            uniqueLocationsPairsIds.add(grandParentNode.id);
          }

          uniqueLocationsPairs.add(node);
          uniqueLocationsPairsIds.add(node.id);
        }
      }

      locationsPairs.value = Array.from(uniqueLocationsPairs);
      locationPairsIds.value = Array.from(uniqueLocationsPairsIds);
    };
 */
 const updateLocationsPairs = (keys) => {
      locationsPairs.value.length = 0;
    if (keys.length === 0) {
        return; // No keys to process
    }
    const checkedKeys = new Set(keys);
    const processNode = (node) => {
        if (!node.children || node.children.length === 0) {
            if (checkedKeys.has(node.id)) {
              locationsPairs.value.push(node);
              locationPairsIds.value.push(node.id);

            }
            return checkedKeys.has(node.id);
        }
        let allChildrenTicked = true;
        for (const child of node.children) {
            const childTicked = processNode(child);
            if (!childTicked) {
                allChildrenTicked = false;
            }
        }
        if (allChildrenTicked) {
          locationsPairs.value.push(node);
          locationPairsIds.value.push(node.id);
            return true;
        } else {
            const index = locationsPairs.value.indexOf(node);
            if (index !== -1) {
              locationsPairs.value.splice(index, 1);
            }
            return false;
        }
    };
  
    locationsNodes.value.forEach(node => {
        processNode(node);
    });
};
    return {
      showEditGroupTag,
      disableEdit,
      locationsNodes,
      expandedLocations,
      editLocationGroup,
      locationPairsIds,
      locationsTree,
      locationsPairs,
      updateLocationsPairs,
      // findParent,
      // findGrandParent,
      treeNodeId,
      tickedKeys,
      ticked,
    };
  },
};
</script>
