<template>
  <q-btn
    label="Add call"
    class="q-mt-md orange-btn bold-font"
    no-caps
    @click="dialog = true"
  ></q-btn>

  <q-spinner
    v-if="areSchemeChangesLoading"
    size="4em"
    color="primary"
  ></q-spinner>
  <q-dialog
    v-else
    v-model="dialog"
    persistent
    :maximized="maximizedToggle"
    transition-show="slide-up"
    transition-hide="slide-down"
    class="add-call-to-parent"
  >
    <q-card class="text-black" style="background: #f1f1f1">
      <q-bar style="background: #25408f">
        <q-space></q-space>

        <q-btn dense flat icon="close" v-close-popup color="white">
          <q-tooltip class="bg-white text-primary">Close</q-tooltip>
        </q-btn>
      </q-bar>

      <q-card-section>
        <span>Add a new call to the parent scheme: </span>
        <div class="text-h6">{{ parentSchemeName }}</div>
      </q-card-section>

      <q-card-section class="q-pa-md">
        <div class="call-create">
          <div class="inputs-box">
            <q-checkbox v-model="call.isCall" label="Is call?" />

            <q-input
              class="q-mt-md"
              borderless
              v-model="call.name"
              bg-color="white"
              label="Call name"
              stack-label
              :rules="requiredFieldRule"
              lazy-rules
            />

            <q-input
              borderless
              v-model="call.identifier"
              bg-color="white"
              label="Call identifier"
              stack-label
            />

            <q-input
              borderless
              v-model="call.budget"
              bg-color="white"
              label="Call budget"
              stack-label
              class="q-mt-md"
            />

            <q-select
              class="q-mt-md"
              borderless
              bg-color="white"
              v-model="call.publicationStatus"
              :options="publicationStatusOptions"
              option-value="name"
              option-label="name"
              stack-label
              label="Publication status"
            />

            <div class="application-periods-row">
              <div class="oneoff-application-period">
                <div class="row">
                  <span class="app-period-heading"
                    >One-off application period</span
                  >
                  <q-checkbox
                    v-model="call.isOneOffDeadline"
                    @click="updateOneOffTrue(call.isOneOffDeadline)"
                  ></q-checkbox>
                </div>

                <q-input
                  class="q-mt-md"
                  borderless
                  v-model="call.callStartDate"
                  label="Start date"
                  stack-label
                  bg-color="white"
                  :disable="!call.isOneOffDeadline"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="call.callStartDate"
                          mask="DD-MM-YYYY"
                          :options="startDateOptionsFn"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>

                <q-input
                  class="q-mt-md"
                  borderless
                  v-model="call.callEndDate"
                  label="End date"
                  bg-color="white"
                  stack-label
                  :rules="requiredFieldRule"
                  :disable="!call.isOneOffDeadline"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="call.callEndDate"
                          mask="DD-MM-YYYY"
                          :options="endDateOptionsFn"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>

              <div class="recurring-periods-row">
                <div class="column">
                  <div class="row">
                    <span class="app-period-heading"
                      >Recurring application period</span
                    >
                    <q-checkbox
                      v-model="call.isRecurringDeadline"
                      @click="updateRecurringTrue(call.isRecurringDeadline)"
                    >
                    </q-checkbox>
                  </div>

                  <div class="yearinput-recurring row q-mt-md">
                    Once per
                    <q-input
                      class="q-pl-sm"
                      style="width: 80px"
                      borderless
                      bg-color="white"
                      type="number"
                      v-model="call.recurringYearsDuration"
                      :disable="!call.isRecurringDeadline"
                      :rules="[requiredFieldRule, recurringRule]"
                    >
                    </q-input>
                    <span class="q-pl-sm">
                      year(s)
                    </span>
                  </div>

                  <div class="yearinput-recurring row q-mt-md">
                    Starting in
                    <q-input
                      class="q-pl-sm"
                      style="width: 100px"
                      borderless
                      bg-color="white"
                      type="number"
                      v-model="call.recurringStartingYear"
                      :disable="!call.isRecurringDeadline"
                      :rules="[requiredFieldRule, recurringRule]"
                    >
                    </q-input>
                  </div>
                </div>
                <div class="column recurring-dates q-mt-lg q-ml-lg">
                  <q-input
                    class="q-mt-md q-pt-md"
                    borderless
                    v-model="call.recurringStartDate"
                    label="Start date"
                    stack-label
                    bg-color="white"
                    :disable="!call.isRecurringDeadline"
                    clearable
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          ref="qDateProxy"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="call.recurringStartDate"
                            mask="DD-MM"
                            :options="startDateOptionsFn"
                          >
                            <div class="row items-center justify-end">
                              <q-btn
                                v-close-popup
                                label="Close"
                                color="primary"
                                flat
                              />
                            </div>
                          </q-date>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>

                  <q-input
                    class="q-mt-md"
                    borderless
                    v-model="call.recurringEndDate"
                    label="End date"
                    stack-label
                    bg-color="white"
                    :disable="!call.isRecurringDeadline"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          ref="qDateProxy"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="call.recurringEndDate"
                            mask="DD-MM"
                            :options="startDateOptionsFn"
                          >
                            <div class="row items-center justify-end">
                              <q-btn
                                v-close-popup
                                label="Close"
                                color="primary"
                                flat
                              />
                            </div>
                          </q-date>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </div>

              <div class="row is-open-row">
                <q-checkbox
                  v-model="call.isAlwaysOpen"
                  @click="updateIsAlwaysOpenTrue(call.isAlwaysOpen)"
                  >Is always open</q-checkbox
                >
              </div>
            </div>

            <q-checkbox
              v-model="call.isEnglishScheme"
              label="Is english call"
            />
          </div>
          <span>
            <p class="section-lables q-mt-md">
              Body:
            </p>
          </span>
          <ckeditor
            :editor="editorRef.help.editor"
            v-model="call.body"
            :config="editorRef.help.editorConfig"
          ></ckeditor>

          <div class="links-docs q-mt-lg">
            <div class="links-list scheme-inputs">
              <q-list bordered>
                <q-item-label class="tags-heading" header>
                  Important links:
                  <q-btn
                    color="primary"
                    :disable="loading"
                    @click="
                      showAddLink = true;
                      showCategories = false;
                    "
                    dense
                    no-caps
                  >
                    <i class="las la-1x la-plus-circle la-fw"></i>
                    <q-tooltip>
                      Add new link
                    </q-tooltip>
                  </q-btn>
                </q-item-label>
                <q-item v-for="link in callImportantLinks" :key="link">
                  <q-item-section avatar>
                    <q-icon name="link" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label class="q-mt-xs link-text">
                      <span class="cursor-pointer">
                        <a :href="link.url" target="_blank">
                          {{ link.text + "   (" + link.url + ")" }}
                        </a>
                      </span>
                    </q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <div class="q-gutter-xs">
                      <q-btn
                        color="red"
                        size="12px"
                        flat
                        dense
                        round
                        icon="delete"
                        @click="removeLink(link)"
                      >
                        <q-tooltip>
                          Remove link
                        </q-tooltip>
                      </q-btn>
                      <q-btn
                        @click="
                          showEditLink = true;
                          selectedLink = link;
                          setValues(link.url, link.text, link.documentType);
                          showCategories = false;
                        "
                        dense
                        size="12px"
                        round
                        flat
                        color="indigo-10"
                        icon="las la-pencil-alt"
                      >
                        <q-tooltip>
                          Edit link
                        </q-tooltip>
                      </q-btn>
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
            <div class="links-list scheme-inputs q-pt-md">
              <q-list bordered separator class="textarea-input">
                <q-item-label class="tags-heading" header>
                  <div class="q-gutter-xs">
                    Documents and other links:
                    <q-btn
                      color="primary"
                      :disable="loading"
                      @click="
                        showAddLink = true;
                        showCategories = true;
                        addLink.documentType = EDocumentType.Overig;
                      "
                      dense
                      no-caps
                    >
                      <i class="las la-1x la-link la-fw"></i>
                      <q-tooltip>
                        Add new link
                      </q-tooltip>
                    </q-btn>
                    <q-btn
                      color="primary"
                      :disable="loading"
                      @click="showAddDocument = true"
                      dense
                      no-caps
                    >
                      <i class="las la-1x la-paperclip la-fw"></i>
                      <q-tooltip>
                        Add new document
                      </q-tooltip>
                    </q-btn>
                  </div>
                </q-item-label>
                <q-item
                  v-ripple
                  v-for="(doc, index) in selectedFiles"
                  :key="doc"
                  class="textarea-input"
                >
                  <q-item-section side style="width:150px;">
                    {{ getDocTypeDescription(doc.documentType) }}
                  </q-item-section>
                  <q-item-section avatar>
                    <i
                      :class="iconBasedOnExtension(getExtension(doc.file.name))"
                      style="color: blue;"
                    ></i>
                  </q-item-section>
                  <q-item-section v-if="doc.name !== ''">{{
                    nameSubstring(doc.name)
                  }}</q-item-section>
                  <q-item-section v-else>{{
                    nameSubstring(doc.file.name)
                  }}</q-item-section>
                  <q-item-section top side>
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      flat
                      dense
                      round
                      icon="delete"
                      color="red"
                      @click="selectedFiles.splice(index, 1)"
                    >
                      <q-tooltip>Remove file</q-tooltip>
                    </q-btn>
                  </q-item-section>
                  <q-item-section top side>
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      flat
                      dense
                      round
                      icon="las la-pencil-alt"
                      color="indigo-10"
                      @click="
                        showEditDocument = true;
                        selectedDocument = doc;
                        setDocumentValues(doc.name, doc.documentType);
                      "
                    >
                      <q-tooltip>Edit document</q-tooltip>
                    </q-btn>
                  </q-item-section>
                  <q-tooltip v-if="doc.name !== ''">{{ doc.name }}</q-tooltip>
                  <q-tooltip v-else>{{ doc.file.name }}</q-tooltip>
                </q-item>
                <q-item
                  v-ripple
                  v-for="link in callOtherLinks"
                  :key="link"
                  class="textarea-input"
                >
                  <q-item-section side style="width:150px;">
                    {{ getDocTypeDescription(link.documentType) }}
                  </q-item-section>
                  <q-item-section avatar>
                    <i class="las la-2x la-link" style="color: blue;"></i>
                  </q-item-section>
                  <q-item-section>
                    <a :href="link.url" target="_blank">
                      {{ link.text + "   (" + link.url + ")" }}
                    </a>
                  </q-item-section>
                  <q-item-section top side>
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      flat
                      dense
                      round
                      icon="delete"
                      color="red"
                      @click="removeLink(link)"
                    >
                      <q-tooltip>Remove link</q-tooltip>
                    </q-btn>
                  </q-item-section>
                  <q-item-section top side>
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      flat
                      dense
                      round
                      icon="las la-pencil-alt"
                      color="indigo-10"
                      @click="
                        showEditLink = true;
                        selectedLink = link;
                        showCategories = true;
                        setValues(link.url, link.text, link.documentType);
                      "
                    >
                      <q-tooltip>Edit link</q-tooltip>
                    </q-btn>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </div>

          <div class="tagsarea-input tags-tabs q-mb-xl q-mt-xl">
            <q-card>
              <q-tabs
                v-model="tab"
                dense
                class="text-grey"
                active-color="primary"
                indicator-color="primary"
                align="justify"
                narrow-indicator
              >
                <q-tab name="audiences" label="Audiences">
                  <q-badge color="#0a1f8f" class="tag-badge" floating>{{
                    call.selectedTags.selectedAudiences.length
                  }}</q-badge>
                </q-tab>
                <q-tab name="purposes" label="Purposes">
                  <q-badge color="#0a1f8f" class="tag-badge" floating>{{
                    call.selectedTags.selectedPurposes.length
                  }}</q-badge>
                </q-tab>
                <q-tab name="locations" label="Locations">
                  <q-badge color="#0a1f8f" class="tag-badge" floating>{{
                    call.selectedTags.selectedLocations.length
                  }}</q-badge>
                </q-tab>
                <q-tab name="schemetype" label="Scheme type">
                  <q-badge color="#0a1f8f" class="tag-badge" floating>{{
                    call.selectedTags.selectedSchemeTypes.length
                  }}</q-badge>
                </q-tab>
                <q-tab name="keywords" label="Keywords">
                  <q-badge color="#0a1f8f" class="tag-badge" floating>{{
                    call.selectedTags.selectedKeywords.length
                  }}</q-badge>
                </q-tab>
                <q-tab name="industriesnew" label="Industries NEW">
                  <q-badge color="#0a1f8f" class="tag-badge" floating>{{
                    call.selectedTags.selectedIndustriesNew.length
                  }}</q-badge>
                </q-tab>
              </q-tabs>

              <q-separator />

              <q-tab-panels v-model="tab" animated>
                <q-tab-panel name="audiences">
                  <div style="min-width: 250px;">
                    <div
                      class="sidebar-margin item-width"
                      style="min-width: 280px; width: 27vw"
                    >
                      <div class="tree q-mt-lg">
                        <div class="row">
                          <span
                            class="filter-box-heading"
                            v-text="audienceNodes.label"
                          ></span>
                          <q-space></q-space>
                          <i
                            class="las la-2x la-times-circle clear-icon on-right"
                            @click="resetSelectedAudiences()"
                          >
                            <q-tooltip>
                              Clear selected tags
                            </q-tooltip>
                          </i>
                          <i
                            class="las la-2x la-check-square clear-icon on-right"
                            @click="selectAllAudiences()"
                          >
                            <q-tooltip>
                              Select all tags
                            </q-tooltip>
                          </i>
                        </div>
                        <q-input
                          dense
                          borderless
                          bg-color="white"
                          v-model="filterAudience"
                          label="Zoeken"
                          class="tree-searchbox"
                        >
                        </q-input>
                        <div class="test">
                          <q-scroll-area
                            :bar-style="barStyle"
                            :thumb-style="thumbStyle"
                            visible
                            class="tree-scroll-area"
                          >
                            <q-tree
                              ref="audienceTree"
                              :filter="filterAudience"
                              :filter-method="myFilterMethod"
                              :nodes="audienceNodes.options"
                              node-key="id"
                              tick-strategy="leaf"
                              v-model:ticked="tickedAudience"
                              @update:ticked="
                                updateAudiencePairs(tickedAudience)
                              "
                              class="default-tree"
                            />
                          </q-scroll-area>
                        </div>
                      </div>
                    </div>
                  </div>
                </q-tab-panel>

                <q-tab-panel name="purposes">
                  <div style="min-width: 250px;">
                    <div
                      class="sidebar-margin item-width"
                      style="min-width: 280px; width: 27vw"
                    >
                      <div class="tree q-mt-lg">
                        <div class="row">
                          <span
                            class="filter-box-heading"
                            v-text="purposesNodes.label"
                          ></span>

                          <q-space></q-space>
                          <i
                            class="las la-2x la-times-circle clear-icon on-right"
                            @click="resetSelectedPurposes()"
                          >
                            <q-tooltip>
                              Clear selected tags
                            </q-tooltip>
                          </i>
                          <i
                            class="las la-2x la-check-square clear-icon on-right"
                            @click="selectAllPurposes()"
                          >
                            <q-tooltip>
                              Select all tags
                            </q-tooltip>
                          </i>
                        </div>
                        <q-input
                          dense
                          borderless
                          bg-color="white"
                          v-model="filterPurposes"
                          label="Zoeken"
                          class="tree-searchbox"
                        >
                        </q-input>
                        <div class="test">
                          <q-scroll-area
                            :bar-style="barStyle"
                            :thumb-style="thumbStyle"
                            visible
                            class="tree-scroll-area"
                          >
                            <q-tree
                              ref="purposesTree"
                              :filter="filterPurposes"
                              :filter-method="myFilterMethod"
                              :nodes="purposesNodes.options"
                              node-key="id"
                              tick-strategy="leaf"
                              v-model:ticked="tickedPurposes"
                              @update:ticked="
                                updatePurposesPairs(tickedPurposes)
                              "
                              class="default-tree"
                            />
                          </q-scroll-area>
                        </div>
                      </div>
                    </div>
                  </div>
                </q-tab-panel>

                <q-tab-panel name="locations">
                  <div style="min-width: 250px;">
                    <div
                      class="sidebar-margin item-width"
                      style="min-width: 280px; width: 27vw"
                    >
                      <div class="tree q-mt-lg">
                        <div class="row">
                          <span
                            class="filter-box-heading"
                            v-text="locationsNodes.label"
                          ></span>
                          <q-space></q-space>
                          <i
                            class="las la-2x la-times-circle clear-icon on-right"
                            @click="resetSelectedLocations()"
                          >
                            <q-tooltip>
                              Clear selected tags
                            </q-tooltip>
                          </i>
                          <i
                            class="las la-2x la-check-square clear-icon on-right"
                            @click="selectAllLocations()"
                          >
                            <q-tooltip>
                              Select all tags
                            </q-tooltip>
                          </i>
                        </div>
                        <q-input
                          dense
                          borderless
                          v-model="filterLocations"
                          label="Zoeken"
                          class="tree-searchbox"
                        >
                        </q-input>
                        <div class="test">
                          <q-scroll-area
                            :bar-style="barStyle"
                            :thumb-style="thumbStyle"
                            visible
                            class="tree-scroll-area"
                          >
                            <q-tree
                              ref="locationsTree"
                              :filter="filterLocations"
                              :filter-method="myFilterMethod"
                              :nodes="locationsNodes.options"
                              node-key="id"
                              tick-strategy="leaf"
                              v-model:ticked="tickedLocations"
                              @update:ticked="
                                updateLocationsPairs(tickedLocations)
                              "
                              class="default-tree"
                            />
                          </q-scroll-area>
                        </div>
                      </div>
                      <div class="row">
                        <p class="q-mt-xl">German location tags</p>
                      </div>
                      <div class="row">
                        <q-select
                          class="q-mr-md"
                          multiple
                          v-model="call.locationTags"
                          :options="locationTagsBulkList"
                          @filter="filterLocationTagsBulk"
                          label="Filter locations"
                          use-chips
                          use-input
                          input-debounce="500"
                          behavior="menu"
                          option-value="id"
                          option-label="label"
                          :disable="call.inheritTags"
                        />
                      </div>
                    </div>
                  </div>
                </q-tab-panel>

                <q-tab-panel name="schemetype">
                  <div style="min-width: 250px;">
                    <div
                      class="sidebar-margin item-width"
                      style="min-width: 280px; width: 27vw"
                    >
                      <div class="tree q-mt-lg">
                        <div class="row">
                          <span
                            class="filter-box-heading"
                            v-text="schemeTypesNodes.label"
                          ></span>
                          <q-space></q-space>
                          <i
                            class="las la-2x la-times-circle clear-icon on-right"
                            @click="resetSelectedSchemeTypes()"
                          >
                            <q-tooltip>
                              Clear selected tags
                            </q-tooltip>
                          </i>
                          <i
                            class="las la-2x la-check-square clear-icon on-right"
                            @click="selectAllSchemeTypes()"
                          >
                            <q-tooltip>
                              Select all tags
                            </q-tooltip>
                          </i>
                        </div>
                        <q-input
                          dense
                          borderless
                          v-model="filterschemeTypes"
                          label="Zoeken"
                          class="tree-searchbox"
                        >
                        </q-input>
                        <div class="test">
                          <q-scroll-area
                            :bar-style="barStyle"
                            :thumb-style="thumbStyle"
                            visible
                            class="tree-scroll-area"
                          >
                            <q-tree
                              ref="schemeTypesTree"
                              :filter="filterSchemeTypes"
                              :filter-method="myFilterMethod"
                              :nodes="schemeTypesNodes.options"
                              node-key="id"
                              tick-strategy="leaf"
                              v-model:ticked="tickedSchemeTypes"
                              @update:ticked="
                                updateSchemeTypesPairs(tickedSchemeTypes)
                              "
                              class="default-tree"
                            />
                          </q-scroll-area>
                        </div>
                      </div>
                    </div>
                  </div>
                </q-tab-panel>

                <q-tab-panel name="keywords">
                  <div style="min-width: 250px" class="tag-selector">
                    <q-select
                      borderless
                      bg-color="white"
                      v-model="call.selectedTags.selectedKeywords"
                      multiple
                      input-debounce="0"
                      :options="filterOptions"
                      option-value="name"
                      class="scheme-inputs"
                      option-label="name"
                      use-input
                      use-chips
                      stack-label
                      label="Select or input keywords"
                      @new-value="createValue"
                      @filter="filterFn"
                    />
                  </div>
                </q-tab-panel>

                <q-tab-panel name="industriesnew">
                  <div style="min-width: 250px;">
                    <div
                      class="sidebar-margin item-width"
                      style="min-width: 280px; width: 27vw"
                    >
                      <div class="tree q-mt-lg">
                        <div class="row">
                          <span
                            class="filter-box-heading"
                            v-text="industriesNewNodes.label"
                          ></span>
                          <q-space></q-space>
                          <i
                            class="las la-2x la-times-circle clear-icon on-right"
                            @click="resetSelectedIndustriesNew()"
                          >
                            <q-tooltip>
                              Clear selected tags
                            </q-tooltip>
                          </i>
                          <i
                            class="las la-2x la-check-square clear-icon on-right"
                            @click="selectAllIndustriesNew()"
                          >
                            <q-tooltip>
                              Select all tags
                            </q-tooltip>
                          </i>
                        </div>
                        <q-input
                          dense
                          borderless
                          v-model="filterIndustriesNew"
                          label="Zoeken"
                          class="tree-searchbox"
                        >
                        </q-input>
                        <div class="test">
                          <q-scroll-area
                            :bar-style="barStyle"
                            :thumb-style="thumbStyle"
                            visible
                            class="tree-scroll-area"
                          >
                            <q-tree
                              ref="industriesNewTree"
                              :filter="filterIndustriesNew"
                              :filter-method="myFilterMethod"
                              :nodes="industriesNewNodes.options"
                              node-key="id"
                              tick-strategy="leaf"
                              v-model:ticked="tickedIndustriesNew"
                              @update:ticked="
                                updateIndustriesNewPairs(tickedIndustriesNew)
                              "
                              class="default-tree"
                            />
                          </q-scroll-area>
                        </div>
                      </div>
                    </div>
                  </div>
                </q-tab-panel>
              </q-tab-panels>
            </q-card>
            <q-list>
              <q-item>
                <q-item-section avatar>
                  <q-checkbox
                    v-model="call.inheritTags"
                    color="primary"
                    @update:model-value="resetAllCustomTags"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label>Inherit tags?</q-item-label>
                  <q-item-label caption
                    >If you select this option, this call will inherit all tags
                    from the parent scheme, and all custom tags you previously
                    selected will be ignored.
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
          <div class="row justify-end">
            <q-btn
              class="q-mt-md q-mb-md bold-font orange-btn-filled"
              label="Save"
              @click="createNewCall()"
              no-caps
            />
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showAddLink" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div>New link</div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none">
        <div class="row justify-around">
          <q-input
            dense
            v-model="addLink.url"
            label="Input url"
            :rules="requiredFieldRule"
            lazy-rules
          />
        </div>
        <div class="row justify-around">
          <q-input
            dense
            v-model="addLink.text"
            label="Input link text"
            :rules="requiredFieldRule"
            lazy-rules
          />
        </div>
        <q-select
          v-if="showCategories"
          borderless
          bg-color="white"
          v-model="addLink.documentType"
          :options="documentTypes"
          option-value="id"
          option-label="description"
          stack-label
          label="Document type"
          dense
          emit-value
          map-options
        />
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          color="primary"
          label="Add"
          @click="addLinkToCall"
          :disable="addLink.url != '' ? null : true"
        />
        <q-btn color="primary" outline label="Cancel" @click="disableAddLink" />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showEditLink" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div>Edit link</div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none">
        <div class="row justify-around">
          <q-input
            dense
            v-model="editLinkUrl"
            label="Input url"
            :rules="requiredFieldRule"
            lazy-rules
          />
        </div>
        <div class="row justify-around">
          <q-input
            dense
            v-model="editLinkText"
            label="Input link text"
            :rules="requiredFieldRule"
            lazy-rules
          />
        </div>
        <div class="justify-around">
          <q-select
            v-if="showCategories"
            borderless
            bg-color="white"
            v-model="editLinkDocumentType"
            :options="documentTypes"
            option-value="id"
            option-label="description"
            stack-label
            label="Document type"
            dense
            emit-value
            map-options
          />
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          color="primary"
          label="Save"
          @click="editLink(editLinkText, editLinkUrl, editLinkDocumentType)"
          :disable="editLinkUrl != '' ? null : true"
        />
        <q-btn
          color="primary"
          outline
          label="Cancel"
          @click="disableEditLink"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showAddDocument" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div>Add document</div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none">
        <q-file
          outlined
          v-model="addFile.file"
          dense
          class="q-pa-md"
          label="Pick one file"
        >
          <template v-slot:prepend>
            <q-icon name="las la-paperclip" />
          </template>
        </q-file>
        <q-input
          dense
          outlined
          v-model="addFile.name"
          label="New name:"
          lazy-rules
          class="q-pa-md"
        />
        <q-select
          outlined
          bg-color="white"
          v-model="addFile.documentType"
          :options="documentTypes"
          option-value="id"
          option-label="description"
          stack-label
          label="Document type"
          dense
          class="q-pa-md"
          emit-value
          map-options
        />
      </q-card-section>
      <q-card-actions align="around">
        <q-btn
          color="primary"
          label="Add"
          @click="addFileToCall"
          :disable="addFile.file == null || addFile.documentType === null"
        />
        <q-btn
          color="primary"
          outline
          label="Cancel"
          @click="disableAddDocument"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showEditDocument" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div>Edit document</div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none">
        <div class="row justify-around">
          <q-input
            dense
            v-model="editDocumentName"
            label="Name"
            stack-label
            :rules="requiredFieldRule"
            lazy-rules
          />
        </div>
        <q-select
          outlined
          bg-color="white"
          v-model="editDocumentDocumentType"
          :options="documentTypes"
          option-value="id"
          option-label="description"
          stack-label
          label="Document type"
          dense
          map-options
          emit-value
        />
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          color="primary"
          label="Save"
          @click="editDocument(editDocumentName, editDocumentDocumentType)"
          :disable="editDocumentName != '' ? null : true"
        />
        <q-btn
          color="primary"
          outline
          label="Cancel"
          @click="disableEditDocument"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<script>
import { ref, reactive, computed, onMounted } from "vue";
import SchemesServices from "../../services/schemes.services";
import { useRoute } from "vue-router";
import moment from "moment";
import store from "../../store";
import TagsService from "../../services/tags.service";
import help from "../../helpers/editor";
import ETagType from "../../enums/ETagType";
import EDocumentType from "../../enums/EDocumentType";
export default {

  props: {
    parentSchemeName: String,
  },
  setup() {
    const route = useRoute();
    const maximizedToggle = ref(true);
    const parentId = route.params.id;
    const dialog = ref(false);
    const showAddLink = ref(false);
    const showEditLink = ref(false);
    const showEditDocument = ref(false);
    const showAddDocument = ref(false);
    let selectedFiles = ref([]);
    const tab = ref("audiences");
    const filterAudience = ref("");
    const filterPurposes = ref("");
    const filterLocations = ref("");
    const filterSchemeTypes = ref("");
    const filterIndustriesNew = ref("");
    const tickedAudience = ref([]);
    const tickedPurposes = ref([]);
    const tickedLocations = ref([]);
    const tickedSchemeTypes = ref([]);
    const tickedIndustriesNew = ref([]);
    const audienceTree = ref(null);
    const purposesTree = ref(null);
    const schemeTypesTree = ref(null);
    const locationsTree = ref(null);
    const industriesNewTree = ref(null);
    const selectedLink = ref(null);
    const editLinkUrl = ref("");
    const editLinkText = ref("");
    const editLinkDocumentType = ref(null);
    const selectedDocument = ref(null);
    const editDocumentName = ref("");
    const editDocumentDocumentType = ref(null);
    const showCategories = ref(false);
    onMounted(async () => {
      await SchemesServices.getPublicationStatuses();
      await TagsService.getTagTypeTree("Audience", ETagType.Audience);
      await TagsService.getTagTypeTree("Purposes", ETagType.Purposes);
      await TagsService.getTagTypeTree("Locations", ETagType.Locations);
      await TagsService.getTagTypeTree("Scheme type", ETagType.SchemeType);
      await TagsService.getTagTypeTree(
        "Industries NEW",
        ETagType.IndustriesNew
      );
    });

    const publicationStatusOptions = computed({
      get: () => store.state.scheme.publicationStatuses,
    });

    const audienceNodes = computed({
      get: () => store.state.scheme.audienceTags,
    });
    const locationsNodes = computed({
      get: () => store.state.scheme.locationsTags,
    });
    const purposesNodes = computed({
      get: () => store.state.scheme.purposesTags,
    });
    const schemeTypesNodes = computed({
      get: () => store.state.scheme.schemeTypesTags,
    });
    const industriesNewNodes = computed({
      get: () => store.state.scheme.industriesNewTags,
    });
    const allKeywordsTags = computed({
      get: () => store.state.tagsNavigation.keywordsTags,
    });
    const documentTypes = computed({
      get: () => store.state.scheme.documentTypes,
    });

    const getDocTypeDescription = (type) => {
      return documentTypes.value.find(({ id }) => id === type).description;
    };

    const filterOptions = ref(allKeywordsTags.value);

    const setValues = (url, text, docType) => {
      editLinkUrl.value = url;
      editLinkText.value = text;
      editLinkDocumentType.value = docType;
    };

    const setDocumentValues = (name, docType) => {
      editDocumentName.value =
        name === "" ? selectedDocument.value.file.name : name;
      editDocumentDocumentType.value = docType;
    };

    const addLink = reactive({
      url: "",
      text: "",
      documentType: null,
    });

    const addFile = reactive({
      file: null,
      name: "",
      documentType: EDocumentType.Overig,
    });

    const editorRef = help;

    const resetAddLink = () => {
      addLink.text = "";
      addLink.url = "";
      addLink.documentType = null;
    };

    const resetAddFile = () => {
      addFile.name = "";
      addFile.file = null;
      addFile.documentType = EDocumentType.Overig;
    };

    const addLinkToCall = async () => {
      if (addLink.text == "") addLink.text = addLink.url;

      call.callLinks.push({
        url: addLink.url,
        text: addLink.text,
        documentType: addLink.documentType,
      });
      resetAddLink();
      showAddLink.value = false;
    };

    const addFileToCall = async () => {
      selectedFiles.value.push({
        file: addFile.file,
        name: addFile.name,
        documentType: addFile.documentType,
      });
      resetAddFile();
      showAddDocument.value = false;
    };

    const disableAddLink = () => {
      resetAddLink();
      showAddLink.value = false;
    };

    const disableEditLink = () => {
      selectedLink.value = null;
      showEditLink.value = false;
    };

    const disableAddDocument = () => {
      resetAddFile();
      showAddDocument.value = false;
    };

    const disableEditDocument = () => {
      selectedDocument.value = null;
      showEditDocument.value = false;
    };

    const editLink = (linkText, linkUrl, linkDocumentType) => {
      if (linkText == "") linkText = linkUrl;

      selectedLink.value.text = linkText;
      selectedLink.value.url = linkUrl;
      selectedLink.value.documentType = linkDocumentType;

      disableEditLink();
    };

    const editDocument = (docName, docDocumentType) => {
      selectedDocument.value.name = docName;
      selectedDocument.value.documentType = docDocumentType;

      disableEditDocument();
    };

    const resetCall = () => {
      call.name = "";
      call.identifier = "";
      call.body = "";
      call.parentId = parentId;
      call.isCall = false;
      call.callStartDate = moment().format("DD-MM-YYYY");
      call.callEndDate = null;
      call.callLinks = [];
      call.locationTags = [];
    };

    const updateAudiencePairs = (keys) => {
      if (!call.inheritTags) {
        call.selectedTags.selectedAudiences = [];
        if (keys.length > 0) {
          for (let i = 0; i < keys.length; i++) {
            let node = audienceTree.value.getNodeByKey(keys[i]);
            call.selectedTags.selectedAudiences.push(node);
          }
          var toAdd = [];

          for (let i = 0; i < audienceNodes.value.options.length; i++) {
            if (
              audienceNodes.value.options[i].children &&
              audienceNodes.value.options[i].children.length > 0
            ) {
              var hasAllChildrenTicked = true;

              for (
                let j = 0;
                j < audienceNodes.value.options[i].children.length;
                j++
              ) {
                // about subchild
                if (
                  audienceNodes.value.options[i].children[j].children &&
                  audienceNodes.value.options[i].children[j].children.length > 0
                ) {
                  var hasAllSubChildrenTicked = true;

                  for (
                    let k = 0;
                    k <
                    audienceNodes.value.options[i].children[j].children.length;
                    k++
                  ) {
                    if (
                      !keys.includes(
                        audienceNodes.value.options[i].children[j].children[k]
                          .id
                      )
                    ) {
                      hasAllSubChildrenTicked = false;
                      break;
                    }
                  }
                  if (
                    hasAllSubChildrenTicked &&
                    !call.selectedTags.selectedAudiences.includes(
                      audienceNodes.value.options[i].children[j]
                    )
                  ) {
                    toAdd.push(audienceNodes.value.options[i].children[j]);
                  }

                  if (
                    !hasAllSubChildrenTicked &&
                    call.selectedTags.selectedAudiences.includes(
                      audienceNodes.value.options[i].children[j]
                    )
                  ) {
                    const index = call.selectedTags.selectedAudiences.indexOf(
                      audienceNodes.value.options[i].children[j]
                    );
                    call.selectedTags.selectedAudiences.splice(index, 1);
                    hasAllChildrenTicked = false;
                  }
                } else {
                  hasAllSubChildrenTicked = false;
                }
                //subchild end

                if (
                  !keys.includes(audienceNodes.value.options[i].children[j].id)
                ) {
                  if (
                    !toAdd.includes(audienceNodes.value.options[i].children[j])
                  ) {
                    hasAllChildrenTicked = false;
                    continue;
                  }
                }
              }

              if (
                hasAllChildrenTicked &&
                !call.selectedTags.selectedAudiences.includes(
                  audienceNodes.value.options[i]
                )
              )
                toAdd.push(audienceNodes.value.options[i]);

              if (
                !hasAllChildrenTicked &&
                call.selectedTags.selectedAudiences.includes(
                  audienceNodes.value.options[i]
                )
              ) {
                const index = call.selectedTags.selectedAudiences.indexOf(
                  audienceNodes.value.options[i]
                );
                call.selectedTags.selectedAudiences.splice(index, 1);
              }
            } else {
              hasAllChildrenTicked = false;
            }
          }

          if (toAdd.length > 0) {
            for (let i = 0; i < toAdd.length; i++)
              call.selectedTags.selectedAudiences.push(toAdd[i]);
          }
        }
      } else {
        tickedAudience.value = [];
      }
    };

    //add parent if all children are selected
    const updatePurposesPairs = (keys) => {
      if (!call.inheritTags) {
        call.selectedTags.selectedPurposes = [];
        if (keys.length > 0) {
          for (let i = 0; i < keys.length; i++) {
            let node = purposesTree.value.getNodeByKey(keys[i]);
            call.selectedTags.selectedPurposes.push(node);
          }
          var toAdd = [];
          for (let i = 0; i < purposesNodes.value.options.length; i++) {
            if (
              purposesNodes.value.options[i].children &&
              purposesNodes.value.options[i].children.length > 0
            ) {
              var hasAllChildrenTicked = true;

              for (
                let j = 0;
                j < purposesNodes.value.options[i].children.length;
                j++
              ) {
                // about subchild
                if (
                  purposesNodes.value.options[i].children[j].children &&
                  purposesNodes.value.options[i].children[j].children.length > 0
                ) {
                  var hasAllSubChildrenTicked = true;

                  for (
                    let k = 0;
                    k <
                    purposesNodes.value.options[i].children[j].children.length;
                    k++
                  ) {
                    if (
                      !keys.includes(
                        purposesNodes.value.options[i].children[j].children[k]
                          .id
                      )
                    ) {
                      hasAllSubChildrenTicked = false;
                      break;
                    }
                  }
                  if (
                    hasAllSubChildrenTicked &&
                    !call.selectedTags.selectedPurposes.includes(
                      purposesNodes.value.options[i].children[j]
                    )
                  )
                    toAdd.push(purposesNodes.value.options[i].children[j]);

                  if (
                    !hasAllSubChildrenTicked &&
                    call.selectedTags.selectedPurposes.includes(
                      purposesNodes.value.options[i].children[j]
                    )
                  ) {
                    const index = call.selectedTags.selectedPurposes.indexOf(
                      purposesNodes.value.options[i].children[j]
                    );
                    call.selectedTags.selectedPurposes.splice(index, 1);
                    hasAllChildrenTicked = false;
                  }
                } else {
                  hasAllSubChildrenTicked = false;
                }
                //subchild end
                if (
                  !keys.includes(purposesNodes.value.options[i].children[j].id)
                ) {
                  if (
                    !toAdd.includes(purposesNodes.value.options[i].children[j])
                  ) {
                    hasAllChildrenTicked = false;
                    continue;
                  }
                }
              }
              if (
                hasAllChildrenTicked &&
                !call.selectedTags.selectedPurposes.includes(
                  purposesNodes.value.options[i]
                )
              )
                toAdd.push(purposesNodes.value.options[i]);

              if (
                !hasAllChildrenTicked &&
                call.selectedTags.selectedPurposes.includes(
                  purposesNodes.value.options[i]
                )
              ) {
                const index = call.selectedTags.selectedPurposes.indexOf(
                  purposesNodes.value.options[i]
                );
                call.selectedTags.selectedPurposes.splice(index, 1);
              }
            } else {
              hasAllChildrenTicked = false;
            }
          }
          if (toAdd.length > 0) {
            for (let i = 0; i < toAdd.length; i++)
              call.selectedTags.selectedPurposes.push(toAdd[i]);
          }
        }
      } else {
        tickedPurposes.value = [];
      }
    };
    //add parent if all children are selected
    const updateLocationsPairs = (keys) => {
      if (!call.inheritTags) {
        call.selectedTags.selectedLocations = [];
        if (keys.length > 0) {
          for (let i = 0; i < keys.length; i++) {
            let node = locationsTree.value.getNodeByKey(keys[i]);
            call.selectedTags.selectedLocations.push(node);
          }
          var toAdd = [];
          for (let i = 0; i < locationsNodes.value.options.length; i++) {
            if (
              locationsNodes.value.options[i].children &&
              locationsNodes.value.options[i].children.length > 0
            ) {
              var hasAllChildrenTicked = true;

              for (
                let j = 0;
                j < locationsNodes.value.options[i].children.length;
                j++
              ) {
                // about subchild
                if (
                  locationsNodes.value.options[i].children[j].children &&
                  locationsNodes.value.options[i].children[j].children.length >
                    0
                ) {
                  var hasAllSubChildrenTicked = true;

                  for (
                    let k = 0;
                    k <
                    locationsNodes.value.options[i].children[j].children.length;
                    k++
                  ) {
                    if (
                      !keys.includes(
                        locationsNodes.value.options[i].children[j].children[k]
                          .id
                      )
                    ) {
                      hasAllSubChildrenTicked = false;
                      break;
                    }
                  }
                  if (
                    hasAllSubChildrenTicked &&
                    !call.selectedTags.selectedLocations.includes(
                      locationsNodes.value.options[i].children[j]
                    )
                  ) {
                    toAdd.push(locationsNodes.value.options[i].children[j]);
                  }

                  if (
                    !hasAllSubChildrenTicked &&
                    call.selectedTags.selectedLocations.includes(
                      locationsNodes.value.options[i].children[j]
                    )
                  ) {
                    const index = call.selectedTags.selectedLocations.indexOf(
                      locationsNodes.value.options[i].children[j]
                    );
                    call.selectedTags.selectedLocations.splice(index, 1);
                    hasAllChildrenTicked = false;
                  }
                } else {
                  hasAllSubChildrenTicked = false;
                }
                //subchild end
                if (
                  !keys.includes(locationsNodes.value.options[i].children[j].id)
                ) {
                  if (
                    !toAdd.includes(locationsNodes.value.options[i].children[j])
                  ) {
                    hasAllChildrenTicked = false;
                    continue;
                  }
                }
              }
              if (
                hasAllChildrenTicked &&
                !call.selectedTags.selectedLocations.includes(
                  locationsNodes.value.options[i]
                )
              )
                toAdd.push(locationsNodes.value.options[i]);

              if (
                !hasAllChildrenTicked &&
                call.selectedTags.selectedLocations.includes(
                  locationsNodes.value.options[i]
                )
              ) {
                const index = call.selectedTags.selectedLocations.indexOf(
                  locationsNodes.value.options[i]
                );
                call.selectedTags.selectedLocations.splice(index, 1);
              }
            } else {
              hasAllChildrenTicked = false;
            }
          }
          if (toAdd.length > 0) {
            for (let i = 0; i < toAdd.length; i++)
              call.selectedTags.selectedLocations.push(toAdd[i]);
          }
        }
      } else {
        tickedLocations.value = [];
      }
    };
    //add parent if all children are selected
    const updateSchemeTypesPairs = (keys) => {
      if (!call.inheritTags) {
        call.selectedTags.selectedSchemeTypes = [];
        if (keys.length > 0) {
          for (let i = 0; i < keys.length; i++) {
            let node = schemeTypesTree.value.getNodeByKey(keys[i]);
            call.selectedTags.selectedSchemeTypes.push(node);
          }
          var toAdd = [];
          for (let i = 0; i < schemeTypesNodes.value.options.length; i++) {
            if (
              schemeTypesNodes.value.options[i].children &&
              schemeTypesNodes.value.options[i].children.length > 0
            ) {
              var hasAllChildrenTicked = true;

              for (
                let j = 0;
                j < schemeTypesNodes.value.options[i].children.length;
                j++
              ) {
                // about subchild
                if (
                  schemeTypesNodes.value.options[i].children[j].children &&
                  schemeTypesNodes.value.options[i].children[j].children
                    .length > 0
                ) {
                  var hasAllSubChildrenTicked = true;

                  for (
                    let k = 0;
                    k <
                    schemeTypesNodes.value.options[i].children[j].children
                      .length;
                    k++
                  ) {
                    if (
                      !keys.includes(
                        schemeTypesNodes.value.options[i].children[j].children[
                          k
                        ].id
                      )
                    ) {
                      hasAllSubChildrenTicked = false;
                      break;
                    }
                  }
                  if (
                    hasAllSubChildrenTicked &&
                    !call.selectedTags.selectedSchemeTypes.includes(
                      schemeTypesNodes.value.options[i].children[j]
                    )
                  ) {
                    toAdd.push(schemeTypesNodes.value.options[i].children[j]);
                  }
                  if (
                    !hasAllSubChildrenTicked &&
                    call.selectedTags.selectedSchemeTypes.includes(
                      schemeTypesNodes.value.options[i].children[j]
                    )
                  ) {
                    const index = call.selectedTags.selectedSchemeTypes.indexOf(
                      schemeTypesNodes.value.options[i].children[j]
                    );
                    call.selectedTags.selectedSchemeTypes.splice(index, 1);
                    hasAllChildrenTicked = false;
                  }
                } else {
                  hasAllSubChildrenTicked = false;
                }
                //subchild end
                if (
                  !keys.includes(
                    schemeTypesNodes.value.options[i].children[j].id
                  )
                ) {
                  if (
                    !toAdd.includes(
                      schemeTypesNodes.value.options[i].children[j]
                    )
                  ) {
                    hasAllChildrenTicked = false;
                    continue;
                  }
                }
              }
              if (
                hasAllChildrenTicked &&
                !call.selectedTags.selectedSchemeTypes.includes(
                  schemeTypesNodes.value.options[i]
                )
              )
                toAdd.push(schemeTypesNodes.value.options[i]);

              if (
                !hasAllChildrenTicked &&
                call.selectedTags.selectedSchemeTypes.includes(
                  schemeTypesNodes.value.options[i]
                )
              ) {
                const index = call.selectedTags.selectedSchemeTypes.indexOf(
                  schemeTypesNodes.value.options[i]
                );
                call.selectedTags.selectedSchemeTypes.splice(index, 1);
              }
            } else {
              hasAllChildrenTicked = false;
            }
          }
          if (toAdd.length > 0) {
            for (let i = 0; i < toAdd.length; i++)
              call.selectedTags.selectedSchemeTypes.push(toAdd[i]);
          }
        }
      } else {
        tickedSchemeTypes.value = [];
      }
    };

    //add parent if all children are selected
    const updateIndustriesNewPairs = (keys) => {
      if (!call.inheritTags) {
        call.selectedTags.selectedIndustriesNew = [];
        if (keys.length > 0) {
          for (let i = 0; i < keys.length; i++) {
            let node = industriesNewTree.value.getNodeByKey(keys[i]);
            call.selectedTags.selectedIndustriesNew.push(node);
          }
          var toAdd = [];
          for (let i = 0; i < industriesNewNodes.value.options.length; i++) {
            if (
              industriesNewNodes.value.options[i].children &&
              industriesNewNodes.value.options[i].children.length > 0
            ) {
              var hasAllChildrenTicked = true;

              for (
                let j = 0;
                j < industriesNewNodes.value.options[i].children.length;
                j++
              ) {
                // about subchild
                if (
                  industriesNewNodes.value.options[i].children[j].children &&
                  industriesNewNodes.value.options[i].children[j].children
                    .length > 0
                ) {
                  var hasAllSubChildrenTicked = true;

                  for (
                    let k = 0;
                    k <
                    industriesNewNodes.value.options[i].children[j].children
                      .length;
                    k++
                  ) {
                    if (
                      !keys.includes(
                        industriesNewNodes.value.options[i].children[j]
                          .children[k].id
                      )
                    ) {
                      hasAllSubChildrenTicked = false;
                      break;
                    }
                  }
                  if (
                    hasAllSubChildrenTicked &&
                    !call.selectedTags.selectedIndustriesNew.includes(
                      industriesNewNodes.value.options[i].children[j]
                    )
                  ) {
                    toAdd.push(industriesNewNodes.value.options[i].children[j]);
                  }
                  if (
                    !hasAllSubChildrenTicked &&
                    call.selectedTags.selectedIndustriesNew.includes(
                      industriesNewNodes.value.options[i].children[j]
                    )
                  ) {
                    const index = call.selectedTags.selectedIndustriesNew.indexOf(
                      industriesNewNodes.value.options[i].children[j]
                    );
                    call.selectedTags.selectedIndustriesNew.splice(index, 1);
                    hasAllChildrenTicked = false;
                  }
                } else {
                  hasAllSubChildrenTicked = false;
                }
                //subchild end
                if (
                  !keys.includes(
                    industriesNewNodes.value.options[i].children[j].id
                  )
                ) {
                  if (
                    !toAdd.includes(
                      industriesNewNodes.value.options[i].children[j]
                    )
                  ) {
                    hasAllChildrenTicked = false;
                    continue;
                  }
                }
              }
              if (
                hasAllChildrenTicked &&
                !call.selectedTags.selectedIndustriesNew.includes(
                  industriesNewNodes.value.options[i]
                )
              )
                toAdd.push(industriesNewNodes.value.options[i]);

              if (
                !hasAllChildrenTicked &&
                call.selectedTags.selectedIndustriesNew.includes(
                  industriesNewNodes.value.options[i]
                )
              ) {
                const index = call.selectedTags.selectedIndustriesNew.indexOf(
                  industriesNewNodes.value.options[i]
                );
                call.selectedTags.selectedIndustriesNew.splice(index, 1);
              }
            } else {
              hasAllChildrenTicked = false;
            }
          }
          if (toAdd.length > 0) {
            for (let i = 0; i < toAdd.length; i++)
              call.selectedTags.selectedIndustriesNew.push(toAdd[i]);
          }
        }
      } else {
        tickedIndustriesNew.value = [];
      }
    };

    const resetSelectedAudiences = () => {
      tickedAudience.value = [];
      updateAudiencePairs(tickedAudience.value);
    };
    const resetSelectedPurposes = () => {
      tickedPurposes.value = [];
      updatePurposesPairs(tickedPurposes.value);
    };
    const resetSelectedLocations = () => {
      tickedLocations.value = [];
      updateLocationsPairs(tickedLocations.value);
    };
    const resetSelectedSchemeTypes = () => {
      tickedSchemeTypes.value = [];
      updateSchemeTypesPairs(tickedAudience.value);
    };
    const resetSelectedIndustriesNew = () => {
      tickedIndustriesNew.value = [];
      updateIndustriesNewPairs(tickedAudience.value);
    };

    const selectAllAudiences = () => {
      tickedAudience.value = [];

      var toTick = [];
      for (let i = 0; i < audienceNodes.value.options.length; i++) {
        toTick.push(audienceNodes.value.options[i].id);

        if (
          audienceNodes.value.options[i].children &&
          audienceNodes.value.options[i].children.length > 0
        ) {
          for (
            let j = 0;
            j < audienceNodes.value.options[i].children.length;
            j++
          ) {
            toTick.push(audienceNodes.value.options[i].children[j].id);

            if (
              audienceNodes.value.options[i].children[j].children &&
              audienceNodes.value.options[i].children[j].children.length > 0
            ) {
              for (
                let k = 0;
                k < audienceNodes.value.options[i].children[j].children.length;
                k++
              ) {
                toTick.push(
                  audienceNodes.value.options[i].children[j].children[k].id
                );
              }
            }
          }
        }
      }

      if (toTick.length > 0) {
        for (let i = 0; i < toTick.length; i++)
          tickedAudience.value.push(toTick[i]);
      }

      updateAudiencePairs(tickedAudience.value);
    };

    const selectAllPurposes = () => {
      tickedPurposes.value = [];

      var toTick = [];
      for (let i = 0; i < purposesNodes.value.options.length; i++) {
        toTick.push(purposesNodes.value.options[i].id);

        if (
          purposesNodes.value.options[i].children &&
          purposesNodes.value.options[i].children.length > 0
        ) {
          for (
            let j = 0;
            j < purposesNodes.value.options[i].children.length;
            j++
          ) {
            toTick.push(purposesNodes.value.options[i].children[j].id);

            if (
              purposesNodes.value.options[i].children[j].children &&
              purposesNodes.value.options[i].children[j].children.length > 0
            ) {
              for (
                let k = 0;
                k < purposesNodes.value.options[i].children[j].children.length;
                k++
              ) {
                toTick.push(
                  purposesNodes.value.options[i].children[j].children[k].id
                );
              }
            }
          }
        }
      }

      if (toTick.length > 0) {
        for (let i = 0; i < toTick.length; i++)
          tickedPurposes.value.push(toTick[i]);
      }

      updatePurposesPairs(tickedPurposes.value);
    };

    const selectAllLocations = () => {
      tickedLocations.value = [];

      var toTick = [];
      for (let i = 0; i < locationsNodes.value.options.length; i++) {
        toTick.push(locationsNodes.value.options[i].id);

        if (
          locationsNodes.value.options[i].children &&
          locationsNodes.value.options[i].children.length > 0
        ) {
          for (
            let j = 0;
            j < locationsNodes.value.options[i].children.length;
            j++
          ) {
            toTick.push(locationsNodes.value.options[i].children[j].id);

            if (
              locationsNodes.value.options[i].children[j].children &&
              locationsNodes.value.options[i].children[j].children.length > 0
            ) {
              for (
                let k = 0;
                k < locationsNodes.value.options[i].children[j].children.length;
                k++
              ) {
                toTick.push(
                  locationsNodes.value.options[i].children[j].children[k].id
                );
              }
            }
          }
        }
      }

      if (toTick.length > 0) {
        for (let i = 0; i < toTick.length; i++)
          tickedLocations.value.push(toTick[i]);
      }

      updateLocationsPairs(tickedLocations.value);
    };

    const selectAllSchemeTypes = () => {
      tickedSchemeTypes.value = [];

      var toTick = [];
      for (let i = 0; i < schemeTypesNodes.value.options.length; i++) {
        toTick.push(schemeTypesNodes.value.options[i].id);

        if (
          schemeTypesNodes.value.options[i].children &&
          schemeTypesNodes.value.options[i].children.length > 0
        ) {
          for (
            let j = 0;
            j < schemeTypesNodes.value.options[i].children.length;
            j++
          ) {
            toTick.push(schemeTypesNodes.value.options[i].children[j].id);

            if (
              schemeTypesNodes.value.options[i].children[j].children &&
              schemeTypesNodes.value.options[i].children[j].children.length > 0
            ) {
              for (
                let k = 0;
                k <
                schemeTypesNodes.value.options[i].children[j].children.length;
                k++
              ) {
                toTick.push(
                  schemeTypesNodes.value.options[i].children[j].children[k].id
                );
              }
            }
          }
        }
      }

      if (toTick.length > 0) {
        for (let i = 0; i < toTick.length; i++)
          tickedSchemeTypes.value.push(toTick[i]);
      }

      updateSchemeTypesPairs(tickedSchemeTypes.value);
    };

    const selectAllIndustriesNew = () => {
      tickedIndustriesNew.value = [];

      var toTick = [];
      for (let i = 0; i < industriesNewNodes.value.options.length; i++) {
        toTick.push(industriesNewNodes.value.options[i].id);

        if (
          industriesNewNodes.value.options[i].children &&
          industriesNewNodes.value.options[i].children.length > 0
        ) {
          for (
            let j = 0;
            j < industriesNewNodes.value.options[i].children.length;
            j++
          ) {
            toTick.push(industriesNewNodes.value.options[i].children[j].id);

            if (
              industriesNewNodes.value.options[i].children[j].children &&
              industriesNewNodes.value.options[i].children[j].children.length >
                0
            ) {
              for (
                let k = 0;
                k <
                industriesNewNodes.value.options[i].children[j].children.length;
                k++
              ) {
                toTick.push(
                  industriesNewNodes.value.options[i].children[j].children[k].id
                );
              }
            }
          }
        }
      }

      if (toTick.length > 0) {
        for (let i = 0; i < toTick.length; i++)
          tickedIndustriesNew.value.push(toTick[i]);
      }

      updateIndustriesNewPairs(tickedIndustriesNew.value);
    };

    const nameSubstring = (name) => {
      return String(name).slice(0, 150);
    };

    const getExtension = (fileName) => {
      return fileName.split(".").pop();
    };

    const callImportantLinks = computed(() => {
      return call.callLinks.filter(
        (el) => el.documentType === EDocumentType.UsefulLinks || el.documentType === null
      );
    });

    const callOtherLinks = computed(() => {
      return call.callLinks.filter(
        (el) =>
          el.documentType !== EDocumentType.UsefulLinks &&
          el.documentType !== null &&
          el.documentType !== EDocumentType.VisualPingLink
      );
    });

    const removeLink = (link) => {
      const index = call.callLinks.indexOf(link);

      if (index > -1) call.callLinks.splice(index, 1);
    };

    const call = reactive({
      name: "",
      identifier: "",
      body: "",
      publicationStatus: "Live",
      parentId: parentId,
      isCall: false,
      callStartDate: moment().format("DD-MM-YYYY"),
      callEndDate: null,
      callLinks: [],
      inheritTags: false,
      selectedTags: {
        selectedAudiences: [],
        selectedPurposes: [],
        selectedLocations: [],
        selectedSchemeTypes: [],
        selectedKeywords: [],
        selectedIndustriesNew: [],
      },
      recurringStartingYear: 0,
      recurringYearsDuration: 0,
      recurringStartDate: moment().format("DD-MM"),
      recurringEndDate: moment().format("DD-MM"),
      isAlwaysOpen: false,
      isRecurringDeadline: false,
      isOneOffDeadline: true,
      isEnglishScheme: false,
      locationTags: []
    });

    const createNewCall = async () => {
      //if check for if locationtags germany are selected but no tree location tag is selected
      if (!call.selectedTags.selectedLocations.length > 0 && call.locationTags.length > 0) {
        store.commit('scheme/enableModalError');
        store.commit(
          'scheme/updateModalError',
          'Please select Germany as location tag.'
        );
        
      }
      else{
        if (selectedFiles.value.length > 0) {
        let fd = new FormData();

        //Even though the selectedFiles variable contains all the selected files, it is not an array. So, in order to use the map function, we are using the
        //Array.from()syntax, which will convert the array-like object into the new array copy.

        Array.from(selectedFiles.value).map(
          ({ file, name, documentType }, index) => {
            fd.append("file" + index, file);
            fd.append("newnamefile" + index, name);
            fd.append("documenttypefile" + index, documentType);
            return fd;
          }
        );
        dialog.value = false;
        await SchemesServices.createCall(call, fd);
      } else await SchemesServices.createCall(call, null);
      
      selectedFiles.value = [];
      resetCall();
      }
    };

    const updateIsAlwaysOpenTrue = (val) => {
      if (val) {
        call.isRecurringDeadline = false;
        call.isOneOffDeadline = false;
      }
    };

    const updateRecurringTrue = (val) => {
      if (val) {
        call.isAlwaysOpen = false;
        call.isOneOffDeadline = false;
      }
    };

    const updateOneOffTrue = (val) => {
      if (val) {
        call.isAlwaysOpen = false;
        call.isRecurringDeadline = false;
      }
    };

    const areSchemeChangesLoading = computed({
      get: () => store.state.scheme.areSchemeChangesLoading,
    });
    const locationTagsBulkList = computed({
      get: () => store.state.scheme.locationTagsBulkList,
    });

    return {
      locationTagsBulkList,
      publicationStatusOptions,
      areSchemeChangesLoading,
      updateOneOffTrue,
      updateRecurringTrue,
      updateIsAlwaysOpenTrue,
      route,
      dialog,
      maximizedToggle,
      call,
      parentId,
      createNewCall,
      addLink,
      showAddLink,
      addLinkToCall,
      disableAddLink,
      disableAddDocument,
      resetCall,
      resetAddLink,
      resetAddFile,
      selectedFiles,
      tab,
      filterAudience,
      filterPurposes,
      filterLocations,
      filterSchemeTypes,
      filterIndustriesNew,
      audienceNodes,
      industriesNewNodes,
      purposesNodes,
      locationsNodes,
      schemeTypesNodes,
      tickedAudience,
      tickedIndustriesNew,
      tickedLocations,
      tickedPurposes,
      tickedSchemeTypes,
      allKeywordsTags,
      filterOptions,
      resetSelectedAudiences,
      resetSelectedIndustriesNew,
      resetSelectedLocations,
      resetSelectedPurposes,
      resetSelectedSchemeTypes,
      audienceTree,
      purposesTree,
      schemeTypesTree,
      locationsTree,
      industriesNewTree,
      updateAudiencePairs,
      updatePurposesPairs,
      updateIndustriesNewPairs,
      updateLocationsPairs,
      updateSchemeTypesPairs,
      selectAllAudiences,
      selectAllLocations,
      selectAllIndustriesNew,
      selectAllPurposes,
      selectAllSchemeTypes,
      showEditLink,
      selectedLink,
      editLinkText,
      editLinkUrl,
      editLinkDocumentType,
      setValues,
      disableEditLink,
      editLink,
      documentTypes,
      showCategories,
      callImportantLinks,
      callOtherLinks,
      removeLink,
      nameSubstring,
      getExtension,
      addFileToCall,
      showAddDocument,
      addFile,
      showEditDocument,
      selectedDocument,
      editDocumentDocumentType,
      editDocumentName,
      setDocumentValues,
      disableEditDocument,
      editDocument,
      editorRef,
      getDocTypeDescription,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || "Field is required!",
      ],
      recurringRule(val) {
        return (val != null) & (val > 0) || "Value must be greater than 0!";
      },

      startDateOptionsFn(date) {
        if (
          call.callEndDate != null &&
          call.callEndDate != "" &&
          call.callEndDate.length > 0
        )
          return (
            date <= moment(call.callEndDate, "DD-MM-YYYY").format("YYYY/MM/DD")
          );

        return true;
      },
      endDateOptionsFn(date) {
        if (
          call.callStartDate != null &&
          call.callStartDate != "" &&
          call.callStartDate.length > 0
        ) {
          return (
            date >=
            moment(call.callStartDate, "DD-MM-YYYY").format("YYYY/MM/DD")
          );
        }

        return true;
      },
      myFilterMethod(node, filter) {
        const filt = filter.toLowerCase();
        return node.label && node.label.toLowerCase().indexOf(filt) > -1;
      },
      filterFn(val, update) {
        update(() => {
          if (val === "") {
            filterOptions.value = allKeywordsTags.value;
          } else {
            const needle = val.toLowerCase();
            filterOptions.value = allKeywordsTags.value.filter(
              (v) => v.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      createValue(val, done) {
        if (val.length > 0) {
          if (!allKeywordsTags.value.includes(val)) {
            store.commit("tagsNavigation/updateAllKeywordsTags", val);
          }
          done(val, "add-unique");
        }
      },
      resetAllCustomTags(value) {
        if (value == true) {
          resetSelectedAudiences();
          resetSelectedIndustriesNew();
          resetSelectedLocations();
          resetSelectedPurposes();
          resetSelectedSchemeTypes();
          call.selectedTags.selectedKeywords = [];
          call.locationTags = [];
        }
      },
      iconBasedOnExtension(extension) {
        switch (extension) {
          case "pdf":
            return "las la-2x la-file-pdf";
          case "doc":
            return "las la-2x la-file-word";
          case "docx":
            return "las la-2x la-file-word";
          case "xls":
            return "las la-2x la-file-excel";
          case "xlsx":
            return "las la-2x la-file-excel";
          case "csv":
            return "las la-2x la-file-csv";
          case "txt":
            return "las la-2x la-file";
          default:
            return "las la-2x la-file";
        }
      },
      filterLocationTagsBulk(val, update) {
        if (!val || val == "" || val.length < 2) return [];
        update(() => {
          if (!val || val == "" || val.length < 2) return [];
          else {
            SchemesServices.searchLocationCodesBulk(val);
            //perform search from service
            // then set store to service return data
          }
        });
      },
    };
  },
};
</script>
