<template>
  <OrganisationErrorModal v-if="hasModalError" />
  <CreateOrganisationBox v-if="showCreate" :showCreate="showCreate" />
  <AddOrganisationBox v-if="showAdd" :showAdd="showAdd" />
  <EditOrganisationBox v-if="showEdit" :showEdit="showEdit" />

  <div class="organisations-container">
    <div class="q-pa-md">
      <q-table
        class="sticky-header"
        title="Organisations"
        :rows="organisations"
        :columns="columns"
        row-key="id"
        :filter="filter"
        :loading="loading"
        :dense="$q.screen.lt.md"
        v-model:pagination="pagination"
        binary-state-sort
      >
        <template v-slot:loading>
          <q-inner-loading showing color="primary"></q-inner-loading>
        </template>
        <template v-slot:top-left>
          <q-btn
            class="orange-btn-filled bold-font"
            :disable="loading"
            label="Add new organisation"
            no-caps
            @click="enableCreate"
            dense
          >
            <i class="las la-2x la-plus-circle la-fw"></i>
          </q-btn>
        </template>
        <template v-slot:top-right>
          <q-input
            borderless
            dense
            debounce="300"
            v-model="filter"
            placeholder="Search"
            @keyup.enter="searchOnEnter"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props" :key="`m_${props.row.id}`">
            <q-td auto-width>
              <q-btn
                size="sm"
                color="blue"
                round
                dense
                v-if="props.row.children && props.row.children.length > 0"
                @click="props.expand = !props.expand"
                :icon="props.expand ? 'arrow_drop_up' : 'arrow_drop_down'"
              />
              <q-tooltip
                v-if="props.row.children && props.row.children.length > 0"
                >Show/hide children</q-tooltip
              >
            </q-td>
            <q-td key="name" :props="props">{{ props.row.name }}</q-td>
            <q-td key="email" :props="props">{{ props.row.email }}</q-td>
            <q-td key="url" :props="props">{{ props.row.url }}</q-td>
            <q-td key="actions" :props="props" class="q-gutter-xs">
              <q-btn
                color="green"
                size="sm"
                no-caps
                @click="enableAdd(props.row)"
              >
                <i class="las la-2x la-plus-square"></i>
                <q-tooltip> Add Parent/child </q-tooltip>
              </q-btn>
              <q-btn
                color="primary"
                size="sm"
                no-caps
                @click="enableEdit(props.row.id)"
              >
                <i class="las la-2x la-pen"></i>
                <q-tooltip> Edit </q-tooltip>
              </q-btn>
            </q-td>
          </q-tr>
          <q-tr
            v-show="props.expand"
            :props="props"
            :key="`e_${props.row.id}`"
            class="q-tr--no-hover q-virtual-scroll--with-prev"
          >
            <q-td colspan="100%">
              <q-item
                v-ripple
                v-for="childs in props.row.children"
                :key="childs.id"
              >
                <div class="organisations-card">
                  <q-item-section class="text-left q-ml-md">{{
                    childs.name
                  }}</q-item-section>
                  <q-item-section class="text-left">{{
                    childs.email
                  }}</q-item-section>
                  <q-item-section class="text-left">{{
                    childs.url
                  }}</q-item-section>
                  <q-item-section top side>
                    <q-btn
                      class="gt-xs"
                      size="12px"
                      flat
                      round
                      icon="edit"
                      color="blue"
                      @click="enableEdit(childs.id)"
                    >
                      <q-tooltip>Edit oragnisation</q-tooltip>
                    </q-btn>
                  </q-item-section>
                </div>
              </q-item>    
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import OrganisationErrorModal from '../components/organisation-components/OrganisationErrorModal.vue';
import EditOrganisationBox from '../components/organisation-components/EditOrganisationBox.vue';
import CreateOrganisationBox from '../components/organisation-components/CreateOrganisationBox.vue';
import AddOrganisationBox from '../components/organisation-components/AddOrganisationBox.vue';
import { onMounted, computed, watch, ref } from 'vue';
import organisationsService from '../services/organisations.service';
import store from '../store/index';
import router from '../router/index';
const columns = [
  {
    name: 'toggleHide',
    required: true,
    align: 'left',
    field: (row) => row.name,
    format: (val) => `${val}`,
  },
  {
    name: 'name',
    required: true,
    label: 'Title',
    align: 'left',
    style: 'white-space: normal; width: 40px',
    headerStyle: 'white-space: normal; width: 40px',
    field: (row) => row.name,
    format: (val) => `${val}`,
  },
  {
    name: 'email',
    label: 'Email',
    align: 'left',
    style: 'white-space: normal; width: 40px',
    headerStyle: 'white-space: normal; width: 40px',
    field: (row) => row.email,
    format: (val) => `${val}`,
  },
  {
    name: 'url',
    label: 'Website',
    align: 'left',
    style: 'white-space: normal; width: 40px',
    headerStyle: 'white-space: normal; width: 40px',
    field: (row) => row.url,
    format: (val) => `${val}`,
  },
  {
    name: 'actions',
    align: 'center',
    label: 'Actions',
    field: 'actions',
    style: 'white-space: normal; width: 40px',
    headerStyle: 'white-space: normal; width: 40px',
  },
];
export default {
  name: 'Organisations',
  components: {
    EditOrganisationBox,
    AddOrganisationBox,
    CreateOrganisationBox,
    OrganisationErrorModal,
  },
  setup() {
    const filter = ref('');

    onMounted(async () => {
      loading.value = true;
      await organisationsService.getPaginationOrganisations();
      loading.value = false;
    });

    //function for quasar to change page with server side request
    //searching while typing replaced with search on enter (for now)
    const onRequest = async (props) => {
      const { page, rowsPerPage } = props.pagination;
      const filter = props.filter;
      const fetchCount =
        rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage;

      loading.value = true;
      console.log(filter);
      await organisationsService.getPaginationOrganisations(
        filter,
        page - 1,
        fetchCount
      );
      store.commit('organisation/updatePaginationPage', props.pagination);
      loading.value = false;
    };

    const searchOnEnter = async() => {
      var page = pagination.value.page;
      var rowsPerPage = pagination.value.rowsPerPage
      const fetchCount =
        rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage;

      loading.value = true;
      await organisationsService.getPaginationOrganisations(
        filter.value,
        page - 1,
        fetchCount
      );
      store.commit('organisation/updatePaginationPage', pagination.value);
      loading.value = false;
      
    }

    watch(
      () => filter.value,
      async (next, prev) => {
        if (prev && prev.length > 0 && next.length == 0) {
           await searchOnEnter();
        }
      }
    );
    //quasar Pagination
    const rows = ref(organisations);
    const loading = ref(false);
    const paginationVari = ref({
      page: 1,
      rowsPerPage: 7,
      rowsNumber: 16,
    });
    const pagination = ref(
      computed({
        get: () => store.state.organisation.pagination,
        set: (paginationVar) =>
          store.commit('organisation/updatePagination', paginationVar),
      })
    );
    const enableCreate = () => {
      store.commit('organisation/enableCreateOrganisation');
    };
    const enableAdd = (current) => {
      store.commit('organisation/setselectedOrganisationName', current.name);
      store.commit('organisation/enableAdd', current.id);
    };
    const enableEdit = (current) => {
      organisationsService.getOrganisationById(current);
      store.commit('organisation/enableEdit', current);

      router.push({ path: `/admin/organisation/edit/${current}` });
    };

    const showCreate = computed({
      get: () => store.state.organisation.showCreateOrganisation,
    });
    const organisations = computed({
      get: () => store.state.organisation.organisationsPaginationList,
    });
    const showEdit = computed({
      get: () => store.state.organisation.showEditOrganisation,
    });

    const showAdd = computed({
      get: () => store.state.organisation.showAdd,
    });

    const hasModalError = computed({
      get: () => store.state.organisation.hasModalError,
    });

    return {
      enableEdit,
      filter,
      searchOnEnter,
      enableAdd,
      enableCreate,
      rows,
      paginationVari,
      onRequest,
      pagination,
      loading,
      organisations,
      organisationsService,
      columns,
      showCreate,
      showAdd,
      showEdit,
      hasModalError,
    };
  },
};
</script>
