<template>
  <div class="schemedetails-component">
    <div class="column">
      <div class="row main-info-top">
        <div class="column details-box">
          <div class="row status-info">
            <span class="detailname-span">Status: &nbsp;</span>
            <span
              class="status-open status"
              v-if="selectedScheme.status == 'Open'"
            >
              {{ selectedScheme.status }}</span
            >
            <span
              class="status-closed status"
              v-else-if="selectedScheme.status == 'Closed'"
            >
              {{ selectedScheme.status }}</span
            >
            <span class="status-none status" v-else>
              {{ selectedScheme.status }}</span
            >
          </div>

          <div class="row status-info">
            <span class="detailname-span">Identifier: &nbsp;</span>
            <span class="status"> {{ selectedScheme.identifier }} </span>
          </div>
          <div v-if="!isCall && !isApplicationPeriod" class="row status-info">
            <span class="detailname-span">Accronym: &nbsp;</span>
            <span class="status"> {{ selectedScheme.accronym }} </span>
          </div>
          <div class="row status-info">
            <div v-if="selectedScheme.isCall || selectedScheme.isApplicationPeriod">
              <span class="detailname-span">Is call: &nbsp;</span>
              <span class="status">Yes </span>
            </div>
            <div v-else>
              <span class="detailname-span">Is call: &nbsp;</span>
              <span class="status">No </span>
            </div>
          </div>

          <div v-if="!isCall && !isApplicationPeriod" class="row status-info">
            <span class="detailname-span">Archive code: &nbsp;</span>
            <span class="status"> {{ selectedScheme.archivecode }} </span>
          </div>

          <div v-if="!isCall && !isApplicationPeriod" class="row status-info">
            <span class="detailname-span">Scope: &nbsp;</span>
            <span class="status"> {{ selectedScheme.scope }} </span>
          </div>

          <div v-if="!isCall && !isApplicationPeriod" class="row status-info">
            <span class="detailname-span">Application term: &nbsp;</span>
            <span class="status"> {{ selectedScheme.applicationTerm }} </span>
          </div>

          <div class="row status-info">
            <span class="detailname-span">Applies to SME?: &nbsp;</span>
            <span class="status"> {{ selectedScheme.smeType }} </span>
          </div>
          <div class="row status-info">
            <span class="detailname-span">Publication status: &nbsp;</span>
            <span class="status"> {{ selectedScheme.publicationStatus }} </span>
          </div>
        </div>
        <div class="date-information">
          <div class="date-input" style="max-width: 300px">
            <span class="date-text">Start date:</span>
            <q-input outlined bg-color="white" v-if="!selectedScheme.isCall && !selectedScheme.isApplicationPeriod" v-model="schemeStart" readonly>
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer"> </q-icon>
              </template>
            </q-input>
            <q-input outlined bg-color="white" v-if="selectedScheme.isCall || selectedScheme.isApplicationPeriod" v-model="callStart" readonly>
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer"> </q-icon>
              </template>
            </q-input>
          </div>

          <div class="date-input end-date" style="max-width: 300px">
            <span class="date-text">End date:</span>
            <q-input outlined bg-color="white" v-if="!selectedScheme.isCall && !selectedScheme.isApplicationPeriod" v-model="schemeEnd" readonly>
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer"> </q-icon>
              </template>
            </q-input>
            <q-input outlined bg-color="white" v-if="selectedScheme.isCall || selectedScheme.isApplicationPeriod" v-model="callEnd" readonly>
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer"> </q-icon>
              </template>
            </q-input>
          </div>
        </div>

        <div class="organisations" style="max-width: 350px">
          <span class="organisations-heading">Organisations:</span>
          <q-list
            dense
            bordered
            padding
            class="rounded-borders"
            v-for="org in schemeOrganisations"
            :key="org.id"
          >
            <q-item clickable v-ripple>
              <q-item-section>
                {{ org.name }}
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>

      <div class="links-list">
        <q-list bordered>
          <q-item-label class="tags-heading" header
            >Important links:
          </q-item-label>
          <q-item
            v-for="link in selectedScheme.schemeLinks"
            :key="link.id"
            tag="a"
            :href="link.url"
            target="_blank"
            clickable
          >
            <q-item-section avatar>
              <q-icon name="link" />
            </q-item-section>
            <q-item-section class="link-text">
              {{ link.text }}
            </q-item-section>
          </q-item>
        </q-list>
      </div>

      <div class="tags-details q-pa-md">
        <span class="tags-heading">Tags:</span>
        <div class="row justify-around">
          <div class="q-pa-md q-gutter-md">
            <q-badge
              outline
              color="primary"
              v-for="tag in schemeTags"
              :key="tag.id"
            >
              {{ tag.name }}
            </q-badge>
          </div>
        </div>
      </div>
      <div class="large-details">
        <div class="introduction">
          <h5>Introduction</h5>
          <div v-if="showMore" v-html="selectedScheme.introduction"></div>
          <div v-if="!showMore" v-html="shortIntroduction"></div>
          <q-btn
            flat
            icon="expand_more"
            v-if="!showMore"
            @click="toggleShowMore()"
          >
            <q-tooltip>
              Read more
            </q-tooltip>
          </q-btn>
          <q-btn
            flat
            icon="expand_less"
            v-if="showMore"
            @click="toggleShowMore()"
          >
            <q-tooltip>
              Read less
            </q-tooltip>
          </q-btn>
        </div>

        <div class="introduction">
          <h5>Body</h5>
          <div v-if="showMoreBody" v-html="selectedScheme.body"></div>
          <div v-if="!showMoreBody" v-html="shortBody"></div>
          <q-btn
            flat
            icon="expand_more"
            v-if="!showMoreBody"
            @click="toggleShowMoreBody()"
          >
            <q-tooltip>
              Read more
            </q-tooltip>
          </q-btn>
          <q-btn
            flat
            icon="expand_less"
            v-if="showMoreBody"
            @click="toggleShowMoreBody()"
          >
            <q-tooltip>
              Read less
            </q-tooltip>
          </q-btn>
        </div>

        <div class="introduction">
          <h5>Criteria</h5>
          <div v-html="selectedScheme.criteria"></div>
        </div>
        <div class="introduction">
          <h5>Budget</h5>
          <div v-html="selectedScheme.budget"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import store from '../../store/index';

export default {
  props: {},
  setup() {
    const selectedScheme = computed({
      get: () => store.state.scheme.selectedScheme,
    });

    const showMore = ref(false);
    const showMoreBody = ref(false);

    const shortIntroduction = computed({
      get: () => store.state.scheme.shortIntroduction,
    });

    const shortBody = computed({
      get: () => store.state.scheme.shortBody,
    });

    const schemeStart = computed({
      get: () => store.state.scheme.selectedSchemeStartDate,
    });

    const callStart = computed({
      get: () => store.state.scheme.selectedSchemeCallStartDate
    });

    const callEnd = computed({
      get: () => store.state.scheme.selectedSchemeCallEndDate
    });

    const isCall = computed({
      get: () => store.state.scheme.selectedSchemeIsCall,
    });

    const isApplicationPeriod = computed({
      get: () => store.state.scheme.selectedSchemeIsApplicationPeriod,
    });

    const schemeEnd = computed({
      get: () => store.state.scheme.selectedSchemeEndDate
    });

    const schemeOrganisations = computed({
      get: () => store.state.scheme.selectedSchemeOrgs,
    });

    const schemeTags = computed({
      get: () => store.state.scheme.selectedSchemeTags,
    });

    const toggleShowMore = () => (showMore.value = !showMore.value);

    const toggleShowMoreBody = () => (showMoreBody.value = !showMoreBody.value);

    return {
      selectedScheme,
      showMore,
      toggleShowMore,
      shortIntroduction,
      toggleShowMoreBody,
      shortBody,
      showMoreBody,
      schemeStart,
      schemeEnd,
      callStart,
      callEnd,
      schemeOrganisations,
      schemeTags,
      isCall,
      isApplicationPeriod
    };
  },
};
</script>
