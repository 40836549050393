import axios from 'axios';
import axiosUrl from './axios.service';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
const API_URL = axiosUrl.getAxiosUrl();
import router from '../router/index';
import AuthService from '../services/auth.service';

class SalesforceService {
  async getAccountsSalesforce() {
    return await axios
      .get(API_URL + '/SalesForce/CheckSalesForceAccess', {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.success) {
  
          axios
            .get(API_URL + '/SalesForce/GetAccounts', {
              headers: authHeader(),
            })
            .then((response) => {
              store.commit('salesForce/updateAccounts', response.data);
            });
        } else {
         location.href = response.data.redirectUrl;
        }
      })
      .catch((error) => {
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          // auto logout if 400 or 401 or 403 response returned from api
          AuthService.logout();
          router.go();
        }
      });
  }

  async postNewsSelection(data) {
    return await axios
      .post(
        API_URL + 'SalesForce/PostNewsSelection',
        {
          itemsList: data,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        console.log('resp is  ', response);
      })
      .catch((error) => {
        console.log('ERROR:  ', error);
    
      });
  }
}

export default new SalesforceService();
