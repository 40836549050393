<template>
  <div class="bar-parent">
    <Bar class="scheme-visits-bar" />
  </div>
</template>

<script>
import { defineComponent, h } from 'vue';

import { Bar } from 'vue-chartjs';
import { computed } from 'vue';
import store from '../../../store/index';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default defineComponent({
  name: 'BarChart',
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
  },
  setup(props) {
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      barThickness: 30,
      scales: {
      },
    };

    const chartDataStore = computed({
      get: () => store.state.portalStats.mainBarData,
    });

    return () =>
      h(Bar, {
        chartData: chartDataStore.value,
        chartOptions,
        chartId: props.chartId,
        width: props.width,
        height: props.height,
        cssClasses: props.cssClasses,
        styles: props.styles,
        plugins: props.plugins,
      });
  },
});
</script>
