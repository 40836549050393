<template>
  <div class="user-stats-page">
    <UsersListModal />
    <div class="row page-heading">
      <h2 class="title">
        User stats
      </h2>
      <div class="row">
        <router-link :to="{ name: 'GeneralStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('general')">
            General stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'UserStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('user')">
            User stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'PortalStats' }" class="page-link q-mr-md">
          <q-btn flat class="btn-navigation" :class="isUnderlined('portal')">
            Portal stats
          </q-btn>
        </router-link>
        <router-link :to="{ name: 'ConsumptionStats' }" class="page-link">
          <q-btn
            flat
            class="btn-navigation"
            :class="isUnderlined('consumption')"
          >
            Consumption stats
          </q-btn>
        </router-link>
        <!--     <router-link :to="{ name: 'OnzeStats' }" class="page-link">
          <q-btn flat class="btn-navigation" :class="isUnderlined('onze')">
            ONZE stats
          </q-btn>
        </router-link> -->
      </div>
    </div>

    <div
      class="row main-page-row"
      style="margin-bottom: 40px; padding-bottom: 20px"
    >
      <div class="column statfilters-area">
        <div class="stats-filters">
          <div class="filter-box">
            <div class="column">
              <div class="search-button">
                <p class="warning">
                  Select filter(s) to show data
                </p>
                <q-btn
                  class="orange-btn bold-font"
                  flat
                  label="Search"
                  no-caps
                  @click="fetchFilteredData()"
                />
              </div>
              <div class="filter-box customers-box">
                <q-select
                  class="filter-select"
                  borderless
                  bg-color="white"
                  v-model="selectedCustomerOwner"
                  :options="customerOwnersList"
                  label="Customer owner"
                  option-value="fullName"
                  option-label="fullName"
                  clearable
                  @popup-show="width = 200"
                  :popup-content-style="`width: 200px; word-break: break-all;`"
                  style="word-break: break-all;"
                />
                <q-select
                  class="filter-select"
                  multiple
                  borderless
                  bg-color="white"
                  v-model="selectedCustomers"
                  :options="filteredCustomersList"
                  label="Customer(s)"
                  option-value="id"
                  option-label="name"
                  @filter="filterCustomersFn"
                  use-input
                  input-debounce="0"
                  behavior="menu"
                  ref="customersMultiselect"
                  @update:model-value="setInput()"
                  clearable
                  use-chips
                  @popup-show="width = 200"
                  :popup-content-style="`width: 200px; word-break: break-all;`"
                  style="word-break: break-all;"
                  emit-value
                  map-options
                />
              </div>
              <div class="filter-box">
                <q-select
                  class="filter-select"
                  multiple
                  borderless
                  bg-color="white"
                  v-model="selectedUsers"
                  :options="filteredUsersList"
                  label="User(s)"
                  option-value="id"
                  option-label="username"
                  @filter="filterUsersFn"
                  use-input
                  input-debounce="0"
                  behavior="menu"
                  ref="usersMultiselect"
                  @update:model-value="setInput()"
                  clearable
                  use-chips
                  @popup-show="width = 200"
                  :popup-content-style="`width: 200px; word-break: break-all;`"
                  style="word-break: break-all;"
                  emit-value
                  map-options
                />
              </div>
              <div class="stats-filters">
                <div class="filter-box">
                  <q-select
                    class="filter-select"
                    borderless
                    bg-color="white"
                    v-model="modelYearFrom"
                    :options="optionsYearsFrom"
                    label="Year from"
                  />
                </div>
                <div class="filter-box">
                  <q-select
                    class="filter-select"
                    borderless
                    bg-color="white"
                    v-model="modelMonthFrom"
                    :options="optionsMonths"
                    label="Month from"
                    option-value="id"
                    option-label="name"
                  >
                  </q-select>
                </div>
              </div>
              <div class="stats-filters">
                <div class="filter-box">
                  <q-select
                    class="filter-select"
                    borderless
                    bg-color="white"
                    v-model="modelYearTo"
                    :options="optionsYearsTo"
                    label="Year to"
                  />
                </div>
                <div class="filter-box">
                  <q-select
                    class="filter-select"
                    borderless
                    bg-color="white"
                    v-model="modelMonthTo"
                    :options="optionsMonths"
                    label="Month to"
                    option-value="id"
                    option-label="name"
                  >
                  </q-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="column main-data-column"
        style="max-width: 75vw; margin-left: 5vw"
      >
        <div
          v-if="isLoadingTable || isLoadingBoxes || isLoadingGraph"
          style="text-align: center; "
        >
          <span style="display: inline-block">
            <q-spinner-bars color="primary" size="2em" />
          </span>
        </div>

        <div class="data-container column" v-else>
          <div
            class="select-customer-placeholder"
            v-if="!clicked || (clicked && selectedCustomers.length == 0)"
          >
            Select a customer to display graph
          </div>
          <div class="graph-box row q-mb-md" v-else>
            <LineGraphUser
              class="line-graph"
              ref="lineGraph"
              v-if="isShowing"
            />
            <q-select
              class="filter q-ml-md"
              borderless
              bg-color="white"
              v-model="selectedGraphType"
              :options="graphTypeOptions"
              label="Graph type"
              option-value="type"
              option-label="type"
              @update:model-value="fetchGraphBasedOnType()"
            />
          </div>

          <div class="row page-bottom">
            <div class="stat-boxes" v-if="isShowing">
              <div class="stats-boxes">
                <div class="row">
                  <div class="stat-section">
                    <span class="stat-section-data">
                      {{ activeAccountsCount }}
                    </span>
                    <span class="stat-section-text">
                      active users
                    </span>
                  </div>
                  <div class="stat-section">
                    <span class="stat-section-data">
                      {{ usersWithVisitCount }}
                    </span>
                    <span class="stat-section-text">
                      users with visit in last 30 days
                    </span>
                  </div>

                  <div class="stat-section">
                    <span class="stat-section-data">
                      {{ usersWithoutNewsletterCount }}
                    </span>
                    <span class="stat-section-text">
                      users without newsletter
                    </span>
                  </div>
                  <div class="stat-section">
                    <span class="stat-section-data">
                      {{ usersWithoutProfileCount }}
                    </span>
                    <span class="stat-section-text">
                      users without profile
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-data" v-if="isShowing">
              <UsersTable class="q-mt-xl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted, watch } from "vue";
import store from "../../store/index.js";
import CustomersService from "../../services/customers.service";
import StatsService from "../../services/stats.service";
import LineGraphUser from "./User/LineGraphUser.vue";
import UsersTable from "./User/UsersTable.vue";
import UsersService from "../../services/user.service";
import UsersListModal from "./User/UsersListModal";
import { useRoute } from "vue-router";
import moment from "moment";
export default {
  name: "UserStats",
  components: {
    LineGraphUser,
    UsersTable,
    UsersListModal,
  },
  setup() {
    const isShowing = ref(false);
    const isLoadingGraph = ref(false);
    const isLoadingBoxes = ref(false);
    const isLoadingTable = ref(false);
    const route = useRoute();
    const clicked = ref(false);
    onMounted(async () => {
      store.commit("userStats/updateYearsOptions");
      let thisMonth = new Date().getMonth();
      let date1 = new Date();
      let date2 = new Date();
      let date3 = new Date();
      thisMonth;
      date1.setMonth(thisMonth - 1, 0);
      date2.setMonth(thisMonth, 0);
      date3.setMonth(thisMonth + 1, 0);

      if (date3.getMonth() == 0 || date3.getMonth() == 1) {
        store.commit("userStats/updateYearFrom", moment().year() - 1);
      }
      store.commit("userStats/updateYearsToOptions");

      store.commit("userStats/updateMonthTo", {
        id: date3.getMonth(),
        name: date3.toLocaleString("nl-NL", { month: "long" }),
      });

      store.commit("userStats/updateMonthFrom", {
        id: date1.getMonth(),
        name: date1.toLocaleString("nl-NL", { month: "long" }),
      });

      store.commit("userStats/resetFilters");
      await CustomersService.getAllCustomers("", "", "", false, 0, 5000);
      await CustomersService.getCustomerTypes();
      await CustomersService.getCustomerOwners();
      await UsersService.getUsersDefaultEnv();
    });

    const fetchFilteredData = async () => {
      clicked.value = true;
      isShowing.value = true;
      fetchGraphBasedOnType();
      isLoadingBoxes.value = true;
      await StatsService.getUsersStatsBoxes(
        selectedCustomers,
        selectedCustomerOwnerId,
        selectedUsers
      );
      isLoadingBoxes.value = false;
      isLoadingTable.value = true;
      await StatsService.getUsersTableStats(selectedUsers, selectedCustomers);
      isLoadingTable.value = false;

      await UsersService.getUsersByFilters(selectedCustomerOwnerId.value);
      await CustomersService.getCustomersByFilters(
        selectedCustomerOwnerId.value
      );
    };

    const selectedCustomers = computed({
      get: () => store.state.userStats.selectedCustomers,
      set: async (val) => {
        store.commit("userStats/updateSelectedCustomers", val);
      },
    });
    const customersList = computed({
      get: () => store.state.customers.customersList,
    });
    const filteredCustomersList = ref(customersList.value);
    const customersMultiselect = ref(null);
    const usersMultiselect = ref(null);
    const setInput = () => {
      if (selectedCustomers.value.length > 0 && clicked) {
        clicked.value = false;
      }
      customersMultiselect.value.updateInputValue("");
      usersMultiselect.value.updateInputValue("");
    };

    const activeAccountsCount = computed({
      get: () => store.state.userStats.totalActiveUsersCount,
    });

    const usersWithoutProfileCount = computed({
      get: () => store.state.userStats.usersWithoutProfileCount,
    });
    const usersWithoutNewsletterCount = computed({
      get: () => store.state.userStats.usersWithoutNewsletterCount,
    });

    const customerOwnersList = computed({
      get: () => store.state.accountManagers.accountManagersBasicInfoList,
    });

    const selectedCustomerOwner = computed({
      get: () => store.state.userStats.selectedCustomerOwner,
      set: async (val) => {
        store.commit("userStats/updateSelectedCustomerOwner", val);
      },
    });

    const selectedCustomerOwnerId = computed({
      get: () => store.state.userStats.selectedCustomerOwnerId,
    });

    const lineGraph = ref(null);

    const usersList = computed({
      get: () => store.state.user.usersListDefaultEnv,
    });

    const filteredUsersList = ref(usersList.value);

    const selectedUsers = computed({
      get: () => store.state.userStats.selectedUsers,
      set: async (val) => {
        store.commit("userStats/updateSelectedUsers", val);
      },
    });

    const activeAccountsList = computed({
      get: () => store.state.userStats.activeAccountsList,
    });

    const noNewsLetterAccountsList = computed({
      get: () => store.state.userStats.noNewsLetterAccountsList,
    });
    const noProfileAccountsList = computed({
      get: () => store.state.userStats.noProfileAccountsList,
    });

    const updateModalWithData = (arr) => {
      if (arr && arr.length > 0) {
        store.commit("userStats/updateShowModal", true);
        store.commit("userStats/updateModalData", arr);
      }
    };

    const selectedGraphType = ref({
      id: 1,
      type: "Visits per user",
    });

    const graphTypeOptions = [
      { id: 1, type: "Visits per user" },
      { id: 2, type: "Scheme visits per user" },
      { id: 3, type: "News visits per user" },
    ];

    const changeGraph = async (type) => {
      if (isFilterSelected.value == true) {
        if (type.id == 1) {
          await StatsService.getGeneralUsersGraph(
            modelYearFrom,
            modelYearTo,
            modelMonthIdFrom,
            modelMonthIdTo,
            selectedUsers,
            selectedCustomerOwnerId,
            selectedCustomers
          );
        } else if (type.id == 2) {
          if (selectedUsers.value && selectedUsers.value.length > 0)
            await StatsService.getUserVsisitsStats(
              modelYearFrom,
              modelYearTo,
              modelMonthIdFrom,
              modelMonthIdTo,
              selectedUsers,
              selectedCustomerOwnerId
            );
        }
      }
    };

    const fetchGraphBasedOnType = async () => {
      isLoadingGraph.value = true;
      if (isFilterSelected.value == true) {
        if (selectedGraphType.value.id == 1) {
          await StatsService.getGeneralUsersGraph(
            modelYearFrom,
            modelYearTo,
            modelMonthIdFrom,
            modelMonthIdTo,
            selectedUsers,
            selectedCustomerOwnerId,
            selectedCustomers
          );
        } else if (selectedGraphType.value.id == 2) {
          await StatsService.getUserVsisitsStats(
            modelYearFrom,
            modelYearTo,
            modelMonthIdFrom,
            modelMonthIdTo,
            selectedUsers,
            selectedCustomerOwnerId,
            selectedCustomers
          );
        } else if (selectedGraphType.value.id == 3) {
          await StatsService.getUserNewsVsisitsStats(
            modelYearFrom,
            modelYearTo,
            modelMonthIdFrom,
            modelMonthIdTo,
            selectedUsers,
            selectedCustomerOwnerId,
            selectedCustomers
          );
        }
      }
      isLoadingGraph.value = false;
    };

    const selectedMonthsIds = computed({
      get: () => store.state.userStats.selectedMonthsIds,
    });

    const usersWithVisitList = computed({
      get: () => store.state.userStats.usersWithVisitList,
    });
    const usersWithVisitCount = computed({
      get: () => store.state.userStats.usersWithVisitInLastMonthCount,
    });

    const isFilterSelected = computed({
      get: () => store.state.userStats.isFilterSelected,
    });

    watch([selectedCustomers, selectedUsers, selectedCustomerOwnerId], () => {
      store.commit("userStats/updateIsFilterSelected");
    });

    watch(
      () => selectedUsers.value,
      async (next, prev) => {
        if (prev && prev.length == 0 && next.length == 1) {
          await CustomersService.getCustomerForUser(next[0]);
          await CustomersService.getCustomerOwnerForUser(next[0]);
        } else if (prev.length == 1 && next.length > 1) {
          await UsersService.getUsersByUser(
            selectedCustomers.value,
            selectedCustomerOwnerId.value
          );
        } else if (prev.length > 0 && (next == null || next.length == 0)) {
          await CustomersService.getAllCustomers("", "", "", false, 0, 5000);
          await CustomersService.getCustomerOwners();
        }
      }
    );
    const selectedMonthsIdsServer = computed({
      get: () => store.state.userStats.selectedMonthsIdsServer,
    });

    const modelYearFrom = computed({
      get: () => store.state.userStats.yearFrom,
      set: (val) => {
        store.commit("userStats/updateYearFrom", val);
        store.commit("userStats/updateYearsToOptions");
      },
    });

    const modelYearTo = computed({
      get: () => store.state.userStats.yearTo,
      set: (val) => {
        store.commit("userStats/updateYearTo", val);
      },
    });

    const modelMonthFrom = computed({
      get: () => store.state.userStats.monthFrom,
      set: (val) => {
        store.commit("userStats/updateMonthFrom", val);
      },
    });

    const modelMonthTo = computed({
      get: () => store.state.userStats.monthTo,
      set: async (val) => {
        store.commit("userStats/updateMonthTo", val);
      },
    });

    const modelMonthIdFrom = computed({
      get: () => store.state.userStats.monthFromId,
    });

    const modelMonthIdTo = computed({
      get: () => store.state.userStats.monthToId,
    });

    const optionsYearsFrom = computed({
      get: () => store.state.userStats.optionsYearsFrom,
    });

    const optionsYearsTo = computed({
      get: () => store.state.userStats.optionsYearsTo,
    });

    return {
      clicked,
      route,
      optionsYearsFrom,
      optionsYearsTo,
      modelMonthFrom,
      modelMonthTo,
      modelMonthIdFrom,
      modelMonthIdTo,
      modelYearTo,
      modelYearFrom,
      selectedMonthsIdsServer,
      usersMultiselect,
      isFilterSelected,
      usersWithVisitCount,
      usersWithVisitList,
      selectedMonthsIds,
      fetchGraphBasedOnType,
      changeGraph,
      fetchFilteredData,
      isLoadingGraph,
      isShowing,
      isLoadingTable,
      isLoadingBoxes,
      graphTypeOptions,
      selectedGraphType,
      updateModalWithData,
      noProfileAccountsList,
      noNewsLetterAccountsList,
      activeAccountsList,
      selectedUsers,
      usersList,
      lineGraph,
      selectedCustomerOwnerId,
      selectedCustomerOwner,
      customerOwnersList,
      usersWithoutNewsletterCount,
      activeAccountsCount,
      usersWithoutProfileCount,
      setInput,
      customersMultiselect,
      selectedCustomers,
      customersList,
      filteredCustomersList,
      filteredUsersList,
      optionsMonths: [
        { id: 0, name: "January" },
        { id: 1, name: "February" },
        { id: 2, name: "March" },
        { id: 3, name: "April" },
        { id: 4, name: "May" },
        { id: 5, name: "June" },
        { id: 6, name: "July" },
        { id: 7, name: "August" },
        { id: 8, name: "September" },
        { id: 9, name: "October" },
        { id: 10, name: "November" },
        { id: 11, name: "December" },
      ],
      filterCustomersFn(val, update) {
        update(() => {
          if (val === "") {
            filteredCustomersList.value = customersList.value;
          } else {
            const needle = val.toLowerCase();

            filteredCustomersList.value = customersList.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterUsersFn(val, update) {
        update(() => {
          if (val == "") {
            filteredUsersList.value = usersList.value;
          } else {
            const needle = val.toLowerCase();

            filteredUsersList.value = usersList.value.filter(
              (v) => v.username.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
    };
  },
  methods: {
    isUnderlined(buttonRoute) {
      if (this.route.fullPath.includes(buttonRoute))
        return "btn-nav-underlined";
      return "";
    },
  },
};
</script>
