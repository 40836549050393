<template>
  <div class="q-pa-md">
    <q-toolbar class="bg-primary text-white">
      <i
        class="las la-arrow-circle-left la-2x back-arrow"
        @click="router.go(-1)"
      >
        <q-tooltip>
          Go to previous page
        </q-tooltip>
      </i>

      <q-toolbar-title>
        Edit customer
        <i class="las la-angle-double-down"></i>
      </q-toolbar-title>
    </q-toolbar>
    <CustomersEditTabs :id="customerId" />
  </div>
</template>
<script>
import CustomersEditTabs from './CustomersEditTabs.vue';
import { useRoute } from 'vue-router';
import router from '../../router/index';
export default {
  name: 'EditCustomer',
  components: {
    CustomersEditTabs,
  },
  setup() {
    const route = useRoute();
    const customerId = route.params.id;

    return {
      route,
      customerId,
      router,
    };
  },
};
</script>
