<template>
  <q-dialog v-model="showEditDocumentDialog" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div>Edit document</div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none">
        <div class="row justify-around">
          <q-input
            dense
            v-model="editDocumentName"
            label="Name"
            stack-label
            :rules="requiredFieldRule"
            lazy-rules
          />
        </div>
        <q-select
          outlined
          bg-color="white"
          v-model="editDocumentDocumentType"
          :options="documentTypes"
          option-value="id"
          option-label="description"
          stack-label
          label="Document type"
          dense
          emit-value
          map-options
        />
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          color="primary"
          label="Save"
          @click="editDocument(editDocumentName, editDocumentDocumentType)"
          :disable="editDocumentName != '' ? null : true"
        />
        <q-btn
          color="primary"
          outline
          label="Cancel"
          @click="disableEditDocument"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed } from 'vue';
import store from '../../store/index';
import schemesServices from '../../services/schemes.services';
import { useRoute } from 'vue-router';

export default {
  name: 'EditDocument',
  setup() {
    const route = useRoute();

    const showEditDocumentDialog = computed({
      get: () => store.state.scheme.showEditDocument,
      set: (value) => store.commit('scheme/updateShowEditDocument', value),
    });

    const documentTypes = computed({
      get: () => store.state.scheme.documentTypes,
    });
    
    const selectedDocument = computed({
      get: () => store.state.scheme.selectedDocument,
    });

    const editDocumentName = computed({
      get: () => store.state.scheme.selectedDocumentName,
      set: (value) => store.commit('scheme/updateSelectedDocumentName', value),
    });

    const editDocumentDocumentType = computed({
      get: () => store.state.scheme.selectedDocumentDocumentType,
      set: (value) => store.commit('scheme/updateSelectedDocumentDocumentType', value),
    });

    const editDocument = async (docName, docType) => {
      if (selectedDocument.value.id !== undefined)
        await schemesServices.updateDocument(route.params.id, selectedDocument.value.id, docName, docType);
      else {
        selectedDocument.value.name = docName;
        selectedDocument.value.documentType = docType;
        selectedDocument.value.documentTypeDescription = docType ? documentTypes.value.find(({ id }) => id === docType).description : '';
      }

      disableEditDocument();
    };

    const disableEditDocument = () => {
      store.commit('scheme/setSelectedDocument', null)
      showEditDocumentDialog.value = false;
    };

    return {
      editDocumentName,
      editDocumentDocumentType,
      selectedDocument,
      showEditDocumentDialog,
      disableEditDocument,
      editDocument,
      documentTypes,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || 'Field is required!',
      ],
    }
  },
};
</script>
