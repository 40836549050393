<template>
    <div class="keyword-search-table">
      <q-table
        class="my-sticky-header-table"
        :rows="tableStatsRows.keywords"
        :columns="columns"
        row-key="name"
        flat
        bordered
        :separator="'cell'"
        :pagination="pagination"
        style="max-heigt: 300px"
        virtual-scroll
        :virtual-scroll-sticky-size-start="30"
        :virtual-scroll-sticky-size-end="30"
        binary-state-sort
        :rows-per-page-options="[]"
      />
    </div>
  </template>
  
  <script>
  import store from '../../../store/index';
  import { computed } from 'vue';
  
  export default {
    setup() {
      const tableStatsRows = computed({
        get: () => store.state.consumptionStats.keywordSerarchTableData,
      });
  
      const columns = [
        {
          name: 'keyword',
          field: 'keyword',
          label: 'Keyword',
          align: 'left',
        },
        {
          name: 'searchCount',
          label: 'Top searches in last 60 days',
          field: 'searchCount',
          align: 'left',
          sortable: true,
        },
      ];
  
      return {
        columns,
        tableStatsRows,
        pagination: {
          rowsPerPage: 500,
          page: 1,
        },
      };
    },
  };
  </script>
  
  <style lang="sass">
  .my-sticky-header-table
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th
      background-color: #25408f
  
    thead tr th
      position: sticky
      z-index: 1
    thead tr:first-child th
      top: 0
  
    &.q-table--loading thead tr:last-child th
      top: 48px
  </style>
  