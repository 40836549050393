const state = () => ({
  orderUp: false,
  orderDown: false,
  showEdit: false,
  showAdd: false,
  showCreate: false,
  selectedNode: 0,
  treeNodes: [],
  treeLabel: '',
  newNodeLabel: '',
  newNodeOrder: 0,
  editedNodeLabel: '',
  editedNodeOrder: 0,
  modalErrorMessage: '',
  hasModalError: false,
  tagName: '',
  tagOrder: 0,
  isHiddenTag: false,
  totalNodes: 0,
  nodeIndex: [{}],
  selectedNodeAndParent: {},
  selectedNodeName: '',
  showCreateLocationGroup: false,
  showEditGroupTag: false,
  nodeIndexLocations: [{}],
  treeNodesLocationGroup: [],
  totalLocationGroupNodes: 0,
  tickedLocationGroups:[],
  treeNodesLocationGroupLabel: 'Location Groups',  
  locationGroupTreeList: [[{}]],
  editSelectedTreeGroupId: 0,
  groupObjectData:[{}],
  groupDataNames: [],
  locationGroupsSelected: [],
  //German tags:
  treeNodesDe: [],
  totalNodesDe: 0,
  languageVersion: 'nl'
});

const getters = {};

const actions = {};

const mutations = {
  toggleEdit(state) {
    state.showEdit = !state.showEdit;
  },
  enableEdit(state, languageCode) {
    state.showEdit = true;
    state.languageVersion = languageCode;
  },
  disableEdit(state) {
    state.showEdit = false;
  },
  toggleCreate(state) {
    state.showCreate = !state.showCreate;
  },
  enableCreate(state, languageCode) {
    state.showCreate = true;
    state.languageVersion = languageCode;
  },
  disableCreate(state) {
    state.showCreate = false;
  },
  enableCreateLocationGroup(state){
    state.showCreateLocationGroup = true;
  },
  disableCreateLocationGroup(state){
    state.showCreateLocationGroup = false;
  },
  enableEditGroupTag(state){
    state.showEditGroupTag = true;
  },
  disableEditGroupTag(state){
    state.showEditGroupTag = false;
  },
  orderUp(state) {
    state.orderUp = true;
  },
  orderDown(state) {
    state.orderDown = true;
  },
  disableOrderUp(state) {
    state.orderUp = false;
  },
  disableOrderDown(state) {
    state.orderDown = false;
  },
  toggleAdd(state) {
    state.showAdd = !state.showAdd;
  },
  enableAdd(state, languageCode) {
    state.showAdd = true;
    state.languageVersion = languageCode;
  },
  disableAdd(state) {
    state.showAdd = false;
  },
  setLocationGroupsSelected(state, val){
     state.locationGroupsSelected = val;
  },
  setSelectedNode(state, node) {
    state.selectedNode = node;

    for (let i = 0; i < state.treeNodes.length; i++) {
      if (state.treeNodes[i].children.length > 0) {
        for (let j = 0; j < state.treeNodes[i].children.length; j++) {
          if (state.treeNodes[i].children[j].id == node) {
            state.selectedNodeName = state.treeNodes[i].children[j].label;
            break;
          }
        }
      }
      if (state.treeNodes[i].id == node) {
        state.selectedNodeName = state.treeNodes[i].label;
        break;
      }
    }
  },
  setSelectedNodeDe(state, node) {
    state.selectedNode = node;

    for (let i = 0; i < state.treeNodesDe.length; i++) {
      if (state.treeNodesDe[i].children.length > 0) {
        for (let j = 0; j < state.treeNodesDe[i].children.length; j++) {
          if (state.treeNodesDe[i].children[j].id == node) {
            state.selectedNodeName = state.treeNodesDe[i].children[j].label;
            break;
          }
        }
      }
      if (state.treeNodesDe[i].id == node) {
        state.selectedNodeName = state.treeNodesDe[i].label;
        break;
      }
    }
  },

  disableAllBoxes(state) {
    state.showCreate = false;
    state.showEdit = false;
    state.showAdd = false;
  },

  updateTree(state, nodes) {
    state.nodeIndex = [];
    state.treeNodes = nodes.allActivities.options;
    state.treeLabel = nodes.allActivities.label;
    for (let i = 0; i < state.treeNodes.length; i++) {
      state.totalNodes++;
      if (state.treeNodes[i].children.length > 0) {
        for (let j = 0; j < state.treeNodes[i].children.length; j++) {
          for (let k = 0; k < state.treeNodes[i].children[j].length; k++) {
            state.totalNodes++;
          }
          state.totalNodes++;
        }
      }
    }

    var counter = 0;

    for (let i = 0; i < state.treeNodes.length; i++) {
      if (
        typeof state.treeNodes[i].label != undefined &&
        state.treeNodes[i].label != undefined
      )
        state.nodeIndex[i] = {
          label: state.treeNodes[i].label,
          index: counter,
          id: state.treeNodes[i].id,
          parent: 0,
          children: [],
        };
      counter++;
      if (state.treeNodes[i].children.length > 0) {
        for (let j = 0; j < state.treeNodes[i].children.length; j++) {
          var subChildren = [];
          for (
            let k = 0;
            k < state.treeNodes[i].children[j].children.length;
            k++
          ) {
            if (
              state.treeNodes[i].children[j].children != undefined &&
              state.treeNodes[i].children[j].children != null
            ) {
              subChildren.push({
                label: state.treeNodes[i].children[j].children[k].label,
                id: state.treeNodes[i].children[j].children[k].id,

                index: i + j + k + 2,
                parent: state.treeNodes[i].children[j],
              });
            }
          }

          if (j > 0 && state.treeNodes[i].children[j - 1].length > 0)
            var parentCounter =
              i + j + 1 + state.treeNodes[i].children[j - 1].length;
          else parentCounter = i + j + 1;

          state.nodeIndex[i].children.push({
            label: state.treeNodes[i].children[j].label,
            id: state.treeNodes[i].children[j].id,
            index: parentCounter,
            parent: state.treeNodes[i],
            children: subChildren,
          });
        }
      }
    }
  },
  updateTreeDe(state, nodes) {
    state.nodeIndex = [];
    state.treeNodesDe = nodes.allActivities.options;
    state.treeLabel = nodes.allActivities.label;
    for (let i = 0; i < state.treeNodesDe.length; i++) {
      state.totalNodesDe++;
      if (state.treeNodesDe[i].children.length > 0) {
        for (let j = 0; j < state.treeNodesDe[i].children.length; j++) {
          for (let k = 0; k < state.treeNodesDe[i].children[j].length; k++) {
            state.totalNodesDe++;
          }
          state.totalNodesDe++;
        }
      }
    }

    var counter = 0;

    for (let i = 0; i < state.treeNodesDe.length; i++) {
      if (
        typeof state.treeNodesDe[i].label != undefined &&
        state.treeNodesDe[i].label != undefined
      )
        state.nodeIndex[i] = {
          label: state.treeNodesDe[i].label,
          index: counter,
          id: state.treeNodesDe[i].id,
          parent: 0,
          children: [],
        };
      counter++;
      if (state.treeNodesDe[i].children.length > 0) {
        for (let j = 0; j < state.treeNodesDe[i].children.length; j++) {
          var subChildren = [];
          for (
            let k = 0;
            k < state.treeNodesDe[i].children[j].children.length;
            k++
          ) {
            if (
              state.treeNodesDe[i].children[j].children != undefined &&
              state.treeNodesDe[i].children[j].children != null
            ) {
              subChildren.push({
                label: state.treeNodesDe[i].children[j].children[k].label,
                id: state.treeNodesDe[i].children[j].children[k].id,

                index: i + j + k + 2,
                parent: state.treeNodesDe[i].children[j],
              });
            }
          }

          if (j > 0 && state.treeNodesDe[i].children[j - 1].length > 0)
            var parentCounter =
              i + j + 1 + state.treeNodesDe[i].children[j - 1].length;
          else parentCounter = i + j + 1;

          state.nodeIndex[i].children.push({
            label: state.treeNodesDe[i].children[j].label,
            id: state.treeNodesDe[i].children[j].id,
            index: parentCounter,
            parent: state.treeNodesDe[i],
            children: subChildren,
          });
        }
      }
    }
  },
 
   
  updateLocationGroupTree(state, nodes) {
    state.nodeIndexLocations = [];
    state.treeNodesLocationGroup = nodes.options;
    state.treeNodesLocationGroupLabel = nodes.label;
    state.totalLocationGroupNodes = 0; // Reset the counter

    // Define a recursive function to calculate totalLocationGroupNodes and build nodeIndexLocations
    const processNode = (node, parentIndex) => {
        const index = state.totalLocationGroupNodes++;
        state.nodeIndexLocations[index] = {
            label: node.label,
            index,
            id: node.id,
            parent: parentIndex,
            children: [],
        };
        if (node.children && node.children.length > 0) {
            node.children.forEach((child) => processNode(child, index));
        }
    };

    // Process each top-level node
    state.treeNodesLocationGroup.forEach((node) => processNode(node, 0));

    // Build nodeIndexLocations for children of each node
    const buildNodeIndexChildren = (node, parentIndex) => {
        if (node.children && node.children.length > 0) {
            node.children.forEach((child) => {
                const childIndex = state.nodeIndexLocations[parentIndex].children.length;
                const childrenIndices = [];
                if (child.children && child.children.length > 0) {
                    child.children.forEach((subChild) => {
                        const subChildIndex = state.totalLocationGroupNodes++;
                        childrenIndices.push(subChildIndex);
                        state.nodeIndexLocations[subChildIndex] = {
                            label: subChild.label,
                            index: subChildIndex,
                            id: subChild.id,
                            parent: childIndex,
                            children: [],
                        };
                    });
                }
                state.nodeIndexLocations[parentIndex].children.push({
                    label: child.label,
                    id: child.id,
                    index: childIndex,
                    parent: parentIndex,
                    children: childrenIndices,
                });
                buildNodeIndexChildren(child, childIndex);
            });
        }
    };
    // Build nodeIndexLocations for children of each top-level node
    state.treeNodesLocationGroup.forEach((node, index) => buildNodeIndexChildren(node, index));
},
  
  
  updateLocationGroupTrees(state, trees){
    state.locationGroupTreeList = trees.length > 0 ? trees.map(tree => [tree]) : [];
   },
   
   updateLocationTickedTags(state,ticked){
    state.tickedLocationGroups = ticked;
   },
   updateSelectedTreeGroupId(state,id){
    state.editSelectedTreeGroupId = id;
   },
  fillTagDetails(state, node) {
    state.tagName = node.name;
    state.tagOrder = node.order;
    state.isHiddenTag = node.isHidden;
  },

  updateOrder(state, node) {
    state.tagOrder = node.order;
    state.orderUp = false;
    state.orderDown = false;
  },

  updateTagName(state, tagName) {
    state.tagName = tagName;
  },

  updateTagOrder(state, tagOrder) {
    state.tagOrder = tagOrder;
  },
  updateIsHiddenTag(state, isHidden) {
    state.isHiddenTag = isHidden;
  },
  resetModalError(state) {
    state.modalErrorMessage = '';
  },

  enableModalError(state) {
    state.hasModalError = true;
  },
  disableModalError(state) {
    state.hasModalError = false;
  },
  updateModalError(state, message) {
    state.orderUp = false;
    state.orderDown = false;
    state.modalErrorMessage = message;
  },

  updateNodeAndParent(state, selected) {
    for (let i = 0; i < state.treeNodes.length; i++) {
      if (state.nodeIndex[i].id == selected) {
        state.selectedNodeAndParent = {
          nodeId: state.nodeIndex[i].id,
          parent:
            state.nodeIndex[i].parent == 0 ? 0 : state.nodeIndex[i].parent.id,
        };
        break;
      } else if (state.nodeIndex[i].children.length > 0) {
        for (let j = 0; j < state.nodeIndex[i].children.length; j++) {
          if (state.nodeIndex[i].children[j].id == selected) {
            state.selectedNodeAndParent = {
              nodeId: state.nodeIndex[i].children[j].id,
              parent: state.nodeIndex[i].children[j].parent,
            };
            break;
          }
        }
      }
    }
  },
  updateNodeAndParentDe(state, selected) {
    for (let i = 0; i < state.treeNodesDe.length; i++) {
      if (state.nodeIndex[i].id == selected) {
        state.selectedNodeAndParent = {
          nodeId: state.nodeIndex[i].id,
          parent:
            state.nodeIndex[i].parent == 0 ? 0 : state.nodeIndex[i].parent.id,
        };
        break;
      } else if (state.nodeIndex[i].children.length > 0) {
        for (let j = 0; j < state.nodeIndex[i].children.length; j++) {
          if (state.nodeIndex[i].children[j].id == selected) {
            state.selectedNodeAndParent = {
              nodeId: state.nodeIndex[i].children[j].id,
              parent: state.nodeIndex[i].children[j].parent,
            };
            break;
          }
        }
      }
    }
  },
  setGroupObjectData(state,group){
    state.groupObjectData = group;
    state.groupDataNames = group.map(item => item.groupName);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
