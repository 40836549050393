<template>
  <div class="tree-select-tags">
    <!--     <q-spinner-dots v-if="areTagsLoading" color="primary" size="2em" />
 -->
    <div style="min-width: 280px; width: 27vw">
      <div class="tree q-mt-lg">
        <div class="row">
          <span
            class="filter-box-heading"
            v-text="translateTabType(nodes.label)"
          ></span>
          <q-space></q-space>
          <i
            class="las la-2x la-times-circle clear-icon on-right"
            @click="resetSelectedNodes()"
          >
            <q-tooltip>
              Clear selected tags
            </q-tooltip>
          </i>
          <i
            class="las la-2x la-check-square clear-icon on-right"
            @click="selectAllNodes()"
          >
            <q-tooltip>
              Select all tags
            </q-tooltip>
          </i>
        </div>
        <q-input
          dense
          outlined
          v-model="filterNodes"
          label="Search"
          class="tree-searchbox"
        >
        </q-input>
        <div class="test">
          <q-scroll-area
            :thumb-style="thumbStyle"
            visible
            class="tree-scroll-area"
          >
            <q-tree
              ref="tree"
              :filter="filterNodes"
              :filter-method="myFilterMethod"
              :nodes="nodes.options"
              node-key="id"
              tick-strategy="leaf"
              v-model:ticked="tickedNodes"
              v-model:expanded="expandedNodes"
              v-model:selected="selectedNodes"
              @update:ticked="updatePairs(tickedNodes)"
              class="default-tree"
              no-transition
            />
          </q-scroll-area>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import store from "../store/index";
import { useVModel } from "../composables/useVModel.js";
import translateTabType from "../helpers/translate.js";

export default {
  name: "TreeSelectTags",
  props: {
    treeNodes: Object,
    pairs: Array,
    tagType: String,
    locationGroupsSelected: Array,
  },
  setup(props) {
    const nodes = ref(props.treeNodes);
    const tree = ref(null);
    const filterNodes = ref("");
    const type = ref(props.tagType);
    const expandedNodes = ref([]);
    const selectedNodes = ref([]);
    const groupType = ref("");
    const uniqueFilteredListIds = ref([]);
    watch(
      () => props.locationGroupsSelected,
      (newValue, oldValue) => {
        const removedItems = oldValue.filter(
          (item) => !newValue.includes(item)
        );
        updateLocationTicks(newValue, removedItems);
      }
    );

    const pairsState = ref(useVModel(props, "pairs"));
    const tickedNodes = ref(pairsState.value.map((el) => el.id));

    const updateLocationTicks = (groupNames, removedItems) => {
      const groups = store.state.tree.groupObjectData;

      const groupNamesSet = new Set(groupNames);
      const removedItemsSet = new Set(removedItems);

      const filteredGroups = groups.filter((group) =>
        groupNamesSet.has(group.groupName)
      );
      const toRemoveIDs = groups.filter((group) =>
        removedItemsSet.has(group.groupName)
      );

      // Use a Set for unique ids
      const uniqueFilteredListIds = new Set(
        filteredGroups.flatMap((group) => group.selectedTagsIds)
      );

      // Untick nodes associated with removed location group from select
      if (removedItems.length > 0) {
        const toRemoveIDsList = new Set(
          toRemoveIDs.flatMap((group) => group.selectedTagsIds)
        );

        tickedNodes.value = tickedNodes.value.filter(
          (id) => !toRemoveIDsList.has(id)
        );
      }

      // Add values to tickedNodes
      uniqueFilteredListIds.forEach((id) => {
        if (!tickedNodes.value.includes(id)) {
          tickedNodes.value.push(id);
        }
      });

      updatePairs(tickedNodes.value);

      // Disabling nodes that are checked from location group but only disabling node if none of its children are disabled
      nodes.value.options.forEach((option) => {
        const isInFilteredList = uniqueFilteredListIds.has(option.id);
        if (option.children.length > 0) {
          option.children.forEach((child) => {
            if (child.children) {
              child.children.forEach((grandchild) => {
                grandchild.disabled = uniqueFilteredListIds.has(grandchild.id);
              });
            } else {
              child.disabled = true;
            }
          });
        } else {
          option.disabled = isInFilteredList;
        }
      });
    };

    const updatePairs = (keys) => {
      var typeOfTag = props.tagType + "NewTags";
      sessionStorage.setItem(typeOfTag, []);
      // session storage clear (for this tag type)
      pairsState.value.length = 0;
      if (keys.length === 0) {
        store.commit(
          "scheme/update" + `${type.value}` + "Count",
          pairsState.value.length
        );
        return; // No keys to process
      }
      const checkedKeys = new Set(keys);
      const processNode = (node) => {
        if (!node.children || node.children.length === 0) {
          if (checkedKeys.has(node.id)) {
            pairsState.value.push(node);
          }
          return checkedKeys.has(node.id);
        }
        let allChildrenTicked = true;
        for (const child of node.children) {
          const childTicked = processNode(child);
          if (!childTicked) {
            allChildrenTicked = false;
          }
        }
        if (allChildrenTicked) {
          pairsState.value.push(node);
          return true;
        } else {
          const index = pairsState.value.indexOf(node);
          if (index !== -1) {
            pairsState.value.splice(index, 1);
          }
          return false;
        }
      };
      nodes.value.options.forEach((node) => {
        processNode(node);
      });
      store.commit(
        "scheme/update" + `${type.value}` + "Count",
        pairsState.value.length
      );
      // set session storage to pairstate.value
      sessionStorage.setItem(typeOfTag, JSON.stringify(pairsState.value));
    };

    const selectAllNodes = () => {
      tickedNodes.value = [];
      const toTick = new Set();
      for (const node of nodes.value.options) {
        toTick.add(node.id);
        if (node.children) {
          for (const child of node.children) {
            toTick.add(child.id);
            if (child.children) {
              for (const subChild of child.children) {
                toTick.add(subChild.id);
              }
            }
          }
        }
      }
      tickedNodes.value = [...toTick];
      updatePairs(tickedNodes.value);
    };
    const resetSelectedNodes = () => {
      tickedNodes.value = [];
      updatePairs(tickedNodes.value);
    };

    return {
      /*areTagsLoading,*/
      translateTabType,
      nodes,
      filterNodes,
      tickedNodes,
      pairsState,
      updatePairs,
      tree,
      expandedNodes,
      selectedNodes,
      selectAllNodes,
      resetSelectedNodes,
      type,
      uniqueFilteredListIds,
      updateLocationTicks,
      groupType,
      myFilterMethod(node, filter) {
        const filt = filter.toLowerCase();
        return node.label && node.label.toLowerCase().indexOf(filt) > -1;
      },
    };
  },
};
</script>
