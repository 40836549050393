<template>
  <q-dialog v-model="show" persistent class="modal-general">
    <q-card style="min-width: 350px">
      <q-card-section style="text-align: center">
        <span class="add-label bold-font"
          >Add child/parent organisation to {{ selectedOrganisationName }}</span
        >
      </q-card-section>

      <q-separator />
      <q-card-section class="q-pt-none">
        <div class="add-org-box column">
          <div class="column">
            <div class="row justify-around">
              <q-input
                v-model="newNodeLabel"
                placeholder="Enter name"
                dense
                bg-color="white"
                borderless
                style="padding-top: 12px; width: 280px"
              />
            </div>
            <div class="row justify-around">
              <q-input
                v-model="newNodeEmail"
                placeholder="Enter email"
                dense
                bg-color="white"
                borderless
                style="padding-top: 12px; width: 280px"
              />
            </div>
            <div class="row justify-around">
              <q-input
                v-model="newNodePhone"
                placeholder="Enter phone"
                dense
                bg-color="white"
                borderless
                style="padding-top: 12px; width: 280px"
              />
            </div>
            <div class="row justify-around">
              <q-input
                v-model="newNodeUrl"
                placeholder="Enter website"
                dense
                bg-color="white"
                borderless
                style="padding-top: 12px; width: 280px"
              />
            </div>
          </div>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          no-caps
          class="orange-btn-filled bold-font"
          label="Add as child"
          @click="addChild()"
        />
        <q-btn
          no-caps
          label="Add as parent"
          class="orange-btn-filled bold-font"
          @click="addParent()"
        />
        <q-btn
          no-caps
          label="Cancel"
          class="orange-btn bold-font"
          @click="disableAdd()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed } from 'vue';
import store from '../../store/index.js';
import OrganisationsService from '../../services/organisations.service';

export default {
  props: {
    showAdd: Boolean,
  },
  setup(props) {
    const show = ref(props.showAdd);

    const disableAdd = () => {
      store.commit('organisation/disableAddOrganisation');
      OrganisationsService.getOrganisationsTree();
    };

    const newNodeLabel = ref('');
    const newNodeEmail = ref('');
    const newNodePhone = ref('');
    const newNodeUrl = ref('');

    const selectedOrganisationName = computed({
      get: () => store.state.organisation.selectedOrganisationName,
    });

    const OrganisationID = computed({
      get: () => store.state.organisation.OrganisationID,
    });

    const parentNode = computed({
      get: () => store.state.organisation.selectedNode,
    });

    const addChild = async () => {
      await OrganisationsService.addChild(
        newNodeLabel,
        newNodeEmail,
        newNodeUrl,
        newNodePhone,
        OrganisationID
      );
      disableAdd();
    };

    const addParent = async () => {
      await OrganisationsService.addParent(
        newNodeLabel,
        newNodeEmail,
        newNodeUrl,
        newNodePhone,
        OrganisationID
      );
      disableAdd();
    };
    return {
      OrganisationID,
      show,
      addParent,
      parentNode,
      disableAdd,
      newNodeLabel,
      newNodeEmail,
      newNodePhone,
      newNodeUrl,
      addChild,
      selectedOrganisationName,
    };
  },
};
</script>
