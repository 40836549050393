<template>
  <ErrorModal />
  <div class="q-pa-md">
    <q-table
      class="sticky-header"
      table-header-style="background-color: #0a1f8f"
      table-header-class="text-white"
      title="Account managers"
      :rows="accountManagersList"
      :rows-per-page-options="[15, 0]"
      :columns="columns"
      row-key="id"
      :filter="filter"
      :dense="$q.screen.lt.md"
    >
      <template v-slot:top-left>
      </template>
      <template v-slot:top-right>
        <q-input
          borderless
          dense
          debounce="300"
          placeholder="Search"
          v-model="filter"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="fullName" :props="props">{{ props.row.fullName }}</q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import ErrorModal from '../components/account-managers-components/ErrorModal.vue';
import { ref, onMounted, computed } from 'vue';
import AccountManagersService from '../services/account.managers.service';
import store from '../store/index.js';

const columns = [
  {
    name: 'fullName',
    label: 'Full name',
    align: 'left',
    sortable: true,
    field: (row) => row.fullName,
  },
];

export default {
  name: "AccountManagersGrid",
  components: {
    ErrorModal
  },
  setup() {
    const filter = ref('');

    onMounted(async () => {
      await AccountManagersService.getAccountManagersWithBasicInfo();
    });

    const accountManagersList = computed({
      get: () => store.state.accountManagers.accountManagersBasicInfoList,
    });

    return {
      columns,
      accountManagersList,
      filter,
    };
  },
};
</script>
