<template>
  <ErrorModal />
  <div class="q-pa-md">
    <q-table
      class="sticky-header"
      table-header-style="background-color: #0a1f8f"
      table-header-class="text-white"
      title="Visualping links"
      :rows="visualPingLinks"
      :columns="columns"
      row-key="id"
      :filter="filter"
      :loading="loading"
      :dense="$q.screen.lt.md"
      v-model:pagination="pagination"
      @request="onRequest"
      :filter-method="customFilter"
      binary-state-sort
    >
      <template v-slot:loading>
        <q-inner-loading showing color="primary"></q-inner-loading>
      </template>
      <template v-slot:top>
        <div>
          <div class="row q-pt-md">
            <q-btn
              :disable="loading"
              label="Add new Visualping link"
              @click="showAddVisualPingLink = true"
              dense
              no-caps
              class="orange-btn-filled bold-font"
            >
              <i class="las la-2x la-plus-circle la-fw"></i>
            </q-btn>
          </div>
          <div class="row q-pt-md">
            <span style="color: #25408f; font-size: small;">
              <i class="las la-info-circle"></i>
              Click a link text to see the unchecked changes that are detected
            </span>
          </div>
        </div>
        <q-space></q-space>

        <q-toggle
          v-model="filter.showUnchangedLinks"
          val="showGreenLinks"
          label="Show unchanged links"
          size="xs"
          color="green-13"
        />
        <q-toggle
          v-model="filter.showChangedLinks"
          val="inactive"
          label="Show changed links"
          size="xs"
          color="orange-14"
        />
        <q-toggle
          v-model="filter.showBrokenLinks"
          val="showBrokenLinks"
          label="Show broken links"
          size="xs"
          color="red-6"
        />
        <q-space></q-space>
        <q-input
          borderless
          dense
          debounce="600"
          placeholder="Search"
          v-model="filter.searchTerm"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="statusColor" :props="props">
            <q-icon
              name="las la-square"
              size="xs"
              :color="props.row.statusColor"
            />
          </q-td>
          <q-td key="url" :props="props">
            <span>
              {{ props.row.url }}
              <q-tooltip anchor="top end" self="top end">{{
                props.row.url
              }}</q-tooltip>
            </span>
          </q-td>
          <q-td key="text" :props="props">
            <span
              class="cursor-pointer"
              @click="
                selectedLinkId = props.row.id;
                showLinkChanges = true;
              "
            >
              {{ props.row.text }}
              <q-tooltip anchor="top end" self="top end">{{
                props.row.text
              }}</q-tooltip>
            </span>
          </q-td>
          <q-td key="schemeName" :props="props">
            <span>
              {{ props.row.schemeName }}
              <q-tooltip anchor="top end" self="top end">{{
                props.row.schemeName
              }}</q-tooltip>
            </span>
          </q-td>
          <q-td key="jobEnabled" :props="props">
            <q-toggle
              v-model="props.row.jobEnabled"
              checked-icon="check"
              color="green"
              unchecked-icon="clear"
              size="xs"
              @update:model-value="
                (value) => toggleVPJobStatus(value, props.row.jobId)
              "
            />
          </q-td>
          <q-td key="actions" :props="props" class="q-gutter-xs">
            <div class="q-gutter-xs">
              <q-btn
                color="red"
                size="12px"
                flat
                dense
                round
                icon="delete"
                @click="removeVisualPingLink(props.row.id)"
              >
                <q-tooltip>
                  Remove link
                </q-tooltip>
              </q-btn>
              <q-btn
                @click="
                  showEditVisualPingLink = true;
                  hideUrlField = true;
                  selectedVpLinkToEdit = props.row;
                "
                dense
                size="12px"
                round
                flat
                color="indigo-10"
                icon="las la-edit"
              >
                <q-tooltip>
                  Rename link
                </q-tooltip>
              </q-btn>
              <q-btn
                @click="
                  showEditVisualPingLink = true;
                  hideUrlField = false;
                  selectedVpLinkToEdit = props.row;
                "
                dense
                size="12px"
                round
                flat
                color="indigo-10"
                icon="las la-pencil-alt"
              >
                <q-tooltip>
                  Edit link
                </q-tooltip>
              </q-btn>
              <q-btn
                :href="props.row.url"
                target="_blank"
                type="a"
                dense
                size="12px"
                round
                flat
                color="indigo-10"
                icon="las la-link"
                style="text-decoration: none"
              >
                <q-tooltip>
                  Go to link
                </q-tooltip>
              </q-btn>
              <q-btn
                :href="'https://visualping.io/jobs/' + props.row.jobId"
                target="_blank"
                type="a"
                dense
                size="12px"
                round
                flat
                color="indigo-10"
                icon="las la-external-link-alt"
                style="text-decoration: none"
              >
                <q-tooltip>
                  Go to link on Visualping
                </q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <AddVisualPingLink v-if="showAddVisualPingLink"
     :selectedSchemeId="null"
     :searchFilter="filter.searchTerm" 
     :toggleUnchangedLinks="filter.showUnchangedLinks"
     :toggleChangedLinks="filter.showChangedLinks"
     :toggleBrokenLinks="filter.showBrokenLinks" />
    <EditVisualPingLink
      v-if="showEditVisualPingLink"
      :selectedSchemeId="null"
      :hideUrlField="hideUrlField"
      :searchFilter="filter.searchTerm" 
      :toggleUnchangedLinks="filter.showUnchangedLinks"
      :toggleChangedLinks="filter.showChangedLinks"
      :toggleBrokenLinks="filter.showBrokenLinks"
    />
    <LinkChanges :selectedLinkId="selectedLinkId"
      :searchFilter="filter.searchTerm" 
      :toggleUnchangedLinks="filter.showUnchangedLinks"
      :toggleChangedLinks="filter.showChangedLinks"
      :toggleBrokenLinks="filter.showBrokenLinks" v-if="showLinkChanges" />
  </div>
</template>

<script>
import ErrorModal from './ErrorModal.vue';
import { ref, reactive, onMounted, computed } from 'vue';
import LinkcheckerService from '../../services/linkchecker.service';
import store from '../../store/index.js';
import AddVisualPingLink from '../schemes-components/AddVisualPingLink.vue';
import EditVisualPingLink from '../schemes-components/EditVisualPingLink.vue';
import LinkChanges from './LinkChanges.vue';
import linkcheckerService from '../../services/linkchecker.service';

const columns = [
  {
    name: 'statusColor',
    label: 'Status',
    align: 'left',
    sortable: true,
    field: (row) => row.statusColor,
  },
  {
    name: 'url',
    label: 'Url',
    align: 'left',
    sortable: false,
    field: (row) => row.url,
    style: 'max-width: 25vw',
    classes: 'ellipsis',
    headerStyle: 'max-width: 25vw',
    headerClasses: 'ellipsis',
  },
  {
    name: 'text',
    label: 'Text',
    align: 'left',
    sortable: true,
    field: (row) => row.text,
    style: 'max-width: 25vw',
    classes: 'ellipsis',
    headerStyle: 'max-width: 25vw',
    headerClasses: 'ellipsis',
  },
  {
    name: 'schemeName',
    label: 'Scheme',
    align: 'left',
    sortable: true,
    field: (row) => row.schemeName,
    style: 'max-width: 25vw',
    classes: 'ellipsis',
    headerStyle: 'max-width: 25vw',
    headerClasses: 'ellipsis',
  },
  {
    name: 'jobEnabled',
    label: 'Job enabled',
    align: 'center',
    sortable: true,
    field: (row) => row.jobEnabled,
  },
  {
    name: 'actions',
    align: 'center',
    label: 'Actions',
    field: 'actions',
  },
];

export default {
  name: 'VisualPingLinks',
  components: {
    ErrorModal,
    AddVisualPingLink,
    EditVisualPingLink,
    LinkChanges,
  },
  setup() {
    const loading = ref(false);
    const hideUrlField = ref(false);
    const selectedLinkId = ref(null);

    const filter = reactive({
      searchTerm: '',
      showUnchangedLinks: true,
      showChangedLinks: true,
      showBrokenLinks: true,
    });

    onMounted(async () => {
      await LinkcheckerService.getVisualPingLinks(
        filter,
        filter.showUnchangedLinks,
        filter.showChangedLinks,
        filter.showBrokenLinks,
        pagination.value.sortBy,
        pagination.value.descending,
        pagination.value.page,
        getFetchCount()
      );
    });

    const visualPingLinks = computed({
      get: () => store.state.linkChecker.visualPingLinks,
    });

    const pagination = ref(
      computed({
        get: () => store.state.linkChecker.pagination,
        set: (paginationVar) =>
          store.commit('linkChecker/updatePagination', paginationVar),
      })
    );

    const onRequest = async (props) => {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const fetchCount =
        rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage;
      const filter = props.filter.searchTerm;

      loading.value = true;

      await LinkcheckerService.getVisualPingLinks(
        filter,
        props.filter.showUnchangedLinks,
        props.filter.showChangedLinks,
        props.filter.showBrokenLinks,
        sortBy,
        descending,
        page,
        fetchCount
      );
      store.commit('linkChecker/updatePaginationPage', props.pagination);
      loading.value = false;
    };

    const showAddVisualPingLink = computed({
      get: () => store.state.scheme.showAddVisualPingLink,
      set: (value) => store.commit('scheme/updateShowAddVisualPingLink', value),
    });

    const showEditVisualPingLink = computed({
      get: () => store.state.scheme.showEditVisualPingLink,
      set: (value) =>
        store.commit('scheme/updateShowEditVisualPingLink', value),
    });

    const removeVisualPingLink = async (linkId) => {
      loading.value = true;
      await LinkcheckerService.removeVisualPingLink(linkId, null);
      await refreshPingLinks();
      loading.value = false;
    };

    const selectedVpLinkToEdit = computed({
      get: () => store.state.scheme.selectedVpLinkToEdit,
      set: (value) => store.commit('scheme/setSelectedVpLinkToEdit', value),
    });

    const showLinkChanges = computed({
      get: () => store.state.linkChecker.showLinkChanges,
      set: (value) => store.commit('linkChecker/updateShowLinkChanges', value),
    });

    const toggleVPJobStatus = async (value, jobId) => {
      linkcheckerService.toggleVPJobStatus(jobId, value, null);
      await refreshPingLinks();
    };

    const customFilter = () => {
      return visualPingLinks.value;
    };
    const getFetchCount = () => {
      return pagination.value.rowsPerPage === 0
        ? pagination.value.rowsNumber
        : pagination.value.rowsPerPage;
    };
    const refreshPingLinks = async () => {
      await LinkcheckerService.getVisualPingLinks(
        filter.searchTerm,
        filter.showUnchangedLinks,
        filter.showChangedLinks,
        filter.showBrokenLinks,
        pagination.value.sortBy,
        pagination.value.descending,
        pagination.value.page,
        getFetchCount()
      );
    };

    return {
      columns,
      getFetchCount,
      refreshPingLinks,
      visualPingLinks,
      filter,
      loading,
      pagination,
      onRequest,
      showAddVisualPingLink,
      removeVisualPingLink,
      showEditVisualPingLink,
      hideUrlField,
      selectedVpLinkToEdit,
      selectedLinkId,
      showLinkChanges,
      toggleVPJobStatus,
      customFilter,
    };
  },
};
</script>
