import axios from 'axios';
import authHeader from '../helpers/auth-header';
import axiosUrl from './axios.service';
import AuthService from '../services/auth.service';

const API_URL = axiosUrl.getAxiosUrl();

class FunctionalitiesUseService {
  async getAllFunctionalitiesUsed() {
    return await axios
      .get(API_URL + 'functionalitiesUse/GetAllFunctionalitiesUsed', {
        headers: authHeader(),
      })
      .then((response) => {
        console.log('Functionalities used: ', response);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async getAllSharedItems() {
    return await axios
      .get(API_URL + 'functionalitiesUse/getAllSharedItems', {
        headers: authHeader(),
      })
      .then((response) => {
        console.log('Shared items: ', response);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  // Item functionality uses
  async getAllItemFunctionalities() {
    return await axios
      .get(API_URL + 'functionalitiesUse/getAllItemFunctionalities', {
        headers: authHeader(),
      })
      .then((response) => {
        console.log('Item functionalities uses:   ', response);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  // Exporting
  async exportSharedItemsData(year, months) {
    return await axios
      .get(API_URL + 'functionalitiesUse/exportSharedItemsData', {
        params: {
          year: year.value,
          months: months ? months.value + '' : null,
        },
        headers: authHeader(),
        responseType: 'blob',
        reportProgress: true,
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async exportFunctionalitiesUsedData(year, months) {
    return await axios
      .get(API_URL + 'functionalitiesUse/exportFunctionalitiesUsedData', {
        params: {
          year: year.value,
          months: months ? months.value + '' : null,
        },
        headers: authHeader(),
        responseType: 'blob',
        reportProgress: true,
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async exportSchemesNewsVisits(year, months) {
    return await axios
      .get(API_URL + 'functionalitiesUse/exportSchemesAndNewsVisits', {
        params: {
          year: year.value,
          months: months ? months.value + '' : null,
        },
        headers: authHeader(),
        responseType: 'blob',
        reportProgress: true,
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async exportKeywordSearches(year, months) {
    return await axios
      .get(API_URL + 'functionalitiesUse/exportKeywordSearches', {
        params: {
          year: year.value,
          months: months ? months.value + '' : null,
        },
        headers: authHeader(),
        responseType: 'blob',
        reportProgress: true,
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }

  async exportPortalUsersTable(selectedCustomersIds) {
    return await axios
      .get(API_URL + 'functionalitiesUse/exportPortalUsersTable', {
        headers: authHeader(),
        responseType: 'blob',
        reportProgress: true,
        params: {
          customers: selectedCustomersIds.value
            ? selectedCustomersIds.value + ''
            : null,
        },
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
        }
      });
  }
}

export default new FunctionalitiesUseService();
