import store from '../index';
const state = () => ({
  selectedYear: 2023,
  selectedMonths: [],
  selectedMonthsIds: [],
  chartData: {},
  schemeVisitsData: [],
  newsVisitsData: [],
  chartDataLabels: [],
  newSchemesCount: 0,
  editedSchemesCount: 0,
  schemeVisitsTableData: [],
  keywordSerarchTableData: [],
  newsVisitsTableData: [],
  newsBarData: [],
  newsBarLabels: [],
  showDataModal: false,
  modalData: [],
  isSchemesModal: true,
  schemesAndNewsBarData: [],
  showExportModal: false,
  selectedYearExport: 2023,
  selectedMonthsExport: [],
  selectedDataExport: '',
  selectedMonthsExportIds: [],
  customersList: [],
  selectedCustomers: [],
  selectedCustomersIds: [],
  schemePurposesBarData: {},
  isLoadingExport: false,
  selectedMonthsIdsServer: [],
  yearFrom: 2023,
  yearTo: 2023,
  monthFrom: 0,
  monthTo: 0,
  monthFromId: 0,
  monthToId: 0,
  optionsYearsFrom: [],
  optionsYearsTo: [],
});

const getters = {};

const actions = {};
import moment from 'moment';
const mutations = {
  updateYearsOptions(state) {
    state.optionsYearsFrom = [];
    state.optionsYearsTo = [];
    var minYear = 2017;
    var current = moment().year();
    state.yearFrom = current;
    state.yearTo = current;
    while (current >= minYear) {
      state.optionsYearsFrom.push(current);
      state.optionsYearsTo.push(current);
      current--;
    }
  },
  updateYearsToOptions(state) {
    state.yearFrom != moment().year()
      ? (state.optionsYearsTo = [state.yearFrom, state.yearFrom + 1])
      : (state.optionsYearsTo = [state.yearFrom]);
  },
  updateYearFrom(state, val) {
    state.yearFrom = val;
  },
  updateYearTo(state, val) {
    state.yearTo = val;
  },
  updateMonthFrom(state, val) {
    state.monthFrom = val.name;
    state.monthFromId = val.id + 1;
  },
  updateMonthTo(state, val) {
    state.monthTo = val.name;
    state.monthToId = val.id + 1;
  },
  updateIsLoadingExport(state, val) {
    state.isLoadingExport = val;
  },

  updateConsumptionSchemePurposesData(state, val) {
    var barLabels = [];
    var barData = [];

    if (val)
      for (let i = 0; i < val.length && i < 16; i++) {
        barLabels.push(val[i].name);
        barData.push(val[i].count);
      }

    state.schemePurposesBarData = {
      labels: barLabels,

      datasets: [
        {
          label: 'Most popular purposes',
          backgroundColor: '#0a108f',
          data: barData,
          color: '#0a108f',
          borderColor: '#0a108f',
        },
      ],
    };
  },
  updateSelectedCustomers(state, val) {
    state.selectedCustomers = [];
    state.selectedCustomersIds = [];
    if (val && val.length > 0)
      for (let i = 0; i < val.length; i++) {
        state.selectedCustomers.push(val[i]);
        state.selectedCustomersIds.push(val[i].id);
      }
  },

  updateCustomersList(state, val) {
    state.customersList = val;
  },
  updateSelectedDataExport(state, val) {
    state.selectedDataExport = val;
  },
  updateSelectedYearExport(state, val) {
    state.selectedYearExport = val;
  },
  updateSelectedMonthsExport(state, val) {
    state.selectedMonthsExport = [];
    state.selectedMonthsExportIds = [];
    for (let i = 0; i < val.length; i++) {
      state.selectedMonthsExport.push(val[i]);
      state.selectedMonthsExportIds.push(val[i].id);
    }
  },
  updateShowExportModal(state, val) {
    state.showExportModal = val;
  },
  updateShowDataModal(state, val) {
    state.showDataModal = val;
  },
  updateModalDataSchemes(state, val) {
    state.modalData = val;
    state.isSchemesModal = true;
  },
  updateModalDataNews(state, val) {
    state.modalData = val;
    state.isSchemesModal = false;
  },
  updateNewsBarData(state, val) {
    var barLabels = [];
    var barData = [];

    if (val != null)
      for (let i = 0; i < val.length; i++) {
        barLabels.push(val[i].monthName);
        barData.push(val[i].count);
      }
    state.newsBarData = {
      labels: barLabels,
      datasets: [
        {
          label: 'Published news',
          backgroundColor: '#0a108f',
          data: barData,
          color: '#0a108f',
          borderColor: '#0a108f',
        },
      ],
    };
  },
  updateSchemesAndNewsBarData(state, val) {
    var barLabelsNews = [];
    var barDataNews = [];
    var barLabelsCreatedSchemes = [];
    var barDataCreatedSchemes = [];
    var barLabelsEditedSchemes = [];
    var barDataEditedSchemes = [];
    if (val != null)
      for (let i = 0; i < val.createdNews.length; i++) {
        barLabelsNews.push(val.createdNews[i].dateString);
        barDataNews.push(val.createdNews[i].count);
      }
    for (let i = 0; i < val.editedSchemes.length; i++) {
      barLabelsEditedSchemes.push(val.editedSchemes[i].dateString);
      barDataEditedSchemes.push(val.editedSchemes[i].count);
    }
    for (let i = 0; i < val.createdSchemes.length; i++) {
      barLabelsCreatedSchemes.push(val.createdSchemes[i].dateString);
      barDataCreatedSchemes.push(val.createdSchemes[i].count);
    }
    state.schemesAndNewsBarData = {
      labels: barLabelsNews,
      datasets: [
        {
          label: 'Published news',
          backgroundColor: '#FF5B33',
          data: barDataNews,
          color: '#FF5B33',
          borderColor: '#FF5B33',
        },
        {
          label: 'Created schemes',
          backgroundColor: '#0a108f',
          data: barDataCreatedSchemes,
          borderColor: '#0a108f',
          color: '#0a108f',
        },
        {
          label: 'Edited schemes',
          backgroundColor: '#1299d7',
          data: barDataEditedSchemes,
          borderColor: '#1299d7',
          color: '#1299d7',
        },
      ],
    };
  },

  updateNewsVisitsTable(state, val) {
    state.newsVisitsTableData = val;
  },
  updateSchemeVisitsTable(state, val) {
    state.schemeVisitsTableData = val;
  },
  updateKeywordSearchTable(state, val) {
    state.keywordSerarchTableData = val;
  },

  updateNewAndEditedSchemesCounts(state, val) {
    state.newSchemesCount = val.newSchemesCount;
    state.editedSchemesCount = val.editedSchemesCount;
  },
  updateSelectedYear(state, val) {
    state.selectedYear = val;
  },
  updateSelectedMonths(state, val) {
    function toMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber, 0);

      return date.toLocaleString('en-US', {
        month: 'long',
      });
    }
    state.selectedMonths = [];
    state.chartDataLabels = [];
    state.selectedMonthsIds = [];
    state.selectedMonthsIdsServer = [];
    for (let i = 0; i < val.length; i++) {
      state.selectedMonths.push(val[i]);
      state.selectedMonthsIds.push(val[i].id);
      state.selectedMonthsIdsServer.push(val[i].id + 1);
      state.chartDataLabels.push(toMonthName(val[i].id + 1).substring(0, 3));
    }
  },
  updateChartData(state, data) {
    state.schemeVisitsData = [];
    state.newsVisitsData = [];
    store.commit('consumptionStats/updateDataSets', data);
    state.chartData = {
      labels: state.chartDataLabels,

      datasets: [
        {
          label: 'Scheme views',
          backgroundColor: '#0a108f',
          data: state.schemeVisitsData,
          borderColor: '#0a108f',
          color: '#0a108f',
        },
        {
          label: 'News views',
          backgroundColor: '#1299d7',
          data: state.newsVisitsData,
          borderColor: '#1299d7',
          color: '#1299d7',
        },
      ],
    };
  },

  updateDataSets(state, data) {
    for (let i = 0; i < data.schemeVisits.length; i++) {
      state.schemeVisitsData.push(data.schemeVisits[i].count);
    }
    for (let i = 0; i < data.newsVisits.length; i++) {
      state.newsVisitsData.push(data.newsVisits[i].count);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
