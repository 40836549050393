<template>
  <ErrorModal />
  <div class="news-page">
    <div class="main">
      <SideBar />
      <q-dialog v-model="showDeleteModal">
        <q-card>
          <q-card-section>
            <div class="text-h6">
              Are you sure you want to delete this news item?
            </div>
          </q-card-section>

          <q-card-actions align="right">
            <q-btn
              class="orange-btn-filled bold-font"
              no-caps
              label="Yes"
              @click="deleteNewsItem(newsIdToDelete)"
            >
            </q-btn>

            <q-btn
              class="orange-btn bold-font"
              no-caps
              label="No"
              @click="showDeleteModal = false"
            >
            </q-btn>
          </q-card-actions>
        </q-card>
      </q-dialog>

      <div class="news-list">
        <q-table
          class="sticky-header news-items"
          table-header-style="background-color: #0a1f8f"
          table-header-class="text-white"
          title="News"
          :rows="allNews"
          :columns="columns"
          row-key="id"
          :loading="loading"
          :dense="$q.screen.lt.md"
          v-model:pagination="pagination"
          @request="onRequest"
        >
          <template v-slot:top>
            <q-btn
              class="orange-btn-filled bold-font"
              no-caps
              :disable="loading"
              label="Add news Item"
              @click="addNewsItem"
              dense
            >
              <i class="las la-2x la-plus-circle la-fw"></i>
            </q-btn>
            <q-space />
            <q-btn
              class="orange-btn-filled bold-font"
              no-caps
              :disable="loading || invitesDisabled"
              label="Consultant screening invite"
              @click="confirmSendInvite = true"
              dense
            >
              <i class="las la-2x la-mail-bulk la-fw"></i>
            </q-btn>

            <q-space />
            <q-btn
              class="orange-btn bold-font"
              no-caps
              :disable="loading"
              label="Consultant screening reminder"
              @click="confirmSendInviteReminders = true"
              dense
            >
              <i class="las la-2x la-paper-plane la-fw"></i>
            </q-btn>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="name" :props="props"
                ><span v-html="props.row.title"></span
              ></q-td>
              <q-td key="newsLetterTypes" :props="props" class="row">
                <q-chip
                  size="xs"
                  v-for="(type, idx) in props.row.newsLetterTypes"
                  :key="idx"
                  :label="type.substring(0, 1)"
                  :class="classForNewsletterType(type)"
                  class="chip bold-font"
                >
                  <q-tooltip v-if="type == 'Default'">
                    Default
                  </q-tooltip>
                  <q-tooltip v-if="type == 'Screening'">
                    Screening
                  </q-tooltip>
                  <q-tooltip v-if="type == 'Portal'">
                    Portal
                  </q-tooltip>
                </q-chip>
              </q-td>
              <q-td key="scope" :props="props">
                <label v-if="props.row.scope != 'Undefined'">{{
                  props.row.scope
                }}</label>
              </q-td>
              <q-td key="date" :props="props">{{
                formatDate(props.row.date)
              }}</q-td>
              <q-td key="status" :props="props">
                <label v-if="props.row.status != 'Undefined'">{{
                  props.row.status
                }}</label>
              </q-td>
              <q-td key="actions" :props="props" class="q-gutter-xs">
                <q-btn
                  color="primary"
                  icon="las la-2x la-pen"
                  size="sm"
                  no-caps
                  @click="editNewsItem(props.row.id, false)"
                  @mousedown.middle="editNewsItem(props.row.id, true)"
                >
                  <q-tooltip> Edit </q-tooltip>
                </q-btn>

                <q-btn
                  v-if="props.row.status != 'Live'"
                  color="primary"
                  @click="publishNews(props.row.id)"
                  icon="las la-2x la-upload"
                  size="sm"
                  no-caps
                >
                  <q-tooltip> Publish News </q-tooltip>
                </q-btn>

                <q-btn
                  color="negative"
                  @click="enableShowDeleteModal(props.row.id)"
                  icon="las la-2x la-trash"
                  size="sm"
                  no-caps
                >
                  <q-tooltip> Delete News </q-tooltip>
                </q-btn>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>
  </div>
  <q-dialog v-model="confirmSendInviteReminders" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-mail-bulk la-fw"></i>
        <span class="q-ml-sm"
          >Are you sure you want to send the news selection reminder to the
          consultants?</span
        >
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          class="orange-btn bold-font"
          no-caps
          label="Cancel"
          v-close-popup
        />
        <q-btn
          label="Verzenden"
          class="orange-btn-filled bold-font"
          no-caps
          v-close-popup
          @click="sendInviteReminders()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="confirmSendInvite" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-mail-bulk la-fw"></i>
        <span class="q-ml-sm"
          >Are you sure you want to send the news selection invite to the
          consultants?</span
        >
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          label="Cancel"
          class="orange-btn bold-font"
          no-caps
          v-close-popup
        />
        <q-btn
          label="Verzenden"
          class="orange-btn-filled bold-font"
          no-caps
          v-close-popup
          @click="sendInvite()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import store from '../store/index';
import NewsService from '../services/news.service';
import router from '../router/index';
import SideBar from '../components/news-components/NewsSideBarSearch.vue';
import moment from 'moment';
import ErrorModal from '../components/news-components/NewsModalError.vue';

import axios from 'axios';
import authHeader from '../helpers/auth-header';
import axiosUrl from '../services/axios.service';

const API_URL = axiosUrl.getAxiosUrl();

const columns = [
  {
    name: 'name',
    required: true,
    label: 'Title',
    align: 'left',
    field: (row) => row.name,
    format: (val) => `${val}`,
  },
  {
    name: 'newsLetterTypes',
    label: 'Newsletter types',
    align: 'left',
    field: (row) => row.newsLetterTypes,
  },
  { name: 'scope', align: 'center', label: 'Scope', field: (row) => row.scope },
  {
    name: 'date',
    align: 'center',
    label: 'Publish date',
    field: (row) => row.date,
  },
  {
    name: 'status',
    align: 'center',
    label: 'Status',
    field: (row) => row.title,
  },
  { name: 'actions', align: 'center', label: 'Actions', field: 'actions' },
];

export default {
  components: {
    SideBar,
    ErrorModal,
  },
  name: 'News',
  setup() {
    //get totalresults from news
    const totalResults = computed({
      get: () => store.state.news.totalResults,
    });
    const confirmSendInvite = ref(false);
    const confirmSendInviteReminders = ref(false);

    const rows = ref(allNews);
    const loading = ref(false);
    const paginationVari = ref({
      page: 1,
      rowsPerPage: 50,
      rowsNumber: 50,
    });

    const pagination = ref(
      computed({
        get: () => store.state.news.pagination,
        set: (paginationVari) =>
          store.commit('news/updatePagination', paginationVari),
      })
    );
    const organisationsInclude = computed({
      get: () => store.state.advancedSearch.organisationsInclude,
      set: (val) =>
        store.commit('advancedSearch/updateOrganisationsInclude', val),
    });
    const organisationsExclude = computed({
      get: () => store.state.advancedSearch.organisationsExclude,
      set: (val) =>
        store.commit('advancedSearch/updateOrganisationsExclude', val),
    });
    //setting default date
    const dateFrom = computed({
      get: () => store.state.newsSearch.dateFrom,
    });
    const dateTo = computed({
      get: () => store.state.newsSearch.dateTo,
    });
    //get maxPage from news
    const maxPages = computed({
      get: () => store.state.news.maxPages,
    });

    onMounted(async () => {
      store.commit('news/setDefaultPagination');
      await NewsService.getAllNews(current.value, dateFrom.value, dateTo.value);

      await axios
        .get(API_URL + 'news/getCanSendInvite', {
          headers: authHeader(),
        })
        .then((response) => {
          invitesDisabled.value = !response.data;
        });
    });

    //get selected nodes
    const audienceTags = computed({
      get: () => store.state.newsSearch.selectedAudiences,
    });
    const selectedLocations = computed({
      get: () =>
        store.state.newsSearch.selectedLocations &&
        store.state.newsSearch.selectedLocations.length > 0
          ? store.state.newsSearch.selectedLocations
          : JSON.parse(sessionStorage.getItem('newsSelectedLocations')),
    });
    const purposesTags = computed({
      get: () => store.state.newsSearch.selectedPurposes,
    });
    const schemesTags = computed({
      get: () => store.state.newsSearch.selectedSchemeTypes,
    });
    const projectTypesTags = computed({
      get: () => store.state.newsSearch.selectedProjectTypes,
    });
    //This is just hidden for now, don't remove it
    /*
    const industriesTags = computed({
      get: () => store.state.newsSearch.selectedIndustries,
    });
    */

    const selectedGermanLocationsPaths = computed({
      get: () => store.state.newsSearch.selectedGermanLocationsPaths,
      set: (val) => {
        store.commit("newsSearch/updateSelectedGermanLocationsPaths", val);
      },
    });

    //get search text from newsSearch
    const searchText = computed({
      get: () => store.state.newsSearch.searchText,
    });

    const currentPage = computed({
      get: () => store.state.news.currentPage,
    });

    const pageSize = computed({
      get: () => store.state.news.pageSize,
    });

    const currentDateFrom = ref(dateFrom.value);
    const currentDateTo = ref(dateTo.value);

    const goToPage = async (current) => {
      store.commit('news/updateCurrentPage', current);
      await NewsService.getAllNews(current);
    };
    const formatDate = (stringDate) => {
      const date = new Date(stringDate);
      return moment(date).format('DD-MM-YYYY');
    };

    const editNewsItem = (id, isMiddleClick) => {
      if (!isMiddleClick) {
        let route = router.resolve({ path: `/admin/news/edit/${id}` });
        window.open(route.href, '_blank');
      } else {
        let route = router.resolve({ path: `/admin/news/edit/${id}` });
        window.open(route.href, '_blank');
      }
    };

    const publishNews = async (id) => {
      await NewsService.publishNews(id);
      router.go();
    };

    const deleteNewsItem = async (id) => {
      await NewsService.deleteNewsItem(id);
      showDeleteModal.value = false;
    };
    const allNews = computed({
      get: () => store.state.news.allNews,
    });

    const current = ref(currentPage.value);

    const addNewsItem = () => {
      router.push({ path: `/admin/news/add` });
    };

    const sendInvite = async () => {
      await NewsService.sendInvites(
        current.value,
        dateFrom.value,
        dateTo.value
      );
      invitesDisabled.value = true;
    };

    const sendInviteReminders = async () => {
      await NewsService.sendInviteReminders(
        current.value,
        dateFrom.value,
        dateTo.value
      );
      invitesDisabled.value = true;
    };

    const notif1 = ref(true);
    const invitesDisabled = ref(true);
    const battery = ref(false);

    const selectedSchemesIds = computed({
      get: () => store.state.newsSearch.selectedSchemesIds,
    });

    const onRequest = async (props) => {
      const { page, rowsPerPage } = props.pagination;
      const fetchCount =
        rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage;
      await NewsService.getSearchResults(
        searchText,
        dateFrom.value,
        dateTo.value,
        audienceTags,
        schemesTags,
        projectTypesTags,
        selectedLocations,
        selectedGermanLocationsPaths,
        purposesTags,
        //This is just hidden for now, don't remove it
        //industriesTags,
        organisationsInclude,
        organisationsExclude,
        selectedSchemesIds,
        page - 1,
        fetchCount
      );
      store.commit('news/updatePaginationPage', props.pagination);
    };

    const showDeleteModal = ref(false);

    const enableShowDeleteModal = (id) => {
      newsIdToDelete.value = id;
      showDeleteModal.value = true;
    };

    const newsIdToDelete = ref(0);

    return {
      organisationsExclude,
      organisationsInclude,
      invitesDisabled,
      newsIdToDelete,
      enableShowDeleteModal,
      showDeleteModal,
      deleteNewsItem,
      confirmSendInvite,
      confirmSendInviteReminders,
      sendInvite,
      sendInviteReminders,
      selectedSchemesIds,
      columns,
      battery,
      notif1,
      current,
      currentPage,
      goToPage,
      maxPages,
      allNews,
      editNewsItem,
      addNewsItem,
      publishNews,
      formatDate,
      rows,
      pagination,
      dateFrom,
      dateTo,
      currentDateFrom,
      currentDateTo,
      loading,
      searchText,
      onRequest,
      totalResults,
      pageSize,
      paginationVari,
      selectedGermanLocationsPaths,
      selectedLocations,
      audienceTags,
      purposesTags,
      schemesTags,
      projectTypesTags,
      classForNewsletterType(type) {
        switch (type) {
          case 'Default':
            return 'default';
          case 'Screening':
            return 'screening';
          case 'Portal':
            return 'portal';

          default:
            return 'None';
        }
      },
    };
  },
};
</script>
