<template>
  <div class="tree-select-tags">
    <div style="min-width: 280px; width: 27vw">
      <div class="tree q-mt-lg">
        <div class="row">
          <span
            class="filter-box-heading"
            v-text="translateTabType(nodes.label)"
          ></span>
          <q-space></q-space>
          <i
            class="las la-2x la-times-circle clear-icon on-right"
            @click="resetSelectedNodes()"
          >
            <q-tooltip>
              Clear selected tags
            </q-tooltip>
          </i>
          <i
            class="las la-2x la-check-square clear-icon on-right"
            @click="selectAllNodes()"
          >
            <q-tooltip>
              Select all tags
            </q-tooltip>
          </i>
        </div>
        <q-input
          dense
          outlined
          v-model="filterNodes"
          label="Search"
          class="tree-searchbox"
        >
        </q-input>
        <div class="test">
          <q-scroll-area
            :bar-style="barStyle"
            :thumb-style="thumbStyle"
            visible
            class="tree-scroll-area"
          >
            <q-tree
              ref="tree"
              :filter="filterNodes"
              :filter-method="myFilterMethod"
              :nodes="nodes.options"
              node-key="id"
              tick-strategy="leaf"
              v-model:ticked="tickedNodes"
              v-model:expanded="expandedNodes"
              v-model:selected="selectedNodes"
              @update:ticked="updatePairs(tickedNodes)"
              class="default-tree"
            />
          </q-scroll-area>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import store from '../../store/index';
import { useVModel } from '../../composables/useVModel';
import translateTabType from '../../helpers/translate';

export default {
  name: 'NewsSelectTagsTree',
  props: {
    treeNodes: Object,
    pairs: Array,
    tagType: String,
  },
  setup(props) {
    const nodes = ref(props.treeNodes);
    const tree = ref(null);
    const filterNodes = ref('');
    const type = ref(props.tagType);
    const expandedNodes = ref([]);
    const selectedNodes = ref([]);

    const pairsState = ref(useVModel(props, 'pairs'));
    const tickedNodes = ref(pairsState.value.map((el) => el.id));

    //add parent if all children are selected
    const updatePairs = (keys) => {
      pairsState.value.length = 0;
      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          let node = tree.value.getNodeByKey(keys[i]);
          pairsState.value.push(node);
        }
        var toAdd = [];

        for (let i = 0; i < nodes.value.options.length; i++) {
          if (
            nodes.value.options[i].children &&
            nodes.value.options[i].children.length > 0
          ) {
            var hasAllChildrenTicked = true;

            for (let j = 0; j < nodes.value.options[i].children.length; j++) {
              if (
                nodes.value.options[i].children[j].children &&
                nodes.value.options[i].children[j].children.length > 0
              ) {
                var hasAllSubChildrenTicked = true;

                for (
                  let k = 0;
                  k < nodes.value.options[i].children[j].children.length;
                  k++
                ) {
                  if (
                    !keys.includes(
                      nodes.value.options[i].children[j].children[k].id
                    )
                  ) {
                    hasAllSubChildrenTicked = false;
                    break;
                  }
                }
                if (
                  hasAllSubChildrenTicked &&
                  !pairsState.value.includes(nodes.value.options[i].children[j])
                ) {
                  toAdd.push(nodes.value.options[i].children[j]);
                }

                if (
                  !hasAllSubChildrenTicked &&
                  pairsState.value.includes(nodes.value.options[i].children[j])
                ) {
                  const index = pairsState.value.indexOf(
                    nodes.value.options[i].children[j]
                  );
                  pairsState.value.splice(index, 1);
                  hasAllChildrenTicked = false;
                }
              } else {
                hasAllSubChildrenTicked = false;
              }
              //subchild end

              if (!keys.includes(nodes.value.options[i].children[j].id)) {
                if (!toAdd.includes(nodes.value.options[i].children[j])) {
                  hasAllChildrenTicked = false;
                  continue;
                }
              }
            }

            if (
              hasAllChildrenTicked &&
              !pairsState.value.includes(nodes.value.options[i])
            )
              toAdd.push(nodes.value.options[i]);

            if (
              !hasAllChildrenTicked &&
              pairsState.value.includes(nodes.value.options[i])
            ) {
              const index = pairsState.value.indexOf(nodes.value.options[i]);
              pairsState.value.splice(index, 1);
            }
          } else {
            hasAllChildrenTicked = false;
          }
        }

        if (toAdd.length > 0) {
          for (let i = 0; i < toAdd.length; i++)
            pairsState.value.push(toAdd[i]);
        }
      }
      store.commit(
        'news/update' + `${type.value}` + 'Count',
        pairsState.value.length
      );
    };

    const selectAllNodes = () => {
      tickedNodes.value = [];

      var toTick = [];
      for (let i = 0; i < nodes.value.options.length; i++) {
        toTick.push(nodes.value.options[i].id);

        if (
          nodes.value.options[i].children &&
          nodes.value.options[i].children.length > 0
        ) {
          for (let j = 0; j < nodes.value.options[i].children.length; j++) {
            toTick.push(nodes.value.options[i].children[j].id);

            if (
              nodes.value.options[i].children[j].children &&
              nodes.value.options[i].children[j].children.length > 0
            ) {
              for (
                let k = 0;
                k < nodes.value.options[i].children[j].children.length;
                k++
              ) {
                toTick.push(nodes.value.options[i].children[j].children[k].id);
              }
            }
          }
        }
      }

      if (toTick.length > 0) {
        for (let i = 0; i < toTick.length; i++)
          tickedNodes.value.push(toTick[i]);
      }

      updatePairs(tickedNodes.value);
    };

    const resetSelectedNodes = () => {
      tickedNodes.value = [];
      updatePairs(tickedNodes.value);
    };

    return {
      translateTabType,
      nodes,
      filterNodes,
      tickedNodes,
      pairsState,
      updatePairs,
      tree,
      expandedNodes,
      selectedNodes,
      selectAllNodes,
      resetSelectedNodes,
      type,
      myFilterMethod(node, filter) {
        const filt = filter.toLowerCase();
        return node.label && node.label.toLowerCase().indexOf(filt) > -1;
      },
    };
  },
};
</script>
