<template>
  <div
    :class="isContrast != 'false' && isContrast != false ? 'high-contrast' : ''"
  >
    <div class="homepage-main-container homepage-main">
      <span>{{ statusText }}</span>
      <div>
        <div v-for="record in records" :key="record">
          {{ record }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosUrl from '../services/axios.service';
import axios from 'axios';
import { onMounted, computed, ref } from 'vue';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
import router from '../router/index';
import AuthService from '../services/auth.service';

const API_URL = axiosUrl.getAxiosUrl();

export default {
  name: 'HomePage',
  setup() {
    onMounted(async () => {
      return await axios
        .get(API_URL + 'SalesForce/CheckSalesForceAccess', {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.success) {
            statusText.value = 'SalesForce is logged in!';

            axios
              .get(API_URL + 'SalesForce/GetAccounts', {
                headers: authHeader(),
              })
              .then((response) => {
                store.commit('salesForce/updateAccounts', response.data);
                router.push({
                  name: 'NewsSelectPage',
                });
              });
          } else {
            statusText.value = 'Need to login to SalesForce';
            location.href = response.data.redirectUrl;
          }
        })
        .catch((error) => {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            // auto logout if 400 or 401 or 403 response returned from api
            AuthService.logout();
            router.go();
          }
        });
    });

    const statusText = ref('Checking SalesForce status');
    const records = ref([]);

    const isContrast = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });
    return {
      isContrast,
      statusText,
      records,
    };
  },
};
</script>
