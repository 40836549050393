<template>
  <div class="scheme-details-page">
    <div class="column">
      <div class="row heading-scheme">
        <i
          class="las la-arrow-circle-left la-3x back-arrow"
          @click="router.go(-1)"
        >
          <q-tooltip> Go to previous page </q-tooltip>
        </i>
        <h4 class="title">{{ grant.name }}</h4>
      </div>
    </div>
    <div class="row main-page">
      <div class="top-details column">
        <span class="box-title"> General information </span>
        <q-input
          label="Identifier"
          stack-label
          outlined
          bg-color="white"
          v-model="grant.identifier"
          readonly
          class="q-mt-sm general-item"
        />
        <q-input
          label="Application term"
          stack-label
          outlined
          bg-color="white"
          v-model="grant.applicationTerm"
          readonly
          class="q-mt-sm general-item"
        />
        <q-input
          label="SME Type"
          stack-label
          outlined
          bg-color="white"
          v-model="grant.smeType"
          readonly
          class="q-mt-sm general-item"
        />
        <q-input
          label="Accronym"
          stack-label
          outlined
          bg-color="white"
          v-model="grant.accronym"
          readonly
          class="q-mt-sm general-item"
        />
        <q-input
          label="Archive code"
          stack-label
          outlined
          bg-color="white"
          v-model="grant.archivecode"
          readonly
          class="q-mt-sm general-item"
        />
        <q-input
          label="Scope"
          stack-label
          outlined
          bg-color="white"
          v-model="grant.scope"
          readonly
          class="q-mt-sm general-item"
        />
        <div class="budgets-table general-item">
          <q-table
            title="Scheme budgets by year"
            dense
            :rows="grant.schemeBudgets"
            :columns="columns"
            row-key="name"
          />
        </div>
        <div class="budgets-table general-item">
          <q-table
            title="Organisations"
            dense
            :rows="grant.organisations"
            :columns="columnsOrganisations"
            row-key="name"
          />
        </div>
      </div>

      <div class="column large-fields">
        <div>
          <span class="box-title"> Introduction </span>
          <div class="introduction">
            <div v-html="shortIntro" v-if="!showMore && shortIntro"></div>
            <div v-html="grant.introduction" v-if="showMore"></div>

            <q-btn
              flat
              icon="expand_less"
              v-if="showMore"
              @click="toggleShowMore()"
            >
              <q-tooltip> Show less </q-tooltip>
            </q-btn>
            <q-btn
              flat
              icon="expand_more"
              v-if="!showMore"
              @click="toggleShowMore()"
            >
              <q-tooltip> Show more </q-tooltip>
            </q-btn>
          </div>
        </div>

        <div>
          <span class="box-title"> Body </span>
          <div
            v-if="grant.body"
            v-html="grant.body"
            class="introduction"
          ></div>
        </div>

        <div>
          <span class="box-title"> Criteria </span>
          <div
            v-if="grant.criteria"
            v-html="grant.criteria"
            class="introduction"
          ></div>
        </div>
      </div>
    </div>
    <div class="tags">
      <q-badge
        class="tag-badge"
        outline
        color="primary"
        v-for="tag in scheme.tags"
        :key="tag.id"
      >
        {{ tag.name }}
      </q-badge>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, computed, ref } from 'vue';
import SchemesSevice from '../services/schemes.services';
import store from '../store/index';
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    onMounted(async () => {
      const id = route.params.schemeId;
      await SchemesSevice.getSchemeById(id, true);
    });

    const grant = computed({
      get: () => store.state.schemeItems.selectedScheme,
    });

    const shortIntro = computed({
      get: () => store.state.schemeItems.shortIntro,
    });

    const showMore = ref(false);

    const columns = [
      {
        name: 'year',
        label: 'Year',
        field: 'year',
        sortable: true,
        align: 'left',
      },
      {
        name: 'amount',
        label: 'Amount (€)',
        field: 'amount',
        sortable: true,
        align: 'left',
      },
    ];

    const columnsOrganisations = [
      {
        name: 'name',
        label: 'Name',
        field: 'name',
        sortable: true,
        align: 'left',
      },
    ];

    const toggleShowMore = () => (showMore.value = !showMore.value);
    return {
      columns,
      route,
      router,
      grant,
      shortIntro,
      showMore,
      toggleShowMore,
      columnsOrganisations,
    };
  },
};
</script>
