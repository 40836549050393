<template>
  <ErrorModal />
  <q-dialog v-model="showEditVisualPingLink" persistent class="modal-general">
    <q-card style="min-width: 50vw">
      <q-card-section>
        <div>Edit link</div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none">
        <div class="row justify-around">
          <q-input
            v-if="!hideUrl"
            dense
            v-model="selectedVpLinkToEditUrl"
            label="Url"
            stack-label
            :rules="[requiredFieldRule, isValidUrl]"
            lazy-rules
            style="min-width: 30vw; max-width: 50vw"
            bg-color="white"
            borderless
            class="q-mt-md"
          />
        </div>
        <div class="row justify-around">
          <q-input
            dense
            v-model="selectedVpLinkToEditText"
            label="Link text"
            stack-label
            style="min-width: 30vw; max-width: 50vw"
            bg-color="white"
            borderless
          />
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          class="orange-btn-filled bold-font"
          no-caps
          label="Save"
          @click="
            editVisualPingLink(
              selectedVpLinkToEditText,
              selectedVpLinkToEditUrl
            )
          "
          :disable="selectedVpLinkToEditUrl != '' ? null : true"
        />
        <q-btn
          class="orange-btn bold-font"
          no-caps
          label="Cancel"
          @click="disableEditVisualPingLink"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed } from 'vue';
import store from '../../store/index';
import ErrorModal from './SchemeModalError.vue';
import linkcheckerService from '../../services/linkchecker.service';

export default {
  name: 'EditVisualPingLink',
  components: {
    ErrorModal,
  },
  props: {
    selectedSchemeId: Number,
    hideUrlField: Boolean,
    searchFilter: String,
    toggleUnchangedLinks: Boolean,
    toggleChangedLinks: Boolean,
    toggleBrokenLinks: Boolean,
  },
  setup(props) {
    const schemeId = computed(() => {
      return props.selectedSchemeId;
    });
    const hideUrl = computed(() => {
      return props.hideUrlField;
    });

    const searchFilterTerm = computed(() => {
      return props.searchFilter;
    })
    const toggleUnchangedLinksShow = computed(() => {
      return props.toggleUnchangedLinks;
    });

    const toggleChangedLinksShow = computed(() => {
      return props.toggleChangedLinks;
    });

    const toggleBrokenLinksShow = computed(() => {
      return props.toggleBrokenLinks;
    });

    const showEditVisualPingLink = computed({
      get: () => store.state.scheme.showEditVisualPingLink,
      set: (value) =>
        store.commit('scheme/updateShowEditVisualPingLink', value),
    });

    const selectedVpLinkToEditUrl = computed({
      get: () => store.state.scheme.selectedVpLinkToEditUrl,
      set: (value) =>
        store.commit('scheme/updateSelectedVpLinkToEditUrl', value),
    });

    const selectedVpLinkToEditText = computed({
      get: () => store.state.scheme.selectedVpLinkToEditText,
      set: (value) =>
        store.commit('scheme/updateSelectedVpLinkToEditText', value),
    });

    const selectedVpLinkToEdit = computed({
      get: () => store.state.scheme.selectedVpLinkToEdit,
    });

    const editVisualPingLink = async (vpLinkText, vpLinkUrl) => {
      if (vpLinkText == '') vpLinkText = vpLinkUrl;

      selectedVpLinkToEdit.value.text = vpLinkText;
      selectedVpLinkToEdit.value.url = vpLinkUrl;

      await linkcheckerService.editVisualPingLink(
        selectedVpLinkToEdit.value,
        schemeId.value
      );
      await refreshPingLinks();
      disableEditVisualPingLink();
    };

    const disableEditVisualPingLink = () => {
      store.commit('scheme/setSelectedVpLinkToEdit', null);
      showEditVisualPingLink.value = false;
    };

    const isValidUrl = (urlString) => {
      var urlPattern = new RegExp(
        '^(https?:\\/\\/)?' +
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
          '((\\d{1,3}\\.){3}\\d{1,3}))' +
          '(\\:\\d+)?(\\/[-a-z\\d%_.,~+]*)*' +
          '(\\?[;&a-z\\d%_.,~+=-]*)?' +
          '(\\#[-a-z\\d_]*)?$',
        'i'
      );

      return urlPattern.test(urlString) || 'Invalid URL';
    };
    const pagination = ref(
      computed({
        get: () => store.state.linkChecker.pagination,
        set: (paginationVar) =>
          store.commit('linkChecker/updatePagination', paginationVar),
      })
    );
    const getFetchCount = () => {
      return pagination.value.rowsPerPage === 0
        ? pagination.value.rowsNumber
        : pagination.value.rowsPerPage;
    };
    const refreshPingLinks = async () => {
      await linkcheckerService.getVisualPingLinks(
        searchFilterTerm.value,
        toggleUnchangedLinksShow.value,
        toggleChangedLinksShow.value,
        toggleBrokenLinksShow.value,
        pagination.value.sortBy,
        pagination.value.descending,
        pagination.value.page,
        getFetchCount()
      );
    };
    return {
      pagination,
      schemeId,
      selectedVpLinkToEdit,
      refreshPingLinks,
      searchFilterTerm,
      toggleUnchangedLinksShow,
      toggleChangedLinksShow,
      toggleBrokenLinksShow,
      hideUrl,
      showEditVisualPingLink,
      editVisualPingLink,
      disableEditVisualPingLink,
      selectedVpLinkToEditUrl,
      selectedVpLinkToEditText,
      isValidUrl,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || 'Field is required!',
      ],
    };
  },
};
</script>
