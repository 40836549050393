import axios from 'axios';
import axiosUrl from './axios.service';
import authHeader from '../helpers/auth-header';
import store from '../store/index';
import AuthService from './auth.service';
import router from '../router';

const API_URL = axiosUrl.getAxiosUrl();

class FiltersService {
  async getDefaultFilters() {
    return await axios
      .get(API_URL + `grantsearchfilter/tags`, {
        params: {
          culture: 'nl',
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('advancedSearch/updateFilterActivities', response.data);
        store.commit('advancedSearch/createLocationsOptions');
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
          router.go();
        }
      });
  }

  async getApplicationTerms() {
    return await axios
      .get(API_URL + `/grantsearchfilter/getAllTermsPairs`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('advancedFilters/updateTerms', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
          router.go();
        }
      });
  }

  async getAllPublicationStatuses() {
    return await axios
      .get(
        API_URL + 'grantsearchfilter/getAllPublicationStatusesGroupOptions',
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit(
          'advancedFilters/updatePublicationStatusesEnums',
          response.data
        );
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          // auto logout if 401 or 403 response returned from api
          AuthService.logout();
          router.go();
        }
      });
  }

  getSchemesForSelect(filter) {
    return axios
      .get(API_URL + 'schemes/getFilteredSchemesForSelect', {
        params: {
          filter: filter,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('newsSearch/updateSchemesOptions', response.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthService.logout();
          router.go();
        }
      });
  }
}

export default new FiltersService();
