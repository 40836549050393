<template>
  <div class="tree q-mt-lg">
    <span
      v-if="props.news != 'news'"
      class="filter-box-heading"
      v-text="heading"
    ></span>

    <q-input
      dense
      borderless
      ref="filterRef"
      v-if="filtering"
      v-model="filter"
      label="Search"
      class="tree-searchbox"
    >
    </q-input>
    <div class="q-pa-md top-padding-rmv">
      <q-tree
        :filter="filter"
        :filter-method="myFilterMethod"
        :nodes="nodesState"
        node-key="id"
        tick-strategy="leaf"
        v-model:ticked="ticked"
        v-model:expanded="expanded"
        v-model:selected="selected"
        @update:ticked="updateSelected(ticked)"
        ref="tree"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import store from '../../store/index';
import ETagType from '../../enums/ETagType';

export default {
  props: {
    nodesState: Array,
    tagType: Number,
    filtering: Boolean,
    news: String,
    prevSelected: String,
  },
  setup(props) {
    const treeNodes = ref(props.nodesState);
    const ticked = ref([]);
    const expanded = ref([]);
    const selected = ref([]);
    const filter = ref('');
    const filterRef = ref(null);

    onMounted(() => {
      if (props.prevSelected && props.prevSelected.length > 0) {
        var selectedArray = props.prevSelected.split(',');
        for (let i = 0; i < selectedArray.length; i++)
          ticked.value.push(parseInt(selectedArray[i]));
      }
    });

    // update pairs uraditi, i pairs.value poslati u store commit
    const updateSelected = (val) => {
      if (props.news == 'news') {
        if (props.tagType === ETagType.Audience) {
          store.commit('newsSearch/updateSelectedAudiences', val);
        }
        if (props.tagType === ETagType.Purposes) {
          store.commit('newsSearch/updateSelectedPurposes', val);
        }
        if (props.tagType === ETagType.SchemeType) {
          store.commit('newsSearch/updateSelectedSchemeTypes', val);
        }
        if (props.tagType === ETagType.ProjectType) {
          store.commit('newsSearch/updateSelectedProjectTypes', val);
        }
        if (props.tagType === ETagType.Locations) {
          store.commit('newsSearch/updateSelectedLocations', val);
        }
        //This is just hidden for now, don't remove it
        /*
        if (props.tagType === ETagType.Industries) {
          store.commit('newsSearch/updateSelectedIndustries', val);
        }
        */
      } else if (props.news != 'news') {
        if (props.tagType === ETagType.Audience) {
          store.commit('advancedSearch/updateSelectedAudiences', val);
        }
        if (props.tagType === ETagType.Purposes) {
          store.commit('advancedSearch/updateSelectedPurposes', val);
        }
        if (props.tagType === ETagType.SchemeType) {
          store.commit('advancedSearch/updateSelectedSchemeTypes', val);
        }
        if (props.tagType === ETagType.ProjectType) {
          store.commit('advancedSearch/updateSelectedProjectTypes', val);
        }
        if (props.tagType === ETagType.Locations) {
          store.commit('advancedSearch/updateSelectedLocations', val);
        }
        /*
        //This is just hidden for now, don't remove it
        if (props.tagType === ETagType.Industries) {
          store.commit('advancedSearch/updateSelectedIndustries', val);
          SchemeUtils.searchSchemes();
        }
        */
      }
    };

    const pairs = ref([]);
    const tree = ref(null);

    //just to add parent to selected
    const updatePairs = (keys) => {
      pairs.value = [];
      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          let node = tree.value.getNodeByKey(keys[i]);
          pairs.value.push(node);
        }
        var toAdd = [];
        for (let i = 0; i < props.nodesState.length; i++) {
          if (
            props.nodesState[i].children &&
            props.nodesState[i].children.length > 0
          ) {
            var hasAllChildrenTicked = true;

            for (let j = 0; j < props.nodesState[i].children.length; j++) {
              if (
                props.nodesState[i].children[j].children &&
                props.nodesState[i].children[j].children.length > 0
              ) {
                var hasAllSubChildrenTicked = true;
                for (
                  let k = 0;
                  k < props.nodesState[i].children[j].children.length;
                  k++
                ) {
                  if (
                    !keys.includes(
                      props.nodesState[i].children[j].children[k].id
                    )
                  ) {
                    hasAllSubChildrenTicked = false;
                    break;
                  }
                }
              }
              if (hasAllSubChildrenTicked)
                toAdd.push(props.nodesState[i].children[j]);

              if (!keys.includes(props.nodesState[i].children[j].id)) {
                hasAllChildrenTicked = false;
                continue;
              }

              if (hasAllChildrenTicked) toAdd.push(props.nodesState[i]);
            }
          } else {
            hasAllChildrenTicked = false;
          }
        }
        if (toAdd.length > 0) {
          for (let i = 0; i < toAdd.length; i++) pairs.value.push(toAdd[i]);
        }
      }
    };

    return {
      updatePairs,
      pairs,
      tree,
      updateSelected,
      ticked,
      filter,
      filterRef,
      expanded,
      treeNodes,
      props,
      selected,
      myFilterMethod(node, filter) {
        const filt = filter.toLowerCase();
        return node.label && node.label.toLowerCase().indexOf(filt) > -1;
      },
    };
  },
};
</script>
