<template>
    <q-dialog v-model="show" persistent fit>
        <q-card class="modal-card">
      <div class="row justify-end close-btn-area">
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
          @click="disableModal()"
          class="row justify-end close-btn"
        />
      </div>

      <q-table
        class="my-sticky-header-table"
        row-key="name"
        flat
        bordered
        :separator="'cell'"
        :pagination="pagination"
        :rows="customersByType"
        :columns="customersColumns"
        :rows-per-page-options="[]"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="customer" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="startDate" :props="props">
              {{ formatDate(props.row.startDate) }}
            </q-td>
            <q-td key="endDate" :props="props">
             <span
              v-if="
                props.row.endDate == null ||
                  props.row.endDate == ''
              "
              >Not specified</span
            >
            <span v-else>{{ formatDate(props.row.endDate) }}</span>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-card>
    </q-dialog>
</template>
<script>
import { computed } from 'vue';
import store from '../../../store/index';
import moment from 'moment';

export default {
  setup() {
    const disableModal = () => {
      store.commit('customers/updateShowPieChartModal', false);
    };

    const show = computed({
      get: () => store.state.customers.showPieChartModal,
    });

    const customersColumns = [
      {
        name: 'customer',
        field: 'name',
        label: 'Customer name',
        align: 'left',
        sortable: true,
      },
      {
        name: 'startDate',
        field: 'startDate',
        label: 'Customer start date',
        align: 'left',
        format: (val) => formatDate(val),
      },
      {
        name: 'endDate',
        field: 'endDate',
        label: 'Customer end date',
        align: 'left',
        sortable: true,
        format: (val) => formatDate(val),
      },
    ];

    const formatDate = (stringDate) => {
      const date = new Date(stringDate);
      return moment(date).format('DD-MM-YYYY');
    };
 
    const customersByType = computed({
      get: () => store.state.customers.pieChartCustomersData,
    });


    return {
      customersByType,
      customersColumns,
      formatDate,
      show,
      disableModal,
      pagination: {
        rowsPerPage: 500,
        page: 1,
      },
    };
  },
};
</script>