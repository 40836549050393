<template>
  <q-dialog class="users-list-modal-consumption" v-model="show" persistent>
    <q-card class="modal-card">
      <div class="row justify-end close-btn-area">
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
          @click="disableModal()"
          class="row justify-end close-btn"
        />
      </div>
      <q-table
        class="my-sticky-header-table"
        row-key="name"
        flat
        bordered
        :separator="'cell'"
        :pagination="pagination"
        :rows="data"
        :columns="columns"
        :rows-per-page-options="[]"
        v-if="isSchemesModal"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="schemeName" :props="props">
              {{ props.row.schemeName }}
            </q-td>
            <q-td key="visitCount" :props="props">
              {{ props.row.visitCount }}
            </q-td>
            <q-td key="downloadCount" :props="props">
              {{ props.row.downloadCount }}
            </q-td>
            <q-td key="saveCount" :props="props">
              {{ props.row.saveCount }}
            </q-td>
            <q-td key="shareCount" :props="props">
              {{ props.row.shareCount }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
      <q-table
        class="my-sticky-header-table"
        row-key="name"
        flat
        bordered
        :separator="'cell'"
        :pagination="pagination"
        :rows="dataNews"
        :columns="columnsNews"
        :rows-per-page-options="[]"
        v-if="!isSchemesModal"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="newsTitle" :props="props">
              {{ props.row.newsTitle }}
            </q-td>
            <q-td key="visitCount" :props="props">
              {{ props.row.visitCount }}
            </q-td>
            <q-td key="shareCount" :props="props">
              {{ props.row.shareCount }}
            </q-td>
            <q-td key="publicationDate" :props="props">
              {{ formatDate(props.row.publicationDate) }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed } from 'vue';
import store from '../../../store/index';
import moment from 'moment';
export default {
  props: {
    typeOfData: String,
  },

  setup() {
    const disableModal = () => {
      store.commit('consumptionStats/updateShowDataModal', false);
    };

    const show = computed({
      get: () => store.state.consumptionStats.showDataModal,
    });

    const data = computed({
      get: () => store.state.consumptionStats.modalData.schemes,
    });
    const dataNews = computed({
      get: () => store.state.consumptionStats.newsVisitsTableData.news,
    });

    const isSchemesModal = computed({
      get: () => store.state.consumptionStats.isSchemesModal,
    });
    const columns = [
      {
        name: 'schemeName',
        field: 'schemeName',
        label: 'Scheme',
        align: 'left',
        sortable: true,
        style: 'max-width: 550px; white-space: pre-wrap;',
      },
      {
        name: 'visitCount',
        field: 'visitCount',
        label: 'Visits (last 30 days)',
        align: 'left',
        sortable: true,
      },
      {
        name: 'downloadCount',
        field: 'downloadCount',
        label: 'Downloads (last 30 days)',
        align: 'left',
        sortable: true,
      },
      {
        name: 'saveCount',
        field: 'saveCount',
        label: 'Saves (last 30 days)',
        align: 'left',
        sortable: true,
      },
      {
        name: 'shareCount',
        field: 'shareCount',
        label: 'Shares (last 30 days)',
        align: 'left',
        sortable: true,
      },
    ];
    const columnsNews = [
      {
        name: 'newsTitle',
        field: 'newsTitle',
        label: 'News title',
        style: 'max-width: 550px; white-space: pre-wrap;',
        align: 'left',
        sortable: true,
      },
      {
        name: 'visitCount',
        field: 'visitCount',
        label: 'Visits (last 30 days)',
        align: 'left',
        sortable: true,
      },
      {
        name: 'shareCount',
        field: 'shareCount',
        label: 'Shares (last 30 days)',
        align: 'left',
        sortable: true,
      },
      {
        name: 'publicationDate',
        field: 'publicationDate',
        label: 'Publication date',
        align: 'left',
        format: (val) => formatDate(val),
      },
    ];
    const formatDate = (stringDate) => {
      const date = new Date(stringDate);
      return moment(date).format('DD-MM-YYYY');
    };

    return {
      dataNews,
      formatDate,
      columnsNews,
      data,
      isSchemesModal,
      show,
      disableModal,
      columns,
      pagination: {
        rowsPerPage: 500,
        page: 1,
      },
    };
  },
};
</script>

<style lang="sass">
.my-sticky-header-table
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    background-color: #25408f

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  &.q-table--loading thead tr:last-child th
    top: 48px
</style>
